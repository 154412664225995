import KeyboardIcon from '@mui/icons-material/Keyboard';
import React, { useCallback, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ReceiptStep1Value } from './ReceiptFormStep1';
import { LunchReceiptOCRAnalysisDTO } from 'probonio-shared-ui/api';
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Chip,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
  styled,
} from '@mui/material';
import { Drawable } from '../../../component/drawable/Drawable';
import { FloatingInfoButton } from '../components/FloatingInfoButton';
import { ChecklistItem } from '../components/ChecklistItem';
import { capitalize } from '../../../utils/capitalize';

interface Props {
  ocrAnalysis: LunchReceiptOCRAnalysisDTO;
  value?: ReceiptStep1Value;
  onChange: (value: ReceiptStep1Value) => void;
  onCancel: () => void;
}

const LIST_ITEM_HEIGHT = 50;

const StyledList = styled(List, { shouldForwardProp: prop => prop !== 'maxItems' })<{ maxItems?: number }>(({ maxItems, theme }) => ({
  '& .MuiListItemButton-root': {
    paddingLeft: theme.spacing(1),
  },
  '& .MuiListItemIcon-root': {
    minWidth: theme.spacing(2),
  },
  '& .MuiListItemSecondaryAction-root': {
    right: theme.spacing(1),
    pointerEvents: 'none',
  },
  overflowY: maxItems ? 'auto' : undefined,
  maxHeight: maxItems ? maxItems * LIST_ITEM_HEIGHT : undefined,
  background: maxItems
    ? `linear-gradient(${theme.palette.mode === 'dark' ? '#353535' : 'white'} 30%,rgba(255, 255, 255, 0)) center top,
    linear-gradient(rgba(255, 255, 255, 0), ${theme.palette.mode === 'dark' ? '#353535' : 'white'} 70%) center bottom,
    linear-gradient(rgba(0, 0, 0, ${theme.palette.mode === 'dark' ? '0.5' : '0.2'}), rgba(0, 0, 0, 0)) center top,
    linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, ${theme.palette.mode === 'dark' ? '0.5' : '0.2'})) center bottom`
    : undefined,
  backgroundRepeat: 'no-repeat',
  backgroundSize: '100% 40px, 100% 40px, 100% 14px, 100% 14px',
  backgroundAttachment: 'local, local, scroll, scroll',
}));

export const ReceiptFormLineItems: React.FC<Props> = ({ ocrAnalysis, value, onChange, onCancel }) => {
  const { t } = useTranslation('benefitModule');
  const [itemSelection, setItemSelection] = useState<Record<string, number>>(
    value?.lineItems ? Object.fromEntries(value.lineItems.map(({ ocrLineItemId, quantity }) => [ocrLineItemId, quantity])) : {},
  );

  const handleToggle = useCallback(
    (value: string) => () => {
      const ocrItem = ocrAnalysis.lineItems.find(item => item.id === value)!;
      if (!itemSelection[value]) {
        setItemSelection({ ...itemSelection, [value]: 1 });
      } else if (ocrItem.unitPrice && ocrItem.quantity > 1 && itemSelection[value] < ocrItem.quantity) {
        setItemSelection({ ...itemSelection, [value]: itemSelection[value] + 1 });
      } else {
        setItemSelection({ ...itemSelection, [value]: 0 });
      }
    },
    [itemSelection, ocrAnalysis.lineItems],
  );

  const total = Object.entries(itemSelection)
    .map(([ocrLineItemId, quantity]) => {
      const ocrLineItem = ocrAnalysis.lineItems.find(item => item.id === ocrLineItemId)!;
      return quantity * (ocrLineItem.quantity > 1 && ocrLineItem.unitPrice ? ocrLineItem.unitPrice : ocrLineItem.total);
    })
    .reduce((sum, value) => sum + value, 0);

  const handleSelectAll = useCallback(() => {
    if (total === ocrAnalysis.total) {
      setItemSelection({});
    } else {
      setItemSelection(Object.fromEntries(ocrAnalysis.lineItems.filter(item => !item.isExcluded).map(item => [item.id, item.quantity])));
    }
  }, [ocrAnalysis.lineItems, ocrAnalysis.total, total]);

  const handleSubmit = useCallback(() => {
    onChange({
      total,
      operatorArray: [],
      valuesArray: [total],
      lineItems: ocrAnalysis.lineItems
        .map(item => ({ ocrLineItemId: item.id, quantity: itemSelection[item.id] }))
        .filter(({ quantity }) => !!quantity),
    });
  }, [itemSelection, ocrAnalysis.lineItems, onChange, total]);

  return (
    <>
      <FloatingInfoButton stateKey="lunch-line-items">
        <Trans i18nKey="benefitModule:benefit.LUNCH.receiptLineItemsChecklist">
          <ChecklistItem />
        </Trans>
      </FloatingInfoButton>
      <Drawable icon={<KeyboardIcon />}>
        <Box mt={0.5} />
        {ocrAnalysis.lineItems.some(item => item.isExcluded) && (
          <Alert severity="warning" sx={{ borderRadius: 0, textAlign: 'left' }}>
            {t('benefit.LUNCH.lineItems.pfandWarning')}
          </Alert>
        )}
        <StyledList disablePadding>
          <ListItem
            disablePadding
            secondaryAction={
              <>
                <Typography variant="body2" component="span" color="inherit">
                  {t('benefit.LUNCH.lineItems.selectedSum')}
                </Typography>{' '}
                <Typography variant="body2" component="span" color="inherit">
                  {t('common:money', { money: total })}
                </Typography>
              </>
            }
          >
            <ListItemButton onClick={handleSelectAll} dense>
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  indeterminate={total > 0 && total < ocrAnalysis.total!}
                  checked={total === ocrAnalysis.total}
                  tabIndex={-1}
                  disableRipple
                />
              </ListItemIcon>
              <ListItemText primary="Alle auswählen" />
            </ListItemButton>
          </ListItem>
        </StyledList>
        <Divider />
        <StyledList disablePadding maxItems={6.5} data-test-id="line-items-list">
          {ocrAnalysis.lineItems
            .filter(item => !item.isExcluded)
            .map(item => (
              <ListItem
                key={item.id}
                disablePadding
                secondaryAction={
                  <>
                    {item.quantity > 1 && (
                      <Chip
                        size="small"
                        color={itemSelection[item.id] ? 'primary' : 'secondary'}
                        label={`${itemSelection[item.id] || 0}/${item.quantity}`}
                        sx={{ mr: 0.5 }}
                      />
                    )}
                    <Typography variant="body2" component="span" color="inherit">
                      {t('common:money', { money: item.quantity > 1 && item.unitPrice ? item.unitPrice : item.total })}
                    </Typography>
                  </>
                }
              >
                <ListItemButton
                  onClick={handleToggle(item.id)}
                  dense
                  sx={{ pr: theme => `${theme.spacing(item.quantity > 1 ? 5.5 : 3.5)} !important` }}
                >
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      indeterminate={!!itemSelection[item.id] && itemSelection[item.id] < item.quantity}
                      checked={itemSelection[item.id] === item.quantity}
                      tabIndex={-1}
                      disableRipple
                    />
                  </ListItemIcon>
                  <ListItemText secondary={capitalize(item.name)} secondaryTypographyProps={{ noWrap: true }} />
                </ListItemButton>
              </ListItem>
            ))}
        </StyledList>
        <Stack direction="row" justifyContent="space-between" marginX={1} paddingTop={0.5}>
          <Button variant="contained" color="secondary" onClick={onCancel} data-test-id="button-manual-entry">
            {t('benefit.LUNCH.lineItems.toManualEntry')}
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={total === 0}
            onClick={handleSubmit}
            sx={{ width: '35%' }}
            data-test-id="button-continue"
          >
            {t('common:button.continue')}
          </Button>
        </Stack>
      </Drawable>
    </>
  );
};
