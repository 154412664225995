import { Box, Typography, styled, useTheme } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { CreateMobilityReceiptDTOMobilityTypeEnum, MobilityReceiptRefundDTOMobilityTypeEnum } from 'probonio-shared-ui/api';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ContainedToggleButton } from '../../../component/button/ContainedToggleButton';
import { AllMobilityTypes } from './availableMobilityTypes';

const TaxFreeBanner = styled(Typography, { shouldForwardProp: prop => prop !== 'show' })<{ show?: boolean }>(({ theme, show = true }) => ({
  backgroundColor: theme.palette.success.main,
  color: theme.palette.success.contrastText,
  transform: 'rotate(-45deg)',
  left: theme.spacing(-1.5),
  top: theme.spacing(0.75),
  padding: `${theme.spacing(0)} ${theme.spacing(1.5)}`,
  position: 'absolute',
  display: show ? 'block' : 'none',
}));

interface Props {
  setMobilityType: (mT: MobilityReceiptRefundDTOMobilityTypeEnum) => void;
  mobilityType: MobilityReceiptRefundDTOMobilityTypeEnum | undefined;
  availableMobilityTypes: AllMobilityTypes;
}

export const MobilityTypes: React.FC<Props> = ({ setMobilityType, mobilityType, availableMobilityTypes }) => {
  const { t } = useTranslation('benefitModule');
  const theme = useTheme();

  const noFocus = useCallback<React.MouseEventHandler<HTMLButtonElement>>(ev => ev.preventDefault(), []);
  const handleChangeType = useCallback(
    (ev: unknown, newValue: MobilityReceiptRefundDTOMobilityTypeEnum) => {
      if (newValue) {
        setMobilityType(newValue);
      }
    },
    [setMobilityType],
  );

  return (
    <div>
      <Typography variant="body2" marginBottom={1} textAlign="center" minHeight="4rem">
        {t(mobilityType ? `benefit.MOBILITY.mobilityType.${mobilityType}.text` : 'benefit.MOBILITY.mobilityType.pleaseSelect')}
      </Typography>
      <Grid container spacing={0.5} columns={15} justifyContent="flex-start" pb={1}>
        {!!availableMobilityTypes &&
          (Object.keys(availableMobilityTypes) as CreateMobilityReceiptDTOMobilityTypeEnum[]).map(key => {
            const value = availableMobilityTypes[key];
            if (!value) {
              return <></>;
            }
            return (
              <Grid size={5} key={key} textAlign="center">
                <Box overflow="hidden" height="100%" data-test-id="mobility-types-buttons">
                  <ContainedToggleButton
                    value={key}
                    selected={key === mobilityType}
                    onChange={handleChangeType}
                    onMouseDown={noFocus}
                    fullWidth
                    disabled={value.isDisabled}
                    sx={{
                      paddingX: 0,
                      paddingTop: 0.8,
                      borderWidth: 2,
                      height: theme => theme.spacing(5.5),
                      backgroundColor: value.isTaxFree ? theme.palette.success.light : '',
                      alignItems: 'flex-start',
                    }}
                  >
                    <Box>
                      <TaxFreeBanner show={value.isTaxFree} variant="body2" fontSize={theme.typography.extraSmall.fontSize}>
                        {t('benefit.MOBILITY.mobilityType.taxFree')}
                      </TaxFreeBanner>
                      {value.icon}
                      <Typography variant="body2" fontSize={theme.typography.small.fontSize}>
                        {t(`benefit.MOBILITY.mobilityType.${key}.title`)}
                      </Typography>
                    </Box>
                  </ContainedToggleButton>
                </Box>
              </Grid>
            );
          })}
      </Grid>
    </div>
  );
};
