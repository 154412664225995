/* tslint:disable */
/* eslint-disable */
/**
 * Probonio full API Docs
 * If you stumbled upon this page and want to integrate Probonio into your business software [click here!](/api/external)    If you\'re looking for more information about Probonio [head over here](https://probonio.de)    **This documentation describes all Endpoints of the Probonio API. It\'s created for experienced Probonio Developers only!**  
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface LunchRefundMetadataDTO
 */
export interface LunchRefundMetadataDTO {
    /**
     * The reason why not the full refund is given. It is not set if the full refund was given.
     * @type {string}
     * @memberof LunchRefundMetadataDTO
     */
    'partialRefundType'?: LunchRefundMetadataDTOPartialRefundTypeEnum;
    /**
     * The maximum number of days per period that may be refunded (at max. lunchDailyLimit).
     * @type {number}
     * @memberof LunchRefundMetadataDTO
     */
    'maxNumberOfReceipts': number;
    /**
     * Type of taxation for the lunch benefit.
     * @type {string}
     * @memberof LunchRefundMetadataDTO
     */
    'taxationType': LunchRefundMetadataDTOTaxationTypeEnum;
    /**
     * Sachbezugswert
     * @type {number}
     * @memberof LunchRefundMetadataDTO
     */
    'nonCashValue': number;
    /**
     * Aufstockung
     * @type {number}
     * @memberof LunchRefundMetadataDTO
     */
    'supplement': number;
    /**
     * 
     * @type {number}
     * @memberof LunchRefundMetadataDTO
     */
    'dailyLimit': number;
    /**
     * 
     * @type {number}
     * @memberof LunchRefundMetadataDTO
     */
    'maxTotalAmount'?: number;
    /**
     * 
     * @type {number}
     * @memberof LunchRefundMetadataDTO
     */
    'taxableAmount': number;
    /**
     * 
     * @type {number}
     * @memberof LunchRefundMetadataDTO
     */
    'taxFreeAmount': number;
}

export const LunchRefundMetadataDTOPartialRefundTypeEnum = {
    ReachedMaxPerMonth: 'REACHED_MAX_PER_MONTH',
    PreventFlatRateTaxation: 'PREVENT_FLAT_RATE_TAXATION',
    ReachedMaxPerReceipt: 'REACHED_MAX_PER_RECEIPT',
    ReachedMaxTotalAmount: 'REACHED_MAX_TOTAL_AMOUNT'
} as const;

export type LunchRefundMetadataDTOPartialRefundTypeEnum = typeof LunchRefundMetadataDTOPartialRefundTypeEnum[keyof typeof LunchRefundMetadataDTOPartialRefundTypeEnum];
export const LunchRefundMetadataDTOTaxationTypeEnum = {
    SimpleTaxation: 'SIMPLE_TAXATION',
    FlatRateTaxation: 'FLAT_RATE_TAXATION'
} as const;

export type LunchRefundMetadataDTOTaxationTypeEnum = typeof LunchRefundMetadataDTOTaxationTypeEnum[keyof typeof LunchRefundMetadataDTOTaxationTypeEnum];


