import { SentryRoutes } from 'probonio-shared-ui/module/error/sentry';
import { Route } from 'react-router-dom';
import InternetInfoPage from './InternetInfoPage';
import { InternetPage } from './InternetPage';

const InternetRoutes: React.FC = () => {
  return (
    <SentryRoutes>
      <Route path="/info" element={<InternetInfoPage />} />
      <Route path="/*" element={<InternetPage />} />
    </SentryRoutes>
  );
};

export default InternetRoutes;
