import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import PendingOutlinedIcon from '@mui/icons-material/PendingOutlined';
import { SvgIconProps } from '@mui/material';
import { useMemo } from 'react';

interface Props extends Pick<SvgIconProps, 'color' | 'fontSize'> {
  severity: 'success' | 'error' | 'info';
}

export const createReceiptIconMapping = (
  props: SvgIconProps,
): {
  success: JSX.Element;
  error: JSX.Element;
  info: JSX.Element;
} => ({
  success: <CheckCircleOutlinedIcon {...props} />,
  error: <CancelOutlinedIcon {...props} />,
  info: <PendingOutlinedIcon {...props} />,
});

export const ReceiptIcon: React.FC<Props> = ({ severity, color, fontSize }) => {
  return useMemo(() => createReceiptIconMapping({ color, fontSize })[severity], [color, fontSize, severity]);
};
