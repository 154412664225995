import { Box, CircularProgress, CircularProgressProps } from '@mui/material';

interface Props {
  backgroundColor?: string;
}

// Inspired by the former Facebook spinners.
export const CircularProgressBar: React.FC<React.PropsWithChildren<Props & CircularProgressProps>> = ({
  children,
  backgroundColor,
  color,
  variant,
  ...props
}) => {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress
        variant="determinate"
        size={40}
        sx={{
          color: theme => backgroundColor || theme.palette.background.default,
        }}
        {...props}
        value={100}
      />
      <CircularProgress
        variant={variant}
        disableShrink={variant !== 'determinate'}
        sx={{
          animationDuration: '550ms',
          position: 'absolute',
          left: 0,
        }}
        size={40}
        color={color}
        {...props}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {children}
      </Box>
    </Box>
  );
};
