import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Box, SelectChangeEvent, Typography, Button, useTheme } from '@mui/material';
import { AxiosError } from 'axios';
import { LoadingButton } from 'probonio-shared-ui/component/button/LoadingButton';
import { apis } from 'probonio-shared-ui/module/api';
import { useWithMessage } from 'probonio-shared-ui/module/error';
import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { TextFieldControl } from '../component/form/TextFieldControl';
import { RegistrationLayout } from '../component/layout/RegistrationLayout';
import { CheckboxControl } from '../component/form/CheckboxControl';
import i18next from 'i18next';
import { SelectLocale } from '../component/languageSelect/SelectLocale';
import i18n from '../lang';

interface FormValues {
  mail: string;
  mailConfirm: string;
  registrationCode: string;
  dataPrivacy: boolean;
}

export const RegisterByCodePage: React.FC = () => {
  const { t } = useTranslation('registerPage');
  const [searchParams] = useSearchParams();
  const initialCode = (searchParams.get('code') || '').replace(/(\w{4})(\w{4})/, '$1-$2');
  const layoutTenant = searchParams.get('tenant') || undefined;
  const { control, handleSubmit, watch } = useForm<FormValues>({ defaultValues: { mail: '', registrationCode: initialCode } });
  const [success, setSuccess] = useState(false);
  const [registrationToken, setRegistrationToken] = useState<string>();
  const navigate = useNavigate();
  const theme = useTheme();

  const withMessage = useWithMessage(err => {
    switch ((err as AxiosError).response?.status) {
      case 403:
        return t('codeForm.invalidCode');
      case 409:
        return t('codeForm.duplicateMail');
      case 429:
        return t('codeForm.rateLimit');
    }
  });

  const registerMutation = useMutation({
    mutationFn: (values: FormValues) =>
      apis.public
        .registerByCode({
          registrationRequestDTO: {
            mail: values.mail,
            registrationCode: values.registrationCode,
            initLocale: i18next.language.slice(0, 2),
          },
        })
        .then(res => res.data),

    onError: withMessage,
    onSuccess: ({ registrationToken }) => {
      setRegistrationToken(registrationToken);
      setSuccess(true);
    },
  });

  const handleRegister = useCallback(
    (values: FormValues) => {
      registerMutation.mutate(values);
    },
    [registerMutation],
  );

  const changeLanguage = useCallback((event: SelectChangeEvent) => {
    void i18n.changeLanguage(event.target.value);
  }, []);

  const isEmployeeCode = watch('registrationCode')?.length >= 12;

  const tenantText = layoutTenant && t(`tenant.${layoutTenant}.text`, '');

  const validateSameMail = useCallback((value: string | boolean, formValues: FormValues) => {
    return value === formValues.mail;
  }, []);

  const handleCompleteRegistration = useCallback(() => {
    if (registrationToken) {
      navigate(`/register#token=${encodeURIComponent(registrationToken)}`);
    } else {
      navigate('/');
    }
  }, [navigate, registrationToken]);

  return (
    <RegistrationLayout layoutTenant={layoutTenant}>
      <Typography variant="h1" textAlign="center" marginBottom={1.25}>
        {t('codeForm.title')}
      </Typography>
      {tenantText && (
        <Typography textAlign="center" marginBottom={1.25}>
          {tenantText}
        </Typography>
      )}
      {success ? (
        <Box sx={{ textAlign: 'center' }}>
          <CheckCircleIcon data-test-id="register-success-icon" color="primary" fontSize="extraLarge" sx={{ marginBottom: 1 }} />
          <Typography data-test-id="register-success-text-email" gutterBottom>
            {t(registrationToken ? 'codeForm.successLine1Token' : 'codeForm.successLine1', { mail: watch('mail') })}
          </Typography>
          <Typography data-test-id="register-success-text-account">
            {t(isEmployeeCode ? 'codeForm.successLine2Employee' : 'codeForm.successLine2')}
          </Typography>
          <Button
            sx={{ marginTop: 1 }}
            data-test-id="back-to-login-button"
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            size="small"
            onClick={handleCompleteRegistration}
          >
            {t(registrationToken ? 'codeForm.completeRegistration' : 'codeForm.backToLogin')}
          </Button>
        </Box>
      ) : (
        <form onSubmit={handleSubmit(handleRegister)}>
          <TextFieldControl
            data-test-id="register-email"
            control={control}
            name="mail"
            label={t('codeForm.mail')}
            fullWidth
            size="small"
            type="email"
            autoFocus
            rules={{ required: true }}
            disabled={registerMutation.isPending}
            slotProps={{
              input: {
                sx: { marginBottom: 1, backgroundColor: 'background.paper' },
              },
            }}
          />
          <TextFieldControl
            data-test-id="register-confirm-email"
            control={control}
            name="mailConfirm"
            label={t('codeForm.mailConfirm')}
            fullWidth
            autoComplete="none"
            size="small"
            type="email"
            rules={{
              required: true,
              validate: validateSameMail,
            }}
            disabled={registerMutation.isPending}
            slotProps={{
              input: {
                sx: { marginBottom: 1, backgroundColor: 'background.paper' },
              },
            }}
          />
          <TextFieldControl
            data-test-id="register-code"
            control={control}
            name="registrationCode"
            label={t('codeForm.registrationCode')}
            placeholder="XXXX-XXXX / XXXX-XXXX-XXXX"
            fullWidth
            size="small"
            rules={{ required: true, pattern: /^\w{4}-?\w{4}(?:-?\w{4})?$/ }}
            disabled={registerMutation.isPending}
            slotProps={{
              input: {
                sx: { marginBottom: 1, backgroundColor: 'background.paper' },
              },
            }}
          />
          <Box marginBottom={1}>
            <SelectLocale variant="outlined" size="small" onLanguageChange={changeLanguage} />
          </Box>
          <Box marginBottom={1}>
            <CheckboxControl
              data-test-id="data-privacy-checkbox"
              control={control}
              name="dataPrivacy"
              label={
                <Trans i18nKey="registerPage:codeForm.dataPrivacy">
                  <a
                    href="https://www.probonio.de/datenschutz-app"
                    target="_blank"
                    rel="noreferrer"
                    style={{ color: theme.palette.primary.main }}
                  />
                </Trans>
              }
              rules={{ required: true }}
            />
          </Box>
          <LoadingButton
            data-test-id="register-button"
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            size="small"
            disabled={!watch('dataPrivacy')}
            loading={registerMutation.isPending}
          >
            {t('codeForm.submit')}
          </LoadingButton>
        </form>
      )}
    </RegistrationLayout>
  );
};
