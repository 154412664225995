import { Box, TextField, useTheme } from '@mui/material';
import { BenefitDTOBenefitEnum } from 'probonio-shared-ui/api';
import { LoadingButton } from 'probonio-shared-ui/component/button/LoadingButton';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CalculatorState } from '../../../component/currencyInput/useCalculatorState';
import { ReceiptInfoCard, ReceiptValues } from '../../lunch';
import { mapCreateMobilityReceipt } from './useCreateMobilityReceipt';
import { MobilityTypeState } from './useMobilityTypeState';
import ReceiptConditions from '../../lunch/components/ReceiptConditions';

interface Props {
  loading?: boolean;
  mobilityTypeState: MobilityTypeState;
  calculatorState: CalculatorState;
  receiptDate: Date;
  validityStartDate?: Date;
  comment?: string;
  onChangeComment: (value: string) => void;
  onSubmit: () => void;
}

function mapToReceiptValues(
  mobilityTypeState: MobilityTypeState,
  calculatorState: CalculatorState,
  receiptDate: Date,
  validityStartDate?: Date,
): ReceiptValues {
  const mappedReceipt = mapCreateMobilityReceipt(mobilityTypeState, calculatorState, receiptDate, validityStartDate);
  return {
    date: receiptDate,
    total: calculatorState.calculatorResult,
    mobilityType: mobilityTypeState.mobilityType,
    mobilityTravelPurpose: mobilityTypeState.travelPurpose,
    mobilityValidityStartDate: mappedReceipt.validityStartDate,
    mobilityValidityEndDate: mappedReceipt.validityEndDate,
  };
}

export const MobilitySummary: React.FC<Props> = ({
  loading,
  mobilityTypeState,
  calculatorState,
  receiptDate,
  validityStartDate,
  comment,
  onChangeComment,
  onSubmit,
}) => {
  const { t } = useTranslation('benefitModule');
  const theme = useTheme();
  const [isChecked, setIsChecked] = useState(false);

  const handleChangeComment = useCallback<React.ChangeEventHandler<HTMLInputElement>>(
    ev => {
      onChangeComment(ev.target.value);
    },
    [onChangeComment],
  );

  const handleCheckboxChange = useCallback((checked: boolean) => {
    setIsChecked(checked);
  }, []);

  const handleSubmit = useCallback<React.FormEventHandler<HTMLFormElement>>(
    ev => {
      ev.preventDefault();
      onSubmit();
    },
    [onSubmit],
  );

  const receiptValues = mapToReceiptValues(mobilityTypeState, calculatorState, receiptDate, validityStartDate);

  return (
    <Box component="form" sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }} onSubmit={handleSubmit}>
      <Box sx={{ flexGrow: 1 }}>
        <ReceiptInfoCard
          receipt={receiptValues}
          includeComment
          sx={{ border: `1px solid ${theme.palette.datePicker.border}` }}
          benefit={BenefitDTOBenefitEnum.Mobility}
        />
        <TextField
          label={t(`benefit.MOBILITY.field.comment`)}
          slotProps={{
            htmlInput: {
              maxLength: 1000,
            },
          }}
          multiline
          rows={3}
          fullWidth
          variant="outlined"
          placeholder={t(`benefit.MOBILITY.placeholder.comment`)}
          disabled={loading}
          value={comment}
          onChange={handleChangeComment}
        />
        <ReceiptConditions benefit={BenefitDTOBenefitEnum.Mobility} value={isChecked} onChange={handleCheckboxChange} />
      </Box>

      <Box>
        <LoadingButton
          variant="contained"
          color="primary"
          fullWidth
          type="submit"
          loading={loading}
          disabled={!isChecked}
          data-test-id="mobility-submit-receipt"
        >
          {t(`benefit.MOBILITY.addReceiptFlow.submitReceipt`)}
        </LoadingButton>
      </Box>
    </Box>
  );
};
