import { QueryFunctionContext, QueryKey, useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { useMeStateSelector } from '../me/types';

type ToTenantArray<T extends QueryKey> = T extends ReadonlyArray<infer U> ? ReadonlyArray<U | string> : ['tenants', string, T];
type ToEmployeeArray<T extends QueryKey> =
  T extends ReadonlyArray<infer U> ? ReadonlyArray<U | string> : ['tenants', string, 'employees', string, T];

/**
 * Creates a wrapped query that depends on the `tenantID` being loaded into the state. The `tenantID` will be passed as
 * additional first parameter to the query function, and will automatically be prepended to the `queryKey`.
 *
 * Ex.: Key `'users'` will be turned into `['tenants', <tenantID>, 'users']`
 */
export function useTenantQuery<TQueryFnData = unknown, TError = unknown, TData = TQueryFnData, TQueryKey extends QueryKey = QueryKey>(
  queryKey: TQueryKey,
  queryFn: (tenantID: string, context: QueryFunctionContext<ToTenantArray<TQueryKey>>) => TQueryFnData | Promise<TQueryFnData>,
  options?: Omit<UseQueryOptions<TQueryFnData, TError, TData, ToTenantArray<TQueryKey>>, 'queryKey' | 'queryFn'>,
): UseQueryResult<TData, TError> {
  const tenantID = useMeStateSelector(state => state.me.currentTenantId);
  const queryKeyArray: readonly unknown[] = typeof queryKey === 'string' ? [queryKey as string] : queryKey;

  return useQuery({
    queryKey: ['tenants', tenantID, ...queryKeyArray] as ToTenantArray<TQueryKey>,
    queryFn: context => queryFn(tenantID!, context),
    enabled: options?.enabled !== false && !!tenantID,
    ...options,
  });
}

/**
 * Creates a wrapped query that depends on the `tenantId` and `employeeId` being loaded into the state. The IDs will be passed as
 * additional first parameter to the query function, and will automatically be prepended to the `queryKey`.
 *
 * Ex.: Key `'benefits'` will be turned into `['tenants', <tenantId>, 'employees', <employeeId>, 'benefits']`
 */
export function useEmployeeQuery<TQueryFnData = unknown, TError = unknown, TData = TQueryFnData, TQueryKey extends QueryKey = QueryKey>(
  queryKey: TQueryKey,
  queryFn: (
    params: { tenantId: string; employeeId: string },
    context: QueryFunctionContext<ToEmployeeArray<TQueryKey>>,
  ) => TQueryFnData | Promise<TQueryFnData>,
  options?: Omit<UseQueryOptions<TQueryFnData, TError, TData, ToEmployeeArray<TQueryKey>>, 'queryKey' | 'queryFn'>,
): UseQueryResult<TData, TError> {
  const tenantId = useMeStateSelector(state => state.me.employment?.tenantId);
  const employeeId = useMeStateSelector(state => state.me.employment?.id);
  const queryKeyArray: readonly unknown[] = typeof queryKey === 'string' ? [queryKey as string] : queryKey;

  return useQuery({
    queryKey: ['tenants', tenantId, 'employees', employeeId, ...queryKeyArray] as ToEmployeeArray<TQueryKey>,
    queryFn: context => queryFn({ tenantId: tenantId!, employeeId: employeeId! }, context),
    enabled: options?.enabled !== false && !!tenantId && !!employeeId,
    ...options,
  });
}
