import { Divider, ListItem, Typography } from '@mui/material';
import React from 'react';
import { HorizontalBox, VerticalBox } from '../layout/BoxLayout';

export const ValueListItem: React.FC<{
  label: React.ReactNode;
  subLabel?: React.ReactNode;
  value: React.ReactNode;
  multiLine?: boolean;
  dataTestId?: string;
}> = ({ label, subLabel, value, multiLine = false, dataTestId }) => {
  const Box = multiLine ? VerticalBox : HorizontalBox;

  return (
    <ListItem sx={{ '&:last-child .value-list-item-divider': { display: 'none' } }} data-test-id={dataTestId}>
      <VerticalBox sx={{ maxWidth: '100%', flexGrow: 1 }}>
        <Box sx={{ maxWidth: '100%', flexWrap: 'nowrap', gap: multiLine ? 0.5 : 2 }}>
          <Typography sx={{ flexGrow: 1 }} variant="caption" color="text.secondary">
            {label}
            {subLabel && (
              <Typography variant="body2" color="text.secondary">
                {subLabel}
              </Typography>
            )}
          </Typography>
          <Typography noWrap whiteSpace={multiLine ? 'pre-line' : undefined} variant="caption" className="selectable">
            {value}
          </Typography>
        </Box>
        <Divider className="value-list-item-divider" sx={{ marginTop: 0.5, marginBottom: 0 }} />
      </VerticalBox>
    </ListItem>
  );
};
