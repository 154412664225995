import { Box, TextField, useTheme } from '@mui/material';
import { BenefitDTOBenefitEnum } from 'probonio-shared-ui/api';
import { LoadingButton } from 'probonio-shared-ui/component/button/LoadingButton';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CalculatorState } from '../../component/currencyInput/useCalculatorState';
import { ReceiptInfoCard, ReceiptValues } from '../lunch';
import ReceiptConditions from '../lunch/components/ReceiptConditions';

interface Props {
  loading?: boolean;
  calculatorState: CalculatorState;
  receiptDate: Date;
  validityStartDate?: Date;
  comment?: string;
  onChangeComment: (value: string) => void;
  onSubmit: () => void;
}

function mapToReceiptValues(calculatorState: CalculatorState, receiptDate: Date): ReceiptValues {
  return {
    date: receiptDate,
    total: calculatorState.calculatorResult,
  };
}

export const RecreationSummary: React.FC<Props> = ({ loading, calculatorState, receiptDate, comment, onChangeComment, onSubmit }) => {
  const { t } = useTranslation('benefitModule');
  const theme = useTheme();
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = useCallback((checked: boolean) => {
    setIsChecked(checked);
  }, []);

  const handleChangeComment = useCallback<React.ChangeEventHandler<HTMLInputElement>>(
    ev => {
      onChangeComment(ev.target.value);
    },
    [onChangeComment],
  );

  const handleSubmit = useCallback<React.FormEventHandler<HTMLFormElement>>(
    ev => {
      ev.preventDefault();
      onSubmit();
    },
    [onSubmit],
  );

  const receiptValues = mapToReceiptValues(calculatorState, receiptDate);

  return (
    <Box component="form" sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }} onSubmit={handleSubmit}>
      <Box sx={{ flexGrow: 1 }}>
        <ReceiptInfoCard
          receipt={receiptValues}
          includeComment
          sx={{ border: `1px solid ${theme.palette.datePicker.border}` }}
          benefit={BenefitDTOBenefitEnum.Recreation}
        />
        <TextField
          label={t(`benefit.RECREATION.field.comment`)}
          slotProps={{
            htmlInput: {
              maxLength: 1000,
            },
          }}
          multiline
          rows={3}
          fullWidth
          variant="outlined"
          placeholder={t(`benefit.RECREATION.placeholder.comment`)}
          disabled={loading}
          value={comment}
          onChange={handleChangeComment}
        />
        <Box mt={1}>
          <ReceiptConditions benefit={BenefitDTOBenefitEnum.Recreation} value={isChecked} onChange={handleCheckboxChange} />
        </Box>
      </Box>{' '}
      <Box>
        <LoadingButton
          variant="contained"
          color="primary"
          fullWidth
          type="submit"
          loading={loading}
          data-test-id="recreation-submit-receipt"
          disabled={!isChecked}
        >
          {t(`benefit.RECREATION.addReceiptFlow.submitReceipt`)}
        </LoadingButton>
      </Box>
    </Box>
  );
};
