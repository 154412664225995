/* tslint:disable */
/* eslint-disable */
/**
 * Probonio full API Docs
 * If you stumbled upon this page and want to integrate Probonio into your business software [click here!](/api/external)    If you\'re looking for more information about Probonio [head over here](https://probonio.de)    **This documentation describes all Endpoints of the Probonio API. It\'s created for experienced Probonio Developers only!**  
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
/**
 * FeedbackApi - axios parameter creator
 * @export
 */
export const FeedbackApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary sends feedback per mail to info@probonio.de
         * @param {string} subject 
         * @param {string} message 
         * @param {any} [file] 
         * @param {string} [uiVersion] 
         * @param {string} [platform] 
         * @param {string} [languageCode] 
         * @param {string} [screenSize] 
         * @param {string} [device] 
         * @param {string} [manufacturer] 
         * @param {string} [userAgent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendFeedback: async (subject: string, message: string, file?: any, uiVersion?: string, platform?: string, languageCode?: string, screenSize?: string, device?: string, manufacturer?: string, userAgent?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'subject' is not null or undefined
            assertParamExists('sendFeedback', 'subject', subject)
            // verify required parameter 'message' is not null or undefined
            assertParamExists('sendFeedback', 'message', message)
            const localVarPath = `/feedback`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
            if (subject !== undefined) { 
                localVarFormParams.append('subject', subject as any);
            }
    
            if (message !== undefined) { 
                localVarFormParams.append('message', message as any);
            }
    
            if (uiVersion !== undefined) { 
                localVarFormParams.append('uiVersion', uiVersion as any);
            }
    
            if (platform !== undefined) { 
                localVarFormParams.append('platform', platform as any);
            }
    
            if (languageCode !== undefined) { 
                localVarFormParams.append('languageCode', languageCode as any);
            }
    
            if (screenSize !== undefined) { 
                localVarFormParams.append('screenSize', screenSize as any);
            }
    
            if (device !== undefined) { 
                localVarFormParams.append('device', device as any);
            }
    
            if (manufacturer !== undefined) { 
                localVarFormParams.append('manufacturer', manufacturer as any);
            }
    
            if (userAgent !== undefined) { 
                localVarFormParams.append('userAgent', userAgent as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FeedbackApi - functional programming interface
 * @export
 */
export const FeedbackApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FeedbackApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary sends feedback per mail to info@probonio.de
         * @param {string} subject 
         * @param {string} message 
         * @param {any} [file] 
         * @param {string} [uiVersion] 
         * @param {string} [platform] 
         * @param {string} [languageCode] 
         * @param {string} [screenSize] 
         * @param {string} [device] 
         * @param {string} [manufacturer] 
         * @param {string} [userAgent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendFeedback(subject: string, message: string, file?: any, uiVersion?: string, platform?: string, languageCode?: string, screenSize?: string, device?: string, manufacturer?: string, userAgent?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendFeedback(subject, message, file, uiVersion, platform, languageCode, screenSize, device, manufacturer, userAgent, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FeedbackApi - factory interface
 * @export
 */
export const FeedbackApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FeedbackApiFp(configuration)
    return {
        /**
         * 
         * @summary sends feedback per mail to info@probonio.de
         * @param {string} subject 
         * @param {string} message 
         * @param {any} [file] 
         * @param {string} [uiVersion] 
         * @param {string} [platform] 
         * @param {string} [languageCode] 
         * @param {string} [screenSize] 
         * @param {string} [device] 
         * @param {string} [manufacturer] 
         * @param {string} [userAgent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendFeedback(subject: string, message: string, file?: any, uiVersion?: string, platform?: string, languageCode?: string, screenSize?: string, device?: string, manufacturer?: string, userAgent?: string, options?: any): AxiosPromise<void> {
            return localVarFp.sendFeedback(subject, message, file, uiVersion, platform, languageCode, screenSize, device, manufacturer, userAgent, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for sendFeedback operation in FeedbackApi.
 * @export
 * @interface FeedbackApiSendFeedbackRequest
 */
export interface FeedbackApiSendFeedbackRequest {
    /**
     * 
     * @type {string}
     * @memberof FeedbackApiSendFeedback
     */
    readonly subject: string

    /**
     * 
     * @type {string}
     * @memberof FeedbackApiSendFeedback
     */
    readonly message: string

    /**
     * 
     * @type {any}
     * @memberof FeedbackApiSendFeedback
     */
    readonly file?: any

    /**
     * 
     * @type {string}
     * @memberof FeedbackApiSendFeedback
     */
    readonly uiVersion?: string

    /**
     * 
     * @type {string}
     * @memberof FeedbackApiSendFeedback
     */
    readonly platform?: string

    /**
     * 
     * @type {string}
     * @memberof FeedbackApiSendFeedback
     */
    readonly languageCode?: string

    /**
     * 
     * @type {string}
     * @memberof FeedbackApiSendFeedback
     */
    readonly screenSize?: string

    /**
     * 
     * @type {string}
     * @memberof FeedbackApiSendFeedback
     */
    readonly device?: string

    /**
     * 
     * @type {string}
     * @memberof FeedbackApiSendFeedback
     */
    readonly manufacturer?: string

    /**
     * 
     * @type {string}
     * @memberof FeedbackApiSendFeedback
     */
    readonly userAgent?: string
}

/**
 * FeedbackApi - object-oriented interface
 * @export
 * @class FeedbackApi
 * @extends {BaseAPI}
 */
export class FeedbackApi extends BaseAPI {
    /**
     * 
     * @summary sends feedback per mail to info@probonio.de
     * @param {FeedbackApiSendFeedbackRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedbackApi
     */
    public sendFeedback(requestParameters: FeedbackApiSendFeedbackRequest, options?: AxiosRequestConfig) {
        return FeedbackApiFp(this.configuration).sendFeedback(requestParameters.subject, requestParameters.message, requestParameters.file, requestParameters.uiVersion, requestParameters.platform, requestParameters.languageCode, requestParameters.screenSize, requestParameters.device, requestParameters.manufacturer, requestParameters.userAgent, options).then((request) => request(this.axios, this.basePath));
    }
}
