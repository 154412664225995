import { SentryRoutes } from 'probonio-shared-ui/module/error/sentry';
import { Route } from 'react-router-dom';
import { AvailableCouponProvider } from '../../module/coupons';
import CouponInfoPage from './CouponInfoPage';
import { CouponOrderPage } from './CouponOrderPage';
import CouponsPage from './CouponsPage';
import ExploreCouponsDetailPage from './ExploreCouponsDetailPage';
import ExploreCouponsPage from './ExploreCouponsPage';
import { CouponsShowVoucherPage } from '../../module/coupons/CouponsShowVoucherPage';

const CouponsRoutes: React.FC = () => (
  <AvailableCouponProvider>
    <SentryRoutes>
      <Route path="/info" element={<CouponInfoPage />} />
      <Route path="/*" element={<CouponsPage />} />
      <Route path="/order/:couponOrderId" element={<CouponOrderPage />} />
      <Route path="/order/:couponOrderId/showVoucher" element={<CouponsShowVoucherPage />} />
      <Route path="/explore-coupons" element={<ExploreCouponsPage />} />
      <Route path="/explore-coupons/:couponId" element={<ExploreCouponsDetailPage />} />
    </SentryRoutes>
  </AvailableCouponProvider>
);

export default CouponsRoutes;
