import { useQueryClient, UseQueryResult } from '@tanstack/react-query';
import { NewsApiListArticlesRequest, NewsArticlesListDTO } from 'probonio-shared-ui/api';
import { apis, useEmployeeQuery } from 'probonio-shared-ui/module/api';
import { useTenantID } from 'probonio-shared-ui/module/me';

export function useNewsArticles(
  parameters?: Partial<NewsApiListArticlesRequest>,
  options?: { enabled?: boolean },
): UseQueryResult<NewsArticlesListDTO, Error> {
  return useEmployeeQuery(
    ['benefits', 'news', 'articles', parameters, apis.news.listArticlesByEmployee.name],
    context => apis.news.listArticlesByEmployee({ tenantId: context.tenantId, employeeId: context.employeeId }).then(res => res.data),
    {
      ...options,
    },
  );
}

export function useInvalidateNewsArticles(): () => Promise<void> {
  const getTenantId = useTenantID();
  const queryClient = useQueryClient();

  return () => queryClient.invalidateQueries({ queryKey: ['tenants', getTenantId(), 'benefits', 'news', 'articles'] });
}
