import { useQueryClient } from '@tanstack/react-query';
import { BaseEmployeeDTO, BaseEmployeeDTOStatusEnum, TenantDTO } from 'probonio-shared-ui/api';
import { apis, useTenantQuery } from 'probonio-shared-ui/module/api';
import { useCallback, useMemo } from 'react';
import { useMeStateSelector } from './types';

/**
 * useTenantID gives you a function returning the currently selected tenantID of the user.
 * The function throws if it is not loaded yet.
 */
export function useTenantID(): () => string {
  const result = useMeStateSelector(state => state.me.currentTenantId);
  return useCallback(() => {
    if (!result) {
      throw new Error('tenant not loaded yet');
    }
    return result;
  }, [result]);
}

export function useTenant(): { tenant?: TenantDTO; isLoading: boolean; invalidateTenant: () => void } {
  const getTenantId = useTenantID();
  const queryClient = useQueryClient();
  const { data: tenant, isLoading } = useTenantQuery(
    [apis.tenants.getTenant.name],
    tenantId => apis.tenants.getTenant({ tenantId, withImage: true }).then(res => res.data),
    {
      staleTime: 60000,
    },
  );
  const invalidateTenant = useCallback(() => {
    void queryClient.invalidateQueries({ queryKey: ['tenants', getTenantId()] });
  }, [queryClient, getTenantId]);
  return { tenant, isLoading, invalidateTenant };
}

export function useActiveEmployment(): () => BaseEmployeeDTO {
  const result = useMeStateSelector(state => state.me.employment);
  return useCallback(() => {
    if (!result) {
      throw new Error('Active employments not loaded yet');
    }
    return result;
  }, [result]);
}

export interface NamedTenant {
  id: string;
  name: string;
  isInactive?: boolean;
}

/**
 * @returns all tenants the user could switch to (including current)
 */
export function useAvailableTenants(): NamedTenant[] | undefined {
  const user = useMeStateSelector(state => state.me.user);

  return useMemo(
    () =>
      user?.employments
        ?.filter(
          employment => employment.status === BaseEmployeeDTOStatusEnum.Active || employment.status === BaseEmployeeDTOStatusEnum.Inactive,
        )
        .map(employment => ({
          id: employment.tenantId,
          name: employment.tenantName!,
          isInactive: employment.status === BaseEmployeeDTOStatusEnum.Inactive,
        })),
    [user?.employments],
  );
}

/**
 * true if the user has other tenants it could switch to (all roles)
 */
export function useHasAvailableTenants(allowInactive?: boolean): boolean {
  const user = useMeStateSelector(state => state.me.user);

  const activeEmployments = user?.employments?.filter(
    employment =>
      employment.status === BaseEmployeeDTOStatusEnum.Active || (allowInactive && employment.status === BaseEmployeeDTOStatusEnum.Inactive),
  );

  return (activeEmployments?.length || 0) > 1;
}
