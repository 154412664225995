import { Box, Fade } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { useSwipeable } from 'react-swipeable';

interface Props<T extends { id: string }> {
  items?: T[];
  currentItemId: string;
  onNavigate: (item: T) => void;
  children: React.ReactNode;
}

export const SwipeableList = <T extends { id: string }>({ items, currentItemId, onNavigate, children }: Props<T>): JSX.Element => {
  // Unmount the fade a short time temporarily to force it to run even if useQuery had cached the result.
  const [showLoading, setShowLoading] = useState(false);

  const currentIndex = items?.findIndex(item => {
    return item.id === currentItemId;
  });

  const handleSwipe = useCallback(
    (direction: string) => {
      if (!items || currentIndex === undefined) {
        return;
      }

      setShowLoading(true);

      let newIndex = 0;
      if (direction === 'right') {
        if (currentIndex === 0) {
          newIndex = items.length - 1;
        } else {
          newIndex = currentIndex - 1;
        }
      } else if (direction === 'left') {
        if (currentIndex !== items.length - 1) {
          newIndex = currentIndex + 1;
        }
      }
      onNavigate(items[newIndex]);
      setShowLoading(false);
    },
    [items, currentIndex, onNavigate],
  );
  const swipeHandlers = useSwipeable({
    onSwipedRight: () => handleSwipe('right'),
    onSwipedLeft: () => handleSwipe('left'),
  });

  return (
    <Box {...swipeHandlers}>
      {!showLoading && (
        <Fade in={true} timeout={500} appear={true} exit={true}>
          <Box>{children}</Box>
        </Fade>
      )}
    </Box>
  );
};
