import { CouponsV2UserOrderDTOStatusEnum } from 'probonio-shared-ui/api';
import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { LoadingLayout } from '../../component/layout/LoadingLayout';
import { CouponsShowVoucherModal } from '../couponsShared/components/CouponsShowVoucherModal';
import { useCouponOrderQuery, useRedeemCouponMutation } from './couponQueries';
import { useCouponOrderVoucherDetails } from './useCouponOrderVoucherDetails';

export const CouponsShowVoucherPage: React.FC = () => {
  const { couponOrderId } = useParams();
  const { data: couponOrder } = useCouponOrderQuery(couponOrderId!);
  const redeemCallback = useRedeemCouponMutation(couponOrder!);

  const isCouponPending =
    couponOrder?.status === CouponsV2UserOrderDTOStatusEnum.Pending || couponOrder?.status === CouponsV2UserOrderDTOStatusEnum.Ordered;

  const voucherDetails = useCouponOrderVoucherDetails(couponOrder);

  const handleConfirm = useCallback(() => {
    if (couponOrder) {
      redeemCallback({ shouldBeRedeemed: !couponOrder.redeemDate });
    }
  }, [couponOrder, redeemCallback]);

  if (!couponOrder || !voucherDetails) {
    return <LoadingLayout />;
  }

  return (
    <CouponsShowVoucherModal
      amount={couponOrder.amount}
      isCouponPending={isCouponPending}
      couponDefinition={couponOrder.couponDefinition}
      voucherDetails={voucherDetails}
      handleConfirmRedeemed={handleConfirm}
      onUpdate={redeemCallback}
      redeemDate={couponOrder.redeemDate}
    />
  );
};
