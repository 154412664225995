import { Checkbox, FormControlLabel } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AvailableGiftDTO, CouponDefinitionDTO } from '../../../../probonio-shared-ui/api';
import { apis } from '../../../../probonio-shared-ui/module/api';
import { useActiveEmployment } from '../../../../probonio-shared-ui/module/me';
import { canBeDefaultCoupon, getCouponAvailability, hasRequiredFlexVariations } from '../couponsShared/couponCombinations';
import { useAvailableCoupons } from './AvailableCouponsContext';

interface Props {
  state: DefaultCouponState;
}

export interface DefaultCouponState {
  // does the current coupon / availability qualify as default?
  allowChange: boolean;
  // is the current coupon already selected as default by employee?
  currentIsDefault: boolean;
  // is the user required to set the default coupon (because none is set yet)?
  forceChange: boolean;
  // checkbox state
  changeDefaultCoupon: boolean;
  isLoading?: boolean;
  setChangeDefaultCoupon: (value: boolean) => void;
  handleConfirm: () => void;
}

export function useDefaultCouponState(coupon?: CouponDefinitionDTO, gift?: AvailableGiftDTO): DefaultCouponState {
  const { availableCoupons, couponsQuery, refetchAvailableCoupons } = useAvailableCoupons();
  const couponBenefitDetails = couponsQuery.data?.details;
  const getActiveEmployment = useActiveEmployment();

  const defaultCouponId = couponBenefitDetails?.defaultCouponId;
  const [changeDefaultCoupon, setChangeDefaultCoupon] = useState(() => {
    if (gift || !couponBenefitDetails?.couponDefaultAllowed) {
      return false;
    }

    return !defaultCouponId;
  });

  const budget = gift ? gift.amount : availableCoupons?.couponMonthlyBudget || 0;
  const availability = coupon && getCouponAvailability(coupon, budget);
  const hasRequiredVariations = couponBenefitDetails?.isFlexBenefit ? hasRequiredFlexVariations(coupon) : true;

  const mutationEmployee = useMutation({
    mutationFn: async () => {
      await apis.coupons.updateCouponEmployeeDefault({
        tenantId: getActiveEmployment().tenantId,
        employeeId: getActiveEmployment().id,
        updateEmployeeCouponDefaultDTO: {
          defaultCouponId: coupon?.id,
        },
      });
    },

    onSuccess: () => {
      refetchAvailableCoupons();
    },
  });

  /*
   * only show checkbox if :
   * the coupon isn't the default coupon
   * it qualifies for the default coupon
   * it has the variations required for flex budget (if active)
   * its not a gift
   * automatic coupons aren't disabled
   */
  const allowChange =
    !!availability && canBeDefaultCoupon(availability) && hasRequiredVariations && !gift && !!couponBenefitDetails?.couponDefaultAllowed;

  const handleConfirm = useCallback(() => {
    if (changeDefaultCoupon && allowChange) {
      mutationEmployee.mutate();
    }
  }, [changeDefaultCoupon, allowChange, mutationEmployee]);

  return useMemo(
    (): DefaultCouponState => ({
      allowChange,
      currentIsDefault: coupon?.id === defaultCouponId,
      forceChange: !couponBenefitDetails?.defaultCouponId,
      changeDefaultCoupon,
      isLoading: mutationEmployee.isPending,
      setChangeDefaultCoupon,
      handleConfirm,
    }),
    [
      allowChange,
      changeDefaultCoupon,
      coupon?.id,
      couponBenefitDetails?.defaultCouponId,
      defaultCouponId,
      handleConfirm,
      mutationEmployee.isPending,
    ],
  );
}

export const ChangeDefaultCouponCheckbox: React.FC<Props> = ({ state }) => {
  const { t } = useTranslation('couponsModule');

  const handleChange = useCallback(() => {
    state.setChangeDefaultCoupon(!state.changeDefaultCoupon);
  }, [state]);

  return state.allowChange && !state.currentIsDefault ? (
    <FormControlLabel
      sx={{ marginRight: 0, marginBottom: 1 }}
      control={<Checkbox onChange={handleChange} disabled={state.forceChange} checked={state.changeDefaultCoupon} />}
      label={t('exploreCoupons.details.defaultCouponMessage')}
    />
  ) : null;
};
