import { Box, Button, Typography } from '@mui/material';
import { LoadingButton } from 'probonio-shared-ui/component/button/LoadingButton';
import { useTranslation } from 'react-i18next';
import { BasicDialog, DialogState } from '../../../component/dialog/BasicDialog';

export const CouponRedeemedModal: React.FC<{
  dialogState: DialogState;
  handleConfirm: () => void;
  handleCancel?: () => void;
  isLoading?: boolean;
}> = ({ dialogState, handleConfirm, handleCancel, isLoading }) => {
  const { t } = useTranslation('couponsModule');

  return (
    <Box>
      <BasicDialog
        title={t('couponRedeemedModal.title')}
        dialogState={dialogState}
        content={
          <Box paddingBottom={0.6}>
            <Typography>{t('couponRedeemedModal.message')}</Typography>
          </Box>
        }
        dataTestId="coupon-redeemed-modal"
        actions={
          <Box display="flex" justifyContent="space-between" width={'100%'}>
            <Button disabled={isLoading} onClick={handleCancel || dialogState.handleClose} data-test-id="coupon-redeemed-modal-cancel">
              {t('couponRedeemedModal.cancel')}
            </Button>
            <LoadingButton loading={isLoading} onClick={handleConfirm} data-test-id="coupon-redeemed-modal-confirm">
              {t('couponRedeemedModal.confirm')}
            </LoadingButton>
          </Box>
        }
      />
    </Box>
  );
};
