import ErrorIcon from '@mui/icons-material/Error';
import { Box, CircularProgress, LinearProgress, linearProgressClasses, styled, Typography, useTheme } from '@mui/material';
import { BenefitDTOBenefitEnum } from 'probonio-shared-ui/api';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonTab } from '../../component/button/CircularFloatingButton';
import { ProgressMoney } from '../benefit/progressAmount/ProgressMoney';
import { useLunchPeriods } from './LunchPeriodsContext';

const StyledLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  backgroundColor: theme.palette.greyBackground,

  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
  },
}));

interface Props {
  selectedTab: ButtonTab;
}

export const LunchAmount: React.FC<Props> = ({ selectedTab }) => {
  const { t } = useTranslation('benefitModule');
  const theme = useTheme();

  const calculateProgressPercent = useCallback((count?: number, maxCount?: number) => {
    if (count === undefined || maxCount === undefined) {
      return 0;
    }
    return Math.min((count / maxCount) * 100, 100);
  }, []);

  const {
    currentPeriod,
    lunchPeriodsQuery: { isLoading, isSuccess, isError },
  } = useLunchPeriods();

  const ProgressAmount: React.FC = () => {
    return (
      <Box marginX={2}>
        <Box display="flex" justifyContent="space-between" marginBottom={0.3}>
          <Typography variant="h2"> {Math.min(currentPeriod?.count || 0, currentPeriod?.maxCount || 0)} </Typography>
          <Typography variant="paragraph">{t('benefit.LUNCH.lunchAmount.progressAmount.submitMessage')}</Typography>
          <Typography variant="h2">{currentPeriod?.maxCount || 0} </Typography>
        </Box>
        <StyledLinearProgress variant="determinate" value={calculateProgressPercent(currentPeriod?.count, currentPeriod?.maxCount)} />
        <Box display="flex" justifyContent="space-between" marginTop={0.3}>
          <Typography variant="paragraph">{t('benefit.LUNCH.lunchAmount.progressAmount.progress')}</Typography>
          <Typography variant="paragraph">{t('benefit.LUNCH.lunchAmount.progressAmount.total')}</Typography>
        </Box>
      </Box>
    );
  };

  return (
    <Box sx={{ textAlign: 'center', paddingBottom: 0.5, paddingTop: 0.2, backgroundColor: theme.palette.background.paper }}>
      {isLoading && <CircularProgress />}

      {selectedTab === ButtonTab.right && isSuccess && (
        <ProgressMoney
          refund={currentPeriod?.refund || 0}
          maxRefund={currentPeriod?.maxRefund || 0}
          benefit={BenefitDTOBenefitEnum.Lunch}
        />
      )}
      {selectedTab === ButtonTab.left && isSuccess && <ProgressAmount />}
      {isError && <ErrorIcon color="error" fontSize="large" />}
    </Box>
  );
};
