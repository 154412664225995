import { useTheme } from '@mui/material';
import { useSnackbar } from 'notistack';
import { CreateLunchReceiptDTOReceiptTypeEnum, LunchPeriodDTO, LunchReceiptOCRAnalysisDTOCountryCodeEnum } from 'probonio-shared-ui/api';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { BackIconButton } from '../../../component/button/BackIconButton';
import { CloseIconButton } from '../../../component/button/CloseIconButton';
import { useImageURI } from '../../../component/camera/useImageUri';
import { useNewDialogState } from '../../../component/dialog/BasicDialog';
import { ImageZoomer } from '../../../component/imageZoomer/ImageZoomer';
import { MobileLayout } from '../../../component/layout';
import { AppToolbar } from '../../../component/layout/AppToolbar';
import { useStepperState } from '../../../component/stepper/useStepperState';
import { capitalize } from '../../../utils/capitalize';
import { DiscardFormConfirmation } from '../../benefit/addReceipt/DiscardFormConfirmation';
import { ImageSelectionState } from '../../shareTarget/useImageSelection';
import MissingCriteriaModal, { MissingCriteria } from '../components/MissingCriteriaModal';
import { OCRLoadingLayout } from './OCRLoadingLayout';
import { ReceiptFormLineItems } from './ReceiptFormLineItems';
import { ReceiptFormStep1, ReceiptStep1Value } from './ReceiptFormStep1';
import { ReceiptFormStep2, ReceiptStep2Value } from './ReceiptFormStep2';
import { ReceiptFormStep3, ReceiptStep3Value } from './ReceiptFormStep3';
import { useAnalyzeReceiptWithOCR } from './useAnalyzeReceipt';
import { mapToCreateLunchReceiptRequest, useCreateLunchReceipt } from './useCreateLunchReceipt';

interface Props {
  imageSelection: ImageSelectionState;
  previousPeriod: LunchPeriodDTO | undefined;
  currentPeriod: LunchPeriodDTO | undefined;
  refetch: () => void;
}

export const AddReceiptFlow: React.FC<Props> = ({ refetch, currentPeriod, previousPeriod, imageSelection }) => {
  const { t } = useTranslation('benefitModule');
  const theme = useTheme();
  const [step1Value, setStep1Value] = useState<ReceiptStep1Value>();
  const [step2Value, setStep2Value] = useState<ReceiptStep2Value>();
  const [step3Value, setStep3Value] = useState<ReceiptStep3Value>();
  const cancelConfirmation = useNewDialogState();
  const { activeStep, handleNext, handlePrevious } = useStepperState(cancelConfirmation.handleOpen);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const imageURI = useImageURI(imageSelection.selectedImage!);

  const { ocrAnalysis, isLoading: isOCRLoading, imageKey: ocrImageKey } = useAnalyzeReceiptWithOCR(imageSelection.selectedImage);
  const [showLineItemsUI, setShowLineItemsUI] = useState(false);
  const [ocrSkipped, setOCRSkipped] = useState(false);
  const [missingCriterias, setMissingCriterias] = useState<MissingCriteria[]>([]);
  const missingCriteriaDialog = useNewDialogState();

  useEffect(() => {
    const criterias = [];
    if (ocrAnalysis?.analysisResult === 'NO_RECEIPT' && ocrAnalysis.errorReason === 'NO_DATE') {
      criterias.push(MissingCriteria.DATE);
    } else if (ocrAnalysis?.analysisResult === 'NO_RECEIPT' && ocrAnalysis.errorReason === 'NO_TOTAL') {
      criterias.push(MissingCriteria.TOTAL);
    }

    if (ocrAnalysis && ocrAnalysis.countryCode !== LunchReceiptOCRAnalysisDTOCountryCodeEnum.De) {
      criterias.push(MissingCriteria.COUNTRY);
    }
    if (criterias.length) {
      setMissingCriterias(criterias);
      missingCriteriaDialog.handleOpen();
    }
    if (ocrAnalysis?.analysisResult === 'SUCCESS' && ocrAnalysis.total && !ocrSkipped) {
      if (ocrAnalysis.lineItems.length) {
        // select line items
        setShowLineItemsUI(true);
      } else if (!ocrSkipped) {
        // use only total for old workflow
        setStep1Value({ total: ocrAnalysis.total, operatorArray: [], valuesArray: [ocrAnalysis.total] });
      }
      if (
        ocrAnalysis.date &&
        (ocrAnalysis.date.substring(0, 7) === currentPeriod?.month ||
          (!previousPeriod?.isLocked && ocrAnalysis.date.substring(0, 7) === previousPeriod?.month))
      ) {
        setStep2Value({ date: new Date(ocrAnalysis.date) });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ocrAnalysis]);

  const handleFinalBack = useCallback((): void => {
    navigate(-1);
  }, [navigate]);

  const mutation = useCreateLunchReceipt(imageSelection, () => {
    refetch();
    enqueueSnackbar(t(`benefit.LUNCH.addReceiptFlow.createReceiptSuccess`), { variant: 'success' });
    handleFinalBack();
  });

  const handleStep1 = useCallback(
    (value: ReceiptStep1Value) => {
      setStep3Value(value3 => {
        const hasCalculation = value.valuesArray.length > 1;
        const userDidChangeComment = !!value3?.userDidChangeComment && value3.comment !== '';

        let comment: string;
        if (ocrAnalysis && value.lineItems) {
          comment = value.lineItems
            .map(selectedItem => {
              const ocrLineItem = ocrAnalysis.lineItems.find(item => item.id === selectedItem.ocrLineItemId)!;
              const total =
                ocrLineItem.quantity > 1 && ocrLineItem.unitPrice ? selectedItem.quantity * ocrLineItem.unitPrice : ocrLineItem.total;
              return `${selectedItem.quantity}x ${capitalize(ocrLineItem.name)} - ${t('common:money', { money: total })}`;
            })
            .join('\n');
        } else if (!hasCalculation || userDidChangeComment) {
          // if theres one input-value write no comment
          // if the comment is edited don't change it even if the calculation is changed
          comment = value3?.comment || '';
        } else {
          // if the calculation changes in the step before, change the comment as well
          comment = value.comment || value3?.comment || '';
        }

        // check benefit and set receiptType accordingly
        let lunchReceiptTypeDefault: CreateLunchReceiptDTOReceiptTypeEnum | undefined;
        if (hasCalculation || value.lineItems) {
          lunchReceiptTypeDefault = CreateLunchReceiptDTOReceiptTypeEnum.Mixed;
        }

        return {
          ...value3,
          comment,
          lunchReceiptType: value3?.lunchReceiptType || lunchReceiptTypeDefault,
          userDidChangeComment,
        };
      });

      setStep1Value(value);
      handleNext();
    },
    [handleNext, ocrAnalysis, t],
  );
  const handleStep2 = useCallback(
    (value: ReceiptStep2Value) => {
      setStep2Value(value);
      handleNext();
    },
    [handleNext],
  );
  const handleStep3 = useCallback(
    (value: ReceiptStep3Value) => {
      setStep3Value(value);
      const receiptToCreate = mapToCreateLunchReceiptRequest(step1Value!, step2Value!, value, ocrAnalysis?.ocrResultId, ocrImageKey);
      if (receiptToCreate) {
        mutation.mutate(receiptToCreate);
      }
    },
    [step1Value, step2Value, ocrAnalysis?.ocrResultId, ocrImageKey, mutation],
  );
  const handleChangeStep3 = useCallback((value: ReceiptStep3Value) => {
    setStep3Value(value);
  }, []);

  const handleHideLineItemsUI = useCallback(() => {
    setStep1Value(undefined);
    setStep3Value(undefined);
    setShowLineItemsUI(false);
  }, []);

  const handleSkipOCR = useCallback(() => {
    setOCRSkipped(true);
  }, []);

  if (isOCRLoading && !ocrSkipped) {
    return <OCRLoadingLayout onSkip={handleSkipOCR} />;
  }

  return (
    <>
      <DiscardFormConfirmation dialogState={cancelConfirmation.dialogState} onSubmit={handleFinalBack} />
      <MobileLayout
        paperBackground
        header={
          <AppToolbar
            absolute={activeStep !== 2}
            invert={activeStep !== 2}
            title={activeStep === 2 ? t(`benefit.LUNCH.addReceiptFlow.addReceiptSummary`) : ''}
            backButton={<BackIconButton onClick={handlePrevious} color="inherit" />}
            actionButton={<CloseIconButton onClick={cancelConfirmation.handleOpen} color="inherit" />}
          />
        }
      >
        {(activeStep === 0 || activeStep === 1) && (
          <ImageZoomer imageUrl={imageURI} imageName="receipt" wrapperStyle={{ backgroundColor: theme.palette.background.dark }} />
        )}
        {activeStep === 0 && !showLineItemsUI && <ReceiptFormStep1 value={step1Value} onChange={handleStep1} />}
        {activeStep === 0 && ocrAnalysis && showLineItemsUI && (
          <ReceiptFormLineItems ocrAnalysis={ocrAnalysis} value={step1Value} onChange={handleStep1} onCancel={handleHideLineItemsUI} />
        )}
        {activeStep === 1 && (
          <ReceiptFormStep2
            previousPeriod={previousPeriod}
            currentPeriod={currentPeriod}
            value={step2Value}
            onChange={handleStep2}
            onBack={handlePrevious}
          />
        )}
        {activeStep === 2 && (
          <ReceiptFormStep3
            loading={mutation.isPending}
            previousValues={{ ...step1Value!, ...step2Value! }}
            ocrAnalysis={ocrAnalysis}
            value={step3Value}
            onSubmit={handleStep3}
            onChange={handleChangeStep3}
          />
        )}
      </MobileLayout>
      <MissingCriteriaModal
        onSubmit={missingCriteriaDialog.dialogState.handleClose}
        missingCriterias={missingCriterias}
        dialogState={missingCriteriaDialog.dialogState}
        handleReuploadImage={imageSelection.handleChangeImage}
      />
    </>
  );
};
