import { Capacitor } from '@capacitor/core';
import { Backdrop, Box, Button, CircularProgress, Typography, useTheme } from '@mui/material';
import { useLoginContext } from '@tiffinger-thiel/appauth-react';
import { BackGesture } from '@tiffinger-thiel/capacitor-back-gesture';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import MessageLayout from '../component/layout/MessageLayout';
import { JailbreakRootWarningDialog } from '../component/jailbreakRootWarning/JailbreakRootWarningDialog';
import UnsupportedBrowserBadge from '../component/unsupportedBrowserBadge/UnsupportedBrowserBadge';

const WelcomePage: React.FC = () => {
  const { login, isReady, isLoggedIn } = useLoginContext();
  const { t } = useTranslation('welcomePage');
  const theme = useTheme();
  const navigate = useNavigate();

  useEffect(() => {
    if (Capacitor.isNativePlatform()) {
      void BackGesture.deactivate().catch(() => {});
    }
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      navigate('/home', { replace: true });
    }
  }, [isLoggedIn, navigate]);

  const handleLogin = useCallback(() => login(), [login]);

  const isAndroid = Capacitor.getPlatform() === 'android';

  return (
    <>
      {/**
       * used to hide the backgroundColor when in Login InAppBrowser
       * is initially hidden with opacity 0 and changed to opacity 1 when needed
       */}
      <div
        id="backgroundHider"
        style={{
          backgroundColor: theme.palette.primary.contrastText,
          opacity: 0,
          position: 'absolute',
          width: '100%',
          height: '100%',
          zIndex: 10,
          transition: 'opacity 1000ms ease-in',
        }}
      />
      <MessageLayout title={t('title')} text={t('text')}>
        <Box sx={{ flexGrow: 0, padding: 1.5, textAlign: 'center', width: '100%' }}>
          <UnsupportedBrowserBadge />
          {isReady ? (
            <Button
              data-test-id="button-to-login"
              variant="contained"
              aria-label={t('login')}
              color="primary"
              onClick={handleLogin}
              fullWidth
              sx={{
                marginBottom: 1,
              }}
            >
              {t('login')}
            </Button>
          ) : (
            <Backdrop sx={{ color: 'white', zIndex: theme => theme.zIndex.drawer + 1 }} open={!isReady}>
              <CircularProgress />
            </Backdrop>
          )}
          {/* diable register code flow for android due to google policy issues */}
          {!isAndroid && (
            <Typography variant="body1" color="text.secondary" textAlign="center" marginBottom={0.5}>
              <Link style={{ color: theme.palette.primary.main }} to={'/register/code'}>
                {t('receivedCode')}
              </Link>
            </Typography>
          )}
          <Typography variant="body1" color="text.secondary" textAlign="center" marginBottom={0.5}>
            <a href="https://www.probonio.de/support" target="_blank" rel="noreferrer" style={{ color: theme.palette.primary.main }}>
              {t('loginIssues')}
            </a>
          </Typography>
          <Typography variant="body1" color="text.secondary" textAlign="center" marginBottom={0.5}>
            <a
              href="https://www.probonio.de/kostenlos-testen"
              target="_blank"
              rel="noreferrer"
              style={{ color: theme.palette.primary.main }}
            >
              {t('testAccount')}
            </a>
          </Typography>
        </Box>
        <JailbreakRootWarningDialog />
      </MessageLayout>
    </>
  );
};

export default WelcomePage;
