import { apis } from 'probonio-shared-ui/module/api';
import { uploadFileToS3 } from 'probonio-shared-ui/module/api';
import { MIME_TYPE_JPEG } from './lunchConstants';
import { UploadLinkDTO } from 'probonio-shared-ui/api';
const CONTENT_TYPE_PDF = 'application/pdf';

export interface UploadLinks {
  imageKey?: string;
  pdfKey?: string;
  thumbnailKey?: string;
}

export async function uploadLunchImageFile(
  tenantId: string,
  employeeId: string,
  mimetype?: string,
  imageFile?: Blob,
  pdfFile?: Blob,
  thumbnailFile?: Blob,
): Promise<UploadLinks> {
  let uploadLink: UploadLinkDTO | undefined;
  if (mimetype && imageFile) {
    uploadLink = await apis.lunch.createUploadLink({ tenantId, employeeId }).then(res => res.data);
    await uploadFileToS3(uploadLink!, imageFile, mimetype);
  }

  let thumbnailKey;
  if (thumbnailFile) {
    const thumbUploadLink = await apis.lunch.createUploadLink({ tenantId, employeeId }).then(res => res.data);
    await uploadFileToS3(thumbUploadLink, thumbnailFile, MIME_TYPE_JPEG);
    thumbnailKey = thumbUploadLink.key;
  }

  let pdfKey;
  if (pdfFile) {
    const uploadLinkPdf = await apis.lunch.createUploadLink({ tenantId, employeeId, contentType: CONTENT_TYPE_PDF }).then(res => res.data);
    await uploadFileToS3(uploadLinkPdf, pdfFile, pdfFile.type);
    pdfKey = uploadLinkPdf.key;
  }

  return { imageKey: uploadLink?.key, pdfKey, thumbnailKey };
}
