import { useTheme } from '@mui/material';

import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { BackIconButton } from '../../component/button/BackIconButton';
import { MobileLayout } from '../../component/layout';
import { AppToolbar } from '../../component/layout/AppToolbar';
import { CircularHeader } from '../../component/button/CircularFloatingButton/CircularHeader';
import { HighlightGuideButton } from '../../module/guiding';
import { useBackNavigator } from '../../module/navigation/BackNavigator';
import { BAVContractInfo } from '../../module/bav/BAVContractInfo';
import { BAVContractDTO } from 'probonio-shared-ui/api';
import { BAVAmount } from './BAVAmount';

interface Props {
  contract: BAVContractDTO;
}

export const BAVContractPage: React.FC<Props> = ({ contract }) => {
  const theme = useTheme();
  const backNavigator = useBackNavigator();
  const navigate = useNavigate();
  const { t } = useTranslation('benefitModule');
  const navigateInfo = useCallback(() => {
    navigate('info');
  }, [navigate]);

  return (
    <MobileLayout
      header={
        <AppToolbar
          title={t('benefit.BAV.name')}
          color={theme.palette.primary.contrastText}
          backButton={<BackIconButton onClick={backNavigator.back} />}
          actionButton={
            <HighlightGuideButton
              name={t('benefit.BAV.name')}
              header={t('benefit.BAV.firstHelpGuide.header')}
              text={t('benefit.BAV.firstHelpGuide.text')}
              onClick={navigateInfo}
            />
          }
        />
      }
    >
      <BAVAmount contract={contract} />
      <CircularHeader borderColor={theme.palette.primary.main} />
      <BAVContractInfo contract={contract} />
    </MobileLayout>
  );
};
