import { Route, Routes } from 'react-router-dom';
import { CouponsV2ShowVoucherPage } from '../../module/couponsV2/CouponsV2ShowVoucherPage';
import CouponsV2InfoPage from './CouponsV2InfoPage';
import { CouponsV2OrderPage } from './CouponsV2OrderPage';
import CouponsV2Page from './CouponsV2Page';
import { CouponsV2TokenPage } from './CouponsV2TokenPage';
import ExploreCouponsV2DetailPage from './ExploreCouponsV2DetailPage';
import ExploreCouponsV2Page from './ExploreCouponsV2Page';

const CouponsV2Routes: React.FC = () => (
  <Routes>
    <Route path="/info" element={<CouponsV2InfoPage />} />
    <Route path="/" element={<CouponsV2Page />} />
    <Route path="/:tabName" element={<CouponsV2Page />} />
    <Route path="/orders/:couponOrderId/showVoucher" element={<CouponsV2ShowVoucherPage />} />
    <Route path="/orders/:couponOrderId" element={<CouponsV2OrderPage />} />
    <Route path="/tokens/:userTokenId" element={<CouponsV2TokenPage />} />
    <Route path="/explore-coupons" element={<ExploreCouponsV2Page />} />
    <Route path="/explore-coupons/:couponId" element={<ExploreCouponsV2DetailPage />} />
  </Routes>
);

export default CouponsV2Routes;
