import { Box, Button } from '@mui/material';
import { BAVContractDTO, BAVContractDTOStatusEnum } from 'probonio-shared-ui/api';
import { useTranslation } from 'react-i18next';
import { useNewDialogState } from '../../component/dialog/BasicDialog';
import { BAVContractDocuments } from './BAVContractDocuments';
import { BAVRequestContactDialog } from './BAVRequestContactDialog';
import { InfoAccordion } from '../../component/infoAccordion/InfoAccordion';
import { InfoRow } from '../../component/infoAccordion/InfoRow';

interface Props {
  contract: BAVContractDTO;
}

export const BAVContractInfo: React.FC<Props> = ({ contract }) => {
  const { t } = useTranslation('benefitModule');
  const contactDialog = useNewDialogState();
  const getContractStatusColor = (status: BAVContractDTOStatusEnum): string => {
    switch (status) {
      case BAVContractDTOStatusEnum.Active:
        return 'success.main';
      case BAVContractDTOStatusEnum.Suspended:
        return 'warning.main';
      case BAVContractDTOStatusEnum.Cancelled:
        return 'error.main';
      case BAVContractDTOStatusEnum.Transferred:
        return 'text.secondary';
    }
  };

  return (
    <Box paddingX={1} paddingBottom={2}>
      <Box>
        <InfoAccordion title={t(`benefit.BAV.employeeData.header`)}>
          <InfoRow label={t(`benefit.BAV.employeeData.salutation`)} info={`${contract.salutation}`} />
          {contract.title && <InfoRow label={t(`benefit.BAV.employeeData.title`)} info={`${contract.title}`} />}
          <InfoRow label={t(`benefit.BAV.employeeData.firstName`)} info={`${contract.firstName}`} />
          <InfoRow label={t(`benefit.BAV.employeeData.lastName`)} info={`${contract.lastName}`} />
          <InfoRow label={t(`benefit.BAV.employeeData.dateOfBirth`)} info={t(`common:date`, { date: new Date(contract.dateOfBirth) })} />

          <InfoRow label={t(`benefit.BAV.employeeData.address`)} />
          {contract.street && <InfoRow label={t(`benefit.BAV.employeeData.street`)} info={contract.street} />}
          {contract.postalCode && <InfoRow label={t(`benefit.BAV.employeeData.postalCode`)} info={`${contract.postalCode}`} />}
          {contract.city && <InfoRow label={t(`benefit.BAV.employeeData.city`)} info={`${contract.city}`} />}
        </InfoAccordion>
      </Box>

      <Box pt={1}>
        <InfoAccordion defaultExpanded title={t(`benefit.BAV.contractFields.title`)}>
          <InfoRow label={t(`benefit.BAV.contractFields.insuranceName`)} info={contract.insuranceName} />
          <InfoRow label={t(`benefit.BAV.contractFields.tariffName`)} info={contract.tariffName} />
          <InfoRow label={t(`benefit.BAV.contractFields.contractNumber`)} info={contract.contractNumber} />
          <InfoRow label={t(`benefit.BAV.contractFields.startDate`)} info={t(`common:date`, { date: new Date(contract.startDate) })} />

          <InfoRow
            label={t(`benefit.BAV.contractFields.status`)}
            info={t(`benefit.BAV.contractFields.statusType.${contract.status}`)}
            color={getContractStatusColor(contract.status)}
          />
        </InfoAccordion>
      </Box>

      <BAVContractDocuments contract={contract} />

      <Box pt={1} flex={1}>
        <Button fullWidth variant="contained" onClick={contactDialog.handleOpen}>
          {t(`benefit.BAV.requestContact`)}
        </Button>
      </Box>

      <BAVRequestContactDialog contract={contract} dialogState={contactDialog.dialogState} />
    </Box>
  );
};
