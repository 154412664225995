import { Filesystem } from '@capacitor/filesystem';

export async function uriToBlob(dataUrl: string, type?: string): Promise<Blob | undefined> {
  const mimeType = dataUrl.substring(dataUrl.indexOf(':') + 1, dataUrl.indexOf(';'));
  if (!mimeType) return;
  const blob = await Filesystem.readFile({
    path: dataUrl,
  }).then(file => {
    return fetch(`data:${mimeType}'};base64,${file.data}`).then(res => res.blob());
  });
  const typedBlob = new Blob([blob], { type });
  return typedBlob;
}
