import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import DescriptionIcon from '@mui/icons-material/Description';
import HistoryEduOutlinedIcon from '@mui/icons-material/HistoryEduOutlined';
import { Box, Divider, Paper, Stack, Step, StepContent, StepLabel, Stepper, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { InternetReceiptRefundDTO } from 'probonio-shared-ui/api';
import { LoadingButton } from 'probonio-shared-ui/component/button';
import { calculateBlobSHA256, hasStoredHash } from 'probonio-shared-ui/utils/hashStorage';
import React, { useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ButtonRendererProps, CameraButton } from '../../component/camera';
import { ImageFile } from '../../component/camera/ImageFile';
import { ConfirmationModal } from '../../component/confirmationModal';
import { useNewDialogState } from '../../component/dialog/BasicDialog';
import { useAppSelector } from '../../redux/hooks';
import { useDeleteReceiptMutation, useDownloadInternetStatementMutation, useUploadReceiptStatementMutation } from './internetQueries';

interface Props {
  receiptInProgress: InternetReceiptRefundDTO;
  currentReceipt?: InternetReceiptRefundDTO;
}

export const ReceiptInProgressPanel: React.FC<Props> = ({ receiptInProgress, currentReceipt }) => {
  const { t } = useTranslation('internetModule');
  const user = useAppSelector(state => state.me.user);
  const { enqueueSnackbar } = useSnackbar();

  const downloadMutation = useDownloadInternetStatementMutation();
  const mutation = useUploadReceiptStatementMutation();
  const deleteMutation = useDeleteReceiptMutation();

  const confirmDeleteDialog = useNewDialogState();

  const handleDownload = useCallback(() => {
    downloadMutation.mutate(receiptInProgress.id);
  }, [downloadMutation, receiptInProgress.id]);

  const handleSelectImage = useCallback(
    async (imageFile: ImageFile, pdf?: Blob) => {
      if (pdf) {
        const hash = await calculateBlobSHA256(pdf);
        if (hasStoredHash(['internetConfirmation'], hash)) {
          enqueueSnackbar(t('receiptInProgress.pleaseSign'), { variant: 'error' });
          return;
        }
      }
      if (currentReceipt) {
        await deleteMutation.mutateAsync(currentReceipt.id).catch(() => {});
      }
      mutation.mutate({ receiptId: receiptInProgress!.id, imageFile, pdf });
    },
    [currentReceipt, deleteMutation, enqueueSnackbar, mutation, receiptInProgress, t],
  );

  const handleDelete = useCallback(() => {
    confirmDeleteDialog.dialogState.handleClose();
    deleteMutation.mutate(receiptInProgress!.id);
  }, [confirmDeleteDialog.dialogState, deleteMutation, receiptInProgress]);

  const UploadStatementButton: React.FC<ButtonRendererProps> = useCallback(
    props => {
      return (
        <LoadingButton
          variant="contained"
          loading={mutation.isPending}
          fullWidth
          startIcon={<CameraAltOutlinedIcon />}
          sx={{ marginTop: 1 }}
          {...props}
        >
          {t('receiptInProgress.upload')}
        </LoadingButton>
      );
    },
    [mutation.isPending, t],
  );

  return (
    <Stack spacing={1} mt={1} data-test-id="receipt-in-progress-panel">
      <Paper sx={{ padding: 1 }}>
        <Typography variant="h3" textAlign="center">
          {t('receiptInProgress.title')}
        </Typography>
        <Box textAlign="center" mt={0.5}>
          <HistoryEduOutlinedIcon sx={{ fontSize: 64 }} />
        </Box>
        <Stepper orientation="vertical" connector={<></>}>
          <Step active>
            <StepLabel>{t('receiptInProgress.step1')}</StepLabel>
            <StepContent>
              <LoadingButton
                size="small"
                variant="contained"
                color="secondary"
                startIcon={<DescriptionIcon />}
                sx={{ marginBottom: 0.5, fontSize: theme => theme.typography.body2.fontSize }}
                loading={downloadMutation.isPending}
                onClick={handleDownload}
              >
                {t('receiptInProgress.downloadAgain')}
              </LoadingButton>
            </StepContent>
          </Step>
          <Step active>
            <StepLabel>{t('receiptInProgress.step2')}</StepLabel>
          </Step>
          <Step active>
            <StepLabel>{t('receiptInProgress.step3')}</StepLabel>
          </Step>
        </Stepper>
        <CameraButton buttonRenderer={UploadStatementButton} onSelect={handleSelectImage} isPdfAllowed maxSize={1920} />
        <Typography mt={1} variant="body2" textAlign="center" color="text.secondary" whiteSpace="pre-line">
          <Trans i18nKey="internetModule:receiptInProgress.text" values={{ mail: user?.mail }} />
        </Typography>
        <Divider sx={{ marginY: 1 }} />
        <Stack direction="row" justifyContent="space-between">
          <Typography color="text.secondary">{t('receiptInProgress.monthlyAmount')}</Typography>
          <Typography color="text.secondary">{t('common:money', { money: receiptInProgress.total })}</Typography>
        </Stack>
      </Paper>
      <LoadingButton
        fullWidth
        variant="contained"
        color="secondary"
        loading={deleteMutation.isPending}
        onClick={confirmDeleteDialog.handleOpen}
        data-test-id="cancel-request-button"
      >
        {t('receiptInProgress.cancelRequest')}
      </LoadingButton>
      <ConfirmationModal
        title={t('receiptInProgress.cancelRequest')}
        message={t('receiptInProgress.cancelRequestMessage')}
        onSubmit={handleDelete}
        onCancel={confirmDeleteDialog.dialogState.handleClose}
        isOpen={confirmDeleteDialog.dialogState.isOpen}
      />
    </Stack>
  );
};
