import { Capacitor } from '@capacitor/core';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import CheckIcon from '@mui/icons-material/Check';
import { Box, Card, Divider, LinearProgress, SwipeableDrawer, Typography, useTheme } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { DateTime } from 'luxon';
import {
  BenefitDTO,
  BenefitDTOBenefitEnum,
  BenefitStatisticsDTO,
  CouponBenefitDetailsDTO,
  CouponsV2BenefitDetailsDTO,
  CustomBenefitDetailsDTO,
  FlexBenefitDetailsDTO,
} from 'probonio-shared-ui/api';
import { CUSTOM_BENEFIT_ICONS } from 'probonio-shared-ui/component/icon';
import { apis, useEmployeeQuery } from 'probonio-shared-ui/module/api';
import React, { useEffect, useMemo, useState } from 'react';
import CountUp from 'react-countup';
import { useTranslation } from 'react-i18next';
import { BENEFIT_ICONS } from '../BenefitIcon';

interface BenefitCurrentMonthProps {
  heading: JSX.Element;
  used: number;
  maxRefund: number;
  moreThanLastMonth: number;
}

const BENEFIT_HISTORY_LENGTH = 6;

const BenefitCurrentMonth: React.FC<BenefitCurrentMonthProps> = ({ heading, used, maxRefund, moreThanLastMonth }) => {
  const theme = useTheme();
  const { t } = useTranslation('benefitModule');
  return (
    <Box
      sx={{
        height: '100%',
        paddingX: 0.5,
        paddingY: 0.75,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      <Box>{heading}</Box>
      <Box paddingY={0.5}>
        <Box paddingY={0.25}>
          <Typography variant="description" color="text.secondary">
            {t('visualization.benefitAmountThisMonth')}
          </Typography>
        </Box>
        <Grid container spacing={0.25}>
          <Grid>
            <Typography variant="h2" fontWeight={500} color={used === maxRefund ? theme.palette.success.main : 'text.primary'}>
              <CountUp end={used / 100} duration={1.5} decimals={moreThanLastMonth > 0 ? 0 : 2} decimal="," separator="." useEasing />€
            </Typography>
          </Grid>
          {used === maxRefund ? (
            <Grid>
              <CheckIcon color="success" />
            </Grid>
          ) : (
            moreThanLastMonth > 0 && (
              <Grid>
                <Typography color={theme.palette.success.main} sx={{ display: 'flex', alignItems: 'center' }}>
                  <ArrowDropUpIcon color="success" fontSize="small" />
                  {t('visualization.amount', { amount: moreThanLastMonth })}
                </Typography>
              </Grid>
            )
          )}
        </Grid>
      </Box>
      <Box>
        <Typography variant="body2" color="text.secondary">
          {t('visualization.availableAmount', { amount: maxRefund })}
        </Typography>
      </Box>
    </Box>
  );
};

interface BenefitCurrentMonthCouponsProps {
  details: CouponBenefitDetailsDTO | CouponsV2BenefitDetailsDTO;
}

const BenefitCurrentMonthCoupons: React.FC<BenefitCurrentMonthCouponsProps> = ({ details }) => {
  const theme = useTheme();
  const Icon = BENEFIT_ICONS.COUPONS;
  const { t } = useTranslation('benefitModule');
  return (
    <Box
      sx={{
        height: '100%',
        paddingX: 0.5,
        paddingY: 0.75,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      <Box>
        <Grid container spacing={0.5} alignItems="center">
          <Grid>
            <Icon sx={{ color: theme.palette.primary.main }} />
          </Grid>
          <Grid size={'grow'}>
            <Typography variant="h3" color={theme.palette.primary.main}>
              {t(`benefit.${BenefitDTOBenefitEnum.Coupons}.name`)}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      {details.mostUsedCoupon ? (
        <>
          <Box>
            <Typography variant="description" color="text.secondary">
              {t('visualization.coupon.mostFrequentlyChosen')}
            </Typography>
          </Box>
          <Box paddingY={0.5}>
            <Typography variant="paragraph" fontWeight={500}>
              {details.mostUsedCoupon.name}
            </Typography>
          </Box>
          <Typography color={theme.palette.primary.light} variant="body2">
            {t('visualization.coupon.timesChosen', { count: details.mostUsedCoupon.timesUsed })}
          </Typography>
        </>
      ) : (
        <Box paddingY={0.75}>
          <Typography variant="description" color="text.secondary">
            {t('visualization.coupon.noCoupons')}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

interface BenefitsProgressBarProps {
  month: string;
  progress: number;
  totalValue: number;
}

const BenefitsProgressBar: React.FC<BenefitsProgressBarProps> = ({ month, progress, totalValue }) => {
  const theme = useTheme();
  const [progressValue, setProgressValue] = useState(0);
  useEffect(() => {
    setTimeout(() => setProgressValue(progress), 100);
  }, [progress]);

  const calculatedValue = Math.min(progressValue / totalValue, 1);

  return (
    <Grid container>
      <Grid size={12} sx={{ display: 'flex', justifyContent: 'center' }}>
        <LinearProgress
          variant="determinate"
          value={progressValue}
          sx={{
            width: '15px',
            borderRadius: '5px',
            height: 45,
            backgroundColor: theme.palette.greyBackground,
            '& span.MuiLinearProgress-bar': {
              transform: `translateY(${100 - calculatedValue * 100}%) !important`, //has to have !important // add multiplier 0.9 so the bar never reaches the top
              transition: 'transform 1.5s ease-in',
              borderRadius: '5px',
              backgroundColor: theme.palette.primary.main,
            },
          }}
        />
      </Grid>
      <Grid size={12} sx={{ display: 'flex', justifyContent: 'center' }}>
        <Typography color="text.secondary">{month}</Typography>
      </Grid>
    </Grid>
  );
};

interface BenefitLastMonthsI {
  history: number[];
}

const BenefitLastMonths: React.FC<BenefitLastMonthsI> = ({ history }) => {
  const { t } = useTranslation('benefitModule');
  const fullRefund = history.reduce((previousValue, currentValue) => previousValue + currentValue, 0);

  return (
    <Box sx={{ paddingX: 0.5, paddingY: 0.75 }}>
      <Box>
        <Typography variant="description" color="text.secondary">
          {t('visualization.benefitAmountOverall')}
        </Typography>
      </Box>
      <Box paddingTop={0.25}>
        <Typography variant="h2" color="text.primary">
          <CountUp end={fullRefund / 100} duration={1.5} decimals={2} decimal="," separator="." useEasing />€
        </Typography>
      </Box>
      <Grid container justifyContent="space-between" sx={{ paddingTop: 0.75 }}>
        {history.map((month, index) => {
          return (
            <React.Fragment key={index}>
              {index > 0 && (
                <Grid>
                  <BenefitsProgressBar
                    month={DateTime.now()
                      .minus({ months: history.length - index - 1 })
                      .setLocale('de')
                      .toFormat('LLLLL')}
                    progress={month}
                    totalValue={Math.max(...history)}
                  />
                </Grid>
              )}
            </React.Fragment>
          );
        })}
      </Grid>
    </Box>
  );
};

interface VisualizationBenefitItemI {
  benefit: BenefitDTO;
  leftCard: JSX.Element;
  rightCard: JSX.Element;
}

const VisualizationBenefitItem: React.FC<VisualizationBenefitItemI> = ({ leftCard, rightCard }) => {
  return (
    <Grid sx={{ width: '100%' }}>
      <Grid container>
        <Grid size={5.7}>
          <Box sx={{ height: '100%' }}>{leftCard}</Box>
        </Grid>
        <Divider orientation="vertical" flexItem variant="middle" sx={{ marginX: '-1px' }} />
        <Grid size={5.7}>
          <Box sx={{ height: '100%' }}>{rightCard}</Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

interface BenefitItemContainerProps {
  benefit: BenefitDTO;
}

const BenefitItemContainer: React.FC<BenefitItemContainerProps> = ({ benefit }) => {
  const theme = useTheme();
  const { t } = useTranslation('benefitModule');
  switch (benefit.benefit) {
    case BenefitDTOBenefitEnum.Coupons:
    case BenefitDTOBenefitEnum.CouponsV2:
      const detailsCoupons = benefit.details as CouponBenefitDetailsDTO | CouponsV2BenefitDetailsDTO;
      return (
        <VisualizationBenefitItem
          benefit={benefit}
          leftCard={<BenefitCurrentMonthCoupons details={detailsCoupons} />}
          rightCard={<BenefitLastMonths history={benefit.statistics!.history} />}
        />
      );
    default:
      if (!benefit.statistics) {
        // Custom benefits may be deactivated.
        return null;
      }
      const history = benefit.statistics.history;
      let Icon = BENEFIT_ICONS[benefit.benefit];
      if (benefit.benefit === BenefitDTOBenefitEnum.Custom) {
        Icon = CUSTOM_BENEFIT_ICONS[(benefit.details as CustomBenefitDetailsDTO).iconName];
      }

      const moreThanLastMonth = history[history.length - 1] - history[history.length - 2];
      return (
        <VisualizationBenefitItem
          benefit={benefit}
          leftCard={
            <BenefitCurrentMonth
              heading={
                <Grid container spacing={0.5} alignItems="center">
                  <Grid>
                    <Icon sx={{ color: theme.palette.primary.main }} />
                  </Grid>
                  <Grid size="grow">
                    <Typography variant="h3" color={theme.palette.primary.main} sx={{ hyphens: 'auto' }}>
                      {t(`benefit.${benefit.benefit}.name`, { ...benefit.details })}
                    </Typography>
                  </Grid>
                </Grid>
              }
              used={benefit.statistics.refund}
              maxRefund={benefit.statistics.maxRefund}
              moreThanLastMonth={moreThanLastMonth}
            />
          }
          rightCard={<BenefitLastMonths history={benefit.statistics.history} />}
        />
      );
  }
  return <></>;
};

const BenefitVisualization: React.FC = () => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation('benefitModule');
  const [budgetOverview, setBudgetOverview] = useState<{
    history: number[];
    maxRefund: number;
    refund: number;
    moreThanLastMonth: number;
  }>();

  const heightDrawer = Capacitor.getPlatform() === 'ios' ? 'calc(100% - 64px)' : '100%';
  const { data: activeBenefits, isLoading } = useEmployeeQuery(['benefits', apis.benefits.getBenefits.name], params =>
    apis.benefits.getBenefits({ ...params }).then(res => res.data),
  );
  const visualizedBenefits = useMemo(() => {
    const flexBenefits =
      (activeBenefits?.benefits.find(dto => dto.benefit === BenefitDTOBenefitEnum.Flex)?.details as FlexBenefitDetailsDTO)?.flexBenefits ||
      [];

    return activeBenefits?.benefits.filter(
      benefit =>
        benefit.benefit !== BenefitDTOBenefitEnum.Bikeleasing &&
        benefit.benefit !== BenefitDTOBenefitEnum.Gifts &&
        benefit.benefit !== BenefitDTOBenefitEnum.News &&
        (benefit.benefit !== BenefitDTOBenefitEnum.Custom || (benefit.details as CustomBenefitDetailsDTO).monthlyGrant !== 0) &&
        // hide coupons V2 tile if coupons V1 tile is shown
        (benefit.benefit !== BenefitDTOBenefitEnum.CouponsV2 ||
          !activeBenefits.benefits.some(benefit => benefit.benefit === BenefitDTOBenefitEnum.Coupons)) &&
        !flexBenefits.includes(benefit.benefit),
    );
  }, [activeBenefits]);

  const toggleDrawer =
    (newOpen: boolean): (() => void) =>
    () => {
      if (visualizedBenefits && visualizedBenefits.length > 1) {
        setOpen(newOpen);
      }
    };

  const calcSumCard = (
    activeBenefits: BenefitDTO[],
  ): { history: number[]; maxRefund: number; refund: number; moreThanLastMonth: number } => {
    const aggregatedStatistics = activeBenefits.reduce<BenefitStatisticsDTO>(
      (stats, benefit) => ({
        history: stats.history.map((value, index) => value + (benefit.statistics?.history[index] || 0)),
        refund: stats.refund + (benefit.statistics?.refund || 0),
        maxRefund: stats.maxRefund + (benefit.statistics?.maxRefund || 0),
      }),
      { history: Array(BENEFIT_HISTORY_LENGTH).fill(0), refund: 0, maxRefund: 0 },
    );

    const history = aggregatedStatistics.history;
    const moreThanLastMonth = history[history.length - 1] - history[history.length - 2];

    return { ...aggregatedStatistics, moreThanLastMonth };
  };

  useEffect(() => {
    if (visualizedBenefits) {
      setBudgetOverview(calcSumCard(visualizedBenefits));
    }
  }, [visualizedBenefits]);

  return (
    <>
      {!isLoading && visualizedBenefits?.length ? (
        <>
          <Box marginBottom={1}>
            <Card onClick={toggleDrawer(true)} sx={{ cursor: `${visualizedBenefits.length > 1 ? 'pointer' : 'initial'}` }}>
              {budgetOverview && (
                <Grid container>
                  <Grid size={6}>
                    <Box>
                      <BenefitCurrentMonth
                        heading={
                          <Typography variant="h2" color="text.primary">
                            {t('visualization.overview')}
                          </Typography>
                        }
                        moreThanLastMonth={budgetOverview.moreThanLastMonth}
                        used={budgetOverview.refund}
                        maxRefund={budgetOverview.maxRefund}
                      />
                    </Box>
                  </Grid>
                  <Divider orientation="vertical" flexItem variant="middle" sx={{ marginX: '-1px' }} />
                  <Grid size={6}>
                    <Box paddingX={0.25}>
                      <BenefitLastMonths history={budgetOverview.history} />
                    </Box>
                  </Grid>
                </Grid>
              )}
            </Card>
          </Box>
          {visualizedBenefits.length > 1 && (
            <SwipeableDrawer
              PaperProps={{ style: { maxHeight: `${heightDrawer}` } }}
              anchor="bottom"
              open={open}
              onClose={toggleDrawer(false)}
              onOpen={toggleDrawer(true)}
              ModalProps={{
                keepMounted: false,
              }}
              sx={{
                '& .MuiPaper-root': {
                  maxWidth: 450,
                  marginX: 'auto',
                  ['@media (max-width:599px)']: {
                    maxWidth: 599,
                  },
                },
              }}
            >
              <Box
                sx={{
                  p: 0.5,
                  pb: 2,
                  height: '100%',

                  overflow: 'auto',
                }}
              >
                <Grid container spacing={1}>
                  {visualizedBenefits.map(benefit => (
                    <BenefitItemContainer
                      key={`${benefit.benefit} ${(benefit.details as CustomBenefitDetailsDTO).customBenefitId || ''}`}
                      benefit={benefit}
                    />
                  ))}
                </Grid>
              </Box>
            </SwipeableDrawer>
          )}
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default BenefitVisualization;
