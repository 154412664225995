import { Box, Button } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonRendererProps, CameraButton } from '../../../component/camera';
import { ImageFile } from '../../../component/camera/ImageFile';
import { BasicDialog, DialogState } from '../../../component/dialog/BasicDialog';
import { MissingCriteriaElements } from './MissingCriteriaElements';

export enum MissingCriteria {
  DATE = 'date',
  TOTAL = 'total',
  COUNTRY = 'country',
}

interface Props {
  handleReuploadImage: (file: ImageFile, pdf?: Blob) => void;
  dialogState: DialogState;
  isLoading?: boolean;
  onSubmit: () => void;
  missingCriterias: MissingCriteria[];
}

const MissingCriteriaModal: React.FC<Props> = ({ handleReuploadImage, dialogState, isLoading, onSubmit, missingCriterias }) => {
  const { t } = useTranslation('benefitModule');

  const UploadImageButton: React.FC<ButtonRendererProps> = useCallback(
    props => {
      return (
        <Button variant="contained" fullWidth {...props}>
          {t('benefit.LUNCH.warningModal.cancelButton')}
        </Button>
      );
    },
    [t],
  );

  const handleSubmit = useCallback(() => {
    onSubmit();
  }, [onSubmit]);

  const reuploadImage = useCallback(
    (imageFile: ImageFile, pdf?: Blob) => {
      dialogState.handleClose();
      handleReuploadImage(imageFile, pdf);
    },
    [dialogState, handleReuploadImage],
  );

  return (
    <BasicDialog
      dialogState={dialogState}
      title=""
      hideCloseIcon
      maxWidth="xs"
      sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
      content={
        // otherwise shadows are broken
        <Box mt={0.1} mb={1}>
          <MissingCriteriaElements missingCriterias={missingCriterias} />
        </Box>
      }
      actions={
        <Grid display="flex" flexDirection="column" gap={0.5}>
          <CameraButton buttonRenderer={UploadImageButton} onSelect={reuploadImage} />
          <Button
            variant="text"
            color="primary"
            onClick={handleSubmit}
            sx={{ marginBottom: 0.5 }}
            data-test-id="missing-criteria-continue-button"
            disabled={isLoading}
          >
            {t('benefit.LUNCH.warningModal.continueButton')}
          </Button>
        </Grid>
      }
    />
  );
};

export default MissingCriteriaModal;
