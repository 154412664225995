import { Clipboard } from '@capacitor/clipboard';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Box, Card, IconButton, List, Stack, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useWithMessage } from 'probonio-shared-ui/module/error';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ValueListItem } from '../../../component/list';
import { useAppSelector } from '../../../redux/hooks';
import { useBikeleasingDetails } from './useBikeleasingDetails';
import { useBikeleasingEmployeeInformation } from './useBikeleasingEmployeeInformation';

const ClipboardCopyItem: React.FC<{ value: string }> = ({ value }: { value: string }) => {
  const { t } = useTranslation('benefitModule');
  const { enqueueSnackbar } = useSnackbar();
  const withMessage = useWithMessage();
  const copyToClipboard = useCallback(() => {
    Clipboard.write({ string: value })
      .then(() => enqueueSnackbar(t('benefit.BIKELEASING.condition.copied'), { variant: 'success' }))
      .catch(withMessage);
  }, [enqueueSnackbar, t, value, withMessage]);
  return (
    <Stack direction="row" alignItems="start">
      {value}
      <IconButton sx={{ padding: 0, paddingLeft: 0.5 }} onClick={copyToClipboard}>
        <ContentCopyIcon fontSize="small" />
      </IconButton>
    </Stack>
  );
};

export const BikeleasingOverview: React.FC = () => {
  const { t } = useTranslation('benefitModule');

  const bikeleasingBenefitDetails = useBikeleasingDetails();
  const bikeleasingEmployeeInformation = useBikeleasingEmployeeInformation(bikeleasingBenefitDetails);

  const employeeNumber = useAppSelector(state => state.me.employment?.employeeNumber);
  return (
    <>
      <Box sx={{ marginBottom: 1, marginTop: 1 }}>
        <Typography variant="h5">{t('benefit.BIKELEASING.information')}</Typography>
      </Box>
      <Card elevation={0} sx={{ maxWidth: '100%', marginBottom: 1, flexShrink: 0 }}>
        <List>
          {bikeleasingBenefitDetails && (
            <ValueListItem
              label={t('benefit.BIKELEASING.condition.companyCode')}
              value={<ClipboardCopyItem value={bikeleasingBenefitDetails?.bikeleasingCondition.companyCode} />}
              dataTestId="bikeleasing-company-code"
            />
          )}

          {bikeleasingEmployeeInformation && (
            <ValueListItem
              label={t('benefit.BIKELEASING.userName')}
              value={<ClipboardCopyItem value={bikeleasingEmployeeInformation.externalUsername} />}
            />
          )}
          {employeeNumber && (
            <ValueListItem label={t('benefit.BIKELEASING.employeeNumber')} value={<ClipboardCopyItem value={employeeNumber} />} />
          )}
        </List>
      </Card>
      <Box sx={{ marginBottom: 1, marginTop: 0.5 }}>
        <Typography variant="h5">{t('benefit.BIKELEASING.conditions')}</Typography>
      </Box>
      <Card elevation={0} sx={{ maxWidth: '100%', marginBottom: 1, flexShrink: 0 }}>
        <List>
          {bikeleasingBenefitDetails && (
            <ValueListItem
              label={t('benefit.BIKELEASING.condition.bikeTypes')}
              value={bikeleasingBenefitDetails?.bikeleasingCondition.allowedBikeTypes.join(', ')}
              subLabel={t('benefit.BIKELEASING.condition.availableTypes')}
              multiLine={bikeleasingBenefitDetails?.bikeleasingCondition.allowedBikeTypes.length > 2}
            />
          )}

          <ValueListItem
            label={t('benefit.BIKELEASING.condition.leasingDurationInMonths')}
            subLabel={t('benefit.BIKELEASING.condition.inMonths')}
            value={bikeleasingBenefitDetails?.bikeleasingCondition.leasingDurationInMonths}
          />
          {bikeleasingBenefitDetails?.bikeleasingCondition.employerContribution?.absolute && (
            <ValueListItem
              label={t('benefit.BIKELEASING.condition.conditionGrantAmount')}
              subLabel={t('benefit.BIKELEASING.condition.byEmployer')}
              value={t('common:money', { money: bikeleasingBenefitDetails?.bikeleasingCondition.employerContribution?.absolute })}
            />
          )}
          {bikeleasingBenefitDetails?.bikeleasingCondition.employerContribution?.percentage && (
            <ValueListItem
              label={t('benefit.BIKELEASING.condition.conditionGrantAmount')}
              subLabel={t('benefit.BIKELEASING.condition.byEmployer')}
              value={`${bikeleasingBenefitDetails?.bikeleasingCondition.employerContribution?.percentage}%`}
            />
          )}
          <ValueListItem
            label={t('benefit.BIKELEASING.condition.maxAmountPerEmployee')}
            subLabel={t('benefit.BIKELEASING.condition.total')}
            value={t('common:money', {
              money: bikeleasingBenefitDetails?.bikeleasingCondition.priceRestrictions?.maxTotalPurchasePriceForAllBikes,
            })}
          />
          <ValueListItem
            label={t('benefit.BIKELEASING.condition.conditionMaximumBikePrice')}
            subLabel={t('benefit.BIKELEASING.condition.perBike')}
            value={t('common:money', { money: bikeleasingBenefitDetails?.bikeleasingCondition.priceRestrictions?.maxPurchasePrice })}
          />
          <ValueListItem
            label={t('benefit.BIKELEASING.condition.conditionMinimumBikePrice')}
            subLabel={t('benefit.BIKELEASING.condition.perBike')}
            value={t('common:money', { money: bikeleasingBenefitDetails?.bikeleasingCondition.priceRestrictions?.minPurchasePrice })}
          />
        </List>
      </Card>
    </>
  );
};
