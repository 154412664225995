import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import { Drawer, useTheme } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CircularFloatingButton } from '../../component/button';
import { ConfirmationModal } from '../../component/confirmationModal';
import { CalculatorFormField } from '../../component/currencyInput/CalculatorFormField';
import { useCalculatorState } from '../../component/currencyInput/useCalculatorState';
import { useNewDialogState } from '../../component/dialog/BasicDialog';
import { SimpleStepper } from '../../component/stepper/SimpleStepper';
import { InternetConfirmationForm } from './InternetConfirmationForm';
import { useInternetCurrentReceipt } from './internetCurrentReceiptQuery';
import { useInternetReceiptInProgress } from './internetQueries';

export const NewInternetReceiptButton: React.FC = () => {
  const { t } = useTranslation('internetModule');
  const theme = useTheme();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const calculatorState = useCalculatorState();

  const { data: currentReceipt } = useInternetCurrentReceipt();
  const { data: receiptInProgress } = useInternetReceiptInProgress();
  const confirmReplaceDialog = useNewDialogState();

  const handleOpenDrawer = useCallback(() => {
    confirmReplaceDialog.dialogState.handleClose();
    setActiveStep(0);
    calculatorState.reset();
    setDrawerOpen(true);
  }, [calculatorState, confirmReplaceDialog.dialogState]);
  const handleCloseDrawer = useCallback(() => {
    setDrawerOpen(false);
  }, []);
  const handleCreate = useCallback(() => {
    if (receiptInProgress) {
      confirmReplaceDialog.handleOpen();
    } else {
      handleOpenDrawer();
    }
  }, [confirmReplaceDialog, handleOpenDrawer, receiptInProgress]);

  const handleSubmitTotal = useCallback(() => {
    setActiveStep(1);
  }, []);
  const handleSubmitConfirmation = useCallback(() => {
    setDrawerOpen(false);
  }, []);

  return (
    <>
      <CircularFloatingButton borderColor={theme.palette.primary.main} buttonProps={{ onClick: handleCreate }}>
        {currentReceipt || receiptInProgress ? <EditIcon fontSize="large" /> : <AddIcon fontSize="large" />}
      </CircularFloatingButton>
      <Drawer
        anchor="bottom"
        open={drawerOpen}
        onClose={handleCloseDrawer}
        PaperProps={{ sx: { textAlign: 'center', paddingY: 1, maxWidth: 455, margin: 'auto' } }}
      >
        <SimpleStepper activeStep={activeStep}>
          <CalculatorFormField
            hideCalculator
            placeholder={t('newReceipt.calculatorText')}
            state={calculatorState}
            onSubmit={handleSubmitTotal}
          />
          <InternetConfirmationForm
            receiptInProgress={receiptInProgress}
            total={calculatorState.calculatorResult}
            onSubmit={handleSubmitConfirmation}
          />
        </SimpleStepper>
      </Drawer>
      <ConfirmationModal
        title={t('newReceipt.confirmReplace')}
        message={t('newReceipt.confirmReplaceMessage')}
        onSubmit={handleOpenDrawer}
        onCancel={confirmReplaceDialog.dialogState.handleClose}
        isOpen={confirmReplaceDialog.dialogState.isOpen}
      />
    </>
  );
};
