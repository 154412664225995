import { Box, useTheme } from '@mui/material';
import { BAVContractDTO, BenefitDTOBenefitEnum } from 'probonio-shared-ui/api';
import React from 'react';
import { BAVProgress } from '../../module/bav/BAVProgress';

interface Props {
  contract: BAVContractDTO;
}

export const BAVAmount: React.FC<Props> = ({ contract }) => {
  const theme = useTheme();

  return (
    <Box sx={{ textAlign: 'center', paddingBottom: 0.5, paddingTop: 0.2, backgroundColor: theme.palette.primary.contrastText }}>
      <BAVProgress benefit={BenefitDTOBenefitEnum.Bav} contract={contract} />
    </Box>
  );
};
