import { Box, Typography } from '@mui/material';
import * as PDF417 from 'pdf417-generator';
import { QRCodeSVG } from 'qrcode.react';
import React, { useEffect, useState } from 'react';
import { useBarcode } from 'react-barcodes';
import { useTranslation } from 'react-i18next';

// formats supported by JSBarcode library
const LINEAR_FORMATS = [
  'CODE39',
  'CODE128',
  'CODE128A',
  'CODE128B',
  'CODE128C',
  'EAN13',
  'EAN8',
  'EAN5',
  'EAN2',
  'UPC',
  'UPCE',
  'ITF14',
  'ITF',
  'MSI',
  'MSI10',
  'MSI11',
  'MSI1010',
  'MSI1110',
];

const CouponLinearBarcode: React.FC<{ format: string; value: string }> = ({ format, value }) => {
  const { t } = useTranslation('couponsModule');
  const [barcodeValid, setBarcodeValid] = useState(true);
  const { inputRef } = useBarcode({
    value,
    options: {
      format,
      height: 60,
      margin: 0,
      displayValue: false,
      valid: setBarcodeValid,
    },
  });

  return (
    <Box>
      <Box
        textAlign="center"
        sx={{
          backgroundColor: 'white',
          padding: 1,
          paddingLeft: 0.5,
          paddingRight: 0.5,
          paddingBottom: 0.5,
          borderRadius: 1,
          '& .barcode-svg': { maxWidth: '100%', width: '100%', height: 'auto' },
        }}
      >
        <svg className="barcode-svg" width="200px" height="80px" ref={inputRef} style={barcodeValid ? undefined : { display: 'none' }} />
        {!barcodeValid && <Typography variant="body1">{t('couponOrderDetails.barcodeInvalid')}</Typography>}
      </Box>
    </Box>
  );
};

const CouponQRCode: React.FC<{ value: string }> = ({ value }) => {
  return (
    <Box textAlign="center">
      <Box
        sx={{
          justifyContent: 'center',
          padding: 0.5,
          backgroundColor: 'white',
          borderRadius: 1,
          display: 'flex',
        }}
      >
        <QRCodeSVG value={value} height={180} width={180} />
      </Box>
    </Box>
  );
};

const CouponPDF417Code: React.FC<{ value: string }> = React.memo(({ value }) => {
  const [barcodeBoxes, setBarcodeBoxes] = useState<{ x: number; y: number }[]>();
  const [barcodeSize, setBarcodeSize] = useState<{ width: number; height: number }>();

  useEffect(() => {
    const boxes: { x: number; y: number }[] = [];
    const fakeContext = {
      scale: () => {},
      fillRect: (x: number, y: number, width: number, height: number) => {
        boxes.push({ x, y });
      },
    };
    const fakeCanvas = { width: 0, height: 0, getContext: () => fakeContext };
    PDF417.draw(value, fakeCanvas, 5, -1, 1);
    if (fakeCanvas.width && fakeCanvas.height && boxes.length) {
      setBarcodeBoxes(boxes);
      setBarcodeSize({ width: fakeCanvas.width, height: fakeCanvas.height });
    } else {
      setBarcodeBoxes(undefined);
      setBarcodeSize(undefined);
    }
  }, [value]);

  return (
    <Box margin={-1.25} textAlign="center">
      <Box sx={{ justifyContent: 'center', padding: 0.5, backgroundColor: 'white', borderRadius: 1, display: 'flex' }}>
        {barcodeBoxes && barcodeSize && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            viewBox={`0 0 ${barcodeSize.width} ${barcodeSize.height}`}
            shapeRendering="crispEdges"
          >
            {barcodeBoxes.map((box, index) => (
              <rect key={index} x={box.x} y={box.y} width={1} height={1} style={{ fill: '#000' }} />
            ))}
          </svg>
        )}
      </Box>
    </Box>
  );
});

export interface BarcodeCouponModel {
  voucherCode?: string;
  voucherPin?: string;
  voucherSerialNumber?: string;
  voucherUrl?: string;
}

interface Props {
  format?: string;
  coupon: BarcodeCouponModel;
  fallback: React.ReactNode;
}

export const CouponBarcode: React.FC<Props> = ({ format = '', coupon, fallback }) => {
  let value = coupon.voucherCode;
  let formatWithoutTemplate = format;

  // the format value may contain an optional template string in the format "QR(${field1},${field2})"
  // in that case, split the template from the format name and process the template
  const templateMatch = /^(\w+)\(([^)]+)\)$/.exec(format);
  if (templateMatch && (coupon.voucherCode || coupon.voucherPin || coupon.voucherSerialNumber || coupon.voucherUrl)) {
    formatWithoutTemplate = templateMatch[1];
    const template = templateMatch[2];
    value = (['voucherCode', 'voucherPin', 'voucherSerialNumber', 'voucherUrl'] as const).reduce(
      (prev, fieldName) => prev.replaceAll(`\${${fieldName}}`, coupon[fieldName] || ''),
      template,
    );
  }

  if (value && LINEAR_FORMATS.includes(formatWithoutTemplate)) {
    return <CouponLinearBarcode format={formatWithoutTemplate} value={value} />;
  } else if (value && formatWithoutTemplate === 'QR') {
    return <CouponQRCode value={value} />;
  } else if (value && formatWithoutTemplate === 'PDF417') {
    return <CouponPDF417Code value={value} />;
  } else {
    return <>{fallback}</>;
  }
};
