import { useMutation } from '@tanstack/react-query';
import { DateTime } from 'luxon';
import { CreateRecreationReceiptDTO } from 'probonio-shared-ui/api';
import { resizeImage } from 'probonio-shared-ui/component/imageResizer/ImageResizer';
import { apis } from 'probonio-shared-ui/module/api';
import { useActiveEmployment } from 'probonio-shared-ui/module/me';
import { CalculatorState } from '../../../component/currencyInput/useCalculatorState';
import { LUNCH_THUMBNAIL_SIZE, MIME_TYPE_JPEG } from '../../lunch/lunchConstants';
import { ImageSelectionState } from '../../shareTarget/useImageSelection';
import { uploadRecreationImageFile } from '../recreationFileUpload';

type CreateRecreationReceiptDTOWithoutImage = Omit<CreateRecreationReceiptDTO, 'imageKey'>;

export function mapCreateRecreationReceipt(calculatorState: CalculatorState, comment?: string): CreateRecreationReceiptDTOWithoutImage {
  return {
    date: DateTime.now().toFormat('yyyy-MM-dd'),
    total: calculatorState.calculatorResult,
    comment,
  };
}

export function useCreateRecreationReceipt(imageSelection: ImageSelectionState, onSuccess: () => void) {
  const getActiveEmployment = useActiveEmployment();

  return useMutation({
    mutationFn: async (dto: CreateRecreationReceiptDTOWithoutImage) => {
      const imageBlob = await imageSelection.selectedImage!.getBlob();
      const mimeType = imageSelection.selectedImage!.mimetype;

      let thumbnailBlob;
      if (!imageSelection.pdf) {
        const imageURI = await imageSelection.selectedImage!.getURI();
        const thumbnail = await resizeImage(imageURI, LUNCH_THUMBNAIL_SIZE, MIME_TYPE_JPEG);
        thumbnailBlob = thumbnail.blob;
      }

      const imageKeys = await uploadRecreationImageFile(
        getActiveEmployment().tenantId,
        getActiveEmployment().id,
        mimeType,
        imageBlob,
        imageSelection.pdf,
        thumbnailBlob,
      );

      return apis.recreation.createReceipt({
        tenantId: getActiveEmployment().tenantId,
        employeeId: getActiveEmployment().id,
        createRecreationReceiptDTO: {
          ...dto,
          imageKey: imageKeys.imageKey,
          pdfKey: imageKeys.pdfKey,
          thumbnailKey: imageKeys.thumbnailKey,
        },
      });
    },

    onSuccess,
  });
}
