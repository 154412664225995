import { useEffect, useState } from 'react';
import { ImageFile } from './ImageFile';

export function useImageURI(imageFile: ImageFile): string | undefined {
  const [imageURI, setImageURI] = useState<string>();

  useEffect(() => {
    const loadImageUri = async (): Promise<void> => {
      const uri = await imageFile.getURI();
      setImageURI(uri);
    };
    void loadImageUri();
  }, [imageFile]);

  return imageURI;
}
