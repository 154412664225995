import { Box, CircularProgress, useTheme } from '@mui/material';
import { useCallback } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { CouponDefinitionDTO } from '../../../../probonio-shared-ui/api';
import { BackIconButton } from '../../component/button/BackIconButton';
import { MobileLayout } from '../../component/layout';
import { AppToolbar } from '../../component/layout/AppToolbar';
import { SwipeableList } from '../../component/swipeableList/SwipeableList';
import {
  useCouponsV2BenefitDetails,
  useCouponsV2DefinitionListQuery,
  useCouponsV2DefinitionQuery,
  useCouponsV2UserTokenCountQuery,
} from '../../module/couponsV2/couponsV2Queries';

import { useTranslation } from 'react-i18next';
import { ALL_CATEGORY } from '../../module/couponsShared/redux/createCouponsSlice';
import { useAvailableVariations } from '../../module/couponsV2/couponsV2Queries';
import { ExploreCouponsV2Detail } from '../../module/couponsV2/explore/ExploreCouponsV2Detail';
import { useBackNavigator } from '../../module/navigation/BackNavigator';
import { useAppSelector } from '../../redux/hooks';
import { useLoadingCallback } from '../../utils/loadingFunction';

const ExploreCouponsV2DetailPage: React.FC = () => {
  const { t } = useTranslation('couponsV2Module');
  const { couponId } = useParams();
  const [searchParams] = useSearchParams();
  const theme = useTheme();
  const navigate = useNavigate();
  const backNavigator = useBackNavigator();
  const selectedCategory = useAppSelector(state => state.couponsV2.selectedCategory);
  const selectedAmount = Number(searchParams.get('amount')) || undefined;
  const benefitDetails = useCouponsV2BenefitDetails();

  const variations = useAvailableVariations(selectedAmount);

  const { data: coupons, isLoading: couponsIsLoading } = useCouponsV2DefinitionListQuery({
    variations,
    includeCombinations: true,
    category: selectedCategory !== ALL_CATEGORY && !!selectedCategory ? selectedCategory : undefined,
  });
  const { data: selectedCoupon, isLoading: selectedCouponIsLoading } = useCouponsV2DefinitionQuery(couponId);

  const { data: availableTokensCount } = useCouponsV2UserTokenCountQuery();

  const handleOrderSingle = useLoadingCallback(() => {}, [], false);

  const handleOrderMultiple = useLoadingCallback(() => {}, [], false);

  const handleNavigate = useCallback(
    (item: CouponDefinitionDTO) => {
      navigate(`../explore-coupons/${item.id}${selectedAmount ? `?amount=${selectedAmount}` : ''}`, { replace: true });
    },
    [navigate, selectedAmount],
  );

  return (
    <MobileLayout
      header={<AppToolbar backButton={<BackIconButton onClick={backNavigator.back} />} />}
      backgroundColor={theme.palette.background.default}
    >
      <Box sx={{ paddingX: 1, paddingBottom: 1 }}>
        <SwipeableList items={coupons?.coupons} currentItemId={couponId!} onNavigate={handleNavigate}>
          {selectedCoupon && !selectedCouponIsLoading && !couponsIsLoading ? (
            <ExploreCouponsV2Detail
              disableMultipleSelection={!!selectedAmount || benefitDetails?.availableTokens.length === 1}
              coupon={selectedCoupon}
              availableAmounts={variations}
              noneAvailableMessage={
                availableTokensCount !== undefined && availableTokensCount > 0
                  ? t('exploreCoupons.details.couponAlreadyUsed')
                  : t('exploreCoupons.details.newUserNoCoupons')
              }
              onOrderSingle={handleOrderSingle}
              onOrderMultiple={handleOrderMultiple}
            />
          ) : (
            <Box textAlign="center">
              <CircularProgress />
            </Box>
          )}
        </SwipeableList>
      </Box>
    </MobileLayout>
  );
};

export default ExploreCouponsV2DetailPage;
