import { Checkbox, CheckboxProps, FormControlLabel, FormHelperText } from '@mui/material';
import React from 'react';
import { FieldPath, FieldValues, useController, UseControllerProps } from 'react-hook-form';

type Props<T extends FieldValues, TName extends FieldPath<T>> = UseControllerProps<T, TName> &
  Omit<CheckboxProps, 'defaultValue'> & {
    label?: React.ReactNode;
    helperText?: React.ReactNode;
    children?: React.ReactNode;
  };

export const CheckboxControl = <T extends FieldValues, TName extends FieldPath<T>>({
  name,
  label,
  rules,
  control,
  defaultValue,
  shouldUnregister,
  helperText,
  ...props
}: Props<T, TName>): JSX.Element => {
  const { field } = useController({ name, rules, control, defaultValue, shouldUnregister });

  return (
    <>
      <FormControlLabel control={<Checkbox checked={!!field.value} {...field} {...props} />} label={label} />
      {helperText && (
        <FormHelperText
          sx={{
            // allow \n in the text, useful for larger helper texts
            whiteSpace: 'pre-line',
          }}
        >
          {helperText}
        </FormHelperText>
      )}
    </>
  );
};
