import { Capacitor } from '@capacitor/core';
import { useLoginContext } from '@tiffinger-thiel/appauth-react';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import MessageLayout from '../component/layout/MessageLayout';
import { unregisterDevice } from '../module/pushNotifications';
import { useAppDispatch } from '../redux/hooks';

/**
 * Intermediate page that triggers the logout when called. Used to prevent the user from
 * being immediately logged in again.
 */
const LogoutPage: React.FC = () => {
  const { isLoggedIn, logout } = useLoginContext();
  const loggingOutRef = useRef(false);
  const navigate = useNavigate();
  const { t } = useTranslation('authModule');
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isLoggedIn) {
      loggingOutRef.current = true;

      void (async () => {
        try {
          await dispatch(unregisterDevice());
        } catch {
          // unregister failed, ignore
        }
        await logout();
        if (Capacitor.getPlatform() !== 'web') {
          navigate('/', { replace: true });
        }
      })();
    } else if (!loggingOutRef.current) {
      navigate('/', { replace: true });
    }
  }, [dispatch, isLoggedIn, logout, navigate]);

  return <MessageLayout text={t('loggingOut')} />;
};

export default LogoutPage;
