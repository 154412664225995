import { Button, Stack, TextField, Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { BAVContractDTO } from 'probonio-shared-ui/api';
import { LoadingButton } from 'probonio-shared-ui/component/button';
import { apis } from 'probonio-shared-ui/module/api';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BasicDialog, DialogState } from '../../component/dialog/BasicDialog';

interface Props {
  contract: BAVContractDTO;
  dialogState: DialogState;
}

export const BAVRequestContactDialog: React.FC<Props> = ({ contract, dialogState }) => {
  const { t } = useTranslation('benefitModule');
  const [comment, setComment] = useState('');
  const { enqueueSnackbar } = useSnackbar();

  const mutation = useMutation({
    mutationFn: async (comment: string) => {
      await apis.bav.createChangeRequest({
        tenantId: contract.tenantId,
        employeeId: contract.employeeId,
        contractId: contract.id,
        createBAVChangeRequestDTO: { comment },
      });
    },

    onSuccess: () => {
      dialogState.handleClose();
      setComment('');
      enqueueSnackbar(t('benefit.BAV.request.successToast'), { variant: 'success' });
    },
  });

  const handleSubmit = useCallback(
    (ev: React.MouseEvent) => {
      ev.preventDefault();
      mutation.mutate(comment);
    },
    [comment, mutation],
  );

  const handleChange = useCallback((ev: React.ChangeEvent<HTMLInputElement>) => {
    setComment(ev.target.value);
  }, []);

  return (
    <BasicDialog
      dialogState={dialogState}
      title={t('benefit.BAV.request.title')}
      content={
        <Stack spacing={1}>
          <Typography>{t('benefit.BAV.request.info')}</Typography>
          <TextField
            required
            label={t('benefit.BAV.request.placeholder')}
            fullWidth
            multiline
            value={comment}
            onChange={handleChange}
            rows={4}
          />
        </Stack>
      }
      actions={
        <>
          <Button variant="text" onClick={dialogState.handleClose}>
            {t('common:button.abort')}
          </Button>
          <LoadingButton
            type="submit"
            color="primary"
            variant="contained"
            loading={mutation.isPending}
            onClick={handleSubmit}
            disabled={comment === ''}
          >
            {t('benefit.BAV.request.sendRequestButton')}
          </LoadingButton>
        </>
      }
    />
  );
};
