import AddIcon from '@mui/icons-material/Add';
import EuroSymbolIcon from '@mui/icons-material/EuroSymbol';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import React, { useCallback, useState } from 'react';
import { ButtonTab, CircularFloatingButton, CircularFloatingButtonTabs } from '../../component/button/CircularFloatingButton';
import { ButtonRendererProps, CameraButton } from '../../component/camera';
import { ImageFile } from '../../component/camera/ImageFile';
import { BenefitDTOBenefitEnum } from 'probonio-shared-ui/api';
import { LUNCH_MAX_IMAGE_SIZE } from './lunchConstants';
import { BasicDialog, useNewDialogState } from '../../component/dialog/BasicDialog';
import { Trans, useTranslation } from 'react-i18next';
import { ChecklistItem } from './components/ChecklistItem';
import { Button, Checkbox, FormControlLabel, FormGroup } from '@mui/material';

interface Props {
  benefit: BenefitDTOBenefitEnum;
  onSelect: (file: ImageFile, pdf?: Blob) => void;
  selectedTab?: ButtonTab;
  handleTabChange?: (newProgressType: ButtonTab) => void;
  borderColor?: string;
  disabled?: boolean;
}

const AddReceiptButton: React.FC<Props> = ({ benefit, onSelect, selectedTab, handleTabChange, borderColor, disabled }) => {
  const { t } = useTranslation('benefitModule');
  const checklistDialog = useNewDialogState();
  const [hideChecklist, setHideChecklist] = useState(localStorage.getItem(`hideChecklist_${benefit}`) === 'true');
  const [doNotShowAgainChecked, setDoNotShowAgainChecked] = useState(false);
  const [takePhotoState, setTakePhotoState] = useState<{ handleTakePhoto: () => void }>();

  const handleClick = useCallback(
    (callback: () => void) => {
      if (hideChecklist) {
        callback();
      } else {
        setTakePhotoState({ handleTakePhoto: callback });
        checklistDialog.handleOpen();
      }
    },
    [hideChecklist, checklistDialog],
  );

  const handleConfirmChecklist = useCallback(() => {
    if (doNotShowAgainChecked) {
      setHideChecklist(true);
      localStorage.setItem(`hideChecklist_${benefit}`, 'true');
    }
    checklistDialog.dialogState.handleClose();
    takePhotoState?.handleTakePhoto();
  }, [benefit, checklistDialog.dialogState, doNotShowAgainChecked, takePhotoState]);

  const handleChangeDoNotShowAgain = useCallback((ev: unknown, checked: boolean) => {
    setDoNotShowAgainChecked(checked);
  }, []);

  const TakePhotoButton: React.FC<ButtonRendererProps> = useCallback(
    props => (
      <CircularFloatingButton
        borderColor={borderColor}
        buttonProps={{ ...props, onClick: () => handleClick(props.onClick), disabled }}
        tabs={
          selectedTab && (
            <CircularFloatingButtonTabs
              defaultValue={selectedTab}
              handleTabChange={handleTabChange}
              leftIcon={<FastfoodIcon />}
              rightIcon={<EuroSymbolIcon />}
            />
          )
        }
      >
        <AddIcon fontSize="large" />
      </CircularFloatingButton>
    ),
    [borderColor, disabled, selectedTab, handleTabChange, handleClick],
  );

  return (
    <>
      <CameraButton maxSize={LUNCH_MAX_IMAGE_SIZE} buttonRenderer={TakePhotoButton} onSelect={onSelect} isPdfAllowed={true} />
      <BasicDialog
        dialogState={checklistDialog.dialogState}
        maxWidth="xs"
        title={t(`benefit.${benefit}.cameraModalTitle`)}
        content={
          <>
            <Trans i18nKey={`benefitModule:benefit.${benefit}.receiptImageChecklist`}>
              <ChecklistItem />
            </Trans>
            <FormGroup sx={{ mt: 1 }}>
              <FormControlLabel
                control={<Checkbox checked={doNotShowAgainChecked} onChange={handleChangeDoNotShowAgain} />}
                label={t('common:doNotShowAgain')}
              />
            </FormGroup>
          </>
        }
        actions={
          <Button fullWidth onClick={handleConfirmChecklist}>
            {t('common:button.continue')}
          </Button>
        }
      />
    </>
  );
};

export default AddReceiptButton;
