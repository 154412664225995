import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Box, CircularProgress, Typography, useTheme } from '@mui/material';
import { useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { BackIconButton } from '../../component/button/BackIconButton';
import { MobileLayout } from '../../component/layout';
import { AppToolbar } from '../../component/layout/AppToolbar';
import { HighlightGuideButton } from '../../module/guiding';
import { CurrentReceiptPanel } from '../../module/internet/CurrentReceiptPanel';
import { useInternetCurrentReceipt } from '../../module/internet/internetCurrentReceiptQuery';
import { useInternetReceiptInProgress } from '../../module/internet/internetQueries';
import { NewInternetReceiptButton } from '../../module/internet/NewInternetReceiptButton';
import { ReceiptInProgressPanel } from '../../module/internet/ReceiptInProgressPanel';
import { useBackNavigator } from '../../module/navigation/BackNavigator';

export const InternetPage: React.FC = () => {
  const theme = useTheme();
  const backNavigator = useBackNavigator();
  const navigate = useNavigate();
  const { t } = useTranslation('benefitModule');

  const { data: currentReceipt, isLoading } = useInternetCurrentReceipt();
  const { data: receiptInProgress, isLoading: isLoadingReceiptInProgress } = useInternetReceiptInProgress();

  const navigateInfo = useCallback(() => {
    navigate('info');
  }, [navigate]);

  return (
    <MobileLayout
      iosOverloadScrollBackground={theme.palette.background.paper}
      header={
        <AppToolbar
          title={t('benefit.INTERNET.name')}
          color={theme.palette.primary.contrastText}
          backButton={<BackIconButton onClick={backNavigator.back} />}
          actionButton={
            <HighlightGuideButton
              name={t('benefit.INTERNET.name')}
              header={t('benefit.INTERNET.firstHelpGuide.header')}
              text={t('benefit.INTERNET.firstHelpGuide.text')}
              onClick={navigateInfo}
            />
          }
        />
      }
    >
      {isLoading || isLoadingReceiptInProgress ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '70vh' }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Box pb={0.5} sx={{ backgroundColor: theme.palette.background.paper }}>
            {currentReceipt ? (
              <>
                <Typography variant="h2" color="text.primary" textAlign="center" gutterBottom data-test-id="internet-refund-amount">
                  {t('benefit.INTERNET.monthlyAmount', { monthlyAmount: currentReceipt.refund })}
                </Typography>
                <Typography color="text.secondary" textAlign="center">
                  {t('benefit.INTERNET.monthlyAmountSub')}
                </Typography>
              </>
            ) : (
              <Typography color="text.secondary" textAlign="center">
                {t('benefit.INTERNET.availableNow')}
              </Typography>
            )}
          </Box>
          <NewInternetReceiptButton />
          <Box sx={{ padding: 1, paddingTop: 0 }}>
            {receiptInProgress && <ReceiptInProgressPanel receiptInProgress={receiptInProgress} currentReceipt={currentReceipt} />}
            {currentReceipt && !receiptInProgress && <CurrentReceiptPanel currentReceipt={currentReceipt} />}
            {!currentReceipt && !receiptInProgress && (
              <Typography variant="h2" fontWeight={400} textAlign="center" mt={6} data-test-id="internet-flat-rate-info">
                <Trans i18nKey="benefitModule:benefit.INTERNET.noReceipt">
                  <AddCircleIcon color="primary" sx={{ verticalAlign: 'middle' }} />
                </Trans>
              </Typography>
            )}
          </Box>
        </>
      )}
    </MobileLayout>
  );
};
