import { SvgIcon, SvgIconProps } from '@mui/material';

export const BikeleasingBIcon: React.ComponentType<SvgIconProps> = props => (
  <SvgIcon {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8.281 9.737">
      <path
        d="M12.618 10.921c.08-.053.53-.264.82-.846.16-.291.265-.688.265-1.191 0-1.376-1.032-2.672-3.44-2.672H6.11c-.344 0-.424.158-.424.423v8.89c0 .265.08.423.424.423H10.449c2.064 0 3.519-.952 3.519-2.83 0-1.615-1.244-2.17-1.35-2.197zm-2.143 2.937h-2.46V8.302h2.249c.767 0 1.217.159 1.217.82 0 .265-.08.476-.239.609-.158.132-.396.238-.74.264a.496.496 0 0 0-.477-.317.485.485 0 0 0-.476.37c0 .053-.026.08-.026.132 0 .106.026.186.08.265.026.053.026.106.052.185.053.291.026.53 0 .715-.027.132-.053.211-.053.211-.026.053-.053.08-.053.133 0 .052-.026.079-.026.132 0 .264.211.503.502.503a.523.523 0 0 0 .477-.318c.688 0 1.164.212 1.164.9-.053.661-.45.952-1.19.952zM10 10.418c-.132 0-.238-.105-.238-.264v-.053c0-.026 0-.026.026-.053a.278.278 0 0 1 .238-.132.24.24 0 0 1 .186.08c.053.052.053.105.053.184v.053c-.027.106-.133.186-.265.186zm.238 1.376v.053c0 .053-.053.106-.08.133a.376.376 0 0 1-.158.053c-.08 0-.159-.053-.212-.106-.026-.053-.053-.08-.053-.133v-.053a.254.254 0 0 1 .238-.185c.16 0 .265.106.265.238z"
        className="st0"
        style={{
          fill: '#95c11e',
          strokeWidth: 0.264583,
        }}
        transform="translate(-5.686 -6.212)"
      />
    </svg>
  </SvgIcon>
);
