import { Box, CircularProgress, Typography } from '@mui/material';
import { BENEFIT_PICTURES } from 'probonio-shared-ui/component/button';
import { Trans, useTranslation } from 'react-i18next';
import { BackIconButton } from '../../component/button/BackIconButton';
import { MobileLayout } from '../../component/layout';
import { AppToolbar } from '../../component/layout/AppToolbar';
import { TenantLogo } from '../../component/layout/TenantLogo';
import { useActiveBenefitsQuery } from '../../module/benefit/useActiveBenefitsQuery';
import { useBackNavigator } from '../../module/navigation/BackNavigator';
import { BenefitDTOBenefitEnum, FlexBenefitDetailsDTO } from 'probonio-shared-ui/api';

const FlexInfoPage: React.FC = () => {
  const backNavigator = useBackNavigator();
  const { t } = useTranslation('benefitModule');

  const { data: activeBenefits, isLoading } = useActiveBenefitsQuery();

  const detailsFlex = activeBenefits?.benefits.find(benefitDto => benefitDto.benefit === BenefitDTOBenefitEnum.Flex)?.details;
  const flexBenefits = (detailsFlex as FlexBenefitDetailsDTO)?.flexBenefits || [];

  return (
    <MobileLayout
      paperBackground
      header={<AppToolbar absolute invert backButton={<BackIconButton onClick={backNavigator.back} color="inherit" />} />}
    >
      <Box flexGrow={1}>
        <img height={250} src={BENEFIT_PICTURES.FLEX} style={{ width: '100%', objectFit: 'cover' }} />
        <Box p={0.5} px={1}>
          <Typography variant="h2" mb={1}>
            {t('benefit.FLEX.infoPage.title')}
          </Typography>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <Typography color="text.secondary" variant="body1" component="div" data-test-id="flex-info-content">
              <Trans i18nKey="benefitModule:benefit.FLEX.infoPage.content" values={detailsFlex} />
              <ul>
                {flexBenefits.map(benefit => {
                  const details = activeBenefits?.benefits.find(benefitDto => benefitDto.benefit === benefit)?.details;
                  return (
                    <li key={benefit}>
                      <Trans i18nKey={`benefitModule:benefit.FLEX.infoPage.${benefit.toLowerCase()}`} values={details} />
                    </li>
                  );
                })}
              </ul>
            </Typography>
          )}
        </Box>
      </Box>
      <Box display="flex" justifyContent="center" mb={0.5}>
        <TenantLogo sx={{ width: '80%', textAlign: 'center' }} />
      </Box>
    </MobileLayout>
  );
};
export default FlexInfoPage;
