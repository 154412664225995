import ErrorIcon from '@mui/icons-material/Error';
import { Box, CircularProgress, useTheme } from '@mui/material';
import { BenefitDTOBenefitEnum } from 'probonio-shared-ui/api';
import React from 'react';
import { ProgressMoney } from '../benefit/progressAmount/ProgressMoney';
import { useRecreationPeriods } from './RecreationPeriodsContext';

export const RecreationAmount: React.FC = () => {
  const theme = useTheme();

  const {
    currentPeriod,
    recreationPeriodsQuery: { isLoading, isSuccess, isError },
  } = useRecreationPeriods();

  return (
    <Box sx={{ textAlign: 'center', paddingBottom: 0.5, paddingTop: 0.2, backgroundColor: theme.palette.background.paper }}>
      {isLoading && <CircularProgress />}
      {isSuccess && (
        <ProgressMoney
          refund={currentPeriod?.refund || 0}
          maxRefund={currentPeriod?.maxRefund || 0}
          benefit={BenefitDTOBenefitEnum.Recreation}
        />
      )}
      {isError && <ErrorIcon color="error" fontSize="large" />}
    </Box>
  );
};
