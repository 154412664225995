/* tslint:disable */
/* eslint-disable */
/**
 * Probonio full API Docs
 * If you stumbled upon this page and want to integrate Probonio into your business software [click here!](/api/external)    If you\'re looking for more information about Probonio [head over here](https://probonio.de)    **This documentation describes all Endpoints of the Probonio API. It\'s created for experienced Probonio Developers only!**  
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface CreateMobilityReceiptDTO
 */
export interface CreateMobilityReceiptDTO {
    /**
     * 
     * @type {number}
     * @memberof CreateMobilityReceiptDTO
     */
    'total': number;
    /**
     * date of the receipt
     * @type {string}
     * @memberof CreateMobilityReceiptDTO
     */
    'date': string;
    /**
     * 
     * @type {string}
     * @memberof CreateMobilityReceiptDTO
     * @deprecated
     */
    'receiptType'?: CreateMobilityReceiptDTOReceiptTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateMobilityReceiptDTO
     */
    'mobilityType'?: CreateMobilityReceiptDTOMobilityTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateMobilityReceiptDTO
     */
    'travelPurpose'?: CreateMobilityReceiptDTOTravelPurposeEnum;
    /**
     * first month of time card validity
     * @type {string}
     * @memberof CreateMobilityReceiptDTO
     */
    'validityStartDate'?: string;
    /**
     * last month of time card validity
     * @type {string}
     * @memberof CreateMobilityReceiptDTO
     */
    'validityEndDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateMobilityReceiptDTO
     */
    'comment'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateMobilityReceiptDTO
     */
    'imageKey': string;
    /**
     * 
     * @type {string}
     * @memberof CreateMobilityReceiptDTO
     */
    'pdfKey'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateMobilityReceiptDTO
     */
    'thumbnailKey'?: string;
}

export const CreateMobilityReceiptDTOReceiptTypeEnum = {
    Local: 'LOCAL',
    LongDistance: 'LONG_DISTANCE'
} as const;

export type CreateMobilityReceiptDTOReceiptTypeEnum = typeof CreateMobilityReceiptDTOReceiptTypeEnum[keyof typeof CreateMobilityReceiptDTOReceiptTypeEnum];
export const CreateMobilityReceiptDTOMobilityTypeEnum = {
    LocalTrain: 'LOCAL_TRAIN',
    LongDistanceTrain: 'LONG_DISTANCE_TRAIN',
    LocalBus: 'LOCAL_BUS',
    LongDistanceBus: 'LONG_DISTANCE_BUS',
    Car: 'CAR',
    SharingOffers: 'SHARING_OFFERS',
    Taxi: 'TAXI',
    Bike: 'BIKE',
    TimeCard: 'TIME_CARD',
    DeutschlandTicket: 'DEUTSCHLAND_TICKET',
    Others: 'OTHERS'
} as const;

export type CreateMobilityReceiptDTOMobilityTypeEnum = typeof CreateMobilityReceiptDTOMobilityTypeEnum[keyof typeof CreateMobilityReceiptDTOMobilityTypeEnum];
export const CreateMobilityReceiptDTOTravelPurposeEnum = {
    Work: 'WORK',
    Private: 'PRIVATE'
} as const;

export type CreateMobilityReceiptDTOTravelPurposeEnum = typeof CreateMobilityReceiptDTOTravelPurposeEnum[keyof typeof CreateMobilityReceiptDTOTravelPurposeEnum];


