import { Box, Drawer, InputAdornment, InputLabel, TextField, Typography, useTheme } from '@mui/material';
import { BenefitDTOBenefitEnum, RecreationPeriodDTO } from 'probonio-shared-ui/api';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ImageFile } from '../../component/camera/ImageFile';
import { CalculatorFormField } from '../../component/currencyInput/CalculatorFormField';
import { useCalculatorState } from '../../component/currencyInput/useCalculatorState';
import { SelectImageField } from '../../component/selectImageField/SelectImageField';

interface Props {
  benefit: BenefitDTOBenefitEnum;
  previousPeriod?: RecreationPeriodDTO;
  benefitPeriod: string;
  benefitPeriodDTO: RecreationPeriodDTO;
  disabled?: boolean;
  values: EditReceiptFormValues;
  onChange: (values: EditReceiptFormValues) => void;
}

export interface EditReceiptFormValues {
  total: number;
  date: Date;
  comment?: string;
  image?: string | ImageFile;
  pdf?: Blob;
}

export const EditRecreationReceiptForm: React.FC<Props> = ({ benefit, disabled, values, onChange }) => {
  const { t } = useTranslation('benefitModule');
  const [currencyOpen, setCurrencyOpen] = useState(false);
  const calculatorState = useCalculatorState();
  const theme = useTheme();

  const handleChangeTotal = useCallback(() => {
    if (calculatorState.calculatorResult === 0) {
      return;
    }
    onChange({ ...values, total: calculatorState.calculatorResult });
    setCurrencyOpen(false);
  }, [calculatorState.calculatorResult, onChange, values]);

  const handleChangeComment = useCallback<React.ChangeEventHandler<HTMLInputElement>>(
    ev => onChange({ ...values, comment: ev.target.value }),
    [values, onChange],
  );

  const handleCloseCurrency = useCallback(() => {
    setCurrencyOpen(false);
  }, []);

  const handleOpenCurrency = useCallback(() => {
    setCurrencyOpen(true);
    calculatorState.reset(values.total);
  }, [calculatorState, values.total]);

  const handleChangeImage = useCallback(
    (image: ImageFile, pdf?: Blob) => {
      onChange({ ...values, image, pdf });
    },
    [values, onChange],
  );

  return (
    <>
      <Box sx={{ marginBottom: 1 }}>
        <InputLabel variant="standard" htmlFor="input-total">
          {t('benefit.RECREATION.receiptPicture')}
        </InputLabel>
        <SelectImageField
          value={values.image}
          onChange={handleChangeImage}
          sx={{ height: theme.spacing(9.25), width: '100%' }}
          benefit={benefit}
        />
      </Box>
      <Box sx={{ marginBottom: 1 }}>
        <InputLabel variant="standard" htmlFor="input-total">
          {t(`benefit.${benefit}.field.total`)}
        </InputLabel>
        <TextField
          id="input-total"
          margin="dense"
          placeholder={t(`benefit.${benefit}.field.total`)}
          variant="outlined"
          fullWidth
          value={t('common:money', { money: values.total })}
          required
          onClick={handleOpenCurrency}
          disabled={disabled}
          slotProps={{
            input: {
              readOnly: true,
              endAdornment: <InputAdornment position="end">€</InputAdornment>,
            },
          }}
        />
      </Box>

      <Box sx={{ marginBottom: 1 }}>
        <Typography variant="h6" color="text.secondary">
          {t(`benefit.RECREATION.field.comment`)}
        </Typography>
        <TextField
          id="input-comment"
          margin="dense"
          slotProps={{
            htmlInput: {
              maxLength: 1000,
            },
          }}
          multiline
          rows={3}
          fullWidth
          variant="outlined"
          placeholder={t('benefit.RECREATION.placeholder.comment')}
          disabled={disabled}
          value={values.comment}
          onChange={handleChangeComment}
        />
      </Box>
      <Box justifyContent="center" display="flex">
        <Drawer
          anchor="bottom"
          open={currencyOpen}
          onClose={handleCloseCurrency}
          PaperProps={{ sx: { textAlign: 'center', paddingY: 1, maxWidth: 455, margin: 'auto' } }}
        >
          <CalculatorFormField state={calculatorState} onSubmit={handleChangeTotal} />
        </Drawer>
      </Box>
    </>
  );
};
