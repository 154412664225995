import { Box, Checkbox, FormControlLabel, Paper, Typography, useTheme } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';
import { LoadingButton } from 'probonio-shared-ui/component/button';
import { apis } from 'probonio-shared-ui/module/api';
import { loadMe, useActiveEmployment, useTenant } from 'probonio-shared-ui/module/me';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { BackIconButton } from '../../component/button/BackIconButton';
import { MobileLayout } from '../../component/layout';
import { AppToolbar } from '../../component/layout/AppToolbar';
import { useBackNavigator } from '../../module/navigation/BackNavigator';
import { useAppDispatch } from '../../redux/hooks';
import { useBavTermsQuery } from './useBAVTermsQuery';

const BAVNotInterestedPage: React.FC = () => {
  const backNavigator = useBackNavigator();
  const { t } = useTranslation('bavModule');
  const navigate = useNavigate();

  const getActiveEmployment = useActiveEmployment();
  const { tenant } = useTenant();
  const { data: latestBavTerms } = useBavTermsQuery({ enabled: !!tenant?.bavShowTerms });
  const dispatch = useAppDispatch();

  const theme = useTheme();
  const mutation = useMutation({
    mutationFn: async () => {
      await apis.employee.updateEmployee({
        tenantId: getActiveEmployment().tenantId,
        employeeId: getActiveEmployment().id,
        updateEmployeeDTO: {
          bavNotInterested: true,
        },
      });
    },

    onSuccess: async () => {
      enqueueSnackbar(t('notinterested.confirmNotify'), { variant: 'success' });
      navigate('/');
      await dispatch(loadMe(true));
    },
  });

  const [checkedTerms, setCheckedTerms] = useState(false);
  const [checkedCompensation, setCheckedCompensation] = useState(false);

  const handleCompensationCheckChanged = useCallback((event: unknown, checked: boolean) => {
    setCheckedTerms(checked);
  }, []);

  const handleCheckTermsChanged = useCallback((event: unknown, checked: boolean) => {
    setCheckedCompensation(checked);
  }, []);

  const handleConfirm = useCallback(() => {
    mutation.mutate();
  }, [mutation]);

  return (
    <MobileLayout
      backgroundColor={theme.palette.background.paper}
      header={<AppToolbar title={t('notinterested.title')} backButton={<BackIconButton onClick={backNavigator.back} />} />}
    >
      <Box
        display="flex"
        flexDirection="column"
        height={'100%'}
        sx={{ paddingX: 1, paddingBottom: 1, background: theme.palette.background.paper }}
        justifyContent={'space-between'}
      >
        <Box>
          <Paper elevation={0} sx={{ paddingTop: 0.5, paddingBottom: 0.5 }}>
            <Box>
              <Typography variant="body1" color="text.secondary">
                {latestBavTerms?.createdAt && tenant?.bavShowTerms
                  ? t('notinterested.termscheckWithDate', {
                      date: t('common:date', { date: new Date(latestBavTerms.createdAt) }),
                    })
                  : t('notinterested.termscheck')}
              </Typography>
              <FormControlLabel
                control={<Checkbox checked={checkedCompensation} onChange={handleCheckTermsChanged} />}
                label={t('notinterested.notedcheck')}
              />
            </Box>
            <Box marginTop={2}>
              <Typography variant="body1" color="text.secondary">
                {t('notinterested.compensationcheck')}
              </Typography>
              <FormControlLabel
                control={<Checkbox checked={checkedTerms} onChange={handleCompensationCheckChanged} />}
                label={t('notinterested.notedcheck')}
              />
            </Box>
          </Paper>
        </Box>
        <Box display="flex" flexDirection={'row'} justifyContent={'space-between'}>
          <LoadingButton
            loading={mutation.isPending}
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleConfirm}
            disabled={!checkedTerms || !checkedCompensation}
          >
            {t('notinterested.confirm')}
          </LoadingButton>
        </Box>
      </Box>
    </MobileLayout>
  );
};
export default BAVNotInterestedPage;
