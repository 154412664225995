import { KeepAwake } from '@capacitor-community/keep-awake';
import { ScreenBrightness } from '@capacitor-community/screen-brightness';
import { Capacitor } from '@capacitor/core';
import { useEffect } from 'react';

const isWeb = Capacitor.getPlatform() === 'web';
const isIOS = Capacitor.getPlatform() === 'ios';

const ANDROID_RESET_BRIGHTNESS = -1;
const IOS_DEFAULT_BRIGHTNESS = 0.5;

export function useFullBrightness(enabled?: boolean): void {
  useEffect(() => {
    if (!enabled || isWeb) {
      return;
    }

    let previousValue: number | undefined;
    let cancelled = false;
    void (async () => {
      previousValue = (await ScreenBrightness.getBrightness()).brightness;
      if (cancelled) return;
      await ScreenBrightness.setBrightness({ brightness: 1.0 });
      if (cancelled) return;
      await KeepAwake.keepAwake();
    })();

    return () => {
      cancelled = true;
      if (isIOS) {
        void ScreenBrightness.setBrightness({ brightness: previousValue ?? IOS_DEFAULT_BRIGHTNESS });
      } else {
        void ScreenBrightness.setBrightness({ brightness: ANDROID_RESET_BRIGHTNESS });
      }
      void KeepAwake.allowSleep();
    };
  }, [enabled]);
}
