import { Capacitor } from '@capacitor/core';
import { NativeSettings, AndroidSettings, IOSSettings } from 'capacitor-native-settings';

/**
 * Note that the only supported option by Apple is "App".
 * Using other options might break in future iOS versions
 * or have your app rejected from the App Store.
 */

export async function openPhoneSettings(): Promise<void> {
  switch (Capacitor.getPlatform()) {
    case 'android':
      return OpenAndroidSettings();
    case 'ios':
      return OpenIOSSettings();
    default:
      return;
  }
}

async function OpenAndroidSettings(): Promise<void> {
  await NativeSettings.openAndroid({
    option: AndroidSettings.ApplicationDetails,
  });
}

async function OpenIOSSettings(): Promise<void> {
  await NativeSettings.openIOS({
    option: IOSSettings.App,
  });
}
