import { Box, Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { CouponOrderDTOStatusEnum } from '../../../../../probonio-shared-ui/api';
import { CouponItemBackground } from '../components/CouponItemBackground';

interface Props {
  title: string;
  subTitles?: string[];
  leftIcon: React.ReactNode;
  rightIcon?: React.ReactNode;
  linkTo?: string;
  amount?: number;
  remainingAmount?: number;
  fade?: boolean;
  statusColor?: CouponOrderDTOStatusEnum;
  dataTestId?: string;
}

export const CouponRow: React.FC<Props> = ({
  title,
  subTitles,
  linkTo,
  leftIcon,
  rightIcon,
  amount,
  remainingAmount,
  fade,
  statusColor,
  dataTestId,
}) => {
  const { t } = useTranslation('couponsModule');
  const navigate = useNavigate();
  const showRightSide = rightIcon || amount !== undefined;

  // Navigates to OrderDetails if status is completed
  const handleNavigateTo = useCallback(() => {
    if (linkTo) {
      navigate(linkTo);
    }
  }, [linkTo, navigate]);

  return (
    <CouponItemBackground
      onClick={handleNavigateTo}
      sx={{
        opacity: fade ? '65%' : undefined,
        cursor: linkTo ? 'pointer' : undefined,
      }}
      container
      columns={15}
      data-test-id={dataTestId}
    >
      <Grid size={3} flexDirection="row" sx={{ display: 'flex', alignItems: 'center' }}>
        {leftIcon}
      </Grid>
      <Grid size={9}>
        <Typography variant="h3">{title}</Typography>
        <Stack direction={'row'} justifyContent={'space-between'} paddingRight={1}>
          {subTitles?.map(title => (
            <Typography key={title} variant="body2" color="text.secondary" className={`${statusColor}`} data-test-id="coupon-row-title">
              {title}
            </Typography>
          ))}
        </Stack>
      </Grid>
      <Grid size={3} flexDirection="row" sx={{ display: 'flex', alignItems: 'center' }}>
        {/* Shows dashed line */}
        {showRightSide && <Box className="vLine-dashed" />}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexGrow: 1,
            flexDirection: 'column',
            textAlign: 'center',
            alignItems: 'center',
          }}
        >
          {/* Shows an Icon if orderStatus is pending */}
          {rightIcon}

          {/* Shows voucher amount if orderStatus is completed */}
          {!rightIcon && amount && (
            <Typography
              color={remainingAmount && remainingAmount !== amount ? 'text.secondary' : ''}
              className={`${statusColor}`}
              variant="h2"
              sx={remainingAmount && remainingAmount !== amount ? { textDecoration: 'line-through' } : undefined}
              marginTop={remainingAmount && remainingAmount !== amount ? '-7px' : undefined}
            >
              {t('amount', { amount })}
            </Typography>
          )}
          {remainingAmount && remainingAmount !== amount && (
            <Typography className={`${statusColor}`} variant="h3" marginBottom={remainingAmount ? '-7px' : undefined}>
              {t('amount', { amount: remainingAmount })}
            </Typography>
          )}
        </Box>
      </Grid>
    </CouponItemBackground>
  );
};
