import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Box, Button, CssBaseline, ThemeProvider, Typography } from '@mui/material';
import { CouponDefinitionDTO, CouponDefinitionDTODisplayModeEnum, VoucherDetailsDTO } from 'probonio-shared-ui/api';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { BackIconButton } from '../../../component/button/BackIconButton';
import { useNewDialogState } from '../../../component/dialog/BasicDialog';
import { MobileLayout } from '../../../component/layout';
import { AppToolbar } from '../../../component/layout/AppToolbar';
import { useFullBrightness } from '../../../component/mobile/useFullBrightness';
import { MATERIAL_THEME_LIGHT } from '../../../theme';
import { LoadingFunction } from '../../../utils/loadingFunction';
import { useBackNavigator } from '../../navigation/BackNavigator';
import { getCouponImageUrl } from '../checkCouponImageUrl';
import { BarcodeCouponModel, CouponBarcode } from '../orderDetails/CouponBarcode';
import { CouponDetailsLine } from '../orderDetails/CouponDetailsLine';
import { UpdateCouponOrderRedeem } from '../redeem/CouponRedeemButton';
import { CouponRedeemedModal } from '../redeem/CouponRedeemedModal';

interface Props {
  isCouponPending?: boolean;
  amount: number;
  couponDefinition: CouponDefinitionDTO;
  voucherDetails: VoucherDetailsDTO;
  handleConfirmRedeemed: () => void;
  onUpdate: LoadingFunction<(value: UpdateCouponOrderRedeem) => void>;
  redeemDate?: string;
}

export const CouponsShowVoucherModal: React.FC<Props> = ({
  isCouponPending,
  amount,
  couponDefinition,
  voucherDetails,
  handleConfirmRedeemed,
  onUpdate,
  redeemDate,
}) => {
  const backNavigator = useBackNavigator();
  const { t } = useTranslation('couponsModule');
  const { dialogState, handleOpen: handleOpenDialog } = useNewDialogState();

  const { url: couponImageUrl } = getCouponImageUrl(couponDefinition);
  const voucherDisplayCode = voucherDetails.displayCode || voucherDetails.code;

  const barcodeCoupon = useMemo(
    (): BarcodeCouponModel => ({
      voucherCode: voucherDetails.code,
      voucherPin: voucherDetails.pin,
      voucherSerialNumber: voucherDetails.serialNumber,
      voucherUrl: voucherDetails.url,
    }),
    [voucherDetails],
  );

  useFullBrightness(true);

  const handleBack = useCallback(async () => {
    if (!redeemDate) {
      dialogState.handleClose();
    }
    backNavigator.back();
  }, [backNavigator, dialogState, redeemDate]);

  const wasLoading = useRef<boolean>();
  useEffect(() => {
    if (wasLoading.current && !onUpdate.isLoading) {
      void handleBack();
    }
    wasLoading.current = onUpdate.isLoading;
  }, [dialogState, handleBack, onUpdate.isLoading]);

  const handleBackAction = useCallback(async () => {
    if (!redeemDate) {
      handleOpenDialog();
    } else {
      void handleBack();
    }
  }, [handleBack, handleOpenDialog, redeemDate]);

  return (
    <ThemeProvider theme={MATERIAL_THEME_LIGHT}>
      <CssBaseline />
      <MobileLayout
        paperBackground
        header={<AppToolbar title={t('couponOrderDetails.voucherView')} backButton={<BackIconButton onClick={handleBackAction} />} />}
      >
        <Box sx={{ paddingX: 1, paddingBottom: 1 }}>
          {voucherDetails.url && couponDefinition.displayMode === CouponDefinitionDTODisplayModeEnum.VoucherUrlOnly ? (
            <Button fullWidth variant="contained" color="primary" endIcon={<OpenInNewIcon />} href={voucherDetails.url} target="_blank">
              {t('couponOrderDetails.voucherUrlLink')}
            </Button>
          ) : (
            <>
              <Box display="flex" sx={{ marginBottom: 0.75, alignItems: 'center', justifyContent: 'center' }} height={120}>
                <img
                  src={couponImageUrl}
                  style={{ borderRadius: 8, maxHeight: 120, maxWidth: 200 }}
                  alt={t('couponOrderDetails.logoAlt', { name: couponDefinition.name })}
                />
              </Box>
              <Typography component="h2" variant="extraBig" textAlign="center" sx={{ marginBottom: 1.25 }}>
                {t('common:money', { money: amount })}
              </Typography>
              <CouponBarcode
                format={
                  couponDefinition.displayMode === CouponDefinitionDTODisplayModeEnum.VoucherUrlOnly
                    ? undefined
                    : couponDefinition.barcodeFormat
                }
                coupon={barcodeCoupon}
                fallback={
                  couponDefinition.redeemUrl && (
                    <Button fullWidth variant="outlined" color="primary" href={couponDefinition.redeemUrl} target="_blank">
                      {t('couponOrderDetails.visitShop')}
                    </Button>
                  )
                }
              />
            </>
          )}

          {couponDefinition.displayMode === CouponDefinitionDTODisplayModeEnum.VoucherUrlOnly && (
            <Typography variant="body2" color="text.secondary" textAlign="center">
              <Trans i18nKey="couponsModule:couponOrderDetails.voucherUrlOnlyHint" />
            </Typography>
          )}
          {couponDefinition.displayMode !== CouponDefinitionDTODisplayModeEnum.VoucherUrlOnly && (
            <>
              <Box sx={{ marginTop: 2 }}>
                {voucherDisplayCode && <CouponDetailsLine title={t('couponOrderDetails.code')} value={voucherDisplayCode} copy />}
                {voucherDetails.pin && <CouponDetailsLine title={t('couponOrderDetails.pin')} value={voucherDetails.pin} copy />}
                {!isCouponPending && voucherDetails.url && (
                  <CouponDetailsLine
                    title={t('couponOrderDetails.originalVoucher')}
                    value={t('couponOrderDetails.clickToShow')}
                    link={voucherDetails.url}
                  />
                )}
              </Box>
            </>
          )}

          {!redeemDate && (
            <CouponRedeemedModal
              handleConfirm={handleConfirmRedeemed}
              dialogState={dialogState}
              isLoading={onUpdate.isLoading}
              handleCancel={handleBack}
            />
          )}

          {!isCouponPending && couponDefinition.checkBalanceUrl && (
            <>
              <Button
                fullWidth
                variant="contained"
                color="secondary"
                sx={{ marginTop: 1 }}
                href={couponDefinition.checkBalanceUrl}
                target="_blank"
              >
                {t('couponOrderDetails.visitBalanceCheckUrl')}
              </Button>
            </>
          )}
        </Box>
      </MobileLayout>
    </ThemeProvider>
  );
};
