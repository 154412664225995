/* tslint:disable */
/* eslint-disable */
/**
 * Probonio full API Docs
 * If you stumbled upon this page and want to integrate Probonio into your business software [click here!](/api/external)    If you\'re looking for more information about Probonio [head over here](https://probonio.de)    **This documentation describes all Endpoints of the Probonio API. It\'s created for experienced Probonio Developers only!**  
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface CouponVariationAvailabilityDTO
 */
export interface CouponVariationAvailabilityDTO {
    /**
     * 
     * @type {string}
     * @memberof CouponVariationAvailabilityDTO
     */
    'availability'?: CouponVariationAvailabilityDTOAvailabilityEnum;
    /**
     * 
     * @type {number}
     * @memberof CouponVariationAvailabilityDTO
     */
    'value': number;
}

export const CouponVariationAvailabilityDTOAvailabilityEnum = {
    InStock: 'IN_STOCK',
    InStockUnlimited: 'IN_STOCK_UNLIMITED',
    OnDemand: 'ON_DEMAND',
    OutOfStock: 'OUT_OF_STOCK'
} as const;

export type CouponVariationAvailabilityDTOAvailabilityEnum = typeof CouponVariationAvailabilityDTOAvailabilityEnum[keyof typeof CouponVariationAvailabilityDTOAvailabilityEnum];


