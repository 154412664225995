import React from 'react';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import { Box, Button, ButtonProps, styled } from '@mui/material';

const FullWidthButtonStyled = styled(Button)(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(0.75),
  textAlign: 'left',
  textTransform: 'none',
  display: 'flex',
  alignItems: 'center',
  color: theme.palette.primary.main,
  backgroundColor: theme.palette.primary.contrastText,
  '&:hover': {
    backgroundColor: theme.palette.primary.contrastText,
  },
  '& .icon': {
    marginRight: theme.spacing(0.75),
    lineHeight: 0,
  },
  '& .header': {
    color: theme.palette.text.primary,
    fontSize: theme.typography.body1.fontSize,
  },
  '& .subtitle': {
    fontSize: theme.typography.body2.fontSize,
  },
  '& .arrow': {
    marginLeft: theme.spacing(0.75),
    lineHeight: 0,
  },
}));

interface Props {
  iconLeft?: React.ReactNode;
  iconRight?: React.ReactNode;
  children?: React.ReactNode;
}

const NavigationButton: React.FC<Props & ButtonProps> = ({
  iconRight = <ChevronRightIcon fontSize="extraLarge" sx={{ color: 'text.secondary', marginX: '-0.33em' }} />,
  iconLeft,
  children,
  ...props
}) => {
  return (
    <FullWidthButtonStyled {...props}>
      {iconLeft && <Box className="icon">{iconLeft}</Box>}
      <Box sx={{ display: 'flex', flexGrow: 1, flexDirection: 'column' }}>{children}</Box>
      <Box className="arrow">
        {iconRight}
        {/* negative margin to compensate for icon padding in svg from MUI */}
      </Box>
    </FullWidthButtonStyled>
  );
};

export default NavigationButton;
