import { Box } from '@mui/material';
import { BenefitDTOBenefitEnum, BikeleasingBenefitDetailsDTO } from 'probonio-shared-ui/api';
import { CircularProgressBar } from 'probonio-shared-ui/component/progress';
import { apis, useEmployeeQuery } from 'probonio-shared-ui/module/api';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { BackIconButton } from '../../component/button/BackIconButton';
import { MobileLayout } from '../../component/layout';
import { AppToolbar } from '../../component/layout/AppToolbar';
import { BikeleasingContractDetails } from '../../module/benefit/bikeleasing/BikeleasingContractDetails';
import { useActiveBenefitsQuery } from '../../module/benefit/useActiveBenefitsQuery';
import { useBackNavigator } from '../../module/navigation/BackNavigator';

export const BikeleasingContractDetailsPage: React.FC = () => {
  const { contractId } = useParams();
  const { back } = useBackNavigator();
  const { data: activeBenefits } = useActiveBenefitsQuery();
  const { t } = useTranslation('benefitModule');

  const bikeleasingBenefitDetails = useMemo(
    () =>
      activeBenefits?.benefits.find(activeBenefit => activeBenefit.benefit === BenefitDTOBenefitEnum.Bikeleasing)?.details as
        | BikeleasingBenefitDetailsDTO
        | undefined,
    [activeBenefits?.benefits],
  );

  const { data: bikeleasingContract } = useEmployeeQuery(['benefit', 'bikeleasing', 'contract', contractId!], params =>
    apis.bikeLeasing.getBikeleasingContractById({ ...params, contractId: contractId! }).then(res => res.data),
  );

  const content = useMemo(() => {
    if (bikeleasingContract && bikeleasingBenefitDetails?.bikeleasingCondition) {
      return <BikeleasingContractDetails condition={bikeleasingBenefitDetails.bikeleasingCondition} contract={bikeleasingContract} />;
    }
    return (
      <Box width="100%" height="100%" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <CircularProgressBar />
      </Box>
    );
  }, [bikeleasingBenefitDetails, bikeleasingContract]);

  useEffect(() => {
    if (!contractId) {
      back();
    }
  }, [back, contractId]);

  return (
    <MobileLayout
      header={
        <AppToolbar
          title={t('benefit.BIKELEASING.contractDetails', { contractNumber: bikeleasingContract?.contractNumber })}
          backButton={<BackIconButton onClick={back} />}
        />
      }
    >
      {content}
    </MobileLayout>
  );
};
