import EuroSymbolIcon from '@mui/icons-material/EuroSymbol';
import SellIcon from '@mui/icons-material/Sell';
import SyncIcon from '@mui/icons-material/Sync';
import { Box, useTheme } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ButtonTab, CircularFloatingButton, CircularFloatingButtonTabs } from '../../component/button';
import { BackIconButton } from '../../component/button/BackIconButton';
import { MobileLayout } from '../../component/layout';
import { AppToolbar } from '../../component/layout/AppToolbar';
import { AutomaticSelection, useAvailableCoupons } from '../../module/coupons';
import { CouponStatus } from '../../module/coupons/CouponStatus';
import { resetCouponsState } from '../../module/coupons/redux';
import { CouponOrderList } from '../../module/couponsShared/orderList/CouponOrderList';
import { HighlightGuideButton } from '../../module/guiding';
import { useBackNavigator } from '../../module/navigation/BackNavigator';
import { useAppDispatch } from '../../redux/hooks';

const CouponsPage: React.FC = () => {
  const { t } = useTranslation('couponsModule');
  const backNavigator = useBackNavigator();
  const theme = useTheme();
  const [selectedTab, setSelectedTab] = useState(ButtonTab.left);
  const navigate = useNavigate();
  const { availableCoupons, couponsQuery } = useAvailableCoupons();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(resetCouponsState());
  }, [dispatch]);

  const handleGoToExploreCoupons = useCallback(() => {
    navigate('/coupons/explore-coupons/');
  }, [navigate]);

  const navigateInfo = useCallback(() => {
    navigate('info');
  }, [navigate]);

  return (
    <MobileLayout
      iosOverloadScrollBackground={theme.palette.background.paper}
      header={
        <>
          <AppToolbar
            title={t('coupons.title')}
            color={theme.palette.primary.contrastText}
            backButton={<BackIconButton onClick={backNavigator.back} />}
            actionButton={
              <HighlightGuideButton
                name={t('benefitModule:benefit.COUPONS.firstHelpGuide.name')}
                header={t('benefitModule:benefit.COUPONS.firstHelpGuide.header')}
                text={t('benefitModule:benefit.COUPONS.firstHelpGuide.text')}
                onClick={navigateInfo}
              />
            }
          />
        </>
      }
    >
      <Box
        sx={{
          textAlign: 'center',
          paddingBottom: 0.5,
          paddingTop: 0.2,
          backgroundColor: theme.palette.primary.contrastText,
        }}
      >
        {availableCoupons && selectedTab === ButtonTab.left && <CouponStatus onClick={handleGoToExploreCoupons} />}
        {selectedTab === ButtonTab.right && <AutomaticSelection />}
      </Box>
      <CircularFloatingButton
        buttonProps={{ onClick: handleGoToExploreCoupons }}
        borderColor={theme.palette.primary.main}
        tabs={
          couponsQuery.data?.details.couponDefaultAllowed ? (
            <CircularFloatingButtonTabs
              defaultValue={selectedTab}
              handleTabChange={setSelectedTab}
              leftIcon={<EuroSymbolIcon />}
              rightIcon={<SyncIcon />}
              disabled={!couponsQuery.data?.details.defaultCouponId || !availableCoupons?.currentlyActivated}
            />
          ) : undefined
        }
      >
        <SellIcon />
      </CircularFloatingButton>
      <CouponOrderList activeVersion="V1" />
    </MobileLayout>
  );
};

export default CouponsPage;
