import { Box, LinearProgress, Typography } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { ReceiptAccordion } from '../benefit/receiptAccordion/ReceiptAccordion';
import { useRecreationPeriods } from './RecreationPeriodsContext';
import { RecreationReceiptList } from './RecreationPeriodList';

export const RecreationPeriods: React.FC = () => {
  const { t } = useTranslation('benefitModule');
  const {
    currentPeriod,
    previousPeriod,
    recreationPeriodsQuery: { data: recreationPeriods, isLoading, isSuccess, isError },
    selectPeriod,
    selectedPeriodYear: selectedPeriod,
  } = useRecreationPeriods();

  const { search } = useLocation();
  const initialOpenPeriod = useMemo(() => new URLSearchParams(search).get('initialOpenPeriod'), [search]);
  const [isInitialLoaded, setIsInitialLoaded] = useState(false);

  useEffect(() => {
    if (!isInitialLoaded && isSuccess && (selectedPeriod || currentPeriod?.year || previousPeriod?.year)) {
      setIsInitialLoaded(true);
      selectPeriod(selectedPeriod || initialOpenPeriod || currentPeriod?.year || previousPeriod?.year);
    }
  }, [selectedPeriod, initialOpenPeriod, isSuccess, selectPeriod, currentPeriod?.year, isInitialLoaded, previousPeriod?.year]);

  const handleTogglePeriod = useCallback(
    (period: string) => () => {
      if (selectedPeriod === period) {
        selectPeriod(undefined);
        return;
      }

      selectPeriod(period);
    },
    [selectedPeriod, selectPeriod],
  );

  return (
    <Box>
      <Box sx={{ marginY: 1 }}>
        <Typography variant="h5">{t('benefit.RECREATION.receiptsTitle')}</Typography>
      </Box>
      <Box>
        {isLoading && <LinearProgress />}
        {isError && <Typography>{t('benefit.RECREATION.noReceipt')}</Typography>}
        {!recreationPeriods && <Typography>{t('benefit.RECREATION.noPeriod')}</Typography>}
        {isSuccess &&
          recreationPeriods &&
          recreationPeriods.recreationPeriods.map(period => (
            <ReceiptAccordion
              onChange={handleTogglePeriod(period.year)}
              monthName={period.year}
              totalLimit={period.refund}
              isLocked={period.isLocked}
              key={period.year}
              expanded={selectedPeriod === period.year}
            >
              <RecreationReceiptList recreationPeriod={period.year} />
            </ReceiptAccordion>
          ))}
      </Box>
    </Box>
  );
};
