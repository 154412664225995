/* tslint:disable */
/* eslint-disable */
/**
 * Probonio full API Docs
 * If you stumbled upon this page and want to integrate Probonio into your business software [click here!](/api/external)    If you\'re looking for more information about Probonio [head over here](https://probonio.de)    **This documentation describes all Endpoints of the Probonio API. It\'s created for experienced Probonio Developers only!**  
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { BaseEmployeeDTO } from './base-employee-dto';
import { TenantRoleDTO } from './tenant-role-dto';

/**
 * 
 * @export
 * @interface UserDTO
 */
export interface UserDTO {
    /**
     * 
     * @type {Array<string>}
     * @memberof UserDTO
     */
    'roles': Array<UserDTORolesEnum>;
    /**
     * 
     * @type {string}
     * @memberof UserDTO
     */
    'status': UserDTOStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof UserDTO
     */
    'legalTermsAction'?: UserDTOLegalTermsActionEnum;
    /**
     * 
     * @type {string}
     * @memberof UserDTO
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof UserDTO
     */
    'idpId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDTO
     */
    'locale'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDTO
     */
    'localeManager'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDTO
     */
    'salutation'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDTO
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDTO
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDTO
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDTO
     */
    'mail'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDTO
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDTO
     */
    'mobilePhone'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDTO
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof UserDTO
     */
    'privacyAgreementDate'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserDTO
     */
    'showWelcomeMessage'?: boolean;
    /**
     * 
     * @type {Array<BaseEmployeeDTO>}
     * @memberof UserDTO
     */
    'employments'?: Array<BaseEmployeeDTO>;
    /**
     * 
     * @type {string}
     * @memberof UserDTO
     */
    'expiryDateRegisterLink'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserDTO
     */
    'mailDeliveryError'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserDTO
     */
    'referralCode'?: string;
    /**
     * 
     * @type {Array<TenantRoleDTO>}
     * @memberof UserDTO
     */
    'tenantRoles'?: Array<TenantRoleDTO>;
    /**
     * 
     * @type {string}
     * @memberof UserDTO
     */
    'legalTermsUserLastConsentDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDTO
     */
    'deleteRequestedAt'?: string;
}

export const UserDTORolesEnum = {
    Admin: 'ADMIN',
    BavManager: 'BAV_MANAGER',
    Audit: 'AUDIT',
    SupportViewer: 'SUPPORT_VIEWER',
    SalesManager: 'SALES_MANAGER',
    CustomerSuccess: 'CUSTOMER_SUCCESS',
    CouponBuyer: 'COUPON_BUYER'
} as const;

export type UserDTORolesEnum = typeof UserDTORolesEnum[keyof typeof UserDTORolesEnum];
export const UserDTOStatusEnum = {
    Active: 'ACTIVE',
    Inactive: 'INACTIVE',
    Pending: 'PENDING',
    Created: 'CREATED',
    Deleted: 'DELETED'
} as const;

export type UserDTOStatusEnum = typeof UserDTOStatusEnum[keyof typeof UserDTOStatusEnum];
export const UserDTOLegalTermsActionEnum = {
    None: 'NONE',
    Skip: 'SKIP',
    Consent: 'CONSENT'
} as const;

export type UserDTOLegalTermsActionEnum = typeof UserDTOLegalTermsActionEnum[keyof typeof UserDTOLegalTermsActionEnum];


