import { useTranslation } from 'react-i18next';

const PlayStoreIcon: React.FC = () => {
  const { i18n } = useTranslation();

  return (
    <>
      {i18n.resolvedLanguage === 'de' ? (
        <a href="https://play.google.com/store/apps/details?id=de.probonio.probonio">
          <img src="/image/play-store-button.svg" alt="play-store-icon" height="40px" />
        </a>
      ) : (
        <a href="https://play.google.com/store/apps/details?id=de.probonio.probonio">
          <img src="/image/play-store-button-english.svg" alt="play-store-icon-english" height="40px" />
        </a>
      )}
    </>
  );
};

export default PlayStoreIcon;
