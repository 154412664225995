import { Typography } from '@mui/material';
import React from 'react';
import { CurrencyInput } from '.';
import { CalculatorState } from './useCalculatorState';

interface Props {
  placeholder?: string;
  hideCalculator?: boolean;
  state: CalculatorState;
  onSubmit: () => void;
}

export const CalculatorFormField: React.FC<Props> = ({ placeholder, hideCalculator, state, onSubmit }) => {
  return (
    <>
      <Typography variant="paragraph" color="primary" paddingBottom={0.3} sx={{ textAlign: 'center' }}>
        {state.operatorArray.length > 0 ? state.calculatorString : placeholder}
      </Typography>
      <CurrencyInput
        onSubmit={onSubmit}
        currentInputValue={state.currentInput}
        calculatorResult={state.calculatorResult}
        maxNumber={999999}
        hideCalculator={!!hideCalculator}
        setCurrentInputValue={state.setCurrentInput}
        onCalculatorStringChange={state.setCalculatorString}
        onCalculatorResultChange={state.setCalculatorResult}
        onValuesArrayChange={state.setValuesArray}
        onOperatorArrayChange={state.setOperatorArray}
        valuesArray={state.valuesArray}
        operatorArray={state.operatorArray}
      />
    </>
  );
};
