import EditIcon from '@mui/icons-material/Edit';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { Box, CircularProgress, IconButton } from '@mui/material';
import { useIsMutating } from '@tanstack/react-query';
import { BenefitDTOBenefitEnum, LunchReceiptRefundDTOStatusEnum } from 'probonio-shared-ui/api';
import { apis, useEmployeeQuery } from 'probonio-shared-ui/module/api';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { BackIconButton } from '../../component/button/BackIconButton';
import { LinkIconButton } from '../../component/button/LinkIconButton';
import { MobileLayout } from '../../component/layout';
import { AppToolbar } from '../../component/layout/AppToolbar';
import { ReceiptDetail } from '../../module/benefit/receipt/ReceiptDetail';
import { useBackNavigator } from '../../module/navigation/BackNavigator';
import { DeleteRecreationReceiptButton } from '../../module/recreation/DeleteRecreationReceiptButton';
import { useRecreationPeriods } from '../../module/recreation/RecreationPeriodsContext';

export const RecreationReceiptDetailPage: React.FC = () => {
  const [isQueryDisabled, setIsQueryDisabled] = useState(false);
  const { t } = useTranslation('benefitModule');
  const {
    selectedPeriod,
    selectPeriod,
    receiptsQuery: { data: receipts, isLoading },
  } = useRecreationPeriods();
  const { receiptId, recreationPeriod } = useParams();
  const isMutating = useIsMutating();
  const navigate = useNavigate();
  const backNavigator = useBackNavigator();
  const handleEdit = useCallback(() => {
    navigate(`/recreation/receipt/edit/${recreationPeriod}/${receiptId}`);
  }, [navigate, recreationPeriod, receiptId]);
  useEffect(() => {
    if (!selectedPeriod || selectedPeriod.year !== recreationPeriod) {
      selectPeriod(recreationPeriod);
    }
  }, [recreationPeriod, selectPeriod, selectedPeriod]);
  const isPeriodLocked = selectedPeriod?.isLocked || false;
  const receipt = useMemo(() => receipts?.receipts.find(r => r.id === receiptId), [receiptId, receipts?.receipts]);
  // Load the receipt only for the image url, as that is not included in the period-receipt list.
  const { data: receiptWithImage } = useEmployeeQuery(
    ['benefits', 'recreation', 'periods', 'receipts', receipt?.id, { withImage: true }, apis.recreation.getReceipt.name],
    params => apis.recreation.getReceipt({ ...params, receiptId: receipt!.id, withImage: true }).then(res => res.data),
    {
      staleTime: 120000,
      placeholderData: previousData => previousData,
      enabled: !!receipt?.id && !isQueryDisabled,
    },
  );

  return (
    <MobileLayout
      header={
        <AppToolbar
          title={t('benefit.RECREATION.detailPage.title')}
          backButton={<BackIconButton onClick={backNavigator.back} />}
          actionButton={
            <>
              {receiptWithImage?.pdfURL && (
                <IconButton href={receiptWithImage?.pdfURL} target="_blank" title={t('benefit.RECREATION.openPDF')}>
                  <PictureAsPdfIcon />
                </IconButton>
              )}
              {receipt && (
                <DeleteRecreationReceiptButton
                  receipt={receipt}
                  disabled={isPeriodLocked || receipt.status === LunchReceiptRefundDTOStatusEnum.Approved}
                  setIsQueryDisabled={setIsQueryDisabled}
                />
              )}
              <LinkIconButton
                onClick={handleEdit}
                disabled={!!isMutating || isPeriodLocked || receipt?.status !== LunchReceiptRefundDTOStatusEnum.Rejected}
              >
                <EditIcon />
              </LinkIconButton>
            </>
          }
        />
      }
    >
      {!receipt && (
        <Box sx={{ textAlign: 'center' }}>
          <CircularProgress />
        </Box>
      )}
      {receipt && !isLoading && (
        <ReceiptDetail
          receipt={{
            ...receipt,
          }}
          imageURL={receiptWithImage?.imageURL!}
          thumbnailURL={receiptWithImage?.thumbnailURL}
          benefit={BenefitDTOBenefitEnum.Recreation}
        />
      )}
    </MobileLayout>
  );
};
