import { CircularProgress } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { CreateBAVEstimateRequestDTONumberOfKidsEnum, CreateBAVEstimateRequestDTOTaxClassEnum } from 'probonio-shared-ui/api';
import { apis } from 'probonio-shared-ui/module/api';
import { useActiveEmployment } from 'probonio-shared-ui/module/me';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BackIconButton } from '../../component/button/BackIconButton';
import { MobileLayout } from '../../component/layout';
import { AppToolbar } from '../../component/layout/AppToolbar';
import { BAVCalculatorForm } from '../../module/bav/BAVCalculatorForm';
import { BAVPhoneContactDialog, PhoneFormValues } from '../../module/bav/BAVPhoneContactDialog';
import { BAVRequestEstimateForm, FormValues } from '../../module/bav/BAVRequestEstimateForm';
import { useBackNavigator } from '../../module/navigation/BackNavigator';
import { BAVCalculatorMeeting } from './BAVCalculatorMeeting';
import { useBAV } from './BAVContext';

export const BAVCalculator: React.FC = () => {
  const backNavigator = useBackNavigator();
  const { t } = useTranslation('bavModule');
  const [step, setStep] = useState(0);
  const [yearOfBirth, setYearOfBirth] = useState(0);
  const [savingsRate, setSavingsRate] = useState(0);
  const getActiveEmployment = useActiveEmployment();
  const { bavDetails } = useBAV();
  const [requestValues, setRequestValues] = useState<FormValues & Partial<PhoneFormValues>>();

  const handleSubmitCalculator = useCallback((calculatorYearOfBirth: number, newSavingsRate: number) => {
    setYearOfBirth(calculatorYearOfBirth);
    setSavingsRate(newSavingsRate);
    setStep(1);
  }, []);

  const mutation = useMutation({
    mutationFn: async (values: FormValues & Partial<PhoneFormValues>) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { acceptDataPrivacy, ...dto } = values;
      const { tenantId, id: employeeId } = getActiveEmployment();
      await apis.bav.createEstimateRequest({
        tenantId,
        employeeId,
        createBAVEstimateRequestDTO: {
          ...dto,
          taxClass: values.taxClass as CreateBAVEstimateRequestDTOTaxClassEnum,
          numberOfKids: values.numberOfKids as CreateBAVEstimateRequestDTONumberOfKidsEnum,
          salary: Number(values.salary),
          savingsRate: Number(savingsRate),
          privateCareInsuranceRate: Number(values.privateCareInsuranceRate),
          privateHealthInsuranceRate: Number(values.privateHealthInsuranceRate),
          phoneNumber: values.phoneNumber || undefined,
        },
      });
    },
  });

  const handleSubmitEstimate = useCallback(
    (values: FormValues) => {
      setRequestValues(values);
      setStep(2);
      mutation.mutate(values);
    },
    [mutation],
  );

  const handleSubmitPhoneContact = useCallback(
    (values: PhoneFormValues) => {
      setRequestValues({ ...requestValues!, ...values });
      setStep(3);
      mutation.mutate({ ...requestValues!, ...values });
    },
    [mutation, requestValues],
  );

  const handleBack = useCallback(() => {
    if (step === 0) backNavigator.back();
    else setStep(step - 1);
  }, [backNavigator, step]);

  return (
    <MobileLayout paddedBody header={<AppToolbar title={t('subPages.calculator')} backButton={<BackIconButton onClick={handleBack} />} />}>
      {bavDetails ? (
        <>
          {step === 0 && <BAVCalculatorForm bavDetails={bavDetails} onSubmit={handleSubmitCalculator} />}
          {step === 1 && <BAVRequestEstimateForm yearOfBirth={yearOfBirth} onSubmit={handleSubmitEstimate} />}
          {step === 2 && <BAVPhoneContactDialog onSubmit={handleSubmitPhoneContact} />}
          {step === 3 && <BAVCalculatorMeeting phoneNumber={requestValues?.phoneNumber} />}
        </>
      ) : (
        <CircularProgress />
      )}
    </MobileLayout>
  );
};
