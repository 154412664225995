import { useQueryClient, UseQueryResult } from '@tanstack/react-query';
import { DateTime } from 'luxon';
import { MobilityPeriodDTO, MobilityPeriodsDTO, MobilityReceiptsDTO } from 'probonio-shared-ui/api';
import { apis, useEmployeeQuery } from 'probonio-shared-ui/module/api';
import { useActiveEmployment } from 'probonio-shared-ui/module/me';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';

export interface MobilityPeriodsState {
  mobilityPeriodsQuery: UseQueryResult<MobilityPeriodsDTO, Error>;
  previousPeriod?: MobilityPeriodDTO;
  currentPeriod?: MobilityPeriodDTO;
  receiptsQuery: UseQueryResult<MobilityReceiptsDTO, Error>;
  refetchMobility: () => void;
  selectPeriod: (month?: string) => void;
  selectedPeriodMonth?: string;
  selectedPeriod?: MobilityPeriodDTO;
}

const MobilityPeriodsContext = createContext<MobilityPeriodsState | undefined>(undefined);

export const MobilityPeriodsProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const queryClient = useQueryClient();
  const getActiveEmployment = useActiveEmployment();
  const [selectedPeriodMonth, setSelectedPeriodMonth] = useState<string>();

  const startMonth = DateTime.utc().minus({ years: 1 }).startOf('month').toFormat('yyyy-MM');
  const endMonth = DateTime.utc().endOf('month').toFormat('yyyy-MM');

  const periodsQuery: UseQueryResult<MobilityPeriodsDTO, Error> = useEmployeeQuery(
    [
      'benefits',
      'mobility',
      'periods',
      {
        startMonth,
        endMonth,
      },
      apis.mobility.getPeriods.name,
    ],
    params =>
      apis.mobility
        .getPeriods({
          ...params,
          startMonth,
          endMonth,
        })
        .then(res => res.data),
    { refetchOnMount: false },
  );

  const selectedPeriod = useMemo(() => {
    if (selectedPeriodMonth && periodsQuery.data) {
      return periodsQuery.data.mobilityPeriods.find(period => period.month === selectedPeriodMonth);
    }
  }, [periodsQuery.data, selectedPeriodMonth]);
  const receiptsQuery: UseQueryResult<MobilityReceiptsDTO, Error> = useEmployeeQuery(
    ['benefits', 'mobility', 'periods', selectedPeriod, 'receipts', apis.mobility.getPeriodReceipts.name],
    params => apis.mobility.getPeriodReceipts({ ...params, month: selectedPeriodMonth! }).then(res => res.data),
    {
      enabled: !!selectedPeriodMonth,
      // Refetch only after min 60 seconds to prevent api calls on every open / close of the accordion.
      staleTime: 60000,
    },
  );

  useEffect(() => {
    if (selectedPeriod === undefined) {
      queryClient.removeQueries({
        queryKey: ['benefits', 'mobility', 'periods', selectedPeriod, 'receipts', apis.mobility.getPeriodReceipts.name],
      });
    }
  }, [queryClient, receiptsQuery, selectedPeriod]);

  const context = useMemo<MobilityPeriodsState>(
    () => ({
      mobilityPeriodsQuery: periodsQuery,
      previousPeriod: periodsQuery.data?.mobilityPeriods[1],
      currentPeriod: periodsQuery.data?.mobilityPeriods[0],
      receiptsQuery: receiptsQuery,
      refetchMobility: async () => {
        return queryClient.invalidateQueries({
          queryKey: ['tenants', getActiveEmployment().tenantId, 'employees', getActiveEmployment().id, 'benefits', 'mobility'],
        });
      },
      selectPeriod: setSelectedPeriodMonth,
      selectedPeriodMonth: selectedPeriodMonth,
      selectedPeriod: selectedPeriod,
    }),
    [getActiveEmployment, periodsQuery, queryClient, receiptsQuery, selectedPeriod, selectedPeriodMonth],
  );

  return <MobilityPeriodsContext.Provider value={context}>{children}</MobilityPeriodsContext.Provider>;
};

export function useMobilityPeriods(): MobilityPeriodsState {
  const ctx = useContext(MobilityPeriodsContext);
  // Throw error that the Context is not injected, if it is undefined.
  if (ctx === undefined) {
    throw new Error('useMobilityPeriods must be used within a MobilityPeriodsProvider');
  }
  return ctx;
}
