import DeleteIcon from '@mui/icons-material/Delete';
import { useSnackbar } from 'notistack';
import { RecreationReceiptDTO } from 'probonio-shared-ui/api';
import { apis } from 'probonio-shared-ui/module/api';
import { useWithMessage } from 'probonio-shared-ui/module/error';
import { useActiveEmployment } from 'probonio-shared-ui/module/me';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import { useBackNavigator } from '../navigation/BackNavigator';
import { useRecreationPeriods } from './RecreationPeriodsContext';
import { ConfirmationModal } from '../../component/confirmationModal';
import { LinkIconButton } from '../../component/button/LinkIconButton';

interface Props {
  receipt: RecreationReceiptDTO;
  setIsQueryDisabled: (bool: boolean) => void;
  disabled?: boolean;
}

export const DeleteRecreationReceiptButton: React.FC<Props> = ({ receipt, disabled, setIsQueryDisabled }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation('benefitModule');
  const { enqueueSnackbar } = useSnackbar();
  const backNavigator = useBackNavigator();
  const withMessage = useWithMessage();
  const getActiveEmployment = useActiveEmployment();
  const { refetchRecreation } = useRecreationPeriods();

  const navigateToRecreationAndReload = useCallback(() => {
    refetchRecreation();
    backNavigator.back();
  }, [backNavigator, refetchRecreation]);

  const deleteMutation = useMutation({
    mutationFn: () =>
      apis.recreation.deleteReceipt({
        tenantId: getActiveEmployment().tenantId,
        employeeId: getActiveEmployment().id,
        receiptId: receipt.id!,
      }),

    onSuccess: () => {
      enqueueSnackbar(t('benefit.RECREATION.deleteReceipt.successToast'), { variant: 'success' });
      navigateToRecreationAndReload();
    },
    onError: (err: Error) => {
      setIsQueryDisabled(false);
      withMessage(err);
    },
  });

  const handleDelete = useCallback(() => {
    setIsQueryDisabled(true);
    deleteMutation.mutate();
  }, [setIsQueryDisabled, deleteMutation]);

  const handleCancel = useCallback(() => {
    setIsOpen(false);
  }, []);

  const handleOpenDialog = useCallback(() => {
    setIsOpen(true);
  }, []);

  return (
    <>
      <ConfirmationModal
        onCancel={handleCancel}
        onSubmit={handleDelete}
        isOpen={isOpen}
        message={t('benefit.RECREATION.deleteReceipt.confirmMessage', receipt && { date: new Date(receipt.date) })}
        title={t('benefit.RECREATION.deleteReceipt.title')}
      />
      <LinkIconButton
        onClick={handleOpenDialog}
        title={t('benefit.RECREATION.deleteReceipt.title')}
        disabled={deleteMutation.isPending || disabled}
      >
        <DeleteIcon />
      </LinkIconButton>
    </>
  );
};
