import { Alert, Box, Button, Stack, Typography } from '@mui/material';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useUserDeleteRequest } from './useUserDeleteRequest';
import { BasicDialog, DialogState, useNewDialogState } from '../../component/dialog/BasicDialog';
import { LoadingButton } from 'probonio-shared-ui/component/button';
import { useQueryClient } from '@tanstack/react-query';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { loadMe } from 'probonio-shared-ui/module/me';
export interface DeleteRequestProps {
  userId: string;
}
export const USER_DELETE_REQUEST_KEY = 'user-delete-request';

const UserDeleteRequestDialog: React.FC<{
  dialogState: DialogState;
  onConfirm: () => void;
  isLoading?: boolean;
}> = ({ dialogState, onConfirm, isLoading }) => {
  const { t } = useTranslation('profilePage');

  return (
    <Box>
      <BasicDialog
        title={t('deletionRequest.modal.title')}
        dialogState={dialogState}
        content={
          <Box paddingBottom={0.6}>
            <Typography>{t('deletionRequest.modal.message')}</Typography>
          </Box>
        }
        dataTestId="coupon-redeemed-modal"
        actions={
          <Box display="flex" justifyContent="space-between" width={'100%'}>
            <Button disabled={isLoading} onClick={dialogState.handleClose}>
              {t('deletionRequest.modal.cancel')}
            </Button>
            <LoadingButton loading={isLoading} onClick={onConfirm}>
              {t('deletionRequest.modal.confirm')}
            </LoadingButton>
          </Box>
        }
      />
    </Box>
  );
};

export const UserDeleteRequestButton: React.FC<DeleteRequestProps> = ({ userId }) => {
  const { t } = useTranslation('profilePage');
  const { dialogState, handleOpen: handleOpenDialog } = useNewDialogState();
  const queryClient = useQueryClient();
  const deleteRequestedAt = useAppSelector(state => state.me.user?.deleteRequestedAt);
  const dispatch = useAppDispatch();

  const handleDeleteRequestSuccess = useCallback(async () => {
    await queryClient.invalidateQueries({ queryKey: ['me'] });
    await dispatch(loadMe(true));
    dialogState.handleClose();
  }, [dialogState, dispatch, queryClient]);

  const deleteRequestMutation = useUserDeleteRequest(handleDeleteRequestSuccess);

  const handleDeleteRequest = useCallback(() => {
    deleteRequestMutation.mutate({ userId });
  }, [deleteRequestMutation, userId]);

  return (
    <Stack direction="row" justifyContent="center">
      {deleteRequestedAt ? (
        <Alert severity="error" sx={{ marginBottom: 0.5 }}>
          {t('deletionRequest.alert')}
        </Alert>
      ) : (
        <>
          <LoadingButton variant="outlined" color="error" size="small" onClick={handleOpenDialog}>
            {t('deletionRequest.button')}
          </LoadingButton>
          <UserDeleteRequestDialog onConfirm={handleDeleteRequest} isLoading={deleteRequestMutation.isPending} dialogState={dialogState} />
        </>
      )}
    </Stack>
  );
};
