import { Box, Typography, useTheme } from '@mui/material';
import { useRef } from 'react';
import { FullWidthCenterBox } from '../layout/BoxLayout';

interface BoxProps {
  active?: boolean;
  value: number;
}

export const NumberBox: React.FC<BoxProps> = ({ active, value }) => {
  const theme = useTheme();
  const ref = useRef<HTMLDivElement>(null);

  return (
    <Box
      mt={1}
      ref={ref}
      justifyContent={'space-between'}
      display={'flex'}
      flexDirection={'column'}
      sx={{
        boxSizing: 'border-box',
        borderWidth: 2,
        backgroundColor: active ? theme.palette.primary.main : '',
        borderColor: theme.palette.primary.main,
        color: active ? theme.palette.primary.main : 'inherit',
        borderStyle: 'solid',
        borderRadius: 1,
        height: theme.spacing(3.3),
        width: theme.spacing(3.3),
        display: 'inline-block',
        flexShrink: 0,
        cursor: 'pointer',
      }}
    >
      <FullWidthCenterBox sx={{ height: '100%', alignItems: 'center', borderRadius: 0.5 }}>
        <Typography sx={{ color: active ? theme.palette.primary.contrastText : 'text.secondary' }} variant="extraBig" fontWeight={'500'}>
          {value}
        </Typography>
      </FullWidthCenterBox>
    </Box>
  );
};
