/* tslint:disable */
/* eslint-disable */
/**
 * Probonio full API Docs
 * If you stumbled upon this page and want to integrate Probonio into your business software [click here!](/api/external)    If you\'re looking for more information about Probonio [head over here](https://probonio.de)    **This documentation describes all Endpoints of the Probonio API. It\'s created for experienced Probonio Developers only!**  
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { CouponDefinitionDTO } from './coupon-definition-dto';
import { VoucherDetailsDTO } from './voucher-details-dto';

/**
 * 
 * @export
 * @interface CouponsV2UserOrderDTO
 */
export interface CouponsV2UserOrderDTO {
    /**
     * 
     * @type {string}
     * @memberof CouponsV2UserOrderDTO
     */
    'status': CouponsV2UserOrderDTOStatusEnum;
    /**
     * For compatibility to v1 orders
     * @type {number}
     * @memberof CouponsV2UserOrderDTO
     * @deprecated
     */
    'couponCount': number;
    /**
     * For compatibility to v1 orders
     * @type {string}
     * @memberof CouponsV2UserOrderDTO
     * @deprecated
     */
    'giftOccasion'?: CouponsV2UserOrderDTOGiftOccasionEnum;
    /**
     * For compatibility to v1 orders
     * @type {number}
     * @memberof CouponsV2UserOrderDTO
     * @deprecated
     */
    'orderIndex': number;
    /**
     * 
     * @type {string}
     * @memberof CouponsV2UserOrderDTO
     */
    'id': string;
    /**
     * 
     * @type {CouponDefinitionDTO}
     * @memberof CouponsV2UserOrderDTO
     */
    'couponDefinition': CouponDefinitionDTO;
    /**
     * 
     * @type {string}
     * @memberof CouponsV2UserOrderDTO
     */
    'orderDate': string;
    /**
     * 
     * @type {string}
     * @memberof CouponsV2UserOrderDTO
     */
    'issueDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof CouponsV2UserOrderDTO
     */
    'expirationDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof CouponsV2UserOrderDTO
     */
    'redeemDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof CouponsV2UserOrderDTO
     */
    'amount': number;
    /**
     * 
     * @type {number}
     * @memberof CouponsV2UserOrderDTO
     */
    'remainingAmount'?: number;
    /**
     * 
     * @type {VoucherDetailsDTO}
     * @memberof CouponsV2UserOrderDTO
     */
    'voucher'?: VoucherDetailsDTO;
    /**
     * 
     * @type {boolean}
     * @memberof CouponsV2UserOrderDTO
     */
    'hasCodeBeenSeen'?: boolean;
}

export const CouponsV2UserOrderDTOStatusEnum = {
    Pending: 'PENDING',
    Ordered: 'ORDERED',
    Completed: 'COMPLETED',
    Error: 'ERROR'
} as const;

export type CouponsV2UserOrderDTOStatusEnum = typeof CouponsV2UserOrderDTOStatusEnum[keyof typeof CouponsV2UserOrderDTOStatusEnum];
export const CouponsV2UserOrderDTOGiftOccasionEnum = {
    Birthday: 'BIRTHDAY',
    SaintsDay: 'SAINTS_DAY',
    ChildBirth: 'CHILD_BIRTH',
    ChildBaptism: 'CHILD_BAPTISM',
    ChildCommunion: 'CHILD_COMMUNION',
    ChildConfirmationCath: 'CHILD_CONFIRMATION_CATH',
    ChildConfirmationProt: 'CHILD_CONFIRMATION_PROT',
    Wedding: 'WEDDING',
    WeddingSilver: 'WEDDING_SILVER',
    WeddingGold: 'WEDDING_GOLD',
    PassedExam: 'PASSED_EXAM',
    CompanyAnniversary: 'COMPANY_ANNIVERSARY',
    Achievement: 'ACHIEVEMENT',
    WorkPerformance: 'WORK_PERFORMANCE',
    Commitment: 'COMMITMENT',
    Proposal: 'PROPOSAL',
    Recruitment: 'RECRUITMENT',
    Attendance: 'ATTENDANCE',
    SigningBonus: 'SIGNING_BONUS',
    ProfitSharing: 'PROFIT_SHARING',
    Other: 'OTHER'
} as const;

export type CouponsV2UserOrderDTOGiftOccasionEnum = typeof CouponsV2UserOrderDTOGiftOccasionEnum[keyof typeof CouponsV2UserOrderDTOGiftOccasionEnum];


