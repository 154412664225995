import { Button } from '@mui/material';
import { useLoginContext } from '@tiffinger-thiel/appauth-react';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import MessageLayout from '../component/layout/MessageLayout';
import { useBackNavigator } from '../module/navigation/BackNavigator';

interface Props {
  registrationPending?: boolean;
}

const InactivePage: React.FC<Props> = ({ registrationPending }) => {
  const backNavigator = useBackNavigator();
  const navigate = useNavigate();

  const { t } = useTranslation('inactivePage');

  const { isReady, isLoggedIn } = useLoginContext();
  useEffect(() => {
    if (isReady && !isLoggedIn) {
      backNavigator.back();
    }
  }, [isLoggedIn, isReady, backNavigator]);

  const handleLogout = useCallback(() => {
    navigate('/logout', { replace: true });
  }, [navigate]);

  return (
    <MessageLayout
      title={t(registrationPending ? 'registered' : 'accountInactive')}
      text={t(registrationPending ? 'registeredDetails' : 'details')}
      actions={
        <Button variant="contained" color="primary" arial-label={t('logout')} onClick={handleLogout} sx={{ paddingX: 4 }}>
          {t('logout')}
        </Button>
      }
    />
  );
};

export default InactivePage;
