/* tslint:disable */
/* eslint-disable */
/**
 * Probonio full API Docs
 * If you stumbled upon this page and want to integrate Probonio into your business software [click here!](/api/external)    If you\'re looking for more information about Probonio [head over here](https://probonio.de)    **This documentation describes all Endpoints of the Probonio API. It\'s created for experienced Probonio Developers only!**  
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface MobilityRefundMetadataDTO
 */
export interface MobilityRefundMetadataDTO {
    /**
     * The reason why not the full refund is given. It is not set if the full refund was given.
     * @type {string}
     * @memberof MobilityRefundMetadataDTO
     */
    'partialRefundType'?: MobilityRefundMetadataDTOPartialRefundTypeEnum;
    /**
     * Type of taxation for the lunch benefit.
     * @type {string}
     * @memberof MobilityRefundMetadataDTO
     */
    'taxationType': MobilityRefundMetadataDTOTaxationTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof MobilityRefundMetadataDTO
     */
    'monthlyLimit': number;
}

export const MobilityRefundMetadataDTOPartialRefundTypeEnum = {
    ReachedMaxPerMonth: 'REACHED_MAX_PER_MONTH',
    TimeCardSplit: 'TIME_CARD_SPLIT'
} as const;

export type MobilityRefundMetadataDTOPartialRefundTypeEnum = typeof MobilityRefundMetadataDTOPartialRefundTypeEnum[keyof typeof MobilityRefundMetadataDTOPartialRefundTypeEnum];
export const MobilityRefundMetadataDTOTaxationTypeEnum = {
    TaxFree: 'TAX_FREE',
    Taxable: 'TAXABLE'
} as const;

export type MobilityRefundMetadataDTOTaxationTypeEnum = typeof MobilityRefundMetadataDTOTaxationTypeEnum[keyof typeof MobilityRefundMetadataDTOTaxationTypeEnum];


