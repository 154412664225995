import { useCallback, useEffect, useMemo, useState } from 'react';
import { ImageFile } from '../../component/camera/ImageFile';
import { pdfToImageBlob, uriToBlob } from '../../utils/fileConversion';
import { IntentFile, useShareTarget } from './ShareTargetContext';
import { resizeImage } from 'probonio-shared-ui/component/imageResizer/ImageResizer';

export interface ImageSelectionState {
  selectedImage?: ImageFile;
  pdf?: Blob;
  isLoading: boolean;
  handleChangeImage: (file: ImageFile, pdf?: Blob) => void;
}

async function pdfToImage(file: IntentFile): Promise<{ pdfBlob: Blob; imageBlob: Blob } | undefined> {
  const pdfBlob = await uriToBlob(file.uri, 'application/pdf');
  if (!pdfBlob) {
    return;
  }
  const imageBlob = await pdfToImageBlob(new File([pdfBlob], 'mobilityReceipt.pdf', { type: 'application/pdf' }));
  if (!imageBlob) {
    return;
  }
  return { pdfBlob, imageBlob };
}

export function useImageSelection(allowPdf?: boolean, maxSize?: number): ImageSelectionState {
  const [selectedImage, setSelectedImage] = useState<ImageFile>();
  const [pdf, setPdf] = useState<Blob>();
  const [isLoading, setIsLoading] = useState(false);

  const { file: intentFile, clearIntent } = useShareTarget();

  const handleChange = useCallback((image: ImageFile, pdf?: Blob) => {
    setSelectedImage(image);
    setPdf(pdf);
  }, []);

  const handleStartIntent = (): void => {
    setIsLoading(true);
  };

  const handleClearIntent = useCallback(() => {
    setIsLoading(false);
    clearIntent();
  }, [clearIntent]);

  // This sets the pdf and image while using shareTarget
  useEffect(() => {
    if (intentFile) {
      void (async () => {
        handleStartIntent();
        try {
          if (intentFile.type !== 'application/pdf') {
            let imageFile = new ImageFile({ intent: intentFile });
            if (maxSize) {
              const imageUri = await imageFile.getURI();
              const resizedFile = await resizeImage(imageUri, maxSize, 'image/jpeg');
              if (resizedFile.isDownscaled) {
                imageFile = new ImageFile({ file: new File([resizedFile.blob], 'shared.jpg', { type: resizedFile.mimeType }) });
              }
            }
            handleChange(imageFile);
            return;
          }

          if (!allowPdf) {
            return;
          }

          const res = await pdfToImage(intentFile);
          if (!res) {
            return;
          }

          const imageFile = new ImageFile({ file: new File([res.imageBlob], 'mobilityReceipt.png', { type: 'image/png' }) });
          handleChange(imageFile, res.pdfBlob);
        } finally {
          handleClearIntent();
        }
      })();
    }
  }, [intentFile, handleChange, handleClearIntent, allowPdf, maxSize]);

  // dispose image after use
  useEffect(() => {
    const lastImage = selectedImage;
    return () => {
      lastImage?.dispose();
    };
  }, [selectedImage]);

  return useMemo(
    () => ({
      selectedImage,
      pdf,
      isLoading,
      handleChangeImage: handleChange,
    }),
    [handleChange, isLoading, pdf, selectedImage],
  );
}
