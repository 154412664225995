import { IconButton, styled } from '@mui/material';
import { MaterialDesignContent, SnackbarProvider as NSnackbarProvider, SnackbarKey } from 'notistack';
import { MouseEventHandler, useCallback, useRef } from 'react';
import CloseIcon from '@mui/icons-material/Close';

declare module 'notistack' {
  interface VariantOverrides {
    notification: true;
  }
}

interface Props {
  children: React.ReactElement;
}

const NotificationMaterialDesignContent = styled(MaterialDesignContent)(({ theme }) => ({
  flexWrap: 'nowrap',
  alignItems: 'flex-start',
  '&.notistack-MuiContent-notification': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  [theme.breakpoints.down('sm')]: {
    // hide actions on small screens
    '& > #notistack-snackbar + div': {
      display: 'none',
    },
  },
}));

export const SnackbarProvider: React.FC<Props> = ({ children }) => {
  const snackbarRef = useRef<NSnackbarProvider>(null);

  const onClickDismiss = useCallback(
    (key: SnackbarKey): MouseEventHandler<HTMLButtonElement> =>
      ev => {
        ev.stopPropagation();
        snackbarRef.current?.closeSnackbar(key);
      },
    [],
  );

  const action = useCallback(
    (key: SnackbarKey) => (
      <IconButton color="inherit" onClick={onClickDismiss(key)}>
        <CloseIcon />
      </IconButton>
    ),
    [onClickDismiss],
  );

  return (
    <NSnackbarProvider ref={snackbarRef} maxSnack={3} action={action} Components={{ notification: NotificationMaterialDesignContent }}>
      {children}
    </NSnackbarProvider>
  );
};
