import { BaseEmployeeDTORolesEnum, EmployeeDTOStatusEnum, UserDTO, UserDTORolesEnum } from '../../api';
import { NamedTenant } from './hooks';

export function mayReadAllTenants(user: UserDTO | undefined): boolean {
  return (
    !!user &&
    (user.roles.includes(UserDTORolesEnum.Admin) ||
      user.roles.includes(UserDTORolesEnum.SalesManager) ||
      user.roles.includes(UserDTORolesEnum.SupportViewer))
  );
}

export function maySwitchAdminTenant(user: UserDTO | undefined): boolean {
  const readAllTenants = mayReadAllTenants(user);

  const tenantAdminRoles = getUserTenantAdminRoles(user);

  return readAllTenants || tenantAdminRoles.length > 1;
}

export function getUserTenantAdminRoles(user: UserDTO | undefined): NamedTenant[] {
  const validEmployments = (user?.employments || []).filter(
    employee =>
      employee.status === EmployeeDTOStatusEnum.Active &&
      (employee.roles.includes(BaseEmployeeDTORolesEnum.TenantAssistant) ||
        employee.roles.includes(BaseEmployeeDTORolesEnum.TenantAdmin) ||
        mayReadAllTenants(user)),
  );
  return validEmployments
    .map(employment => ({ id: employment.tenantId, name: employment.tenantName! }))
    .concat(
      user?.tenantRoles
        ?.filter(
          tenantRole =>
            (tenantRole.role === BaseEmployeeDTORolesEnum.TenantAdmin ||
              tenantRole.role === BaseEmployeeDTORolesEnum.TenantAssistant ||
              tenantRole.role === BaseEmployeeDTORolesEnum.TenantSales) &&
            !validEmployments.some(employee => employee.tenantId === tenantRole.tenantId),
        )
        .map(tenantRole => ({ id: tenantRole.tenantId, name: tenantRole.tenantName! })) || [],
    );
}
