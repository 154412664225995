import { Box, Button } from '@mui/material';
import { BikeBenefitDetailsDTO, BikeBenefitDetailsDTOEmployerExtrasEnum, BikeContractDTO } from 'probonio-shared-ui/api';
import { useTranslation } from 'react-i18next';

import CheckIcon from '@mui/icons-material/Check';
import { InfoAccordion } from '../../component/infoAccordion/InfoAccordion';
import { InfoRow } from '../../component/infoAccordion/InfoRow';

interface Props {
  contract: BikeContractDTO;
  bikeBenefitDetails: BikeBenefitDetailsDTO;
}

export const BikeContractInfo: React.FC<Props> = ({ contract, bikeBenefitDetails }) => {
  const { t } = useTranslation('benefitModule');
  const showInsurance = bikeBenefitDetails.employerExtras.includes(BikeBenefitDetailsDTOEmployerExtrasEnum.Insurance);
  const showInspection = bikeBenefitDetails.employerExtras.includes(BikeBenefitDetailsDTOEmployerExtrasEnum.Inspection);
  const showGrant = bikeBenefitDetails.employerGrant > 0;
  const platformName = bikeBenefitDetails.platformName || t(`benefit.BIKE.contract.yourPlatform`);

  const bikeWorth = contract.priceNet
    ? t(`benefit.BIKE.contract.fields.worthof`, { worth: t('common:money', { money: contract.priceNet }) })
    : t(`benefit.BIKE.contract.taxPrivileged`);

  return (
    <Box paddingX={1} paddingBottom={2}>
      <Box pt={1}>
        <InfoAccordion defaultExpanded title={t(`benefit.BIKE.contract.yourAdvantage`)}>
          <InfoRow
            label={t(`benefit.BIKE.contract.fields.grant`)}
            display={showGrant}
            info={t('common:money', { money: bikeBenefitDetails.employerGrant })}
            actions={<CheckIcon color="success" />}
          />

          <InfoRow
            label={t(`benefit.BIKE.contract.fields.insurance`)}
            display={showInsurance}
            info={t(`benefit.BIKE.contract.paidByEmployer`)}
            actions={<CheckIcon color="success" />}
          />

          <InfoRow
            label={t(`benefit.BIKE.contract.fields.inspection`)}
            display={showInspection}
            info={t(`benefit.BIKE.contract.paidByEmployer`)}
            actions={<CheckIcon color="success" />}
          />

          <InfoRow label={t(`benefit.BIKE.contract.fields.bike`)} info={bikeWorth} actions={<CheckIcon color="success" />} />
        </InfoAccordion>
      </Box>
      <Box pt={1}>
        <InfoAccordion defaultExpanded title={t(`benefit.BIKE.contract.leasingHeader`)}>
          <InfoRow
            label={t(`benefit.BIKE.contract.fields.platform`)}
            display={!!bikeBenefitDetails.platformName}
            info={`${bikeBenefitDetails.platformName}`}
          />
          <InfoRow label={t(`benefit.BIKE.contract.fields.status`)} display={!!contract.status} info={`${contract.status}`} />
          <InfoRow
            label={t(`benefit.BIKE.contract.fields.leasingStart`)}
            info={t(`common:date`, { date: new Date(contract.leasingStartDate!) })}
          />

          <InfoRow
            label={t(`benefit.BIKE.contract.fields.leasingEnd`)}
            display={!!contract.leasingEndDate}
            info={t(`common:date`, { date: new Date(contract.leasingEndDate!) })}
          />
          <InfoRow label={t(`benefit.BIKE.contract.fields.supplier`)} display={!!contract.bikeSupplier} info={`${contract.bikeSupplier}`} />
        </InfoAccordion>
      </Box>
      <Box pt={1} flex={1}>
        <Button fullWidth variant="contained" href={bikeBenefitDetails.platformUrl} target="_blank">
          {t('benefit.BIKE.contract.platform', { platform: platformName })}
        </Button>
      </Box>
    </Box>
  );
};
