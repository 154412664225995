import { ToggleButtonGroup } from '@mui/material';
import { MobilityReceiptRefundDTOTravelPurposeEnum } from 'probonio-shared-ui/api';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ContainedToggleButton } from '../../../component/button/ContainedToggleButton';
import { FullWidthCenterBox } from '../../../component/layout/BoxLayout';

interface Props {
  setMobilityTravelPurpose: (r: MobilityReceiptRefundDTOTravelPurposeEnum) => void;
  mobilityTravelPurpose: MobilityReceiptRefundDTOTravelPurposeEnum | undefined;
}
export const MobilityTravelPurposes: React.FC<Props> = ({ setMobilityTravelPurpose, mobilityTravelPurpose }) => {
  const { t } = useTranslation('benefitModule');
  const noFocus = useCallback<React.MouseEventHandler<HTMLButtonElement>>(ev => ev.preventDefault(), []);

  const mobilityTravelPurposes = [MobilityReceiptRefundDTOTravelPurposeEnum.Private, MobilityReceiptRefundDTOTravelPurposeEnum.Work];

  const handleChangeType = useCallback(
    (ev: unknown, newValue: MobilityReceiptRefundDTOTravelPurposeEnum) => {
      if (newValue) {
        setMobilityTravelPurpose(newValue);
      }
    },
    [setMobilityTravelPurpose],
  );

  return (
    <FullWidthCenterBox marginBottom={1}>
      <ToggleButtonGroup value={mobilityTravelPurpose} onChange={handleChangeType} exclusive fullWidth size="small">
        {mobilityTravelPurposes.map((value, i) => (
          <ContainedToggleButton value={value} key={value + i} onMouseDown={noFocus}>
            {t(`benefit.MOBILITY.travelPurpose.${value}.title`)}
          </ContainedToggleButton>
        ))}
      </ToggleButtonGroup>
    </FullWidthCenterBox>
  );
};
