import { Box, Divider, Paper, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { BackIconButton } from '../../component/button/BackIconButton';
import { MobileLayout } from '../../component/layout';
import { AppToolbar } from '../../component/layout/AppToolbar';
import { useBackNavigator } from '../../module/navigation/BackNavigator';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import CheckIcon from '@mui/icons-material/Check';
import { TenantLogo } from '../../component/layout/TenantLogo';
import DangerousIcon from '@mui/icons-material/Dangerous';
import { useMobilityPeriods } from '../../module/mobility/MobilityPeriodsContext';
import { MobilityPeriodDTOMobilityTaxationTypeEnum } from 'probonio-shared-ui/api';
import { useTenant } from 'probonio-shared-ui/module/me';

interface TextProps {
  children: React.ReactNode;
  isItalic?: boolean;
}

const MobilityBenefitsText: React.FC<TextProps> = ({ children }) => {
  return (
    <Box marginLeft={0.3} marginRight={0.5} marginBottom={0.3} display="flex" alignItems={'center'} gap={0.5}>
      <CheckIcon color="success" />
      <Typography variant="body2">{children}</Typography>
    </Box>
  );
};
const MobilityNotRefundableText: React.FC<TextProps> = ({ children }) => {
  return (
    <Box marginLeft={0.3} marginRight={0.5} marginBottom={0.3} display="flex" alignItems={'center'} gap={0.5}>
      <DangerousIcon color="error" />
      <Typography variant="body2">{children}</Typography>
    </Box>
  );
};
const MobilityRefundableText: React.FC<TextProps> = ({ children, isItalic }) => {
  return (
    <Box marginLeft={0.3} marginRight={0.5} marginBottom={0.3} display="flex" alignItems={'center'} gap={0.5}>
      <ReceiptLongOutlinedIcon color="success" />
      <Typography variant="body2" fontStyle={isItalic ? 'italic' : 'inherit'}>
        {children}
      </Typography>
    </Box>
  );
};

const MobilityRefundable: React.FC = () => {
  const { t } = useTranslation('benefitModule');
  const { tenant } = useTenant();

  return (
    <Paper elevation={0} sx={{ paddingTop: 0.3, width: '100%' }}>
      <Box sx={{ ml: 0.8 }}>
        <Typography variant="descriptionBold">{t('benefit.MOBILITY.infoPage.refundable.title')}</Typography>
        <Divider variant={'fullWidth'} sx={{ marginRight: 0.3, marginBottom: 0.3, marginTop: 0.1 }} />
        <MobilityRefundableText>{t('benefit.MOBILITY.infoPage.refundable.hint1')}</MobilityRefundableText>
        <MobilityRefundableText>{t('benefit.MOBILITY.infoPage.refundable.hint2')}</MobilityRefundableText>
        <MobilityRefundableText>{t('benefit.MOBILITY.infoPage.refundable.hint3')}</MobilityRefundableText>
        <MobilityRefundableText>{t('benefit.MOBILITY.infoPage.refundable.hint4')}</MobilityRefundableText>
        <MobilityRefundableText>
          {t('benefit.MOBILITY.infoPage.refundable.hint5', { count: tenant?.lunchPeriodCloseDelay, ordinal: true })}
        </MobilityRefundableText>
      </Box>
    </Paper>
  );
};
const MobilityNotRefundable: React.FC = () => {
  const { t } = useTranslation('benefitModule');
  return (
    <Paper elevation={0} sx={{ paddingTop: 0.3, width: '100%' }}>
      <Box sx={{ ml: 0.8 }}>
        <Typography variant="descriptionBold">{t('benefit.MOBILITY.infoPage.refundable.not.title')}</Typography>
        <Divider variant={'fullWidth'} sx={{ marginRight: 0.3, marginBottom: 0.3, marginTop: 0.1 }} />
        <MobilityNotRefundableText>{t('benefit.MOBILITY.infoPage.refundable.not.hint1')}</MobilityNotRefundableText>
        <MobilityNotRefundableText>{t('benefit.MOBILITY.infoPage.refundable.not.hint2')}</MobilityNotRefundableText>
        <MobilityNotRefundableText>{t('benefit.MOBILITY.infoPage.refundable.not.hint3')}</MobilityNotRefundableText>
      </Box>
    </Paper>
  );
};

const MobilityBenefits: React.FC = () => {
  const { t } = useTranslation('benefitModule');
  const { currentPeriod } = useMobilityPeriods();
  return (
    <Paper elevation={0} sx={{ paddingTop: 0.3, width: '100%' }}>
      <Box sx={{ marginLeft: 0.8 }}>
        <Typography variant="descriptionBold">{t('benefit.MOBILITY.infoPage.yourMobilityBenefits.title')}</Typography>
        <Divider variant={'fullWidth'} sx={{ marginRight: 0.3, marginBottom: 0.3, marginTop: 0.1 }} />
        <MobilityBenefitsText>
          {t('benefit.MOBILITY.infoPage.yourMobilityBenefits.advantage1', { maxRefund: currentPeriod?.maxRefund || 100 })}
        </MobilityBenefitsText>
        <MobilityBenefitsText>{t('benefit.MOBILITY.infoPage.yourMobilityBenefits.advantage2')}</MobilityBenefitsText>
        <MobilityBenefitsText>{t('benefit.MOBILITY.infoPage.yourMobilityBenefits.advantage3')}</MobilityBenefitsText>
      </Box>
    </Paper>
  );
};

const FlexMobilityRefundable: React.FC = () => {
  const { t } = useTranslation('benefitModule');
  return (
    <Paper elevation={0} sx={{ paddingTop: 0.3, width: '100%' }}>
      <Box sx={{ ml: 0.8 }}>
        <Typography variant="descriptionBold">{t('benefit.MOBILITY.infoPage.flexRefundable.title')}</Typography>
        <Divider variant={'fullWidth'} sx={{ marginRight: 0.3, marginBottom: 0.3, marginTop: 0.1 }} />
        <MobilityRefundableText>{t('benefit.MOBILITY.infoPage.flexRefundable.hint1')}</MobilityRefundableText>
        <MobilityRefundableText>{t('benefit.MOBILITY.infoPage.flexRefundable.hint2')}</MobilityRefundableText>
        <MobilityRefundableText>{t('benefit.MOBILITY.infoPage.flexRefundable.hint3')}</MobilityRefundableText>
        <MobilityRefundableText>{t('benefit.MOBILITY.infoPage.flexRefundable.hint4')}</MobilityRefundableText>
        <MobilityRefundableText>{t('benefit.MOBILITY.infoPage.flexRefundable.hint5')}</MobilityRefundableText>
        <MobilityRefundableText>{t('benefit.MOBILITY.infoPage.flexRefundable.hint6')}</MobilityRefundableText>
      </Box>
    </Paper>
  );
};

const FlexMobilityOtherRefundables: React.FC = () => {
  const { t } = useTranslation('benefitModule');
  return (
    <Paper elevation={0} sx={{ paddingTop: 0.3, width: '100%' }}>
      <Box sx={{ ml: 0.8 }}>
        <Typography variant="descriptionBold">{t('benefit.MOBILITY.infoPage.flexRefundable.other.title')}</Typography>
        <Divider variant={'fullWidth'} sx={{ marginRight: 0.3, marginBottom: 0.3, marginTop: 0.1 }} />
        <MobilityRefundableText>{t('benefit.MOBILITY.infoPage.flexRefundable.other.hint1')}</MobilityRefundableText>
        <MobilityRefundableText>{t('benefit.MOBILITY.infoPage.flexRefundable.other.hint2')}</MobilityRefundableText>
        <MobilityRefundableText>{t('benefit.MOBILITY.infoPage.flexRefundable.other.hint3')}</MobilityRefundableText>
        <MobilityRefundableText>{t('benefit.MOBILITY.infoPage.flexRefundable.other.hint4')}</MobilityRefundableText>
        <MobilityRefundableText>{t('benefit.MOBILITY.infoPage.flexRefundable.other.hint5')}</MobilityRefundableText>
        <MobilityRefundableText isItalic>{t('benefit.MOBILITY.infoPage.flexRefundable.other.hint6')}</MobilityRefundableText>
      </Box>
    </Paper>
  );
};

const FlexMobilityBenefits: React.FC = () => {
  const { t } = useTranslation('benefitModule');
  const { currentPeriod } = useMobilityPeriods();
  return (
    <Paper elevation={0} sx={{ paddingTop: 0.3, width: '100%' }}>
      <Box sx={{ marginLeft: 0.8 }}>
        <Typography variant="descriptionBold">{t('benefit.MOBILITY.infoPage.yourFlexMobilityBenefits.title')}</Typography>
        <Divider variant={'fullWidth'} sx={{ marginRight: 0.3, marginBottom: 0.3, marginTop: 0.1 }} />
        <MobilityBenefitsText>
          {t('benefit.MOBILITY.infoPage.yourFlexMobilityBenefits.advantage1', { maxRefund: currentPeriod?.maxRefund || 100 })}
        </MobilityBenefitsText>
        <MobilityBenefitsText>{t('benefit.MOBILITY.infoPage.yourFlexMobilityBenefits.advantage2')}</MobilityBenefitsText>
        <MobilityBenefitsText>{t('benefit.MOBILITY.infoPage.yourFlexMobilityBenefits.advantage3')}</MobilityBenefitsText>
      </Box>
    </Paper>
  );
};

export const MobilityInfoPage: React.FC = () => {
  const backNavigator = useBackNavigator();
  const { currentPeriod } = useMobilityPeriods();
  const { t } = useTranslation('benefitModule');

  return (
    <MobileLayout
      header={<AppToolbar title={t('benefit.MOBILITY.infoPage.title')} backButton={<BackIconButton onClick={backNavigator.back} />} />}
    >
      <Box
        display="flex"
        flexDirection="column"
        height={'auto'}
        justifyContent="space-between"
        alignItems="center"
        marginX={1}
        gap={0.8}
        marginBottom={0.7}
      >
        <Box
          borderRadius={'8px'}
          component="img"
          width={'100%'}
          sx={{
            maxWidth: 410,
          }}
          src="/image/mobility-info-picture.png"
        />
        {currentPeriod?.mobilityTaxationType === MobilityPeriodDTOMobilityTaxationTypeEnum.TaxFree && (
          <>
            <MobilityRefundable />
            <MobilityBenefits />
            <MobilityNotRefundable />
          </>
        )}
        {currentPeriod?.mobilityTaxationType === MobilityPeriodDTOMobilityTaxationTypeEnum.Taxable && (
          <>
            <FlexMobilityRefundable />
            <FlexMobilityBenefits />
            <FlexMobilityOtherRefundables />
          </>
        )}
        <TenantLogo sx={{ width: '80%', textAlign: 'center', marginBottom: 1 }} />
      </Box>
    </MobileLayout>
  );
};
