/* tslint:disable */
/* eslint-disable */
/**
 * Probonio full API Docs
 * If you stumbled upon this page and want to integrate Probonio into your business software [click here!](/api/external)    If you\'re looking for more information about Probonio [head over here](https://probonio.de)    **This documentation describes all Endpoints of the Probonio API. It\'s created for experienced Probonio Developers only!**  
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CreateCustomBenefitDTO } from '../dto';
// @ts-ignore
import { CustomBenefitDTO } from '../dto';
// @ts-ignore
import { CustomBenefitListDTO } from '../dto';
// @ts-ignore
import { IdentifierDTO } from '../dto';
// @ts-ignore
import { UpdateCustomBenefitDTO } from '../dto';
// @ts-ignore
import { UploadLinkDTO } from '../dto';
/**
 * CustomBenefitApi - axios parameter creator
 * @export
 */
export const CustomBenefitApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary create new custom benefits for given tenant
         * @param {string} tenantId 
         * @param {CreateCustomBenefitDTO} createCustomBenefitDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCustomBenefit: async (tenantId: string, createCustomBenefitDTO: CreateCustomBenefitDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('createCustomBenefit', 'tenantId', tenantId)
            // verify required parameter 'createCustomBenefitDTO' is not null or undefined
            assertParamExists('createCustomBenefit', 'createCustomBenefitDTO', createCustomBenefitDTO)
            const localVarPath = `/tenants/{tenantId}/benefits/custom`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCustomBenefitDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a form POST upload link for uploading the benefit image. Refer to https://docs.aws.amazon.com/AmazonS3/latest/API/sigv4-post-example.html on how to implement the form POST.
         * @summary create a presigned upload link
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUploadLink: async (tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('createUploadLink', 'tenantId', tenantId)
            const localVarPath = `/tenants/{tenantId}/benefits/custom/createUploadLink`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary delete a custom benefit
         * @param {string} tenantId 
         * @param {string} customBenefitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCustomBenefit: async (tenantId: string, customBenefitId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('deleteCustomBenefit', 'tenantId', tenantId)
            // verify required parameter 'customBenefitId' is not null or undefined
            assertParamExists('deleteCustomBenefit', 'customBenefitId', customBenefitId)
            const localVarPath = `/tenants/{tenantId}/benefits/custom/{customBenefitId}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"customBenefitId"}}`, encodeURIComponent(String(customBenefitId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get single custom benefit
         * @param {string} tenantId 
         * @param {string} customBenefitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomBenefit: async (tenantId: string, customBenefitId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('getCustomBenefit', 'tenantId', tenantId)
            // verify required parameter 'customBenefitId' is not null or undefined
            assertParamExists('getCustomBenefit', 'customBenefitId', customBenefitId)
            const localVarPath = `/tenants/{tenantId}/benefits/custom/{customBenefitId}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"customBenefitId"}}`, encodeURIComponent(String(customBenefitId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary list custom benefits for given tenant
         * @param {string} tenantId 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {boolean} [withImage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCustomBenefits: async (tenantId: string, page?: number, pageSize?: number, withImage?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('listCustomBenefits', 'tenantId', tenantId)
            const localVarPath = `/tenants/{tenantId}/benefits/custom`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (withImage !== undefined) {
                localVarQueryParameter['withImage'] = withImage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update custom benefit
         * @param {string} tenantId 
         * @param {string} customBenefitId 
         * @param {UpdateCustomBenefitDTO} updateCustomBenefitDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCustomBenefit: async (tenantId: string, customBenefitId: string, updateCustomBenefitDTO: UpdateCustomBenefitDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('updateCustomBenefit', 'tenantId', tenantId)
            // verify required parameter 'customBenefitId' is not null or undefined
            assertParamExists('updateCustomBenefit', 'customBenefitId', customBenefitId)
            // verify required parameter 'updateCustomBenefitDTO' is not null or undefined
            assertParamExists('updateCustomBenefit', 'updateCustomBenefitDTO', updateCustomBenefitDTO)
            const localVarPath = `/tenants/{tenantId}/benefits/custom/{customBenefitId}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"customBenefitId"}}`, encodeURIComponent(String(customBenefitId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCustomBenefitDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomBenefitApi - functional programming interface
 * @export
 */
export const CustomBenefitApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CustomBenefitApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary create new custom benefits for given tenant
         * @param {string} tenantId 
         * @param {CreateCustomBenefitDTO} createCustomBenefitDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCustomBenefit(tenantId: string, createCustomBenefitDTO: CreateCustomBenefitDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdentifierDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCustomBenefit(tenantId, createCustomBenefitDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create a form POST upload link for uploading the benefit image. Refer to https://docs.aws.amazon.com/AmazonS3/latest/API/sigv4-post-example.html on how to implement the form POST.
         * @summary create a presigned upload link
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUploadLink(tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadLinkDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUploadLink(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary delete a custom benefit
         * @param {string} tenantId 
         * @param {string} customBenefitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCustomBenefit(tenantId: string, customBenefitId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCustomBenefit(tenantId, customBenefitId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get single custom benefit
         * @param {string} tenantId 
         * @param {string} customBenefitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomBenefit(tenantId: string, customBenefitId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomBenefitDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomBenefit(tenantId, customBenefitId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary list custom benefits for given tenant
         * @param {string} tenantId 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {boolean} [withImage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listCustomBenefits(tenantId: string, page?: number, pageSize?: number, withImage?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomBenefitListDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listCustomBenefits(tenantId, page, pageSize, withImage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary update custom benefit
         * @param {string} tenantId 
         * @param {string} customBenefitId 
         * @param {UpdateCustomBenefitDTO} updateCustomBenefitDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCustomBenefit(tenantId: string, customBenefitId: string, updateCustomBenefitDTO: UpdateCustomBenefitDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCustomBenefit(tenantId, customBenefitId, updateCustomBenefitDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CustomBenefitApi - factory interface
 * @export
 */
export const CustomBenefitApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CustomBenefitApiFp(configuration)
    return {
        /**
         * 
         * @summary create new custom benefits for given tenant
         * @param {string} tenantId 
         * @param {CreateCustomBenefitDTO} createCustomBenefitDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCustomBenefit(tenantId: string, createCustomBenefitDTO: CreateCustomBenefitDTO, options?: any): AxiosPromise<IdentifierDTO> {
            return localVarFp.createCustomBenefit(tenantId, createCustomBenefitDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a form POST upload link for uploading the benefit image. Refer to https://docs.aws.amazon.com/AmazonS3/latest/API/sigv4-post-example.html on how to implement the form POST.
         * @summary create a presigned upload link
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUploadLink(tenantId: string, options?: any): AxiosPromise<UploadLinkDTO> {
            return localVarFp.createUploadLink(tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary delete a custom benefit
         * @param {string} tenantId 
         * @param {string} customBenefitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCustomBenefit(tenantId: string, customBenefitId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteCustomBenefit(tenantId, customBenefitId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get single custom benefit
         * @param {string} tenantId 
         * @param {string} customBenefitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomBenefit(tenantId: string, customBenefitId: string, options?: any): AxiosPromise<CustomBenefitDTO> {
            return localVarFp.getCustomBenefit(tenantId, customBenefitId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary list custom benefits for given tenant
         * @param {string} tenantId 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {boolean} [withImage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCustomBenefits(tenantId: string, page?: number, pageSize?: number, withImage?: boolean, options?: any): AxiosPromise<CustomBenefitListDTO> {
            return localVarFp.listCustomBenefits(tenantId, page, pageSize, withImage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update custom benefit
         * @param {string} tenantId 
         * @param {string} customBenefitId 
         * @param {UpdateCustomBenefitDTO} updateCustomBenefitDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCustomBenefit(tenantId: string, customBenefitId: string, updateCustomBenefitDTO: UpdateCustomBenefitDTO, options?: any): AxiosPromise<void> {
            return localVarFp.updateCustomBenefit(tenantId, customBenefitId, updateCustomBenefitDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createCustomBenefit operation in CustomBenefitApi.
 * @export
 * @interface CustomBenefitApiCreateCustomBenefitRequest
 */
export interface CustomBenefitApiCreateCustomBenefitRequest {
    /**
     * 
     * @type {string}
     * @memberof CustomBenefitApiCreateCustomBenefit
     */
    readonly tenantId: string

    /**
     * 
     * @type {CreateCustomBenefitDTO}
     * @memberof CustomBenefitApiCreateCustomBenefit
     */
    readonly createCustomBenefitDTO: CreateCustomBenefitDTO
}

/**
 * Request parameters for createUploadLink operation in CustomBenefitApi.
 * @export
 * @interface CustomBenefitApiCreateUploadLinkRequest
 */
export interface CustomBenefitApiCreateUploadLinkRequest {
    /**
     * 
     * @type {string}
     * @memberof CustomBenefitApiCreateUploadLink
     */
    readonly tenantId: string
}

/**
 * Request parameters for deleteCustomBenefit operation in CustomBenefitApi.
 * @export
 * @interface CustomBenefitApiDeleteCustomBenefitRequest
 */
export interface CustomBenefitApiDeleteCustomBenefitRequest {
    /**
     * 
     * @type {string}
     * @memberof CustomBenefitApiDeleteCustomBenefit
     */
    readonly tenantId: string

    /**
     * 
     * @type {string}
     * @memberof CustomBenefitApiDeleteCustomBenefit
     */
    readonly customBenefitId: string
}

/**
 * Request parameters for getCustomBenefit operation in CustomBenefitApi.
 * @export
 * @interface CustomBenefitApiGetCustomBenefitRequest
 */
export interface CustomBenefitApiGetCustomBenefitRequest {
    /**
     * 
     * @type {string}
     * @memberof CustomBenefitApiGetCustomBenefit
     */
    readonly tenantId: string

    /**
     * 
     * @type {string}
     * @memberof CustomBenefitApiGetCustomBenefit
     */
    readonly customBenefitId: string
}

/**
 * Request parameters for listCustomBenefits operation in CustomBenefitApi.
 * @export
 * @interface CustomBenefitApiListCustomBenefitsRequest
 */
export interface CustomBenefitApiListCustomBenefitsRequest {
    /**
     * 
     * @type {string}
     * @memberof CustomBenefitApiListCustomBenefits
     */
    readonly tenantId: string

    /**
     * 
     * @type {number}
     * @memberof CustomBenefitApiListCustomBenefits
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof CustomBenefitApiListCustomBenefits
     */
    readonly pageSize?: number

    /**
     * 
     * @type {boolean}
     * @memberof CustomBenefitApiListCustomBenefits
     */
    readonly withImage?: boolean
}

/**
 * Request parameters for updateCustomBenefit operation in CustomBenefitApi.
 * @export
 * @interface CustomBenefitApiUpdateCustomBenefitRequest
 */
export interface CustomBenefitApiUpdateCustomBenefitRequest {
    /**
     * 
     * @type {string}
     * @memberof CustomBenefitApiUpdateCustomBenefit
     */
    readonly tenantId: string

    /**
     * 
     * @type {string}
     * @memberof CustomBenefitApiUpdateCustomBenefit
     */
    readonly customBenefitId: string

    /**
     * 
     * @type {UpdateCustomBenefitDTO}
     * @memberof CustomBenefitApiUpdateCustomBenefit
     */
    readonly updateCustomBenefitDTO: UpdateCustomBenefitDTO
}

/**
 * CustomBenefitApi - object-oriented interface
 * @export
 * @class CustomBenefitApi
 * @extends {BaseAPI}
 */
export class CustomBenefitApi extends BaseAPI {
    /**
     * 
     * @summary create new custom benefits for given tenant
     * @param {CustomBenefitApiCreateCustomBenefitRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomBenefitApi
     */
    public createCustomBenefit(requestParameters: CustomBenefitApiCreateCustomBenefitRequest, options?: AxiosRequestConfig) {
        return CustomBenefitApiFp(this.configuration).createCustomBenefit(requestParameters.tenantId, requestParameters.createCustomBenefitDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a form POST upload link for uploading the benefit image. Refer to https://docs.aws.amazon.com/AmazonS3/latest/API/sigv4-post-example.html on how to implement the form POST.
     * @summary create a presigned upload link
     * @param {CustomBenefitApiCreateUploadLinkRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomBenefitApi
     */
    public createUploadLink(requestParameters: CustomBenefitApiCreateUploadLinkRequest, options?: AxiosRequestConfig) {
        return CustomBenefitApiFp(this.configuration).createUploadLink(requestParameters.tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary delete a custom benefit
     * @param {CustomBenefitApiDeleteCustomBenefitRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomBenefitApi
     */
    public deleteCustomBenefit(requestParameters: CustomBenefitApiDeleteCustomBenefitRequest, options?: AxiosRequestConfig) {
        return CustomBenefitApiFp(this.configuration).deleteCustomBenefit(requestParameters.tenantId, requestParameters.customBenefitId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get single custom benefit
     * @param {CustomBenefitApiGetCustomBenefitRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomBenefitApi
     */
    public getCustomBenefit(requestParameters: CustomBenefitApiGetCustomBenefitRequest, options?: AxiosRequestConfig) {
        return CustomBenefitApiFp(this.configuration).getCustomBenefit(requestParameters.tenantId, requestParameters.customBenefitId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary list custom benefits for given tenant
     * @param {CustomBenefitApiListCustomBenefitsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomBenefitApi
     */
    public listCustomBenefits(requestParameters: CustomBenefitApiListCustomBenefitsRequest, options?: AxiosRequestConfig) {
        return CustomBenefitApiFp(this.configuration).listCustomBenefits(requestParameters.tenantId, requestParameters.page, requestParameters.pageSize, requestParameters.withImage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update custom benefit
     * @param {CustomBenefitApiUpdateCustomBenefitRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomBenefitApi
     */
    public updateCustomBenefit(requestParameters: CustomBenefitApiUpdateCustomBenefitRequest, options?: AxiosRequestConfig) {
        return CustomBenefitApiFp(this.configuration).updateCustomBenefit(requestParameters.tenantId, requestParameters.customBenefitId, requestParameters.updateCustomBenefitDTO, options).then((request) => request(this.axios, this.basePath));
    }
}
