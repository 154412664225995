import { Box, CircularProgress, Typography } from '@mui/material';
import { BENEFIT_PICTURES } from 'probonio-shared-ui/component/button';
import { Trans, useTranslation } from 'react-i18next';
import { BackIconButton } from '../../component/button/BackIconButton';
import { MobileLayout } from '../../component/layout';
import { AppToolbar } from '../../component/layout/AppToolbar';
import { TenantLogo } from '../../component/layout/TenantLogo';
import { useActiveBenefitsQuery } from '../../module/benefit/useActiveBenefitsQuery';
import { useBackNavigator } from '../../module/navigation/BackNavigator';
import { BenefitDTOBenefitEnum } from 'probonio-shared-ui/api';

const RecreationInfoPage: React.FC = () => {
  const backNavigator = useBackNavigator();
  const { t } = useTranslation('benefitModule');

  const { data: activeBenefits, isLoading } = useActiveBenefitsQuery();

  const detailsRecreation = activeBenefits?.benefits.find(benefitDto => benefitDto.benefit === BenefitDTOBenefitEnum.Recreation)?.details;

  return (
    <MobileLayout
      paperBackground
      header={<AppToolbar absolute invert backButton={<BackIconButton onClick={backNavigator.back} color="inherit" />} />}
    >
      <Box flexGrow={1}>
        <img height={250} src={BENEFIT_PICTURES.RECREATION} style={{ width: '100%', objectFit: 'cover' }} />
        <Box p={0.5} px={1}>
          <Typography variant="h2" mb={1}>
            {t('benefit.RECREATION.infoPage.title')}
          </Typography>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <Typography color="text.secondary" variant="body1" component="div" data-test-id="recreation-info-content">
              <Trans i18nKey="benefitModule:benefit.RECREATION.infoPage.content" values={detailsRecreation} />
            </Typography>
          )}
        </Box>
      </Box>
      <Box display="flex" justifyContent="center" mb={0.5}>
        <TenantLogo sx={{ width: '80%', textAlign: 'center' }} />
      </Box>
    </MobileLayout>
  );
};
export default RecreationInfoPage;
