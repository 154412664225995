import { ShareTarget } from '@tiffinger-thiel/share-target';
import { useSnackbar } from 'notistack';
import { BenefitDTOBenefitEnum } from 'probonio-shared-ui/api';
import { apis, useEmployeeQuery } from 'probonio-shared-ui/module/api';
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

export interface IntentFile {
  uri: string;
  type: string;
}

interface ShareTargetState {
  file: IntentFile | undefined;
  clearIntent: () => void;
}
const ShareTargetContext = createContext<ShareTargetState | undefined>(undefined);

export const ShareTargetProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const snackbar = useSnackbar();
  const { t } = useTranslation('errorModule');

  const { data: activeBenefits, isSuccess } = useEmployeeQuery(['benefits', apis.benefits.getBenefits.name], params =>
    apis.benefits.getBenefits({ ...params }).then(res => res.data),
  );

  const [state, setState] = useState<{
    file?: IntentFile;
  }>({ file: undefined });

  const [listener, setListener] = useState<boolean>(false);

  const clearIntent = useCallback(() => {
    setState({
      file: undefined,
    });
  }, []);

  const navigateToAddLunch = useCallback(() => {
    if (!state.file?.type) {
      return;
    }
    const benefit = location.pathname.startsWith('/mobility') ? BenefitDTOBenefitEnum.Mobility : BenefitDTOBenefitEnum.Lunch;
    if (!activeBenefits?.benefits.some(e => e.benefit === benefit)) {
      snackbar.enqueueSnackbar(t('benefitNotActive'), { variant: 'error' });
      clearIntent();
      return;
    }

    switch (benefit) {
      case BenefitDTOBenefitEnum.Lunch:
        // prevent pushing Add Page twice into History
        if (location.pathname !== '/lunch/receipt/add') {
          navigate('/lunch/receipt/add');
        }
        break;
      case BenefitDTOBenefitEnum.Mobility:
        // prevent pushing Add Page twice into History
        if (location.pathname !== '/mobility/receipt/add') {
          navigate('/mobility/receipt/add');
        }
        break;
    }
  }, [activeBenefits?.benefits, clearIntent, location.pathname, navigate, snackbar, state.file?.type, t]);

  useEffect(() => {
    if (listener) {
      return;
    }
    ShareTarget.addListener('intentReceived', data => {
      setState({
        file:
          data.type && data.uri
            ? {
                type: data.type,
                uri: data.uri,
              }
            : undefined,
      });
    });
    setListener(true);
  }, [listener]);

  useEffect(() => {
    if (state.file && isSuccess) {
      navigateToAddLunch();
    }
  }, [isSuccess, state.file, navigateToAddLunch]);

  const context = useMemo(() => ({ file: state.file, clearIntent }), [clearIntent, state.file]);

  return <ShareTargetContext.Provider value={context}>{children}</ShareTargetContext.Provider>;
};

export function useShareTarget(): ShareTargetState {
  const ctx = useContext(ShareTargetContext);

  if (!ctx) {
    throw new Error('useShareTarget must be used within a ShareTargetProvider');
  }

  return ctx;
}
