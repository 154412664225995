import { useMemo } from 'react';
import { GenericScrollPicker } from './GenericScrollingPicker';
import { NumberBox } from './NumberBox';

interface ScrollingNumberRangePickerProps {
  min: number;
  max: number;
  value?: number;
  onChange: (month: number) => void;
}

export const ScrollingNumberRangePicker: React.FC<ScrollingNumberRangePickerProps> = ({ min, max, value, onChange }) => {
  const numbers = useMemo(() => {
    const numbers: number[] = [];
    for (let i = min; i <= max; i++) {
      numbers.push(i);
    }

    return numbers;
  }, [max, min]);

  return (
    <GenericScrollPicker
      options={numbers.map(n => ({
        value: n,
        component: <NumberBox value={n} active={n === value} />,
      }))}
      value={value}
      onChange={onChange}
    />
  );
};
