import * as Sentry from '@sentry/browser';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { BaseEmployeeDTO, EmployeeDTOStatusEnum, TenantRoleDTO, UserDTO } from 'probonio-shared-ui/api';
import { apis } from 'probonio-shared-ui/module/api';
import { getUserTenantAdminRoles as getUserAdminTenants } from './permissionSelectors';

export interface MeState {
  user?: UserDTO;
  employment?: BaseEmployeeDTO;
  currentTenantId?: string;
  tenantAdminRoles: Pick<TenantRoleDTO, 'tenantId' | 'tenantName'>[];
  forbidden?: boolean;
  registrationPending?: boolean;
  initializationError?: boolean;
  showWelcomeMessage?: boolean;
}

const initialState: MeState = {
  user: undefined,
  tenantAdminRoles: [],
};

export const loadMe = createAsyncThunk('me/loadMe', async (isAuthorized: boolean) => {
  if (isAuthorized) {
    try {
      const response = await apis.me.getMe();
      return {
        forbidden: false,
        initializationError: false,
        user: response.data,
      };
    } catch (err) {
      if ((err as AxiosError)?.request?.status === 403) {
        return {
          forbidden: true,
          initializationError: false,
        };
      } else {
        throw err;
      }
    }
  } else {
    return;
  }
});

const LOCAL_STORAGE_KEY = 'selectedTenantId';

const IS_APP = !!import.meta.env.VITE_WEB_MANAGER_URL;

export const meSlice = createSlice({
  name: 'me',
  initialState,
  reducers: {
    switchTenant: (state, action: PayloadAction<{ tenantId: string; isAdmin?: boolean }>) => {
      if (IS_APP) {
        // Normal user: switch to different active employment
        const newEmployment = state.user?.employments?.find(employment => employment.tenantId === action.payload.tenantId);
        if (newEmployment) {
          state.employment = newEmployment;
          state.currentTenantId = newEmployment.tenantId;
          localStorage.setItem(LOCAL_STORAGE_KEY, newEmployment.tenantId);
        }
      } else {
        state.currentTenantId = action.payload.tenantId;
        const adminTenants = getUserAdminTenants(state.user);
        if (adminTenants.some(adminTenant => adminTenant.id === action.payload.tenantId)) {
          // save the tenant if the user actually belongs to it (i.e. not superadmin)
          localStorage.setItem(LOCAL_STORAGE_KEY, action.payload.tenantId);
        }
      }
    },
    setShowWelcomeMessage: (state, action: PayloadAction<boolean>) => {
      state.showWelcomeMessage = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(loadMe.fulfilled, (state, action) => {
      if (!action.payload) {
        return;
      }

      // load last selected tenant from local storage
      const selectedTenantId = localStorage.getItem(LOCAL_STORAGE_KEY);
      const employments = action.payload.user?.employments
        ?.filter(employee => employee.status === EmployeeDTOStatusEnum.Active || employee.status === EmployeeDTOStatusEnum.Inactive)
        .sort(
          (a, b) =>
            // order: last selected, active, with admin role, others
            (b.tenantId === selectedTenantId ? 1 : 0) - (a.tenantId === selectedTenantId ? 1 : 0) ||
            (b.status === EmployeeDTOStatusEnum.Active ? 1 : 0) - (a.status === EmployeeDTOStatusEnum.Active ? 1 : 0),
        );
      const adminTenants = getUserAdminTenants(action.payload.user).sort(
        (a, b) => (b.id === selectedTenantId ? 1 : 0) - (a.id === selectedTenantId ? 1 : 0),
      );

      state.user = action.payload.user;
      state.showWelcomeMessage = action.payload.user?.showWelcomeMessage;
      if (IS_APP) {
        state.employment = employments?.[0];
        state.currentTenantId = state.employment?.tenantId;
      } else {
        state.currentTenantId = adminTenants[0]?.id;
      }
      state.forbidden = action.payload?.forbidden || !state.currentTenantId;
      state.registrationPending =
        IS_APP &&
        !state.employment &&
        action.payload.user?.employments?.some(employee => employee.status === EmployeeDTOStatusEnum.Registered);
      Sentry.setUser({ id: action.payload.user?.id, email: action.payload.user?.mail });
    });
    builder.addCase(loadMe.rejected, state => {
      state.forbidden = false;
      state.registrationPending = false;
      state.initializationError = true;
    });
  },
});

// Action creators are generated for each case reducer function
export const { switchTenant, setShowWelcomeMessage } = meSlice.actions;

export const meReducer = meSlice.reducer;

export function isTenantSelected(): boolean {
  return !!localStorage.getItem(LOCAL_STORAGE_KEY);
}
