import { Route, Routes } from 'react-router-dom';
import { BikeleasingInfoPage } from './BikeleasingInfoPage';
import BikeleasingLearnMorePage from './BikeleasingLearnMorePage';
import { BikeleasingPage } from './BikeleasingPage';
import { BikeleasingContractDetailsPage } from './BikeleasingContractDetailsPage';

export const BikeleasingRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<BikeleasingPage />} />
      <Route path="/info" element={<BikeleasingInfoPage />} />
      <Route path="/learnmore" element={<BikeleasingLearnMorePage />} />
      <Route path="/contracts/:contractId" element={<BikeleasingContractDetailsPage />} />
    </Routes>
  );
};
