import { SvgIcon, SvgIconProps } from '@mui/material';

export const ProbonioIcon: React.FC<SvgIconProps> = props => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m 12.277333,23.076375 c 2.123377,-0.317939 3.544851,-0.919572 4.826838,-2.042903 1.769315,-1.550312 2.772287,-4.015642 3.020148,-7.423805 0.06611,-0.909131 0.02899,-3.120336 -0.06326,-3.7686589 -0.204314,-1.435757 -0.61864,-2.610915 -1.194422,-3.387574 -0.646568,-0.872171 -1.701033,-1.440101 -3.053974,-1.644892 -0.421344,-0.06377 -1.871885,-0.14003 -1.871885,-0.09839 0,0.01153 0.159242,0.118314 0.353814,0.237199 0.194613,0.11894 0.524622,0.378704 0.73331,0.577327 1.0198,0.970479 1.586269,2.38477 1.845114,4.6067499 0.07965,0.683404 0.06536,3.060365 -0.02255,3.758636 -0.150137,1.19274 -0.365214,2.049333 -0.712972,2.83944 -0.597832,1.358496 -1.701827,2.322378 -3.153995,2.753785 -0.832413,0.247277 -1.117735,0.274423 -2.884879,0.274423 -1.4874172,0 -1.6466581,-0.0071 -2.1258423,-0.09417 C 7.3841732,19.556537 6.6124419,19.311266 6.1887601,19.096565 4.4142667,18.197289 3.4986923,16.415321 3.2454856,13.368028 c -0.062352,-0.750057 -0.024139,-2.957294 0.0654,-3.7795169 0.2743386,-2.51958 0.9786359,-4.583321 2.059494,-6.034685 0.3774506,-0.506873 1.150936,-1.296896 1.617842,-1.652463 0.4939682,-0.376198 1.1712743,-0.777787 1.696815,-1.00610503 l 0.417376,-0.181291 -0.3684716,-0.012444 c -0.4386746,-0.01478 -1.2290321,0.07989 -1.8928071,0.226727 C 4.8256284,1.3741101 3.2444416,2.3518521 2.1222384,3.8461601 0.8200382,5.5802191 0.19038032,7.7295321 0.02721376,10.997456 c -0.17085125,3.422615 0.46995765,6.585756 1.71970394,8.488795 1.3612107,2.072762 3.1938085,3.189579 5.9376541,3.618522 0.3282958,0.05133 0.8263569,0.07049 2.1008265,0.08094 1.6690017,0.01361 1.6712567,0.01353 2.4919347,-0.109334 z"
        id="path2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M 11.722682,0.81528007 C 9.5993041,1.1332191 8.1778299,1.7348511 6.895843,2.8581821 5.126528,4.4084941 4.1235613,6.8738161 3.8757001,10.28198 c -0.066111,0.90913 -0.028983,3.120335 0.063271,3.768658 0.2043027,1.435757 0.6186255,2.610915 1.1944066,3.387575 0.6465688,0.872169 1.7010331,1.440099 3.0539747,1.644904 0.4213431,0.06378 1.8718846,0.140029 1.8718846,0.09839 0,-0.01152 -0.159241,-0.118313 -0.3538134,-0.237212 C 9.5108091,18.825358 9.1808009,18.565594 8.9721132,18.366971 7.9523118,17.396492 7.3858438,15.982201 7.1269991,13.760221 7.0473581,13.076818 7.0616411,10.699856 7.1495511,10.001585 7.2996878,8.8088451 7.5147652,7.9522521 7.8625224,7.1621451 8.4603543,5.8036491 9.564349,4.8397761 11.016518,4.4083681 c 0.832413,-0.247276 1.117736,-0.274422 2.884878,-0.274422 1.487418,0 1.646659,0.0071 2.125843,0.09417 0.588602,0.106996 1.360334,0.352268 1.784016,0.566969 1.774494,0.899266 2.690056,2.681236 2.943261,5.7285279 0.06235,0.750057 0.02414,2.957294 -0.0654,3.779517 -0.274338,2.51958 -0.978623,4.583322 -2.059482,6.034697 -0.37745,0.506874 -1.150935,1.296898 -1.617842,1.652464 -0.493968,0.376196 -1.171274,0.777787 -1.696815,1.006103 l -0.417376,0.181291 0.368473,0.01245 c 0.438674,0.01478 1.229032,-0.07989 1.892806,-0.226729 2.015506,-0.445858 3.596681,-1.423605 4.718884,-2.917913 1.3022,-1.734067 1.931855,-3.88338 2.095021,-7.151304 0.170852,-3.4226179 -0.469955,-6.5857599 -1.7197,-8.4887909 -1.361212,-2.072762 -3.193796,-3.189578 -5.937642,-3.61852203 -0.328296,-0.05133 -0.826357,-0.0705 -2.100827,-0.08094 -1.669001,-0.01361 -1.671256,-0.01353 -2.491934,0.109335 z"
        id="path6"
      />
    </SvgIcon>
  );
};
