import { Capacitor } from '@capacitor/core';
import InfoIcon from '@mui/icons-material/Info';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, IconButton, useTheme } from '@mui/material';
import { useState, useCallback } from 'react';

interface Props {
  stateKey?: string;
}

export const FloatingInfoButton: React.FC<React.PropsWithChildren<Props>> = ({ stateKey, children }) => {
  const theme = useTheme();
  const extraPaddingTop = Capacitor.getPlatform() === 'ios' ? 2 : 0;
  const [visible, setVisible] = useState(stateKey ? localStorage.getItem(`${stateKey}-info-button`) !== 'hide' : true);

  const handleToggle = useCallback(() => {
    setVisible(!visible);
    if (stateKey) {
      localStorage.setItem(`${stateKey}-info-button`, visible ? 'hide' : 'show');
    }
  }, [stateKey, visible]);

  return (
    <Box position="absolute" top={theme.spacing(4 + extraPaddingTop)} left={0} right={0} pl={1} sx={{ pointerEvents: 'none' }}>
      <Box sx={{ display: 'inline-block', backgroundColor: theme.palette.alphaBackground, color: 'white', borderRadius: '50%' }}>
        <IconButton color="inherit" onClick={handleToggle} sx={{ pointerEvents: 'auto' }}>
          {visible ? <InfoIcon /> : <InfoOutlinedIcon />}
        </IconButton>
      </Box>
      <Box
        onClick={handleToggle}
        position="absolute"
        left={theme.spacing(1)}
        right={theme.spacing(1)}
        top={`calc(100% + ${theme.spacing(0.4)})`}
        px={1}
        py={0.5}
        sx={{
          pointerEvents: visible ? 'auto' : undefined,
          color: 'white',
          backgroundColor: theme.palette.alphaBackground,
          borderRadius: 1,
          fontSize: theme.typography.body2.fontSize,
          opacity: visible ? 1 : 0,
          transition: 'opacity ease-in-out 0.15s',
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: theme.spacing(-0.5),
            left: theme.spacing(0.5),
            width: 0,
            height: 0,
            borderLeft: `${theme.spacing(0.5)} solid transparent`,
            borderRight: `${theme.spacing(0.5)} solid transparent`,
            borderBottom: `${theme.spacing(0.5)} solid ${theme.palette.alphaBackground}`,
          }}
        />
        {children}
      </Box>
    </Box>
  );
};
