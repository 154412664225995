import { Alert, Box, useTheme } from '@mui/material';
import { BenefitDTOBenefitEnum } from 'probonio-shared-ui/api';
import { useTenant } from 'probonio-shared-ui/module/me';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { BackIconButton } from '../../component/button/BackIconButton';
import { ImageFile } from '../../component/camera/ImageFile';
import { MobileLayout } from '../../component/layout';
import { AppToolbar } from '../../component/layout/AppToolbar';
import { HighlightGuideButton } from '../../module/guiding/HighlightGuideButton';
import { AddReceiptButton } from '../../module/lunch';
import { useBackNavigator } from '../../module/navigation/BackNavigator';
import { RecreationAmount } from '../../module/recreation/RecreationAmount';
import { RecreationPeriods } from '../../module/recreation/RecreationPeriods';

interface Props {
  onSelectImage?: (file: ImageFile, pdf?: Blob) => void;
}

const RecreationPage: React.FC<Props> = ({ onSelectImage }) => {
  const navigate = useNavigate();
  const { t } = useTranslation('benefitModule');
  const theme = useTheme();
  const backNavigator = useBackNavigator();
  const { tenant } = useTenant();

  const handleSelectImage = useCallback(
    (file: ImageFile, pdf?: Blob) => {
      if (onSelectImage) {
        onSelectImage(file, pdf);
      }
      navigate('receipt/add');
    },
    [onSelectImage, navigate],
  );
  const navigateInfo = useCallback(() => {
    navigate('info');
  }, [navigate]);

  return (
    <MobileLayout
      iosOverloadScrollBackground={theme.palette.background.paper}
      header={
        <AppToolbar
          title={t('benefit.RECREATION.name')}
          color={theme.palette.background.paper}
          backButton={<BackIconButton onClick={backNavigator.back} />}
          actionButton={
            <HighlightGuideButton
              name={t('benefit.RECREATION.name')}
              header={t('benefit.RECREATION.firstHelpGuide.header')}
              text={t('benefit.RECREATION.firstHelpGuide.text')}
              onClick={navigateInfo}
            />
          }
        />
      }
    >
      <RecreationAmount />
      <AddReceiptButton benefit={BenefitDTOBenefitEnum.Recreation} borderColor={theme.palette.primary.main} onSelect={handleSelectImage} />

      <Box sx={{ padding: 1, paddingTop: 0 }}>
        {tenant?.lunchPeriodCloseDelay && tenant.lunchPeriodCloseDelay < 0 && tenant.flexBenefits?.includes('RECREATION') && (
          <Alert severity="warning" sx={{ marginBottom: 0.5 }}>
            {t('benefit.LUNCH.periodCloseDelayWarning', { count: -tenant.lunchPeriodCloseDelay })}
          </Alert>
        )}
        <RecreationPeriods />
      </Box>
    </MobileLayout>
  );
};

export default RecreationPage;
