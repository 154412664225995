import CheckIcon from '@mui/icons-material/Check';
import { Box } from '@mui/material';
import {
  BikeleasingConditionDTO,
  BikeleasingContractDTO,
  BikeleasingPaidByDTOConclusionEnum,
  BikeleasingPaidByDTOPaidByEnum,
} from 'probonio-shared-ui/api';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { InfoAccordion } from '../../../component/infoAccordion/InfoAccordion';
import { InfoRow } from '../../../component/infoAccordion/InfoRow';

interface Props {
  contract: BikeleasingContractDTO;
  condition: BikeleasingConditionDTO;
}

export const BikeleasingContractDetails: React.FC<Props> = ({ condition, contract }) => {
  const { t } = useTranslation('benefitModule');

  const {
    accessories,
    bike,
    employerContribution,
    endDate,
    insuranceCoverage,
    leasingDuration,
    startDate,
    leasingRate,
    purchasePrice,
    recommendedRetailPrice,
    trailerRecommendedRetailPrice,

    transferDate,
  } = contract;
  const renderEuro = useCallback(
    (value: number | undefined): string => (value !== undefined ? t('common:money', { money: value }) : ''),
    [t],
  );
  const renderDate = useCallback((value: string | undefined): string => (value ? t('common:date', { date: new Date(value) }) : ''), [t]);

  const wearInsuranceRow = [
    <InfoRow
      key="wearInsurance"
      label={t('benefit.BIKELEASING.contract.insurance.wear')}
      display={condition.wearInsurance && condition.wearInsurance.conclusion !== BikeleasingPaidByDTOConclusionEnum.NotAllowed}
      info={t(`benefit.BIKELEASING.conclusion.${condition.wearInsurance?.conclusion}`)}
    />,
  ];

  const inspectionPackageRow = [
    <InfoRow
      key="inspectionPackage"
      label={t('benefit.BIKELEASING.contract.insurance.inspectionPackage')}
      display={condition.inspectionPackage && condition.inspectionPackage.conclusion !== BikeleasingPaidByDTOConclusionEnum.NotAllowed}
      info={t(`benefit.BIKELEASING.conclusion.${condition.inspectionPackage?.conclusion}`)}
    />,
  ];

  const yourAdvantageRows: Array<React.ReactNode> = [
    <InfoRow
      label={t(`benefit.BIKELEASING.contract.employerContribution`)}
      key={'grant'}
      display={!!(employerContribution ?? condition.employerContribution?.absolute ?? condition.employerContribution?.percentage)}
      info={
        (employerContribution ?? condition.employerContribution?.absolute)
          ? renderEuro(employerContribution ?? condition.employerContribution?.absolute)
          : `${condition.employerContribution?.percentage}%`
      }
      actions={<CheckIcon color="success" />}
    />,
    <InfoRow
      key={'insuranceCoverage'}
      display={!!insuranceCoverage.length}
      label={t('benefit.BIKELEASING.contract.insuranceCoverage')}
      multiLine={true}
      info={contract.insuranceCoverage.join(', ')}
      actions={<CheckIcon color="success" />}
    />,
    ...(condition.wearInsurance?.paidBy === BikeleasingPaidByDTOPaidByEnum.Employer ? wearInsuranceRow : []),
    ...(condition.inspectionPackage?.paidBy === BikeleasingPaidByDTOPaidByEnum.Employer ? inspectionPackageRow : []),
  ];

  const leasingRows: Array<React.ReactNode> = [
    <InfoRow
      key="leasingDuration"
      display={!!leasingDuration}
      label={t('benefit.BIKELEASING.contract.leasingDuration')}
      info={t('benefit.BIKELEASING.contract.leasingDurationMonths', { leasingDuration })}
    />,
    <InfoRow key="startDate" label={t('benefit.BIKELEASING.contract.startDate')} info={renderDate(startDate)} />,
    <InfoRow key="endDate" label={t('benefit.BIKELEASING.contract.endDate')} info={renderDate(endDate)} />,
    <InfoRow key="leasingRate" label={t('benefit.BIKELEASING.contract.leasingRate')} info={renderEuro(leasingRate)} />,
    ...(condition.wearInsurance?.paidBy === BikeleasingPaidByDTOPaidByEnum.Employee ? wearInsuranceRow : []),
    ...(condition.inspectionPackage?.paidBy === BikeleasingPaidByDTOPaidByEnum.Employee ? inspectionPackageRow : []),
  ];

  const bikeRows: Array<React.ReactNode> = [
    <InfoRow key="transferDate" label={t('benefit.BIKELEASING.contract.transferDate')} info={renderDate(transferDate)} />,
    <InfoRow key="brand" label={t('benefit.BIKELEASING.contract.brand')} info={bike.brand} />,
    <InfoRow key="model" label={t('benefit.BIKELEASING.contract.model')} info={bike.model} />,
    <InfoRow key="frameSize" label={t('benefit.BIKELEASING.contract.frameSize')} info={bike.frameSize} />,
    <InfoRow
      key="recommendedRetailPrice"
      label={t('benefit.BIKELEASING.contract.recommendedRetailPrice')}
      info={renderEuro(recommendedRetailPrice)}
    />,
    <InfoRow key="purchasePrice" label={t('benefit.BIKELEASING.contract.purchasePrice')} info={renderEuro(purchasePrice)} />,
    <InfoRow
      display={!!trailerRecommendedRetailPrice}
      key="trailerRecommendedRetailPrice"
      label={t('benefit.BIKELEASING.contract.trailerRecommendedRetailPrice')}
      info={renderEuro(trailerRecommendedRetailPrice)}
    />,
    <InfoRow
      display={!!accessories.description}
      key="items"
      label={t('benefit.BIKELEASING.contract.items')}
      info={accessories.description}
    />,
    <InfoRow
      display={!!accessories.lockBrand}
      key="lockBrand"
      label={t('benefit.BIKELEASING.contract.lockBrand')}
      info={accessories.lockBrand}
    />,
  ];

  return (
    <Box paddingX={1} paddingBottom={2}>
      <Box pt={1}>
        <InfoAccordion defaultExpanded title={t(`benefit.BIKELEASING.section.yourAdvantage`)}>
          {...yourAdvantageRows}
        </InfoAccordion>
      </Box>

      <Box pt={1}>
        <InfoAccordion defaultExpanded title={t('benefit.BIKELEASING.section.leasing')}>
          {...leasingRows}
        </InfoAccordion>
      </Box>
      <Box pt={1}>
        <InfoAccordion defaultExpanded title={t('benefit.BIKELEASING.section.bike')}>
          {...bikeRows}
        </InfoAccordion>
      </Box>
    </Box>
  );
};
