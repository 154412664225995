// DO NOT IMPORT WITH
// import * as icons from '@mui/icons-material';
// As that packages ALL icons which is too much.

import Diversity3 from '@mui/icons-material/Diversity3';
import Forum from '@mui/icons-material/Forum';
import Groups from '@mui/icons-material/Groups';
import Devices from '@mui/icons-material/Devices';
import DevicesOther from '@mui/icons-material/DevicesOther';
import Router from '@mui/icons-material/Router';
import Wifi from '@mui/icons-material/Wifi';
import PhoneIphone from '@mui/icons-material/PhoneIphone';
import ChargingStation from '@mui/icons-material/ChargingStation';
import Mouse from '@mui/icons-material/Mouse';
import Headphones from '@mui/icons-material/Headphones';
import Computer from '@mui/icons-material/Computer';
import CellTower from '@mui/icons-material/CellTower';
import LtePlusMobiledata from '@mui/icons-material/LtePlusMobiledata';
import CellWifi from '@mui/icons-material/CellWifi';
import CastForEducation from '@mui/icons-material/CastForEducation';
import School from '@mui/icons-material/School';
import CheckCircle from '@mui/icons-material/CheckCircle';
import Euro from '@mui/icons-material/Euro';
import Fingerprint from '@mui/icons-material/Fingerprint';
import LocationOn from '@mui/icons-material/LocationOn';
import TurnedIn from '@mui/icons-material/TurnedIn';
import TouchApp from '@mui/icons-material/TouchApp';
import LocalActivity from '@mui/icons-material/LocalActivity';
import Audiotrack from '@mui/icons-material/Audiotrack';
import Restaurant from '@mui/icons-material/Restaurant';
import OutdoorGrill from '@mui/icons-material/OutdoorGrill';
import LocalDining from '@mui/icons-material/LocalDining';
import LocalDrink from '@mui/icons-material/LocalDrink';
import EmojiFoodBeverage from '@mui/icons-material/EmojiFoodBeverage';
import Dining from '@mui/icons-material/Dining';
import Coffee from '@mui/icons-material/Coffee';
import CoffeeMaker from '@mui/icons-material/CoffeeMaker';
import LocalCafe from '@mui/icons-material/LocalCafe';
import WorkspacePremium from '@mui/icons-material/WorkspacePremium';
import VolunteerActivism from '@mui/icons-material/VolunteerActivism';
import Star from '@mui/icons-material/Star';
import AutoAwesome from '@mui/icons-material/AutoAwesome';
import ThumbUp from '@mui/icons-material/ThumbUp';
import RocketLaunch from '@mui/icons-material/RocketLaunch';
import SavedSearch from '@mui/icons-material/SavedSearch';
import Handshake from '@mui/icons-material/Handshake';
import Favorite from '@mui/icons-material/Favorite';
import EmojiEmotions from '@mui/icons-material/EmojiEmotions';
import Help from '@mui/icons-material/Help';
import Celebration from '@mui/icons-material/Celebration';
import Cake from '@mui/icons-material/Cake';
import Event from '@mui/icons-material/Event';
import EventAvailable from '@mui/icons-material/EventAvailable';
import Attractions from '@mui/icons-material/Attractions';
import Spa from '@mui/icons-material/Spa';
import FitnessCenter from '@mui/icons-material/FitnessCenter';
import MonitorHeart from '@mui/icons-material/MonitorHeart';
import MedicalServices from '@mui/icons-material/MedicalServices';
import Shower from '@mui/icons-material/Shower';
import Yard from '@mui/icons-material/Yard';
import Deck from '@mui/icons-material/Deck';
import Chair from '@mui/icons-material/Chair';
import AddShoppingCart from '@mui/icons-material/AddShoppingCart';
import ShoppingBasket from '@mui/icons-material/ShoppingBasket';
import CardGiftcard from '@mui/icons-material/CardGiftcard';
import Discount from '@mui/icons-material/Discount';
import Percent from '@mui/icons-material/Percent';
import LocalGroceryStore from '@mui/icons-material/LocalGroceryStore';
import Bed from '@mui/icons-material/Bed';
import BeachAccess from '@mui/icons-material/BeachAccess';
import CardTravel from '@mui/icons-material/CardTravel';
import Home from '@mui/icons-material/Home';
import Hotel from '@mui/icons-material/Hotel';
import Luggage from '@mui/icons-material/Luggage';
import NightShelter from '@mui/icons-material/NightShelter';
import Work from '@mui/icons-material/Work';
import CalendarMonth from '@mui/icons-material/CalendarMonth';
import BusinessCenter from '@mui/icons-material/BusinessCenter';
import NoCrash from '@mui/icons-material/NoCrash';
import CarRental from '@mui/icons-material/CarRental';
import DirectionsBike from '@mui/icons-material/DirectionsBike';
import BikeScooter from '@mui/icons-material/BikeScooter';
import DirectionsBusFilled from '@mui/icons-material/DirectionsBusFilled';
import DirectionsCarFilled from '@mui/icons-material/DirectionsCarFilled';
import DirectionsRailwayFilled from '@mui/icons-material/DirectionsRailwayFilled';
import ElectricScooter from '@mui/icons-material/ElectricScooter';
import ElectricCar from '@mui/icons-material/ElectricCar';
import ElectricBike from '@mui/icons-material/ElectricBike';
import EvStation from '@mui/icons-material/EvStation';
import Flight from '@mui/icons-material/Flight';
import Garage from '@mui/icons-material/Garage';
import Commute from '@mui/icons-material/Commute';
import LocalGasStation from '@mui/icons-material/LocalGasStation';
import LocalParking from '@mui/icons-material/LocalParking';
import ConnectingAirports from '@mui/icons-material/ConnectingAirports';
import BabyChangingStation from '@mui/icons-material/BabyChangingStation';
import BedroomBaby from '@mui/icons-material/BedroomBaby';
import ChildFriendly from '@mui/icons-material/ChildFriendly';
import ChildCare from '@mui/icons-material/ChildCare';
import FamilyRestroom from '@mui/icons-material/FamilyRestroom';

export const CUSTOM_BENEFIT_ICONS: Record<string, typeof Diversity3> = {
  Diversity3,
  Forum,
  Groups,
  Devices,
  DevicesOther,
  Router,
  Wifi,
  PhoneIphone,
  ChargingStation,
  Mouse,
  Headphones,
  Computer,
  CellTower,
  LtePlusMobiledata,
  CellWifi,
  CastForEducation,
  School,
  CheckCircle,
  Euro,
  Fingerprint,
  LocationOn,
  TurnedIn,
  TouchApp,
  LocalActivity,
  Audiotrack,
  Restaurant,
  OutdoorGrill,
  LocalDining,
  LocalDrink,
  EmojiFoodBeverage,
  Dining,
  Coffee,
  CoffeeMaker,
  LocalCafe,
  WorkspacePremium,
  VolunteerActivism,
  Star,
  AutoAwesome,
  ThumbUp,
  RocketLaunch,
  SavedSearch,
  Handshake,
  Favorite,
  EmojiEmotions,
  Help,
  Celebration,
  Cake,
  Event,
  EventAvailable,
  Attractions,
  Spa,
  FitnessCenter,
  MonitorHeart,
  MedicalServices,
  Shower,
  Yard,
  Deck,
  Chair,
  AddShoppingCart,
  ShoppingBasket,
  CardGiftcard,
  Discount,
  Percent,
  LocalGroceryStore,
  Bed,
  BeachAccess,
  CardTravel,
  Home,
  Hotel,
  Luggage,
  NightShelter,
  Work,
  CalendarMonth,
  BusinessCenter,
  NoCrash,
  CarRental,
  DirectionsBike,
  BikeScooter,
  DirectionsBusFilled,
  DirectionsCarFilled,
  DirectionsRailwayFilled,
  ElectricScooter,
  ElectricCar,
  ElectricBike,
  EvStation,
  Flight,
  Garage,
  Commute,
  LocalGasStation,
  LocalParking,
  ConnectingAirports,
  BabyChangingStation,
  BedroomBaby,
  ChildFriendly,
  ChildCare,
  FamilyRestroom,
};
