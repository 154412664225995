import { useQueryClient } from '@tanstack/react-query';
import { AvailableGiftDTO, BenefitDTO, CouponBenefitDetailsDTO, CouponDefinitionDTO } from 'probonio-shared-ui/api';
import { apis, useEmployeeQuery, useTenantQuery } from 'probonio-shared-ui/module/api';
import { useActiveEmployment } from 'probonio-shared-ui/module/me';
import { createContext, useContext, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

type CouponsBenefitDTO = BenefitDTO & { details: CouponBenefitDetailsDTO };

export interface AvailableCouponState {
  couponsQuery: { data: CouponsBenefitDTO | undefined; isLoading: boolean };
  availableCoupons?: CouponBenefitDetailsDTO;
  defaultCoupon?: CouponDefinitionDTO;
  refetchAvailableCoupons: () => void;
}

const AvailableCouponContext = createContext<AvailableCouponState | undefined>(undefined);

export const AvailableCouponProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const getActiveEmployment = useActiveEmployment();
  const queryClient = useQueryClient();
  const location = useLocation();
  const gift = location.state as AvailableGiftDTO;

  const couponsQuery = useEmployeeQuery(
    ['benefits', 'coupons', apis.benefits.getBenefit.name],
    params =>
      apis.benefits
        .getBenefit({
          ...params,
          benefit: 'COUPONS',
        })
        .then(res => res.data),
    { enabled: !gift },
  );

  const defaultCouponId = (couponsQuery.data as CouponsBenefitDTO | undefined)?.details.defaultCouponId;
  const defaultCouponQuery = useTenantQuery(
    ['benefits', 'coupons', 'definition', defaultCouponId!, apis.coupons.getCouponDefinition.name],
    tenantId =>
      apis.coupons
        .getCouponDefinition({
          tenantId,
          couponId: defaultCouponId!,
        })
        .then(res => res.data),
    { enabled: !!defaultCouponId },
  );

  const context = useMemo<AvailableCouponState>(
    () => ({
      couponsQuery: { data: couponsQuery.data as CouponsBenefitDTO, isLoading: couponsQuery.isLoading },
      availableCoupons: couponsQuery.data?.details as CouponBenefitDetailsDTO | undefined,
      defaultCoupon: defaultCouponQuery.data,
      refetchAvailableCoupons: async () => {
        return queryClient.invalidateQueries({
          queryKey: ['tenants', getActiveEmployment().tenantId, 'employees', getActiveEmployment().id, 'benefits', 'coupons'],
        });
      },
    }),
    [couponsQuery.data, couponsQuery.isLoading, defaultCouponQuery.data, getActiveEmployment, queryClient],
  );

  return <AvailableCouponContext.Provider value={context}>{children}</AvailableCouponContext.Provider>;
};

export function useAvailableCoupons(): AvailableCouponState {
  const ctx = useContext(AvailableCouponContext);
  // Throw error that the Context is not injected, if it is undefined.
  if (ctx === undefined) {
    throw new Error('useAvailableCoupons must be used within a AvailableCouponProvider');
  }
  return ctx;
}
