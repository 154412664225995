import CheckIcon from '@mui/icons-material/Check';
import { Box, CircularProgress, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { BenefitDTOBenefitEnum, RecreationReceiptRefundDTOStatusEnum, UpdateRecreationReceiptDTO } from 'probonio-shared-ui/api';
import { apis, useEmployeeQuery } from 'probonio-shared-ui/module/api';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { CloseIconButton } from '../../component/button/CloseIconButton';
import { LoadingLinkIconButton } from '../../component/button/LoadingLinkIconButton';
import { ImageFile } from '../../component/camera/ImageFile';
import { MobileLayout } from '../../component/layout';
import { AppToolbar } from '../../component/layout/AppToolbar';
import { EditReceiptFormValues, mapFromReceipt, mapToUpdateRequest } from '../../module/benefit/receipt';
import { useBackNavigator } from '../../module/navigation/BackNavigator';
import { useRecreationPeriods } from '../../module/recreation/RecreationPeriodsContext';
import { useUpdateRecreationReceipt } from '../../module/recreation/addReceipt/useUpdateRecreationReceipt';
import { EditRecreationReceiptForm } from './EditRecreationReceiptForm';

const INITIAL_VALUES: EditReceiptFormValues = {
  total: 0,
  date: new Date(),
  comment: '',
};

const EditReceiptPage: React.FC = () => {
  const { recreationPeriod, receiptId } = useParams();
  const { t } = useTranslation('benefitModule');
  const { enqueueSnackbar } = useSnackbar();
  const { refetchRecreation, previousPeriod, selectedPeriod, selectPeriod } = useRecreationPeriods();
  const backNavigator = useBackNavigator();

  const { data: receipt, isLoading } = useEmployeeQuery(
    ['benefits', 'recreation', 'periods', 'receipts', receiptId, { withImage: true }, apis.recreation.getReceipt.name],
    params => apis.recreation.getReceipt({ ...params, receiptId: receiptId!, withImage: true }).then(res => res.data),
  );

  const [values, setValues] = useState<EditReceiptFormValues>(
    receipt ? mapFromReceipt(receipt, BenefitDTOBenefitEnum.Recreation) : INITIAL_VALUES,
  );

  useEffect(() => {
    if (!selectedPeriod) {
      selectPeriod(recreationPeriod);
    }
  }, [recreationPeriod, refetchRecreation, selectPeriod, selectedPeriod]);

  useEffect(() => {
    if (receipt) {
      setValues(mapFromReceipt(receipt, BenefitDTOBenefitEnum.Recreation));
    }
  }, [receipt]);

  const mutation = useUpdateRecreationReceipt(receiptId!, values, () => {
    refetchRecreation();
    enqueueSnackbar(t(`benefit.RECREATION.addReceiptFlow.createReceiptSuccess`), { variant: 'success' });
    backNavigator.back();
  });

  const handleSave = useCallback<React.FormEventHandler<HTMLFormElement>>(
    ev => {
      ev.preventDefault();
      const mappedRequest = mapToUpdateRequest(receipt!, values, BenefitDTOBenefitEnum.Recreation) as UpdateRecreationReceiptDTO;
      if (Object.values(mappedRequest).every(value => value === undefined) && !(values.image instanceof ImageFile)) {
        enqueueSnackbar(t('benefit.LUNCH.noChanges'), { variant: 'warning' });
        return;
      }
      mutation.mutate(mappedRequest);
    },
    [receipt, values, enqueueSnackbar, t, mutation],
  );

  const isLockedForEditing = receipt?.status === RecreationReceiptRefundDTOStatusEnum.Approved;

  return (
    <form onSubmit={handleSave} style={{ height: '100%' }}>
      <MobileLayout
        paperBackground
        header={
          <AppToolbar
            title={t('benefit.RECREATION.editReceipt')}
            backButton={<CloseIconButton onClick={backNavigator.back} />}
            actionButton={
              isLockedForEditing ? undefined : (
                <LoadingLinkIconButton type="submit" loading={mutation.isPending}>
                  <CheckIcon />
                </LoadingLinkIconButton>
              )
            }
          />
        }
      >
        <Box sx={{ padding: 1 }}>
          {isLockedForEditing ? (
            <Typography>{t('benefit.RECREATION.receiptNotEditable')}</Typography>
          ) : (
            <EditRecreationReceiptForm
              benefit={BenefitDTOBenefitEnum.Recreation}
              previousPeriod={previousPeriod}
              benefitPeriod={recreationPeriod!}
              benefitPeriodDTO={selectedPeriod!}
              values={values}
              onChange={setValues}
              disabled={mutation.isPending || isLoading}
            />
          )}
          {isLoading && <CircularProgress />}
        </Box>
      </MobileLayout>
    </form>
  );
};

export default EditReceiptPage;
