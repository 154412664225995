import { Box, Divider, Paper, SxProps, useTheme } from '@mui/material';

const CutoutDivider: React.FC<{ size?: number; spacing: number; sx?: SxProps }> = ({ size = 1, spacing, sx }) => {
  const theme = useTheme();

  return (
    <Box sx={{ ...sx, position: 'relative' }}>
      <Box
        sx={{
          position: 'absolute',
          marginTop: 'auto',
          left: 0,
          top: '50%',
          transform: `translate(-${theme.spacing(size / 2 + spacing)}, -50%)`,
          height: theme.spacing(size),
          width: theme.spacing(size),
          backgroundColor: theme.palette.background.default,
          borderRadius: '50%',
        }}
      />
      <Box
        sx={{
          position: 'absolute',
          marginTop: 'auto',
          right: 0,
          top: '50%',
          transform: `translate(${theme.spacing(size / 2 + spacing)}, -50%)`,
          height: theme.spacing(size),
          width: theme.spacing(size),
          backgroundColor: theme.palette.background.default,
          borderRadius: '50%',
        }}
      />
      <Divider sx={{ borderStyle: 'dashed', borderColor: theme.palette.text.secondary, borderBottom: 'none' }} />
    </Box>
  );
};

interface Props {
  bottomPanel?: React.ReactNode;
  sx?: SxProps;
}

export const CouponCard: React.FC<React.PropsWithChildren<Props>> = ({ bottomPanel, children, sx }) => {
  return (
    <Paper sx={{ padding: 1.25, marginBottom: 1, backgroundImage: 'unset', ...sx }}>
      {children}
      {bottomPanel && <CutoutDivider spacing={1.25} sx={{ marginTop: 1, marginBottom: 1.25 }} />}
      {bottomPanel}
    </Paper>
  );
};
