import { Box, Divider, Fab, SwipeableDrawer, useTheme } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  children: React.ReactNode;
  icon: React.ReactNode;
}

export const Drawable: React.FC<Props> = ({ children, icon }) => {
  const theme = useTheme();
  const [open, setOpen] = useState(true);
  const { t } = useTranslation('benefitModule');
  const toggleDrawer =
    (newOpen: boolean): React.ReactEventHandler<{}> =>
    () => {
      setOpen(newOpen);
    };
  return (
    <>
      {!open && (
        <Fab variant="extended" onClick={toggleDrawer(true)} style={{ position: 'absolute', bottom: 35, right: 20 }} color="primary">
          {icon}
          <Box component="span" ml={0.5}>
            {t('benefit.LUNCH.showDrawable')}
          </Box>
        </Fab>
      )}
      <SwipeableDrawer
        hideBackdrop
        anchor="bottom"
        open={open}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        disableSwipeToOpen={true}
        SlideProps={{ appear: false }}
        PaperProps={{ sx: { textAlign: 'center', maxWidth: 450, margin: 'auto', visibility: 'visible', pb: 1 } }}
        ModalProps={{ keepMounted: true, sx: { visibility: 'hidden' } }}
      >
        <Divider sx={{ borderRadius: 80, borderWidth: 2, width: 56, margin: `${theme.spacing(0.65)} auto 0`, opacity: 1 }} />
        {children}
      </SwipeableDrawer>
    </>
  );
};
