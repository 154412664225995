import * as React from 'react';
import { Box, useTheme } from '@mui/material';
import { TenantLogo } from './TenantLogo';

interface Props {
  onAvatarClick?: React.MouseEventHandler<HTMLDivElement>;
  children?: React.ReactNode;
  right?: React.ReactNode;
}

export const AvatarHeader: React.FC<Props> = ({ children, right }) => {
  const theme = useTheme();
  return (
    <Box sx={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'center', padding: `${theme.spacing(0.5)} 0}` }} gap={1}>
      <Box sx={{ width: '100%' }}>
        <TenantLogo />
      </Box>
      {children && <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'left', paddingLeft: 0.8, minWidth: 0 }}>{children}</Box>}
      {right && <Box sx={{ whiteSpace: 'nowrap' }}>{right}</Box>}
    </Box>
  );
};
