import { Capacitor } from '@capacitor/core';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RegisterDeviceDTOPlatformEnum } from 'probonio-shared-ui/api';
import { apis } from 'probonio-shared-ui/module/api';
import { APP_VERSION } from '../../constants';
import { RootState } from '../../redux/store';

export interface PushNotificationState {
  deviceToken?: string;
}

const initialState: PushNotificationState = {};

function getCurrentPlatform(): RegisterDeviceDTOPlatformEnum {
  if (Capacitor.getPlatform() === 'ios') {
    return RegisterDeviceDTOPlatformEnum.Ios;
  } else if (Capacitor.getPlatform() === 'android') {
    return RegisterDeviceDTOPlatformEnum.Android;
  } else {
    return RegisterDeviceDTOPlatformEnum.Web;
  }
}

export const registerDevice = createAsyncThunk('pushNotification/registerDevice', async (deviceToken: string, { getState }) => {
  const user = (getState() as RootState).me.user;
  if (user) {
    const platform = getCurrentPlatform();
    await apis.user.registerDevice({ userId: user.id, registerDeviceDTO: { deviceToken, platform, appVersion: APP_VERSION } });
    return deviceToken;
  }
});

export const unregisterDevice = createAsyncThunk('pushNotification/unregisterDevice', async (arg: void, { getState }) => {
  const user = (getState() as RootState).me.user;
  const deviceToken = (getState() as RootState).pushNotification.deviceToken;
  if (user && deviceToken) {
    await apis.user.unregisterDevice({ userId: user.id, deviceToken });
  }
});

export const pushNotificationSlice = createSlice({
  name: 'pushNotification',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(registerDevice.fulfilled, (state, action) => {
      if (!action.payload) {
        return;
      }
      state.deviceToken = action.payload;
    });
    builder.addCase(unregisterDevice.fulfilled, (state, action) => {
      state.deviceToken = undefined;
    });
  },
});

export const pushNotificationReducer = pushNotificationSlice.reducer;
