import { Camera } from '@capacitor/camera';
import { Capacitor } from '@capacitor/core';
import { Backdrop, CircularProgress } from '@mui/material';
import { useWithMessage } from 'probonio-shared-ui/module/error';
import { ChangeEventHandler, useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { pdfToImageBlob } from '../../utils/fileConversion';
import { ImageFile } from './ImageFile';
import { NativeSettings, IOSSettings } from 'capacitor-native-settings';
import { ConfirmationModal } from '../confirmationModal';
import { resizeImage } from 'probonio-shared-ui/component/imageResizer/ImageResizer';
import { useNewDialogState } from '../dialog/BasicDialog';
import { CameraDrawer } from './CameraDrawer';
import { LUNCH_MAX_IMAGE_SIZE } from '../../module/lunch/lunchConstants';
import { useSnackbar } from 'notistack';

export interface ButtonRendererProps {
  onClick: () => void;
}

interface Props {
  buttonRenderer: React.ComponentType<ButtonRendererProps>;
  onSelect: (imageFile: ImageFile, pdf?: Blob) => void;
  isPdfAllowed?: boolean;
  uploadOnly?: boolean;
  maxSize?: number;
}

export const CameraButton: React.FC<Props> = ({ buttonRenderer, onSelect, isPdfAllowed = false, uploadOnly, maxSize }) => {
  const CircularButton = buttonRenderer;
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation('cameraButton');

  const [file, setFile] = useState<ImageFile>();
  const [isLoading, setIsLoading] = useState(false);
  const cameraDrawer = useNewDialogState();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);
  const cameraRef = useRef<HTMLInputElement>(null);

  const accept = isPdfAllowed ? 'image/*, application/pdf' : 'image/*';
  const sessionStorageSettingsKey = 'settingsModal';

  const withMessage = useWithMessage(err => {
    if (err.name === 'InvalidPDFException') {
      return t('errorModule:invalidPdfError');
    }
  });

  const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    async e => {
      if (!e.target.files?.length) {
        return;
      }
      setIsLoading(true);
      const targetFile = e.target.files[0];
      let newFile: ImageFile;
      let pdfFile: Blob | undefined;

      // Check file for valid mimetype.
      if (!targetFile.type.startsWith('image/')) {
        pdfFile = targetFile;
        const pdfFileSize = pdfFile?.size / 1024;

        if (pdfFileSize > LUNCH_MAX_IMAGE_SIZE) {
          enqueueSnackbar(t('sizeErrorPdf'), { variant: 'error' });
          setIsLoading(false);
          return;
        }

        const imageData = await pdfToImageBlob(targetFile).catch(err => {
          setIsLoading(false);
          withMessage(err);
        });

        if (!imageData) return;
        newFile = new ImageFile({
          file: new File([imageData], file?.filename.replace('.pdf', '.png') || 'wasPdf.png', {
            type: 'image/png',
          }),
        });
      } else if (maxSize) {
        // resize image if required
        const originalImage = new ImageFile({ file: targetFile });
        const imageUri = await originalImage.getURI();
        const resizedFile = await resizeImage(imageUri, maxSize, targetFile.type);
        if (resizedFile.isDownscaled) {
          newFile = new ImageFile({ file: new File([resizedFile.blob], targetFile.name, { type: resizedFile.mimeType }) });
        } else {
          newFile = new ImageFile({ file: targetFile });
        }
      } else {
        newFile = new ImageFile({ file: targetFile });
      }

      file?.dispose();
      setFile(newFile);
      setIsLoading(false);
      cameraDrawer.dialogState.handleClose();
      onSelect(newFile, pdfFile);
    },
    [cameraDrawer.dialogState, enqueueSnackbar, file, maxSize, onSelect, t, withMessage],
  );

  const handleOpenSettingsModal = (): void => {
    // only show once when app got started new
    sessionStorage.setItem(sessionStorageSettingsKey, 'true');
    setIsModalOpen(true);
  };

  const handleTakePhoto = useCallback(async () => {
    if (!cameraRef.current) {
      return;
    }
    cameraRef.current.click();
  }, []);

  const handleSelectFile = useCallback(() => {
    if (!inputRef.current) {
      return;
    }
    inputRef.current.click();
  }, []);

  const handleCloseSettingsModal = useCallback((): void => {
    if (inputRef.current) {
      inputRef.current.click();
    }
    setIsModalOpen(false);
  }, []);

  const redirectToPermissionSettings = useCallback(async (): Promise<void> => {
    await NativeSettings.openIOS({ option: IOSSettings.App });
    setIsModalOpen(false);
  }, []);

  const handleOpenAndroid = useCallback(() => {
    if (uploadOnly) {
      handleSelectFile();
    } else {
      cameraDrawer.handleOpen();
    }
  }, [cameraDrawer, handleSelectFile, uploadOnly]);

  const handleOpenIOS = useCallback(async () => {
    const { camera: cameraPermission } = await Camera.checkPermissions();
    const hasCameraPermissions = cameraPermission === 'granted' || cameraPermission === 'prompt';
    const hasAskedAlready = sessionStorage.getItem(sessionStorageSettingsKey);

    if (!hasCameraPermissions && !hasAskedAlready) {
      handleOpenSettingsModal();
      return;
    }

    handleSelectFile();
  }, [handleSelectFile]);

  const handleUploadClick = useCallback(() => {
    switch (Capacitor.getPlatform()) {
      case 'android':
        return handleOpenAndroid();
      case 'ios':
        return handleOpenIOS();
      default:
        return handleSelectFile();
    }
  }, [handleOpenAndroid, handleOpenIOS, handleSelectFile]);

  return (
    <>
      <CircularButton onClick={handleUploadClick} />
      <input style={{ display: 'none' }} ref={inputRef} type={'file'} accept={accept} onChange={handleChange} />
      <input style={{ display: 'none' }} ref={cameraRef} type={'file'} accept="image/*" capture="environment" onChange={handleChange} />
      <Backdrop sx={{ color: 'white', zIndex: theme => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <CameraDrawer dialogState={cameraDrawer.dialogState} onTakePhoto={handleTakePhoto} onSelectImage={handleSelectFile} />
      <ConfirmationModal
        isOpen={isModalOpen}
        message={t('permissionSettings.message')}
        onSubmit={redirectToPermissionSettings}
        cancelButtonTitle={t('permissionSettings.cancelButton')}
        okButtonTitle={t('permissionSettings.okButton')}
        onCancel={handleCloseSettingsModal}
      />
    </>
  );
};
