import { useLoginContext } from '@tiffinger-thiel/appauth-react';
import React from 'react';

// HOC to inject the logout function into the GlobalErrorCatcher
// eslint-disable-next-line @typescript-eslint/naming-convention
export const withLogoutHOC = <P extends object>(Component: React.ComponentType<P>): React.ComponentType<P> => {
  return (props: P) => {
    const { logout } = useLoginContext();

    return <Component logout={logout} {...props} />;
  };
};
