import { useLoginContext } from '@tiffinger-thiel/appauth-react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import MessageLayout from '../component/layout/MessageLayout';
import { OAUTH_RETURN_PATH } from '../module/auth/PrivatePage';

const OAuthPage: React.FC = () => {
  const { t } = useTranslation('authModule');
  const { isReady, isLoggedIn } = useLoginContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (isReady) {
      const returnPath = (isLoggedIn && localStorage.getItem(OAUTH_RETURN_PATH)) || '/';
      localStorage.removeItem(OAUTH_RETURN_PATH);
      navigate(returnPath);
    }
  }, [isReady, isLoggedIn, navigate]);

  return <MessageLayout title={t('processingLogin.title')} text={t('processingLogin.text')} />;
};

export default OAuthPage;
