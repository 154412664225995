import { Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { BasicAlert } from '../../../component/alert';
import { FullWidthCenterBox } from '../../../component/layout/BoxLayout';
import { apis } from 'probonio-shared-ui/module/api';
import { useEmployeeQuery } from 'probonio-shared-ui/module/api';

export function useLunchReceiptIsUsed(monthToLoad?: string, previousMonth?: string, options?: { exclude?: Date }): (date: Date) => boolean {
  const { data } = useEmployeeQuery(
    ['benefits', 'lunch', 'periods', monthToLoad, 'receipts', apis.lunch.getPeriodReceipts.name],
    params => apis.lunch.getPeriodReceipts({ ...params, month: monthToLoad! }).then(res => res.data),
    {
      enabled: !!monthToLoad,
    },
  );
  const { data: previousData } = useEmployeeQuery(
    ['benefits', 'lunch', 'periods', previousMonth, 'receipts', apis.lunch.getPeriodReceipts.name],
    params => apis.lunch.getPeriodReceipts({ ...params, month: previousMonth! }).then(res => res.data),
    {
      enabled: !!previousMonth,
    },
  );

  const cache = useMemo(() => {
    const receiptCache = new Set<string>();

    if (!data?.receipts) {
      return receiptCache;
    }

    data.receipts.forEach(receipt => {
      receiptCache.add(receipt.date);
    });
    previousData?.receipts.forEach(receipt => {
      receiptCache.add(receipt.date);
    });

    return receiptCache;
  }, [data?.receipts, previousData?.receipts]);

  return useCallback(
    (date: Date) => {
      if (!cache) {
        return false;
      }

      const day = DateTime.fromJSDate(date).toFormat('yyyy-MM-dd');

      if (options?.exclude && day === DateTime.fromJSDate(options?.exclude).toFormat('yyyy-MM-dd')) {
        return false;
      }

      return cache.has(DateTime.fromJSDate(date).toFormat('yyyy-MM-dd'));
    },
    [cache, options?.exclude],
  );
}

interface Props {
  show: boolean;
}

export const ReceiptExistsAlert: React.FC<Props> = ({ show }) => {
  const { t } = useTranslation('benefitModule');

  // The box is there to reserve the space for the alert.
  // Not sure if "theme.spacing" is the correct way for this.
  return (
    <FullWidthCenterBox marginTop={1.2} marginBottom={1.2}>
      <BasicAlert icon={false} severity="warning" sx={{ visibility: show ? undefined : 'hidden' }}>
        <Typography variant="body1" paddingX={1}>
          {t('benefit.LUNCH.receiptAlreadyExists')}
        </Typography>
      </BasicAlert>
    </FullWidthCenterBox>
  );
};
