import { SentryRoutes } from 'probonio-shared-ui/module/error/sentry';
import { Route } from 'react-router-dom';
import { MobilityPeriodsProvider } from '../../module/mobility/MobilityPeriodsContext';
import { useImageSelection } from '../../module/shareTarget/useImageSelection';
import { AddMobilityReceiptPage } from './AddMobilityReceiptPage';
import EditReceiptPage from './EditReceiptPage';
import { MobilityInfoPage } from './MobilityInfoPage';
import { MobilityPage } from './MobilityPage';
import { MobilityReceiptDetailPage } from './MobilityReceiptDetailPage';

export const MobilityRoutes: React.FC = () => {
  const imageSelection = useImageSelection(true);

  return (
    <MobilityPeriodsProvider>
      <SentryRoutes>
        <Route path="/info" element={<MobilityInfoPage />} />
        <Route path="receipt/view/:mobilityPeriod/:receiptId" element={<MobilityReceiptDetailPage />} />
        {imageSelection.selectedImage && <Route path="/receipt/add" element={<AddMobilityReceiptPage imageSelection={imageSelection} />} />}
        <Route path="/*" element={<MobilityPage onSelectImage={imageSelection.handleChangeImage} isLoading={imageSelection.isLoading} />} />
        <Route path="receipt/edit/:mobilityPeriod/:receiptId" element={<EditReceiptPage />} />
      </SentryRoutes>
    </MobilityPeriodsProvider>
  );
};
