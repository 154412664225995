import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { IconButton, IconButtonProps } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

interface Props extends IconButtonProps {
  value?: string;
}

export const CopyToClipboardButton: React.FC<Props> = ({ value, ...props }: Props) => {
  const { t } = useTranslation('common');
  const { enqueueSnackbar } = useSnackbar();

  const handleCopy = useCallback(() => {
    if (value) {
      void navigator.clipboard.writeText(value);
      enqueueSnackbar(t('copyToClipboardSuccess'), { variant: 'success' });
    }
  }, [enqueueSnackbar, t, value]);

  return (
    <IconButton title={t('copyToClipboard')} {...props} onClick={handleCopy}>
      <ContentCopyIcon />
    </IconButton>
  );
};
