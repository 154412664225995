import { IconButton, IconButtonProps } from '@mui/material';
import { Link, To } from 'react-router-dom';

interface Props extends IconButtonProps {
  to?: To;
}

export const LinkIconButton: React.FC<Props> = ({ to, color, size, children, ...props }) => {
  return to ? (
    <IconButton component={Link} to={to} color={color} size={size}>
      {children}
    </IconButton>
  ) : (
    <IconButton color={color} size={size} {...props}>
      {children}
    </IconButton>
  );
};
