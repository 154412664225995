import DeleteIcon from '@mui/icons-material/Delete';
import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import { LunchReceiptDTO } from 'probonio-shared-ui/api';
import { LinkIconButton } from '../../component/button/LinkIconButton';
import { apis } from 'probonio-shared-ui/module/api';
import { useWithMessage } from 'probonio-shared-ui/module/error';
import { useActiveEmployment } from 'probonio-shared-ui/module/me';
import { useLunchPeriods } from './LunchPeriodsContext';
import { ConfirmationModal } from '../../component/confirmationModal';
import { useBackNavigator } from '../navigation/BackNavigator';

interface Props {
  receipt: LunchReceiptDTO;
  setIsQueryDisabled: (bool: boolean) => void;
  disabled?: boolean;
}

export const DeleteLunchReceiptButton: React.FC<Props> = ({ receipt, disabled, setIsQueryDisabled }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation('benefitModule');
  const { enqueueSnackbar } = useSnackbar();
  const backNavigator = useBackNavigator();
  const withMessage = useWithMessage();
  const getActiveEmployment = useActiveEmployment();
  const { refetchLunch } = useLunchPeriods();

  const navigateToLunchAndReload = useCallback(() => {
    refetchLunch();
    backNavigator.back();
  }, [backNavigator, refetchLunch]);

  const deleteMutation = useMutation({
    mutationFn: () =>
      apis.lunch.deleteReceipt({
        tenantId: getActiveEmployment().tenantId,
        employeeId: getActiveEmployment().id,
        receiptId: receipt.id!,
      }),

    onSuccess: () => {
      enqueueSnackbar(t('benefit.LUNCH.deleteReceipt.successToast'), { variant: 'success' });
      navigateToLunchAndReload();
    },
    onError: (err: Error) => {
      setIsQueryDisabled(false);
      withMessage(err);
    },
  });

  const handleDelete = useCallback(() => {
    setIsQueryDisabled(true);
    deleteMutation.mutate();
  }, [setIsQueryDisabled, deleteMutation]);

  const handleCancel = useCallback(() => {
    setIsOpen(false);
  }, []);

  const handleOpenDialog = useCallback(() => {
    setIsOpen(true);
  }, []);

  return (
    <>
      <ConfirmationModal
        onCancel={handleCancel}
        onSubmit={handleDelete}
        isOpen={isOpen}
        message={t(
          'benefit.LUNCH.deleteReceipt.confirmMessage',
          receipt && { receiptType: receipt.receiptType, date: new Date(receipt.date) },
        )}
        title={t('benefit.LUNCH.deleteReceipt.title')}
      />
      <LinkIconButton
        onClick={handleOpenDialog}
        title={t('benefit.LUNCH.deleteReceipt.title')}
        disabled={deleteMutation.isPending || disabled}
      >
        <DeleteIcon />
      </LinkIconButton>
    </>
  );
};
