import React from 'react';

interface Props {
  activeStep: number;
}

export const SimpleStepper: React.FC<React.PropsWithChildren<Props>> = ({ activeStep, children }) => {
  const childSteps = React.Children.toArray(children);

  return <>{childSteps[activeStep]}</>;
};
