import EuroSymbolIcon from '@mui/icons-material/EuroSymbol';
import SellIcon from '@mui/icons-material/Sell';
import { Box, useTheme } from '@mui/material';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { ButtonTab, CircularFloatingButton, CircularFloatingButtonTabs } from '../../component/button';
import { BackIconButton } from '../../component/button/BackIconButton';
import { MobileLayout } from '../../component/layout';
import { AppToolbar } from '../../component/layout/AppToolbar';
import { CouponOrderList } from '../../module/couponsShared/orderList/CouponOrderList';

import { CouponsV2TokenStatus } from '../../module/couponsV2/userTokens/CouponsV2TokenStatus';
import { resetCouponsState } from '../../module/couponsV2/redux';
import { HighlightGuideButton } from '../../module/guiding';
import { useBackNavigator } from '../../module/navigation/BackNavigator';
import { useAppDispatch } from '../../redux/hooks';
import { ProbonioIcon } from './ProbonioIcon';
import { CouponsV2TokenList } from '../../module/couponsV2/userTokens/CouponsV2TokenList';
import { CouponsV2Status } from '../../module/couponsV2/CouponsV2Status';

enum CouponTabs {
  orders = 'orders',
  tokens = 'tokens',
}

const CouponsV2Page: React.FC = () => {
  const { t } = useTranslation('couponsV2Module');
  const backNavigator = useBackNavigator();
  const theme = useTheme();
  const { tabName } = useParams();
  const selectedTab = tabName || CouponTabs.orders;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(resetCouponsState());
  }, [dispatch]);

  const handleTabChange = useCallback(
    (newSelectedTab: ButtonTab) => {
      navigate(`/couponsV2/${newSelectedTab === ButtonTab.left ? CouponTabs.orders : CouponTabs.tokens}`, { replace: true });
    },
    [navigate],
  );

  const handleGoToExploreCoupons = useCallback(() => {
    navigate('/couponsV2/explore-coupons');
  }, [navigate]);

  const navigateInfo = useCallback(() => {
    navigate('/couponsV2/info');
  }, [navigate]);

  return (
    <MobileLayout
      iosOverloadScrollBackground={theme.palette.background.paper}
      header={
        <>
          <AppToolbar
            title={t(selectedTab === CouponTabs.orders ? 'coupons.title' : 'userTokens.title')}
            color={theme.palette.primary.contrastText}
            backButton={<BackIconButton onClick={backNavigator.back} />}
            actionButton={
              <HighlightGuideButton
                name={t('benefitModule:benefit.COUPONS_V2.firstHelpGuide.name')}
                header={t('benefitModule:benefit.COUPONS_V2.firstHelpGuide.header')}
                text={t('benefitModule:benefit.COUPONS_V2.firstHelpGuide.text')}
                onClick={navigateInfo}
              />
            }
          />
        </>
      }
    >
      <Box
        sx={{
          display: 'flex',
          textAlign: 'center',
          paddingBottom: 0.5,
          paddingTop: 0.2,
          backgroundColor: theme.palette.primary.contrastText,
        }}
      >
        <Box width="100%" marginRight="-100%" visibility={selectedTab === CouponTabs.orders ? 'visible' : 'hidden'}>
          <CouponsV2Status onClick={handleGoToExploreCoupons} />
        </Box>
        <Box width="100%" marginRight="-100%" visibility={selectedTab === CouponTabs.tokens ? 'visible' : 'hidden'}>
          <CouponsV2TokenStatus />
        </Box>
      </Box>
      <CircularFloatingButton
        buttonProps={{ onClick: handleGoToExploreCoupons }}
        borderColor={theme.palette.primary.main}
        tabs={
          <CircularFloatingButtonTabs
            defaultValue={selectedTab === CouponTabs.orders ? ButtonTab.left : ButtonTab.right}
            handleTabChange={handleTabChange}
            leftIcon={<EuroSymbolIcon />}
            rightIcon={<ProbonioIcon />}
          />
        }
      >
        <SellIcon />
      </CircularFloatingButton>
      {selectedTab === CouponTabs.orders && <CouponOrderList activeVersion="V2" />}
      {selectedTab === CouponTabs.tokens && <CouponsV2TokenList />}
    </MobileLayout>
  );
};

export default CouponsV2Page;
