import { useCallback, useRef } from 'react';
import { ReactZoomPanPinchRef, TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';

interface Props {
  imageUrl?: string;
  imageName?: string;
  wrapperStyle?: React.CSSProperties;
  centered?: boolean;
}

export const ImageZoomer: React.FC<Props> = ({ imageUrl, imageName = 'image', wrapperStyle, centered }) => {
  const transformComponentRef = useRef<ReactZoomPanPinchRef | null>(null);

  const handleLoad = useCallback(() => {
    if (transformComponentRef.current) {
      const { centerView } = transformComponentRef.current;
      centerView(undefined, 0);
    }
  }, []);

  return imageUrl ? (
    <TransformWrapper
      ref={transformComponentRef}
      centerOnInit
      alignmentAnimation={{ sizeX: 300, sizeY: 450, disabled: true }}
      doubleClick={{ mode: 'reset' }}
    >
      <TransformComponent
        contentStyle={{ display: 'flex', flexDirection: 'column', flexGrow: 1, justifyContent: centered ? 'center' : 'start' }}
        wrapperStyle={{
          ...wrapperStyle,
          width: '100%',
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
        }}
      >
        <img src={imageUrl} alt={imageName} width="100%" onLoad={handleLoad} />
      </TransformComponent>
    </TransformWrapper>
  ) : null;
};
