import { Box, Divider, Stack, Typography } from '@mui/material';

const SPACING_NORMAL = 0.5;
const SPACING_MULTILINE = 0.1;

interface Props {
  label: string;
  display?: boolean;
  info?: string;
  multiLine?: boolean;
  color?: string;
  actions?: React.ReactNode;
}

export const InfoRow: React.FC<Props> = ({ label, info, multiLine, display = true, color, actions }) => {
  if (!display || info === undefined) {
    return null;
  }

  return (
    <Box mx={1}>
      {!multiLine && <Divider sx={{ margin: 0 }} />}

      <Stack
        paddingTop={multiLine ? SPACING_MULTILINE : SPACING_NORMAL}
        paddingBottom={SPACING_NORMAL}
        display="flex"
        justifyContent="space-between"
        direction={'row'}
        gap={2.5}
      >
        <Typography variant="caption" color="text.secondary">
          {label}
        </Typography>
        <Stack direction={'row'} spacing={SPACING_NORMAL}>
          <Typography align="right" color={color}>
            {info}
          </Typography>
          {actions}
        </Stack>
      </Stack>
    </Box>
  );
};
