/* tslint:disable */
/* eslint-disable */
/**
 * Probonio full API Docs
 * If you stumbled upon this page and want to integrate Probonio into your business software [click here!](/api/external)    If you\'re looking for more information about Probonio [head over here](https://probonio.de)    **This documentation describes all Endpoints of the Probonio API. It\'s created for experienced Probonio Developers only!**  
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface NotificationSettingsDTO
 */
export interface NotificationSettingsDTO {
    /**
     * 
     * @type {Array<string>}
     * @memberof NotificationSettingsDTO
     */
    'notificationChannels': Array<NotificationSettingsDTONotificationChannelsEnum>;
}

export const NotificationSettingsDTONotificationChannelsEnum = {
    Push: 'PUSH',
    Mail: 'MAIL'
} as const;

export type NotificationSettingsDTONotificationChannelsEnum = typeof NotificationSettingsDTONotificationChannelsEnum[keyof typeof NotificationSettingsDTONotificationChannelsEnum];


