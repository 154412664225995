/* tslint:disable */
/* eslint-disable */
/**
 * Probonio full API Docs
 * If you stumbled upon this page and want to integrate Probonio into your business software [click here!](/api/external)    If you\'re looking for more information about Probonio [head over here](https://probonio.de)    **This documentation describes all Endpoints of the Probonio API. It\'s created for experienced Probonio Developers only!**  
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { BikeBenefitContractDetailsDTO } from './bike-benefit-contract-details-dto';

/**
 * 
 * @export
 * @interface BikeBenefitDetailsDTO
 */
export interface BikeBenefitDetailsDTO {
    /**
     * 
     * @type {Array<string>}
     * @memberof BikeBenefitDetailsDTO
     */
    'employerExtras': Array<BikeBenefitDetailsDTOEmployerExtrasEnum>;
    /**
     * 
     * @type {string}
     * @memberof BikeBenefitDetailsDTO
     */
    'platformName'?: string;
    /**
     * 
     * @type {string}
     * @memberof BikeBenefitDetailsDTO
     */
    'platformUrl': string;
    /**
     * 
     * @type {number}
     * @memberof BikeBenefitDetailsDTO
     */
    'employerGrant': number;
    /**
     * 
     * @type {BikeBenefitContractDetailsDTO}
     * @memberof BikeBenefitDetailsDTO
     */
    'activeContract'?: BikeBenefitContractDetailsDTO;
}

export const BikeBenefitDetailsDTOEmployerExtrasEnum = {
    Inspection: 'INSPECTION',
    Insurance: 'INSURANCE'
} as const;

export type BikeBenefitDetailsDTOEmployerExtrasEnum = typeof BikeBenefitDetailsDTOEmployerExtrasEnum[keyof typeof BikeBenefitDetailsDTOEmployerExtrasEnum];


