import { CapacitorJailbreakRootDetection } from '@aalzehla/capacitor-jailbreak-root-detection';
import GppMaybeIcon from '@mui/icons-material/GppMaybe';
import { Box, Button, Checkbox, Dialog, FormControlLabel, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { MobileLayout } from '../layout';
import { useTranslation } from 'react-i18next';

export const JailbreakRootWarningDialog = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const LOCALSTORAGE_KEY = 'jailbreakRootWarningModalOpened';
  const { t } = useTranslation('authModule');

  useEffect(() => {
    void (async () => {
      const isJailbrokenOrRooted = (await CapacitorJailbreakRootDetection.isJailbrokenOrRooted()).result;
      if (!isJailbrokenOrRooted) {
        return;
      }
      const modalState = localStorage.getItem(LOCALSTORAGE_KEY);
      if (modalState !== 'closed' && isJailbrokenOrRooted) {
        setIsOpen(true);
      }
    })();
  }, []);

  const handleClose = useCallback(() => {
    localStorage.setItem(LOCALSTORAGE_KEY, 'closed');
    setIsOpen(false);
  }, []);

  const handleChange = useCallback((event: unknown, checked: boolean) => {
    setIsChecked(checked);
  }, []);

  return (
    <>
      <Dialog fullScreen open={isOpen} onClose={handleClose}>
        <MobileLayout padded>
          <Box display="flex" flexGrow={1} flexDirection="column" justifyContent="center">
            <Box my={0.5} display="flex" justifyContent="center" width="100%">
              <Typography textAlign="center" variant="h2">
                {t('jailbreakRootWarning.title')}
              </Typography>
            </Box>
            <Box textAlign={'center'}>
              <GppMaybeIcon color="warning" sx={{ fontSize: 80 }} />
            </Box>
            <Box data-test-id="allow-notification-modal" textAlign={'center'}>
              <Typography variant="description">{t('jailbreakRootWarning.description')} </Typography>
              <Box mt={1}>
                <FormControlLabel
                  control={<Checkbox required checked={isChecked} onChange={handleChange} />}
                  label={
                    <Typography component="span" variant="body2" color="text.secondary">
                      {t('jailbreakRootWarning.checkbox')}
                    </Typography>
                  }
                />
              </Box>
              <Button
                data-test-id="confirm-allow-notifications"
                onClick={handleClose}
                variant="contained"
                sx={{
                  width: '100%',
                  marginTop: 1,
                }}
                disabled={!isChecked}
              >
                {t('jailbreakRootWarning.buttonContinue')}
              </Button>
            </Box>
          </Box>
        </MobileLayout>
      </Dialog>
    </>
  );
};
