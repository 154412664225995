import { useMutation } from '@tanstack/react-query';
import { ImageFile } from '../../../component/camera/ImageFile';
import { apis } from 'probonio-shared-ui/module/api';
import { useActiveEmployment } from 'probonio-shared-ui/module/me';
import { useEffect, useState } from 'react';
import { uploadLunchImageFile } from '../lunchFileUpload';
import { LunchReceiptOCRAnalysisDTO } from 'probonio-shared-ui/api';

export function useAnalyzeReceiptWithOCR(selectedImage?: ImageFile): {
  isLoading: boolean;
  ocrAnalysis?: LunchReceiptOCRAnalysisDTO;
  imageKey?: string;
} {
  const getActiveEmployment = useActiveEmployment();
  const [ocrAnalysis, setOCRAnalysis] = useState<LunchReceiptOCRAnalysisDTO>();
  const [imageKey, setImageKey] = useState<string>();

  const ocrMutation = useMutation({
    mutationFn: async () => {
      const imageBlob = await selectedImage!.getBlob();
      const mimeType = selectedImage!.mimetype;

      const { imageKey } = await uploadLunchImageFile(getActiveEmployment().tenantId, getActiveEmployment().id, mimeType, imageBlob);

      const result = await apis.lunch
        .analyzeReceipt({
          tenantId: getActiveEmployment().tenantId,
          employeeId: getActiveEmployment().id,
          createLunchOCRReceiptDTO: { imageKey: imageKey! },
        })
        .then(res => res.data);

      return { result, imageKey };
    },

    onSuccess: ({ result, imageKey }) => {
      setOCRAnalysis(result);
      setImageKey(imageKey);
    },
  });

  useEffect(() => {
    if (selectedImage) {
      ocrMutation.mutate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedImage]);

  return { isLoading: ocrMutation.isPending || !ocrAnalysis, ocrAnalysis, imageKey };
}
