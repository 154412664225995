import { useMutation } from '@tanstack/react-query';
import { UpdateRecreationReceiptDTO } from 'probonio-shared-ui/api';
import { resizeImage } from 'probonio-shared-ui/component/imageResizer/ImageResizer';
import { apis } from 'probonio-shared-ui/module/api';
import { useActiveEmployment } from 'probonio-shared-ui/module/me';
import { ImageFile } from '../../../component/camera/ImageFile';
import { EditReceiptFormValues } from '../../../page/BenefitRecreationPage/EditRecreationReceiptForm';
import { LUNCH_THUMBNAIL_SIZE, MIME_TYPE_JPEG } from '../../lunch/lunchConstants';
import { UploadLinks, uploadRecreationImageFile } from '../recreationFileUpload';

type MappedDTO = Omit<UpdateRecreationReceiptDTO, 'imageKey'>;

export function useUpdateRecreationReceipt(receiptId: string, values: EditReceiptFormValues, onSuccess: () => void) {
  const getActiveEmployment = useActiveEmployment();

  return useMutation({
    mutationFn: async (dto: MappedDTO) => {
      let imageKeys: UploadLinks | undefined;
      if (values.image instanceof ImageFile) {
        const imageBlob = await values.image.getBlob();
        const mimeType = values.image.mimetype;

        let thumbnailBlob;
        if (!values.pdf) {
          const imageURI = await values.image.getURI();
          const thumbnail = await resizeImage(imageURI, LUNCH_THUMBNAIL_SIZE, MIME_TYPE_JPEG);
          thumbnailBlob = thumbnail.blob;
        }

        imageKeys = await uploadRecreationImageFile(
          getActiveEmployment().tenantId,
          getActiveEmployment().id,
          mimeType,
          imageBlob,
          values.pdf,
          thumbnailBlob,
        );
      }
      const fullDTO = { ...dto, ...imageKeys };
      return apis.recreation.updateReceipt({
        tenantId: getActiveEmployment().tenantId,
        employeeId: getActiveEmployment().id,
        receiptId: receiptId!,
        updateRecreationReceiptDTO: fullDTO,
        isAudit: false,
      });
    },

    onSuccess,
  });
}
