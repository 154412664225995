import { Capacitor } from '@capacitor/core';
import { Box, useTheme } from '@mui/material';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { BackIconButton } from '../button/BackIconButton';
import { GradientBox } from './BoxLayout';
import backgroundImage from './img/background.png';
import backgroundImageDark from './img/backgroundDark.png';
import logoVerticalDark from './img/probonio-vertical-dark.svg';
import logoVerticalLight from './img/probonio-vertical.svg';

interface Props {
  layoutTenant?: string;
}

export const RegistrationLayout: React.FC<React.PropsWithChildren<Props>> = ({ layoutTenant, children }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const goToLogin = useCallback(() => {
    navigate('/');
  }, [navigate]);
  const extraPaddingTop = Capacitor.getPlatform() === 'ios' ? 2 : 0;
  const extraTopBackButton = Capacitor.getPlatform() === 'ios' ? 20 : 0;

  return (
    <GradientBox
      sx={{
        height: '100%',
        display: 'flex',
        backgroundImage: `url("${theme.palette.mode === 'light' ? backgroundImage : backgroundImageDark}")`,
        backgroundSize: 'contain',
        backgroundPositionX: 0,
        backgroundPositionY: '50%',
        backgroundRepeat: 'no-repeat',
        [theme.breakpoints.down('sm')]: {
          backgroundSize: 'cover',
          backgroundPositionX: '50%',
          display: 'block',
        },
      }}
    >
      <Box
        sx={{
          flex: '50%',
          paddingTop: 0.75,
          paddingBottom: 1.25,
          textAlign: 'center',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          gap: 4,
          [theme.breakpoints.up('lg')]: {
            flexDirection: 'row',
          },
          [theme.breakpoints.down('sm')]: {
            gap: 2,
            flexDirection: 'row',
            paddingTop: 0.75 + extraPaddingTop,
            backgroundColor: 'background.paper',
            borderBottomLeftRadius: '100%',
            borderBottomRightRadius: '100%',
            width: 'auto',
          },
        }}
      >
        <BackIconButton onClick={goToLogin} sx={{ position: 'fixed', top: 20 + extraTopBackButton, left: 20 }} />
        {layoutTenant && (
          <Box
            component="img"
            src={`/image/tenant/${layoutTenant}.svg`}
            alt="logo"
            sx={{ height: 150, [theme.breakpoints.down('sm')]: { height: 75 } }}
          />
        )}
        <Box
          component="img"
          src={theme.palette.mode === 'light' ? logoVerticalLight : logoVerticalDark}
          alt="logo"
          sx={{ height: 150, [theme.breakpoints.down('sm')]: { height: 75 } }}
        />
      </Box>

      <Box flex="50%" display="flex" flexDirection="column" justifyContent="center">
        <Box padding={1} sx={{ [theme.breakpoints.up('sm')]: { width: '75%', maxWidth: 500 } }}>
          {children}
        </Box>
      </Box>
    </GradientBox>
  );
};
