import { AuthorizationRequestHandler, DefaultCrypto, RedirectRequestHandler } from '@openid/appauth';
import {
  AuthenticateOptions,
  EndSessionRequestHandler,
  ErrorAction,
  ErrorHandler,
  NoHashQueryStringUtils,
  RedirectEndSessionRequestHandler,
} from '@tiffinger-thiel/appauth-react';
import { AuthLocalStorageBackend } from 'probonio-shared-ui/module/auth';
import { CapacitorBrowserLocationLike } from './CapacitorBrowserLocationLike';

export function createMobileAuthHandlers(
  authConfig: AuthenticateOptions,
  onError: ErrorHandler,
): {
  authHandler: AuthorizationRequestHandler;
  endSessionHandler: EndSessionRequestHandler;
} {
  const virtualLocation = new CapacitorBrowserLocationLike(authConfig.redirectUrl, handleRedirect);

  const authHandler = new RedirectRequestHandler(
    new AuthLocalStorageBackend(),
    new NoHashQueryStringUtils(),
    virtualLocation,
    new DefaultCrypto(),
  );
  const endSessionHandler = new RedirectEndSessionRequestHandler(
    new AuthLocalStorageBackend(),
    new NoHashQueryStringUtils(),
    virtualLocation,
  );

  function handleRedirect(): void {
    void authHandler.completeAuthorizationRequestIfPossible().catch(err => onError(err, ErrorAction.COMPLETE_AUTHORIZATION_REQUEST));
  }

  return { authHandler, endSessionHandler };
}
