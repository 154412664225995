import { AxiosResponse } from 'axios';

export function downloadFile(fileName: string, data: string | Blob, contentType?: string): void {
  let url;
  if (typeof data === 'string') {
    url = `data:${contentType?.replace(/ /g, '') || 'text/plain;charset=utf-8'},` + encodeURIComponent(data);
  } else {
    url = URL.createObjectURL(data);
  }
  downloadUrl(url, fileName);
  if (typeof data !== 'string') {
    URL.revokeObjectURL(url);
  }
}

export function downloadUrl(url: string, fileName: string, newTab?: boolean): void {
  const element = document.createElement('a');
  element.setAttribute('href', url);
  element.setAttribute('download', fileName);
  if (newTab) {
    element.setAttribute('target', '_blank');
    element.setAttribute('rel', 'noreferrer');
  }

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}

export function downloadResponse(
  response: AxiosResponse<string | Blob>,
  defaultFileName: string | ((contentType?: string) => string),
): void {
  const contentType = response.headers['content-type'];
  const contentDisposition = response.headers['content-disposition'];
  const fileName = /attachment; filename="([^"]+)"/.exec(contentDisposition || '')?.[1];

  const resolvedFileName = typeof defaultFileName === 'string' ? defaultFileName : defaultFileName(contentType);
  downloadFile(fileName || resolvedFileName, response.data, contentType);
}
