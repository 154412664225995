import { Capacitor } from '@capacitor/core';
import { Device } from '@capacitor/device';
import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';
import { resizeImage } from 'probonio-shared-ui/component/imageResizer/ImageResizer';
import { apis } from 'probonio-shared-ui/module/api';
import { useTranslation } from 'react-i18next';
import { ImageFile } from '../../component/camera/ImageFile';
import { APP_VERSION } from '../../constants';

export function useFeedbackMutation(
  handleSuccess: () => void,
): UseMutationResult<void, Error, { subject: string; message: string; imageFile?: ImageFile }> {
  const { t } = useTranslation('feedbackModule');
  const MIME_TYPE_PNG = 'image/png';

  return useMutation({
    mutationFn: async (data: { subject: string; message: string; imageFile?: ImageFile }) => {
      let resizedFile;
      if (data.imageFile) {
        const uri = await data.imageFile.getURI();
        resizedFile = uri ? await resizeImage(uri, 600, MIME_TYPE_PNG) : undefined;
      }
      const langCode = await Device.getLanguageCode();
      const deviceInfo = await Device.getInfo();
      await apis.feedback.sendFeedback({
        subject: data.subject,
        message: data.message,
        file: resizedFile?.blob,
        uiVersion: APP_VERSION,
        platform: Capacitor.getPlatform(),
        languageCode: langCode.value,
        screenSize: `Width: ${window.innerWidth}px Height: ${window.innerHeight}px`,
        device: `${deviceInfo.model} ${deviceInfo.operatingSystem} ${deviceInfo.osVersion}`,
        manufacturer: deviceInfo.manufacturer,
        userAgent: navigator.userAgent,
      });
    },

    onSuccess: () => {
      enqueueSnackbar(t('feedbackSend'), { variant: 'success' });
      handleSuccess();
    },
  });
}
