import { Box, Button, Paper, Typography } from '@mui/material';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { FullWidthCenterBox } from '../../../component/layout/BoxLayout';
import { useBikeleasingDetails } from './useBikeleasingDetails';

export const BikeleasingRegister: React.FC = () => {
  const { t } = useTranslation('benefitModule');

  const bikeleasingDetails = useBikeleasingDetails();

  if (!bikeleasingDetails || bikeleasingDetails.bikeleasingEmployeeInformation.length) {
    return null;
  }

  return (
    <Box sx={{ marginBottom: 1, marginTop: 1 }}>
      <Paper sx={{ boxShadow: 0, p: 1 }} data-test-id={'bikeleasing-register'}>
        <Typography mb={1}>
          <Trans i18nKey={'benefitModule:benefit.BIKELEASING.alreadyRegisteredInfo'}>
            <a href="https://portal.bikeleasing.de/user/profile" target="_blank" rel="noreferrer"></a>
          </Trans>
        </Typography>
        <Typography mb={1}>
          <Trans>{t('benefit.BIKELEASING.registerNowInfo')}</Trans>
        </Typography>

        <FullWidthCenterBox>
          <Button variant="contained" href="https://portal.bikeleasing.de/registration">
            {t('benefit.BIKELEASING.registerNow')}
          </Button>
        </FullWidthCenterBox>
      </Paper>
    </Box>
  );
};
