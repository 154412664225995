import CheckIcon from '@mui/icons-material/Check';
import { Typography } from '@mui/material';

export const ChecklistItem: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <Typography variant="inherit" gutterBottom mt="0.35em" display="flex" alignItems="center">
      <CheckIcon color="success" sx={{ mr: 0.5 }} /> {children}
    </Typography>
  );
};
