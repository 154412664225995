import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  Box,
  Button,
  Card,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Slider,
  Stack,
  Typography,
} from '@mui/material';
import { DateTime } from 'luxon';
import { BAVBenefitDetailsDTO } from 'probonio-shared-ui/api';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { BasicDialog, useNewDialogState } from '../../component/dialog/BasicDialog';
import { InfoItem } from '../../component/infoAccordion/InfoItem';
import { calculateBAVEstimate } from './bavCalculator';

interface Props {
  bavDetails: BAVBenefitDetailsDTO;
  onSubmit: (yearOfBirth: number, savingsRate: number) => void;
}

const YEAR_RANGE = new Array(50).fill(0).map((v, index) => DateTime.now().year - index - 15); // age 15 - 65
const STEP_SIZE = 50;

export const BAVCalculatorForm: React.FC<Props> = ({ bavDetails, onSubmit }) => {
  const { t } = useTranslation('bavModule');
  const [yearOfBirth, setYearOfBirth] = useState(DateTime.now().year - 30);
  const [savingsRate, setSavingsRate] = useState(0);
  const infoDialog = useNewDialogState();
  const marks = useMemo(() => {
    const steps = [
      ...new Array(Math.floor((bavDetails.maxSavingsRate - 1) / STEP_SIZE) + 1).fill(0).map((v, index) => index * STEP_SIZE),
      bavDetails.maxSavingsRate,
    ];
    return steps.map((value, index) => ({
      value,
      label: index === 0 || index === steps.length - 1 ? `${value}€` : undefined,
    }));
  }, [bavDetails]);

  useEffect(() => {
    setSavingsRate(Math.trunc(bavDetails.maxSavingsRate / 2));
  }, [bavDetails.maxSavingsRate]);

  const handleChangeYearOfBirth = useCallback((event: SelectChangeEvent<number>) => {
    setYearOfBirth(event.target.value as number);
  }, []);

  const handleChangeSavingsRate = useCallback((event: Event, value: number | number[]) => {
    setSavingsRate(value as number);
  }, []);

  const estimate = calculateBAVEstimate(bavDetails, yearOfBirth, savingsRate);

  const handleSubmit = useCallback(() => {
    onSubmit(yearOfBirth, savingsRate);
  }, [onSubmit, savingsRate, yearOfBirth]);

  const navigate = useNavigate();
  const handleNavigate = useCallback(() => {
    navigate(`/bav/meeting`);
  }, [navigate]);

  return (
    <>
      <Box flexGrow={1}>
        <Card sx={{ padding: 1, marginBottom: 2 }}>
          <FormControl fullWidth>
            <InputLabel>{t('calculatorForm.calculator.yearOfBirth')}</InputLabel>
            <Select
              size="small"
              label={t('calculatorForm.calculator.yearOfBirth')}
              fullWidth
              value={yearOfBirth}
              onChange={handleChangeYearOfBirth}
            >
              {YEAR_RANGE.map(year => (
                <MenuItem key={year} value={year}>
                  {year}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <InfoItem label={t('calculatorForm.calculator.savingsRate')} value={`${savingsRate}€`} />
          <Slider
            value={savingsRate}
            onChange={handleChangeSavingsRate}
            valueLabelDisplay="auto"
            marks={marks}
            min={0}
            max={bavDetails.maxSavingsRate}
            step={2}
            sx={{
              '& .MuiSlider-markLabel': {
                transform: 'none',
                color: 'text.primary',
                '& ~ .MuiSlider-markLabel': { transform: 'translateX(-100%)' },
              },
            }}
          />
          <InfoItem
            label={t('calculatorForm.calculator.nettoMonthlyCost')}
            value={
              <Stack direction="row" alignItems="center">
                <div>~{Math.trunc(estimate.nettoMonthlyCost)}€</div>
                <IconButton edge="end" sx={{ marginY: -1 }} onClick={infoDialog.handleOpen}>
                  <InfoOutlinedIcon />
                </IconButton>
              </Stack>
            }
          />
          <InfoItem
            label={t('calculatorForm.calculator.additionalPaymentPercent')}
            value={`${bavDetails.additionalPaymentPercent}% = ${t('common:money', { money: estimate.employerPayment * 100 })}`}
          />
          {bavDetails.vwlConversion ? (
            <InfoItem
              label={t('calculatorForm.calculator.vwlConversion')}
              value={`${t('common:money', { money: bavDetails.vwlConversion })}`}
            />
          ) : undefined}
          <InfoItem label={t('calculatorForm.calculator.rateOfInterest')} value={`${estimate.rateOfInterest * 100}%`} />
        </Card>
        <Card sx={{ padding: 1, marginBottom: 2, textAlign: 'center' }}>
          <Typography variant="caption" color="text.secondary">
            {t('calculatorForm.calculator.pensionRate')}
          </Typography>
          <Typography variant="h1" marginBottom={1}>
            {Math.trunc(estimate.pensionRate).toLocaleString()} €
          </Typography>
          <Typography variant="caption" color="text.secondary">
            {t('calculatorForm.calculator.finalAmount')}
          </Typography>
          <Typography variant="h1">{Math.trunc(estimate.finalAmount).toLocaleString()} €</Typography>
        </Card>
      </Box>
      {!bavDetails.consultants.length ? (
        <Button variant="contained" color="primary" fullWidth onClick={handleSubmit}>
          {t('calculatorForm.calculator.continueButton')}
        </Button>
      ) : (
        <Button variant="contained" color="primary" fullWidth onClick={handleNavigate}>
          {t('calculatorForm.calculator.meeting')}
        </Button>
      )}
      <BasicDialog
        dialogState={infoDialog.dialogState}
        title={t('calculatorForm.calculator.infoTitle')}
        content={
          <Typography whiteSpace="pre-line">
            <Trans i18nKey={'bavModule:calculatorForm.calculator.infoText'} />
          </Typography>
        }
        actions={null}
      />
    </>
  );
};
