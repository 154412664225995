/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { NotificationSettingsDTO } from 'probonio-shared-ui/api';
import { apis } from 'probonio-shared-ui/module/api';
import { useAppSelector } from '../../redux/hooks';

export function useNotificationSettingsQuery(): UseQueryResult<NotificationSettingsDTO, Error> {
  const userID = useAppSelector(state => state.me.user?.id ?? '');

  const queryResult = useQuery({
    queryKey: ['users', userID, 'notificationSettings', apis.user.getNotificationSettings.name],
    queryFn: () =>
      apis.user
        .getNotificationSettings({
          userId: userID,
        })
        .then(res => {
          return res.data;
        }),
  });

  return queryResult;
}
