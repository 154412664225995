import { Box, Paper, Step, StepContent, StepLabel, Stepper, Typography } from '@mui/material';
import { LoadingButton } from 'probonio-shared-ui/component/button';
import * as React from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useBikeDetailsQuery } from './useBikeDetailsQuery';

export const BikeStepper: React.FC = () => {
  const { data: detailsBike } = useBikeDetailsQuery();
  const { t } = useTranslation('benefitModule');

  const handleToPlatform = useCallback(() => {
    window.open(detailsBike!.platformUrl, '_blank', 'noreferrer');
  }, [detailsBike]);

  const steps = [
    {
      label: t('benefit.BIKE.learnMorePage.stepOne.title'),
      description: t('benefit.BIKE.learnMorePage.stepOne.description'),
    },
    {
      label: t('benefit.BIKE.learnMorePage.stepTwo.title'),
      description: t('benefit.BIKE.learnMorePage.stepTwo.description'),
    },
    {
      label: t('benefit.BIKE.learnMorePage.stepThree.title'),
      description: t('benefit.BIKE.learnMorePage.stepThree.description'),
    },
    {
      label: t('benefit.BIKE.learnMorePage.stepFour.title'),
      description: t('benefit.BIKE.learnMorePage.stepFour.description'),
    },
  ];

  return (
    <>
      <Box sx={{ paddingX: 1 }}>
        <Paper sx={{ padding: 1, marginBottom: 1 }}>
          <Box data-test-id="bike-stepper" sx={{ maxWidth: 400 }}>
            <Stepper orientation="vertical">
              {steps.map((step, index) => (
                <Step key={step.label} expanded={true} active={true}>
                  <StepLabel optional={index === 3 ? '' : null}>{step.label}</StepLabel>
                  <StepContent>
                    <Typography variant="body2">{step.description}</Typography>
                  </StepContent>
                </Step>
              ))}
            </Stepper>
          </Box>
          <Box data-test-id="bike-process-text" sx={{ width: '100%', marginTop: 1 }}>
            <Typography data-test-id="bike-process-text" variant="body2">
              {t('benefit.BIKE.learnMorePage.processInfo')}
            </Typography>
          </Box>
        </Paper>
      </Box>
      <Box sx={{ position: 'sticky', bottom: 0, padding: 1, backgroundColor: 'background.default' }}>
        <LoadingButton data-test-id="confirmation-button" variant="contained" fullWidth type="button" onClick={handleToPlatform}>
          {detailsBike?.platformName
            ? t('benefit.BIKE.learnMorePage.bikeSupplier', { platformName: detailsBike.platformName })
            : t('benefit.BIKE.learnMorePage.bikeSupplierFallback')}
        </LoadingButton>
      </Box>
    </>
  );
};

export default BikeStepper;
