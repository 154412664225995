import { Box, Button, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { BasicDialog, DialogState } from '../dialog/BasicDialog';

interface Props {
  dialogState: DialogState;
  onClose: () => void;
}

export const UnsupportedBrowserDialog: React.FC<Props> = ({ dialogState, onClose }) => {
  const { t } = useTranslation('unsupportedBrowserModal');

  const handleClose = useCallback(() => {
    dialogState.handleClose();
    onClose();
  }, [dialogState, onClose]);

  return (
    <BasicDialog
      dataTestId="referral-modal"
      dialogState={dialogState}
      maxWidth="xs"
      fullWidth
      title={t('dialogTitle')}
      content={
        <Box>
          <Typography>{t('description_one')}</Typography>
          <Typography>{t('description_two')}</Typography>
        </Box>
      }
      actions={
        <Grid container flexGrow={1} justifyContent="space-between">
          <Button onClick={handleClose}>{t('actions.noReopen')}</Button>
          <Button variant="text" onClick={dialogState.handleClose}>
            {t('actions.close')}
          </Button>
        </Grid>
      }
    />
  );
};
