import { TextField, TextFieldProps } from '@mui/material';
import { useMemo } from 'react';
import { FieldValues, useController, UseControllerProps } from 'react-hook-form';

export type TextFieldControlProps<T extends FieldValues> = UseControllerProps<T> & TextFieldProps;

export const TextFieldControl = <T extends FieldValues>({
  name,
  rules,
  control,
  defaultValue,
  shouldUnregister,
  ...props
}: TextFieldControlProps<T>): JSX.Element => {
  const { field, fieldState } = useController({ name, rules, control, defaultValue, shouldUnregister });
  const id = useMemo(() => (Math.random() + 1).toString(36).substring(7), []);
  const htmlInputProps = useMemo(
    () =>
      typeof rules?.maxLength === 'number' ? { maxLength: rules?.maxLength, ...props.slotProps?.htmlInput } : props.slotProps?.htmlInput,
    [props.slotProps?.htmlInput, rules?.maxLength],
  );

  return (
    <TextField
      {...field}
      {...props}
      id={id}
      required={props.required || !!rules?.required}
      slotProps={{ input: props.slotProps?.input, htmlInput: htmlInputProps }}
      error={fieldState.invalid}
      helperText={fieldState.error?.message || props.helperText}
    />
  );
};
