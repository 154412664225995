import { Box } from '@mui/material';
import CouponsStatusLayout from '../couponsShared/components/CouponsStatusLayout';
import { useCouponsV2BenefitDetails } from './couponsV2Queries';

interface CouponProps {
  onClick: () => void;
}

export const CouponsV2Status: React.FC<CouponProps> = ({ onClick }) => {
  const benefitDetails = useCouponsV2BenefitDetails();
  const availableCouponsCount = benefitDetails?.availableTokens.length || 0;
  const availableAmount = benefitDetails?.availableTokens.reduce((sum, token) => sum + token.amount, 0) || 0;

  return (
    <Box flexDirection={'column'} sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
      {(benefitDetails?.currentlyActivated || availableCouponsCount >= 0) && (
        <CouponsStatusLayout onClick={onClick} isCouponAvailable={availableAmount > 0} availableAmount={availableAmount} />
      )}
    </Box>
  );
};
