import { Box, Divider, Paper, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { BackIconButton } from '../../component/button/BackIconButton';
import { MobileLayout } from '../../component/layout';
import { AppToolbar } from '../../component/layout/AppToolbar';
import { TenantLogo } from '../../component/layout/TenantLogo';
import { useBackNavigator } from '../../module/navigation/BackNavigator';
import { useAvailableCoupons } from '../../module/coupons/AvailableCouponsContext';
import CheckIcon from '@mui/icons-material/Check';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import SyncIcon from '@mui/icons-material/Sync';
import { useTenant } from 'probonio-shared-ui/module/me';

interface TextProps {
  children: React.ReactNode;
}

const CouponConditionText: React.FC<TextProps> = ({ children }) => {
  return (
    <Box marginLeft={0.3} marginRight={0.5} marginBottom={0.3} display="flex" alignItems={'center'} gap={0.5}>
      <CardGiftcardIcon color="success" />
      <Typography variant="body2">{children}</Typography>
    </Box>
  );
};

const CouponConditions: React.FC = () => {
  const { t } = useTranslation('benefitModule');

  return (
    <Paper elevation={0} sx={{ paddingTop: 0.3 }}>
      <Box sx={{ ml: 0.8 }}>
        <Typography variant="descriptionBold">{t('benefit.COUPONS.infoPage.requiredHints.title')}</Typography>
        <Divider variant={'fullWidth'} sx={{ marginRight: 0.3, marginBottom: 0.3, marginTop: 0.1 }} />
        <CouponConditionText>{t('benefit.COUPONS.infoPage.requiredHints.attention')}</CouponConditionText>
        <CouponConditionText>{t('benefit.COUPONS.infoPage.requiredHints.return')}</CouponConditionText>
        <CouponConditionText>{t('benefit.COUPONS.infoPage.requiredHints.collectionPossible')}</CouponConditionText>
        <CouponConditionText>{t('benefit.COUPONS.infoPage.requiredHints.checkAmount')}</CouponConditionText>
        <CouponConditionText>{t('benefit.COUPONS.infoPage.requiredHints.validity')}</CouponConditionText>
      </Box>
    </Paper>
  );
};

const CouponBenefitsText: React.FC<TextProps> = ({ children }) => {
  return (
    <Box marginLeft={0.3} marginRight={0.5} marginBottom={0.3} display="flex" alignItems={'center'} gap={0.5}>
      <CheckIcon color="success" />
      <Typography variant="body2">{children}</Typography>
    </Box>
  );
};

const CouponStrongText: React.FC<TextProps> = ({ children }) => {
  return (
    <Box marginLeft={0.3} marginRight={0.5} marginBottom={0.3} display="flex" alignItems={'center'} gap={0.5}>
      <CheckIcon color="success" />
      <Typography variant="body2">
        <Trans>{children}</Trans>
      </Typography>
    </Box>
  );
};

const CouponBenefits: React.FC = () => {
  const { t } = useTranslation('benefitModule');
  const { availableCoupons, couponsQuery } = useAvailableCoupons();
  const { tenant } = useTenant();

  const couponOrderDeadline = tenant?.couponOrderDeadline || 31;
  let deadlineKey = 'redeemOnDay';
  if (couponOrderDeadline === 1) {
    deadlineKey = 'redeemFirst';
  } else if (couponOrderDeadline === 31) {
    deadlineKey = 'redeemBefore';
  }

  return (
    <Paper elevation={0} sx={{ paddingTop: 0.3 }}>
      <Box sx={{ ml: 0.8 }}>
        <Typography variant="descriptionBold">{t('benefit.COUPONS.infoPage.howItWorks.title')}</Typography>
        <Divider variant={'fullWidth'} sx={{ marginRight: 0.3, marginBottom: 0.3, marginTop: 0.1 }} />
        <CouponBenefitsText>
          {availableCoupons?.couponMonthlyBudget
            ? t('benefit.COUPONS.infoPage.howItWorks.amount', {
                amountPerVoucher: availableCoupons?.couponMonthlyBudget,
              })
            : t('benefit.COUPONS.infoPage.howItWorks.noAmount')}
        </CouponBenefitsText>
        <CouponBenefitsText>
          {t(`benefit.COUPONS.infoPage.howItWorks.${deadlineKey}`, { couponOrderDeadline: tenant?.couponOrderDeadline })}
        </CouponBenefitsText>
        <CouponStrongText>
          {couponsQuery.data?.details.couponDefaultAllowed
            ? t('benefit.COUPONS.infoPage.howItWorks.noRedemption')
            : t('benefit.COUPONS.infoPage.howItWorks.noAutomaticRedemption')}
        </CouponStrongText>
      </Box>
    </Paper>
  );
};

const CouponAutomaticText: React.FC<TextProps> = ({ children }) => {
  return (
    <Box marginLeft={0.3} marginRight={0.5} marginBottom={0.3} display="flex" alignItems={'center'} gap={0.5}>
      <SyncIcon color="success" />
      <Typography variant="body2">{children}</Typography>
    </Box>
  );
};

const CouponAutomatic: React.FC = () => {
  const { t } = useTranslation('benefitModule');
  const { defaultCoupon } = useAvailableCoupons();
  return (
    <Paper elevation={0} sx={{ paddingTop: 0.3 }}>
      <Box sx={{ ml: 0.8 }}>
        <Typography variant="descriptionBold">{t('benefit.COUPONS.infoPage.automaticRedemption.title')}</Typography>
        <Divider variant={'fullWidth'} sx={{ marginRight: 0.3, marginBottom: 0.3, marginTop: 0.1 }} />
        <CouponAutomaticText>{t('benefit.COUPONS.infoPage.automaticRedemption.whereToActivate')}</CouponAutomaticText>
        <CouponAutomaticText>{t('benefit.COUPONS.infoPage.automaticRedemption.whatHappens')}</CouponAutomaticText>
        {defaultCoupon?.name && (
          <CouponAutomaticText>
            {t('benefit.COUPONS.infoPage.automaticRedemption.ifFallback', { fallbackVoucher: defaultCoupon?.name })}
          </CouponAutomaticText>
        )}
        <CouponAutomaticText>
          {t('benefit.COUPONS.infoPage.automaticRedemption.whereToSee')}
          <SyncIcon sx={{ height: 20, marginBottom: -0.2 }} />
        </CouponAutomaticText>
      </Box>
    </Paper>
  );
};

const CouponInfoPage: React.FC = () => {
  const backNavigator = useBackNavigator();
  const { t } = useTranslation('benefitModule');
  const couponsQuery = useAvailableCoupons();

  return (
    <MobileLayout
      header={<AppToolbar title={t('benefit.COUPONS.infoPage.title')} backButton={<BackIconButton onClick={backNavigator.back} />} />}
    >
      <Box
        display="flex"
        flexDirection="column"
        height={'auto'}
        justifyContent="space-between"
        alignItems="center"
        marginX={1}
        gap={0.8}
        marginBottom={0.7}
      >
        <Box
          borderRadius={'8px'}
          component="img"
          width={'100%'}
          sx={{
            maxWidth: 410,
          }}
          src="/image/coupon-info-picture-scaled.jpg"
        />
        <CouponConditions />
        <CouponBenefits />
        {couponsQuery.couponsQuery.data?.details.couponDefaultAllowed && <CouponAutomatic />}
        <TenantLogo sx={{ width: '80%', textAlign: 'center' }} />
      </Box>
    </MobileLayout>
  );
};
export default CouponInfoPage;
