import CheckIcon from '@mui/icons-material/Check';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import { Box, Divider, Paper, Typography } from '@mui/material';
import { InternetBenefitDetailsDTO } from 'probonio-shared-ui/api';
import { apis, useEmployeeQuery } from 'probonio-shared-ui/module/api';
import { useTranslation } from 'react-i18next';
import { BackIconButton } from '../../component/button/BackIconButton';
import { MobileLayout } from '../../component/layout';
import { AppToolbar } from '../../component/layout/AppToolbar';
import { TenantLogo } from '../../component/layout/TenantLogo';
import { useBackNavigator } from '../../module/navigation/BackNavigator';

interface TextProps {
  children: React.ReactNode;
}

const ReceiptConditionText: React.FC<TextProps> = ({ children }) => {
  return (
    <Box marginLeft={0.3} marginRight={0.5} marginBottom={0.3} display="flex" alignItems={'center'} gap={0.5}>
      <ReceiptLongOutlinedIcon color="success" />
      <Typography variant="body2">{children}</Typography>
    </Box>
  );
};
const ReceiptConditions: React.FC = () => {
  const { t } = useTranslation('benefitModule');
  return (
    <Paper elevation={0} sx={{ paddingTop: 0.3, width: '100%' }}>
      <Box sx={{ ml: 0.8 }}>
        <Typography variant="descriptionBold">{t('benefit.INTERNET.infoPage.requiredHints.title')}</Typography>
        <Divider variant={'fullWidth'} sx={{ marginRight: 0.3, marginBottom: 0.3, marginTop: 0.1 }} />
        <ReceiptConditionText>{t('benefit.INTERNET.infoPage.requiredHints.oneTimeSubmission')}</ReceiptConditionText>
        <ReceiptConditionText>{t('benefit.INTERNET.infoPage.requiredHints.yearlyConfirmation')}</ReceiptConditionText>
        <ReceiptConditionText>{t('benefit.INTERNET.infoPage.requiredHints.newOrAcceptedReceipts')}</ReceiptConditionText>
        <ReceiptConditionText>{t('benefit.INTERNET.infoPage.requiredHints.onlyRunningCosts')}</ReceiptConditionText>
        <ReceiptConditionText>{t('benefit.INTERNET.infoPage.requiredHints.payingBillThemselves')}</ReceiptConditionText>
      </Box>
    </Paper>
  );
};

const ReceiptAdvantagesText: React.FC<TextProps> = ({ children }) => {
  return (
    <Box marginLeft={0.3} marginRight={0.5} marginBottom={0.3} display="flex" alignItems={'center'} gap={0.5}>
      <CheckIcon color="success" />
      <Typography variant="body2">{children}</Typography>
    </Box>
  );
};

const ReceiptBenefits: React.FC = () => {
  const { t } = useTranslation('benefitModule');

  const { data: benefitData } = useEmployeeQuery(['benefits', 'internet', apis.benefits.getBenefit.name], params =>
    apis.benefits.getBenefit({ ...params, benefit: 'INTERNET' }).then(res => res.data),
  );
  const internetDetails = benefitData?.details as InternetBenefitDetailsDTO | undefined;

  return (
    <Paper elevation={0} sx={{ paddingTop: 0.3, width: '100%' }}>
      <Box sx={{ ml: 0.8 }}>
        <Typography variant="descriptionBold">{t('benefit.LUNCH.infoPage.yourLunchBenefits.title')}</Typography>
        <Divider variant={'fullWidth'} sx={{ marginRight: 0.3, marginBottom: 0.3, marginTop: 0.1 }} />
        <ReceiptAdvantagesText>
          {t('benefit.INTERNET.infoPage.yourInternetBenefits.refundHints.monthlyRefund', {
            monthlyBudget: internetDetails?.monthlyBudget,
          })}
        </ReceiptAdvantagesText>
        <ReceiptAdvantagesText>{t('benefit.INTERNET.infoPage.yourInternetBenefits.refundHints.whenRefund')}</ReceiptAdvantagesText>
      </Box>
    </Paper>
  );
};

const InternetInfoPage: React.FC = () => {
  const backNavigator = useBackNavigator();
  const { t } = useTranslation('benefitModule');

  return (
    <MobileLayout
      header={<AppToolbar title={t('benefit.INTERNET.infoPage.title')} backButton={<BackIconButton onClick={backNavigator.back} />} />}
    >
      <Box
        display="flex"
        flexDirection="column"
        height={'auto'}
        justifyContent="space-between"
        alignItems="center"
        marginX={1}
        gap={0.8}
        marginBottom={0.7}
      >
        <Box
          borderRadius={'8px'}
          component="img"
          width={'100%'}
          sx={{
            maxWidth: 410,
          }}
          src="/image/internet-info-picture.png"
        />
        <ReceiptConditions />
        <ReceiptBenefits />
        <TenantLogo sx={{ width: '80%', textAlign: 'center' }} />
      </Box>
    </MobileLayout>
  );
};
export default InternetInfoPage;
