/* tslint:disable */
/* eslint-disable */
/**
 * Probonio full API Docs
 * If you stumbled upon this page and want to integrate Probonio into your business software [click here!](/api/external)    If you\'re looking for more information about Probonio [head over here](https://probonio.de)    **This documentation describes all Endpoints of the Probonio API. It\'s created for experienced Probonio Developers only!**  
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { RegistrationRequestDTO } from '../dto';
// @ts-ignore
import { RegistrationResponseDTO } from '../dto';
// @ts-ignore
import { UpdateRequiredDTO } from '../dto';
/**
 * PublicApi - axios parameter creator
 * @export
 */
export const PublicApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary JSON web key set containing the public key of the registration token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJwks: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/registrationKey/jwks.json`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary openid configuration for the registration token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpenIdConfiguration: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/registrationKey/.well-known/openid-configuration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCheck: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/health`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary register new account by tenant registration code
         * @param {RegistrationRequestDTO} registrationRequestDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerByCode: async (registrationRequestDTO: RegistrationRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'registrationRequestDTO' is not null or undefined
            assertParamExists('registerByCode', 'registrationRequestDTO', registrationRequestDTO)
            const localVarPath = `/public/register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(registrationRequestDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary check compatibility of given version
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        versionCheck: async (version: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('versionCheck', 'version', version)
            const localVarPath = `/versionCheck`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PublicApi - functional programming interface
 * @export
 */
export const PublicApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PublicApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary JSON web key set containing the public key of the registration token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getJwks(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getJwks(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary openid configuration for the registration token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOpenIdConfiguration(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOpenIdConfiguration(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async healthCheck(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.healthCheck(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary register new account by tenant registration code
         * @param {RegistrationRequestDTO} registrationRequestDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async registerByCode(registrationRequestDTO: RegistrationRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RegistrationResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.registerByCode(registrationRequestDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary check compatibility of given version
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async versionCheck(version: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateRequiredDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.versionCheck(version, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PublicApi - factory interface
 * @export
 */
export const PublicApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PublicApiFp(configuration)
    return {
        /**
         * 
         * @summary JSON web key set containing the public key of the registration token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJwks(options?: any): AxiosPromise<void> {
            return localVarFp.getJwks(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary openid configuration for the registration token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpenIdConfiguration(options?: any): AxiosPromise<void> {
            return localVarFp.getOpenIdConfiguration(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCheck(options?: any): AxiosPromise<void> {
            return localVarFp.healthCheck(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary register new account by tenant registration code
         * @param {RegistrationRequestDTO} registrationRequestDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerByCode(registrationRequestDTO: RegistrationRequestDTO, options?: any): AxiosPromise<RegistrationResponseDTO> {
            return localVarFp.registerByCode(registrationRequestDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary check compatibility of given version
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        versionCheck(version: string, options?: any): AxiosPromise<UpdateRequiredDTO> {
            return localVarFp.versionCheck(version, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for registerByCode operation in PublicApi.
 * @export
 * @interface PublicApiRegisterByCodeRequest
 */
export interface PublicApiRegisterByCodeRequest {
    /**
     * 
     * @type {RegistrationRequestDTO}
     * @memberof PublicApiRegisterByCode
     */
    readonly registrationRequestDTO: RegistrationRequestDTO
}

/**
 * Request parameters for versionCheck operation in PublicApi.
 * @export
 * @interface PublicApiVersionCheckRequest
 */
export interface PublicApiVersionCheckRequest {
    /**
     * 
     * @type {string}
     * @memberof PublicApiVersionCheck
     */
    readonly version: string
}

/**
 * PublicApi - object-oriented interface
 * @export
 * @class PublicApi
 * @extends {BaseAPI}
 */
export class PublicApi extends BaseAPI {
    /**
     * 
     * @summary JSON web key set containing the public key of the registration token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    public getJwks(options?: AxiosRequestConfig) {
        return PublicApiFp(this.configuration).getJwks(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary openid configuration for the registration token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    public getOpenIdConfiguration(options?: AxiosRequestConfig) {
        return PublicApiFp(this.configuration).getOpenIdConfiguration(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    public healthCheck(options?: AxiosRequestConfig) {
        return PublicApiFp(this.configuration).healthCheck(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary register new account by tenant registration code
     * @param {PublicApiRegisterByCodeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    public registerByCode(requestParameters: PublicApiRegisterByCodeRequest, options?: AxiosRequestConfig) {
        return PublicApiFp(this.configuration).registerByCode(requestParameters.registrationRequestDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary check compatibility of given version
     * @param {PublicApiVersionCheckRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    public versionCheck(requestParameters: PublicApiVersionCheckRequest, options?: AxiosRequestConfig) {
        return PublicApiFp(this.configuration).versionCheck(requestParameters.version, options).then((request) => request(this.axios, this.basePath));
    }
}
