import { Box, Paper, styled } from '@mui/material';

export const Image = styled('img')(({ theme }) => ({
  objectFit: 'cover',
  borderRadius: theme.shape.borderRadius,
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(1),
  marginBottom: theme.spacing(0.75),
  flexGrow: 1,
}));

export const ContentContainer = styled(Paper)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(1),
  marginBottom: theme.spacing(1),
  padding: theme.spacing(1),
  justifyContent: 'center',
  alignItems: 'center',
  flexGrow: 1,
}));

interface Props {
  image?: string;
  footer?: React.ReactNode;
}

/**
 * Just an image with children in a paper bellow.
 * Headline is not here as it comes from the mobile layout which is in the proponio-ui project.
 */
export const MobileInfoPage: React.FC<React.PropsWithChildren<Props>> = ({ children, image, footer }) => {
  return (
    <Box data-test-id="custom-benefit-creator-page-step-preview" width="100%" display="flex" flexDirection="column">
      {image && <Image height={132} src={image} />}
      <ContentContainer sx={{ wordBreak: 'break-word' }} elevation={0}>
        {children}
      </ContentContainer>
      {footer && (
        <Box display="flex" justifyContent="center" marginBottom={1}>
          {footer}
        </Box>
      )}
    </Box>
  );
};
