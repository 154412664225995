/* tslint:disable */
/* eslint-disable */
/**
 * Probonio full API Docs
 * If you stumbled upon this page and want to integrate Probonio into your business software [click here!](/api/external)    If you\'re looking for more information about Probonio [head over here](https://probonio.de)    **This documentation describes all Endpoints of the Probonio API. It\'s created for experienced Probonio Developers only!**  
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface BAVContractDTO
 */
export interface BAVContractDTO {
    /**
     * 
     * @type {string}
     * @memberof BAVContractDTO
     */
    'status': BAVContractDTOStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof BAVContractDTO
     */
    'origin'?: BAVContractDTOOriginEnum;
    /**
     * 
     * @type {string}
     * @memberof BAVContractDTO
     */
    'paymentPeriod': BAVContractDTOPaymentPeriodEnum;
    /**
     * 
     * @type {string}
     * @memberof BAVContractDTO
     */
    'insuranceType': BAVContractDTOInsuranceTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof BAVContractDTO
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof BAVContractDTO
     */
    'tenantId': string;
    /**
     * 
     * @type {string}
     * @memberof BAVContractDTO
     */
    'employeeId': string;
    /**
     * 
     * @type {string}
     * @memberof BAVContractDTO
     */
    'salutation'?: string;
    /**
     * 
     * @type {string}
     * @memberof BAVContractDTO
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof BAVContractDTO
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof BAVContractDTO
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof BAVContractDTO
     */
    'mail': string;
    /**
     * 
     * @type {string}
     * @memberof BAVContractDTO
     */
    'dateOfBirth': string;
    /**
     * 
     * @type {string}
     * @memberof BAVContractDTO
     */
    'street': string;
    /**
     * 
     * @type {string}
     * @memberof BAVContractDTO
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof BAVContractDTO
     */
    'postalCode': string;
    /**
     * 
     * @type {string}
     * @memberof BAVContractDTO
     */
    'country': string;
    /**
     * 
     * @type {number}
     * @memberof BAVContractDTO
     */
    'employeePremiumGross': number;
    /**
     * 
     * @type {number}
     * @memberof BAVContractDTO
     */
    'employeePremiumNet'?: number;
    /**
     * 
     * @type {number}
     * @memberof BAVContractDTO
     */
    'employerGrant': number;
    /**
     * 
     * @type {number}
     * @memberof BAVContractDTO
     */
    'vwlConversion': number;
    /**
     * 
     * @type {string}
     * @memberof BAVContractDTO
     */
    'startDate': string;
    /**
     * 
     * @type {string}
     * @memberof BAVContractDTO
     */
    'mainDueDate': string;
    /**
     * 
     * @type {string}
     * @memberof BAVContractDTO
     */
    'insuranceIBAN': string;
    /**
     * 
     * @type {string}
     * @memberof BAVContractDTO
     */
    'insuranceName': string;
    /**
     * 
     * @type {string}
     * @memberof BAVContractDTO
     */
    'contractNumber': string;
    /**
     * 
     * @type {string}
     * @memberof BAVContractDTO
     */
    'tariffName': string;
    /**
     * 
     * @type {boolean}
     * @memberof BAVContractDTO
     */
    'isCollective': boolean;
}

export const BAVContractDTOStatusEnum = {
    Active: 'ACTIVE',
    Suspended: 'SUSPENDED',
    Cancelled: 'CANCELLED',
    Transferred: 'TRANSFERRED'
} as const;

export type BAVContractDTOStatusEnum = typeof BAVContractDTOStatusEnum[keyof typeof BAVContractDTOStatusEnum];
export const BAVContractDTOOriginEnum = {
    Probonio: 'PROBONIO',
    Other: 'OTHER'
} as const;

export type BAVContractDTOOriginEnum = typeof BAVContractDTOOriginEnum[keyof typeof BAVContractDTOOriginEnum];
export const BAVContractDTOPaymentPeriodEnum = {
    Monthly: 'MONTHLY',
    Quarterly: 'QUARTERLY',
    Yearly: 'YEARLY'
} as const;

export type BAVContractDTOPaymentPeriodEnum = typeof BAVContractDTOPaymentPeriodEnum[keyof typeof BAVContractDTOPaymentPeriodEnum];
export const BAVContractDTOInsuranceTypeEnum = {
    DirectInsurance: 'DIRECT_INSURANCE',
    ReliefFund: 'RELIEF_FUND'
} as const;

export type BAVContractDTOInsuranceTypeEnum = typeof BAVContractDTOInsuranceTypeEnum[keyof typeof BAVContractDTOInsuranceTypeEnum];


