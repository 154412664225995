export const resizeImage = async (
  uri: string,
  maxSize: number,
  mimeType: string,
): Promise<{ blob: Blob; mimeType: string; isDownscaled: boolean }> => {
  const image = document.createElement('img');
  image.src = uri;

  await new Promise((resolve, reject) => {
    image.onload = resolve;
    image.onerror = reject;
  });

  const canvas = document.createElement('canvas');
  canvas.id = 'canvas';

  const ctx = canvas.getContext('2d');
  if (!ctx) {
    throw new Error('could not get a 2d context');
  }

  canvas.width = image.width;
  canvas.height = image.height;

  let isDownscaled = false;
  if (image.width > maxSize || image.height > maxSize) {
    isDownscaled = true;
    if (canvas.width > canvas.height) {
      canvas.width = maxSize;
      canvas.height = maxSize * (image.height / image.width);
    } else {
      canvas.height = maxSize;
      canvas.width = maxSize * (image.width / image.height);
    }
  }
  ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
  const blob: Blob = await new Promise((resolve, reject) =>
    canvas.toBlob(
      blob => {
        if (blob) {
          resolve(blob);
        } else {
          reject();
        }
      },
      mimeType,
      0.75,
    ),
  );
  return { blob, mimeType, isDownscaled };
};

export function blobToBase64String(blob: Blob): Promise<string> {
  return new Promise<string>((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.onload = event => {
      const dataUrl = event.target!.result as string;
      resolve(dataUrl.split(',')[1]);
    };
    fileReader.onerror = event => {
      reject(event.target!.error);
    };
    fileReader.readAsDataURL(blob);
  });
}
