import React from 'react';
import { UserDTO } from 'probonio-shared-ui/api';
import { ValueListItem } from '../../component/list';
import { Card, List } from '@mui/material';
import { useTranslation } from 'react-i18next';

type MeValues = Pick<UserDTO, 'firstName' | 'lastName' | 'mail' | 'title' | 'salutation'>;

interface Props {
  me?: MeValues;
}

const MeInfoCard: React.FC<Props> = ({ me }) => {
  const { t } = useTranslation('profilePage');

  return (
    <Card elevation={0} sx={{ maxWidth: '100%', marginBottom: 1, flexShrink: 0 }} data-test-id="me-info-card">
      <List>
        <ValueListItem label={t('salutation')} value={me?.salutation || ''} />
        <ValueListItem label={t('title')} value={me?.title || ''} />
        <ValueListItem label={t('firstName')} value={me?.firstName || ''} />
        <ValueListItem label={t('lastName')} value={me?.lastName || ''} />
        <ValueListItem label={t('mail')} value={me?.mail || ''} />
      </List>
    </Card>
  );
};

export default MeInfoCard;
