import { ToggleButtonGroup } from '@mui/material';
import { LunchReceiptDTOReceiptTypeEnum, LunchReceiptRefundDTOReceiptTypeEnum } from 'probonio-shared-ui/api';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ContainedToggleButton } from '../../../component/button/ContainedToggleButton';
import { FullWidthCenterBox } from '../../../component/layout/BoxLayout';

interface Props {
  setLunchReceiptType: (r: LunchReceiptDTOReceiptTypeEnum) => void;
  lunchReceiptType: LunchReceiptDTOReceiptTypeEnum | undefined;
  disabled?: boolean;
}

export const LunchReceiptTypes: React.FC<Props> = ({ setLunchReceiptType, lunchReceiptType, disabled }) => {
  const { t } = useTranslation('benefitModule');
  const noFocus = useCallback<React.MouseEventHandler<HTMLButtonElement>>(ev => ev.preventDefault(), []);

  const lunchReceiptTypes = [LunchReceiptRefundDTOReceiptTypeEnum.Single, LunchReceiptRefundDTOReceiptTypeEnum.Mixed];

  const handleChangeType = useCallback(
    (ev: unknown, newValue: LunchReceiptRefundDTOReceiptTypeEnum) => {
      if (newValue) {
        setLunchReceiptType(newValue);
      }
    },
    [setLunchReceiptType],
  );

  return (
    <FullWidthCenterBox marginBottom={1}>
      <ToggleButtonGroup value={lunchReceiptType} onChange={handleChangeType} exclusive fullWidth size="small" disabled={disabled}>
        {lunchReceiptTypes.map((value, i) => (
          <ContainedToggleButton value={value} key={value + i} onMouseDown={noFocus}>
            {t(`benefit.LUNCH.receiptType.${value}.title`)}
          </ContainedToggleButton>
        ))}
      </ToggleButtonGroup>
    </FullWidthCenterBox>
  );
};
