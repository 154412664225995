import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import { Box, Button, Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { NotificationSettingsDTONotificationChannelsEnum } from 'probonio-shared-ui/api';
import { apis } from 'probonio-shared-ui/module/api';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNotificationSettingsQuery } from '../../module/profile/NotificationSettingsQuery';
import { usePushNotifications } from '../../module/pushNotifications/PushNotificationRegistration';
import { useAppSelector } from '../../redux/hooks';
import { hasSeenAppPromotion } from '../appPromotion/appPromotionModal';
import { BasicDialog } from '../dialog/BasicDialog';

const LOCALSTORAGE_KEY = 'appNotificationModalOpened';

// to prevent showing two popups at the same time, only show notification popup on second load of
// web page, after user has previously clicked away app promotion
const seenAppPromotion = hasSeenAppPromotion();

export const PushNotificationDialog: React.FC = () => {
  const { t } = useTranslation('pushNotificationModule');
  const [isOpen, setIsOpen] = useState(false);
  const { isSupported, hasPermission, requestPermission } = usePushNotifications();
  const userID = useAppSelector(state => state.me.user?.id);
  const { data: notificationSettings } = useNotificationSettingsQuery();

  useEffect(() => {
    void (async () => {
      if (!notificationSettings || !seenAppPromotion) {
        return;
      }

      const pushSupported = await isSupported();
      const permissionGranted = await hasPermission();
      const pushEnabled = notificationSettings?.notificationChannels.includes(NotificationSettingsDTONotificationChannelsEnum.Push);
      const modalState = localStorage.getItem(LOCALSTORAGE_KEY);
      if (modalState !== 'closed' && pushSupported && (!permissionGranted || !pushEnabled)) {
        setIsOpen(true);
      } else if (permissionGranted && pushEnabled) {
        localStorage.setItem(LOCALSTORAGE_KEY, 'closed');
      }
    })();
  }, [hasPermission, isSupported, notificationSettings]);

  const notificationSettingsMutation = useMutation({
    mutationFn: () =>
      apis.user
        .updateNotificationSettings({
          userId: userID!,
          updateNotificationSettingsDTO: { notificationChannels: [NotificationSettingsDTONotificationChannelsEnum.Push] },
        })
        .then(response => response.data),
  });

  const handleClose = useCallback(() => {
    localStorage.setItem(LOCALSTORAGE_KEY, 'closed');
    setIsOpen(false);
  }, []);

  const handleAllow = useCallback(() => {
    handleClose();
    notificationSettingsMutation.mutate();
    void requestPermission();
  }, [handleClose, notificationSettingsMutation, requestPermission]);

  return (
    <>
      <BasicDialog
        maxWidth="xs"
        hideCloseIcon
        dialogState={{ isOpen: isOpen, handleClose: handleClose }}
        title={
          <Box my={0.5} display="flex" justifyContent="center" width="100%">
            <Typography textAlign="center" variant="h2">
              {t('title')}
            </Typography>
          </Box>
        }
        content={
          <>
            <Box textAlign={'center'}>
              <NotificationsActiveIcon color="primary" sx={{ fontSize: 80 }} />
            </Box>
            <Box data-test-id="allow-notification-modal" textAlign={'center'}>
              <Typography variant="description"> {t('text')} </Typography>
              <Button
                data-test-id="confirm-allow-notifications"
                onClick={handleAllow}
                type="submit"
                // color="primary"
                variant="contained"
                sx={{
                  width: '100%',
                  marginTop: 1,
                }}
              >
                {t('signUpButton')}
              </Button>
              <Box textAlign={'center'}>
                <Typography variant="description">{t('permissionForAlerts')}</Typography>
              </Box>
            </Box>

            <Button
              data-test-id="reject-notification"
              onClick={handleClose}
              type="submit"
              color="primary"
              variant="text"
              sx={{ width: '100%' }}
            >
              {t('rejectAlertsButton')}
            </Button>
          </>
        }
        actions={null}
      />
    </>
  );
};
