import { CouponOrderDTO, VoucherDetailsDTO } from 'probonio-shared-ui/api';
import { useMemo } from 'react';

export function useCouponOrderVoucherDetails(couponOrder?: CouponOrderDTO): VoucherDetailsDTO | undefined {
  return useMemo(
    (): VoucherDetailsDTO | undefined =>
      couponOrder && {
        code: couponOrder.voucherCode,
        pin: couponOrder.voucherPin,
        serialNumber: couponOrder.voucherSerialNumber,
        url: couponOrder.voucherUrl,
        displayCode: couponOrder.voucherDisplayCode,
      },
    [couponOrder],
  );
}
