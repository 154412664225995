import { Box, CircularProgress, Typography } from '@mui/material';
import { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroller';
import { getScrollParent } from '../../../utils/scrollParent';
import { useCouponsV2UserTokenListQuery } from '../couponsV2Queries';
import { CouponsV2UserTokenItem } from './CouponsV2UserTokenItem';

export const CouponsV2TokenList: React.FC = () => {
  const { t } = useTranslation('couponsV2Module');
  const boxRef = useRef<HTMLDivElement | null>(null);

  const { data: userTokenList, isLoading, isFetching, hasNextPage, fetchNextPage } = useCouponsV2UserTokenListQuery();

  const handleLoadMore = useCallback(() => {
    void fetchNextPage();
  }, [fetchNextPage]);

  const handleScrollParent = useCallback(() => {
    if (boxRef.current) {
      return getScrollParent(boxRef.current);
    }
    return null;
  }, []);

  return userTokenList && !isLoading ? (
    <>
      {userTokenList?.pages[0]?.totalCount > 0 ? (
        <Box sx={{ padding: 1 }} ref={boxRef}>
          <InfiniteScroll
            pageStart={0}
            loadMore={handleLoadMore}
            hasMore={hasNextPage && !isFetching}
            useWindow={false}
            getScrollParent={handleScrollParent}
            loader={
              <Box key={0} display="flex" justifyContent="center" py={0.5}>
                <CircularProgress />
              </Box>
            }
          >
            {userTokenList.pages
              .flatMap(page => page.results)
              .map(userToken => (
                <CouponsV2UserTokenItem key={userToken.id} userToken={userToken} />
              ))}
          </InfiniteScroll>
        </Box>
      ) : (
        <Typography variant="h2" fontWeight={400} textAlign="center" mt={6} px={1}>
          {t('userTokens.noResults')}
        </Typography>
      )}
    </>
  ) : (
    <Box sx={{ display: 'flex', height: '70%', justifyContent: 'center', alignItems: 'center' }}>
      <CircularProgress />
    </Box>
  );
};
