import { FirebaseApp, initializeApp } from 'firebase/app';
import { getMessaging, getToken, isSupported, MessagePayload, Messaging, onMessage, Unsubscribe } from 'firebase/messaging';
import { PushAdapter, PushCallbacks } from './types';

type MessagePayloadInternal = Omit<MessagePayload, 'messageId'> & { fcmMessageId: string; messageType: string };

/**
 * Adapter for Firebase Web Push notifications using the browser Notification API
 */
export class WebPushAdapter implements PushAdapter {
  private app: FirebaseApp;
  private messaging?: Messaging;
  private unsubscribeOnMessage: Unsubscribe | undefined;
  private swRegistration: ServiceWorkerRegistration | undefined;
  public callbacks: PushCallbacks | undefined;

  public constructor() {
    const firebaseConfig = JSON.parse(import.meta.env.VITE_FIREBASE_CONFIG);

    // Initialize Firebase
    this.app = initializeApp(firebaseConfig);
  }

  public async initialize(): Promise<void> {
    const supported = await this.isSupported();
    if (!supported) {
      return;
    }

    // Initialize Firebase Cloud Messaging and get a reference to the service
    this.messaging = getMessaging(this.app);

    this.unsubscribeOnMessage = onMessage(this.messaging, payload => {
      this.callbacks?.onNotificationReceived({
        id: payload.messageId,
        title: payload.notification?.title,
        body: payload.notification?.body,
        data: payload.data,
      });
    });
    navigator.serviceWorker.addEventListener('message', this.onMessage);

    if (Notification.permission === 'granted') {
      await this.registerMessagingToken();
    }
  }

  public async isSupported(): Promise<boolean> {
    const isMobileBrowser = window.matchMedia('(max-width: 599px)').matches;

    return !isMobileBrowser && (await isSupported());
  }

  public async hasPermission(): Promise<boolean> {
    const supported = await this.isSupported();
    if (!supported) {
      return false;
    }

    return Notification.permission === 'granted';
  }

  public async requestPermission(): Promise<void> {
    const supported = await this.isSupported();
    if (!supported) {
      return;
    }

    const permissionResult = await Notification.requestPermission();
    if (permissionResult === 'granted') {
      await this.registerMessagingToken();
    }
  }

  public async destroy(): Promise<void> {
    const supported = await this.isSupported();
    if (!supported) {
      return;
    }

    this.unsubscribeOnMessage?.();
    navigator.serviceWorker.removeEventListener('message', this.onMessage);
  }

  private onMessage = (event: MessageEvent): void => {
    // https://github.com/firebase/firebase-js-sdk/issues/3922
    if (event.data?.messageType === 'notification-clicked') {
      const payload = event.data as MessagePayloadInternal;
      this.callbacks?.onNotificationAction({
        actionId: '',
        notification: {
          id: payload.fcmMessageId,
          title: payload.notification?.title,
          body: payload.notification?.body,
          data: payload.data,
        },
      });
    }
  };

  private async registerMessagingToken(): Promise<void> {
    try {
      if (!this.swRegistration) {
        this.swRegistration = await this.registerServiceWorker();
      }

      const token = await getToken(this.messaging!, {
        vapidKey: import.meta.env.VITE_FIREBASE_VAPID_KEY,
        serviceWorkerRegistration: this.swRegistration,
      });
      this.callbacks?.onRegistration(token);
    } catch (err) {
      // an error here means that service workers are not supported, or firebase registration failed
      // this is non-critical and we simply don't support web push then
      // still log the error for debugging
      // eslint-disable-next-line no-console
      console.error('Firebase Web Push initialization failed', err);
    }
  }

  // based on https://github.com/firebase/firebase-js-sdk/blob/master/packages/messaging/src/helpers/registerDefaultSw.ts
  private async registerServiceWorker(): Promise<ServiceWorkerRegistration> {
    const swRegistration = await navigator.serviceWorker.register(
      `/firebase-messaging-sw.js?config=${btoa(JSON.stringify(this.app.options))}`,
      { scope: '/firebase-cloud-messaging-push-scope' },
    );
    void swRegistration.update().catch(() => {
      /* it is non blocking and we don't care if it failed */
    });
    return swRegistration;
  }
}
