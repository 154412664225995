import { Box, CircularProgress, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { BackIconButton } from '../../component/button/BackIconButton';
import { MobileLayout } from '../../component/layout';
import { AppToolbar } from '../../component/layout/AppToolbar';
import { useBackNavigator } from '../../module/navigation/BackNavigator';
import { NewsCard } from '../../module/news/NewsCard';
import { useNewsArticles } from '../../module/news/newsQueries';

export const NewsPage: React.FC = () => {
  const theme = useTheme();
  const backNavigator = useBackNavigator();
  const { t } = useTranslation('benefitModule');

  const { data: newsArticles, isLoading } = useNewsArticles({ withImage: true });

  return (
    <MobileLayout
      iosOverloadScrollBackground={theme.palette.background.default}
      header={<AppToolbar title={t('benefit.NEWS.name')} backButton={<BackIconButton onClick={backNavigator.back} />} />}
    >
      {isLoading || !newsArticles?.results.length ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '70vh' }}>
          {isLoading && <CircularProgress />}
          {!newsArticles?.results.length && <Typography variant="caption">{t('benefit.NEWS.missingArticles')}</Typography>}
        </Box>
      ) : (
        <Box px={1}>{newsArticles?.results && newsArticles.results.map(result => <NewsCard key={result.id} article={result} />)}</Box>
      )}
    </MobileLayout>
  );
};
