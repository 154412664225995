import { App as CapacitorApp } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useBackNavigator } from '../../module/navigation/BackNavigator';

const BACK_BUTTON_DELAY = 5000;

/**
 * Listens for the hardware back button and exists the app with confirmation if pressed
 */
export const BackButtonListener: React.FC = () => {
  const { t } = useTranslation('common');
  const [backButtonTime, setBackButtonTime] = useState<Date>();
  const { enqueueSnackbar } = useSnackbar();
  const backNavigator = useBackNavigator();

  const handleExitApp = useCallback(() => {
    if (!backButtonTime || Date.now() - backButtonTime.getTime() > BACK_BUTTON_DELAY) {
      setBackButtonTime(new Date());
      enqueueSnackbar(t('confirmExitApp'));
    } else {
      void CapacitorApp.exitApp();
    }
  }, [backButtonTime, enqueueSnackbar, t]);

  useEffect(() => {
    if (Capacitor.isNativePlatform()) {
      const listener = CapacitorApp.addListener('backButton', () => {
        if (!window.location.hash || window.location.hash === '#/home' || window.location.hash === '#/') {
          handleExitApp();
        } else {
          backNavigator.back();
        }
      });
      return () => {
        void listener.then(listener => void listener.remove());
      };
    }
  }, [handleExitApp, backNavigator]);

  return <></>;
};
