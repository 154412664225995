import { Box, CircularProgress, Divider, Paper, Typography } from '@mui/material';
import { useTenant } from 'probonio-shared-ui/module/me';
import { Trans, useTranslation } from 'react-i18next';
import { BackIconButton } from '../../component/button/BackIconButton';
import { MobileLayout } from '../../component/layout';
import { AppToolbar } from '../../component/layout/AppToolbar';
import { TenantLogo } from '../../component/layout/TenantLogo';
import { useBackNavigator } from '../../module/navigation/BackNavigator';
import { useBAV } from './BAVContext';
import CheckIcon from '@mui/icons-material/Check';
import { BENEFIT_PICTURES } from 'probonio-shared-ui/component/button';

interface TextProps {
  children: React.ReactNode;
}

const BAVStepText: React.FC<TextProps> = ({ children }) => {
  return (
    <Box marginLeft={0.3} marginRight={0.5} marginBottom={0.3} display="flex" alignItems={'center'} gap={0.5}>
      <CheckIcon color="success" />
      <Typography variant="body2">{children}</Typography>
    </Box>
  );
};

const BAVInfoPage: React.FC = () => {
  const backNavigator = useBackNavigator();
  const { bavDetails } = useBAV();
  const { tenant } = useTenant();
  const { t } = useTranslation('benefitModule');

  return (
    <MobileLayout header={<AppToolbar title={t('benefit.BAV.name')} backButton={<BackIconButton onClick={backNavigator.back} />} />}>
      <Box
        display="flex"
        flexDirection="column"
        height={'auto'}
        justifyContent="space-between"
        alignItems="center"
        marginX={1}
        gap={0.8}
        marginBottom={1}
      >
        <Box
          borderRadius={'8px'}
          component="img"
          width={'100%'}
          sx={{
            maxWidth: 410,
          }}
          src={BENEFIT_PICTURES.BAV}
        />
        <Paper elevation={0} sx={{ paddingTop: 0.3 }}>
          <Box marginX={0.8}>
            <Typography variant="descriptionBold">{t('benefitModule:benefit.BAV.whatIs.header')}</Typography>
            <Divider variant={'fullWidth'} sx={{ marginRight: 0.3, marginBottom: 0.3, marginTop: 0.1 }} />
            {bavDetails ? (
              <Typography variant="body2" marginBottom={1}>
                <Trans
                  i18nKey="benefitModule:benefit.BAV.whatIs.description"
                  values={{
                    additionalPaymentPercent: bavDetails.additionalPaymentPercent,
                    tenantName: tenant ? tenant.name : t('benefit.BAV.employer'),
                    vwlText:
                      bavDetails.vwlConversion > 0
                        ? t('benefit.BAV.vwlConversion', { vwlConversion: t('common:money', { money: bavDetails.vwlConversion }) })
                        : '',
                  }}
                />
              </Typography>
            ) : (
              <CircularProgress />
            )}
          </Box>
        </Paper>
        <Paper elevation={0} sx={{ paddingTop: 0.3 }}>
          <Box marginX={0.8}>
            <Typography variant="descriptionBold">{t('benefitModule:benefit.BAV.howItWorks.header')}</Typography>
            <Divider variant={'fullWidth'} sx={{ marginRight: 0.3, marginBottom: 0.3, marginTop: 0.1 }} />
            {bavDetails ? (
              <Typography variant="body2" marginBottom={1}>
                <Trans
                  i18nKey="benefitModule:benefit.BAV.howItWorks.description"
                  values={{
                    additionalPaymentPercent: bavDetails.additionalPaymentPercent,
                    tenantName: tenant ? tenant.name : t('benefit.BAV.employer'),
                    vwlText:
                      bavDetails.vwlConversion > 0
                        ? t('benefit.BAV.vwlConversion', { vwlConversion: t('common:money', { money: bavDetails.vwlConversion }) })
                        : '',
                  }}
                >
                  <BAVStepText>list</BAVStepText>
                  <BAVStepText>list</BAVStepText>
                  <BAVStepText>list</BAVStepText>
                  <BAVStepText>list</BAVStepText>
                </Trans>
              </Typography>
            ) : (
              <CircularProgress />
            )}
          </Box>
        </Paper>

        <TenantLogo sx={{ width: '80%', textAlign: 'center' }} />
      </Box>
    </MobileLayout>
  );
};
export default BAVInfoPage;
