/* tslint:disable */
/* eslint-disable */
/**
 * Probonio full API Docs
 * If you stumbled upon this page and want to integrate Probonio into your business software [click here!](/api/external)    If you\'re looking for more information about Probonio [head over here](https://probonio.de)    **This documentation describes all Endpoints of the Probonio API. It\'s created for experienced Probonio Developers only!**  
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { CouponVariationAvailabilityDTO } from './coupon-variation-availability-dto';

/**
 * 
 * @export
 * @interface CouponDefinitionDTO
 */
export interface CouponDefinitionDTO {
    /**
     * 
     * @type {string}
     * @memberof CouponDefinitionDTO
     */
    'displayMode'?: CouponDefinitionDTODisplayModeEnum;
    /**
     * 
     * @type {string}
     * @memberof CouponDefinitionDTO
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CouponDefinitionDTO
     */
    'productNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof CouponDefinitionDTO
     */
    'name': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CouponDefinitionDTO
     */
    'categories'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CouponDefinitionDTO
     */
    'websiteDescription'?: string;
    /**
     * 
     * @type {string}
     * @memberof CouponDefinitionDTO
     */
    'redemptionDescription'?: string;
    /**
     * 
     * @type {number}
     * @memberof CouponDefinitionDTO
     */
    'redemptionAmountMaximal'?: number;
    /**
     * 
     * @type {string}
     * @memberof CouponDefinitionDTO
     */
    'iconUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof CouponDefinitionDTO
     */
    'imageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof CouponDefinitionDTO
     */
    'redeemUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof CouponDefinitionDTO
     */
    'checkBalanceUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof CouponDefinitionDTO
     */
    'barcodeFormat'?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof CouponDefinitionDTO
     */
    'variations'?: Array<number>;
    /**
     * 
     * @type {Array<CouponVariationAvailabilityDTO>}
     * @memberof CouponDefinitionDTO
     */
    'variationAvailability'?: Array<CouponVariationAvailabilityDTO>;
    /**
     * 
     * @type {Array<number>}
     * @memberof CouponDefinitionDTO
     */
    'combinations'?: Array<number>;
    /**
     * 
     * @type {boolean}
     * @memberof CouponDefinitionDTO
     */
    'enabled'?: boolean;
}

export const CouponDefinitionDTODisplayModeEnum = {
    VoucherUrlOnly: 'VOUCHER_URL_ONLY'
} as const;

export type CouponDefinitionDTODisplayModeEnum = typeof CouponDefinitionDTODisplayModeEnum[keyof typeof CouponDefinitionDTODisplayModeEnum];


