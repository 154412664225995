import { Box } from '@mui/material';
import React from 'react';
import { Helmet } from 'react-helmet';
import { isDevelopment } from '../../constants';
import { useAppSelector } from '../../redux/hooks';

interface Props {
  title: React.ReactNode;
  phoneNumber?: string;
}

export const BavHubspot: React.FC<Props> = ({ title, phoneNumber }) => {
  const MEETING_URL = isDevelopment ? 'https://meetings-eu1.hubspot.com/maximilian-tiffinger' : 'https://meetings-eu1.hubspot.com/dklincov';
  const user = useAppSelector(state => state.me.user);

  const meetingParams = new URLSearchParams({
    embed: 'true',
    firstName: user?.firstName || '',
    lastName: user?.lastName || '',
    email: user?.mail || '',
    phone: phoneNumber || '',
  });

  return (
    <>
      <Helmet>
        <script
          type="text/javascript"
          src={`https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js?v=${Math.random() * 999}`}
        />
      </Helmet>
      <Box paddingBottom={1} paddingX={1}>
        {title}
      </Box>
      <Box paddingBottom={1} paddingX={1} />
      <Box position="relative" flexGrow={1} display="flex" justifyContent="center" alignItems="center">
        <Box position="absolute" left={0} top={0} right={0}>
          <div className="meetings-iframe-container" data-src={`${MEETING_URL}?${meetingParams.toString()}`} />
        </Box>
      </Box>
    </>
  );
};
