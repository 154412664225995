import { Skeleton, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { Trans, useTranslation } from 'react-i18next';
import { CouponsStatusLayout, useAvailableCoupons } from '.';

interface CouponProps {
  onClick: () => void;
}

const startOfNextMonth = DateTime.now().plus({ month: 1 }).startOf('month').toJSDate();

const CouponInfo: React.FC = () => {
  const { availableCoupons } = useAvailableCoupons();
  const { t } = useTranslation('couponsModule');

  if (!availableCoupons) {
    return <Skeleton width="50%" sx={{ display: 'inline-block' }} />;
  }
  if (availableCoupons.coupons.length > 0 && availableCoupons.currentlyActivated) {
    return (
      <Trans i18nKey="couponsModule:coupons.exploreCouponsCard.textCouponAvailable">
        <Typography color="success.main" component="span" variant="caption" />
      </Trans>
    );
  } else if (availableCoupons.activatedNextMonth) {
    return <>{t('coupons.exploreCouponsCard.textCouponNextMonth', { date: startOfNextMonth })}</>;
  }
  return (
    <Trans i18nKey="couponsModule:coupons.exploreCouponsCard.textCouponNotAvailable">
      <Typography color="error.main" component="span" variant="caption" />
    </Trans>
  );
};

export const CouponStatus: React.FC<CouponProps> = ({ onClick }) => {
  const { availableCoupons } = useAvailableCoupons();
  const isCouponAvailable = availableCoupons && availableCoupons.coupons.length > 0;

  return (
    <>
      {availableCoupons?.currentlyActivated && (
        <CouponsStatusLayout
          onClick={onClick}
          isCouponAvailable={isCouponAvailable}
          availableAmount={availableCoupons.coupons.length > 0 ? availableCoupons?.couponMonthlyBudget! : 0}
        />
      )}
      <Typography variant="h3" textAlign="center" marginTop={0.2}>
        <CouponInfo />
      </Typography>
    </>
  );
};
