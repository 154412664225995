import { Alert, Backdrop, Box, CircularProgress, useTheme } from '@mui/material';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { BackIconButton } from '../../component/button/BackIconButton';
import { ImageFile } from '../../component/camera/ImageFile';
import { MobileLayout } from '../../component/layout';
import { AppToolbar } from '../../component/layout/AppToolbar';
import { HighlightGuideButton } from '../../module/guiding';
import { AddReceiptButton } from '../../module/lunch';
import { TicketAmount } from '../../module/mobility/MobilityAmount';
import { MobilityPeriods } from '../../module/mobility/MobilityPeriods';
import { useBackNavigator } from '../../module/navigation/BackNavigator';
import { BenefitDTOBenefitEnum } from 'probonio-shared-ui/api';
import { useMobilityPeriods } from '../../module/mobility/MobilityPeriodsContext';
import { useTenant } from 'probonio-shared-ui/module/me';

interface Props {
  onSelectImage: (file: ImageFile, pdf?: Blob) => void;
  isLoading: boolean;
}

export const MobilityPage: React.FC<Props> = ({ onSelectImage, isLoading }) => {
  const theme = useTheme();
  const backNavigator = useBackNavigator();
  const navigate = useNavigate();
  const { t } = useTranslation('benefitModule');
  const { currentPeriod } = useMobilityPeriods();
  const { tenant } = useTenant();

  const navigateInfo = useCallback(() => {
    navigate('info');
  }, [navigate]);

  const handleSelectImage = useCallback(
    (file: ImageFile, pdf?: Blob) => {
      onSelectImage(file, pdf);
      navigate('receipt/add');
    },
    [onSelectImage, navigate],
  );

  return (
    <MobileLayout
      iosOverloadScrollBackground={theme.palette.background.paper}
      header={
        <AppToolbar
          title={t('benefit.MOBILITY.name')}
          color={theme.palette.primary.contrastText}
          backButton={<BackIconButton onClick={backNavigator.back} />}
          actionButton={
            <HighlightGuideButton
              name={t('benefit.MOBILITY.name')}
              header={t('benefit.MOBILITY.firstHelpGuide.header')}
              text={t('benefit.MOBILITY.firstHelpGuide.text')}
              onClick={navigateInfo}
            />
          }
        />
      }
    >
      <TicketAmount />
      <AddReceiptButton
        benefit={BenefitDTOBenefitEnum.Mobility}
        borderColor={theme.palette.primary.main}
        onSelect={handleSelectImage}
        disabled={currentPeriod?.isLocked}
      />
      <Box sx={{ padding: 1, paddingTop: 0.5 }}>
        {tenant?.lunchPeriodCloseDelay && tenant.lunchPeriodCloseDelay < 0 && (
          <Alert severity="warning" sx={{ marginBottom: 0.5 }}>
            {t('benefit.LUNCH.periodCloseDelayWarning', { count: -tenant.lunchPeriodCloseDelay })}
          </Alert>
        )}
        <MobilityPeriods />
      </Box>
      <Backdrop sx={{ color: 'white', zIndex: theme => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </MobileLayout>
  );
};
