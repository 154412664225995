import { SentryRoutes } from 'probonio-shared-ui/module/error/sentry';
import { FC } from 'react';
import { Route } from 'react-router-dom';
import { isDevelopment } from '../constants';
import PrivatePage from '../module/auth/PrivatePage';
import { BAVRoutes } from './BenefitBAVPage/BAVRoutes';
import BenefitConsultingPage from './BenefitConsultingPage';
import CouponsRoutes from './BenefitCouponPage/CouponsRoutes';
import { BenefitCustomRoutes } from './BenefitCustomPage/BenefitCustomRoutes';
import InternetRoutes from './BenefitInternetPage';
import LunchRoutes from './BenefitLunchPage/LunchRoutes';
import { MobilityRoutes } from './BenefitMobilityPage/MobilityRoutes';
import DebugPage from './DebugPage';
import EditProfilePage from './EditProfilePage';
import Error404Page from './Error404Page';
import Feedback from './FeedbackPage';
import HomePage from './HomePage/HomePage';
import LoginErrorPage from './LoginErrorPage';
import LogoutPage from './LogoutPage';
import OAuthPage from './OAuthPage';
import ProfilePage from './ProfilePage';
import { RegisterByCodePage } from './RegisterByCodePage';
import RegisterErrorPage from './RegisterErrorPage';
import RegisterPage from './RegisterPage';
import SocialLoginError from './SocialLoginError';
import WelcomePage from './WelcomePage';
import BikeRoutes from './BenefitBikePage/BikeRoutes';
import CouponsV2Routes from './BenefitCouponsV2Page/CouponsV2Routes';
import NewsRoutes from './BenefitNewsPage/NewsRoutes';
import FlexRoutes from './BenefitFlexPage/FlexRoutes';
import RecreationRoutes from './BenefitRecreationPage/RecreationRoutes';
import { BikeleasingRoutes } from './BenefitBikeleasingPage';
import LegalTermsPage from '../module/legalTerms/LegalTermsPage';

const AppRoutes: FC = () => (
  <SentryRoutes>
    <Route path="/" element={<WelcomePage />} />
    <Route path="/lunch/*" element={<PrivatePage page={LunchRoutes} />} />
    <Route path="/coupons/*" element={<PrivatePage page={CouponsRoutes} />} />
    <Route path="/couponsV2/*" element={<PrivatePage page={CouponsV2Routes} />} />
    <Route path="/mobility/*" element={<PrivatePage page={MobilityRoutes} />} />
    <Route path="/custom/*" element={<PrivatePage page={BenefitCustomRoutes} />} />
    <Route path="/bav/*" element={<PrivatePage page={BAVRoutes} />} />
    <Route path="/home" element={<PrivatePage page={HomePage} />} />
    <Route path="/internet/*" element={<PrivatePage page={InternetRoutes} />} />
    <Route path="/bike/*" element={<PrivatePage page={BikeRoutes} />} />
    <Route path="/bikeleasing/*" element={<PrivatePage page={BikeleasingRoutes} />} />
    <Route path="/news/*" element={<PrivatePage page={NewsRoutes} />} />
    <Route path="/recreation/*" element={<PrivatePage page={RecreationRoutes} />} />
    <Route path="/flex/*" element={<PrivatePage page={FlexRoutes} />} />
    <Route path="/profile" element={<PrivatePage page={ProfilePage} />} />
    <Route path="/profile/edit" element={<PrivatePage page={EditProfilePage} />} />
    <Route path="/benefit-consulting" element={<PrivatePage page={BenefitConsultingPage} />} />
    <Route path="/feedback" element={<PrivatePage page={Feedback} />} />
    <Route path="/terms" element={<PrivatePage page={LegalTermsPage} />} />
    <Route path="/oauth" element={<OAuthPage />} />
    <Route path="/logout" element={<LogoutPage />} />
    <Route path="/register" element={<RegisterPage />} />
    <Route path="/register/error" element={<RegisterErrorPage />} />
    <Route path="/register/code" element={<RegisterByCodePage />} />
    <Route path="/socialLoginError" element={<SocialLoginError />} />
    <Route path="/login/error" element={<LoginErrorPage />} />
    {isDevelopment && <Route path="/debug/*" element={<DebugPage />} />}
    <Route path="/*" element={<Error404Page />} />
  </SentryRoutes>
);

export default AppRoutes;
