/* tslint:disable */
/* eslint-disable */
/**
 * Probonio full API Docs
 * If you stumbled upon this page and want to integrate Probonio into your business software [click here!](/api/external)    If you\'re looking for more information about Probonio [head over here](https://probonio.de)    **This documentation describes all Endpoints of the Probonio API. It\'s created for experienced Probonio Developers only!**  
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { BikeleasingConditionListDTO } from '../dto';
// @ts-ignore
import { BikeleasingContractDTO } from '../dto';
// @ts-ignore
import { BikeleasingContractsListDTO } from '../dto';
// @ts-ignore
import { BikeleasingEmployeeInformationListDTO } from '../dto';
// @ts-ignore
import { CreateBikeleasingConditionDTO } from '../dto';
// @ts-ignore
import { CreateBikeleasingTenantDTO } from '../dto';
// @ts-ignore
import { IdentifierDTO } from '../dto';
// @ts-ignore
import { ServiceExceptionDTO } from '../dto';
/**
 * BikeleasingApi - axios parameter creator
 * @export
 */
export const BikeleasingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Bikeleasing-Condition
         * @param {string} tenantId 
         * @param {CreateBikeleasingConditionDTO} createBikeleasingConditionDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCondition: async (tenantId: string, createBikeleasingConditionDTO: CreateBikeleasingConditionDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('createCondition', 'tenantId', tenantId)
            // verify required parameter 'createBikeleasingConditionDTO' is not null or undefined
            assertParamExists('createCondition', 'createBikeleasingConditionDTO', createBikeleasingConditionDTO)
            const localVarPath = `/tenants/{tenantId}/benefits/bikeleasing/conditions`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createBikeleasingConditionDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Bikeleasing-Condition
         * @param {string} tenantId 
         * @param {string} conditionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCondition: async (tenantId: string, conditionId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('deleteCondition', 'tenantId', tenantId)
            // verify required parameter 'conditionId' is not null or undefined
            assertParamExists('deleteCondition', 'conditionId', conditionId)
            const localVarPath = `/tenants/{tenantId}/benefits/bikeleasing/conditions/{conditionId}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"conditionId"}}`, encodeURIComponent(String(conditionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List all Bikeleasing-Conditions synced for Tenant
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} contractId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBikeleasingContractById: async (tenantId: string, employeeId: string, contractId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('getBikeleasingContractById', 'tenantId', tenantId)
            // verify required parameter 'employeeId' is not null or undefined
            assertParamExists('getBikeleasingContractById', 'employeeId', employeeId)
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('getBikeleasingContractById', 'contractId', contractId)
            const localVarPath = `/tenants/{tenantId}/employees/{employeeId}/benefits/bikeleasing/contracts/{contractId}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"employeeId"}}`, encodeURIComponent(String(employeeId)))
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List all Bikeleasing-Conditions synced for Tenant
         * @param {string} tenantId 
         * @param {string} [searchText] 
         * @param {Array<string>} [sortBy] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBikeleasingContracts: async (tenantId: string, searchText?: string, sortBy?: Array<string>, page?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('getBikeleasingContracts', 'tenantId', tenantId)
            const localVarPath = `/tenants/{tenantId}/benefits/bikeleasing/contracts`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (searchText !== undefined) {
                localVarQueryParameter['searchText'] = searchText;
            }

            if (sortBy) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List all Bikeleasing-Conditions synced for Tenant
         * @param {string} tenantId 
         * @param {Array<string>} employeeIds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmployeeInformations: async (tenantId: string, employeeIds: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('getEmployeeInformations', 'tenantId', tenantId)
            // verify required parameter 'employeeIds' is not null or undefined
            assertParamExists('getEmployeeInformations', 'employeeIds', employeeIds)
            const localVarPath = `/tenants/{tenantId}/benefits/bikeleasing/employeeInformation`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (employeeIds) {
                localVarQueryParameter['employeeIds'] = employeeIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List all Bikeleasing-Conditions synced for Tenant
         * @param {string} tenantId 
         * @param {string} [filter] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {Array<string>} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listConditions: async (tenantId: string, filter?: string, page?: number, pageSize?: number, sortBy?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('listConditions', 'tenantId', tenantId)
            const localVarPath = `/tenants/{tenantId}/benefits/bikeleasing/conditions`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (sortBy) {
                localVarQueryParameter['sortBy'] = sortBy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateBikeleasingTenantDTO} createBikeleasingTenantDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerBikeleasingUser: async (createBikeleasingTenantDTO: CreateBikeleasingTenantDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createBikeleasingTenantDTO' is not null or undefined
            assertParamExists('registerBikeleasingUser', 'createBikeleasingTenantDTO', createBikeleasingTenantDTO)
            const localVarPath = `/bikeleasing/register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createBikeleasingTenantDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sync Bikeleasing-Condition
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncConditionsWithEmployees: async (tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('syncConditionsWithEmployees', 'tenantId', tenantId)
            const localVarPath = `/tenants/{tenantId}/benefits/bikeleasing/sync`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BikeleasingApi - functional programming interface
 * @export
 */
export const BikeleasingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BikeleasingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Bikeleasing-Condition
         * @param {string} tenantId 
         * @param {CreateBikeleasingConditionDTO} createBikeleasingConditionDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCondition(tenantId: string, createBikeleasingConditionDTO: CreateBikeleasingConditionDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCondition(tenantId, createBikeleasingConditionDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Bikeleasing-Condition
         * @param {string} tenantId 
         * @param {string} conditionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCondition(tenantId: string, conditionId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCondition(tenantId, conditionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List all Bikeleasing-Conditions synced for Tenant
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} contractId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBikeleasingContractById(tenantId: string, employeeId: string, contractId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BikeleasingContractDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBikeleasingContractById(tenantId, employeeId, contractId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List all Bikeleasing-Conditions synced for Tenant
         * @param {string} tenantId 
         * @param {string} [searchText] 
         * @param {Array<string>} [sortBy] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBikeleasingContracts(tenantId: string, searchText?: string, sortBy?: Array<string>, page?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BikeleasingContractsListDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBikeleasingContracts(tenantId, searchText, sortBy, page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List all Bikeleasing-Conditions synced for Tenant
         * @param {string} tenantId 
         * @param {Array<string>} employeeIds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEmployeeInformations(tenantId: string, employeeIds: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BikeleasingEmployeeInformationListDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEmployeeInformations(tenantId, employeeIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List all Bikeleasing-Conditions synced for Tenant
         * @param {string} tenantId 
         * @param {string} [filter] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {Array<string>} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listConditions(tenantId: string, filter?: string, page?: number, pageSize?: number, sortBy?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BikeleasingConditionListDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listConditions(tenantId, filter, page, pageSize, sortBy, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateBikeleasingTenantDTO} createBikeleasingTenantDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async registerBikeleasingUser(createBikeleasingTenantDTO: CreateBikeleasingTenantDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdentifierDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.registerBikeleasingUser(createBikeleasingTenantDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Sync Bikeleasing-Condition
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncConditionsWithEmployees(tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncConditionsWithEmployees(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BikeleasingApi - factory interface
 * @export
 */
export const BikeleasingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BikeleasingApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Bikeleasing-Condition
         * @param {string} tenantId 
         * @param {CreateBikeleasingConditionDTO} createBikeleasingConditionDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCondition(tenantId: string, createBikeleasingConditionDTO: CreateBikeleasingConditionDTO, options?: any): AxiosPromise<void> {
            return localVarFp.createCondition(tenantId, createBikeleasingConditionDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Bikeleasing-Condition
         * @param {string} tenantId 
         * @param {string} conditionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCondition(tenantId: string, conditionId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteCondition(tenantId, conditionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List all Bikeleasing-Conditions synced for Tenant
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} contractId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBikeleasingContractById(tenantId: string, employeeId: string, contractId: string, options?: any): AxiosPromise<BikeleasingContractDTO> {
            return localVarFp.getBikeleasingContractById(tenantId, employeeId, contractId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List all Bikeleasing-Conditions synced for Tenant
         * @param {string} tenantId 
         * @param {string} [searchText] 
         * @param {Array<string>} [sortBy] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBikeleasingContracts(tenantId: string, searchText?: string, sortBy?: Array<string>, page?: number, pageSize?: number, options?: any): AxiosPromise<BikeleasingContractsListDTO> {
            return localVarFp.getBikeleasingContracts(tenantId, searchText, sortBy, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List all Bikeleasing-Conditions synced for Tenant
         * @param {string} tenantId 
         * @param {Array<string>} employeeIds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmployeeInformations(tenantId: string, employeeIds: Array<string>, options?: any): AxiosPromise<BikeleasingEmployeeInformationListDTO> {
            return localVarFp.getEmployeeInformations(tenantId, employeeIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List all Bikeleasing-Conditions synced for Tenant
         * @param {string} tenantId 
         * @param {string} [filter] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {Array<string>} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listConditions(tenantId: string, filter?: string, page?: number, pageSize?: number, sortBy?: Array<string>, options?: any): AxiosPromise<BikeleasingConditionListDTO> {
            return localVarFp.listConditions(tenantId, filter, page, pageSize, sortBy, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateBikeleasingTenantDTO} createBikeleasingTenantDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerBikeleasingUser(createBikeleasingTenantDTO: CreateBikeleasingTenantDTO, options?: any): AxiosPromise<IdentifierDTO> {
            return localVarFp.registerBikeleasingUser(createBikeleasingTenantDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sync Bikeleasing-Condition
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncConditionsWithEmployees(tenantId: string, options?: any): AxiosPromise<void> {
            return localVarFp.syncConditionsWithEmployees(tenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createCondition operation in BikeleasingApi.
 * @export
 * @interface BikeleasingApiCreateConditionRequest
 */
export interface BikeleasingApiCreateConditionRequest {
    /**
     * 
     * @type {string}
     * @memberof BikeleasingApiCreateCondition
     */
    readonly tenantId: string

    /**
     * 
     * @type {CreateBikeleasingConditionDTO}
     * @memberof BikeleasingApiCreateCondition
     */
    readonly createBikeleasingConditionDTO: CreateBikeleasingConditionDTO
}

/**
 * Request parameters for deleteCondition operation in BikeleasingApi.
 * @export
 * @interface BikeleasingApiDeleteConditionRequest
 */
export interface BikeleasingApiDeleteConditionRequest {
    /**
     * 
     * @type {string}
     * @memberof BikeleasingApiDeleteCondition
     */
    readonly tenantId: string

    /**
     * 
     * @type {string}
     * @memberof BikeleasingApiDeleteCondition
     */
    readonly conditionId: string
}

/**
 * Request parameters for getBikeleasingContractById operation in BikeleasingApi.
 * @export
 * @interface BikeleasingApiGetBikeleasingContractByIdRequest
 */
export interface BikeleasingApiGetBikeleasingContractByIdRequest {
    /**
     * 
     * @type {string}
     * @memberof BikeleasingApiGetBikeleasingContractById
     */
    readonly tenantId: string

    /**
     * 
     * @type {string}
     * @memberof BikeleasingApiGetBikeleasingContractById
     */
    readonly employeeId: string

    /**
     * 
     * @type {string}
     * @memberof BikeleasingApiGetBikeleasingContractById
     */
    readonly contractId: string
}

/**
 * Request parameters for getBikeleasingContracts operation in BikeleasingApi.
 * @export
 * @interface BikeleasingApiGetBikeleasingContractsRequest
 */
export interface BikeleasingApiGetBikeleasingContractsRequest {
    /**
     * 
     * @type {string}
     * @memberof BikeleasingApiGetBikeleasingContracts
     */
    readonly tenantId: string

    /**
     * 
     * @type {string}
     * @memberof BikeleasingApiGetBikeleasingContracts
     */
    readonly searchText?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof BikeleasingApiGetBikeleasingContracts
     */
    readonly sortBy?: Array<string>

    /**
     * 
     * @type {number}
     * @memberof BikeleasingApiGetBikeleasingContracts
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof BikeleasingApiGetBikeleasingContracts
     */
    readonly pageSize?: number
}

/**
 * Request parameters for getEmployeeInformations operation in BikeleasingApi.
 * @export
 * @interface BikeleasingApiGetEmployeeInformationsRequest
 */
export interface BikeleasingApiGetEmployeeInformationsRequest {
    /**
     * 
     * @type {string}
     * @memberof BikeleasingApiGetEmployeeInformations
     */
    readonly tenantId: string

    /**
     * 
     * @type {Array<string>}
     * @memberof BikeleasingApiGetEmployeeInformations
     */
    readonly employeeIds: Array<string>
}

/**
 * Request parameters for listConditions operation in BikeleasingApi.
 * @export
 * @interface BikeleasingApiListConditionsRequest
 */
export interface BikeleasingApiListConditionsRequest {
    /**
     * 
     * @type {string}
     * @memberof BikeleasingApiListConditions
     */
    readonly tenantId: string

    /**
     * 
     * @type {string}
     * @memberof BikeleasingApiListConditions
     */
    readonly filter?: string

    /**
     * 
     * @type {number}
     * @memberof BikeleasingApiListConditions
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof BikeleasingApiListConditions
     */
    readonly pageSize?: number

    /**
     * 
     * @type {Array<string>}
     * @memberof BikeleasingApiListConditions
     */
    readonly sortBy?: Array<string>
}

/**
 * Request parameters for registerBikeleasingUser operation in BikeleasingApi.
 * @export
 * @interface BikeleasingApiRegisterBikeleasingUserRequest
 */
export interface BikeleasingApiRegisterBikeleasingUserRequest {
    /**
     * 
     * @type {CreateBikeleasingTenantDTO}
     * @memberof BikeleasingApiRegisterBikeleasingUser
     */
    readonly createBikeleasingTenantDTO: CreateBikeleasingTenantDTO
}

/**
 * Request parameters for syncConditionsWithEmployees operation in BikeleasingApi.
 * @export
 * @interface BikeleasingApiSyncConditionsWithEmployeesRequest
 */
export interface BikeleasingApiSyncConditionsWithEmployeesRequest {
    /**
     * 
     * @type {string}
     * @memberof BikeleasingApiSyncConditionsWithEmployees
     */
    readonly tenantId: string
}

/**
 * BikeleasingApi - object-oriented interface
 * @export
 * @class BikeleasingApi
 * @extends {BaseAPI}
 */
export class BikeleasingApi extends BaseAPI {
    /**
     * 
     * @summary Create Bikeleasing-Condition
     * @param {BikeleasingApiCreateConditionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BikeleasingApi
     */
    public createCondition(requestParameters: BikeleasingApiCreateConditionRequest, options?: AxiosRequestConfig) {
        return BikeleasingApiFp(this.configuration).createCondition(requestParameters.tenantId, requestParameters.createBikeleasingConditionDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Bikeleasing-Condition
     * @param {BikeleasingApiDeleteConditionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BikeleasingApi
     */
    public deleteCondition(requestParameters: BikeleasingApiDeleteConditionRequest, options?: AxiosRequestConfig) {
        return BikeleasingApiFp(this.configuration).deleteCondition(requestParameters.tenantId, requestParameters.conditionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List all Bikeleasing-Conditions synced for Tenant
     * @param {BikeleasingApiGetBikeleasingContractByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BikeleasingApi
     */
    public getBikeleasingContractById(requestParameters: BikeleasingApiGetBikeleasingContractByIdRequest, options?: AxiosRequestConfig) {
        return BikeleasingApiFp(this.configuration).getBikeleasingContractById(requestParameters.tenantId, requestParameters.employeeId, requestParameters.contractId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List all Bikeleasing-Conditions synced for Tenant
     * @param {BikeleasingApiGetBikeleasingContractsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BikeleasingApi
     */
    public getBikeleasingContracts(requestParameters: BikeleasingApiGetBikeleasingContractsRequest, options?: AxiosRequestConfig) {
        return BikeleasingApiFp(this.configuration).getBikeleasingContracts(requestParameters.tenantId, requestParameters.searchText, requestParameters.sortBy, requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List all Bikeleasing-Conditions synced for Tenant
     * @param {BikeleasingApiGetEmployeeInformationsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BikeleasingApi
     */
    public getEmployeeInformations(requestParameters: BikeleasingApiGetEmployeeInformationsRequest, options?: AxiosRequestConfig) {
        return BikeleasingApiFp(this.configuration).getEmployeeInformations(requestParameters.tenantId, requestParameters.employeeIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List all Bikeleasing-Conditions synced for Tenant
     * @param {BikeleasingApiListConditionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BikeleasingApi
     */
    public listConditions(requestParameters: BikeleasingApiListConditionsRequest, options?: AxiosRequestConfig) {
        return BikeleasingApiFp(this.configuration).listConditions(requestParameters.tenantId, requestParameters.filter, requestParameters.page, requestParameters.pageSize, requestParameters.sortBy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BikeleasingApiRegisterBikeleasingUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BikeleasingApi
     */
    public registerBikeleasingUser(requestParameters: BikeleasingApiRegisterBikeleasingUserRequest, options?: AxiosRequestConfig) {
        return BikeleasingApiFp(this.configuration).registerBikeleasingUser(requestParameters.createBikeleasingTenantDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sync Bikeleasing-Condition
     * @param {BikeleasingApiSyncConditionsWithEmployeesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BikeleasingApi
     */
    public syncConditionsWithEmployees(requestParameters: BikeleasingApiSyncConditionsWithEmployeesRequest, options?: AxiosRequestConfig) {
        return BikeleasingApiFp(this.configuration).syncConditionsWithEmployees(requestParameters.tenantId, options).then((request) => request(this.axios, this.basePath));
    }
}
