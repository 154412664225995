import { styled, ToggleButton } from '@mui/material';

export const ContainedToggleButton = styled(ToggleButton)(({ color, theme }) => {
  const themeColor = (color === 'standard' ? '' : color) || 'primary';
  return {
    color: theme.palette[themeColor].main,
    borderColor: theme.palette[themeColor].main,
    '&.Mui-selected, &.Mui-selected:hover': {
      color: theme.palette[themeColor].contrastText,
      backgroundColor: theme.palette[themeColor].main,
    },
  };
});
