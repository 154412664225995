import HomeIcon from '@mui/icons-material/Home';
import LogoutIcon from '@mui/icons-material/Logout';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import { Button } from '@mui/material';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorView } from './ErrorView';

interface Props {
  logout?: () => void;
  eventId?: string;
}

export const ErrorFallback = ({ logout, eventId }: Props): JSX.Element => {
  const { t } = useTranslation('errorModule');

  const handleGoHome = useCallback(() => {
    window.location.href = '/';
  }, []);

  const handleGetSupport = useCallback(() => {
    window.location.href = '/benefit-consulting';
  }, []);

  return (
    <ErrorView
      headlineLarge={t('unhandled.headlineLarge')}
      headlineSmall={t('unhandled.headlineSmall')}
      message={t('unhandled.message')}
      eventId={eventId}
    >
      <Button startIcon={<HomeIcon />} color="warning" variant="contained" onClick={handleGoHome} sx={{ margin: 0.5 }}>
        {t('goHome')}
      </Button>
      {/* Logout Button will only be displayed if there is an after the Auth Provider is initialized */}
      {logout && (
        <>
          <Button startIcon={<ContactSupportIcon />} color="warning" variant="contained" sx={{ margin: 0.5 }} onClick={handleGetSupport}>
            {t('getSupport')}
          </Button>
          <Button startIcon={<LogoutIcon />} sx={{ margin: 0.5 }} color="warning" variant="contained" onClick={logout}>
            {t('logout')}
          </Button>
        </>
      )}
    </ErrorView>
  );
};
