import {
  MobilityReceiptDTOMobilityTypeEnum,
  MobilityReceiptRefundDTOMobilityTypeEnum,
  MobilityReceiptRefundDTOTravelPurposeEnum,
} from 'probonio-shared-ui/api';
import { useMemo, useState } from 'react';

export interface MobilityTypeState {
  travelPurpose: MobilityReceiptRefundDTOTravelPurposeEnum;
  mobilityType?: MobilityReceiptRefundDTOMobilityTypeEnum;
  validityMonths?: number;
  setTravelPurpose: (value: MobilityReceiptRefundDTOTravelPurposeEnum) => void;
  setMobilityType: (value: MobilityReceiptRefundDTOMobilityTypeEnum) => void;
  setValidityMonths: (value: number) => void;
  isTimeCard: boolean;
}

export function useMobilityTypeState(): MobilityTypeState {
  const [travelPurpose, setTravelPurpose] = useState<MobilityReceiptRefundDTOTravelPurposeEnum>(
    MobilityReceiptRefundDTOTravelPurposeEnum.Private,
  );
  const [mobilityType, setMobilityType] = useState<MobilityReceiptRefundDTOMobilityTypeEnum>();
  const [validityMonths, setValidityMonths] = useState<number>();

  return useMemo(
    (): MobilityTypeState => ({
      travelPurpose,
      mobilityType,
      validityMonths,
      setTravelPurpose,
      setMobilityType,
      setValidityMonths,
      isTimeCard: mobilityType === MobilityReceiptDTOMobilityTypeEnum.TimeCard && !!validityMonths && validityMonths !== 1,
    }),
    [mobilityType, travelPurpose, validityMonths],
  );
}
