import KeyboardIcon from '@mui/icons-material/Keyboard';
import { useSnackbar } from 'notistack';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { CalculatorFormField } from '../../component/currencyInput/CalculatorFormField';
import { useCalculatorState } from '../../component/currencyInput/useCalculatorState';
import { useNewDialogState } from '../../component/dialog/BasicDialog';
import { LoadingLayout } from '../../component/layout/LoadingLayout';
import { FullscreenStep } from '../../component/stepper/FullscreenStep';
import { ImageZoomerStep } from '../../component/stepper/ImageZoomerStep';
import { SimpleStepper } from '../../component/stepper/SimpleStepper';
import { useStepperState } from '../../component/stepper/useStepperState';
import { DiscardFormConfirmation } from '../../module/benefit/addReceipt/DiscardFormConfirmation';
import { useRecreationPeriods } from '../../module/recreation/RecreationPeriodsContext';
import { RecreationSummary } from '../../module/recreation/RecreationSummary';
import { mapCreateRecreationReceipt, useCreateRecreationReceipt } from '../../module/recreation/addReceipt/useCreateRecreationReceipt';
import { ImageSelectionState } from '../../module/shareTarget/useImageSelection';

interface Props {
  imageSelection: ImageSelectionState;
}

export const AddRecreationReceiptPage: React.FC<Props> = ({ imageSelection }) => {
  const { t } = useTranslation('benefitModule');
  const { refetchRecreation, currentPeriod } = useRecreationPeriods();
  const cancelConfirmation = useNewDialogState();
  const { activeStep, handleNext, handlePrevious } = useStepperState(cancelConfirmation.handleOpen);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const calculatorState = useCalculatorState();
  const [comment, setComment] = useState<string>();

  const handleClose = useCallback((): void => {
    navigate(-1);
  }, [navigate]);

  const mutation = useCreateRecreationReceipt(imageSelection, () => {
    enqueueSnackbar(t(`benefit.RECREATION.addReceiptFlow.createReceiptSuccess`), { variant: 'success' });
    refetchRecreation();
    handleClose();
  });

  const handleConfirm = useCallback(() => {
    const mappedRequest = mapCreateRecreationReceipt(calculatorState, comment);
    mutation.mutate(mappedRequest);
  }, [calculatorState, comment, mutation]);

  if (!currentPeriod || !imageSelection.selectedImage) {
    return <LoadingLayout />;
  }

  return (
    <>
      <DiscardFormConfirmation dialogState={cancelConfirmation.dialogState} onSubmit={handleClose} />
      <SimpleStepper activeStep={activeStep}>
        <ImageZoomerStep
          icon={<KeyboardIcon />}
          image={imageSelection.selectedImage}
          onPrevious={handlePrevious}
          onCancel={cancelConfirmation.handleOpen}
        >
          <CalculatorFormField
            state={calculatorState}
            placeholder={t(`benefit.RECREATION.addReceiptFlow.hintTotal`)}
            hideCalculator
            onSubmit={handleNext}
          />
        </ImageZoomerStep>
        <FullscreenStep
          title={t('benefit.RECREATION.addReceiptFlow.addReceiptSummary')}
          onPrevious={handlePrevious}
          onCancel={cancelConfirmation.handleOpen}
        >
          <RecreationSummary
            calculatorState={calculatorState}
            receiptDate={new Date()}
            loading={mutation.isPending}
            comment={comment}
            onChangeComment={setComment}
            onSubmit={handleConfirm}
          />
        </FullscreenStep>
      </SimpleStepper>
    </>
  );
};
