/* tslint:disable */
/* eslint-disable */
/**
 * Probonio full API Docs
 * If you stumbled upon this page and want to integrate Probonio into your business software [click here!](/api/external)    If you\'re looking for more information about Probonio [head over here](https://probonio.de)    **This documentation describes all Endpoints of the Probonio API. It\'s created for experienced Probonio Developers only!**  
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { CouponDefinitionDTO } from './coupon-definition-dto';

/**
 * 
 * @export
 * @interface CouponOrderDTO
 */
export interface CouponOrderDTO {
    /**
     * 
     * @type {string}
     * @memberof CouponOrderDTO
     */
    'status': CouponOrderDTOStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof CouponOrderDTO
     */
    'giftOccasion'?: CouponOrderDTOGiftOccasionEnum;
    /**
     * 
     * @type {string}
     * @memberof CouponOrderDTO
     */
    'id': string;
    /**
     * 
     * @type {CouponDefinitionDTO}
     * @memberof CouponOrderDTO
     */
    'couponDefinition': CouponDefinitionDTO;
    /**
     * 
     * @type {string}
     * @memberof CouponOrderDTO
     */
    'orderDate': string;
    /**
     * 
     * @type {string}
     * @memberof CouponOrderDTO
     */
    'issueDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof CouponOrderDTO
     */
    'expirationDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof CouponOrderDTO
     */
    'redeemDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof CouponOrderDTO
     */
    'amount': number;
    /**
     * 
     * @type {number}
     * @memberof CouponOrderDTO
     */
    'remainingAmount'?: number;
    /**
     * 
     * @type {string}
     * @memberof CouponOrderDTO
     */
    'voucherSerialNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof CouponOrderDTO
     */
    'voucherCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof CouponOrderDTO
     */
    'voucherDisplayCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof CouponOrderDTO
     */
    'voucherPin'?: string;
    /**
     * 
     * @type {string}
     * @memberof CouponOrderDTO
     */
    'voucherUrl'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CouponOrderDTO
     */
    'hasCodeBeenSeen'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CouponOrderDTO
     */
    'couponCount': number;
    /**
     * 
     * @type {number}
     * @memberof CouponOrderDTO
     */
    'orderIndex': number;
}

export const CouponOrderDTOStatusEnum = {
    PaymentDue: 'PAYMENT_DUE',
    Pending: 'PENDING',
    Ordered: 'ORDERED',
    Completed: 'COMPLETED',
    Error: 'ERROR',
    PaymentFailed: 'PAYMENT_FAILED'
} as const;

export type CouponOrderDTOStatusEnum = typeof CouponOrderDTOStatusEnum[keyof typeof CouponOrderDTOStatusEnum];
export const CouponOrderDTOGiftOccasionEnum = {
    Birthday: 'BIRTHDAY',
    SaintsDay: 'SAINTS_DAY',
    ChildBirth: 'CHILD_BIRTH',
    ChildBaptism: 'CHILD_BAPTISM',
    ChildCommunion: 'CHILD_COMMUNION',
    ChildConfirmationCath: 'CHILD_CONFIRMATION_CATH',
    ChildConfirmationProt: 'CHILD_CONFIRMATION_PROT',
    Wedding: 'WEDDING',
    WeddingSilver: 'WEDDING_SILVER',
    WeddingGold: 'WEDDING_GOLD',
    PassedExam: 'PASSED_EXAM',
    CompanyAnniversary: 'COMPANY_ANNIVERSARY',
    Achievement: 'ACHIEVEMENT',
    WorkPerformance: 'WORK_PERFORMANCE',
    Commitment: 'COMMITMENT',
    Proposal: 'PROPOSAL',
    Recruitment: 'RECRUITMENT',
    Attendance: 'ATTENDANCE',
    SigningBonus: 'SIGNING_BONUS',
    ProfitSharing: 'PROFIT_SHARING',
    Other: 'OTHER'
} as const;

export type CouponOrderDTOGiftOccasionEnum = typeof CouponOrderDTOGiftOccasionEnum[keyof typeof CouponOrderDTOGiftOccasionEnum];


