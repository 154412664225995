import React from 'react';
import CouponItem from './CouponItem';
import { CouponItemModel } from './couponItemModel';

interface Props {
  couponOrders: CouponItemModel[];
}

export const CouponItemsList: React.FC<Props> = ({ couponOrders }) => {
  return (
    <>
      {couponOrders.map(couponOrder => (
        <CouponItem
          key={couponOrder.id}
          couponOrder={couponOrder}
          urlPrefix={couponOrder.version === 'V1' ? '/coupons/order' : '/couponsV2/orders'}
        />
      ))}
    </>
  );
};
