import { Box, Divider, Paper, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { BackIconButton } from '../../component/button/BackIconButton';
import { MobileLayout } from '../../component/layout';
import { AppToolbar } from '../../component/layout/AppToolbar';
import { useBackNavigator } from '../../module/navigation/BackNavigator';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import CheckIcon from '@mui/icons-material/Check';
import { TenantLogo } from '../../component/layout/TenantLogo';
import { useLunchPeriods } from '../../module/lunch/LunchPeriodsContext';
import { useTenant } from 'probonio-shared-ui/module/me';

interface TextProps {
  children: React.ReactNode;
}

const ReceiptConditionText: React.FC<TextProps> = ({ children }) => {
  return (
    <Box marginLeft={0.3} marginRight={0.5} marginBottom={1} display="flex" alignItems={'center'} gap={0.5}>
      <ReceiptLongOutlinedIcon color="success" />
      <Typography variant="body2">{children}</Typography>
    </Box>
  );
};
const ReceiptConditions: React.FC = () => {
  const { t } = useTranslation('benefitModule');
  const { tenant } = useTenant();

  return (
    <Paper elevation={0} sx={{ paddingTop: 0.3 }}>
      <Box sx={{ ml: 0.8 }}>
        <Typography variant="descriptionBold">{t('benefit.LUNCH.infoPage.requiredHints.title')}</Typography>
        <Divider variant={'fullWidth'} sx={{ marginRight: 0.3, marginBottom: 0.3, marginTop: 0.1 }} />
        <ReceiptConditionText>{t('benefit.LUNCH.infoPage.requiredHints.local')}</ReceiptConditionText>
        <ReceiptConditionText>{t('benefit.LUNCH.infoPage.requiredHints.food')}</ReceiptConditionText>
        <ReceiptConditionText>{t('benefit.LUNCH.infoPage.requiredHints.readable')}</ReceiptConditionText>
        <ReceiptConditionText>{t('benefit.LUNCH.infoPage.requiredHints.german')}</ReceiptConditionText>
        <ReceiptConditionText>{t('benefit.LUNCH.infoPage.requiredHints.maxAmount')}</ReceiptConditionText>
        <ReceiptConditionText>
          {t('benefit.LUNCH.infoPage.requiredHints.uploadDate', { count: tenant?.lunchPeriodCloseDelay, ordinal: true })}
        </ReceiptConditionText>
      </Box>
    </Paper>
  );
};

const ReceiptAdvantagesText: React.FC<TextProps> = ({ children }) => {
  return (
    <Box marginLeft={0.3} marginRight={0.5} marginBottom={0.3} display="flex" alignItems={'center'} gap={0.5}>
      <CheckIcon color="success" />
      <Typography variant="body2">{children}</Typography>
    </Box>
  );
};

const ReceiptBenefits: React.FC = () => {
  const { t } = useTranslation('benefitModule');
  const { currentPeriod, previousPeriod } = useLunchPeriods();
  const lunchPeriod = currentPeriod || previousPeriod;
  return (
    <Paper elevation={0} sx={{ paddingTop: 0.3 }}>
      <Box sx={{ ml: 0.8 }}>
        <Typography variant="descriptionBold">{t('benefit.LUNCH.infoPage.yourLunchBenefits.title')}</Typography>
        <Divider variant={'fullWidth'} sx={{ marginRight: 0.3, marginBottom: 0.3, marginTop: 0.1 }} />
        <ReceiptAdvantagesText>
          {t('benefit.LUNCH.infoPage.yourLunchBenefits.cashback', {
            refundPerMeal: lunchPeriod?.dailyLimit || 690,
          })}
        </ReceiptAdvantagesText>
        <ReceiptAdvantagesText>
          {t('benefit.LUNCH.infoPage.yourLunchBenefits.cashbackAmount', { maxRefund: lunchPeriod?.maxRefund || 10005 })}
        </ReceiptAdvantagesText>
        <ReceiptAdvantagesText>{t('benefit.LUNCH.infoPage.yourLunchBenefits.cashbackDate')}</ReceiptAdvantagesText>
      </Box>
    </Paper>
  );
};

const LunchInfoPage: React.FC = () => {
  const backNavigator = useBackNavigator();
  const { t } = useTranslation('benefitModule');

  return (
    <MobileLayout
      header={<AppToolbar title={t('benefit.LUNCH.infoPage.title')} backButton={<BackIconButton onClick={backNavigator.back} />} />}
    >
      <Box
        display="flex"
        flexDirection="column"
        height={'auto'}
        justifyContent="space-between"
        alignItems="center"
        marginX={1}
        gap={0.8}
        marginBottom={0.7}
      >
        <Box
          borderRadius={'8px'}
          component="img"
          width={'100%'}
          sx={{
            maxWidth: 410,
          }}
          src="/image/lunch-info-picture.png"
        />
        <ReceiptConditions />
        <ReceiptBenefits />
        <TenantLogo sx={{ width: '80%', textAlign: 'center' }} />
      </Box>
    </MobileLayout>
  );
};
export default LunchInfoPage;
