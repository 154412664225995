import BackspaceIcon from '@mui/icons-material/BackspaceOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircleIcon from '@mui/icons-material/Circle';
import UndoIcon from '@mui/icons-material/Undo';
import { Box, Button, Divider, IconButton, keyframes, styled, Typography, useTheme } from '@mui/material';
import Grid from '@mui/material/Grid2';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useCurrency from './hooks';

export enum Operator {
  PLUS = '+',
  MINUS = '-',
  MULTIPLY = '*',
  DIVIDE = '/',
}

const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace', 'Enter'];

const convertNumberToFormattedString = (value: number): string => {
  return (value / 100).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
};

const buildCalculatorArray = (numbers: number[], operatorArray: Operator[]): string => {
  const resultArray: string[] = [];

  for (let i = 0; i < numbers.length; i++) {
    if (operatorArray[i - 1] === Operator.MULTIPLY || operatorArray[i - 1] === Operator.DIVIDE) {
      resultArray.push(numbers[i].toString());
    } else {
      resultArray.push(convertNumberToFormattedString(numbers[i]));
    }
    if (i < operatorArray.length) {
      resultArray.push(operatorArray[i]);
    }
  }
  return resultArray.join(' ');
};

const shakingAnimation = keyframes`
  10% {
    transform: translate3d(-6px, 0, 0);
  }
  
  20%, 80% {
    transform: translate3d(4px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-6px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(6px, 0, 0);
  }
`;

const ShakingTypography = styled(Typography, {
  shouldForwardProp: prop => prop !== 'shaking',
})<{ shaking?: boolean }>(({ shaking }) => {
  return shaking
    ? {
        animation: `${shakingAnimation} 0.60s cubic-bezier(0.4,.77,1,.43) both`,
        transform: 'translate3d(0, 0, 0)',
        perspective: '1000px',
      }
    : {};
});

function useDelayedFlag(delay: number): [boolean, () => void] {
  const [active, setActive] = useState(false);

  const activate = useCallback(() => {
    setActive(true);
    setTimeout(() => {
      setActive(false);
    }, delay);
  }, [delay]);

  return [active, activate];
}

interface KeypadButtonProps {
  children: React.ReactNode;
  onClick: () => void;
  disabled?: boolean;
  size?: number;
}

const KeypadButton: React.FC<KeypadButtonProps> = ({ children, onClick, disabled = false, size = 3 }) => {
  return (
    <Grid size={size} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
      <Button disabled={disabled} color="inherit" onClick={onClick} sx={{ paddingY: 0.3, height: '100%' }}>
        <Box fontSize={26} display="flex">
          {children}
        </Box>
      </Button>
    </Grid>
  );
};

interface KeypadNumberButtonProps {
  value: number;
  onClick: (number: number) => () => void;
  hideCalculator: boolean;
}

const KeypadNumberButton: React.FC<KeypadNumberButtonProps> = ({ value, onClick, hideCalculator }) => {
  return (
    <KeypadButton onClick={onClick(value)} size={hideCalculator ? 4 : undefined}>
      {value}
    </KeypadButton>
  );
};

interface Props {
  currentInputValue: number;
  calculatorResult: number;
  valuesArray: number[];
  operatorArray: Operator[];
  maxNumber: number;
  setCurrentInputValue: React.Dispatch<React.SetStateAction<number>>;
  onCalculatorResultChange: (newResult: number) => void;
  onCalculatorStringChange: (calculatorString: string) => void;
  onValuesArrayChange: React.Dispatch<React.SetStateAction<number[]>>;
  onOperatorArrayChange: React.Dispatch<React.SetStateAction<Operator[]>>;
  onSubmit: () => void;
  hideCalculator: boolean;
}

export const CurrencyInput: React.FC<Props> = ({
  currentInputValue,
  calculatorResult,
  maxNumber,
  valuesArray,
  operatorArray,
  hideCalculator,
  setCurrentInputValue,
  onCalculatorStringChange,
  onCalculatorResultChange,
  onValuesArrayChange,
  onOperatorArrayChange,
  onSubmit,
}) => {
  const { t } = useTranslation('common');

  const [isShaking, startShake] = useDelayedFlag(300);
  const { handleChangeTotal } = useCurrency(maxNumber, setCurrentInputValue, startShake);
  const theme = useTheme();
  const size = hideCalculator ? 9 : 12;

  /**
   * Recalculates the result based on the two arrays only.
   */
  const calculateResult = useCallback(() => {
    const tempOperators = [...operatorArray];
    const tempInputValues = [...valuesArray];
    let result: number = 0;

    function replaceCalculation(i: number): number {
      tempInputValues.splice(i + 1, 1);
      tempOperators.splice(i, 1);
      tempInputValues[i] = result;
      return result;
    }

    // ignore/pop it if the last input was an operator and calculate the result without it.
    if (tempOperators.length === tempInputValues.length) {
      tempOperators.pop();
    }

    // Calculate dot-operators first and replace the numbers and operator with the result.
    for (let i = 0; i <= tempOperators.length; i++) {
      const num1 = tempInputValues[i];
      const num2 = tempInputValues[i + 1];

      switch (tempOperators[i]) {
        case Operator.DIVIDE:
          result = num1 / num2;
          replaceCalculation(i);
          i -= 1;
          break;
        case Operator.MULTIPLY:
          result = num1 * num2;
          replaceCalculation(i);
          i -= 1;
          break;
      }
    }

    // then calculate dash-operators.
    for (let i = 0; i <= tempOperators.length; i++) {
      const num1 = tempInputValues[i];
      const num2 = tempInputValues[i + 1];

      switch (tempOperators[i]) {
        case Operator.MINUS:
          result = num1 - num2;
          replaceCalculation(i);
          i -= 1;
          break;
        case Operator.PLUS:
          result = num1 + num2;
          replaceCalculation(i);
          i -= 1;
          break;
      }
    }

    if (tempInputValues.length === 1) {
      result = tempInputValues[0];
    }
    result = Math.trunc(result);
    onCalculatorResultChange(result);
    return result;
  }, [valuesArray, operatorArray, onCalculatorResultChange]);

  /**
   * Either push the new operator or just replace the old one.
   */
  const handleInputOperator = useCallback(
    (operator: Operator) => {
      if (operatorArray.length === valuesArray.length) {
        onOperatorArrayChange([...operatorArray.slice(0, operatorArray.length - 1), operator]);
      } else {
        onOperatorArrayChange([...operatorArray, operator]);
        setCurrentInputValue(0);
      }
    },
    [onOperatorArrayChange, operatorArray, setCurrentInputValue, valuesArray.length],
  );

  const handleMinus = useCallback(() => {
    handleInputOperator(Operator.MINUS);
  }, [handleInputOperator]);

  const handlePlus = useCallback(() => {
    handleInputOperator(Operator.PLUS);
  }, [handleInputOperator]);

  const handleMultiply = useCallback(() => {
    handleInputOperator(Operator.MULTIPLY);
  }, [handleInputOperator]);

  const handleDivide = useCallback(() => {
    handleInputOperator(Operator.DIVIDE);
  }, [handleInputOperator]);

  const handleUndo = useCallback(() => {
    if (valuesArray.length === 0 && operatorArray.length === 0) {
      startShake();
      return;
    }

    // if last value in array is a number, slice it
    if (valuesArray.length > operatorArray.length) {
      onValuesArrayChange([...valuesArray.slice(0, valuesArray.length - 1)]);
    } //if last value in array is an operator, slice it and replace the input-vale with the last number-value
    else if (valuesArray.length === operatorArray.length) {
      onOperatorArrayChange([...operatorArray.slice(0, operatorArray.length - 1)]);
      setCurrentInputValue(valuesArray[valuesArray.length - 1]);
    } //if only one value and operator slice only operator (value will be put into input field)
    else if (operatorArray.length === 1) {
      onOperatorArrayChange([...operatorArray.slice(0, operatorArray.length - 1)]);
      setCurrentInputValue(valuesArray[valuesArray.length - 1]);
    }
    // if theres just one value left, clear everything
    if (valuesArray.length === 1 && operatorArray.length === 0) {
      onValuesArrayChange([]);
      onCalculatorResultChange(0);
      setCurrentInputValue(0);
    }
    calculateResult();
  }, [
    calculateResult,
    onCalculatorResultChange,
    onOperatorArrayChange,
    onValuesArrayChange,
    operatorArray,
    setCurrentInputValue,
    startShake,
    valuesArray,
  ]);

  /**
   * Just handle keyboard input
   */
  useEffect(() => {
    const onKeyDown = (event: KeyboardEvent): void => {
      event.preventDefault();
      if (allowedKeys.indexOf(event.key) < 0) {
        return;
      }

      if (event.key === 'Backspace') {
        handleChangeTotal(-1)();
        return;
      }
      if (event.key === 'Enter') {
        if (calculatorResult) {
          onSubmit();
        }
        return;
      }
      handleChangeTotal(Number.parseInt(event.key))();
    };
    window.addEventListener('keydown', onKeyDown);
    return () => {
      window.removeEventListener('keydown', onKeyDown);
    };
  }, [calculatorResult, handleChangeTotal, onSubmit]);

  /**
   * calculate the new string and result on any array change
   */
  useEffect(() => {
    const newResult = calculateResult();
    if (valuesArray.length < 1) {
      onCalculatorStringChange('');
    }

    if (valuesArray.length === 1) {
      onCalculatorStringChange(
        buildCalculatorArray(valuesArray, operatorArray).concat(' = ', convertNumberToFormattedString(valuesArray[0]), '€'),
      ); // if theres just one number show this number as result
    }
    if (valuesArray.length > 1 && operatorArray.length >= 1) {
      onCalculatorStringChange(
        buildCalculatorArray(valuesArray, operatorArray).concat(' = ', convertNumberToFormattedString(newResult), '€'),
      );
    }
  }, [calculateResult, onCalculatorStringChange, operatorArray, valuesArray]);

  /**
   * Update calculatorArray on input value change
   */
  useEffect(() => {
    onValuesArrayChange(current => {
      // Check array lengths.
      if (current.length === operatorArray.length && currentInputValue > 0) {
        // If new number push into the calculatorArray.
        return [...current, currentInputValue];
      } else if (current.length > operatorArray.length && current[current.length - 1] !== currentInputValue) {
        // If there is already a number, replace it with the new value.
        return [...current.slice(0, current.length - 1), currentInputValue];
      }
      return current;
    });
  }, [currentInputValue, onValuesArrayChange, operatorArray.length]);

  const handleSubmit = useCallback(() => {
    if (calculatorResult <= 0) {
      startShake();
      return;
    } else if (operatorArray.length === valuesArray.length) {
      startShake();
      return;
    }
    onSubmit();
  }, [calculatorResult, onSubmit, startShake, operatorArray, valuesArray]);

  return (
    <Grid container size={size} minWidth="100%">
      {!hideCalculator && (
        <Grid size={3}>
          <IconButton onClick={handleUndo}>
            <CircleIcon fontSize="extraLarge" sx={{ color: theme.palette.secondary.main }} />
            <UndoIcon fontSize="medium" sx={{ color: theme.palette.secondary.contrastText, position: 'absolute', left: 16 }} />
          </IconButton>
        </Grid>
      )}
      <Grid size={6} margin="auto">
        <ShakingTypography variant="extraBig" shaking={isShaking} display="block" data-test-id="calculator-amount">
          {operatorArray[operatorArray.length - 1] === Operator.MULTIPLY || operatorArray[operatorArray.length - 1] === Operator.DIVIDE
            ? currentInputValue
            : t('money', { money: currentInputValue, cents: currentInputValue })}
        </ShakingTypography>
      </Grid>
      {!hideCalculator && <Grid size={3} />}
      <Divider variant={'middle'} sx={{ width: '90%', marginLeft: '5%', marginRight: '5%' }} />

      {!hideCalculator && (
        <KeypadButton onClick={handleDivide} disabled={calculatorResult === 0 && currentInputValue === 0}>
          ÷
        </KeypadButton>
      )}

      <KeypadNumberButton hideCalculator={hideCalculator} value={1} onClick={handleChangeTotal} />
      <KeypadNumberButton hideCalculator={hideCalculator} value={2} onClick={handleChangeTotal} />
      <KeypadNumberButton hideCalculator={hideCalculator} value={3} onClick={handleChangeTotal} />

      {!hideCalculator && (
        <KeypadButton onClick={handleMultiply} disabled={calculatorResult === 0 && currentInputValue === 0}>
          ×
        </KeypadButton>
      )}

      <KeypadNumberButton hideCalculator={hideCalculator} value={4} onClick={handleChangeTotal} />
      <KeypadNumberButton hideCalculator={hideCalculator} value={5} onClick={handleChangeTotal} />
      <KeypadNumberButton hideCalculator={hideCalculator} value={6} onClick={handleChangeTotal} />

      {!hideCalculator && (
        <KeypadButton onClick={handleMinus} disabled={calculatorResult === 0 && currentInputValue === 0}>
          −
        </KeypadButton>
      )}

      <KeypadNumberButton hideCalculator={hideCalculator} value={7} onClick={handleChangeTotal} />
      <KeypadNumberButton hideCalculator={hideCalculator} value={8} onClick={handleChangeTotal} />
      <KeypadNumberButton hideCalculator={hideCalculator} value={9} onClick={handleChangeTotal} />

      {!hideCalculator && (
        <KeypadButton onClick={handlePlus} disabled={calculatorResult === 0 && currentInputValue === 0}>
          +
        </KeypadButton>
      )}

      <KeypadButton onClick={handleChangeTotal(-1)} size={hideCalculator ? 4 : undefined}>
        <BackspaceIcon fontSize="large" />
      </KeypadButton>
      <KeypadNumberButton hideCalculator={hideCalculator} value={0} onClick={handleChangeTotal} />

      <KeypadButton onClick={handleSubmit} size={hideCalculator ? 4 : undefined}>
        <CheckCircleIcon fontSize="large" sx={{ color: theme.palette.primary.main }} />
      </KeypadButton>
    </Grid>
  );
};
