import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import KeyboardIcon from '@mui/icons-material/Keyboard';
import { Box } from '@mui/material';
import { DateTime } from 'luxon';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { CalculatorFormField } from '../../component/currencyInput/CalculatorFormField';
import { useCalculatorState } from '../../component/currencyInput/useCalculatorState';
import { useNewDialogState } from '../../component/dialog/BasicDialog';
import { LoadingLayout } from '../../component/layout/LoadingLayout';
import { FullscreenStep } from '../../component/stepper/FullscreenStep';
import { ImageZoomerStep } from '../../component/stepper/ImageZoomerStep';
import { SimpleStepper } from '../../component/stepper/SimpleStepper';
import { useStepperState } from '../../component/stepper/useStepperState';
import { DiscardFormConfirmation } from '../../module/benefit/addReceipt/DiscardFormConfirmation';
import { PeriodDatePicker } from '../../module/benefit/datePicker/PeriodDatePicker';
import { MobilitySummary } from '../../module/mobility/addReceipt/MobilitySummary';
import { MobilityTypeForm } from '../../module/mobility/addReceipt/MobilityTypeForm';
import { mapCreateMobilityReceipt, useCreateMobilityReceipt } from '../../module/mobility/addReceipt/useCreateMobilityReceipt';
import { useMobilityTypeState } from '../../module/mobility/addReceipt/useMobilityTypeState';
import { useMobilityPeriods } from '../../module/mobility/MobilityPeriodsContext';
import { ImageSelectionState } from '../../module/shareTarget/useImageSelection';
import { useAvailableMobilityTypes } from '../../module/mobility/addReceipt/availableMobilityTypes';
import { CreateMobilityReceiptDTOMobilityTypeEnum } from '../../../../probonio-shared-ui/api';

interface Props {
  imageSelection: ImageSelectionState;
}

export const AddMobilityReceiptPage: React.FC<Props> = ({ imageSelection }) => {
  const { t } = useTranslation('benefitModule');
  const { refetchMobility, previousPeriod, currentPeriod } = useMobilityPeriods();
  const cancelConfirmation = useNewDialogState();
  const { activeStep, handleNext, handlePrevious } = useStepperState(cancelConfirmation.handleOpen);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const mobilityTypeState = useMobilityTypeState();
  const calculatorState = useCalculatorState();
  const [receiptDate, setReceiptDate] = useState<Date>();
  const [validityStartDate, setValidityStartDate] = useState<Date>(DateTime.now().startOf('month').toJSDate());
  const [comment, setComment] = useState<string>();
  const mobilityTypes = useAvailableMobilityTypes(currentPeriod, mobilityTypeState.travelPurpose, mobilityTypeState.validityMonths);

  // prefill value for Deutschland Ticket
  useEffect(() => {
    if (mobilityTypeState.mobilityType === CreateMobilityReceiptDTOMobilityTypeEnum.DeutschlandTicket) {
      if (!calculatorState.calculatorResult) {
        calculatorState.reset(4900);
      }
      if (!receiptDate) {
        setReceiptDate(DateTime.now().startOf('month').toJSDate());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mobilityTypeState.mobilityType]);

  const handleSubmitDate = useCallback(
    (value: Date) => {
      if (mobilityTypeState.isTimeCard) {
        setReceiptDate(new Date());
        setValidityStartDate(value);
      } else {
        setReceiptDate(value);
      }
      handleNext();
    },
    [handleNext, mobilityTypeState.isTimeCard],
  );

  const handleClose = useCallback((): void => {
    navigate(-1);
  }, [navigate]);

  const mutation = useCreateMobilityReceipt(imageSelection, () => {
    enqueueSnackbar(t(`benefit.MOBILITY.addReceiptFlow.createReceiptSuccess`), { variant: 'success' });
    refetchMobility();
    handleClose();
  });

  const handleConfirm = useCallback(() => {
    const mappedRequest = mapCreateMobilityReceipt(mobilityTypeState, calculatorState, receiptDate!, validityStartDate, comment);
    mutation.mutate(mappedRequest);
  }, [calculatorState, comment, mobilityTypeState, mutation, receiptDate, validityStartDate]);

  if (!currentPeriod || !imageSelection.selectedImage) {
    return <LoadingLayout />;
  }

  const datePickerLabelKey =
    (mobilityTypeState.mobilityType && mobilityTypes[mobilityTypeState.mobilityType]?.dateHintKey) || 'benefit.MOBILITY.dateHint';

  return (
    <>
      <DiscardFormConfirmation dialogState={cancelConfirmation.dialogState} onSubmit={handleClose} />
      <SimpleStepper activeStep={activeStep}>
        <FullscreenStep title="Ticket einreichen" onPrevious={handlePrevious} onCancel={cancelConfirmation.handleOpen}>
          <MobilityTypeForm currentPeriod={currentPeriod} state={mobilityTypeState} onPrevious={handlePrevious} onSubmit={handleNext} />
        </FullscreenStep>
        <ImageZoomerStep
          icon={<KeyboardIcon />}
          image={imageSelection.selectedImage}
          onPrevious={handlePrevious}
          onCancel={cancelConfirmation.handleOpen}
        >
          <CalculatorFormField
            state={calculatorState}
            placeholder={t(`benefit.MOBILITY.addReceiptFlow.hintTotal`)}
            hideCalculator
            onSubmit={handleNext}
          />
        </ImageZoomerStep>
        <ImageZoomerStep
          icon={<CalendarMonthIcon />}
          image={imageSelection.selectedImage}
          onPrevious={handlePrevious}
          onCancel={cancelConfirmation.handleOpen}
        >
          <Box marginBottom={0.75}>
            <PeriodDatePicker
              label={t(datePickerLabelKey)}
              numberOfMonths={mobilityTypeState.isTimeCard ? mobilityTypeState.validityMonths! - 1 : undefined}
              showLastMonth={previousPeriod && !previousPeriod.isLocked}
              month={currentPeriod.month}
              value={mobilityTypeState.isTimeCard ? validityStartDate : receiptDate}
              onConfirm={handleSubmitDate}
              onCancel={handlePrevious}
            />
          </Box>
        </ImageZoomerStep>
        <FullscreenStep
          title={t('benefit.MOBILITY.addReceiptFlow.addReceiptSummary')}
          onPrevious={handlePrevious}
          onCancel={cancelConfirmation.handleOpen}
        >
          <MobilitySummary
            mobilityTypeState={mobilityTypeState}
            calculatorState={calculatorState}
            receiptDate={receiptDate || new Date()}
            validityStartDate={validityStartDate}
            loading={mutation.isPending}
            comment={comment}
            onChangeComment={setComment}
            onSubmit={handleConfirm}
          />
        </FullscreenStep>
      </SimpleStepper>
    </>
  );
};
