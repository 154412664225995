import * as Sentry from '@sentry/react';
import { captureException } from '@sentry/react';
import { AxiosError } from 'axios';
import React from 'react';
import { createRoutesFromChildren, matchRoutes, Routes, useLocation, useNavigationType } from 'react-router-dom';

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  environment: import.meta.env.VITE_SENTRY_ENVIRONMENT || 'dev',
  dist: `${import.meta.env.VITE_SENTRY_ENVIRONMENT || 'dev'}-${import.meta.env.VITE_SENTRY_DIST || 'web'}`,
  integrations: [
    new Sentry.BrowserTracing({
      tracePropagationTargets: [import.meta.env.VITE_APP_API_URL.replace(/^https?:\/\//, '')],
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      ),
    }),
    new Sentry.Replay({
      // Additional SDK configuration goes in here, for example:
      maskAllText: true,
      blockAllMedia: true,
    }),
  ],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: import.meta.env.VITE_SENTRY_TRACE_RATE ? Number(import.meta.env.VITE_SENTRY_TRACE_RATE) : 0.1,
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 1.0,
  normalizeDepth: 6,
});

export const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

export function captureMessageError(error: Error): void {
  const contexts: Record<string, Record<string, unknown>> = {};
  if (error instanceof AxiosError) {
    contexts.axios = {
      method: error.config?.method || 'get',
      url: error.config?.url,
      data: error.config?.data,
      response: error.response?.data,
      status: error.response?.status,
    };
  }
  captureException(error, { contexts });
}
