import { useCallback, useMemo, useState } from 'react';

export interface StepperState {
  activeStep: number;
  handleNext: () => void;
  handlePrevious: () => void;
}

export function useStepperState(onClose?: () => void): StepperState {
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = useCallback(() => {
    setActiveStep(step => Math.min(step + 1, 3));
  }, []);

  const handlePrevious = useCallback(() => {
    if (activeStep <= 0) {
      onClose?.();
    } else {
      setActiveStep(activeStep - 1);
    }
  }, [activeStep, onClose]);

  return useMemo(
    () => ({
      activeStep,
      handleNext,
      handlePrevious,
    }),
    [activeStep, handleNext, handlePrevious],
  );
}
