import VisibilityIcon from '@mui/icons-material/Visibility';
import { Box, Button, Typography, useTheme } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { DateTime } from 'luxon';
import { CouponsV2UserOrderDTO, CouponsV2UserOrderDTOStatusEnum } from 'probonio-shared-ui/api';
import { apis } from 'probonio-shared-ui/module/api';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../redux/hooks';
import { getCouponImageUrl } from '../couponsShared/checkCouponImageUrl';
import { CouponCard } from '../couponsShared/components/CouponCard';
import { CouponUsageText } from '../couponsShared/components/CouponUsageText';
import { useOpenVoucherInBrowser } from '../couponsShared/orderDetails/useOpenVoucherInBrowser';
import { CouponRedeemButton } from '../couponsShared/redeem/CouponRedeemButton';
import { useRedeemCouponV2Mutation } from './couponsV2Queries';

interface Props {
  couponOrder: CouponsV2UserOrderDTO;
}

export const CouponsV2OrderDetail: React.FC<Props> = ({ couponOrder }) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation('couponsModule');
  const theme = useTheme();
  const couponDefinition = couponOrder.couponDefinition;
  const { url: couponImageUrl } = getCouponImageUrl(couponOrder.couponDefinition);
  const hasRemainingAmount = !!couponOrder.remainingAmount && couponOrder.amount !== couponOrder.remainingAmount;
  const isCouponPending =
    couponOrder.status === CouponsV2UserOrderDTOStatusEnum.Pending || couponOrder.status === CouponsV2UserOrderDTOStatusEnum.Ordered;
  const orderDate = DateTime.fromISO(couponOrder.orderDate).toJSDate();
  const currentUser = useAppSelector(state => state.me.user);
  const redeemCallback = useRedeemCouponV2Mutation(couponOrder);
  const { shouldOpenInBrowser, handleOpenBrowser, renderedRedeemModal } = useOpenVoucherInBrowser({
    voucher: couponOrder.voucher,
    redeemDate: couponOrder.redeemDate,
    onRedeem: redeemCallback,
  });

  const mutation = useMutation({
    mutationFn: async () => {
      if (couponOrder.id && !couponOrder.hasCodeBeenSeen) {
        return apis.couponsV2.setCouponCodeToHasBeenSeen({
          userId: currentUser!.id,
          userOrderId: couponOrder.id,
        });
      }
    },
  });

  const navigateToShowVoucherPage = useCallback(async () => {
    if (!couponOrder.hasCodeBeenSeen) {
      mutation.mutate();
    }

    if (shouldOpenInBrowser) {
      await handleOpenBrowser();
    } else {
      navigate(`/couponsV2/orders/${couponOrder.id}/showVoucher`);
    }
  }, [couponOrder.hasCodeBeenSeen, couponOrder.id, handleOpenBrowser, mutation, navigate, shouldOpenInBrowser]);

  return (
    <>
      <CouponCard
        bottomPanel={
          couponOrder.redeemDate && (
            <Box sx={{ textAlign: 'center' }}>
              <Typography variant="caption">{t('couponOrderDetails.redeemDate')}</Typography>
              <Typography variant="body1" color="text.secondary">
                {i18n.format(new Date(couponOrder.redeemDate), 'date')}
              </Typography>
            </Box>
          )
        }
      >
        <Box display="flex" sx={{ marginBottom: 0.75, alignItems: 'center', justifyContent: 'center' }} height={120}>
          <img
            src={couponImageUrl}
            style={{ borderRadius: 8, maxHeight: 120, maxWidth: 200 }}
            alt={t('couponOrderDetails.logoAlt', { name: couponDefinition.name })}
          />
        </Box>
        <Typography variant="h1" textAlign="center" sx={{ marginBottom: 1 }}>
          {couponDefinition.name}
        </Typography>
        {hasRemainingAmount ? (
          <Box sx={{ marginBottom: 1.25 }} textAlign="center">
            <Typography component="span" variant="extraBig">
              {t('common:money', { money: couponOrder.remainingAmount })}
            </Typography>
            {' / '}
            <Typography component="span" variant="body1" color="text.secondary">
              {t('common:money', { money: couponOrder.amount })}
            </Typography>
          </Box>
        ) : (
          <Typography component="h2" variant="extraBig" textAlign="center" sx={{ marginBottom: 1.25 }}>
            {t('common:money', { money: couponOrder.amount })}
          </Typography>
        )}
        <CouponUsageText usageText={couponDefinition.redemptionDescription} theme={theme} />
        {!isCouponPending && (
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={navigateToShowVoucherPage}
            data-test-id="coupon-display-voucher-button"
          >
            <VisibilityIcon sx={{ marginRight: '6px' }} />
            {t('couponOrderDetails.displayVoucher')}
          </Button>
        )}
        {isCouponPending && (
          <Box textAlign="center" display="flex" flexDirection="column" justifyContent="space-between" rowGap={2} py={1}>
            <Typography variant="caption" fontStyle="italic" color={theme.palette.primary.main}>
              {t('couponOrderDetails.pendingMessage')}
            </Typography>
            <Box>
              <Typography fontWeight={700} color={theme.palette.text.secondary}>
                {t('couponOrderDetails.orderDate')}
              </Typography>
              <Typography color={theme.palette.text.secondary}>{i18n.format(orderDate, 'date')}</Typography>
            </Box>
          </Box>
        )}
      </CouponCard>
      {!isCouponPending && <CouponRedeemButton couponOrder={couponOrder} onUpdate={redeemCallback} />}
      {renderedRedeemModal}
      <Box />
    </>
  );
};
