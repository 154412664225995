import SellIcon from '@mui/icons-material/Sell';
import { useCallback } from 'react';
import { useCouponsV2AvailableUserTokenListQuery, useInvalidateCouponsV2UserOrders } from '../../couponsV2/couponsV2Queries';
import PullToRefresh from 'react-simple-pull-to-refresh';
import { FullWidthCenterBox } from '../../../component/layout/BoxLayout';
import { Box, CircularProgress, Typography, useTheme } from '@mui/material';
import { CouponItemsList } from './CouponItemsList';
import { Trans, useTranslation } from 'react-i18next';
import { useInvalidateCouponOrders } from '../../coupons/couponQueries';
import { CouponsV2UserTokenItem } from '../../couponsV2/userTokens/CouponsV2UserTokenItem';
import { useCombinedCouponOrderListQuery } from '../couponsSharedQueries';

interface Props {
  activeVersion: 'V1' | 'V2';
}

export const CouponOrderList: React.FC<Props> = ({ activeVersion }) => {
  const { t } = useTranslation('common');
  const theme = useTheme();

  const { data: couponOrderList, isLoading: isCouponsLoading } = useCombinedCouponOrderListQuery();
  const { data: userTokenList, isLoading: isV2TokensLoading } = useCouponsV2AvailableUserTokenListQuery({
    enabled: activeVersion === 'V1',
  });
  const invalidateCouponOrders = useInvalidateCouponOrders();
  const invalidateUserOrders = useInvalidateCouponsV2UserOrders();

  const handleRefresh = useCallback(async () => {
    if (activeVersion === 'V1') {
      await invalidateCouponOrders();
    } else {
      await invalidateUserOrders();
    }
  }, [activeVersion, invalidateCouponOrders, invalidateUserOrders]);

  const isLoading = isCouponsLoading || isV2TokensLoading;

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', height: '70%', justifyContent: 'center', alignItems: 'center' }}>
        <CircularProgress />
      </Box>
    );
  }

  return couponOrderList.length || userTokenList?.results.length ? (
    <PullToRefresh
      onRefresh={handleRefresh}
      pullingContent={
        <FullWidthCenterBox>
          <Typography variant="h6">{t('common:pullToRefresh')}</Typography>
        </FullWidthCenterBox>
      }
    >
      <Box sx={{ padding: 1 }}>
        {userTokenList?.results.map(userToken => <CouponsV2UserTokenItem key={userToken.id} userToken={userToken} />)}
        <CouponItemsList couponOrders={couponOrderList} />
      </Box>
    </PullToRefresh>
  ) : (
    <Typography variant="h2" fontWeight={400} textAlign="center" mt={6} px={1}>
      <Trans i18nKey="benefitModule:benefit.COUPONS.noReceipt">
        <SellIcon
          sx={{
            color: theme.palette.primary.contrastText,
            verticalAlign: 'middle',
            backgroundColor: theme.palette.primary.main,
            borderRadius: '50%',
            padding: 0.2,
          }}
        />
      </Trans>
    </Typography>
  );
};
