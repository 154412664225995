import EventBusyIcon from '@mui/icons-material/EventBusy';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LanguageIcon from '@mui/icons-material/Language';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Accordion, AccordionDetails, AccordionSummary, Box, SvgIconProps } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { MissingCriteria } from './MissingCriteriaModal';

interface ElementProps {
  missingCriteria: MissingCriteria;
  defaultExpanded: boolean;
}
const MissingCriteriaElement: React.FC<ElementProps> = ({ missingCriteria, defaultExpanded }) => {
  const { t } = useTranslation('benefitModule');

  const iconProps: SvgIconProps = { color: 'primary', fontSize: 'large', sx: { mr: 0.5 } };

  let icon: JSX.Element;
  let title: string;
  let description: string;

  switch (missingCriteria) {
    case MissingCriteria.DATE: {
      icon = <EventBusyIcon {...iconProps} />;
      title = t('benefit.LUNCH.warningModal.missingDateTitle');
      description = t('benefit.LUNCH.warningModal.missingDateDescription');
      break;
    }
    case MissingCriteria.TOTAL: {
      icon = <WarningAmberIcon {...iconProps} />;
      title = t('benefit.LUNCH.warningModal.missingTotalTitle');
      description = t('benefit.LUNCH.warningModal.missingTotalDescription');
      break;
    }

    case MissingCriteria.COUNTRY: {
      icon = <LanguageIcon {...iconProps} />;
      title = t('benefit.LUNCH.warningModal.isNotGermanTitle');
      description = t('benefit.LUNCH.warningModal.isNotGermanDescription');
      break;
    }

    default: {
      icon = <WarningAmberIcon {...iconProps} />;
      title = t('benefit.LUNCH.warningModal.unknownTitle');
      description = t('benefit.LUNCH.warningModal.unknownDescription');
    }
  }
  return (
    <Accordion data-test-id={`missing-criteria-${missingCriteria}`} defaultExpanded={defaultExpanded}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {icon}
          {title}
        </Box>
      </AccordionSummary>
      <AccordionDetails>{description}</AccordionDetails>
    </Accordion>
  );
};

interface Props {
  missingCriterias: MissingCriteria[];
}
export const MissingCriteriaElements: React.FC<Props> = ({ missingCriterias }) => {
  return missingCriterias.map(missingCriteria => (
    <MissingCriteriaElement key={missingCriteria} missingCriteria={missingCriteria} defaultExpanded={missingCriterias.length === 1} />
  ));
};
