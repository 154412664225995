import { AvailableGiftDTO } from 'probonio-shared-ui/api';
import { useTranslation } from 'react-i18next';
import { useNewDialogState } from '../../../component/dialog/BasicDialog';
import GiftModal from './GiftModal';
import { GiftNavButton } from './GiftNavButton';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

interface Props {
  gift: AvailableGiftDTO;
}

export const GiftButton: React.FC<Props> = ({ gift }) => {
  const { dialogState, handleOpen: handleOpenDialog } = useNewDialogState();
  const { t } = useTranslation('benefitModule');
  const navigate = useNavigate();

  const handleConfirm = useCallback(() => {
    navigate('/coupons/explore-coupons', { state: gift });
  }, [gift, navigate]);

  return (
    <>
      <GiftNavButton onClick={handleOpenDialog} />
      <GiftModal
        gift={gift}
        dialogState={dialogState}
        infoMessage={t('benefit.GIFTS.giftsModal.infoMessage', { amount: gift.amount })}
        onConfirm={handleConfirm}
      />
    </>
  );
};
