import { Box, CircularProgress, useTheme } from '@mui/material';
import { BenefitDTOBenefitEnum } from 'probonio-shared-ui/api';
import React from 'react';
import { TaxProgressMoney } from '../benefit/progressAmount/TaxProgressMoney';
import { useMobilityPeriods } from './MobilityPeriodsContext';

export const TicketAmount: React.FC = () => {
  const theme = useTheme();

  const {
    currentPeriod,
    mobilityPeriodsQuery: { isLoading, isSuccess },
  } = useMobilityPeriods();

  return (
    <Box sx={{ textAlign: 'center', paddingBottom: 0.5, paddingTop: 0.2, backgroundColor: theme.palette.primary.contrastText }}>
      {isLoading && <CircularProgress />}
      {isSuccess && (
        <TaxProgressMoney
          benefit={BenefitDTOBenefitEnum.Mobility}
          taxFreeRefund={currentPeriod?.refundTaxFree}
          taxedRefund={currentPeriod?.refundTaxable}
          maxRefund={currentPeriod?.maxRefund}
        />
      )}
    </Box>
  );
};
