import FullscreenIcon from '@mui/icons-material/Fullscreen';
import { Box, Card, CircularProgress, IconButton, styled, useTheme } from '@mui/material';
import {
  LunchReceiptRefundDTOStatusEnum,
  LunchReceiptRefundDTO,
  BenefitDTOBenefitEnum,
  MobilityReceiptRefundDTO,
  RecreationReceiptRefundDTO,
} from 'probonio-shared-ui/api';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FullScreenPicture from '../../../component/dialog/FullScreenPicture';
import { FullWidthCenterBox, VerticalBox } from '../../../component/layout/BoxLayout';
import { ReceiptInfoCard, ReceiptStatusAlert } from '../../lunch';

interface ReceiptValues {
  id: string;
  status: LunchReceiptRefundDTOStatusEnum;
  comment?: string;
  date: string | Date;
  total: number;
  userTotal?: number;
  refund?: number;
  refundMetadata?:
    | LunchReceiptRefundDTO['refundMetadata']
    | MobilityReceiptRefundDTO['refundMetadata']
    | RecreationReceiptRefundDTO['refundMetadata'];
  rejectionReason?: string;
  isAuditedByTenant?: boolean;
  lunchReceiptType?: LunchReceiptRefundDTO['receiptType'];
  mobilityType?: MobilityReceiptRefundDTO['mobilityType'];
  mobilityTravelPurpose?: MobilityReceiptRefundDTO['travelPurpose'];
  mobilityValidityStartDate?: MobilityReceiptRefundDTO['validityStartDate'];
  mobilityValidityEndDate?: MobilityReceiptRefundDTO['validityEndDate'];
}

interface Props {
  receipt: ReceiptValues;
  imageURL: string;
  thumbnailURL?: string;
  benefit: BenefitDTOBenefitEnum;
}

const BaseCard = styled(Card)(({ theme }) => ({
  marginBottom: theme.spacing(1),
}));

export const ReceiptDetail: React.FC<Props> = ({ receipt, imageURL, thumbnailURL, benefit }) => {
  const { t } = useTranslation('benefitModule');
  const [isFullscreen, setIsFullscreen] = useState<boolean>(false);

  const theme = useTheme();

  // If the page is reloaded directly (or a wrong period is loaded), select the current period.

  const handleToggleFullscreen = useCallback(() => {
    setIsFullscreen(prevState => !prevState);
  }, [setIsFullscreen]);

  const smallImageURL = thumbnailURL || imageURL;

  return (
    <>
      {receipt && (
        <>
          <Box paddingX={1}>
            <VerticalBox>
              <Box marginBottom={1}>
                <ReceiptStatusAlert
                  receiptStatus={receipt.status}
                  rejectionReason={receipt.rejectionReason}
                  isModifiedByAudit={!!receipt.userTotal && receipt.total !== receipt.userTotal}
                  isAuditedByTenant={receipt.isAuditedByTenant}
                />
              </Box>
              <BaseCard elevation={0} sx={{ position: 'relative', height: theme.spacing(13.5), display: 'flex' }}>
                {!!smallImageURL && (
                  <>
                    <img
                      alt={t('benefit.LUNCH.receiptPicture')}
                      src={smallImageURL}
                      onClick={handleToggleFullscreen}
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        display: 'block',
                      }}
                    />
                    <IconButton
                      onClick={handleToggleFullscreen}
                      color="secondary"
                      sx={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        padding: 1,
                      }}
                    >
                      <FullscreenIcon fontSize="extraLarge" />
                    </IconButton>
                  </>
                )}
                {!smallImageURL && (
                  <FullWidthCenterBox sx={{ flexGrow: 1 }}>
                    <VerticalBox sx={{ justifyContent: 'center' }}>
                      <CircularProgress />
                    </VerticalBox>
                  </FullWidthCenterBox>
                )}
              </BaseCard>
              <ReceiptInfoCard receipt={receipt} benefit={benefit} includeComment />
            </VerticalBox>
          </Box>
          {imageURL && <FullScreenPicture isOpen={isFullscreen} imageURL={imageURL} onClose={handleToggleFullscreen} />}
        </>
      )}
    </>
  );
};
