import { BikeleasingBenefitDetailsDTO, BikeleasingEmployeeInformationDTO } from 'probonio-shared-ui/api';
import { useActiveEmployment } from 'probonio-shared-ui/module/me';

export function useBikeleasingEmployeeInformation(
  details: BikeleasingBenefitDetailsDTO | undefined,
): BikeleasingEmployeeInformationDTO | undefined {
  const getActiveEmployment = useActiveEmployment();
  const employeeId = getActiveEmployment().id;

  return details?.bikeleasingEmployeeInformation.find(information => information.employeeId === employeeId);
}
