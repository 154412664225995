import React from 'react';
import { Box, InputLabel } from '@mui/material';
import { Control } from 'react-hook-form';
import { TextFieldControl } from '../../component/form/TextFieldControl';
import { useTranslation } from 'react-i18next';

interface MeFields {
  firstName: string;
  lastName: string;
  title: string;
  salutation: string;
}

interface Props {
  control: Control<MeFields>;
  onSubmit: () => void;
}

// Require at least one unicode letter
const pattern = /\p{L}+/u;

const EditMeForm: React.FC<Props> = ({ onSubmit, control }) => {
  const { t } = useTranslation('profilePage');
  return (
    <form onSubmit={onSubmit}>
      <Box sx={{ marginBottom: 1 }}>
        <InputLabel variant="standard" htmlFor="title">
          {t('salutation')}
        </InputLabel>
        <TextFieldControl control={control} rules={{ pattern }} name="salutation" fullWidth variant="outlined" margin="dense" />
      </Box>
      <Box sx={{ marginBottom: 1 }}>
        <InputLabel variant="standard" htmlFor="title">
          {t('title')}
        </InputLabel>
        <TextFieldControl control={control} rules={{ pattern }} name="title" fullWidth variant="outlined" margin="dense" />
      </Box>
      <Box sx={{ marginBottom: 1 }}>
        <InputLabel variant="standard" htmlFor="firstName">
          {t('firstName')}
        </InputLabel>
        <TextFieldControl
          control={control}
          rules={{ required: true, pattern }}
          name="firstName"
          fullWidth
          variant="outlined"
          margin="dense"
        />
      </Box>
      <Box sx={{ marginBottom: 1 }}>
        <InputLabel variant="standard" htmlFor="lastName">
          {t('lastName')}
        </InputLabel>
        <TextFieldControl
          control={control}
          rules={{ required: true, pattern }}
          name="lastName"
          fullWidth
          variant="outlined"
          margin="dense"
        />
      </Box>
    </form>
  );
};

export default EditMeForm;
