import { Box } from '@mui/material';
import React from 'react';
import { BackIconButton } from '../button/BackIconButton';
import { CloseIconButton } from '../button/CloseIconButton';
import { MobileLayout } from '../layout';
import { AppToolbar } from '../layout/AppToolbar';

interface Props {
  title?: string;
  transparent?: boolean;
  onPrevious: () => void;
  onCancel: () => void;
}

export const FullscreenStep: React.FC<React.PropsWithChildren<Props>> = ({ title, transparent, onPrevious, onCancel, children }) => {
  return (
    <MobileLayout
      paperBackground
      header={
        <AppToolbar
          absolute={transparent}
          invert={transparent}
          title={title}
          backButton={<BackIconButton onClick={onPrevious} color="inherit" />}
          actionButton={<CloseIconButton onClick={onCancel} color="inherit" />}
        />
      }
    >
      {transparent ? (
        children
      ) : (
        <Box sx={{ px: 1, pt: 1, pb: 1.75, flexGrow: 1, display: 'flex', flexDirection: 'column' }}>{children}</Box>
      )}
    </MobileLayout>
  );
};
