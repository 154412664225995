import React, { useCallback } from 'react';

interface UseCurrencyReturn {
  handleChangeTotal: (number: number) => () => void;
}

const useCurrency = (
  maxNumber: number,
  setCurrentTotal: React.Dispatch<React.SetStateAction<number>>,
  onError: () => void,
): UseCurrencyReturn => {
  const addDigit = useCallback(
    (number: number): void => {
      setCurrentTotal(current => {
        const newValue = current * 10 + number;
        if (newValue > maxNumber) {
          onError();
          return current;
        }
        return newValue;
      });
    },
    [maxNumber, onError, setCurrentTotal],
  );

  const removeDigit = useCallback((): void => {
    // subtract
    setCurrentTotal(current => {
      if (current <= 0) {
        onError();
        return current;
      }
      const newValue = Math.trunc(current / 10);
      return newValue;
    });
  }, [onError, setCurrentTotal]);

  const handleChangeTotal = useCallback(
    (number: number) => {
      return () => {
        const isAddition = number >= 0;

        if (isAddition) {
          addDigit(number);
          return;
        }
        removeDigit();
      };
    },
    [addDigit, removeDigit],
  );

  return {
    handleChangeTotal,
  };
};

export default useCurrency;
