import { Box, Button, Card, SxProps, Theme, useTheme } from '@mui/material';
import { BenefitDTOBenefitEnum } from 'probonio-shared-ui/api';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonRendererProps, CameraButton } from '../camera';
import { ImageFile } from '../camera/ImageFile';
import { LUNCH_MAX_IMAGE_SIZE } from '../../module/lunch/lunchConstants';

interface Props {
  benefit: BenefitDTOBenefitEnum;
  sx?: SxProps<Theme>;
  disabled?: boolean;
  value?: string | ImageFile;
  onChange: (value: ImageFile, pdf?: Blob) => void;
}

const EditImageButton: React.FC<ButtonRendererProps> = props => {
  const { t } = useTranslation('benefitModule');
  return (
    <Button variant="outlined" color="secondary" {...props}>
      {t('benefit.LUNCH.selectPicture')}
    </Button>
  );
};

export const SelectImageField: React.FC<Props> = ({ sx, disabled, value, benefit, onChange }) => {
  const { t } = useTranslation('benefitModule');
  const theme = useTheme();
  const [image, setImage] = useState<string>();

  useEffect(() => {
    const loadImageUri = async (): Promise<void> => {
      const uri = value instanceof ImageFile ? await value.getURI() : value;
      setImage(uri);
    };
    void loadImageUri();
  }, [value]);

  return (
    <Card elevation={0} sx={{ ...sx, position: 'relative' }}>
      {image && (
        <img
          alt={t('benefit.LUNCH.receiptPicture')}
          src={image}
          style={{ height: '100%', width: '100%', objectFit: 'cover', display: 'block' }}
        />
      )}
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          bottom: 0,
          right: 0,
          left: 0,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: theme.palette.alphaBackground,
        }}
      >
        {!disabled && (
          <CameraButton
            data-test-id="select-new-image"
            buttonRenderer={EditImageButton}
            maxSize={LUNCH_MAX_IMAGE_SIZE}
            onSelect={onChange}
            isPdfAllowed={benefit === BenefitDTOBenefitEnum.Mobility || benefit === BenefitDTOBenefitEnum.Lunch}
          />
        )}
      </Box>
    </Card>
  );
};
