import DescriptionIcon from '@mui/icons-material/Description';
import { Box, Typography } from '@mui/material';
import { InternetReceiptRefundDTO } from 'probonio-shared-ui/api';
import { LoadingButton } from 'probonio-shared-ui/component/button';
import React, { useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useCreateInternetReceiptMutation, useDeleteReceiptMutation, useDownloadInternetStatementMutation } from './internetQueries';

interface Props {
  receiptInProgress?: InternetReceiptRefundDTO;
  total: number;
  onSubmit: () => void;
}

export const InternetConfirmationForm: React.FC<Props> = ({ receiptInProgress, total, onSubmit }) => {
  const { t } = useTranslation('internetModule');

  const mutation = useCreateInternetReceiptMutation();
  const downloadMutation = useDownloadInternetStatementMutation();
  const deleteMutation = useDeleteReceiptMutation();

  const handleDownload = useCallback(() => {
    mutation.mutate(total, {
      onSuccess: receiptId => {
        downloadMutation.mutate(receiptId, { onSuccess: onSubmit });
      },
    });
  }, [downloadMutation, mutation, onSubmit, total]);
  const handleDeleteAndDownload = useCallback(() => {
    if (receiptInProgress) {
      deleteMutation.mutate(receiptInProgress.id, { onSuccess: handleDownload });
    } else {
      handleDownload();
    }
  }, [deleteMutation, handleDownload, receiptInProgress]);

  return (
    <Box px={1}>
      <Typography variant="h3" mb={1}>
        {t('newReceipt.confirmation')}
      </Typography>
      <Typography whiteSpace="pre-line" mb={1}>
        <Trans i18nKey="internetModule:newReceipt.confirmationRequired" values={{ total }} />
      </Typography>
      <LoadingButton
        fullWidth
        variant="contained"
        loading={mutation.isPending || downloadMutation.isPending || deleteMutation.isPending}
        onClick={handleDeleteAndDownload}
        startIcon={<DescriptionIcon />}
        data-test-id="download-statement-button"
      >
        {t('newReceipt.downloadConfirmation')}
      </LoadingButton>
    </Box>
  );
};
