/* tslint:disable */
/* eslint-disable */
/**
 * Probonio full API Docs
 * If you stumbled upon this page and want to integrate Probonio into your business software [click here!](/api/external)    If you\'re looking for more information about Probonio [head over here](https://probonio.de)    **This documentation describes all Endpoints of the Probonio API. It\'s created for experienced Probonio Developers only!**  
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { BikeContractDTO } from '../dto';
// @ts-ignore
import { BikeContractIdentifierDTO } from '../dto';
// @ts-ignore
import { BikeContractListDTO } from '../dto';
// @ts-ignore
import { UpsertBikeContractDTO } from '../dto';
/**
 * BikeApi - axios parameter creator
 * @export
 */
export const BikeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary delete bike contract
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} contractId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteContract: async (tenantId: string, employeeId: string, contractId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('deleteContract', 'tenantId', tenantId)
            // verify required parameter 'employeeId' is not null or undefined
            assertParamExists('deleteContract', 'employeeId', employeeId)
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('deleteContract', 'contractId', contractId)
            const localVarPath = `/tenants/{tenantId}/employees/{employeeId}/benefits/bike/contracts/{contractId}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"employeeId"}}`, encodeURIComponent(String(employeeId)))
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get a bike contract by id
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} contractId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContract: async (tenantId: string, employeeId: string, contractId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('getContract', 'tenantId', tenantId)
            // verify required parameter 'employeeId' is not null or undefined
            assertParamExists('getContract', 'employeeId', employeeId)
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('getContract', 'contractId', contractId)
            const localVarPath = `/tenants/{tenantId}/employees/{employeeId}/benefits/bike/contracts/{contractId}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"employeeId"}}`, encodeURIComponent(String(employeeId)))
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary list bike contracts for employee
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listContracts: async (tenantId: string, employeeId: string, page?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('listContracts', 'tenantId', tenantId)
            // verify required parameter 'employeeId' is not null or undefined
            assertParamExists('listContracts', 'employeeId', employeeId)
            const localVarPath = `/tenants/{tenantId}/employees/{employeeId}/benefits/bike/contracts`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"employeeId"}}`, encodeURIComponent(String(employeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary list bike contracts for tenant
         * @param {string} tenantId 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTenantContracts: async (tenantId: string, page?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('listTenantContracts', 'tenantId', tenantId)
            const localVarPath = `/tenants/{tenantId}/benefits/bike/contracts`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary upsert a bike contract by contractNumber
         * @param {string} tenantId 
         * @param {string} contractNumber 
         * @param {UpsertBikeContractDTO} upsertBikeContractDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsertContract: async (tenantId: string, contractNumber: string, upsertBikeContractDTO: UpsertBikeContractDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('upsertContract', 'tenantId', tenantId)
            // verify required parameter 'contractNumber' is not null or undefined
            assertParamExists('upsertContract', 'contractNumber', contractNumber)
            // verify required parameter 'upsertBikeContractDTO' is not null or undefined
            assertParamExists('upsertContract', 'upsertBikeContractDTO', upsertBikeContractDTO)
            const localVarPath = `/tenants/{tenantId}/benefits/bike/contracts/{contractNumber}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"contractNumber"}}`, encodeURIComponent(String(contractNumber)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(upsertBikeContractDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BikeApi - functional programming interface
 * @export
 */
export const BikeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BikeApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary delete bike contract
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} contractId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteContract(tenantId: string, employeeId: string, contractId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteContract(tenantId, employeeId, contractId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get a bike contract by id
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} contractId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getContract(tenantId: string, employeeId: string, contractId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BikeContractDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getContract(tenantId, employeeId, contractId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary list bike contracts for employee
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listContracts(tenantId: string, employeeId: string, page?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BikeContractListDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listContracts(tenantId, employeeId, page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary list bike contracts for tenant
         * @param {string} tenantId 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listTenantContracts(tenantId: string, page?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BikeContractListDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listTenantContracts(tenantId, page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary upsert a bike contract by contractNumber
         * @param {string} tenantId 
         * @param {string} contractNumber 
         * @param {UpsertBikeContractDTO} upsertBikeContractDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async upsertContract(tenantId: string, contractNumber: string, upsertBikeContractDTO: UpsertBikeContractDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BikeContractIdentifierDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.upsertContract(tenantId, contractNumber, upsertBikeContractDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BikeApi - factory interface
 * @export
 */
export const BikeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BikeApiFp(configuration)
    return {
        /**
         * 
         * @summary delete bike contract
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} contractId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteContract(tenantId: string, employeeId: string, contractId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteContract(tenantId, employeeId, contractId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get a bike contract by id
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} contractId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContract(tenantId: string, employeeId: string, contractId: string, options?: any): AxiosPromise<BikeContractDTO> {
            return localVarFp.getContract(tenantId, employeeId, contractId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary list bike contracts for employee
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listContracts(tenantId: string, employeeId: string, page?: number, pageSize?: number, options?: any): AxiosPromise<BikeContractListDTO> {
            return localVarFp.listContracts(tenantId, employeeId, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary list bike contracts for tenant
         * @param {string} tenantId 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTenantContracts(tenantId: string, page?: number, pageSize?: number, options?: any): AxiosPromise<BikeContractListDTO> {
            return localVarFp.listTenantContracts(tenantId, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary upsert a bike contract by contractNumber
         * @param {string} tenantId 
         * @param {string} contractNumber 
         * @param {UpsertBikeContractDTO} upsertBikeContractDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsertContract(tenantId: string, contractNumber: string, upsertBikeContractDTO: UpsertBikeContractDTO, options?: any): AxiosPromise<BikeContractIdentifierDTO> {
            return localVarFp.upsertContract(tenantId, contractNumber, upsertBikeContractDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for deleteContract operation in BikeApi.
 * @export
 * @interface BikeApiDeleteContractRequest
 */
export interface BikeApiDeleteContractRequest {
    /**
     * 
     * @type {string}
     * @memberof BikeApiDeleteContract
     */
    readonly tenantId: string

    /**
     * 
     * @type {string}
     * @memberof BikeApiDeleteContract
     */
    readonly employeeId: string

    /**
     * 
     * @type {string}
     * @memberof BikeApiDeleteContract
     */
    readonly contractId: string
}

/**
 * Request parameters for getContract operation in BikeApi.
 * @export
 * @interface BikeApiGetContractRequest
 */
export interface BikeApiGetContractRequest {
    /**
     * 
     * @type {string}
     * @memberof BikeApiGetContract
     */
    readonly tenantId: string

    /**
     * 
     * @type {string}
     * @memberof BikeApiGetContract
     */
    readonly employeeId: string

    /**
     * 
     * @type {string}
     * @memberof BikeApiGetContract
     */
    readonly contractId: string
}

/**
 * Request parameters for listContracts operation in BikeApi.
 * @export
 * @interface BikeApiListContractsRequest
 */
export interface BikeApiListContractsRequest {
    /**
     * 
     * @type {string}
     * @memberof BikeApiListContracts
     */
    readonly tenantId: string

    /**
     * 
     * @type {string}
     * @memberof BikeApiListContracts
     */
    readonly employeeId: string

    /**
     * 
     * @type {number}
     * @memberof BikeApiListContracts
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof BikeApiListContracts
     */
    readonly pageSize?: number
}

/**
 * Request parameters for listTenantContracts operation in BikeApi.
 * @export
 * @interface BikeApiListTenantContractsRequest
 */
export interface BikeApiListTenantContractsRequest {
    /**
     * 
     * @type {string}
     * @memberof BikeApiListTenantContracts
     */
    readonly tenantId: string

    /**
     * 
     * @type {number}
     * @memberof BikeApiListTenantContracts
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof BikeApiListTenantContracts
     */
    readonly pageSize?: number
}

/**
 * Request parameters for upsertContract operation in BikeApi.
 * @export
 * @interface BikeApiUpsertContractRequest
 */
export interface BikeApiUpsertContractRequest {
    /**
     * 
     * @type {string}
     * @memberof BikeApiUpsertContract
     */
    readonly tenantId: string

    /**
     * 
     * @type {string}
     * @memberof BikeApiUpsertContract
     */
    readonly contractNumber: string

    /**
     * 
     * @type {UpsertBikeContractDTO}
     * @memberof BikeApiUpsertContract
     */
    readonly upsertBikeContractDTO: UpsertBikeContractDTO
}

/**
 * BikeApi - object-oriented interface
 * @export
 * @class BikeApi
 * @extends {BaseAPI}
 */
export class BikeApi extends BaseAPI {
    /**
     * 
     * @summary delete bike contract
     * @param {BikeApiDeleteContractRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BikeApi
     */
    public deleteContract(requestParameters: BikeApiDeleteContractRequest, options?: AxiosRequestConfig) {
        return BikeApiFp(this.configuration).deleteContract(requestParameters.tenantId, requestParameters.employeeId, requestParameters.contractId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get a bike contract by id
     * @param {BikeApiGetContractRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BikeApi
     */
    public getContract(requestParameters: BikeApiGetContractRequest, options?: AxiosRequestConfig) {
        return BikeApiFp(this.configuration).getContract(requestParameters.tenantId, requestParameters.employeeId, requestParameters.contractId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary list bike contracts for employee
     * @param {BikeApiListContractsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BikeApi
     */
    public listContracts(requestParameters: BikeApiListContractsRequest, options?: AxiosRequestConfig) {
        return BikeApiFp(this.configuration).listContracts(requestParameters.tenantId, requestParameters.employeeId, requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary list bike contracts for tenant
     * @param {BikeApiListTenantContractsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BikeApi
     */
    public listTenantContracts(requestParameters: BikeApiListTenantContractsRequest, options?: AxiosRequestConfig) {
        return BikeApiFp(this.configuration).listTenantContracts(requestParameters.tenantId, requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary upsert a bike contract by contractNumber
     * @param {BikeApiUpsertContractRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BikeApi
     */
    public upsertContract(requestParameters: BikeApiUpsertContractRequest, options?: AxiosRequestConfig) {
        return BikeApiFp(this.configuration).upsertContract(requestParameters.tenantId, requestParameters.contractNumber, requestParameters.upsertBikeContractDTO, options).then((request) => request(this.axios, this.basePath));
    }
}
