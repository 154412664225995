import React from 'react';
import { AddReceiptFlow } from '../../module/lunch/addReceipt/AddReceiptFlow';
import { useLunchPeriods } from '../../module/lunch/LunchPeriodsContext';
import { ImageSelectionState } from '../../module/shareTarget/useImageSelection';
interface Props {
  imageSelection: ImageSelectionState;
}

const AddReceiptPage: React.FC<Props> = ({ imageSelection }) => {
  const { currentPeriod, refetchLunch, previousPeriod } = useLunchPeriods();
  return (
    <AddReceiptFlow currentPeriod={currentPeriod} refetch={refetchLunch} imageSelection={imageSelection} previousPeriod={previousPeriod} />
  );
};

export default AddReceiptPage;
