/* tslint:disable */
/* eslint-disable */
/**
 * Probonio full API Docs
 * If you stumbled upon this page and want to integrate Probonio into your business software [click here!](/api/external)    If you\'re looking for more information about Probonio [head over here](https://probonio.de)    **This documentation describes all Endpoints of the Probonio API. It\'s created for experienced Probonio Developers only!**  
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface BikeleasingPaidByDTO
 */
export interface BikeleasingPaidByDTO {
    /**
     * 
     * @type {string}
     * @memberof BikeleasingPaidByDTO
     */
    'paidBy'?: BikeleasingPaidByDTOPaidByEnum;
    /**
     * 
     * @type {string}
     * @memberof BikeleasingPaidByDTO
     */
    'conclusion'?: BikeleasingPaidByDTOConclusionEnum;
}

export const BikeleasingPaidByDTOPaidByEnum = {
    Employer: 'Employer',
    Employee: 'Employee'
} as const;

export type BikeleasingPaidByDTOPaidByEnum = typeof BikeleasingPaidByDTOPaidByEnum[keyof typeof BikeleasingPaidByDTOPaidByEnum];
export const BikeleasingPaidByDTOConclusionEnum = {
    Voluntary: 'Voluntary',
    Compulsory: 'Compulsory',
    NotAllowed: 'NotAllowed'
} as const;

export type BikeleasingPaidByDTOConclusionEnum = typeof BikeleasingPaidByDTOConclusionEnum[keyof typeof BikeleasingPaidByDTOConclusionEnum];


