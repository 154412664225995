import { useMemo, useState } from 'react';
import { Operator } from './CurrencyInput';

export interface CalculatorState {
  currentInput: number;
  calculatorString: string;
  calculatorResult: number;
  valuesArray: number[];
  operatorArray: Operator[];
  setCurrentInput: React.Dispatch<React.SetStateAction<number>>;
  setCalculatorResult: (newResult: number) => void;
  setCalculatorString: (calculatorString: string) => void;
  setValuesArray: React.Dispatch<React.SetStateAction<number[]>>;
  setOperatorArray: React.Dispatch<React.SetStateAction<Operator[]>>;
  reset: (value?: number) => void;
}

export function useCalculatorState(): CalculatorState {
  const [currentInput, setCurrentInput] = useState<number>(0);
  const [calculatorString, setCalculatorString] = useState('');
  const [calculatorResult, setCalculatorResult] = useState(0);
  const [valuesArray, setValuesArray] = useState<number[]>([]);
  const [operatorArray, setOperatorArray] = useState<Operator[]>([]);

  return useMemo(
    (): CalculatorState => ({
      currentInput,
      calculatorString,
      calculatorResult,
      valuesArray,
      operatorArray,
      setCurrentInput,
      setCalculatorResult,
      setCalculatorString,
      setValuesArray,
      setOperatorArray,
      reset: value => {
        setCurrentInput(value || 0);
        setCalculatorResult(value || 0);
        setCalculatorString('');
        setValuesArray([]);
        setOperatorArray([]);
      },
    }),
    [calculatorResult, calculatorString, currentInput, operatorArray, valuesArray],
  );
}
