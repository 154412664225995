import { Button } from '@mui/material';
import { useSnackbar } from 'notistack';
import { InternetReceiptRefundDTO } from 'probonio-shared-ui/api';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfirmationModal } from '../../component/confirmationModal';
import { useNewDialogState } from '../../component/dialog/BasicDialog';
import { useDeleteReceiptMutation } from './internetQueries';

interface Props {
  currentReceipt: InternetReceiptRefundDTO;
}

export const DisableInternetButton: React.FC<Props> = ({ currentReceipt }) => {
  const { t } = useTranslation('benefitModule');
  const dialogState = useNewDialogState();
  const { enqueueSnackbar } = useSnackbar();

  const mutation = useDeleteReceiptMutation();

  const handleDeactivate = useCallback(() => {
    mutation.mutate(currentReceipt.id, {
      onSuccess: () => {
        enqueueSnackbar(t('benefit.INTERNET.confirmOnDisable'), { variant: 'success' });
        dialogState.dialogState.handleClose();
      },
    });
  }, [currentReceipt.id, dialogState.dialogState, enqueueSnackbar, mutation, t]);

  return (
    <>
      <Button data-test-id="disable-flat-rate-button" variant="contained" color="secondary" fullWidth onClick={dialogState.handleOpen}>
        {t('benefit.INTERNET.deleteButton')}
      </Button>
      <ConfirmationModal
        onCancel={dialogState.dialogState.handleClose}
        onSubmit={handleDeactivate}
        isOpen={dialogState.dialogState.isOpen}
        message={t('benefit.INTERNET.deleteModal.text')}
        title={t('benefit.INTERNET.deleteModal.title')}
      />
    </>
  );
};
