import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';
import { apis } from 'probonio-shared-ui/module/api';
import { useTranslation } from 'react-i18next';
import { DeleteRequestProps } from './UserDeleteRequestButton';

export function useUserDeleteRequest(onSuccess: () => void): UseMutationResult<void, Error, DeleteRequestProps> {
  const { t } = useTranslation('profilePage');

  return useMutation({
    mutationFn: async ({ userId }: DeleteRequestProps) => {
      await apis.user.sendDeleteUserRequest({ userId });
    },

    onSuccess: () => {
      enqueueSnackbar(t('deletionRequest.success'), { variant: 'success' });
      onSuccess();
    },
  });
}
