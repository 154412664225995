/* tslint:disable */
/* eslint-disable */
/**
 * Probonio full API Docs
 * If you stumbled upon this page and want to integrate Probonio into your business software [click here!](/api/external)    If you\'re looking for more information about Probonio [head over here](https://probonio.de)    **This documentation describes all Endpoints of the Probonio API. It\'s created for experienced Probonio Developers only!**  
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { LunchRefundMetadataDTO } from './lunch-refund-metadata-dto';

/**
 * 
 * @export
 * @interface LunchReceiptRefundDTO
 */
export interface LunchReceiptRefundDTO {
    /**
     * 
     * @type {string}
     * @memberof LunchReceiptRefundDTO
     */
    'status': LunchReceiptRefundDTOStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof LunchReceiptRefundDTO
     */
    'receiptType': LunchReceiptRefundDTOReceiptTypeEnum;
    /**
     * refund of this receipt in its period in cent
     * @type {number}
     * @memberof LunchReceiptRefundDTO
     */
    'refund': number;
    /**
     * 
     * @type {LunchRefundMetadataDTO}
     * @memberof LunchReceiptRefundDTO
     */
    'refundMetadata': LunchRefundMetadataDTO;
    /**
     * 
     * @type {string}
     * @memberof LunchReceiptRefundDTO
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof LunchReceiptRefundDTO
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof LunchReceiptRefundDTO
     */
    'userTotal'?: number;
    /**
     * 
     * @type {string}
     * @memberof LunchReceiptRefundDTO
     */
    'date': string;
    /**
     * 
     * @type {string}
     * @memberof LunchReceiptRefundDTO
     */
    'comment'?: string;
    /**
     * 
     * @type {string}
     * @memberof LunchReceiptRefundDTO
     */
    'rejectionReason'?: string;
    /**
     * 
     * @type {number}
     * @memberof LunchReceiptRefundDTO
     */
    'rejectionCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof LunchReceiptRefundDTO
     */
    'rejectionDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof LunchReceiptRefundDTO
     */
    'imageURL'?: string;
    /**
     * 
     * @type {string}
     * @memberof LunchReceiptRefundDTO
     */
    'thumbnailURL'?: string;
    /**
     * 
     * @type {string}
     * @memberof LunchReceiptRefundDTO
     */
    'pdfURL'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof LunchReceiptRefundDTO
     */
    'isAuditedByTenant'?: boolean;
}

export const LunchReceiptRefundDTOStatusEnum = {
    Approved: 'APPROVED',
    Rejected: 'REJECTED',
    Pending: 'PENDING'
} as const;

export type LunchReceiptRefundDTOStatusEnum = typeof LunchReceiptRefundDTOStatusEnum[keyof typeof LunchReceiptRefundDTOStatusEnum];
export const LunchReceiptRefundDTOReceiptTypeEnum = {
    Single: 'SINGLE',
    Group: 'GROUP',
    Mixed: 'MIXED'
} as const;

export type LunchReceiptRefundDTOReceiptTypeEnum = typeof LunchReceiptRefundDTOReceiptTypeEnum[keyof typeof LunchReceiptRefundDTOReceiptTypeEnum];


