import { UploadLinkDTO } from 'probonio-shared-ui/api';

export async function uploadFileToS3(uploadLink: UploadLinkDTO, file: Blob, mimeType?: string): Promise<void> {
  const formData = new FormData();
  formData.append('Content-Type', mimeType || file.type || 'application/octet-stream');
  Object.keys(uploadLink.params).forEach(param => formData.append(param, uploadLink.params[param]));
  formData.append('file', file);
  const res = await fetch(uploadLink.url, { method: 'POST', body: formData });
  if (!res.ok) {
    throw new Error(`Unexpected status code ${res.status} on fetch request to ${uploadLink.url}`);
  }
}
