/* eslint-disable no-console */
import { Capacitor } from '@capacitor/core';
import { ActionPerformed, PushNotificationSchema } from '@capacitor/push-notifications';
import { Box, Typography } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { MouseEventHandler, createContext, useCallback, useContext, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { CapacitorPushAdapter } from './adapter/capacitorPush';
import { PushAdapter } from './adapter/types';
import { WebPushAdapter } from './adapter/webPush';
import { registerDevice } from './pushNotificationSlice';

const ABSOLUTE_URL_EXP = /^(?:[a-z+]+:)?\/\//;

function createPlatformPushAdapter(): PushAdapter {
  if (Capacitor.isNativePlatform()) {
    return new CapacitorPushAdapter();
  } else {
    return new WebPushAdapter();
  }
}
const pushAdapter: PushAdapter = createPlatformPushAdapter();

interface PushNotificationState {
  isSupported: () => Promise<boolean>;
  hasPermission: () => Promise<boolean>;
  requestPermission: () => Promise<void>;
}

const PushNotificationContext = createContext<PushNotificationState | undefined>(undefined);

export const PushNotificationRegistration: React.FC<React.PropsWithChildren> = ({ children }) => {
  const user = useAppSelector(state => state.me.user);
  const dispatch = useAppDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  // register token with backend on login
  const handleRegisterDevice = useCallback(
    (token: string) => {
      if (user) {
        void dispatch(registerDevice(token));
      }
    },
    [user, dispatch],
  );

  // show snackbar toast when notification is received
  const handleNotification = useCallback(
    (notification: PushNotificationSchema) => {
      let onClick: MouseEventHandler<HTMLDivElement> | undefined;
      const targetUrl = notification.data?.url;
      if (targetUrl && !ABSOLUTE_URL_EXP.test(targetUrl)) {
        onClick = () => {
          void queryClient.invalidateQueries();
          navigate(targetUrl);
          closeSnackbar();
        };
      }
      enqueueSnackbar(
        <Box>
          {notification.title && (
            <Typography variant="descriptionBold" color="inherit" component="div" gutterBottom>
              {notification.title}
            </Typography>
          )}
          <Typography variant="body2" color="inherit">
            {notification.body}
          </Typography>
        </Box>,
        { variant: 'notification', autoHideDuration: 7500, SnackbarProps: { onClick } },
      );
    },
    [closeSnackbar, enqueueSnackbar, navigate, queryClient],
  );

  // navigate to url when the user clicks on a notification
  const handleNotificationAction = useCallback(
    (notification: ActionPerformed) => {
      const targetUrl = notification.notification.data?.url;
      if (targetUrl && !ABSOLUTE_URL_EXP.test(targetUrl)) {
        void queryClient.invalidateQueries();
        navigate(targetUrl);
      }
    },
    [navigate, queryClient],
  );

  useEffect(() => {
    pushAdapter.callbacks = {
      onRegistration: handleRegisterDevice,
      onNotificationReceived: handleNotification,
      onNotificationAction: handleNotificationAction,
    };
  }, [handleNotification, handleNotificationAction, handleRegisterDevice]);
  // initialize the adapter when the user logs in
  useEffect(() => {
    if (user) {
      void pushAdapter.initialize();
      return () => {
        void pushAdapter.destroy();
      };
    }
  }, [user]);

  const context = useMemo(
    (): PushNotificationState => ({
      isSupported: () => pushAdapter.isSupported(),
      hasPermission: () => pushAdapter.hasPermission(),
      requestPermission: async () => {
        await pushAdapter.requestPermission();
      },
    }),
    [],
  );

  return <PushNotificationContext.Provider value={context}>{children}</PushNotificationContext.Provider>;
};

export function usePushNotifications(): PushNotificationState {
  const ctx = useContext(PushNotificationContext);
  // Throw error that the Context is not injected, if it is undefined.
  if (ctx === undefined) {
    throw new Error('usePushNotifications must be used within a PushNotificationRegistration');
  }
  return ctx;
}
