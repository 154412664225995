import { DateTime, Interval } from 'luxon';
import {
  BenefitDTOBenefitEnum,
  LunchReceiptDTO,
  MobilityReceiptDTO,
  MobilityReceiptDTOMobilityTypeEnum,
  RecreationReceiptDTO,
  UpdateLunchReceiptDTO,
  UpdateMobilityReceiptDTO,
} from 'probonio-shared-ui/api';
import { EditReceiptFormValues } from './EditReceiptForm';

export function mapFromReceipt(
  receipt: LunchReceiptDTO | MobilityReceiptDTO | RecreationReceiptDTO,
  benefit: BenefitDTOBenefitEnum,
): EditReceiptFormValues {
  if (benefit === BenefitDTOBenefitEnum.Lunch) {
    const lunchReceipt = receipt as LunchReceiptDTO;
    return {
      lunchReceiptType: lunchReceipt.receiptType,
      total: lunchReceipt.total,
      date: new Date(receipt.date),
      comment: lunchReceipt.comment || '',
      image: lunchReceipt.thumbnailURL || lunchReceipt.imageURL,
    };
  }
  const mobilityReceipt = receipt as MobilityReceiptDTO;
  return {
    mobilityType: mobilityReceipt.mobilityType,
    mobilityTravelPurpose: mobilityReceipt.travelPurpose,
    mobilityValidityStartDate: mobilityReceipt.validityStartDate ? new Date(mobilityReceipt.validityStartDate) : undefined,
    mobilityValidityMonths: getValidityMonths(mobilityReceipt.validityStartDate, mobilityReceipt.validityEndDate),
    total: mobilityReceipt.total,
    date: new Date(receipt.date),
    comment: mobilityReceipt.comment || '',
    image: mobilityReceipt.thumbnailURL || mobilityReceipt.imageURL,
  };
}

export function getValidityMonths(validityStartDate?: string, validityEndDate?: string): number | undefined {
  if (validityStartDate && validityEndDate) {
    return (DateTime.fromISO(validityStartDate).until(DateTime.fromISO(validityEndDate).plus({ months: 1 })) as Interval).length('months');
  }
}

export function getValidityEndDate(validityStartDate: Date, validityMonths: number): string {
  return DateTime.fromJSDate(validityStartDate)
    .plus({ months: validityMonths - 1 })
    .toFormat('yyyy-MM');
}

function getChangedValues<T extends {}>(initialValues: T, newValues: T): Partial<T> {
  return Object.fromEntries(
    Object.entries(newValues).filter(entry => JSON.stringify(initialValues[entry[0] as keyof T]) !== JSON.stringify(entry[1])),
  ) as Partial<T>;
}

export function mapToUpdateRequest(
  initialReceipt: LunchReceiptDTO | MobilityReceiptDTO | RecreationReceiptDTO,
  formValues: EditReceiptFormValues,
  benefit: BenefitDTOBenefitEnum,
): UpdateLunchReceiptDTO | UpdateMobilityReceiptDTO {
  const initialValues = mapFromReceipt(initialReceipt, benefit);
  const changedValues = getChangedValues(initialValues, formValues);

  if (benefit === BenefitDTOBenefitEnum.Lunch) {
    const updateLunchReceipt: UpdateLunchReceiptDTO = {
      receiptType: changedValues.lunchReceiptType,
      total: changedValues.total,
      date: changedValues.date ? DateTime.fromJSDate(changedValues.date).toFormat('yyyy-MM-dd') : undefined,
      comment: changedValues.comment === '' ? null : changedValues.comment,
    };
    return updateLunchReceipt;
  }

  let validityStartDate: string | undefined | null, validityEndDate: string | undefined | null;
  if (
    changedValues.mobilityValidityStartDate ||
    changedValues.mobilityValidityMonths ||
    changedValues.mobilityType === MobilityReceiptDTOMobilityTypeEnum.TimeCard
  ) {
    const startDate = changedValues.mobilityValidityStartDate || initialValues.mobilityValidityStartDate;
    const validityMonths = changedValues.mobilityValidityMonths || initialValues.mobilityValidityMonths;
    if (startDate && validityMonths && validityMonths !== 1) {
      validityStartDate = DateTime.fromJSDate(startDate).toFormat('yyyy-MM');
      validityEndDate = getValidityEndDate(startDate, validityMonths);
    } else {
      validityStartDate = null;
      validityEndDate = null;
    }
  }

  const updateMobilityReceipt: UpdateMobilityReceiptDTO = {
    mobilityType: changedValues.mobilityType,
    travelPurpose: changedValues.mobilityTravelPurpose,
    validityStartDate,
    validityEndDate,
    total: changedValues.total,
    date: changedValues.date ? DateTime.fromJSDate(changedValues.date).toFormat('yyyy-MM-dd') : undefined,
    comment: changedValues.comment === '' ? null : changedValues.comment,
  };

  return updateMobilityReceipt;
}
