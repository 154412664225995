import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Stack, useTheme } from '@mui/material';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ThemeVariant, useThemeSwitcher } from './ThemeSwitcherProvider';
import LightModeIcon from '@mui/icons-material/LightMode';
import NightlightIcon from '@mui/icons-material/Nightlight';
import BrightnessMediumIcon from '@mui/icons-material/BrightnessMedium';

const useThemeItem = (icon: React.ReactNode, option: ThemeVariant, hide?: boolean): React.ReactNode => {
  const { t } = useTranslation('profilePage');

  if (hide) {
    return null;
  }

  return (
    <MenuItem value={option}>
      <Stack alignItems={'center'} direction="row" columnGap={0.3}>
        {icon} {t(`themeSwitch.${option}`)}
      </Stack>
    </MenuItem>
  );
};

export const ThemeSwitcher: React.FC = () => {
  const { selectedVariant, switchTheme } = useThemeSwitcher();
  const { t } = useTranslation('profilePage');

  const theme = useTheme();

  const handleThemeSwitch = useCallback(
    (e: SelectChangeEvent<ThemeVariant>) => {
      switchTheme(e.target?.value as ThemeVariant);
    },
    [switchTheme],
  );

  return (
    <FormControl fullWidth variant="filled">
      <InputLabel sx={{ color: 'text.primary' }} id="switch-theme-selector-label">
        {t('theme')}
      </InputLabel>
      <Select
        data-test-id="switch-theme-selector"
        labelId="switch-theme-selector-label"
        id="switch-theme-selector"
        disableUnderline
        value={selectedVariant}
        label={selectedVariant}
        sx={{ backgroundColor: theme.palette.background.paper, borderRadius: '7px' }}
        onChange={handleThemeSwitch}
      >
        {useThemeItem(<LightModeIcon />, 'light')}
        {useThemeItem(<NightlightIcon />, 'dark')}
        {useThemeItem(<BrightnessMediumIcon />, 'system')}
      </Select>
    </FormControl>
  );
};
