import CircleIcon from '@mui/icons-material/Circle';
import { Box, Button, ToggleButtonGroup, Typography, useTheme } from '@mui/material';
import { CouponDefinitionDTO, CouponVariationAvailabilityDTOAvailabilityEnum } from 'probonio-shared-ui/api';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNewDialogState } from '../../../component/dialog/BasicDialog';
import { LoadingFunction } from '../../../utils/loadingFunction';
import { getCouponImageUrl } from '../checkCouponImageUrl';
import { CouponCard } from '../components/CouponCard';
import { CouponUsageText } from '../components/CouponUsageText';
import { getCouponAvailability } from '../couponCombinations';
import { ExploreCouponsDetailModal } from './ExploreCouponsDetailModal';
import { ContainedToggleButton } from '../../../component/button/ContainedToggleButton';

interface Props {
  coupon: CouponDefinitionDTO;
  availableAmounts?: number[];
  dialogMessage?: React.ReactNode;
  dialogExtension?: React.ReactNode;
  noneAvailableMessage?: React.ReactNode;
  onOrder: LoadingFunction<(amount: number) => void>;
  tenantBudgetAvailable?: boolean;
}

const AVAILABILITY_COLORS: Record<CouponVariationAvailabilityDTOAvailabilityEnum, 'success' | 'info' | 'error'> = {
  IN_STOCK: 'success',
  IN_STOCK_UNLIMITED: 'success',
  ON_DEMAND: 'info',
  OUT_OF_STOCK: 'error',
};

export const ExploreCouponsDetail: React.FC<Props> = ({
  coupon,
  availableAmounts,
  dialogExtension,
  noneAvailableMessage,
  onOrder,
  tenantBudgetAvailable,
}) => {
  const { dialogState, handleOpen: handleOpenDialog } = useNewDialogState();
  const { t } = useTranslation('couponsModule');
  const theme = useTheme();
  const [selectedAmount, setSelectedAmount] = useState(availableAmounts?.[0]);
  const availability = selectedAmount ? getCouponAvailability(coupon, selectedAmount) : undefined;

  const { url: couponImageUrl } = getCouponImageUrl(coupon);

  const selectableAmounts = availableAmounts
    ?.filter(amount => coupon.variations?.includes(amount) || coupon.combinations?.includes(amount))
    .sort((a, b) => b - a);

  const handleChangeAmount = useCallback((event: React.MouseEvent, value: number | undefined) => {
    if (value) {
      setSelectedAmount(value);
    }
  }, []);

  return (
    <>
      <CouponCard
        bottomPanel={
          <>
            <Button variant="outlined" fullWidth href={coupon.redeemUrl || '#'} target="_blank">
              {t('exploreCoupons.details.visitShop')}
            </Button>
          </>
        }
      >
        <Box display={'flex'} sx={{ marginBottom: 0.75, alignItems: 'center', justifyContent: 'center' }} height={120}>
          <img src={couponImageUrl} style={{ borderRadius: 8, maxHeight: 120, maxWidth: 200 }} />
        </Box>
        <Typography variant="h1" textAlign="center" sx={{ marginBottom: 1 }}>
          {coupon.name}
        </Typography>
        {selectableAmounts?.[0] && availability ? (
          <>
            <Box display="flex" justifyContent="center" sx={{ marginBottom: 1.25 }}>
              {selectableAmounts.length > 1 ? (
                <ToggleButtonGroup exclusive fullWidth value={selectedAmount} onChange={handleChangeAmount}>
                  {selectableAmounts.map(amount => (
                    <ContainedToggleButton key={amount} value={amount}>
                      {t('couponOrderDetails.couponAmount', { amount })}
                    </ContainedToggleButton>
                  ))}
                </ToggleButtonGroup>
              ) : (
                <Typography component="h2" variant="extraBig" textAlign="center" data-test-id="single-coupon-amount">
                  {t('couponOrderDetails.couponAmount', { amount: selectedAmount })}
                </Typography>
              )}
            </Box>

            <CouponUsageText usageText={coupon.redemptionDescription} theme={theme} />

            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              marginBottom={0.5}
              fontSize={theme.typography.description.fontSize}
            >
              <CircleIcon
                color={!tenantBudgetAvailable ? 'error' : AVAILABILITY_COLORS[availability]}
                fontSize="inherit"
                sx={{ verticalAlign: 'middle', marginRight: 0.4 }}
              />
              <Typography variant="description" data-test-id="couponStatus">
                {t(`couponOrderDetails.couponAvailability.${tenantBudgetAvailable ? availability : 'NO_BUDGET'}`)}
              </Typography>
            </Box>
          </>
        ) : (
          <Box paddingBottom={0.25}>
            <Typography variant="body2" fontStyle="italic" textAlign="center" color={theme.palette.text.secondary}>
              {noneAvailableMessage || t('exploreCoupons.details.couponAlreadyUsed')}
            </Typography>
          </Box>
        )}

        <Button
          variant="contained"
          fullWidth
          disabled={
            !selectableAmounts?.[0] || availability === CouponVariationAvailabilityDTOAvailabilityEnum.OutOfStock || !tenantBudgetAvailable
          }
          sx={{
            '&:disabled': { backgroundColor: theme.palette.primary.main, color: theme.palette.primary.contrastText, opacity: 0.5 },
          }}
          onClick={handleOpenDialog}
          data-test-id="order-coupon-button"
        >
          {t('exploreCoupons.details.orderCoupon')}
        </Button>
      </CouponCard>
      {selectedAmount && (
        <ExploreCouponsDetailModal
          coupon={coupon}
          dialogState={dialogState}
          amount={selectedAmount}
          onConfirm={onOrder}
          tenantBudgetAvailable={tenantBudgetAvailable}
        >
          {dialogExtension}
        </ExploreCouponsDetailModal>
      )}
    </>
  );
};
