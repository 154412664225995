import { FormControlLabel, FormControlLabelProps, Switch, SwitchProps } from '@mui/material';
import React from 'react';
import { FieldPath, FieldValues, useController, UseControllerProps } from 'react-hook-form';

type Props<T extends FieldValues, TName extends FieldPath<T>> = UseControllerProps<T, TName> &
  Omit<SwitchProps, 'defaultValue'> & {
    label: React.ReactNode;
    children?: React.ReactNode;
    controlLabelProps?: Omit<FormControlLabelProps, 'label' | 'control'>;
  };

export const SwitchControl = <T extends FieldValues, TName extends FieldPath<T>>({
  name,
  label,
  rules,
  control,
  defaultValue,
  shouldUnregister,
  controlLabelProps,
  ...props
}: Props<T, TName>): JSX.Element => {
  const { field } = useController({ name, rules, control, defaultValue, shouldUnregister });

  return (
    <FormControlLabel
      label={label}
      {...controlLabelProps}
      control={<Switch onChange={field.onChange} checked={field.value} value={field.value} {...props} />}
    />
  );
};
