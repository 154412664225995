import { PluginListenerHandle } from '@capacitor/core';
import { ActionPerformed, PushNotifications, PushNotificationSchema, Token } from '@capacitor/push-notifications';
import { openPhoneSettings } from '../../../component/pushNotificationDialog/openPhoneSettings';
import { PushAdapter, PushCallbacks } from './types';

/**
 * Adapter for native Android/iOS notifications using @capacitor/push-notifications plugin
 */
export class CapacitorPushAdapter implements PushAdapter {
  private registrationHandle: PluginListenerHandle | undefined;
  private notificationReceivedHandle: PluginListenerHandle | undefined;
  private actionPerformedHandle: PluginListenerHandle | undefined;
  public callbacks: PushCallbacks | undefined;

  public async initialize(): Promise<void> {
    this.registrationHandle = await PushNotifications.addListener('registration', (token: Token) => {
      this.callbacks?.onRegistration(token.value);
    });
    this.notificationReceivedHandle = await PushNotifications.addListener(
      'pushNotificationReceived',
      (notification: PushNotificationSchema) => {
        this.callbacks?.onNotificationReceived(notification);
      },
    );
    this.actionPerformedHandle = await PushNotifications.addListener('pushNotificationActionPerformed', (notification: ActionPerformed) => {
      this.callbacks?.onNotificationAction(notification);
    });

    const hasPermission = await this.hasPermission();
    if (hasPermission) {
      await PushNotifications.register();
    }
  }

  public async isSupported(): Promise<boolean> {
    return true;
  }

  public async hasPermission(): Promise<boolean> {
    const existingPermission = await PushNotifications.checkPermissions();
    return existingPermission.receive === 'granted';
  }

  public async requestPermission(): Promise<void> {
    const existingPermission = await PushNotifications.checkPermissions();
    const permissionResult = await PushNotifications.requestPermissions();
    if (permissionResult.receive === 'granted') {
      await PushNotifications.register();
    } else if (permissionResult.receive === 'denied' && existingPermission.receive === 'denied') {
      await openPhoneSettings();
    }
  }

  public async destroy(): Promise<void> {
    await this.registrationHandle?.remove();
    await this.notificationReceivedHandle?.remove();
    await this.actionPerformedHandle?.remove();
  }
}
