/* tslint:disable */
/* eslint-disable */
/**
 * Probonio full API Docs
 * If you stumbled upon this page and want to integrate Probonio into your business software [click here!](/api/external)    If you\'re looking for more information about Probonio [head over here](https://probonio.de)    **This documentation describes all Endpoints of the Probonio API. It\'s created for experienced Probonio Developers only!**  
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface BaseEmployeeDTO
 */
export interface BaseEmployeeDTO {
    /**
     * 
     * @type {string}
     * @memberof BaseEmployeeDTO
     */
    'status': BaseEmployeeDTOStatusEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof BaseEmployeeDTO
     */
    'roles': Array<BaseEmployeeDTORolesEnum>;
    /**
     * 
     * @type {string}
     * @memberof BaseEmployeeDTO
     */
    'employeeType'?: BaseEmployeeDTOEmployeeTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof BaseEmployeeDTO
     */
    'civilStatus'?: BaseEmployeeDTOCivilStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof BaseEmployeeDTO
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof BaseEmployeeDTO
     */
    'tenantId': string;
    /**
     * 
     * @type {string}
     * @memberof BaseEmployeeDTO
     */
    'tenantName'?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseEmployeeDTO
     */
    'employeeNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseEmployeeDTO
     */
    'birthDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseEmployeeDTO
     */
    'hireDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseEmployeeDTO
     */
    'terminationDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseEmployeeDTO
     */
    'department'?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseEmployeeDTO
     */
    'costCenter'?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseEmployeeDTO
     */
    'defaultCouponId'?: string;
    /**
     * 
     * @type {number}
     * @memberof BaseEmployeeDTO
     */
    'receiptCred'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof BaseEmployeeDTO
     */
    'bavNotInterested'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof BaseEmployeeDTO
     */
    'numberOfKids'?: number;
}

export const BaseEmployeeDTOStatusEnum = {
    Active: 'ACTIVE',
    Inactive: 'INACTIVE',
    Registered: 'REGISTERED',
    InvitationCode: 'INVITATION_CODE',
    Deleted: 'DELETED'
} as const;

export type BaseEmployeeDTOStatusEnum = typeof BaseEmployeeDTOStatusEnum[keyof typeof BaseEmployeeDTOStatusEnum];
export const BaseEmployeeDTORolesEnum = {
    TenantAdmin: 'TENANT_ADMIN',
    TenantAssistant: 'TENANT_ASSISTANT',
    User: 'USER',
    TenantSales: 'TENANT_SALES'
} as const;

export type BaseEmployeeDTORolesEnum = typeof BaseEmployeeDTORolesEnum[keyof typeof BaseEmployeeDTORolesEnum];
export const BaseEmployeeDTOEmployeeTypeEnum = {
    FullTime: 'FULL_TIME',
    PartTime: 'PART_TIME',
    Intern: 'INTERN',
    Student: 'STUDENT',
    MiniJob: 'MINI_JOB',
    Misc: 'MISC'
} as const;

export type BaseEmployeeDTOEmployeeTypeEnum = typeof BaseEmployeeDTOEmployeeTypeEnum[keyof typeof BaseEmployeeDTOEmployeeTypeEnum];
export const BaseEmployeeDTOCivilStatusEnum = {
    Single: 'SINGLE',
    Married: 'MARRIED',
    Divorced: 'DIVORCED',
    Widowed: 'WIDOWED',
    CivilUnion: 'CIVIL_UNION',
    CivilUnionDeceased: 'CIVIL_UNION_DECEASED',
    CivilUnionCancelled: 'CIVIL_UNION_CANCELLED'
} as const;

export type BaseEmployeeDTOCivilStatusEnum = typeof BaseEmployeeDTOCivilStatusEnum[keyof typeof BaseEmployeeDTOCivilStatusEnum];


