import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import MessageLayout from '../component/layout/MessageLayout';

const SocialLoginError: React.FC = () => {
  const { t } = useTranslation('authModule');

  return (
    <MessageLayout
      title={t('socialLoginError.title')}
      text={t('socialLoginError.text')}
      actions={
        <Button component={Link} to="/" variant="contained" color="warning">
          {t('errorModule:goHome')}
        </Button>
      }
    />
  );
};

export default SocialLoginError;
