import HomeIcon from '@mui/icons-material/Home';
import { Button } from '@mui/material';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorView } from '../module/error/ErrorView';

const LoginErrorPage: React.FC = () => {
  const { t } = useTranslation('errorModule');

  const handleGoHome = useCallback(() => {
    window.location.href = '/';
  }, []);

  return (
    <ErrorView
      headlineLarge={t('loginError.headlineLarge')}
      headlineSmall={t('loginError.headlineSmall')}
      message={t('loginError.message')}
    >
      <Button startIcon={<HomeIcon />} color="warning" variant="contained" onClick={handleGoHome} sx={{ margin: 0.5 }}>
        {t('goHome')}
      </Button>
    </ErrorView>
  );
};

export default LoginErrorPage;
