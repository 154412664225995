import { Box, Button, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { getReceiptSeverity } from '../../lunch';
import { ReceiptIcon } from '../../lunch/components/ReceiptIcon';

export type ReceiptStatus = 'APPROVED' | 'REJECTED' | 'PENDING' | 'WAITING_FOR_AUDIT';

interface Props {
  linkTo: string;
  receiptStatus: ReceiptStatus;
  amountPaid: React.ReactNode;
  date: React.ReactNode;
}

export const Receipt: React.FC<Props> = ({ linkTo, receiptStatus, amountPaid, date }) => {
  const { t } = useTranslation('benefitModule');
  const navigate = useNavigate();
  const handleNavigateTo = useCallback(() => {
    navigate(linkTo);
  }, [linkTo, navigate]);
  return (
    <ListItem sx={{ padding: 0, paddingX: 1 }} component={Button} onClick={handleNavigateTo} data-test-id={receiptStatus}>
      <ListItemIcon color={getReceiptSeverity(receiptStatus) + '.main'}>
        <ReceiptIcon severity={getReceiptSeverity(receiptStatus)} color={getReceiptSeverity(receiptStatus)} fontSize="extraLarge" />
      </ListItemIcon>

      <ListItemText
        primary={
          <Typography component="span" variant="caption" color="text.primary">
            {t(`benefit.LUNCH.receipts.${receiptStatus}.title`)}
          </Typography>
        }
        secondary={
          <Box component="span" sx={{ display: 'flex', flexDirection: 'row' }}>
            <Box component="span" sx={{ flexGrow: 1 }}>
              <Typography component="span" variant="inherit">
                {amountPaid}
              </Typography>{' '}
              {t(`benefit.LUNCH.receipts.${receiptStatus}.description`)}
            </Box>
            <Typography component="span" variant="inherit">
              {date}
            </Typography>
          </Box>
        }
      />
    </ListItem>
  );
};
