import { createSvgIcon } from '@mui/material';

export const PresentIcon = createSvgIcon(
  <>
    <path d="m 1.5,12.750381 v 8.984403 c 0,0.826566 0.6735,1.497401 1.5,1.497401 h 7.5 V 12.750381 Z" />
    <path d="m 22.5,6.7607779 h -4.746 c 0.3405,-0.2320971 0.633,-0.4626967 0.837,-0.669338 1.2105,-1.214392 1.2105,-3.1909608 0,-4.4053527 -1.176,-1.18294653 -3.225,-1.17995172 -4.3995,0 -0.651,0.6513693 -2.376,3.3032658 -2.1375,5.0746907 H 11.946 C 12.183,4.989353 10.458,2.3374565 9.8085,1.6860872 8.6325,0.50613548 6.5835,0.50613548 5.409,1.6860872 4.2,2.9004791 4.2,4.8770479 5.4075,6.0914399 5.613,6.2980812 5.9055,6.5286808 6.246,6.7607779 H 1.5 c -0.8265,0 -1.5,0.6723329 -1.5,1.4974007 V 10.50428 c 0,0.413282 0.336,0.7487 0.75,0.7487 H 10.5 V 8.2581786 h 3 V 11.25298 h 9.75 c 0.414,0 0.75,-0.335418 0.75,-0.7487 V 8.2581786 C 24,7.4331108 23.328,6.7607779 22.5,6.7607779 Z M 10.4115,6.7113637 c 0,0 -0.063,0.049414 -0.2775,0.049414 -1.0365,0 -3.015,-1.0751336 -3.6615,-1.7250055 C 5.844,4.4038693 5.844,3.3736577 6.4725,2.7417546 6.777,2.4362849 7.1805,2.268576 7.6095,2.268576 c 0.4275,0 0.831,0.1677089 1.1355,0.4731786 1.011,1.0152377 2.016,3.6087356 1.6665,3.9696091 z m 3.453,0.049414 c -0.213,0 -0.276,-0.047917 -0.276,-0.049414 -0.3495,-0.3608735 0.6555,-2.9543714 1.6665,-3.9696091 0.6045,-0.609442 1.662,-0.6124368 2.2725,0 0.63,0.6319031 0.63,1.6621147 0,2.2940178 -0.6465,0.6498719 -2.625,1.7250055 -3.663,1.7250053 z" />
    <path d="M 13.5,12.750381 V 23.232185 H 21 c 0.828,0 1.5,-0.670835 1.5,-1.497401 v -8.984403 z" />
  </>,
  'Incentive',
);
