import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import { Box, CircularProgress, IconButton, useTheme } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { BackIconButton } from '../../component/button/BackIconButton';
import { MobileLayout } from '../../component/layout';
import { AppToolbar } from '../../component/layout/AppToolbar';
import { SwipeableList } from '../../component/swipeableList/SwipeableList';
import { CouponCard } from '../../module/coupons';
import { CouponsV2OrderDetail } from '../../module/couponsV2/CouponsV2OrderDetail';
import { useCouponsV2UserOrderQuery } from '../../module/couponsV2/couponsV2Queries';
import { useBackNavigator } from '../../module/navigation/BackNavigator';
import { useCombinedCouponOrderListQuery } from '../../module/couponsShared/couponsSharedQueries';
import { CouponItemModel } from '../../module/couponsShared/orderList/couponItemModel';

export const CouponsV2OrderPage: React.FC = () => {
  const backNavigator = useBackNavigator();
  const { couponOrderId } = useParams();
  const theme = useTheme();
  const navigate = useNavigate();

  const { data: orders } = useCombinedCouponOrderListQuery();
  const selectableOrders = useMemo(() => orders.filter(order => order.status !== 'ERROR'), [orders]);
  const { data: couponOrder, isLoading } = useCouponsV2UserOrderQuery(couponOrderId!);
  const couponDefinition = couponOrder?.couponDefinition as Record<string, string> | undefined;

  const handleNavigate = useCallback(
    (item: CouponItemModel) => {
      navigate(`/${item.version === 'V1' ? 'coupons/order' : 'couponsV2/orders'}/${item.id}`, { replace: true });
    },
    [navigate],
  );

  return (
    <MobileLayout
      header={
        <AppToolbar
          backButton={<BackIconButton onClick={backNavigator.back} />}
          actionButton={
            <>
              {couponDefinition?.redeemUrl && (
                <IconButton href={couponDefinition.redeemUrl} target="_blank">
                  <ShoppingCartOutlinedIcon />
                </IconButton>
              )}
            </>
          }
        />
      }
      backgroundColor={theme.palette.background.default}
    >
      <Box sx={{ paddingX: 1, paddingBottom: 1 }}>
        {isLoading ? (
          <CouponCard>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
              <CircularProgress />
            </Box>
          </CouponCard>
        ) : (
          <SwipeableList items={selectableOrders} currentItemId={couponOrderId!} onNavigate={handleNavigate}>
            <CouponsV2OrderDetail couponOrder={couponOrder!} />
          </SwipeableList>
        )}
      </Box>
    </MobileLayout>
  );
};
