import { useTranslation } from 'react-i18next';

const AppStoreIcon: React.FC = () => {
  const { i18n } = useTranslation();

  return (
    <>
      {i18n.resolvedLanguage === 'de' ? (
        <a href="https://apps.apple.com/np/app/probonio/id1617828643">
          <img src="/image/app-store-button.svg" alt="app-store-icon" height="40px" />
        </a>
      ) : (
        <a href="https://apps.apple.com/np/app/probonio/id1617828643">
          <img src="/image/app-store-button-english.svg" alt="app-store-icon-english" height="40px" />
        </a>
      )}
    </>
  );
};

export default AppStoreIcon;
