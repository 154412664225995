import { useMutation, useQueryClient } from '@tanstack/react-query';
import { CouponsV2AvailableGiftDTO, CouponsV2GiftDTOGiftTypeEnum } from 'probonio-shared-ui/api';
import { apis } from 'probonio-shared-ui/module/api';
import { useActiveEmployment, useTenant } from 'probonio-shared-ui/module/me';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useNewDialogState } from '../../../component/dialog/BasicDialog';
import GiftModal from '../../coupons/gift/GiftModal';
import { GiftNavButton } from '../../coupons/gift/GiftNavButton';
import { IncentiveNavButton } from './IncentiveNavButton';

interface Props {
  gift: CouponsV2AvailableGiftDTO;
}

export const GiftV2Button: React.FC<Props> = ({ gift }) => {
  const { dialogState, handleOpen: handleOpenDialog } = useNewDialogState();
  const { tenant } = useTenant();

  const getActiveEmployment = useActiveEmployment();
  const queryClient = useQueryClient();
  const { t } = useTranslation('benefitModule');
  const navigate = useNavigate();

  const refreshBenefits = useCallback(async () => {
    await queryClient.invalidateQueries({
      queryKey: ['tenants', getActiveEmployment().tenantId, 'employees', getActiveEmployment().id, 'benefits'],
    });
  }, [getActiveEmployment, queryClient]);

  const mutation = useMutation({
    mutationFn: async () => {
      await apis.couponsV2.claimCouponGift({
        tenantId: tenant!.id,
        employeeId: getActiveEmployment().id,
        couponsV2GiftId: gift.couponGiftId,
      });
    },
  });
  const handleClaimGift = useCallback(async () => {
    handleOpenDialog();
    void mutation.mutate();
  }, [mutation, handleOpenDialog]);

  const handleConfirm = useCallback(() => {
    navigate(`/couponsV2/explore-coupons?amount=${gift.amount}`);
  }, [gift, navigate]);

  const handleClose = useCallback(() => {
    void refreshBenefits();
    dialogState.handleClose();
  }, [dialogState, refreshBenefits]);

  const giftDialogState = useMemo(() => {
    return { ...dialogState, handleClose: handleClose };
  }, [dialogState, handleClose]);

  return (
    <>
      {gift.giftType === CouponsV2GiftDTOGiftTypeEnum.Incentive ? (
        <IncentiveNavButton onClick={handleClaimGift} />
      ) : (
        <GiftNavButton onClick={handleClaimGift} />
      )}
      <GiftModal
        gift={gift}
        dialogState={giftDialogState}
        infoMessage={t(
          gift.giftType === CouponsV2GiftDTOGiftTypeEnum.Incentive
            ? 'couponsV2Module:incentives.modalInfoMessage'
            : 'benefit.GIFTS.giftsModal.infoMessageCouponsV2Gift',
          { amount: gift.amount },
        )}
        isLoading={mutation.isPending}
        onConfirm={handleConfirm}
      />
    </>
  );
};
