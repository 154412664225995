import React from 'react';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useTranslation } from 'react-i18next';
import AppStoreIcon from './AppStoreIcon';
import PlayStoreIcon from './PlayStoreIcon';
import { Capacitor } from '@capacitor/core';

interface Props {
  iconsOnly?: boolean;
}

const AppPromotion: React.FC<Props> = ({ iconsOnly }) => {
  const { t } = useTranslation('appPromotion');

  if (Capacitor.isNativePlatform()) {
    return <> </>;
  }

  return (
    <Grid container mt={1} columnGap={1} rowGap={0.5} justifyContent="space-between">
      {!iconsOnly && (
        <Grid size={12}>
          <Typography variant="h6" textAlign="center">
            {t('getAppNow')}
          </Typography>
        </Grid>
      )}
      <Grid>
        <AppStoreIcon />
      </Grid>
      <Grid>
        <PlayStoreIcon />
      </Grid>
    </Grid>
  );
};

export default AppPromotion;
