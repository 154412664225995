/* tslint:disable */
/* eslint-disable */
/**
 * Probonio full API Docs
 * If you stumbled upon this page and want to integrate Probonio into your business software [click here!](/api/external)    If you\'re looking for more information about Probonio [head over here](https://probonio.de)    **This documentation describes all Endpoints of the Probonio API. It\'s created for experienced Probonio Developers only!**  
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CreateInternetReceiptDTO } from '../dto';
// @ts-ignore
import { IdentifierDTO } from '../dto';
// @ts-ignore
import { InternetReceiptRefundDTO } from '../dto';
// @ts-ignore
import { InternetReceiptTenantListDTO } from '../dto';
// @ts-ignore
import { UpdateInternetReceiptDTO } from '../dto';
// @ts-ignore
import { UploadLinkDTO } from '../dto';
/**
 * InternetApi - axios parameter creator
 * @export
 */
export const InternetApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary create a new receipt in progress
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {CreateInternetReceiptDTO} createInternetReceiptDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createReceipt: async (tenantId: string, employeeId: string, createInternetReceiptDTO: CreateInternetReceiptDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('createReceipt', 'tenantId', tenantId)
            // verify required parameter 'employeeId' is not null or undefined
            assertParamExists('createReceipt', 'employeeId', employeeId)
            // verify required parameter 'createInternetReceiptDTO' is not null or undefined
            assertParamExists('createReceipt', 'createInternetReceiptDTO', createInternetReceiptDTO)
            const localVarPath = `/tenants/{tenantId}/employees/{employeeId}/benefits/internet/receipts`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"employeeId"}}`, encodeURIComponent(String(employeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createInternetReceiptDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a form POST upload link for uploading the receipt image. Refer to https://docs.aws.amazon.com/AmazonS3/latest/API/sigv4-post-example.html on how to implement the form POST.
         * @summary create a presigned upload link
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUploadLink: async (tenantId: string, employeeId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('createUploadLink', 'tenantId', tenantId)
            // verify required parameter 'employeeId' is not null or undefined
            assertParamExists('createUploadLink', 'employeeId', employeeId)
            const localVarPath = `/tenants/{tenantId}/employees/{employeeId}/benefits/internet/receipts/createUploadLink`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"employeeId"}}`, encodeURIComponent(String(employeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete the receipt with the given ID. Approved receipts cannot be deleted.
         * @summary delete a single receipt
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} receiptId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteReceipt: async (tenantId: string, employeeId: string, receiptId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('deleteReceipt', 'tenantId', tenantId)
            // verify required parameter 'employeeId' is not null or undefined
            assertParamExists('deleteReceipt', 'employeeId', employeeId)
            // verify required parameter 'receiptId' is not null or undefined
            assertParamExists('deleteReceipt', 'receiptId', receiptId)
            const localVarPath = `/tenants/{tenantId}/employees/{employeeId}/benefits/internet/receipts/{receiptId}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"employeeId"}}`, encodeURIComponent(String(employeeId)))
                .replace(`{${"receiptId"}}`, encodeURIComponent(String(receiptId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary download the statement form for a receipt in progress
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} receiptId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadStatement: async (tenantId: string, employeeId: string, receiptId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('downloadStatement', 'tenantId', tenantId)
            // verify required parameter 'employeeId' is not null or undefined
            assertParamExists('downloadStatement', 'employeeId', employeeId)
            // verify required parameter 'receiptId' is not null or undefined
            assertParamExists('downloadStatement', 'receiptId', receiptId)
            const localVarPath = `/tenants/{tenantId}/employees/{employeeId}/benefits/internet/receipts/{receiptId}/statement`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"employeeId"}}`, encodeURIComponent(String(employeeId)))
                .replace(`{${"receiptId"}}`, encodeURIComponent(String(receiptId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get all internet receipts for a tenant
         * @param {string} month 
         * @param {string} tenantId 
         * @param {string} [department] 
         * @param {string} [filter] 
         * @param {Array<string>} [sortBy] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInternetReceipts: async (month: string, tenantId: string, department?: string, filter?: string, sortBy?: Array<string>, page?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'month' is not null or undefined
            assertParamExists('getInternetReceipts', 'month', month)
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('getInternetReceipts', 'tenantId', tenantId)
            const localVarPath = `/tenants/{tenantId}/benefits/internet/receipts`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (month !== undefined) {
                localVarQueryParameter['month'] = month;
            }

            if (department !== undefined) {
                localVarQueryParameter['department'] = department;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (sortBy) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Read the receipt of the requested month. The Internet benefit must be active for the employee at the given month.
         * @summary Gets the receipt with the latest completed Date
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} [month] 
         * @param {boolean} [inProgress] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReceiptByMonth: async (tenantId: string, employeeId: string, month?: string, inProgress?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('getReceiptByMonth', 'tenantId', tenantId)
            // verify required parameter 'employeeId' is not null or undefined
            assertParamExists('getReceiptByMonth', 'employeeId', employeeId)
            const localVarPath = `/tenants/{tenantId}/employees/{employeeId}/benefits/internet/receipts`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"employeeId"}}`, encodeURIComponent(String(employeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (month !== undefined) {
                localVarQueryParameter['month'] = month;
            }

            if (inProgress !== undefined) {
                localVarQueryParameter['inProgress'] = inProgress;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get csv with receipt details
         * @param {string} month 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReceiptDetailsExport: async (month: string, tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'month' is not null or undefined
            assertParamExists('getReceiptDetailsExport', 'month', month)
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('getReceiptDetailsExport', 'tenantId', tenantId)
            const localVarPath = `/tenants/{tenantId}/benefits/internet/reports/receiptDetails`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (month !== undefined) {
                localVarQueryParameter['month'] = month;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary send the statement form for a receipt in progress by mail
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} receiptId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendStatementByMail: async (tenantId: string, employeeId: string, receiptId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('sendStatementByMail', 'tenantId', tenantId)
            // verify required parameter 'employeeId' is not null or undefined
            assertParamExists('sendStatementByMail', 'employeeId', employeeId)
            // verify required parameter 'receiptId' is not null or undefined
            assertParamExists('sendStatementByMail', 'receiptId', receiptId)
            const localVarPath = `/tenants/{tenantId}/employees/{employeeId}/benefits/internet/receipts/{receiptId}/statement/sendMail`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"employeeId"}}`, encodeURIComponent(String(employeeId)))
                .replace(`{${"receiptId"}}`, encodeURIComponent(String(receiptId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates a receipt before it expires
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} receiptId 
         * @param {UpdateInternetReceiptDTO} updateInternetReceiptDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateExpirationDate: async (tenantId: string, employeeId: string, receiptId: string, updateInternetReceiptDTO: UpdateInternetReceiptDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('updateExpirationDate', 'tenantId', tenantId)
            // verify required parameter 'employeeId' is not null or undefined
            assertParamExists('updateExpirationDate', 'employeeId', employeeId)
            // verify required parameter 'receiptId' is not null or undefined
            assertParamExists('updateExpirationDate', 'receiptId', receiptId)
            // verify required parameter 'updateInternetReceiptDTO' is not null or undefined
            assertParamExists('updateExpirationDate', 'updateInternetReceiptDTO', updateInternetReceiptDTO)
            const localVarPath = `/tenants/{tenantId}/employees/{employeeId}/benefits/internet/receipts/{receiptId}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"employeeId"}}`, encodeURIComponent(String(employeeId)))
                .replace(`{${"receiptId"}}`, encodeURIComponent(String(receiptId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateInternetReceiptDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InternetApi - functional programming interface
 * @export
 */
export const InternetApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InternetApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary create a new receipt in progress
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {CreateInternetReceiptDTO} createInternetReceiptDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createReceipt(tenantId: string, employeeId: string, createInternetReceiptDTO: CreateInternetReceiptDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdentifierDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createReceipt(tenantId, employeeId, createInternetReceiptDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create a form POST upload link for uploading the receipt image. Refer to https://docs.aws.amazon.com/AmazonS3/latest/API/sigv4-post-example.html on how to implement the form POST.
         * @summary create a presigned upload link
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUploadLink(tenantId: string, employeeId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadLinkDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUploadLink(tenantId, employeeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete the receipt with the given ID. Approved receipts cannot be deleted.
         * @summary delete a single receipt
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} receiptId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteReceipt(tenantId: string, employeeId: string, receiptId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteReceipt(tenantId, employeeId, receiptId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary download the statement form for a receipt in progress
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} receiptId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadStatement(tenantId: string, employeeId: string, receiptId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadStatement(tenantId, employeeId, receiptId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get all internet receipts for a tenant
         * @param {string} month 
         * @param {string} tenantId 
         * @param {string} [department] 
         * @param {string} [filter] 
         * @param {Array<string>} [sortBy] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInternetReceipts(month: string, tenantId: string, department?: string, filter?: string, sortBy?: Array<string>, page?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternetReceiptTenantListDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInternetReceipts(month, tenantId, department, filter, sortBy, page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Read the receipt of the requested month. The Internet benefit must be active for the employee at the given month.
         * @summary Gets the receipt with the latest completed Date
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} [month] 
         * @param {boolean} [inProgress] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReceiptByMonth(tenantId: string, employeeId: string, month?: string, inProgress?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternetReceiptRefundDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReceiptByMonth(tenantId, employeeId, month, inProgress, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get csv with receipt details
         * @param {string} month 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReceiptDetailsExport(month: string, tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReceiptDetailsExport(month, tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary send the statement form for a receipt in progress by mail
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} receiptId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendStatementByMail(tenantId: string, employeeId: string, receiptId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendStatementByMail(tenantId, employeeId, receiptId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates a receipt before it expires
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} receiptId 
         * @param {UpdateInternetReceiptDTO} updateInternetReceiptDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateExpirationDate(tenantId: string, employeeId: string, receiptId: string, updateInternetReceiptDTO: UpdateInternetReceiptDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateExpirationDate(tenantId, employeeId, receiptId, updateInternetReceiptDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InternetApi - factory interface
 * @export
 */
export const InternetApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InternetApiFp(configuration)
    return {
        /**
         * 
         * @summary create a new receipt in progress
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {CreateInternetReceiptDTO} createInternetReceiptDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createReceipt(tenantId: string, employeeId: string, createInternetReceiptDTO: CreateInternetReceiptDTO, options?: any): AxiosPromise<IdentifierDTO> {
            return localVarFp.createReceipt(tenantId, employeeId, createInternetReceiptDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a form POST upload link for uploading the receipt image. Refer to https://docs.aws.amazon.com/AmazonS3/latest/API/sigv4-post-example.html on how to implement the form POST.
         * @summary create a presigned upload link
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUploadLink(tenantId: string, employeeId: string, options?: any): AxiosPromise<UploadLinkDTO> {
            return localVarFp.createUploadLink(tenantId, employeeId, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete the receipt with the given ID. Approved receipts cannot be deleted.
         * @summary delete a single receipt
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} receiptId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteReceipt(tenantId: string, employeeId: string, receiptId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteReceipt(tenantId, employeeId, receiptId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary download the statement form for a receipt in progress
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} receiptId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadStatement(tenantId: string, employeeId: string, receiptId: string, options?: any): AxiosPromise<any> {
            return localVarFp.downloadStatement(tenantId, employeeId, receiptId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get all internet receipts for a tenant
         * @param {string} month 
         * @param {string} tenantId 
         * @param {string} [department] 
         * @param {string} [filter] 
         * @param {Array<string>} [sortBy] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInternetReceipts(month: string, tenantId: string, department?: string, filter?: string, sortBy?: Array<string>, page?: number, pageSize?: number, options?: any): AxiosPromise<InternetReceiptTenantListDTO> {
            return localVarFp.getInternetReceipts(month, tenantId, department, filter, sortBy, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * Read the receipt of the requested month. The Internet benefit must be active for the employee at the given month.
         * @summary Gets the receipt with the latest completed Date
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} [month] 
         * @param {boolean} [inProgress] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReceiptByMonth(tenantId: string, employeeId: string, month?: string, inProgress?: boolean, options?: any): AxiosPromise<InternetReceiptRefundDTO> {
            return localVarFp.getReceiptByMonth(tenantId, employeeId, month, inProgress, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get csv with receipt details
         * @param {string} month 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReceiptDetailsExport(month: string, tenantId: string, options?: any): AxiosPromise<string> {
            return localVarFp.getReceiptDetailsExport(month, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary send the statement form for a receipt in progress by mail
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} receiptId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendStatementByMail(tenantId: string, employeeId: string, receiptId: string, options?: any): AxiosPromise<void> {
            return localVarFp.sendStatementByMail(tenantId, employeeId, receiptId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates a receipt before it expires
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} receiptId 
         * @param {UpdateInternetReceiptDTO} updateInternetReceiptDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateExpirationDate(tenantId: string, employeeId: string, receiptId: string, updateInternetReceiptDTO: UpdateInternetReceiptDTO, options?: any): AxiosPromise<void> {
            return localVarFp.updateExpirationDate(tenantId, employeeId, receiptId, updateInternetReceiptDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createReceipt operation in InternetApi.
 * @export
 * @interface InternetApiCreateReceiptRequest
 */
export interface InternetApiCreateReceiptRequest {
    /**
     * 
     * @type {string}
     * @memberof InternetApiCreateReceipt
     */
    readonly tenantId: string

    /**
     * 
     * @type {string}
     * @memberof InternetApiCreateReceipt
     */
    readonly employeeId: string

    /**
     * 
     * @type {CreateInternetReceiptDTO}
     * @memberof InternetApiCreateReceipt
     */
    readonly createInternetReceiptDTO: CreateInternetReceiptDTO
}

/**
 * Request parameters for createUploadLink operation in InternetApi.
 * @export
 * @interface InternetApiCreateUploadLinkRequest
 */
export interface InternetApiCreateUploadLinkRequest {
    /**
     * 
     * @type {string}
     * @memberof InternetApiCreateUploadLink
     */
    readonly tenantId: string

    /**
     * 
     * @type {string}
     * @memberof InternetApiCreateUploadLink
     */
    readonly employeeId: string
}

/**
 * Request parameters for deleteReceipt operation in InternetApi.
 * @export
 * @interface InternetApiDeleteReceiptRequest
 */
export interface InternetApiDeleteReceiptRequest {
    /**
     * 
     * @type {string}
     * @memberof InternetApiDeleteReceipt
     */
    readonly tenantId: string

    /**
     * 
     * @type {string}
     * @memberof InternetApiDeleteReceipt
     */
    readonly employeeId: string

    /**
     * 
     * @type {string}
     * @memberof InternetApiDeleteReceipt
     */
    readonly receiptId: string
}

/**
 * Request parameters for downloadStatement operation in InternetApi.
 * @export
 * @interface InternetApiDownloadStatementRequest
 */
export interface InternetApiDownloadStatementRequest {
    /**
     * 
     * @type {string}
     * @memberof InternetApiDownloadStatement
     */
    readonly tenantId: string

    /**
     * 
     * @type {string}
     * @memberof InternetApiDownloadStatement
     */
    readonly employeeId: string

    /**
     * 
     * @type {string}
     * @memberof InternetApiDownloadStatement
     */
    readonly receiptId: string
}

/**
 * Request parameters for getInternetReceipts operation in InternetApi.
 * @export
 * @interface InternetApiGetInternetReceiptsRequest
 */
export interface InternetApiGetInternetReceiptsRequest {
    /**
     * 
     * @type {string}
     * @memberof InternetApiGetInternetReceipts
     */
    readonly month: string

    /**
     * 
     * @type {string}
     * @memberof InternetApiGetInternetReceipts
     */
    readonly tenantId: string

    /**
     * 
     * @type {string}
     * @memberof InternetApiGetInternetReceipts
     */
    readonly department?: string

    /**
     * 
     * @type {string}
     * @memberof InternetApiGetInternetReceipts
     */
    readonly filter?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof InternetApiGetInternetReceipts
     */
    readonly sortBy?: Array<string>

    /**
     * 
     * @type {number}
     * @memberof InternetApiGetInternetReceipts
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof InternetApiGetInternetReceipts
     */
    readonly pageSize?: number
}

/**
 * Request parameters for getReceiptByMonth operation in InternetApi.
 * @export
 * @interface InternetApiGetReceiptByMonthRequest
 */
export interface InternetApiGetReceiptByMonthRequest {
    /**
     * 
     * @type {string}
     * @memberof InternetApiGetReceiptByMonth
     */
    readonly tenantId: string

    /**
     * 
     * @type {string}
     * @memberof InternetApiGetReceiptByMonth
     */
    readonly employeeId: string

    /**
     * 
     * @type {string}
     * @memberof InternetApiGetReceiptByMonth
     */
    readonly month?: string

    /**
     * 
     * @type {boolean}
     * @memberof InternetApiGetReceiptByMonth
     */
    readonly inProgress?: boolean
}

/**
 * Request parameters for getReceiptDetailsExport operation in InternetApi.
 * @export
 * @interface InternetApiGetReceiptDetailsExportRequest
 */
export interface InternetApiGetReceiptDetailsExportRequest {
    /**
     * 
     * @type {string}
     * @memberof InternetApiGetReceiptDetailsExport
     */
    readonly month: string

    /**
     * 
     * @type {string}
     * @memberof InternetApiGetReceiptDetailsExport
     */
    readonly tenantId: string
}

/**
 * Request parameters for sendStatementByMail operation in InternetApi.
 * @export
 * @interface InternetApiSendStatementByMailRequest
 */
export interface InternetApiSendStatementByMailRequest {
    /**
     * 
     * @type {string}
     * @memberof InternetApiSendStatementByMail
     */
    readonly tenantId: string

    /**
     * 
     * @type {string}
     * @memberof InternetApiSendStatementByMail
     */
    readonly employeeId: string

    /**
     * 
     * @type {string}
     * @memberof InternetApiSendStatementByMail
     */
    readonly receiptId: string
}

/**
 * Request parameters for updateExpirationDate operation in InternetApi.
 * @export
 * @interface InternetApiUpdateExpirationDateRequest
 */
export interface InternetApiUpdateExpirationDateRequest {
    /**
     * 
     * @type {string}
     * @memberof InternetApiUpdateExpirationDate
     */
    readonly tenantId: string

    /**
     * 
     * @type {string}
     * @memberof InternetApiUpdateExpirationDate
     */
    readonly employeeId: string

    /**
     * 
     * @type {string}
     * @memberof InternetApiUpdateExpirationDate
     */
    readonly receiptId: string

    /**
     * 
     * @type {UpdateInternetReceiptDTO}
     * @memberof InternetApiUpdateExpirationDate
     */
    readonly updateInternetReceiptDTO: UpdateInternetReceiptDTO
}

/**
 * InternetApi - object-oriented interface
 * @export
 * @class InternetApi
 * @extends {BaseAPI}
 */
export class InternetApi extends BaseAPI {
    /**
     * 
     * @summary create a new receipt in progress
     * @param {InternetApiCreateReceiptRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternetApi
     */
    public createReceipt(requestParameters: InternetApiCreateReceiptRequest, options?: AxiosRequestConfig) {
        return InternetApiFp(this.configuration).createReceipt(requestParameters.tenantId, requestParameters.employeeId, requestParameters.createInternetReceiptDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a form POST upload link for uploading the receipt image. Refer to https://docs.aws.amazon.com/AmazonS3/latest/API/sigv4-post-example.html on how to implement the form POST.
     * @summary create a presigned upload link
     * @param {InternetApiCreateUploadLinkRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternetApi
     */
    public createUploadLink(requestParameters: InternetApiCreateUploadLinkRequest, options?: AxiosRequestConfig) {
        return InternetApiFp(this.configuration).createUploadLink(requestParameters.tenantId, requestParameters.employeeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete the receipt with the given ID. Approved receipts cannot be deleted.
     * @summary delete a single receipt
     * @param {InternetApiDeleteReceiptRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternetApi
     */
    public deleteReceipt(requestParameters: InternetApiDeleteReceiptRequest, options?: AxiosRequestConfig) {
        return InternetApiFp(this.configuration).deleteReceipt(requestParameters.tenantId, requestParameters.employeeId, requestParameters.receiptId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary download the statement form for a receipt in progress
     * @param {InternetApiDownloadStatementRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternetApi
     */
    public downloadStatement(requestParameters: InternetApiDownloadStatementRequest, options?: AxiosRequestConfig) {
        return InternetApiFp(this.configuration).downloadStatement(requestParameters.tenantId, requestParameters.employeeId, requestParameters.receiptId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get all internet receipts for a tenant
     * @param {InternetApiGetInternetReceiptsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternetApi
     */
    public getInternetReceipts(requestParameters: InternetApiGetInternetReceiptsRequest, options?: AxiosRequestConfig) {
        return InternetApiFp(this.configuration).getInternetReceipts(requestParameters.month, requestParameters.tenantId, requestParameters.department, requestParameters.filter, requestParameters.sortBy, requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Read the receipt of the requested month. The Internet benefit must be active for the employee at the given month.
     * @summary Gets the receipt with the latest completed Date
     * @param {InternetApiGetReceiptByMonthRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternetApi
     */
    public getReceiptByMonth(requestParameters: InternetApiGetReceiptByMonthRequest, options?: AxiosRequestConfig) {
        return InternetApiFp(this.configuration).getReceiptByMonth(requestParameters.tenantId, requestParameters.employeeId, requestParameters.month, requestParameters.inProgress, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get csv with receipt details
     * @param {InternetApiGetReceiptDetailsExportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternetApi
     */
    public getReceiptDetailsExport(requestParameters: InternetApiGetReceiptDetailsExportRequest, options?: AxiosRequestConfig) {
        return InternetApiFp(this.configuration).getReceiptDetailsExport(requestParameters.month, requestParameters.tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary send the statement form for a receipt in progress by mail
     * @param {InternetApiSendStatementByMailRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternetApi
     */
    public sendStatementByMail(requestParameters: InternetApiSendStatementByMailRequest, options?: AxiosRequestConfig) {
        return InternetApiFp(this.configuration).sendStatementByMail(requestParameters.tenantId, requestParameters.employeeId, requestParameters.receiptId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates a receipt before it expires
     * @param {InternetApiUpdateExpirationDateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternetApi
     */
    public updateExpirationDate(requestParameters: InternetApiUpdateExpirationDateRequest, options?: AxiosRequestConfig) {
        return InternetApiFp(this.configuration).updateExpirationDate(requestParameters.tenantId, requestParameters.employeeId, requestParameters.receiptId, requestParameters.updateInternetReceiptDTO, options).then((request) => request(this.axios, this.basePath));
    }
}
