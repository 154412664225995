import { Route, Routes } from 'react-router-dom';
import FlexInfoPage from './FlexInfoPage';

const FlexRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<FlexInfoPage />} />
    </Routes>
  );
};

export default FlexRoutes;
