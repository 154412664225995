import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import NavigationButton from '../../../component/button/NavigationButton';
import { PresentIcon } from './PresentIcon';

interface Props {
  onClick: () => void;
}

export const IncentiveNavButton: React.FC<Props> = ({ onClick }) => {
  const { t } = useTranslation('couponsV2Module');

  return (
    <Box sx={{ marginTop: 1 }} color="primary">
      <NavigationButton onClick={onClick} iconRight={null} data-test-id="incentive-button" iconLeft={<PresentIcon fontSize="large" />}>
        <Typography variant="h3" color="text.primary">
          {t('incentives.buttonTitle')}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {t('incentives.buttonSubtitle')}
        </Typography>
      </NavigationButton>
    </Box>
  );
};
