import { AvailableGiftDTO } from 'probonio-shared-ui/api';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { BackIconButton } from '../../component/button/BackIconButton';
import { AppToolbar } from '../../component/layout/AppToolbar';
import { MobileLayout } from '../../component/layout/MobileLayout';
import { ExploreCoupons, useAvailableCoupons } from '../../module/coupons';
import { setCategory, setCategoryScrollPosition, setScrollPosition } from '../../module/coupons/redux';
import { useBackNavigator } from '../../module/navigation/BackNavigator';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { ExploreCouponsState } from '../../module/couponsShared/explore/exploreCouponsState';
import { useCouponDefinitionListQuery } from '../../module/coupons/couponQueries';
import { Box, CircularProgress } from '@mui/material';

const ExploreCouponsPage: React.FC = () => {
  const { t } = useTranslation('couponsModule');
  const { availableCoupons, couponsQuery } = useAvailableCoupons();
  const location = useLocation();
  const gift = location.state as AvailableGiftDTO;
  const backNavigator = useBackNavigator();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const savedScrollPosition = useAppSelector(state => state.coupons.scrollPosition);
  const categoryScrollPosition = useAppSelector(state => state.coupons.categoryScrollPosition);
  const selectedCategory = useAppSelector(state => state.coupons.selectedCategory);
  const scrollRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (couponsQuery.isLoading || !couponsQuery.data?.benefit) {
      return;
    }
    if (savedScrollPosition && scrollRef.current) {
      scrollRef.current.scrollTo(0, savedScrollPosition);
    }
  }, [couponsQuery.data, couponsQuery.isLoading, savedScrollPosition]);

  const handleNavigateToDetails = useCallback(
    (couponDefinitionId: string) => {
      if (scrollRef.current) {
        dispatch(setScrollPosition(scrollRef.current.scrollTop));
      }
      navigate(`/coupons/explore-coupons/${couponDefinitionId}`, { state: gift });
    },
    [dispatch, gift, navigate],
  );

  const exploreCouponsState = useMemo(
    (): ExploreCouponsState => ({
      selectedCategory,
      categoryScrollPosition,
      setCategory: category => dispatch(setCategory(category)),
      setCategoryScrollPosition: categoryScrollPosition => dispatch(setCategoryScrollPosition(categoryScrollPosition)),
    }),
    [categoryScrollPosition, dispatch, selectedCategory],
  );

  const variation = gift ? gift.amount : availableCoupons?.couponMonthlyBudget;

  const { data: couponDefinitions } = useCouponDefinitionListQuery({
    variation,
    includeCombinations: true,
  });

  return (
    <MobileLayout
      header={<AppToolbar title={t('exploreCoupons.title')} backButton={<BackIconButton onClick={backNavigator.back} />} />}
      scrollRef={scrollRef}
    >
      {!couponsQuery.isLoading && couponDefinitions ? (
        <ExploreCoupons state={exploreCouponsState} couponDefinitions={couponDefinitions} onOpenCouponDetails={handleNavigateToDetails} />
      ) : (
        <Box textAlign="center" padding={5}>
          <CircularProgress />
        </Box>
      )}
    </MobileLayout>
  );
};
export default ExploreCouponsPage;
