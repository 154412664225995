import { Box, Button, Typography } from '@mui/material';
import Confetti from 'canvas-confetti';
import { AvailableGiftDTO, CouponsV2AvailableGiftDTO, CouponsV2GiftDTOGiftTypeEnum } from 'probonio-shared-ui/api';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { BasicDialog, DialogState } from '../../../component/dialog/BasicDialog';
import { LoadingButton } from 'probonio-shared-ui/component/button';

interface Props {
  gift: AvailableGiftDTO | CouponsV2AvailableGiftDTO;
  dialogState: DialogState;
  infoMessage: string;
  isLoading?: boolean;
  onConfirm: () => void;
}

const GiftModal: React.FC<Props> = ({ gift, dialogState, infoMessage, isLoading, onConfirm }) => {
  const { t } = useTranslation('benefitModule');

  const confetti = useMemo(() => Confetti.create(undefined, { resize: true }), []);

  useEffect(() => {
    if (dialogState.isOpen) {
      void confetti({
        particleCount: 300,
        spread: 360,
        scalar: 2,
        zIndex: 1301,
        decay: 0.9,
        origin: { x: 0.5, y: 0.15 },
        startVelocity: 20,
        disableForReducedMotion: true,
      });
      setTimeout(() => confetti.reset(), 5500);
    }
  }, [confetti, dialogState.isOpen]);

  const isIncentive = 'incentiveOccasion' in gift && gift.giftType === CouponsV2GiftDTOGiftTypeEnum.Incentive;

  return (
    <BasicDialog
      dialogState={dialogState}
      title={
        <Typography variant="h1" component="span" data-test-id="gift-modal-title">
          {isIncentive
            ? t([
                `couponsV2Module:incentives.giftOccasionTitle.${gift.incentiveOccasion}`,
                'couponsV2Module:incentives.giftOccasionTitle.DEFAULT',
              ])
            : t([`benefit.GIFTS.giftsModal.giftOccasionTitle.${gift.occasion}`, 'benefit.GIFTS.giftsModal.giftOccasionTitle.DEFAULT'])}
        </Typography>
      }
      hideCloseIcon
      maxWidth="xs"
      sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
      content={
        <Box>
          <Typography whiteSpace="pre-line" marginBottom={1} textAlign="center">
            {gift.description}
          </Typography>
          <Typography fontStyle="italic" marginBottom={1} textAlign="center">
            {infoMessage}
          </Typography>
        </Box>
      }
      actions={
        <Box display="flex" flexDirection="column">
          <LoadingButton
            loading={isLoading}
            variant="contained"
            onClick={onConfirm}
            sx={{ marginBottom: 0.5 }}
            data-test-id="gift-modal-select-voucher"
          >
            {t(isIncentive ? 'couponsV2Module:incentives.redeem' : 'benefit.GIFTS.giftsModal.redeem')}
          </LoadingButton>
          <Button
            variant="text"
            color="primary"
            onClick={dialogState.handleClose}
            sx={{ marginBottom: 0.5 }}
            data-test-id="gift-modal-select-later"
            disabled={isLoading}
          >
            {t(isIncentive ? 'couponsV2Module:incentives.cancelRedeem' : 'benefit.GIFTS.giftsModal.cancelRedeem')}
          </Button>
        </Box>
      }
    />
  );
};

export default GiftModal;
