import CheckIcon from '@mui/icons-material/Check';
import { Box, CircularProgress, Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import {
  BenefitDTOBenefitEnum,
  LunchReceiptRefundDTOReceiptTypeEnum,
  LunchReceiptRefundDTOStatusEnum,
  UpdateLunchReceiptDTO,
} from 'probonio-shared-ui/api';
import { resizeImage } from 'probonio-shared-ui/component/imageResizer/ImageResizer';
import { apis, useEmployeeQuery } from 'probonio-shared-ui/module/api';
import { useActiveEmployment } from 'probonio-shared-ui/module/me';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { CloseIconButton } from '../../component/button/CloseIconButton';
import { LoadingLinkIconButton } from '../../component/button/LoadingLinkIconButton';
import { ImageFile } from '../../component/camera/ImageFile';
import { MobileLayout } from '../../component/layout';
import { AppToolbar } from '../../component/layout/AppToolbar';
import { EditReceiptForm, EditReceiptFormValues, mapFromReceipt, mapToUpdateRequest } from '../../module/benefit/receipt';
import { uploadLunchImageFile } from '../../module/lunch';
import { LUNCH_THUMBNAIL_SIZE, MIME_TYPE_JPEG } from '../../module/lunch/lunchConstants';
import { UploadLinks } from '../../module/lunch/lunchFileUpload';
import { useLunchPeriods } from '../../module/lunch/LunchPeriodsContext';
import { useBackNavigator } from '../../module/navigation/BackNavigator';

type MappedDTO = Omit<UpdateLunchReceiptDTO, 'imageKey'>;
const INITIAL_VALUES: EditReceiptFormValues = {
  lunchReceiptType: LunchReceiptRefundDTOReceiptTypeEnum.Single,
  total: 0,
  date: new Date(),
  comment: '',
};

const EditReceiptPage: React.FC = () => {
  const { lunchPeriod, receiptId } = useParams();
  const { t } = useTranslation('benefitModule');
  const { enqueueSnackbar } = useSnackbar();
  const getActiveEmployment = useActiveEmployment();
  const { refetchLunch, previousPeriod, selectedPeriod } = useLunchPeriods();
  const backNavigator = useBackNavigator();

  const { data: receipt, isLoading } = useEmployeeQuery(
    ['benefits', 'lunch', 'periods', 'receipts', receiptId, { withImage: true }, apis.lunch.getReceipt.name],
    params => apis.lunch.getReceipt({ ...params, receiptId: receiptId!, withImage: true }).then(res => res.data),
  );

  const [values, setValues] = useState<EditReceiptFormValues>(
    receipt ? mapFromReceipt(receipt, BenefitDTOBenefitEnum.Lunch) : INITIAL_VALUES,
  );
  useEffect(() => {
    if (receipt) {
      setValues(mapFromReceipt(receipt, BenefitDTOBenefitEnum.Lunch));
    }
  }, [receipt]);

  const mutation = useMutation({
    mutationFn: async (dto: MappedDTO) => {
      let imageKeys: UploadLinks | undefined;
      if (values.image instanceof ImageFile) {
        const imageBlob = await values.image.getBlob();
        const mimeType = values.image.mimetype;

        let thumbnailBlob;
        if (!values.pdf) {
          const imageURI = await values.image.getURI();
          const thumbnail = await resizeImage(imageURI, LUNCH_THUMBNAIL_SIZE, MIME_TYPE_JPEG);
          thumbnailBlob = thumbnail.blob;
        }

        imageKeys = await uploadLunchImageFile(
          getActiveEmployment().tenantId,
          getActiveEmployment().id,
          mimeType,
          imageBlob,
          values.pdf,
          thumbnailBlob,
        );
      }
      const fullDTO = { ...dto, ...imageKeys };
      return apis.lunch.updateReceipt({
        tenantId: getActiveEmployment().tenantId,
        employeeId: getActiveEmployment().id,
        receiptId: receiptId!,
        updateLunchReceiptDTO: fullDTO,
      });
    },

    onSuccess: () => {
      refetchLunch();
      enqueueSnackbar(t('benefit.LUNCH.updateReceiptSuccess'), { variant: 'success' });
      backNavigator.back();
    },
  });

  const handleSave = useCallback<React.FormEventHandler<HTMLFormElement>>(
    ev => {
      ev.preventDefault();
      const mappedRequest = mapToUpdateRequest(receipt!, values, BenefitDTOBenefitEnum.Lunch) as UpdateLunchReceiptDTO;
      if (Object.values(mappedRequest).every(value => value === undefined) && !(values.image instanceof ImageFile)) {
        enqueueSnackbar(t('benefit.LUNCH.noChanges'), { variant: 'warning' });
        return;
      }
      mutation.mutate(mappedRequest);
    },
    [receipt, values, enqueueSnackbar, t, mutation],
  );

  const isLockedForEditing = receipt?.status === LunchReceiptRefundDTOStatusEnum.Approved;

  return (
    <form onSubmit={handleSave} style={{ height: '100%' }}>
      <MobileLayout
        paperBackground
        header={
          <AppToolbar
            title={t('benefit.LUNCH.editReceipt')}
            backButton={<CloseIconButton onClick={backNavigator.back} />}
            actionButton={
              isLockedForEditing ? undefined : (
                <LoadingLinkIconButton type="submit" loading={mutation.isPending}>
                  <CheckIcon />
                </LoadingLinkIconButton>
              )
            }
          />
        }
      >
        <Box sx={{ padding: 1 }}>
          {isLockedForEditing ? (
            <Typography>{t('benefit.RECREATION.receiptNotEditable')}</Typography>
          ) : (
            <EditReceiptForm
              benefit={BenefitDTOBenefitEnum.Lunch}
              previousPeriod={previousPeriod}
              benefitPeriod={lunchPeriod!}
              benefitPeriodDTO={selectedPeriod!}
              values={values}
              onChange={setValues}
              disabled={mutation.isPending || isLoading}
            />
          )}
          {isLoading && <CircularProgress />}
        </Box>
      </MobileLayout>
    </form>
  );
};

export default EditReceiptPage;
