import { UseQueryResult } from '@tanstack/react-query';
import { DateTime } from 'luxon';
import { InternetReceiptRefundDTO } from 'probonio-shared-ui/api';
import { apis, useEmployeeQuery } from 'probonio-shared-ui/module/api';

export function useInternetCurrentReceipt(): UseQueryResult<InternetReceiptRefundDTO, Error> {
  return useEmployeeQuery(['benefits', 'internet', 'receipts', apis.internet.getReceiptByMonth.name], params =>
    apis.internet
      .getReceiptByMonth({
        ...params,
        month: DateTime.now().toFormat('yyyy-MM'),
      })
      .then(res => {
        return res.data;
      }),
  );
}
