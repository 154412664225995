import { AuthorizationRequestHandler, DefaultCrypto, RedirectRequestHandler } from '@openid/appauth';
import { EndSessionRequestHandler, NoHashQueryStringUtils, RedirectEndSessionRequestHandler } from '@tiffinger-thiel/appauth-react';
import { AuthLocalStorageBackend } from 'probonio-shared-ui/module/auth';

export function createDesktopAuthHandlers(): {
  authHandler: AuthorizationRequestHandler;
  endSessionHandler: EndSessionRequestHandler;
} {
  const authHandler = new RedirectRequestHandler(
    new AuthLocalStorageBackend(),
    new NoHashQueryStringUtils(),
    window.location,
    new DefaultCrypto(),
  );
  const endSessionHandler = new RedirectEndSessionRequestHandler(
    new AuthLocalStorageBackend(),
    new NoHashQueryStringUtils(),
    window.location,
  );

  return { authHandler, endSessionHandler };
}
