import { BikeContractDTO } from 'probonio-shared-ui/api';
import { apis, useEmployeeQuery } from 'probonio-shared-ui/module/api';

export function useCurrentBikeContract(): { contract: BikeContractDTO | undefined; isLoading: boolean } {
  const { data: contracts, isLoading } = useEmployeeQuery(['benefits', 'bike', 'contracts', { page: 0, pageSize: 1 }], params =>
    apis.bike.listContracts({ ...params, page: 0, pageSize: 1 }).then(res => res.data),
  );

  return { contract: contracts?.results[0], isLoading };
}
