import { Box, Typography, useTheme } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useTranslation } from 'react-i18next';
import { CouponItemBackground } from './CouponItemBackground';
import { CouponItemDivider } from './CouponItemDivider';

interface CouponStatusProps {
  isCouponAvailable?: boolean;
  availableAmount: number;
  onClick: () => void;
}

export const CouponsStatusLayout: React.FC<CouponStatusProps> = ({ availableAmount, isCouponAvailable, onClick }) => {
  const theme = useTheme();
  const { t } = useTranslation('couponsModule');
  return (
    <Box flexDirection={'column'} sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
      <CouponItemBackground
        container
        columns={15}
        onClick={onClick}
        marginX={'auto'}
        sx={{
          '&::after': { backgroundColor: 'background.paper' },
          '&::before': { backgroundColor: 'background.paper' },
          '&:hover': { cursor: 'pointer' },
          backgroundColor: isCouponAvailable ? theme.palette.primary.main : theme.palette.greyBackground,
          maxWidth: 300,
          height: 65,
          color: isCouponAvailable ? theme.palette.primary.contrastText : 'text.secondary',
          padding: 0,
          paddingX: 1,
          marginBottom: 0.25,
        }}
        data-test-id="coupon-status-content"
      >
        {/* Coupon Content */}
        <Grid size={8} justifyContent={'center'} sx={{ display: 'flex', alignContent: 'center' }} paddingX={2}>
          {isCouponAvailable ? (
            <Typography
              variant="extraBig"
              sx={{
                color: theme.palette.primary.contrastText,
                fontSize: '25px',
                paddingRight: theme.spacing(0.5),
              }}
            >
              {t('coupons.textCouponAvailability')}
            </Typography>
          ) : (
            <Typography
              variant="extraBig"
              sx={{
                color: 'text.secondary',
                fontSize: '25px',
                paddingRight: theme.spacing(0.5),
                paddingBottom: theme.spacing(0.3),
              }}
            >
              {t('coupons.textCouponAvailability')}
            </Typography>
          )}
        </Grid>
        <Grid justifyContent={'center'} size={1}>
          <CouponItemDivider broken={!isCouponAvailable} />
        </Grid>
        {isCouponAvailable ? (
          <Grid size={6} justifyContent={'center'} flexDirection="row" sx={{ display: 'flex', alignContent: 'center' }}>
            <Typography variant="extraBig" sx={{ color: theme.palette.primary.contrastText }}>
              {t('common:moneyFloat', {
                money: availableAmount,
              })}
            </Typography>
          </Grid>
        ) : (
          <Grid
            size={6}
            justifyContent={'center'}
            flexDirection="row"
            sx={{ display: 'flex', alignContent: 'center', paddingBottom: theme.spacing(0.4) }}
          >
            <Typography variant="extraBig" sx={{ color: 'text.secondary' }}>
              {t('common:moneyFloat', {
                money: availableAmount,
              })}
            </Typography>
          </Grid>
        )}
      </CouponItemBackground>
    </Box>
  );
};

export default CouponsStatusLayout;
