/* tslint:disable */
/* eslint-disable */
/**
 * Probonio full API Docs
 * If you stumbled upon this page and want to integrate Probonio into your business software [click here!](/api/external)    If you\'re looking for more information about Probonio [head over here](https://probonio.de)    **This documentation describes all Endpoints of the Probonio API. It\'s created for experienced Probonio Developers only!**  
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CreateRecreationReceiptDTO } from '../dto';
// @ts-ignore
import { IdentifierDTO } from '../dto';
// @ts-ignore
import { RecreationPeriodDTO } from '../dto';
// @ts-ignore
import { RecreationPeriodsDTO } from '../dto';
// @ts-ignore
import { RecreationReceiptDTO } from '../dto';
// @ts-ignore
import { RecreationReceiptTenantListDTO } from '../dto';
// @ts-ignore
import { RecreationReceiptsDTO } from '../dto';
// @ts-ignore
import { UpdateRecreationReceiptDTO } from '../dto';
// @ts-ignore
import { UploadLinkDTO } from '../dto';
/**
 * RecreationApi - axios parameter creator
 * @export
 */
export const RecreationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Add a receipt.
         * @summary add a receipt
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {CreateRecreationReceiptDTO} createRecreationReceiptDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createReceipt: async (tenantId: string, employeeId: string, createRecreationReceiptDTO: CreateRecreationReceiptDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('createReceipt', 'tenantId', tenantId)
            // verify required parameter 'employeeId' is not null or undefined
            assertParamExists('createReceipt', 'employeeId', employeeId)
            // verify required parameter 'createRecreationReceiptDTO' is not null or undefined
            assertParamExists('createReceipt', 'createRecreationReceiptDTO', createRecreationReceiptDTO)
            const localVarPath = `/tenants/{tenantId}/employees/{employeeId}/benefits/recreation/receipts`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"employeeId"}}`, encodeURIComponent(String(employeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createRecreationReceiptDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a form POST upload link for uploading the receipt image. Refer to https://docs.aws.amazon.com/AmazonS3/latest/API/sigv4-post-example.html on how to implement the form POST.
         * @summary create a presigned upload link
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} [contentType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUploadLink: async (tenantId: string, employeeId: string, contentType?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('createUploadLink', 'tenantId', tenantId)
            // verify required parameter 'employeeId' is not null or undefined
            assertParamExists('createUploadLink', 'employeeId', employeeId)
            const localVarPath = `/tenants/{tenantId}/employees/{employeeId}/benefits/recreation/receipts/createUploadLink`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"employeeId"}}`, encodeURIComponent(String(employeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (contentType !== undefined) {
                localVarQueryParameter['contentType'] = contentType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete the receipt with the given ID. Approved receipts cannot be deleted.
         * @summary delete a single receipt
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} receiptId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteReceipt: async (tenantId: string, employeeId: string, receiptId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('deleteReceipt', 'tenantId', tenantId)
            // verify required parameter 'employeeId' is not null or undefined
            assertParamExists('deleteReceipt', 'employeeId', employeeId)
            // verify required parameter 'receiptId' is not null or undefined
            assertParamExists('deleteReceipt', 'receiptId', receiptId)
            const localVarPath = `/tenants/{tenantId}/employees/{employeeId}/benefits/recreation/receipts/{receiptId}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"employeeId"}}`, encodeURIComponent(String(employeeId)))
                .replace(`{${"receiptId"}}`, encodeURIComponent(String(receiptId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Read the current recreation period. The period will only exist if the recreation benefit is activated for the current user within the current month. Returns the active recreation period of the current user.
         * @summary read the current recreation period
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentPeriod: async (tenantId: string, employeeId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('getCurrentPeriod', 'tenantId', tenantId)
            // verify required parameter 'employeeId' is not null or undefined
            assertParamExists('getCurrentPeriod', 'employeeId', employeeId)
            const localVarPath = `/tenants/{tenantId}/employees/{employeeId}/benefits/recreation/periods/current`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"employeeId"}}`, encodeURIComponent(String(employeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Read the receipts of the requested period. The Recreation benefit must be active for the employee at the given month.
         * @summary read the receipts of the requested period
         * @param {string} year 
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPeriodReceipts: async (year: string, tenantId: string, employeeId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'year' is not null or undefined
            assertParamExists('getPeriodReceipts', 'year', year)
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('getPeriodReceipts', 'tenantId', tenantId)
            // verify required parameter 'employeeId' is not null or undefined
            assertParamExists('getPeriodReceipts', 'employeeId', employeeId)
            const localVarPath = `/tenants/{tenantId}/employees/{employeeId}/benefits/recreation/receipts`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"employeeId"}}`, encodeURIComponent(String(employeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (year !== undefined) {
                localVarQueryParameter['year'] = year;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the periods in a range.
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} startYear 
         * @param {string} endYear 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPeriods: async (tenantId: string, employeeId: string, startYear: string, endYear: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('getPeriods', 'tenantId', tenantId)
            // verify required parameter 'employeeId' is not null or undefined
            assertParamExists('getPeriods', 'employeeId', employeeId)
            // verify required parameter 'startYear' is not null or undefined
            assertParamExists('getPeriods', 'startYear', startYear)
            // verify required parameter 'endYear' is not null or undefined
            assertParamExists('getPeriods', 'endYear', endYear)
            const localVarPath = `/tenants/{tenantId}/employees/{employeeId}/benefits/recreation/periods`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"employeeId"}}`, encodeURIComponent(String(employeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (startYear !== undefined) {
                localVarQueryParameter['startYear'] = startYear;
            }

            if (endYear !== undefined) {
                localVarQueryParameter['endYear'] = endYear;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Read a receipt.
         * @summary read a single receipt
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} receiptId 
         * @param {boolean} [withImage] if true, imageURL will be populated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReceipt: async (tenantId: string, employeeId: string, receiptId: string, withImage?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('getReceipt', 'tenantId', tenantId)
            // verify required parameter 'employeeId' is not null or undefined
            assertParamExists('getReceipt', 'employeeId', employeeId)
            // verify required parameter 'receiptId' is not null or undefined
            assertParamExists('getReceipt', 'receiptId', receiptId)
            const localVarPath = `/tenants/{tenantId}/employees/{employeeId}/benefits/recreation/receipts/{receiptId}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"employeeId"}}`, encodeURIComponent(String(employeeId)))
                .replace(`{${"receiptId"}}`, encodeURIComponent(String(receiptId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (withImage !== undefined) {
                localVarQueryParameter['withImage'] = withImage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get csv with receipt details
         * @param {string} month 
         * @param {string} tenantId 
         * @param {string} [department] 
         * @param {string} [filter] 
         * @param {Array<string>} [sortBy] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReceiptDetailsExport: async (month: string, tenantId: string, department?: string, filter?: string, sortBy?: Array<string>, page?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'month' is not null or undefined
            assertParamExists('getReceiptDetailsExport', 'month', month)
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('getReceiptDetailsExport', 'tenantId', tenantId)
            const localVarPath = `/tenants/{tenantId}/benefits/recreation/reports/receiptDetails`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (month !== undefined) {
                localVarQueryParameter['month'] = month;
            }

            if (department !== undefined) {
                localVarQueryParameter['department'] = department;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (sortBy) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get all recreation receipts for a tenant
         * @param {string} month 
         * @param {string} tenantId 
         * @param {string} [department] 
         * @param {string} [filter] 
         * @param {Array<string>} [sortBy] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecreationReceipts: async (month: string, tenantId: string, department?: string, filter?: string, sortBy?: Array<string>, page?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'month' is not null or undefined
            assertParamExists('getRecreationReceipts', 'month', month)
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('getRecreationReceipts', 'tenantId', tenantId)
            const localVarPath = `/tenants/{tenantId}/benefits/recreation/receipts`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (month !== undefined) {
                localVarQueryParameter['month'] = month;
            }

            if (department !== undefined) {
                localVarQueryParameter['department'] = department;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (sortBy) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a receipt.
         * @summary update a single receipt
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} receiptId 
         * @param {UpdateRecreationReceiptDTO} updateRecreationReceiptDTO 
         * @param {boolean} [isAudit] 
         * @param {boolean} [isTenantAudit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateReceipt: async (tenantId: string, employeeId: string, receiptId: string, updateRecreationReceiptDTO: UpdateRecreationReceiptDTO, isAudit?: boolean, isTenantAudit?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('updateReceipt', 'tenantId', tenantId)
            // verify required parameter 'employeeId' is not null or undefined
            assertParamExists('updateReceipt', 'employeeId', employeeId)
            // verify required parameter 'receiptId' is not null or undefined
            assertParamExists('updateReceipt', 'receiptId', receiptId)
            // verify required parameter 'updateRecreationReceiptDTO' is not null or undefined
            assertParamExists('updateReceipt', 'updateRecreationReceiptDTO', updateRecreationReceiptDTO)
            const localVarPath = `/tenants/{tenantId}/employees/{employeeId}/benefits/recreation/receipts/{receiptId}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"employeeId"}}`, encodeURIComponent(String(employeeId)))
                .replace(`{${"receiptId"}}`, encodeURIComponent(String(receiptId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (isAudit !== undefined) {
                localVarQueryParameter['isAudit'] = isAudit;
            }

            if (isTenantAudit !== undefined) {
                localVarQueryParameter['isTenantAudit'] = isTenantAudit;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateRecreationReceiptDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RecreationApi - functional programming interface
 * @export
 */
export const RecreationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RecreationApiAxiosParamCreator(configuration)
    return {
        /**
         * Add a receipt.
         * @summary add a receipt
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {CreateRecreationReceiptDTO} createRecreationReceiptDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createReceipt(tenantId: string, employeeId: string, createRecreationReceiptDTO: CreateRecreationReceiptDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdentifierDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createReceipt(tenantId, employeeId, createRecreationReceiptDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create a form POST upload link for uploading the receipt image. Refer to https://docs.aws.amazon.com/AmazonS3/latest/API/sigv4-post-example.html on how to implement the form POST.
         * @summary create a presigned upload link
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} [contentType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUploadLink(tenantId: string, employeeId: string, contentType?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadLinkDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUploadLink(tenantId, employeeId, contentType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete the receipt with the given ID. Approved receipts cannot be deleted.
         * @summary delete a single receipt
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} receiptId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteReceipt(tenantId: string, employeeId: string, receiptId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteReceipt(tenantId, employeeId, receiptId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Read the current recreation period. The period will only exist if the recreation benefit is activated for the current user within the current month. Returns the active recreation period of the current user.
         * @summary read the current recreation period
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCurrentPeriod(tenantId: string, employeeId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RecreationPeriodDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCurrentPeriod(tenantId, employeeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Read the receipts of the requested period. The Recreation benefit must be active for the employee at the given month.
         * @summary read the receipts of the requested period
         * @param {string} year 
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPeriodReceipts(year: string, tenantId: string, employeeId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RecreationReceiptsDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPeriodReceipts(year, tenantId, employeeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the periods in a range.
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} startYear 
         * @param {string} endYear 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPeriods(tenantId: string, employeeId: string, startYear: string, endYear: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RecreationPeriodsDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPeriods(tenantId, employeeId, startYear, endYear, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Read a receipt.
         * @summary read a single receipt
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} receiptId 
         * @param {boolean} [withImage] if true, imageURL will be populated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReceipt(tenantId: string, employeeId: string, receiptId: string, withImage?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RecreationReceiptDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReceipt(tenantId, employeeId, receiptId, withImage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get csv with receipt details
         * @param {string} month 
         * @param {string} tenantId 
         * @param {string} [department] 
         * @param {string} [filter] 
         * @param {Array<string>} [sortBy] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReceiptDetailsExport(month: string, tenantId: string, department?: string, filter?: string, sortBy?: Array<string>, page?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReceiptDetailsExport(month, tenantId, department, filter, sortBy, page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get all recreation receipts for a tenant
         * @param {string} month 
         * @param {string} tenantId 
         * @param {string} [department] 
         * @param {string} [filter] 
         * @param {Array<string>} [sortBy] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRecreationReceipts(month: string, tenantId: string, department?: string, filter?: string, sortBy?: Array<string>, page?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RecreationReceiptTenantListDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRecreationReceipts(month, tenantId, department, filter, sortBy, page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update a receipt.
         * @summary update a single receipt
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} receiptId 
         * @param {UpdateRecreationReceiptDTO} updateRecreationReceiptDTO 
         * @param {boolean} [isAudit] 
         * @param {boolean} [isTenantAudit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateReceipt(tenantId: string, employeeId: string, receiptId: string, updateRecreationReceiptDTO: UpdateRecreationReceiptDTO, isAudit?: boolean, isTenantAudit?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateReceipt(tenantId, employeeId, receiptId, updateRecreationReceiptDTO, isAudit, isTenantAudit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RecreationApi - factory interface
 * @export
 */
export const RecreationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RecreationApiFp(configuration)
    return {
        /**
         * Add a receipt.
         * @summary add a receipt
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {CreateRecreationReceiptDTO} createRecreationReceiptDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createReceipt(tenantId: string, employeeId: string, createRecreationReceiptDTO: CreateRecreationReceiptDTO, options?: any): AxiosPromise<IdentifierDTO> {
            return localVarFp.createReceipt(tenantId, employeeId, createRecreationReceiptDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a form POST upload link for uploading the receipt image. Refer to https://docs.aws.amazon.com/AmazonS3/latest/API/sigv4-post-example.html on how to implement the form POST.
         * @summary create a presigned upload link
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} [contentType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUploadLink(tenantId: string, employeeId: string, contentType?: string, options?: any): AxiosPromise<UploadLinkDTO> {
            return localVarFp.createUploadLink(tenantId, employeeId, contentType, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete the receipt with the given ID. Approved receipts cannot be deleted.
         * @summary delete a single receipt
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} receiptId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteReceipt(tenantId: string, employeeId: string, receiptId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteReceipt(tenantId, employeeId, receiptId, options).then((request) => request(axios, basePath));
        },
        /**
         * Read the current recreation period. The period will only exist if the recreation benefit is activated for the current user within the current month. Returns the active recreation period of the current user.
         * @summary read the current recreation period
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentPeriod(tenantId: string, employeeId: string, options?: any): AxiosPromise<RecreationPeriodDTO> {
            return localVarFp.getCurrentPeriod(tenantId, employeeId, options).then((request) => request(axios, basePath));
        },
        /**
         * Read the receipts of the requested period. The Recreation benefit must be active for the employee at the given month.
         * @summary read the receipts of the requested period
         * @param {string} year 
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPeriodReceipts(year: string, tenantId: string, employeeId: string, options?: any): AxiosPromise<RecreationReceiptsDTO> {
            return localVarFp.getPeriodReceipts(year, tenantId, employeeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the periods in a range.
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} startYear 
         * @param {string} endYear 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPeriods(tenantId: string, employeeId: string, startYear: string, endYear: string, options?: any): AxiosPromise<RecreationPeriodsDTO> {
            return localVarFp.getPeriods(tenantId, employeeId, startYear, endYear, options).then((request) => request(axios, basePath));
        },
        /**
         * Read a receipt.
         * @summary read a single receipt
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} receiptId 
         * @param {boolean} [withImage] if true, imageURL will be populated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReceipt(tenantId: string, employeeId: string, receiptId: string, withImage?: boolean, options?: any): AxiosPromise<RecreationReceiptDTO> {
            return localVarFp.getReceipt(tenantId, employeeId, receiptId, withImage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get csv with receipt details
         * @param {string} month 
         * @param {string} tenantId 
         * @param {string} [department] 
         * @param {string} [filter] 
         * @param {Array<string>} [sortBy] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReceiptDetailsExport(month: string, tenantId: string, department?: string, filter?: string, sortBy?: Array<string>, page?: number, pageSize?: number, options?: any): AxiosPromise<string> {
            return localVarFp.getReceiptDetailsExport(month, tenantId, department, filter, sortBy, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get all recreation receipts for a tenant
         * @param {string} month 
         * @param {string} tenantId 
         * @param {string} [department] 
         * @param {string} [filter] 
         * @param {Array<string>} [sortBy] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecreationReceipts(month: string, tenantId: string, department?: string, filter?: string, sortBy?: Array<string>, page?: number, pageSize?: number, options?: any): AxiosPromise<RecreationReceiptTenantListDTO> {
            return localVarFp.getRecreationReceipts(month, tenantId, department, filter, sortBy, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a receipt.
         * @summary update a single receipt
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} receiptId 
         * @param {UpdateRecreationReceiptDTO} updateRecreationReceiptDTO 
         * @param {boolean} [isAudit] 
         * @param {boolean} [isTenantAudit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateReceipt(tenantId: string, employeeId: string, receiptId: string, updateRecreationReceiptDTO: UpdateRecreationReceiptDTO, isAudit?: boolean, isTenantAudit?: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.updateReceipt(tenantId, employeeId, receiptId, updateRecreationReceiptDTO, isAudit, isTenantAudit, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createReceipt operation in RecreationApi.
 * @export
 * @interface RecreationApiCreateReceiptRequest
 */
export interface RecreationApiCreateReceiptRequest {
    /**
     * 
     * @type {string}
     * @memberof RecreationApiCreateReceipt
     */
    readonly tenantId: string

    /**
     * 
     * @type {string}
     * @memberof RecreationApiCreateReceipt
     */
    readonly employeeId: string

    /**
     * 
     * @type {CreateRecreationReceiptDTO}
     * @memberof RecreationApiCreateReceipt
     */
    readonly createRecreationReceiptDTO: CreateRecreationReceiptDTO
}

/**
 * Request parameters for createUploadLink operation in RecreationApi.
 * @export
 * @interface RecreationApiCreateUploadLinkRequest
 */
export interface RecreationApiCreateUploadLinkRequest {
    /**
     * 
     * @type {string}
     * @memberof RecreationApiCreateUploadLink
     */
    readonly tenantId: string

    /**
     * 
     * @type {string}
     * @memberof RecreationApiCreateUploadLink
     */
    readonly employeeId: string

    /**
     * 
     * @type {string}
     * @memberof RecreationApiCreateUploadLink
     */
    readonly contentType?: string
}

/**
 * Request parameters for deleteReceipt operation in RecreationApi.
 * @export
 * @interface RecreationApiDeleteReceiptRequest
 */
export interface RecreationApiDeleteReceiptRequest {
    /**
     * 
     * @type {string}
     * @memberof RecreationApiDeleteReceipt
     */
    readonly tenantId: string

    /**
     * 
     * @type {string}
     * @memberof RecreationApiDeleteReceipt
     */
    readonly employeeId: string

    /**
     * 
     * @type {string}
     * @memberof RecreationApiDeleteReceipt
     */
    readonly receiptId: string
}

/**
 * Request parameters for getCurrentPeriod operation in RecreationApi.
 * @export
 * @interface RecreationApiGetCurrentPeriodRequest
 */
export interface RecreationApiGetCurrentPeriodRequest {
    /**
     * 
     * @type {string}
     * @memberof RecreationApiGetCurrentPeriod
     */
    readonly tenantId: string

    /**
     * 
     * @type {string}
     * @memberof RecreationApiGetCurrentPeriod
     */
    readonly employeeId: string
}

/**
 * Request parameters for getPeriodReceipts operation in RecreationApi.
 * @export
 * @interface RecreationApiGetPeriodReceiptsRequest
 */
export interface RecreationApiGetPeriodReceiptsRequest {
    /**
     * 
     * @type {string}
     * @memberof RecreationApiGetPeriodReceipts
     */
    readonly year: string

    /**
     * 
     * @type {string}
     * @memberof RecreationApiGetPeriodReceipts
     */
    readonly tenantId: string

    /**
     * 
     * @type {string}
     * @memberof RecreationApiGetPeriodReceipts
     */
    readonly employeeId: string
}

/**
 * Request parameters for getPeriods operation in RecreationApi.
 * @export
 * @interface RecreationApiGetPeriodsRequest
 */
export interface RecreationApiGetPeriodsRequest {
    /**
     * 
     * @type {string}
     * @memberof RecreationApiGetPeriods
     */
    readonly tenantId: string

    /**
     * 
     * @type {string}
     * @memberof RecreationApiGetPeriods
     */
    readonly employeeId: string

    /**
     * 
     * @type {string}
     * @memberof RecreationApiGetPeriods
     */
    readonly startYear: string

    /**
     * 
     * @type {string}
     * @memberof RecreationApiGetPeriods
     */
    readonly endYear: string
}

/**
 * Request parameters for getReceipt operation in RecreationApi.
 * @export
 * @interface RecreationApiGetReceiptRequest
 */
export interface RecreationApiGetReceiptRequest {
    /**
     * 
     * @type {string}
     * @memberof RecreationApiGetReceipt
     */
    readonly tenantId: string

    /**
     * 
     * @type {string}
     * @memberof RecreationApiGetReceipt
     */
    readonly employeeId: string

    /**
     * 
     * @type {string}
     * @memberof RecreationApiGetReceipt
     */
    readonly receiptId: string

    /**
     * if true, imageURL will be populated
     * @type {boolean}
     * @memberof RecreationApiGetReceipt
     */
    readonly withImage?: boolean
}

/**
 * Request parameters for getReceiptDetailsExport operation in RecreationApi.
 * @export
 * @interface RecreationApiGetReceiptDetailsExportRequest
 */
export interface RecreationApiGetReceiptDetailsExportRequest {
    /**
     * 
     * @type {string}
     * @memberof RecreationApiGetReceiptDetailsExport
     */
    readonly month: string

    /**
     * 
     * @type {string}
     * @memberof RecreationApiGetReceiptDetailsExport
     */
    readonly tenantId: string

    /**
     * 
     * @type {string}
     * @memberof RecreationApiGetReceiptDetailsExport
     */
    readonly department?: string

    /**
     * 
     * @type {string}
     * @memberof RecreationApiGetReceiptDetailsExport
     */
    readonly filter?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof RecreationApiGetReceiptDetailsExport
     */
    readonly sortBy?: Array<string>

    /**
     * 
     * @type {number}
     * @memberof RecreationApiGetReceiptDetailsExport
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof RecreationApiGetReceiptDetailsExport
     */
    readonly pageSize?: number
}

/**
 * Request parameters for getRecreationReceipts operation in RecreationApi.
 * @export
 * @interface RecreationApiGetRecreationReceiptsRequest
 */
export interface RecreationApiGetRecreationReceiptsRequest {
    /**
     * 
     * @type {string}
     * @memberof RecreationApiGetRecreationReceipts
     */
    readonly month: string

    /**
     * 
     * @type {string}
     * @memberof RecreationApiGetRecreationReceipts
     */
    readonly tenantId: string

    /**
     * 
     * @type {string}
     * @memberof RecreationApiGetRecreationReceipts
     */
    readonly department?: string

    /**
     * 
     * @type {string}
     * @memberof RecreationApiGetRecreationReceipts
     */
    readonly filter?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof RecreationApiGetRecreationReceipts
     */
    readonly sortBy?: Array<string>

    /**
     * 
     * @type {number}
     * @memberof RecreationApiGetRecreationReceipts
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof RecreationApiGetRecreationReceipts
     */
    readonly pageSize?: number
}

/**
 * Request parameters for updateReceipt operation in RecreationApi.
 * @export
 * @interface RecreationApiUpdateReceiptRequest
 */
export interface RecreationApiUpdateReceiptRequest {
    /**
     * 
     * @type {string}
     * @memberof RecreationApiUpdateReceipt
     */
    readonly tenantId: string

    /**
     * 
     * @type {string}
     * @memberof RecreationApiUpdateReceipt
     */
    readonly employeeId: string

    /**
     * 
     * @type {string}
     * @memberof RecreationApiUpdateReceipt
     */
    readonly receiptId: string

    /**
     * 
     * @type {UpdateRecreationReceiptDTO}
     * @memberof RecreationApiUpdateReceipt
     */
    readonly updateRecreationReceiptDTO: UpdateRecreationReceiptDTO

    /**
     * 
     * @type {boolean}
     * @memberof RecreationApiUpdateReceipt
     */
    readonly isAudit?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof RecreationApiUpdateReceipt
     */
    readonly isTenantAudit?: boolean
}

/**
 * RecreationApi - object-oriented interface
 * @export
 * @class RecreationApi
 * @extends {BaseAPI}
 */
export class RecreationApi extends BaseAPI {
    /**
     * Add a receipt.
     * @summary add a receipt
     * @param {RecreationApiCreateReceiptRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecreationApi
     */
    public createReceipt(requestParameters: RecreationApiCreateReceiptRequest, options?: AxiosRequestConfig) {
        return RecreationApiFp(this.configuration).createReceipt(requestParameters.tenantId, requestParameters.employeeId, requestParameters.createRecreationReceiptDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a form POST upload link for uploading the receipt image. Refer to https://docs.aws.amazon.com/AmazonS3/latest/API/sigv4-post-example.html on how to implement the form POST.
     * @summary create a presigned upload link
     * @param {RecreationApiCreateUploadLinkRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecreationApi
     */
    public createUploadLink(requestParameters: RecreationApiCreateUploadLinkRequest, options?: AxiosRequestConfig) {
        return RecreationApiFp(this.configuration).createUploadLink(requestParameters.tenantId, requestParameters.employeeId, requestParameters.contentType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete the receipt with the given ID. Approved receipts cannot be deleted.
     * @summary delete a single receipt
     * @param {RecreationApiDeleteReceiptRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecreationApi
     */
    public deleteReceipt(requestParameters: RecreationApiDeleteReceiptRequest, options?: AxiosRequestConfig) {
        return RecreationApiFp(this.configuration).deleteReceipt(requestParameters.tenantId, requestParameters.employeeId, requestParameters.receiptId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Read the current recreation period. The period will only exist if the recreation benefit is activated for the current user within the current month. Returns the active recreation period of the current user.
     * @summary read the current recreation period
     * @param {RecreationApiGetCurrentPeriodRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecreationApi
     */
    public getCurrentPeriod(requestParameters: RecreationApiGetCurrentPeriodRequest, options?: AxiosRequestConfig) {
        return RecreationApiFp(this.configuration).getCurrentPeriod(requestParameters.tenantId, requestParameters.employeeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Read the receipts of the requested period. The Recreation benefit must be active for the employee at the given month.
     * @summary read the receipts of the requested period
     * @param {RecreationApiGetPeriodReceiptsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecreationApi
     */
    public getPeriodReceipts(requestParameters: RecreationApiGetPeriodReceiptsRequest, options?: AxiosRequestConfig) {
        return RecreationApiFp(this.configuration).getPeriodReceipts(requestParameters.year, requestParameters.tenantId, requestParameters.employeeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the periods in a range.
     * @param {RecreationApiGetPeriodsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecreationApi
     */
    public getPeriods(requestParameters: RecreationApiGetPeriodsRequest, options?: AxiosRequestConfig) {
        return RecreationApiFp(this.configuration).getPeriods(requestParameters.tenantId, requestParameters.employeeId, requestParameters.startYear, requestParameters.endYear, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Read a receipt.
     * @summary read a single receipt
     * @param {RecreationApiGetReceiptRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecreationApi
     */
    public getReceipt(requestParameters: RecreationApiGetReceiptRequest, options?: AxiosRequestConfig) {
        return RecreationApiFp(this.configuration).getReceipt(requestParameters.tenantId, requestParameters.employeeId, requestParameters.receiptId, requestParameters.withImage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get csv with receipt details
     * @param {RecreationApiGetReceiptDetailsExportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecreationApi
     */
    public getReceiptDetailsExport(requestParameters: RecreationApiGetReceiptDetailsExportRequest, options?: AxiosRequestConfig) {
        return RecreationApiFp(this.configuration).getReceiptDetailsExport(requestParameters.month, requestParameters.tenantId, requestParameters.department, requestParameters.filter, requestParameters.sortBy, requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get all recreation receipts for a tenant
     * @param {RecreationApiGetRecreationReceiptsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecreationApi
     */
    public getRecreationReceipts(requestParameters: RecreationApiGetRecreationReceiptsRequest, options?: AxiosRequestConfig) {
        return RecreationApiFp(this.configuration).getRecreationReceipts(requestParameters.month, requestParameters.tenantId, requestParameters.department, requestParameters.filter, requestParameters.sortBy, requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a receipt.
     * @summary update a single receipt
     * @param {RecreationApiUpdateReceiptRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecreationApi
     */
    public updateReceipt(requestParameters: RecreationApiUpdateReceiptRequest, options?: AxiosRequestConfig) {
        return RecreationApiFp(this.configuration).updateReceipt(requestParameters.tenantId, requestParameters.employeeId, requestParameters.receiptId, requestParameters.updateRecreationReceiptDTO, requestParameters.isAudit, requestParameters.isTenantAudit, options).then((request) => request(this.axios, this.basePath));
    }
}
