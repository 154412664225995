import { Button } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import { useLoginContext } from '@tiffinger-thiel/appauth-react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorView } from '../module/error/ErrorView';

const Error404Page: React.FC = () => {
  const { t } = useTranslation('errorModule');
  const { isLoggedIn } = useLoginContext();

  const handleGoHome = useCallback(() => {
    window.location.href = '/';
  }, []);

  const handleGetSupport = useCallback(() => {
    window.location.href = '/benefit-consulting';
  }, []);

  return (
    <ErrorView headlineLarge={t('notFound.headlineLarge')} headlineSmall={t('notFound.headlineSmall')} message={t('notFound.message')}>
      <Button startIcon={<HomeIcon />} color="warning" variant="contained" onClick={handleGoHome} sx={{ margin: 0.5 }}>
        {t('goHome')}
      </Button>
      {isLoggedIn && (
        <Button startIcon={<ContactSupportIcon />} color="warning" variant="contained" onClick={handleGetSupport} sx={{ margin: 0.5 }}>
          {t('getSupport')}
        </Button>
      )}
    </ErrorView>
  );
};

export default Error404Page;
