import { useQueryClient, UseQueryResult } from '@tanstack/react-query';
import { DateTime } from 'luxon';
import { RecreationPeriodDTO, RecreationPeriodsDTO, RecreationReceiptsDTO } from 'probonio-shared-ui/api';
import { apis, useEmployeeQuery } from 'probonio-shared-ui/module/api';
import { useActiveEmployment } from 'probonio-shared-ui/module/me';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';

export interface RecreationPeriodsState {
  recreationPeriodsQuery: UseQueryResult<RecreationPeriodsDTO, Error>;
  previousPeriod?: RecreationPeriodDTO;
  currentPeriod?: RecreationPeriodDTO;
  receiptsQuery: UseQueryResult<RecreationReceiptsDTO, Error>;
  refetchRecreation: () => void;
  selectPeriod: (year?: string) => void;
  selectedPeriodYear?: string;
  selectedPeriod?: RecreationPeriodDTO;
}

const RecreationPeriodsContext = createContext<RecreationPeriodsState | undefined>(undefined);

export const RecreationPeriodsProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const queryClient = useQueryClient();
  const getActiveEmployment = useActiveEmployment();
  const [selectedPeriodYear, setSelectedPeriodYear] = useState<string>();

  const startYear = DateTime.utc().minus({ years: 10 }).startOf('year').toFormat('yyyy');
  const endYear = DateTime.utc().endOf('year').toFormat('yyyy');

  const periodsQuery: UseQueryResult<RecreationPeriodsDTO, Error> = useEmployeeQuery(
    [
      'benefits',
      'recreation',
      'periods',
      {
        startYear,
        endYear,
      },
      apis.recreation.getPeriods.name,
    ],
    params =>
      apis.recreation
        .getPeriods({
          ...params,
          startYear,
          endYear,
        })
        .then(res => res.data),
    { refetchOnMount: false },
  );

  const selectedPeriod = useMemo(() => {
    if (selectedPeriodYear && periodsQuery.data) {
      return periodsQuery.data.recreationPeriods.find(period => period.year === selectedPeriodYear);
    }
  }, [periodsQuery.data, selectedPeriodYear]);

  const receiptsQuery: UseQueryResult<RecreationReceiptsDTO, Error> = useEmployeeQuery(
    ['benefits', 'recreation', 'periods', selectedPeriod, 'receipts', apis.recreation.getPeriodReceipts.name],
    params => apis.recreation.getPeriodReceipts({ ...params, year: selectedPeriodYear! }).then(res => res.data),
    {
      enabled: !!selectedPeriodYear,
      // Refetch only after min 60 seconds to prevent api calls on every open / close of the accordion.
      staleTime: 60000,
    },
  );

  useEffect(() => {
    if (selectedPeriod === undefined) {
      queryClient.removeQueries({
        queryKey: ['benefits', 'recreation', 'periods', selectedPeriod, 'receipts', apis.recreation.getPeriodReceipts.name],
      });
    }
  }, [queryClient, receiptsQuery, selectedPeriod]);

  const context = useMemo<RecreationPeriodsState>(
    () => ({
      recreationPeriodsQuery: periodsQuery,
      previousPeriod: periodsQuery.data?.recreationPeriods.find(
        period => period.year === DateTime.now().minus({ year: 1 }).toFormat('yyyy-MM'),
      ),
      currentPeriod: periodsQuery.data?.recreationPeriods.find(period => period.year === DateTime.now().toFormat('yyyy')),
      receiptsQuery: receiptsQuery,

      refetchRecreation: async () => {
        return queryClient.invalidateQueries({
          queryKey: ['tenants', getActiveEmployment().tenantId, 'employees', getActiveEmployment().id, 'benefits', 'recreation'],
        });
      },
      selectPeriod: setSelectedPeriodYear,
      selectedPeriodYear: selectedPeriodYear,
      selectedPeriod: selectedPeriod,
    }),
    [getActiveEmployment, periodsQuery, queryClient, receiptsQuery, selectedPeriod, selectedPeriodYear],
  );

  return <RecreationPeriodsContext.Provider value={context}>{children}</RecreationPeriodsContext.Provider>;
};

export function useRecreationPeriods(): RecreationPeriodsState {
  const ctx = useContext(RecreationPeriodsContext);
  // Throw error that the Context is not injected, if it is undefined.
  if (ctx === undefined) {
    throw new Error('useRecreationPeriods must be used within a RecreationPeriodsProvider');
  }
  return ctx;
}
