import React from 'react';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionProps, AccordionSummary, Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import LockIcon from '@mui/icons-material/Lock';

interface Props {
  children: React.ReactNode;
  monthName: string;
  totalLimit: number;
  isLocked?: boolean;
  expanded?: boolean;
}

export const ReceiptAccordion: React.FC<Props & AccordionProps> = ({ children, monthName, totalLimit, isLocked, ...props }) => {
  const { t } = useTranslation();

  return (
    <Box sx={{ marginY: 1 }}>
      <Accordion {...props} elevation={0}>
        <AccordionSummary
          sx={{ paddingLeft: 1, paddingRight: 0, paddingY: 0 }}
          expandIcon={<ExpandMore sx={{ marginX: 0.5 }} fontSize="large" />}
        >
          <Box sx={{ display: 'flex', flexGrow: 1 }}>
            {isLocked && <LockIcon sx={{ color: 'text.secondary', marginRight: 0.5 }} />}
            <Typography component="span" color="text.secondary" variant="caption">
              {monthName}
            </Typography>
            <Typography component="span" color="text.secondary" variant="caption" sx={{ flexGrow: 1, textAlign: 'right' }}>
              {t('common:money', { money: totalLimit })}
            </Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: 0 }}>{children}</AccordionDetails>
      </Accordion>
    </Box>
  );
};
