import { Box, Button, Typography } from '@mui/material';
import { CouponDefinitionDTO, CouponVariationAvailabilityDTOAvailabilityEnum } from 'probonio-shared-ui/api';
import { LoadingButton } from 'probonio-shared-ui/component/button/LoadingButton';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { BasicAlert } from '../../../component/alert';
import { BasicDialog, DialogState } from '../../../component/dialog/BasicDialog';
import { LoadingFunction } from '../../../utils/loadingFunction';
import { getCouponAvailability, getFirstCouponCombination } from '../couponCombinations';

interface Props {
  dialogState: DialogState;
  coupon: CouponDefinitionDTO;
  amount: number;
  message?: React.ReactNode;
  onConfirm: LoadingFunction<(amount: number) => void>;
  tenantBudgetAvailable?: boolean;
}

export const ExploreCouponsDetailModal: React.FC<React.PropsWithChildren<Props>> = ({
  dialogState,
  coupon,
  amount,
  message,
  onConfirm,
  tenantBudgetAvailable,
  children,
}) => {
  const { t } = useTranslation('couponsModule');

  const combinations = getFirstCouponCombination(coupon.variations, amount);
  const availability = getCouponAvailability(coupon, amount);

  const handleConfirm = useCallback(() => {
    onConfirm(amount);
  }, [amount, onConfirm]);

  return (
    <BasicDialog
      title={t('exploreCoupons.details.confirmModal.title')}
      dialogState={dialogState}
      content={
        <Box>
          <Typography marginBottom={1}>
            {t('exploreCoupons.details.confirmModal.message')}{' '}
            {availability === CouponVariationAvailabilityDTOAvailabilityEnum.OnDemand &&
              t('exploreCoupons.details.confirmModal.onDemandWarning')}
          </Typography>
          {combinations ? (
            <BasicAlert
              severity="info"
              sx={{
                mb: 1,
                // allow newlines in the translation
                whiteSpace: 'pre-wrap',
              }}
            >
              {t('exploreCoupons.details.exploreCouponsAlert.warningCombination', { budget: amount })}
              {combinations.map((value, index) => (
                <div key={index}>{t('exploreCoupons.details.exploreCouponsAlert.combinationValue', { count: 1, value })}</div>
              ))}
            </BasicAlert>
          ) : null}
          {children}
        </Box>
      }
      actions={
        <Box display="flex" justifyContent="space-between" width={'100%'}>
          <Button onClick={dialogState.handleClose} disabled={onConfirm.isLoading}>
            {t('exploreCoupons.details.confirmModal.cancel')}
          </Button>
          <LoadingButton
            disabled={tenantBudgetAvailable && !tenantBudgetAvailable}
            onClick={handleConfirm}
            loading={onConfirm.isLoading}
            data-test-id="confirm-order"
          >
            {t('exploreCoupons.details.confirmModal.confirm')}
          </LoadingButton>
        </Box>
      }
    />
  );
};
