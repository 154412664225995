import { CircularProgress } from '@mui/material';
import * as React from 'react';
import { LinkIconButton } from './LinkIconButton';

interface Props extends React.ComponentProps<typeof LinkIconButton> {
  loading?: boolean;
}

export const LoadingLinkIconButton: React.FC<Props> = ({ loading, children, ...props }) => {
  return (
    <LinkIconButton disabled={loading || props.disabled} {...props}>
      {loading ? <CircularProgress color="inherit" size={24} /> : children}
    </LinkIconButton>
  );
};
