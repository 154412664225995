import CelebrationIcon from '@mui/icons-material/Celebration';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import NavigationButton from '../../../component/button/NavigationButton';

interface Props {
  onClick: () => void;
}

export const GiftNavButton: React.FC<Props> = ({ onClick }) => {
  const { t } = useTranslation('benefitModule');

  return (
    <Box sx={{ marginTop: 1 }} color="primary">
      <NavigationButton onClick={onClick} iconRight={null} data-test-id="gift-button" iconLeft={<CelebrationIcon fontSize="large" />}>
        <Typography variant="h3" color="text.primary">
          {t('benefit.GIFTS.buttonTitle')}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {t('benefit.GIFTS.buttonSubtitle')}
        </Typography>
      </NavigationButton>
    </Box>
  );
};
