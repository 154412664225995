import { VoucherDetailsDTO } from 'probonio-shared-ui/api';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useNewDialogState } from '../../../component/dialog/BasicDialog';
import { LoadingFunction } from '../../../utils/loadingFunction';
import { UpdateCouponOrderRedeem } from '../redeem/CouponRedeemButton';
import { CouponRedeemedModal } from '../redeem/CouponRedeemedModal';

interface OpenVoucherInBrowserParams {
  voucher?: VoucherDetailsDTO;
  redeemDate?: string;
  onRedeem: LoadingFunction<(redeemConditions: UpdateCouponOrderRedeem) => void>;
}

export function useOpenVoucherInBrowser({ redeemDate, onRedeem, voucher }: OpenVoucherInBrowserParams): {
  shouldOpenInBrowser: boolean;
  handleOpenBrowser: () => void;
  renderedRedeemModal: JSX.Element;
} {
  const redeemedModal = useNewDialogState();

  const shouldOpenInBrowser = !!voucher?.url && !voucher.code && !voucher.pin && !voucher.displayCode;

  const handleOpenBrowser = useCallback(() => {
    window.open(voucher?.url, '_blank');

    if (!redeemDate) {
      redeemedModal.handleOpen();
    }
  }, [redeemDate, redeemedModal, voucher?.url]);

  const handleConfirmRedeemed = useCallback(() => {
    onRedeem({ shouldBeRedeemed: true });
  }, [onRedeem]);

  const wasLoading = useRef<boolean>();
  useEffect(() => {
    if (wasLoading.current && !onRedeem.isLoading) {
      redeemedModal.dialogState.handleClose();
    }
    wasLoading.current = onRedeem.isLoading;
  }, [redeemedModal.dialogState, onRedeem.isLoading]);

  return useMemo(() => {
    const renderedRedeemModal = (
      <CouponRedeemedModal
        handleConfirm={handleConfirmRedeemed}
        dialogState={redeemedModal.dialogState}
        isLoading={onRedeem.isLoading}
        handleCancel={redeemedModal.dialogState.handleClose}
      />
    );

    return { shouldOpenInBrowser, handleOpenBrowser, renderedRedeemModal };
  }, [handleConfirmRedeemed, handleOpenBrowser, onRedeem.isLoading, redeemedModal.dialogState, shouldOpenInBrowser]);
}
