import { Box, LinearProgress, linearProgressClasses, styled, Typography } from '@mui/material';
import { BenefitDTOBenefitEnum } from 'probonio-shared-ui/api';
import { useTranslation } from 'react-i18next';

interface Props {
  maxRefund?: number;
  refund?: number;
  benefit: BenefitDTOBenefitEnum;
}

export const StyledLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  backgroundColor: theme.palette.greyBackground,

  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
  },
}));

export const ProgressMoney: React.FC<Props> = ({ maxRefund, refund, benefit }) => {
  const { t } = useTranslation('benefitModule');

  return (
    <Box marginX={2}>
      <Box display="flex" justifyContent="space-between" marginBottom={0.3}>
        <Typography variant="h2">{t('common:money', { money: refund })} </Typography>
        <Typography variant="paragraph">{t(`benefit.${benefit}.refundProgress.title`)}</Typography>
        <Typography variant="h2">{t('common:money', { money: maxRefund })} </Typography>
      </Box>

      <StyledLinearProgress variant="determinate" value={refund && maxRefund ? (refund / maxRefund) * 100 : 0} />
      <Box display="flex" justifyContent="space-between" marginTop={0.3}>
        <Typography variant="paragraph">{t(`benefit.${benefit}.refundProgress.progress`)}</Typography>
        <Typography variant="paragraph">{t(`benefit.${benefit}.refundProgress.total`)}</Typography>
      </Box>
    </Box>
  );
};
