import { Button, ButtonProps, CircularProgress } from '@mui/material';
import React from 'react';

interface Props extends ButtonProps {
  loading?: boolean;
  dataTestId?: string;
}

export const LoadingButton = React.forwardRef<HTMLButtonElement, Props>(({ loading, dataTestId, disabled, children, ...props }, ref) => {
  return (
    <Button
      ref={ref}
      disabled={loading || disabled}
      {...props}
      sx={{ '& .MuiButton-startIcon': { opacity: loading ? 0 : 1, transition: 'opacity 0.1s' }, ...props.sx }}
    >
      {loading && (
        <CircularProgress
          color="inherit"
          size={28}
          sx={{
            position: 'absolute',
            marginLeft: 'auto',
            marginRight: 'auto',
            left: 0,
            right: 0,
          }}
        />
      )}
      <span data-test-id={dataTestId} style={{ display: 'inline-flex', opacity: loading ? 0 : 1, transition: 'opacity 0.1s' }}>
        {children}
      </span>
    </Button>
  );
});

export default LoadingButton;
