import { Capacitor } from '@capacitor/core';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import { Box, IconButton } from '@mui/material';
import { BackGesture } from '@tiffinger-thiel/capacitor-back-gesture';
import { isTenantSelected, useHasAvailableTenants, useTenant } from 'probonio-shared-ui/module/me';
import { FC, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AppPromotion from '../../component/appPromotion/AppPromotion';
import AppPromotionModal from '../../component/appPromotion/appPromotionModal/AppPromotionModal';
import { useNewDialogState } from '../../component/dialog/BasicDialog';
import { AvatarHeader, MobileLayout } from '../../component/layout';
import { VerticalBox } from '../../component/layout/BoxLayout';
import { PushNotificationDialog } from '../../component/pushNotificationDialog/PushNotificationDialog';
import ReferralVisualization from '../../component/referralModal/ReferralVisualization';
import { MyBenefitList } from '../../module/benefit';
import BenefitVisualization from '../../module/benefit/benefitVisualization/BenefitVisualization';
import { FeedbackVisualization } from '../../module/feedback/FeedbackVisualization';
import { SwitchTenantDialog } from '../../module/me/SwitchTenantDialog';
import UnsupportedBrowserBadge from '../../component/unsupportedBrowserBadge/UnsupportedBrowserBadge';

const HomePage: FC = () => {
  const navigate = useNavigate();
  const switchTenantDialog = useNewDialogState();

  const { tenant } = useTenant();

  useEffect(() => {
    if (Capacitor.isNativePlatform()) {
      void BackGesture.activate().catch(() => {});
    }
  }, []);

  const navigateToProfile = useCallback(() => {
    navigate('/profile');
  }, [navigate]);

  const navigateToBenefitConsulting = useCallback(() => {
    navigate('/benefit-consulting');
  }, [navigate]);

  const hasAvailableTenants = useHasAvailableTenants();

  useEffect(() => {
    const openDialog = switchTenantDialog.handleOpen;
    if (hasAvailableTenants && !isTenantSelected()) {
      openDialog();
    }
  }, [hasAvailableTenants, switchTenantDialog.handleOpen]);

  return (
    <MobileLayout
      paddedHeader
      paddedBody
      header={
        <AvatarHeader
          right={
            <>
              <IconButton size="large" sx={{ marginRight: -0.25 }} onClick={navigateToBenefitConsulting}>
                <ContactSupportIcon fontSize="large" color="primary" />
              </IconButton>
              <IconButton size="large" sx={{ marginRight: -0.25 }} onClick={navigateToProfile} data-test-id="navigate-to-account-button">
                <AccountCircleIcon fontSize="large" color="primary" />
              </IconButton>
            </>
          }
        />
      }
    >
      <VerticalBox sx={{ flexGrow: 1, marginTop: 1, flexWrap: 'nowrap' }}>
        {tenant?.showStatisticsAtTop && <BenefitVisualization />}
        <ReferralVisualization />
        <UnsupportedBrowserBadge />
        <VerticalBox sx={{ flexGrow: 1, flexShrink: 0, marginBottom: 1, flexWrap: 'nowrap' }}>
          <MyBenefitList />
        </VerticalBox>
        <FeedbackVisualization />
        {!tenant?.showStatisticsAtTop && <BenefitVisualization />}
        <Box display="flex" justifyContent="center" width="100%">
          <Box sx={{ maxWidth: '300px' }}>
            <AppPromotion />
          </Box>
        </Box>
        <AppPromotionModal />
      </VerticalBox>
      <SwitchTenantDialog dialogState={switchTenantDialog.dialogState} />
      <PushNotificationDialog />
    </MobileLayout>
  );
};

export default HomePage;
