import { Box, Skeleton, SxProps, useTheme } from '@mui/material';
import { apis, useTenantQuery } from 'probonio-shared-ui/module/api';
import { useAppSelector } from '../../redux/hooks';
import probonioLogoLight from '../brand/logo/logo.svg';
import probonioLogoDark from '../brand/logo/logoDark.svg';

export const TenantLogo: React.FC<{ sx?: SxProps }> = ({ sx }) => {
  const theme = useTheme();
  const { data: tenant, isLoading } = useTenantQuery([apis.tenants.getTenant.name], tenantId =>
    apis.tenants.getTenant({ tenantId, withImage: true }).then(res => res.data),
  );

  const currentUser = useAppSelector(state => state.me.user);
  const SIMON_MAIL = 'simon@tiffinger-thiel.de';
  const SIMON_LOGO = '/image/demo_logo.png';
  const defaultLogo = theme.palette.mode === 'light' ? probonioLogoLight : probonioLogoDark;
  const tenantLogoColor = theme.palette.mode === 'light' ? tenant?.imageURL : tenant?.imageURLDark || tenant?.imageURL;
  const tenantLogo = currentUser?.mail === SIMON_MAIL ? SIMON_LOGO : tenantLogoColor || defaultLogo;

  return (
    <Box sx={{ borderRadius: '5px', lineHeight: 0, ...sx }}>
      {isLoading ? (
        <Skeleton variant="rectangular" width={150} height={40} sx={{ display: 'inline-block' }} />
      ) : (
        <img src={tenantLogo} alt={`${tenant?.imageURL ? tenant.name : 'Probonio'} Logo`} style={{ maxHeight: '60px', maxWidth: '100%' }} />
      )}
    </Box>
  );
};
