import { Box, IconButton, keyframes, styled, Typography, useTheme } from '@mui/material';
import Grid from '@mui/material/Grid2';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { useCallback, useState } from 'react';

const HealthContainer = styled(Box)(() => ({
  width: '100%',
  height: 'calc(50vh - 20px)',
  display: 'flex',
}));

interface Props {
  turnScreen?: boolean;
  health: number;
  handleHealthChange: (changeNum: number) => void;
}

const shakingAnimation = keyframes`
  10% {
    transform: translate3d(-6px, 0, 0);
  }
  
  20%, 80% {
    transform: translate3d(4px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-6px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(6px, 0, 0);
  }
`;

const ShakingTypography = styled(Typography, {
  shouldForwardProp: prop => prop !== 'shaking',
})<{ shaking?: boolean }>(({ shaking }) => {
  return shaking
    ? {
        animation: `${shakingAnimation} 0.60s cubic-bezier(0.4,.77,1,.43) both`,
        transform: 'translate3d(0, 0, 0)',
        perspective: '1000px',
      }
    : {};
});

const MagicCounterPlayer: React.FC<Props> = ({ turnScreen = false, health, handleHealthChange }) => {
  const [shake, setShake] = useState(false);

  const handleShake = (): void => {
    setShake(true);
    setTimeout(() => {
      setShake(false);
    }, 500);
  };

  const theme = useTheme();

  const reducePlayerHealth = useCallback(() => {
    if (health > 0) {
      handleHealthChange(-1);
    }

    handleShake();
  }, [handleHealthChange, health]);

  const addPlayerHealth = useCallback(() => {
    handleHealthChange(1);
  }, [handleHealthChange]);

  return (
    <>
      <ShakingTypography variant="h3" shaking={shake}>
        <HealthContainer sx={{ backgroundColor: turnScreen ? theme.palette.magicCounter.player1 : theme.palette.magicCounter.player2 }}>
          <Grid container sx={{ alignItems: 'center' }} flexGrow={1}>
            <Grid sx={{ marginLeft: 'auto', width: '50%', height: '100%', zIndex: 1 }}>
              {turnScreen ? (
                <IconButton sx={{ color: '#fff', width: '100%', height: '100%' }} size="large" onClick={addPlayerHealth}>
                  <AddCircleOutlineIcon sx={{ fontSize: 42 }} />
                </IconButton>
              ) : (
                <IconButton sx={{ color: '#fff', width: '100%', height: '100%' }} size="large" onClick={reducePlayerHealth}>
                  <RemoveCircleOutlineIcon sx={{ fontSize: 42 }} />
                </IconButton>
              )}
            </Grid>
            <Grid sx={{ position: 'absolute', width: '100%', textAlign: 'center' }}>
              <Typography fontSize={64} sx={{ transform: turnScreen ? 'rotate(180deg)' : 'rotate(0)', color: '#fff' }}>
                {health}
              </Typography>
            </Grid>
            <Grid sx={{ marginRight: 'auto', width: '50%', height: '100%' }}>
              {turnScreen ? (
                <IconButton sx={{ color: '#fff', width: '100%', height: '100%' }} size="large" onClick={reducePlayerHealth}>
                  <RemoveCircleOutlineIcon sx={{ fontSize: 42 }} />
                </IconButton>
              ) : (
                <IconButton sx={{ color: '#fff', width: '100%', height: '100%' }} size="large" onClick={addPlayerHealth}>
                  <AddCircleOutlineIcon sx={{ fontSize: 42 }} />
                </IconButton>
              )}
            </Grid>
          </Grid>
        </HealthContainer>
      </ShakingTypography>
    </>
  );
};

export default MagicCounterPlayer;
