import { AlertProps } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { BasicAlert } from '../../../component/alert';
import { ReceiptStatus } from '../../benefit/receipt/Receipt';
import { createReceiptIconMapping } from './ReceiptIcon';

const RECEIPT_STATUS_SEVERITY: Record<ReceiptStatus, 'success' | 'error' | 'info'> = {
  ['APPROVED']: 'success',
  ['REJECTED']: 'error',
  ['PENDING']: 'info',
  ['WAITING_FOR_AUDIT']: 'info',
};

export function getReceiptSeverity(status: ReceiptStatus): 'success' | 'error' | 'info' {
  return RECEIPT_STATUS_SEVERITY[status] || 'success';
}

interface Props extends AlertProps {
  receiptStatus: ReceiptStatus;
  rejectionReason: string | undefined;
  isModifiedByAudit: boolean;
  isAuditedByTenant?: boolean;
}

const ICON_MAPPING = createReceiptIconMapping({ fontSize: 'medium' });

export const ReceiptStatusAlert: React.FC<Props> = ({
  receiptStatus,
  rejectionReason,
  isModifiedByAudit,
  isAuditedByTenant,
  ...alertProps
}) => {
  const { t } = useTranslation('benefitModule');
  return (
    <>
      <BasicAlert
        variant="standard"
        severity={getReceiptSeverity(receiptStatus)}
        {...alertProps}
        iconMapping={ICON_MAPPING}
        className="selectable"
      >
        {receiptStatus === 'APPROVED' && isModifiedByAudit
          ? t(`benefit.LUNCH.receipts.${receiptStatus}.modifiedByAuditAlertDescription`)
          : t(`benefit.LUNCH.receipts.${receiptStatus}.alertDescription`, {
              reason: rejectionReason || t('benefit.LUNCH.defaultRejectionReason'),
              byTenant: isAuditedByTenant ? t('benefit.LUNCH.auditedByTenant') : '',
            })}
      </BasicAlert>
    </>
  );
};
