import ExpandMore from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Typography, AccordionProps } from '@mui/material';

interface Props extends AccordionProps {
  title: string;
}

export const InfoAccordion: React.FC<Props> = ({ children, title, ...props }: Props) => {
  return (
    <Accordion {...props}>
      <AccordionSummary
        sx={{ paddingLeft: 1, paddingRight: 0, paddingY: 0 }}
        expandIcon={<ExpandMore sx={{ marginX: 0.5 }} fontSize="large" />}
      >
        <Typography component="span" color="text.secondary" variant="caption">
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ padding: 0 }}>{children}</AccordionDetails>
    </Accordion>
  );
};
