import { Box, Button, CircularProgress, Divider, Stack, Typography } from '@mui/material';
import { DateTime, DurationLike } from 'luxon';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DateBox } from '../../../component/ScrollPicker/DateBox';
import { GenericScrollPicker, ScrollPickerOption } from '../../../component/ScrollPicker/GenericScrollingPicker';
import { ReceiptExistsAlert } from './ReceiptExistsAlert';

function daysBetween(startDate: Date, endDate: Date, increment?: DurationLike): Date[] {
  const result: Date[] = [];
  let current = DateTime.fromJSDate(startDate);
  // full days only
  while (current <= DateTime.fromJSDate(endDate)) {
    result.push(current.toJSDate());
    current = current.plus(increment || { days: 1 });
  }
  return result;
}

interface Props {
  label?: string;
  month: string;
  dayIsUsed?: (date: Date) => boolean;
  numberOfMonths?: number;
  showLastMonth?: boolean;
  value?: Date;
  onConfirm: (value: Date) => void;
  onCancel: () => void;
}

export const PeriodDatePicker: React.FC<Props> = ({
  label,
  month,
  value,
  dayIsUsed,
  numberOfMonths,
  showLastMonth,
  onConfirm,
  onCancel,
}) => {
  const { t, i18n } = useTranslation('benefitModule');
  const maxDate = useMemo(() => new Date(Math.min(DateTime.fromISO(month).endOf('month').toJSDate().getTime(), Date.now())), [month]);
  const [selectedDate, setSelectedDate] = useState(value || maxDate);
  const [startDate, endDate] = useMemo(() => {
    return [
      numberOfMonths
        ? DateTime.fromISO(month).startOf('month').minus({ months: numberOfMonths }).toJSDate()
        : showLastMonth
          ? DateTime.fromISO(month).startOf('month').minus({ month: 1 }).toJSDate()
          : DateTime.fromISO(month).startOf('month').toJSDate(),
      maxDate,
    ];
  }, [maxDate, month, numberOfMonths, showLastMonth]);

  useEffect(() => {
    const newDate = DateTime.fromJSDate(value || maxDate).startOf('day');
    setSelectedDate(newDate.toJSDate());
  }, [maxDate, value]);

  const handleSubmit: React.MouseEventHandler<HTMLButtonElement> = useCallback(
    ev => {
      ev.preventDefault();
      onConfirm(selectedDate);
    },
    [onConfirm, selectedDate],
  );

  const values: ScrollPickerOption<Date>[] = useMemo(() => {
    return daysBetween(startDate, endDate, numberOfMonths ? { months: 1 } : { days: 1 }).map(date => {
      return {
        value: date,
        component: (
          <DateBox
            key={date.toString()}
            mode={numberOfMonths ? 'months' : 'days'}
            date={date}
            active={date.toLocaleDateString() === selectedDate?.toLocaleDateString()}
          />
        ),
      };
    });
  }, [endDate, numberOfMonths, selectedDate, startDate]);

  if (!month) {
    return <CircularProgress />;
  }

  const isUsed = dayIsUsed ? dayIsUsed(selectedDate) : false;

  return (
    <>
      <Box display="flex" flexDirection="column" marginX={1}>
        {label && (
          <Typography variant="paragraph" color="primary" paddingBottom={0.3} sx={{ textAlign: 'center' }}>
            {label}
          </Typography>
        )}
        <Typography variant="extraBig" paddingBottom={0.7}>
          {i18n.format(selectedDate, numberOfMonths ? 'monthAndYear' : 'date')}
        </Typography>
        <Divider sx={{ marginBottom: 1.2 }} />
        <Box sx={{ marginX: -1 }}>
          <GenericScrollPicker options={values} onChange={setSelectedDate} value={selectedDate} />
        </Box>
        <ReceiptExistsAlert show={isUsed} />
      </Box>

      <Stack direction="row" justifyContent={'space-between'} marginX={1}>
        <Button variant="contained" color="secondary" onClick={onCancel} sx={{ width: '35%' }}>
          {t('common:button.back')}
        </Button>

        <Button
          variant="contained"
          color="primary"
          disabled={isUsed}
          onClick={handleSubmit}
          sx={{ width: '35%' }}
          data-test-id="button-continue"
        >
          {t('common:button.continue')}
        </Button>
      </Stack>
    </>
  );
};
