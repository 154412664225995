import { Box, CircularProgress, useTheme } from '@mui/material';
import { CouponsV2UserTokenDTO } from 'probonio-shared-ui/api';
import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { BackIconButton } from '../../component/button/BackIconButton';
import { MobileLayout } from '../../component/layout';
import { AppToolbar } from '../../component/layout/AppToolbar';
import { SwipeableList } from '../../component/swipeableList/SwipeableList';
import { CouponCard } from '../../module/coupons';
import { USER_TOKENS_PAGE_SIZE, useCouponsV2UserTokenListQuery, useCouponsV2UserTokenQuery } from '../../module/couponsV2/couponsV2Queries';
import { useBackNavigator } from '../../module/navigation/BackNavigator';
import { CouponsV2TokenDetail } from '../../module/couponsV2/userTokens/CouponsV2TokenDetail';

export const CouponsV2TokenPage: React.FC = () => {
  const backNavigator = useBackNavigator();
  const { userTokenId } = useParams();
  const theme = useTheme();
  const navigate = useNavigate();

  const { data: tokens, hasNextPage, fetchNextPage } = useCouponsV2UserTokenListQuery();
  const { data: userToken, isLoading } = useCouponsV2UserTokenQuery(userTokenId!);

  const handleNavigate = useCallback(
    (item: CouponsV2UserTokenDTO) => {
      // when going to the next-to-last item, load the next page if available
      const indexOnPage = tokens?.pages[tokens.pages.length - 1].results.findIndex(token => token.id === item.id);
      if (indexOnPage && indexOnPage >= USER_TOKENS_PAGE_SIZE - 2 && hasNextPage) {
        void fetchNextPage();
      }
      navigate(`/couponsV2/tokens/${item.id}`, { replace: true });
    },
    [fetchNextPage, hasNextPage, navigate, tokens?.pages],
  );

  return (
    <MobileLayout
      header={<AppToolbar backButton={<BackIconButton onClick={backNavigator.back} />} />}
      backgroundColor={theme.palette.background.default}
    >
      <Box sx={{ paddingX: 1, paddingBottom: 1 }}>
        {isLoading ? (
          <CouponCard>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
              <CircularProgress />
            </Box>
          </CouponCard>
        ) : (
          <SwipeableList items={tokens?.pages.flatMap(page => page.results)} currentItemId={userTokenId!} onNavigate={handleNavigate}>
            <CouponsV2TokenDetail userToken={userToken!} />
          </SwipeableList>
        )}
      </Box>
    </MobileLayout>
  );
};
