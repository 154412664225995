import { Box, CircularProgress, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BavHubspot } from './BAVHubspot';

export const BAVCalculatorMeeting: React.FC<{ phoneNumber?: string }> = ({ phoneNumber }) => {
  const { t } = useTranslation('bavModule');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => setLoading(false), 2500);
  }, []);

  return loading ? (
    <Box position="relative" flexGrow={1} display="flex" justifyContent="center" alignItems="center">
      <Box textAlign="center">
        <CircularProgress size={100} />
        <Typography variant="h2" marginTop={1}>
          {t('meeting.loading')}
        </Typography>
      </Box>
    </Box>
  ) : (
    <BavHubspot title={<Typography variant="body1">{t('meeting.lead')}</Typography>} phoneNumber={phoneNumber} />
  );
};
