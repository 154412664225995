import { UseMutationResult, useMutation } from '@tanstack/react-query';
import { SetNewsArticleStatusDTOStatusEnum } from 'probonio-shared-ui/api';
import { apis } from 'probonio-shared-ui/module/api';
import { useTenant } from 'probonio-shared-ui/module/me';
import { useAppSelector } from '../../redux/hooks';
import { useInvalidateArticle } from './useInvalidateArticle';

export function useSetArticleStatusMutation(articleId: string): UseMutationResult<void, Error, SetNewsArticleStatusDTOStatusEnum> {
  const { tenant } = useTenant();
  const employeeId = useAppSelector(state => state.me.employment?.id);
  const invalidateArticle = useInvalidateArticle(employeeId!, articleId);

  return useMutation({
    mutationFn: async (status: SetNewsArticleStatusDTOStatusEnum) => {
      await apis.news.setNewsArticleEmployeeStatus({
        tenantId: tenant!.id,
        employeeId: employeeId!,
        articleId,
        setNewsArticleStatusDTO: {
          status,
        },
      });
    },

    onSuccess: async () => {
      await invalidateArticle();
    },
  });
}
