/* tslint:disable */
/* eslint-disable */
/**
 * Probonio full API Docs
 * If you stumbled upon this page and want to integrate Probonio into your business software [click here!](/api/external)    If you\'re looking for more information about Probonio [head over here](https://probonio.de)    **This documentation describes all Endpoints of the Probonio API. It\'s created for experienced Probonio Developers only!**  
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CreateNewsArticleDTO } from '../dto';
// @ts-ignore
import { IdentifierDTO } from '../dto';
// @ts-ignore
import { NewsArticleDTO } from '../dto';
// @ts-ignore
import { NewsArticlesListDTO } from '../dto';
// @ts-ignore
import { SetNewsArticleStatusDTO } from '../dto';
// @ts-ignore
import { UpdateNewsArticleDTO } from '../dto';
// @ts-ignore
import { UploadLinkDTO } from '../dto';
/**
 * NewsApi - axios parameter creator
 * @export
 */
export const NewsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary create a new news article
         * @param {string} tenantId 
         * @param {CreateNewsArticleDTO} createNewsArticleDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createArticle: async (tenantId: string, createNewsArticleDTO: CreateNewsArticleDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('createArticle', 'tenantId', tenantId)
            // verify required parameter 'createNewsArticleDTO' is not null or undefined
            assertParamExists('createArticle', 'createNewsArticleDTO', createNewsArticleDTO)
            const localVarPath = `/tenants/{tenantId}/benefits/news/articles`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createNewsArticleDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a form POST upload link for uploading the benefit image. Refer to https://docs.aws.amazon.com/AmazonS3/latest/API/sigv4-post-example.html on how to implement the form POST.
         * @summary create a presigned upload link
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUploadLink: async (tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('createUploadLink', 'tenantId', tenantId)
            const localVarPath = `/tenants/{tenantId}/benefits/news/articles/createUploadLink`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes a news article
         * @param {string} tenantId 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteArticle: async (tenantId: string, articleId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('deleteArticle', 'tenantId', tenantId)
            // verify required parameter 'articleId' is not null or undefined
            assertParamExists('deleteArticle', 'articleId', articleId)
            const localVarPath = `/tenants/{tenantId}/benefits/news/articles/{articleId}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"articleId"}}`, encodeURIComponent(String(articleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary expire a news article
         * @param {string} tenantId 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expireNewsArticle: async (tenantId: string, articleId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('expireNewsArticle', 'tenantId', tenantId)
            // verify required parameter 'articleId' is not null or undefined
            assertParamExists('expireNewsArticle', 'articleId', articleId)
            const localVarPath = `/tenants/{tenantId}/benefits/news/articles/{articleId}/expire`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"articleId"}}`, encodeURIComponent(String(articleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a certain news article
         * @param {string} tenantId 
         * @param {string} articleId 
         * @param {boolean} [withImage] if true, imageURL will be populated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArticle: async (tenantId: string, articleId: string, withImage?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('getArticle', 'tenantId', tenantId)
            // verify required parameter 'articleId' is not null or undefined
            assertParamExists('getArticle', 'articleId', articleId)
            const localVarPath = `/tenants/{tenantId}/benefits/news/articles/{articleId}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"articleId"}}`, encodeURIComponent(String(articleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (withImage !== undefined) {
                localVarQueryParameter['withImage'] = withImage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a certain news article with NewsArticleEmployeeStatus
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} articleId 
         * @param {boolean} [withImage] if true, imageURL will be populated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArticleWithEmployeeStatus: async (tenantId: string, employeeId: string, articleId: string, withImage?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('getArticleWithEmployeeStatus', 'tenantId', tenantId)
            // verify required parameter 'employeeId' is not null or undefined
            assertParamExists('getArticleWithEmployeeStatus', 'employeeId', employeeId)
            // verify required parameter 'articleId' is not null or undefined
            assertParamExists('getArticleWithEmployeeStatus', 'articleId', articleId)
            const localVarPath = `/tenants/{tenantId}/employees/{employeeId}/benefits/news/articles/{articleId}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"employeeId"}}`, encodeURIComponent(String(employeeId)))
                .replace(`{${"articleId"}}`, encodeURIComponent(String(articleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (withImage !== undefined) {
                localVarQueryParameter['withImage'] = withImage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all news articles for tenant
         * @param {string} tenantId 
         * @param {'ACTIVE' | 'DRAFT' | 'EXPIRED'} [status] 
         * @param {boolean} [withImage] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listArticles: async (tenantId: string, status?: 'ACTIVE' | 'DRAFT' | 'EXPIRED', withImage?: boolean, page?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('listArticles', 'tenantId', tenantId)
            const localVarPath = `/tenants/{tenantId}/benefits/news/articles`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (withImage !== undefined) {
                localVarQueryParameter['withImage'] = withImage;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all news articles for employee
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listArticlesByEmployee: async (tenantId: string, employeeId: string, page?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('listArticlesByEmployee', 'tenantId', tenantId)
            // verify required parameter 'employeeId' is not null or undefined
            assertParamExists('listArticlesByEmployee', 'employeeId', employeeId)
            const localVarPath = `/tenants/{tenantId}/employees/{employeeId}/benefits/news/articles`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"employeeId"}}`, encodeURIComponent(String(employeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary creates articleEmployee status
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} articleId 
         * @param {SetNewsArticleStatusDTO} setNewsArticleStatusDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setNewsArticleEmployeeStatus: async (tenantId: string, employeeId: string, articleId: string, setNewsArticleStatusDTO: SetNewsArticleStatusDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('setNewsArticleEmployeeStatus', 'tenantId', tenantId)
            // verify required parameter 'employeeId' is not null or undefined
            assertParamExists('setNewsArticleEmployeeStatus', 'employeeId', employeeId)
            // verify required parameter 'articleId' is not null or undefined
            assertParamExists('setNewsArticleEmployeeStatus', 'articleId', articleId)
            // verify required parameter 'setNewsArticleStatusDTO' is not null or undefined
            assertParamExists('setNewsArticleEmployeeStatus', 'setNewsArticleStatusDTO', setNewsArticleStatusDTO)
            const localVarPath = `/tenants/{tenantId}/employees/{employeeId}/benefits/news/articles/{articleId}/status`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"employeeId"}}`, encodeURIComponent(String(employeeId)))
                .replace(`{${"articleId"}}`, encodeURIComponent(String(articleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(setNewsArticleStatusDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates a news article
         * @param {string} tenantId 
         * @param {string} articleId 
         * @param {UpdateNewsArticleDTO} updateNewsArticleDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateArticle: async (tenantId: string, articleId: string, updateNewsArticleDTO: UpdateNewsArticleDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('updateArticle', 'tenantId', tenantId)
            // verify required parameter 'articleId' is not null or undefined
            assertParamExists('updateArticle', 'articleId', articleId)
            // verify required parameter 'updateNewsArticleDTO' is not null or undefined
            assertParamExists('updateArticle', 'updateNewsArticleDTO', updateNewsArticleDTO)
            const localVarPath = `/tenants/{tenantId}/benefits/news/articles/{articleId}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"articleId"}}`, encodeURIComponent(String(articleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateNewsArticleDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NewsApi - functional programming interface
 * @export
 */
export const NewsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NewsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary create a new news article
         * @param {string} tenantId 
         * @param {CreateNewsArticleDTO} createNewsArticleDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createArticle(tenantId: string, createNewsArticleDTO: CreateNewsArticleDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdentifierDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createArticle(tenantId, createNewsArticleDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create a form POST upload link for uploading the benefit image. Refer to https://docs.aws.amazon.com/AmazonS3/latest/API/sigv4-post-example.html on how to implement the form POST.
         * @summary create a presigned upload link
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUploadLink(tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadLinkDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUploadLink(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Deletes a news article
         * @param {string} tenantId 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteArticle(tenantId: string, articleId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteArticle(tenantId, articleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary expire a news article
         * @param {string} tenantId 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async expireNewsArticle(tenantId: string, articleId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.expireNewsArticle(tenantId, articleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets a certain news article
         * @param {string} tenantId 
         * @param {string} articleId 
         * @param {boolean} [withImage] if true, imageURL will be populated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getArticle(tenantId: string, articleId: string, withImage?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NewsArticleDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getArticle(tenantId, articleId, withImage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets a certain news article with NewsArticleEmployeeStatus
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} articleId 
         * @param {boolean} [withImage] if true, imageURL will be populated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getArticleWithEmployeeStatus(tenantId: string, employeeId: string, articleId: string, withImage?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NewsArticleDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getArticleWithEmployeeStatus(tenantId, employeeId, articleId, withImage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all news articles for tenant
         * @param {string} tenantId 
         * @param {'ACTIVE' | 'DRAFT' | 'EXPIRED'} [status] 
         * @param {boolean} [withImage] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listArticles(tenantId: string, status?: 'ACTIVE' | 'DRAFT' | 'EXPIRED', withImage?: boolean, page?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NewsArticlesListDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listArticles(tenantId, status, withImage, page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all news articles for employee
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listArticlesByEmployee(tenantId: string, employeeId: string, page?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NewsArticlesListDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listArticlesByEmployee(tenantId, employeeId, page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary creates articleEmployee status
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} articleId 
         * @param {SetNewsArticleStatusDTO} setNewsArticleStatusDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setNewsArticleEmployeeStatus(tenantId: string, employeeId: string, articleId: string, setNewsArticleStatusDTO: SetNewsArticleStatusDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setNewsArticleEmployeeStatus(tenantId, employeeId, articleId, setNewsArticleStatusDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates a news article
         * @param {string} tenantId 
         * @param {string} articleId 
         * @param {UpdateNewsArticleDTO} updateNewsArticleDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateArticle(tenantId: string, articleId: string, updateNewsArticleDTO: UpdateNewsArticleDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateArticle(tenantId, articleId, updateNewsArticleDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NewsApi - factory interface
 * @export
 */
export const NewsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NewsApiFp(configuration)
    return {
        /**
         * 
         * @summary create a new news article
         * @param {string} tenantId 
         * @param {CreateNewsArticleDTO} createNewsArticleDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createArticle(tenantId: string, createNewsArticleDTO: CreateNewsArticleDTO, options?: any): AxiosPromise<IdentifierDTO> {
            return localVarFp.createArticle(tenantId, createNewsArticleDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a form POST upload link for uploading the benefit image. Refer to https://docs.aws.amazon.com/AmazonS3/latest/API/sigv4-post-example.html on how to implement the form POST.
         * @summary create a presigned upload link
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUploadLink(tenantId: string, options?: any): AxiosPromise<UploadLinkDTO> {
            return localVarFp.createUploadLink(tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes a news article
         * @param {string} tenantId 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteArticle(tenantId: string, articleId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteArticle(tenantId, articleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary expire a news article
         * @param {string} tenantId 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expireNewsArticle(tenantId: string, articleId: string, options?: any): AxiosPromise<void> {
            return localVarFp.expireNewsArticle(tenantId, articleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a certain news article
         * @param {string} tenantId 
         * @param {string} articleId 
         * @param {boolean} [withImage] if true, imageURL will be populated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArticle(tenantId: string, articleId: string, withImage?: boolean, options?: any): AxiosPromise<NewsArticleDTO> {
            return localVarFp.getArticle(tenantId, articleId, withImage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a certain news article with NewsArticleEmployeeStatus
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} articleId 
         * @param {boolean} [withImage] if true, imageURL will be populated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArticleWithEmployeeStatus(tenantId: string, employeeId: string, articleId: string, withImage?: boolean, options?: any): AxiosPromise<NewsArticleDTO> {
            return localVarFp.getArticleWithEmployeeStatus(tenantId, employeeId, articleId, withImage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all news articles for tenant
         * @param {string} tenantId 
         * @param {'ACTIVE' | 'DRAFT' | 'EXPIRED'} [status] 
         * @param {boolean} [withImage] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listArticles(tenantId: string, status?: 'ACTIVE' | 'DRAFT' | 'EXPIRED', withImage?: boolean, page?: number, pageSize?: number, options?: any): AxiosPromise<NewsArticlesListDTO> {
            return localVarFp.listArticles(tenantId, status, withImage, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all news articles for employee
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listArticlesByEmployee(tenantId: string, employeeId: string, page?: number, pageSize?: number, options?: any): AxiosPromise<NewsArticlesListDTO> {
            return localVarFp.listArticlesByEmployee(tenantId, employeeId, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary creates articleEmployee status
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} articleId 
         * @param {SetNewsArticleStatusDTO} setNewsArticleStatusDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setNewsArticleEmployeeStatus(tenantId: string, employeeId: string, articleId: string, setNewsArticleStatusDTO: SetNewsArticleStatusDTO, options?: any): AxiosPromise<void> {
            return localVarFp.setNewsArticleEmployeeStatus(tenantId, employeeId, articleId, setNewsArticleStatusDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates a news article
         * @param {string} tenantId 
         * @param {string} articleId 
         * @param {UpdateNewsArticleDTO} updateNewsArticleDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateArticle(tenantId: string, articleId: string, updateNewsArticleDTO: UpdateNewsArticleDTO, options?: any): AxiosPromise<void> {
            return localVarFp.updateArticle(tenantId, articleId, updateNewsArticleDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createArticle operation in NewsApi.
 * @export
 * @interface NewsApiCreateArticleRequest
 */
export interface NewsApiCreateArticleRequest {
    /**
     * 
     * @type {string}
     * @memberof NewsApiCreateArticle
     */
    readonly tenantId: string

    /**
     * 
     * @type {CreateNewsArticleDTO}
     * @memberof NewsApiCreateArticle
     */
    readonly createNewsArticleDTO: CreateNewsArticleDTO
}

/**
 * Request parameters for createUploadLink operation in NewsApi.
 * @export
 * @interface NewsApiCreateUploadLinkRequest
 */
export interface NewsApiCreateUploadLinkRequest {
    /**
     * 
     * @type {string}
     * @memberof NewsApiCreateUploadLink
     */
    readonly tenantId: string
}

/**
 * Request parameters for deleteArticle operation in NewsApi.
 * @export
 * @interface NewsApiDeleteArticleRequest
 */
export interface NewsApiDeleteArticleRequest {
    /**
     * 
     * @type {string}
     * @memberof NewsApiDeleteArticle
     */
    readonly tenantId: string

    /**
     * 
     * @type {string}
     * @memberof NewsApiDeleteArticle
     */
    readonly articleId: string
}

/**
 * Request parameters for expireNewsArticle operation in NewsApi.
 * @export
 * @interface NewsApiExpireNewsArticleRequest
 */
export interface NewsApiExpireNewsArticleRequest {
    /**
     * 
     * @type {string}
     * @memberof NewsApiExpireNewsArticle
     */
    readonly tenantId: string

    /**
     * 
     * @type {string}
     * @memberof NewsApiExpireNewsArticle
     */
    readonly articleId: string
}

/**
 * Request parameters for getArticle operation in NewsApi.
 * @export
 * @interface NewsApiGetArticleRequest
 */
export interface NewsApiGetArticleRequest {
    /**
     * 
     * @type {string}
     * @memberof NewsApiGetArticle
     */
    readonly tenantId: string

    /**
     * 
     * @type {string}
     * @memberof NewsApiGetArticle
     */
    readonly articleId: string

    /**
     * if true, imageURL will be populated
     * @type {boolean}
     * @memberof NewsApiGetArticle
     */
    readonly withImage?: boolean
}

/**
 * Request parameters for getArticleWithEmployeeStatus operation in NewsApi.
 * @export
 * @interface NewsApiGetArticleWithEmployeeStatusRequest
 */
export interface NewsApiGetArticleWithEmployeeStatusRequest {
    /**
     * 
     * @type {string}
     * @memberof NewsApiGetArticleWithEmployeeStatus
     */
    readonly tenantId: string

    /**
     * 
     * @type {string}
     * @memberof NewsApiGetArticleWithEmployeeStatus
     */
    readonly employeeId: string

    /**
     * 
     * @type {string}
     * @memberof NewsApiGetArticleWithEmployeeStatus
     */
    readonly articleId: string

    /**
     * if true, imageURL will be populated
     * @type {boolean}
     * @memberof NewsApiGetArticleWithEmployeeStatus
     */
    readonly withImage?: boolean
}

/**
 * Request parameters for listArticles operation in NewsApi.
 * @export
 * @interface NewsApiListArticlesRequest
 */
export interface NewsApiListArticlesRequest {
    /**
     * 
     * @type {string}
     * @memberof NewsApiListArticles
     */
    readonly tenantId: string

    /**
     * 
     * @type {'ACTIVE' | 'DRAFT' | 'EXPIRED'}
     * @memberof NewsApiListArticles
     */
    readonly status?: 'ACTIVE' | 'DRAFT' | 'EXPIRED'

    /**
     * 
     * @type {boolean}
     * @memberof NewsApiListArticles
     */
    readonly withImage?: boolean

    /**
     * 
     * @type {number}
     * @memberof NewsApiListArticles
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof NewsApiListArticles
     */
    readonly pageSize?: number
}

/**
 * Request parameters for listArticlesByEmployee operation in NewsApi.
 * @export
 * @interface NewsApiListArticlesByEmployeeRequest
 */
export interface NewsApiListArticlesByEmployeeRequest {
    /**
     * 
     * @type {string}
     * @memberof NewsApiListArticlesByEmployee
     */
    readonly tenantId: string

    /**
     * 
     * @type {string}
     * @memberof NewsApiListArticlesByEmployee
     */
    readonly employeeId: string

    /**
     * 
     * @type {number}
     * @memberof NewsApiListArticlesByEmployee
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof NewsApiListArticlesByEmployee
     */
    readonly pageSize?: number
}

/**
 * Request parameters for setNewsArticleEmployeeStatus operation in NewsApi.
 * @export
 * @interface NewsApiSetNewsArticleEmployeeStatusRequest
 */
export interface NewsApiSetNewsArticleEmployeeStatusRequest {
    /**
     * 
     * @type {string}
     * @memberof NewsApiSetNewsArticleEmployeeStatus
     */
    readonly tenantId: string

    /**
     * 
     * @type {string}
     * @memberof NewsApiSetNewsArticleEmployeeStatus
     */
    readonly employeeId: string

    /**
     * 
     * @type {string}
     * @memberof NewsApiSetNewsArticleEmployeeStatus
     */
    readonly articleId: string

    /**
     * 
     * @type {SetNewsArticleStatusDTO}
     * @memberof NewsApiSetNewsArticleEmployeeStatus
     */
    readonly setNewsArticleStatusDTO: SetNewsArticleStatusDTO
}

/**
 * Request parameters for updateArticle operation in NewsApi.
 * @export
 * @interface NewsApiUpdateArticleRequest
 */
export interface NewsApiUpdateArticleRequest {
    /**
     * 
     * @type {string}
     * @memberof NewsApiUpdateArticle
     */
    readonly tenantId: string

    /**
     * 
     * @type {string}
     * @memberof NewsApiUpdateArticle
     */
    readonly articleId: string

    /**
     * 
     * @type {UpdateNewsArticleDTO}
     * @memberof NewsApiUpdateArticle
     */
    readonly updateNewsArticleDTO: UpdateNewsArticleDTO
}

/**
 * NewsApi - object-oriented interface
 * @export
 * @class NewsApi
 * @extends {BaseAPI}
 */
export class NewsApi extends BaseAPI {
    /**
     * 
     * @summary create a new news article
     * @param {NewsApiCreateArticleRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsApi
     */
    public createArticle(requestParameters: NewsApiCreateArticleRequest, options?: AxiosRequestConfig) {
        return NewsApiFp(this.configuration).createArticle(requestParameters.tenantId, requestParameters.createNewsArticleDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a form POST upload link for uploading the benefit image. Refer to https://docs.aws.amazon.com/AmazonS3/latest/API/sigv4-post-example.html on how to implement the form POST.
     * @summary create a presigned upload link
     * @param {NewsApiCreateUploadLinkRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsApi
     */
    public createUploadLink(requestParameters: NewsApiCreateUploadLinkRequest, options?: AxiosRequestConfig) {
        return NewsApiFp(this.configuration).createUploadLink(requestParameters.tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes a news article
     * @param {NewsApiDeleteArticleRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsApi
     */
    public deleteArticle(requestParameters: NewsApiDeleteArticleRequest, options?: AxiosRequestConfig) {
        return NewsApiFp(this.configuration).deleteArticle(requestParameters.tenantId, requestParameters.articleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary expire a news article
     * @param {NewsApiExpireNewsArticleRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsApi
     */
    public expireNewsArticle(requestParameters: NewsApiExpireNewsArticleRequest, options?: AxiosRequestConfig) {
        return NewsApiFp(this.configuration).expireNewsArticle(requestParameters.tenantId, requestParameters.articleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a certain news article
     * @param {NewsApiGetArticleRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsApi
     */
    public getArticle(requestParameters: NewsApiGetArticleRequest, options?: AxiosRequestConfig) {
        return NewsApiFp(this.configuration).getArticle(requestParameters.tenantId, requestParameters.articleId, requestParameters.withImage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a certain news article with NewsArticleEmployeeStatus
     * @param {NewsApiGetArticleWithEmployeeStatusRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsApi
     */
    public getArticleWithEmployeeStatus(requestParameters: NewsApiGetArticleWithEmployeeStatusRequest, options?: AxiosRequestConfig) {
        return NewsApiFp(this.configuration).getArticleWithEmployeeStatus(requestParameters.tenantId, requestParameters.employeeId, requestParameters.articleId, requestParameters.withImage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all news articles for tenant
     * @param {NewsApiListArticlesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsApi
     */
    public listArticles(requestParameters: NewsApiListArticlesRequest, options?: AxiosRequestConfig) {
        return NewsApiFp(this.configuration).listArticles(requestParameters.tenantId, requestParameters.status, requestParameters.withImage, requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all news articles for employee
     * @param {NewsApiListArticlesByEmployeeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsApi
     */
    public listArticlesByEmployee(requestParameters: NewsApiListArticlesByEmployeeRequest, options?: AxiosRequestConfig) {
        return NewsApiFp(this.configuration).listArticlesByEmployee(requestParameters.tenantId, requestParameters.employeeId, requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary creates articleEmployee status
     * @param {NewsApiSetNewsArticleEmployeeStatusRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsApi
     */
    public setNewsArticleEmployeeStatus(requestParameters: NewsApiSetNewsArticleEmployeeStatusRequest, options?: AxiosRequestConfig) {
        return NewsApiFp(this.configuration).setNewsArticleEmployeeStatus(requestParameters.tenantId, requestParameters.employeeId, requestParameters.articleId, requestParameters.setNewsArticleStatusDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates a news article
     * @param {NewsApiUpdateArticleRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsApi
     */
    public updateArticle(requestParameters: NewsApiUpdateArticleRequest, options?: AxiosRequestConfig) {
        return NewsApiFp(this.configuration).updateArticle(requestParameters.tenantId, requestParameters.articleId, requestParameters.updateNewsArticleDTO, options).then((request) => request(this.axios, this.basePath));
    }
}
