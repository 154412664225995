import { Capacitor } from '@capacitor/core';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, Dialog, IconButton, IconButtonProps, Typography, useTheme } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';

interface HighlightGuideButtonProps extends IconButtonProps {
  header: string;
  text: string;
}

export const HighlightGuideButton: React.FC<HighlightGuideButtonProps> = ({ ...props }) => {
  const theme = useTheme();
  const [show, setShow] = useState<boolean>(false);
  const localStorageKey = props.name + '_showGuide';
  const extraPaddingTop = Capacitor.getPlatform() === 'ios' ? 2 : 0;

  const handleClick = useCallback(() => {
    setShow(false);
  }, []);

  useEffect(() => {
    const storage = window.localStorage.getItem(localStorageKey);

    if (storage === 'false') {
      return;
    }

    setShow(true);
    window.localStorage.setItem(localStorageKey, 'false');
  }, [localStorageKey]);

  return (
    <>
      <IconButton {...props}>
        <InfoOutlinedIcon data-test-id="first-time-dialog-info-button" />
      </IconButton>
      <Dialog
        open={show}
        onClose={handleClick}
        fullWidth
        maxWidth="sm"
        sx={{
          ['@media (min-width:600px)']: {
            width: '450px',
            marginLeft: 'auto',
            marginRight: 'auto',
          },
        }}
        PaperProps={{
          style: {
            position: 'absolute',
            right: 0,
            top: theme.spacing(extraPaddingTop),
            margin: 0,
            width: '360px',
            height: '360px',
            backgroundColor: theme.palette.primary.main,
            borderRadius: '0 0 0 100%',
            borderLeftStyle: 'solid',
            borderBottomStyle: 'solid',
            borderWidth: 10,
            borderColor: theme.palette.primary.dark,
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            position: 'absolute',
            top: 15,
            right: 15,
          }}
        >
          <Box
            sx={{
              width: 50,
              alignSelf: 'end',
              backgroundColor: theme.palette.background.paper,
              border: 'solid',
              borderRadius: '100%',
              borderWidth: 5,
              borderColor: theme.palette.primary.light,
            }}
          >
            <IconButton {...props}>
              <InfoOutlinedIcon sx={{ color: 'inherit' }} />
            </IconButton>
          </Box>
          <Box
            sx={{
              paddingTop: 1,
              width: 270,
              textAlign: 'right',
              color: theme.palette.background.paper,
            }}
          >
            <Typography variant="h5">{props.header}</Typography>
            <Typography>{props.text}</Typography>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};
