import { useSnackbar } from 'notistack';
import { InternetReceiptRefundDTO } from 'probonio-shared-ui/api';
import { LoadingButton } from 'probonio-shared-ui/component/button';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfirmationModal } from '../../component/confirmationModal';
import { useNewDialogState } from '../../component/dialog/BasicDialog';
import { useConfirmInternetReceiptMutation } from './internetQueries';

interface Props {
  currentReceipt: InternetReceiptRefundDTO;
}

export const ConfirmationInternetButton: React.FC<Props> = ({ currentReceipt }) => {
  const { t } = useTranslation('benefitModule');
  const { enqueueSnackbar } = useSnackbar();
  const dialogState = useNewDialogState();

  const mutation = useConfirmInternetReceiptMutation();

  const handleConfirmReceipt = useCallback(() => {
    mutation.mutate(currentReceipt.id, {
      onSuccess: () => {
        enqueueSnackbar(t('benefit.INTERNET.confirmOnSucess'), { variant: 'success' });
        dialogState.dialogState.handleClose();
      },
    });
  }, [currentReceipt.id, dialogState.dialogState, enqueueSnackbar, mutation, t]);

  return (
    <>
      <LoadingButton data-test-id="confirmation-button" variant="contained" fullWidth type="submit" onClick={dialogState.handleOpen}>
        {t('benefit.INTERNET.downloadModal.title')}
      </LoadingButton>

      <ConfirmationModal
        onCancel={dialogState.dialogState.handleClose}
        onSubmit={handleConfirmReceipt}
        isOpen={dialogState.dialogState.isOpen}
        message={t('benefit.INTERNET.downloadModal.text', { monthlyAmount: currentReceipt?.total })}
        title={t('benefit.INTERNET.downloadModal.title')}
      />
    </>
  );
};
