import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import MessageLayout from '../component/layout/MessageLayout';
import { Capacitor } from '@capacitor/core';
import { useAppSelector } from '../redux/hooks';
import { LoadingLayout } from '../component/layout/LoadingLayout';

const PROBONIO_APPSTORE_LINK = 'itms-apps://apps.apple.com/de/app/probonio/id1617828643';
const PROBONIO_PLAYSTORE_LINK = 'https://play.google.com/store/apps/details?id=de.probonio.probonio';

let storeLink = '';
if (Capacitor.getPlatform() === 'ios') {
  storeLink = PROBONIO_APPSTORE_LINK;
} else if (Capacitor.getPlatform() === 'android') {
  storeLink = PROBONIO_PLAYSTORE_LINK;
}

const UpdatePage: React.FC = () => {
  const { t } = useTranslation('updatePage');
  const isLoading = useAppSelector(state => state.versionCheck.isLoading);

  return isLoading ? (
    <LoadingLayout />
  ) : (
    <MessageLayout
      title={t('title')}
      text={t('text')}
      actions={
        <Button href={storeLink} variant="contained" color="primary">
          {t('buttonText')}
        </Button>
      }
    />
  );
};

export default UpdatePage;
