import { CircularProgress, Paper } from '@mui/material';
import { UserDTOLegalTermsActionEnum } from 'probonio-shared-ui/api';
import { useTenant } from 'probonio-shared-ui/module/me';
import { useTranslation } from 'react-i18next';
import { BackIconButton } from '../../component/button/BackIconButton';
import { MobileLayout } from '../../component/layout';
import { AppToolbar } from '../../component/layout/AppToolbar';
import { useAppSelector } from '../../redux/hooks';
import { useBackNavigator } from '../navigation/BackNavigator';
import { LegalTermsInfo } from './LegalTermsInfo';

const LegalTermsPage = () => {
  const { t } = useTranslation('legalTerms');
  const backNavigator = useBackNavigator();
  const user = useAppSelector(state => state.me.user);

  const { tenant } = useTenant();

  if (!user || !tenant) {
    return <CircularProgress />;
  }

  const legalTermsAction =
    user?.legalTermsAction !== UserDTOLegalTermsActionEnum.None ? UserDTOLegalTermsActionEnum.Consent : UserDTOLegalTermsActionEnum.None;

  return (
    <MobileLayout header={<AppToolbar title={t('title')} backButton={<BackIconButton onClick={backNavigator.back} color="inherit" />} />}>
      <Paper sx={{ padding: 1 }}>
        <LegalTermsInfo user={user} tenantId={tenant.id} action={legalTermsAction} />
      </Paper>
    </MobileLayout>
  );
};

export default LegalTermsPage;
