import workersrc from 'pdfjs-dist/build/pdf.worker.min.mjs?url';

function blobToUnit8Array(blob: Blob): Promise<Uint8Array> {
  return new Promise<Uint8Array>((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.onload = event => {
      const buffer = event.target!.result as ArrayBuffer;
      resolve(new Uint8Array(buffer));
    };
    fileReader.onerror = event => {
      throw new Error('pdfToImage - Error reading file', { cause: event.target?.error });
    };
    fileReader.readAsArrayBuffer(blob);
  });
}
export const pdfToImageBlob = async (file: Blob): Promise<Blob | undefined> => {
  const PDFJS = await import('pdfjs-dist');
  PDFJS.GlobalWorkerOptions.workerSrc = workersrc;

  if (!file.type.startsWith('application/pdf')) {
    return;
  }
  const data = await blobToUnit8Array(file);
  const pdf = await PDFJS.getDocument(data).promise;
  try {
    const page = await pdf.getPage(1);
    const viewport = page.getViewport({ scale: 1.5 });
    const canvas = document.createElement('canvas');
    canvas.id = 'canvas';
    const ctx = canvas.getContext('2d');
    if (!ctx) {
      throw new Error('could not get a 2d context');
    }
    canvas.height = viewport.height;
    canvas.width = viewport.width;

    const renderContext = { canvasContext: ctx, viewport: viewport };
    await page.render(renderContext).promise;
    return (await new Promise<Blob | null>(resolve => canvas.toBlob(resolve, 'image/png'))) || undefined;
  } finally {
    await pdf.destroy();
  }
};
