import { Box, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { BackIconButton } from '../../component/button/BackIconButton';
import { MobileLayout } from '../../component/layout';
import { AppToolbar } from '../../component/layout/AppToolbar';
import { useBackNavigator } from '../../module/navigation/BackNavigator';
import { useBavTermsQuery } from './useBAVTermsQuery';
import DOMPurify from 'dompurify';

const BAVTermsPage: React.FC = () => {
  const backNavigator = useBackNavigator();
  const { data: latestBavTerms } = useBavTermsQuery();
  const { t } = useTranslation('bavModule');
  const theme = useTheme();
  return (
    <MobileLayout
      paperBackground
      header={<AppToolbar title={t('subPages.terms')} backButton={<BackIconButton onClick={backNavigator.back} />} />}
    >
      <Box sx={{ backgroundColor: theme.palette.background.paper, height: '100%' }} display="flex" flexDirection={'column'} flexGrow={1}>
        <Box width="100%" display="flex" flexDirection="column" />
        <Box p={1} flexGrow={1}>
          <Typography
            data-test-id="ui-bav-terms-html"
            color="text.secondary"
            variant="body1"
            dangerouslySetInnerHTML={{ __html: latestBavTerms?.html ? DOMPurify.sanitize(latestBavTerms.html) : '' }}
            gutterBottom
            component="div"
          />
        </Box>
      </Box>
    </MobileLayout>
  );
};
export default BAVTermsPage;
