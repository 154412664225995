import { createSvgIcon } from '@mui/material';

export const PalmTreeIcon = createSvgIcon(
  <svg id="Layer_1" height="512" viewBox="0 0 32 32" width="512" xmlns="http://www.w3.org/2000/svg">
    <g>
      <g>
        <path
          fill="currentColor"
          d="m28.291 14.038s1.178-5.469-5.639-6.211c0 0 .803-1.375 4.098-1.795 0 0-3.105-3.797-8.223.184-2.439-6.006-7.027-4.27-7.027-4.27 2.645 2.01 2.65 3.604 2.65 3.604-6.273-2.766-7.988 2.558-7.988 2.558 4.02-1.236 7.588 1.094 7.588 1.094-9.502-.16-6.174 8.471-6.174 8.471 4.363-6.568 9.324-5.385 9.324-5.385s4.891 1.455 5.385 9.326c0 0 7.197-5.811-1.113-10.424 0 0 4.258-.232 7.119 2.848z"
        />
      </g>
      <g>
        <path
          fill="currentColor"
          d="m12.344 26.312c.008-.078.014-.154.023-.23.145-1.098.354-2.188.619-3.262.77-3.107 2.043-6.137 3.785-8.844-.125-.063-.225-.107-.285-.129-.09-.014-.273-.035-.527-.035-.445 0-1.023.059-1.688.258-.225.336-.457.666-.67 1.006-.629.996-1.207 2.023-1.732 3.076-1.285 2.572-2.211 5.281-2.766 8.076-3.916.289-5.473 1.994-5.473 4.059h13.381c.001-1.919-1.341-3.524-4.667-3.975z"
        />
      </g>
    </g>
  </svg>,
  'PalmTree',
);
