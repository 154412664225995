import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import { Box, Button, Card, List, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { BackIconButton } from '../../component/button/BackIconButton';
import { MobileLayout } from '../../component/layout';
import { AppToolbar } from '../../component/layout/AppToolbar';
import { ValueListItem } from '../../component/list';
import { useBackNavigator } from '../../module/navigation/BackNavigator';
import { useBAV } from './BAVContext';
import { BavHubspot } from './BAVHubspot';

const BAVMeetingPage: React.FC = () => {
  const backNavigator = useBackNavigator();
  const { t } = useTranslation('bavModule');

  const { bavDetails } = useBAV();

  return (
    <MobileLayout
      paddedBody
      header={<AppToolbar title={t('subPages.meeting')} backButton={<BackIconButton onClick={backNavigator.back} />} />}
    >
      {bavDetails === undefined || bavDetails?.consultants === undefined || bavDetails?.consultants.length === 0 ? (
        <BavHubspot title={<Typography variant="body1">{t('meeting.info')}</Typography>} />
      ) : (
        <>
          <Typography variant="paragraph">{t('meeting.infoExternalConsultant')}</Typography>
          <Box paddingY={1}>
            <Card elevation={0} sx={{ maxWidth: '100%', marginBottom: 1, flexShrink: 0 }} data-test-id="me-info-card">
              <List>
                {Object.entries(bavDetails.consultants[0] || {}).map(([key, value]) => {
                  if (key === 'tenantId' || key === 'id') {
                    return null;
                  }
                  const label = key;
                  return <ValueListItem key={key} label={t(`meeting.${label}`)} value={value || ''} />;
                })}
              </List>
            </Card>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              href={`mailto:${bavDetails.consultants[0].mail}`}
              sx={{
                marginBottom: 1,
                marginTop: 1,
              }}
            >
              <EmailIcon sx={{ marginRight: '8px' }} />
              {t('meeting.writeMail')}
            </Button>
            <Button variant="contained" color="primary" fullWidth href={`tel:${bavDetails.consultants[0].phoneNumber}`}>
              <PhoneIcon sx={{ marginRight: '8px' }} />
              {t('meeting.call')}
            </Button>
          </Box>
        </>
      )}
    </MobileLayout>
  );
};
export default BAVMeetingPage;
