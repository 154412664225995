import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { loadVersionCheck } from './versionCheckSlice';

interface Props {
  updatePage: React.ReactNode;
}

export const VersionCheckListener: React.FC<React.PropsWithChildren<Props>> = ({ children, updatePage }) => {
  const dispatch = useAppDispatch();
  const { updateRequired, isLoading } = useAppSelector(state => state.versionCheck);

  useEffect(() => {
    void dispatch(loadVersionCheck());
  }, [dispatch]);

  return <>{updateRequired || isLoading ? updatePage : children}</>;
};
