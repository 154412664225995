import CheckIcon from '@mui/icons-material/Check';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, Card, IconButton, Slider, Typography } from '@mui/material';
import { LoadingButton } from 'probonio-shared-ui/component/button';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { InfoItem } from '../../component/infoAccordion/InfoItem';
import { BasicDialog, useNewDialogState } from '../../component/dialog/BasicDialog';
import { calculateBikeCosts } from './bikeCalculator';
import { useBikeDetailsQuery } from './useBikeDetailsQuery';

const MAX_VALUE = 5000;
const STEP_SIZE = 500;

export const BikeCalculatorForm: React.FC = () => {
  const { data: detailsBike } = useBikeDetailsQuery();
  const { t } = useTranslation('benefitModule');
  const [bikePrice, setBikePrice] = useState(1000);
  const navigate = useNavigate();
  const infoDialog = useNewDialogState();

  const purchasePrice = calculateBikeCosts(bikePrice, (detailsBike?.employerGrant || 0) / 100);

  const marks = useMemo(() => {
    const steps = new Array(MAX_VALUE / STEP_SIZE + 1).fill(0);
    return steps.map((value, index) => ({
      value: index * STEP_SIZE,
      label: index === 0 || index === steps.length - 1 ? `${(index * STEP_SIZE).toLocaleString()}€` : undefined,
    }));
  }, []);

  const handleBikePrice = useCallback((event: Event, value: number | number[]) => {
    setBikePrice(value as number);
  }, []);

  const handleLearnMore = useCallback(() => {
    navigate(`learnmore/`);
  }, [navigate]);

  return (
    <>
      <Box paddingX={1}>
        <Box data-test-id="bike-intro-text" marginBottom={1}>
          <Typography>{t('benefit.BIKE.introText')}</Typography>
        </Box>

        <Card data-test-id="bike-price-slider" sx={{ paddingX: 1, paddingBottom: 1, marginBottom: 1 }}>
          <InfoItem label={t('benefit.BIKE.calculator.title')} value={`${bikePrice.toLocaleString()}€`} />
          <Slider
            value={bikePrice}
            onChange={handleBikePrice}
            valueLabelDisplay="auto"
            marks={marks}
            min={0}
            max={MAX_VALUE}
            step={2}
            sx={{
              '& .MuiSlider-markLabel': {
                transform: 'none',
                color: 'text.primary',
                '& ~ .MuiSlider-markLabel': { transform: 'translateX(-100%)' },
              },
            }}
          />
          {detailsBike?.employerGrant ? (
            <InfoItem label={t('benefit.BIKE.employerSubsidy')} value={t('common:money', { money: detailsBike.employerGrant })} />
          ) : undefined}
          {(detailsBike?.employerExtras || []).map(extra => (
            <Box key={extra} display="flex" alignItems={'center'} gap={0.5} marginTop={1}>
              <CheckIcon color="success" />
              <Typography variant="caption" color="text.secondary">
                {t(`benefit.BIKE.employerExtras.${extra}`)}
              </Typography>
            </Box>
          ))}
        </Card>
        <Card data-test-id="bike-monthly-price" sx={{ paddingTop: 1, marginBottom: 1, textAlign: 'center' }}>
          <Box marginBottom={0.5} display="flex" justifyContent="center" alignItems="center">
            <Typography variant="caption" color="text.secondary">
              {t('benefit.BIKE.calculator.monthlyCost')}
            </Typography>
            <IconButton data-test-id="calculation-basis" edge="end" sx={{ marginY: -1 }} onClick={infoDialog.handleOpen}>
              <InfoOutlinedIcon />
            </IconButton>
          </Box>

          <Typography variant="h1" marginBottom={1}>
            {Math.trunc(purchasePrice.monthlyCosts).toLocaleString()} €
          </Typography>
        </Card>
        <Box>
          <LoadingButton data-test-id="learn-more-button" variant="contained" fullWidth type="button" onClick={handleLearnMore}>
            {t('benefit.BIKE.learnMore')}
          </LoadingButton>
        </Box>
      </Box>
      <BasicDialog
        dialogState={infoDialog.dialogState}
        title={t('benefit.BIKE.calculationBasis.title')}
        content={<Typography whiteSpace="pre-line">{t('benefit.BIKE.calculationBasis.text')}</Typography>}
        actions={null}
      />
    </>
  );
};
