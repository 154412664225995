/* tslint:disable */
/* eslint-disable */
/**
 * Probonio full API Docs
 * If you stumbled upon this page and want to integrate Probonio into your business software [click here!](/api/external)    If you\'re looking for more information about Probonio [head over here](https://probonio.de)    **This documentation describes all Endpoints of the Probonio API. It\'s created for experienced Probonio Developers only!**  
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CouponDefinitionDTO } from '../dto';
// @ts-ignore
import { CouponDefinitionListDTO } from '../dto';
// @ts-ignore
import { CouponsV2GiftDTO } from '../dto';
// @ts-ignore
import { CouponsV2GiftListDTO } from '../dto';
// @ts-ignore
import { CouponsV2GiftWithEmployeeListDTO } from '../dto';
// @ts-ignore
import { CouponsV2TokenOrderDTO } from '../dto';
// @ts-ignore
import { CouponsV2TokenOrderListDTO } from '../dto';
// @ts-ignore
import { CouponsV2UserOrderDTO } from '../dto';
// @ts-ignore
import { CouponsV2UserOrderListDTO } from '../dto';
// @ts-ignore
import { CouponsV2UserOrderWithUserListDTO } from '../dto';
// @ts-ignore
import { CouponsV2UserTokenDTO } from '../dto';
// @ts-ignore
import { CouponsV2UserTokenListDTO } from '../dto';
// @ts-ignore
import { CreateCouponsV2GiftDTO } from '../dto';
// @ts-ignore
import { IdentifierDTO } from '../dto';
// @ts-ignore
import { UpdateCouponsV2GiftDTO } from '../dto';
// @ts-ignore
import { UpdateCouponsV2UserOrderDTO } from '../dto';
/**
 * CouponsV2Api - axios parameter creator
 * @export
 */
export const CouponsV2ApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary cancels a pending token order
         * @param {string} tenantId 
         * @param {string} couponsV2TokenOrderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelTokenOrder: async (tenantId: string, couponsV2TokenOrderId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('cancelTokenOrder', 'tenantId', tenantId)
            // verify required parameter 'couponsV2TokenOrderId' is not null or undefined
            assertParamExists('cancelTokenOrder', 'couponsV2TokenOrderId', couponsV2TokenOrderId)
            const localVarPath = `/tenants/{tenantId}/benefits/coupons_v2/tokenOrders/{couponsV2TokenOrderId}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"couponsV2TokenOrderId"}}`, encodeURIComponent(String(couponsV2TokenOrderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary cancels a pending coupon user order
         * @param {string} userId 
         * @param {string} userOrderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelUserOrder: async (userId: string, userOrderId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('cancelUserOrder', 'userId', userId)
            // verify required parameter 'userOrderId' is not null or undefined
            assertParamExists('cancelUserOrder', 'userOrderId', userOrderId)
            const localVarPath = `/users/{userId}/benefits/coupons_v2/userOrders/{userOrderId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"userOrderId"}}`, encodeURIComponent(String(userOrderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary claims the gift for an employee and assigns it to the user
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} couponsV2GiftId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        claimCouponGift: async (tenantId: string, employeeId: string, couponsV2GiftId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('claimCouponGift', 'tenantId', tenantId)
            // verify required parameter 'employeeId' is not null or undefined
            assertParamExists('claimCouponGift', 'employeeId', employeeId)
            // verify required parameter 'couponsV2GiftId' is not null or undefined
            assertParamExists('claimCouponGift', 'couponsV2GiftId', couponsV2GiftId)
            const localVarPath = `/tenants/{tenantId}/employees/{employeeId}/benefits/coupons_v2/gifts/{couponsV2GiftId}/claim`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"employeeId"}}`, encodeURIComponent(String(employeeId)))
                .replace(`{${"couponsV2GiftId"}}`, encodeURIComponent(String(couponsV2GiftId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary creates a coupon gift for employee
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {CreateCouponsV2GiftDTO} createCouponsV2GiftDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCouponGift: async (tenantId: string, employeeId: string, createCouponsV2GiftDTO: CreateCouponsV2GiftDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('createCouponGift', 'tenantId', tenantId)
            // verify required parameter 'employeeId' is not null or undefined
            assertParamExists('createCouponGift', 'employeeId', employeeId)
            // verify required parameter 'createCouponsV2GiftDTO' is not null or undefined
            assertParamExists('createCouponGift', 'createCouponsV2GiftDTO', createCouponsV2GiftDTO)
            const localVarPath = `/tenants/{tenantId}/employees/{employeeId}/benefits/coupons_v2/gifts`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"employeeId"}}`, encodeURIComponent(String(employeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCouponsV2GiftDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deletes a coupon gift for employee
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} couponsV2GiftId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCouponGift: async (tenantId: string, employeeId: string, couponsV2GiftId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('deleteCouponGift', 'tenantId', tenantId)
            // verify required parameter 'employeeId' is not null or undefined
            assertParamExists('deleteCouponGift', 'employeeId', employeeId)
            // verify required parameter 'couponsV2GiftId' is not null or undefined
            assertParamExists('deleteCouponGift', 'couponsV2GiftId', couponsV2GiftId)
            const localVarPath = `/tenants/{tenantId}/employees/{employeeId}/benefits/coupons_v2/gifts/{couponsV2GiftId}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"employeeId"}}`, encodeURIComponent(String(employeeId)))
                .replace(`{${"couponsV2GiftId"}}`, encodeURIComponent(String(couponsV2GiftId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary returns the whole transaction history list in CSV format
         * @param {string} tenantId 
         * @param {string} [month] 
         * @param {'BENEFIT_ACTIVATION' | 'GIFT'} [orderReason] 
         * @param {'GIFT' | 'INCENTIVE'} [giftType] 
         * @param {Array<'INITIAL' | 'INVOICE_CREATED' | 'PAID' | 'AVAILABLE' | 'DELIVERED' | 'PAYMENT_FAILED' | 'PENDING_REFUND' | 'CANCELLED'>} [status] 
         * @param {string} [filter] 
         * @param {boolean} [withAggregations] 
         * @param {boolean} [deletedOnly] 
         * @param {Array<string>} [sortBy] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportTokenOrders: async (tenantId: string, month?: string, orderReason?: 'BENEFIT_ACTIVATION' | 'GIFT', giftType?: 'GIFT' | 'INCENTIVE', status?: Array<'INITIAL' | 'INVOICE_CREATED' | 'PAID' | 'AVAILABLE' | 'DELIVERED' | 'PAYMENT_FAILED' | 'PENDING_REFUND' | 'CANCELLED'>, filter?: string, withAggregations?: boolean, deletedOnly?: boolean, sortBy?: Array<string>, page?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('exportTokenOrders', 'tenantId', tenantId)
            const localVarPath = `/tenants/{tenantId}/benefits/coupons_v2/tokenOrders/export/csv`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (month !== undefined) {
                localVarQueryParameter['month'] = month;
            }

            if (orderReason !== undefined) {
                localVarQueryParameter['orderReason'] = orderReason;
            }

            if (giftType !== undefined) {
                localVarQueryParameter['giftType'] = giftType;
            }

            if (status) {
                localVarQueryParameter['status'] = status;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (withAggregations !== undefined) {
                localVarQueryParameter['withAggregations'] = withAggregations;
            }

            if (deletedOnly !== undefined) {
                localVarQueryParameter['deletedOnly'] = deletedOnly;
            }

            if (sortBy) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary returns coupons available to user
         * @param {string} userId 
         * @param {boolean} [includeVariations] 
         * @param {number} [variation] 
         * @param {Array<number>} [variations] 
         * @param {string} [category] 
         * @param {boolean} [includeCombinations] 
         * @param {boolean} [isFallback] 
         * @param {boolean} [isFlexFallback] 
         * @param {Array<string>} [sortBy] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findUserCouponDefinitions: async (userId: string, includeVariations?: boolean, variation?: number, variations?: Array<number>, category?: string, includeCombinations?: boolean, isFallback?: boolean, isFlexFallback?: boolean, sortBy?: Array<string>, page?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('findUserCouponDefinitions', 'userId', userId)
            const localVarPath = `/users/{userId}/benefits/coupons_v2/definitions`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (includeVariations !== undefined) {
                localVarQueryParameter['includeVariations'] = includeVariations;
            }

            if (variation !== undefined) {
                localVarQueryParameter['variation'] = variation;
            }

            if (variations) {
                localVarQueryParameter['variations'] = variations;
            }

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (includeCombinations !== undefined) {
                localVarQueryParameter['includeCombinations'] = includeCombinations;
            }

            if (isFallback !== undefined) {
                localVarQueryParameter['isFallback'] = isFallback;
            }

            if (isFlexFallback !== undefined) {
                localVarQueryParameter['isFlexFallback'] = isFlexFallback;
            }

            if (sortBy) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary returns a single coupon gift by id
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} couponsV2GiftId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCouponGift: async (tenantId: string, employeeId: string, couponsV2GiftId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('getCouponGift', 'tenantId', tenantId)
            // verify required parameter 'employeeId' is not null or undefined
            assertParamExists('getCouponGift', 'employeeId', employeeId)
            // verify required parameter 'couponsV2GiftId' is not null or undefined
            assertParamExists('getCouponGift', 'couponsV2GiftId', couponsV2GiftId)
            const localVarPath = `/tenants/{tenantId}/employees/{employeeId}/benefits/coupons_v2/gifts/{couponsV2GiftId}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"employeeId"}}`, encodeURIComponent(String(employeeId)))
                .replace(`{${"couponsV2GiftId"}}`, encodeURIComponent(String(couponsV2GiftId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get a single token order by id
         * @param {string} tenantId 
         * @param {string} couponsV2TokenOrderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTokenOrder: async (tenantId: string, couponsV2TokenOrderId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('getTokenOrder', 'tenantId', tenantId)
            // verify required parameter 'couponsV2TokenOrderId' is not null or undefined
            assertParamExists('getTokenOrder', 'couponsV2TokenOrderId', couponsV2TokenOrderId)
            const localVarPath = `/tenants/{tenantId}/benefits/coupons_v2/tokenOrders/{couponsV2TokenOrderId}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"couponsV2TokenOrderId"}}`, encodeURIComponent(String(couponsV2TokenOrderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary returns a single coupon definition for the user
         * @param {string} userId 
         * @param {string} couponDefinitionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserCouponDefinition: async (userId: string, couponDefinitionId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getUserCouponDefinition', 'userId', userId)
            // verify required parameter 'couponDefinitionId' is not null or undefined
            assertParamExists('getUserCouponDefinition', 'couponDefinitionId', couponDefinitionId)
            const localVarPath = `/users/{userId}/benefits/coupons_v2/definitions/{couponDefinitionId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"couponDefinitionId"}}`, encodeURIComponent(String(couponDefinitionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary returns a single coupon user order by id
         * @param {string} userId 
         * @param {string} userOrderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserOrder: async (userId: string, userOrderId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getUserOrder', 'userId', userId)
            // verify required parameter 'userOrderId' is not null or undefined
            assertParamExists('getUserOrder', 'userOrderId', userOrderId)
            const localVarPath = `/users/{userId}/benefits/coupons_v2/userOrders/{userOrderId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"userOrderId"}}`, encodeURIComponent(String(userOrderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary returns a single user token by id
         * @param {string} userId 
         * @param {string} userTokenId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserToken: async (userId: string, userTokenId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getUserToken', 'userId', userId)
            // verify required parameter 'userTokenId' is not null or undefined
            assertParamExists('getUserToken', 'userTokenId', userTokenId)
            const localVarPath = `/users/{userId}/benefits/coupons_v2/userTokens/{userTokenId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"userTokenId"}}`, encodeURIComponent(String(userTokenId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get all coupon orders for all users
         * @param {Array<'PENDING' | 'ORDERED' | 'COMPLETED' | 'ERROR'>} [status] 
         * @param {string} [filter] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {Array<string>} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAllOrders: async (status?: Array<'PENDING' | 'ORDERED' | 'COMPLETED' | 'ERROR'>, filter?: string, page?: number, pageSize?: number, sortBy?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/benefits/coupons_v2/userOrders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (status) {
                localVarQueryParameter['status'] = status;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (sortBy) {
                localVarQueryParameter['sortBy'] = sortBy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get all budget orders for all tenants
         * @param {string} [month] 
         * @param {'BENEFIT_ACTIVATION' | 'GIFT'} [orderReason] 
         * @param {'GIFT' | 'INCENTIVE'} [giftType] 
         * @param {Array<'INITIAL' | 'INVOICE_CREATED' | 'PAID' | 'AVAILABLE' | 'DELIVERED' | 'PAYMENT_FAILED' | 'PENDING_REFUND' | 'CANCELLED'>} [status] 
         * @param {string} [filter] 
         * @param {boolean} [withAggregations] 
         * @param {boolean} [deletedOnly] 
         * @param {Array<string>} [sortBy] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAllTokenOrders: async (month?: string, orderReason?: 'BENEFIT_ACTIVATION' | 'GIFT', giftType?: 'GIFT' | 'INCENTIVE', status?: Array<'INITIAL' | 'INVOICE_CREATED' | 'PAID' | 'AVAILABLE' | 'DELIVERED' | 'PAYMENT_FAILED' | 'PENDING_REFUND' | 'CANCELLED'>, filter?: string, withAggregations?: boolean, deletedOnly?: boolean, sortBy?: Array<string>, page?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/benefits/coupons_v2/tokenOrders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (month !== undefined) {
                localVarQueryParameter['month'] = month;
            }

            if (orderReason !== undefined) {
                localVarQueryParameter['orderReason'] = orderReason;
            }

            if (giftType !== undefined) {
                localVarQueryParameter['giftType'] = giftType;
            }

            if (status) {
                localVarQueryParameter['status'] = status;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (withAggregations !== undefined) {
                localVarQueryParameter['withAggregations'] = withAggregations;
            }

            if (deletedOnly !== undefined) {
                localVarQueryParameter['deletedOnly'] = deletedOnly;
            }

            if (sortBy) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary returns coupon gifts for employee
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCouponGifts: async (tenantId: string, employeeId: string, page?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('listCouponGifts', 'tenantId', tenantId)
            // verify required parameter 'employeeId' is not null or undefined
            assertParamExists('listCouponGifts', 'employeeId', employeeId)
            const localVarPath = `/tenants/{tenantId}/employees/{employeeId}/benefits/coupons_v2/gifts`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"employeeId"}}`, encodeURIComponent(String(employeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary returns coupon gifts for tenant
         * @param {string} tenantId 
         * @param {string} [filter] 
         * @param {boolean} [isDelivered] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {Array<string>} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTenantCouponGifts: async (tenantId: string, filter?: string, isDelivered?: boolean, page?: number, pageSize?: number, sortBy?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('listTenantCouponGifts', 'tenantId', tenantId)
            const localVarPath = `/tenants/{tenantId}/benefits/coupons_v2/gifts`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (isDelivered !== undefined) {
                localVarQueryParameter['isDelivered'] = isDelivered;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (sortBy) {
                localVarQueryParameter['sortBy'] = sortBy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary returns token orders for tenant
         * @param {string} tenantId 
         * @param {string} [month] 
         * @param {'BENEFIT_ACTIVATION' | 'GIFT'} [orderReason] 
         * @param {'GIFT' | 'INCENTIVE'} [giftType] 
         * @param {Array<'INITIAL' | 'INVOICE_CREATED' | 'PAID' | 'AVAILABLE' | 'DELIVERED' | 'PAYMENT_FAILED' | 'PENDING_REFUND' | 'CANCELLED'>} [status] 
         * @param {string} [filter] 
         * @param {boolean} [withAggregations] 
         * @param {boolean} [deletedOnly] 
         * @param {Array<string>} [sortBy] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTokenOrders: async (tenantId: string, month?: string, orderReason?: 'BENEFIT_ACTIVATION' | 'GIFT', giftType?: 'GIFT' | 'INCENTIVE', status?: Array<'INITIAL' | 'INVOICE_CREATED' | 'PAID' | 'AVAILABLE' | 'DELIVERED' | 'PAYMENT_FAILED' | 'PENDING_REFUND' | 'CANCELLED'>, filter?: string, withAggregations?: boolean, deletedOnly?: boolean, sortBy?: Array<string>, page?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('listTokenOrders', 'tenantId', tenantId)
            const localVarPath = `/tenants/{tenantId}/benefits/coupons_v2/tokenOrders`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (month !== undefined) {
                localVarQueryParameter['month'] = month;
            }

            if (orderReason !== undefined) {
                localVarQueryParameter['orderReason'] = orderReason;
            }

            if (giftType !== undefined) {
                localVarQueryParameter['giftType'] = giftType;
            }

            if (status) {
                localVarQueryParameter['status'] = status;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (withAggregations !== undefined) {
                localVarQueryParameter['withAggregations'] = withAggregations;
            }

            if (deletedOnly !== undefined) {
                localVarQueryParameter['deletedOnly'] = deletedOnly;
            }

            if (sortBy) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary returns coupon orders for user
         * @param {string} userId 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUserOrders: async (userId: string, page?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('listUserOrders', 'userId', userId)
            const localVarPath = `/users/{userId}/benefits/coupons_v2/userOrders`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary returns tokens for user
         * @param {string} userId 
         * @param {boolean} [isRedeemed] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {Array<string>} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUserTokens: async (userId: string, isRedeemed?: boolean, page?: number, pageSize?: number, sortBy?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('listUserTokens', 'userId', userId)
            const localVarPath = `/users/{userId}/benefits/coupons_v2/userTokens`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (isRedeemed !== undefined) {
                localVarQueryParameter['isRedeemed'] = isRedeemed;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (sortBy) {
                localVarQueryParameter['sortBy'] = sortBy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary sets coupon code to seen
         * @param {string} userId 
         * @param {string} userOrderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setCouponCodeToHasBeenSeen: async (userId: string, userOrderId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('setCouponCodeToHasBeenSeen', 'userId', userId)
            // verify required parameter 'userOrderId' is not null or undefined
            assertParamExists('setCouponCodeToHasBeenSeen', 'userOrderId', userOrderId)
            const localVarPath = `/users/{userId}/benefits/coupons_v2/userOrders/orderView/{userOrderId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"userOrderId"}}`, encodeURIComponent(String(userOrderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updates a coupon gift for employee
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} couponsV2GiftId 
         * @param {UpdateCouponsV2GiftDTO} updateCouponsV2GiftDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCouponGift: async (tenantId: string, employeeId: string, couponsV2GiftId: string, updateCouponsV2GiftDTO: UpdateCouponsV2GiftDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('updateCouponGift', 'tenantId', tenantId)
            // verify required parameter 'employeeId' is not null or undefined
            assertParamExists('updateCouponGift', 'employeeId', employeeId)
            // verify required parameter 'couponsV2GiftId' is not null or undefined
            assertParamExists('updateCouponGift', 'couponsV2GiftId', couponsV2GiftId)
            // verify required parameter 'updateCouponsV2GiftDTO' is not null or undefined
            assertParamExists('updateCouponGift', 'updateCouponsV2GiftDTO', updateCouponsV2GiftDTO)
            const localVarPath = `/tenants/{tenantId}/employees/{employeeId}/benefits/coupons_v2/gifts/{couponsV2GiftId}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"employeeId"}}`, encodeURIComponent(String(employeeId)))
                .replace(`{${"couponsV2GiftId"}}`, encodeURIComponent(String(couponsV2GiftId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCouponsV2GiftDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updates a coupon order for user
         * @param {string} userId 
         * @param {string} userOrderId 
         * @param {UpdateCouponsV2UserOrderDTO} updateCouponsV2UserOrderDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserOrder: async (userId: string, userOrderId: string, updateCouponsV2UserOrderDTO: UpdateCouponsV2UserOrderDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updateUserOrder', 'userId', userId)
            // verify required parameter 'userOrderId' is not null or undefined
            assertParamExists('updateUserOrder', 'userOrderId', userOrderId)
            // verify required parameter 'updateCouponsV2UserOrderDTO' is not null or undefined
            assertParamExists('updateUserOrder', 'updateCouponsV2UserOrderDTO', updateCouponsV2UserOrderDTO)
            const localVarPath = `/users/{userId}/benefits/coupons_v2/userOrders/{userOrderId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"userOrderId"}}`, encodeURIComponent(String(userOrderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCouponsV2UserOrderDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CouponsV2Api - functional programming interface
 * @export
 */
export const CouponsV2ApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CouponsV2ApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary cancels a pending token order
         * @param {string} tenantId 
         * @param {string} couponsV2TokenOrderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelTokenOrder(tenantId: string, couponsV2TokenOrderId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelTokenOrder(tenantId, couponsV2TokenOrderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary cancels a pending coupon user order
         * @param {string} userId 
         * @param {string} userOrderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelUserOrder(userId: string, userOrderId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelUserOrder(userId, userOrderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary claims the gift for an employee and assigns it to the user
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} couponsV2GiftId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async claimCouponGift(tenantId: string, employeeId: string, couponsV2GiftId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.claimCouponGift(tenantId, employeeId, couponsV2GiftId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary creates a coupon gift for employee
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {CreateCouponsV2GiftDTO} createCouponsV2GiftDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCouponGift(tenantId: string, employeeId: string, createCouponsV2GiftDTO: CreateCouponsV2GiftDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdentifierDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCouponGift(tenantId, employeeId, createCouponsV2GiftDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary deletes a coupon gift for employee
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} couponsV2GiftId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCouponGift(tenantId: string, employeeId: string, couponsV2GiftId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCouponGift(tenantId, employeeId, couponsV2GiftId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary returns the whole transaction history list in CSV format
         * @param {string} tenantId 
         * @param {string} [month] 
         * @param {'BENEFIT_ACTIVATION' | 'GIFT'} [orderReason] 
         * @param {'GIFT' | 'INCENTIVE'} [giftType] 
         * @param {Array<'INITIAL' | 'INVOICE_CREATED' | 'PAID' | 'AVAILABLE' | 'DELIVERED' | 'PAYMENT_FAILED' | 'PENDING_REFUND' | 'CANCELLED'>} [status] 
         * @param {string} [filter] 
         * @param {boolean} [withAggregations] 
         * @param {boolean} [deletedOnly] 
         * @param {Array<string>} [sortBy] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportTokenOrders(tenantId: string, month?: string, orderReason?: 'BENEFIT_ACTIVATION' | 'GIFT', giftType?: 'GIFT' | 'INCENTIVE', status?: Array<'INITIAL' | 'INVOICE_CREATED' | 'PAID' | 'AVAILABLE' | 'DELIVERED' | 'PAYMENT_FAILED' | 'PENDING_REFUND' | 'CANCELLED'>, filter?: string, withAggregations?: boolean, deletedOnly?: boolean, sortBy?: Array<string>, page?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exportTokenOrders(tenantId, month, orderReason, giftType, status, filter, withAggregations, deletedOnly, sortBy, page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary returns coupons available to user
         * @param {string} userId 
         * @param {boolean} [includeVariations] 
         * @param {number} [variation] 
         * @param {Array<number>} [variations] 
         * @param {string} [category] 
         * @param {boolean} [includeCombinations] 
         * @param {boolean} [isFallback] 
         * @param {boolean} [isFlexFallback] 
         * @param {Array<string>} [sortBy] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findUserCouponDefinitions(userId: string, includeVariations?: boolean, variation?: number, variations?: Array<number>, category?: string, includeCombinations?: boolean, isFallback?: boolean, isFlexFallback?: boolean, sortBy?: Array<string>, page?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CouponDefinitionListDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findUserCouponDefinitions(userId, includeVariations, variation, variations, category, includeCombinations, isFallback, isFlexFallback, sortBy, page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary returns a single coupon gift by id
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} couponsV2GiftId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCouponGift(tenantId: string, employeeId: string, couponsV2GiftId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CouponsV2GiftDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCouponGift(tenantId, employeeId, couponsV2GiftId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get a single token order by id
         * @param {string} tenantId 
         * @param {string} couponsV2TokenOrderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTokenOrder(tenantId: string, couponsV2TokenOrderId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CouponsV2TokenOrderDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTokenOrder(tenantId, couponsV2TokenOrderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary returns a single coupon definition for the user
         * @param {string} userId 
         * @param {string} couponDefinitionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserCouponDefinition(userId: string, couponDefinitionId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CouponDefinitionDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserCouponDefinition(userId, couponDefinitionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary returns a single coupon user order by id
         * @param {string} userId 
         * @param {string} userOrderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserOrder(userId: string, userOrderId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CouponsV2UserOrderDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserOrder(userId, userOrderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary returns a single user token by id
         * @param {string} userId 
         * @param {string} userTokenId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserToken(userId: string, userTokenId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CouponsV2UserTokenDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserToken(userId, userTokenId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get all coupon orders for all users
         * @param {Array<'PENDING' | 'ORDERED' | 'COMPLETED' | 'ERROR'>} [status] 
         * @param {string} [filter] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {Array<string>} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAllOrders(status?: Array<'PENDING' | 'ORDERED' | 'COMPLETED' | 'ERROR'>, filter?: string, page?: number, pageSize?: number, sortBy?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CouponsV2UserOrderWithUserListDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAllOrders(status, filter, page, pageSize, sortBy, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get all budget orders for all tenants
         * @param {string} [month] 
         * @param {'BENEFIT_ACTIVATION' | 'GIFT'} [orderReason] 
         * @param {'GIFT' | 'INCENTIVE'} [giftType] 
         * @param {Array<'INITIAL' | 'INVOICE_CREATED' | 'PAID' | 'AVAILABLE' | 'DELIVERED' | 'PAYMENT_FAILED' | 'PENDING_REFUND' | 'CANCELLED'>} [status] 
         * @param {string} [filter] 
         * @param {boolean} [withAggregations] 
         * @param {boolean} [deletedOnly] 
         * @param {Array<string>} [sortBy] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAllTokenOrders(month?: string, orderReason?: 'BENEFIT_ACTIVATION' | 'GIFT', giftType?: 'GIFT' | 'INCENTIVE', status?: Array<'INITIAL' | 'INVOICE_CREATED' | 'PAID' | 'AVAILABLE' | 'DELIVERED' | 'PAYMENT_FAILED' | 'PENDING_REFUND' | 'CANCELLED'>, filter?: string, withAggregations?: boolean, deletedOnly?: boolean, sortBy?: Array<string>, page?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CouponsV2TokenOrderListDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAllTokenOrders(month, orderReason, giftType, status, filter, withAggregations, deletedOnly, sortBy, page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary returns coupon gifts for employee
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listCouponGifts(tenantId: string, employeeId: string, page?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CouponsV2GiftListDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listCouponGifts(tenantId, employeeId, page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary returns coupon gifts for tenant
         * @param {string} tenantId 
         * @param {string} [filter] 
         * @param {boolean} [isDelivered] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {Array<string>} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listTenantCouponGifts(tenantId: string, filter?: string, isDelivered?: boolean, page?: number, pageSize?: number, sortBy?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CouponsV2GiftWithEmployeeListDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listTenantCouponGifts(tenantId, filter, isDelivered, page, pageSize, sortBy, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary returns token orders for tenant
         * @param {string} tenantId 
         * @param {string} [month] 
         * @param {'BENEFIT_ACTIVATION' | 'GIFT'} [orderReason] 
         * @param {'GIFT' | 'INCENTIVE'} [giftType] 
         * @param {Array<'INITIAL' | 'INVOICE_CREATED' | 'PAID' | 'AVAILABLE' | 'DELIVERED' | 'PAYMENT_FAILED' | 'PENDING_REFUND' | 'CANCELLED'>} [status] 
         * @param {string} [filter] 
         * @param {boolean} [withAggregations] 
         * @param {boolean} [deletedOnly] 
         * @param {Array<string>} [sortBy] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listTokenOrders(tenantId: string, month?: string, orderReason?: 'BENEFIT_ACTIVATION' | 'GIFT', giftType?: 'GIFT' | 'INCENTIVE', status?: Array<'INITIAL' | 'INVOICE_CREATED' | 'PAID' | 'AVAILABLE' | 'DELIVERED' | 'PAYMENT_FAILED' | 'PENDING_REFUND' | 'CANCELLED'>, filter?: string, withAggregations?: boolean, deletedOnly?: boolean, sortBy?: Array<string>, page?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CouponsV2TokenOrderListDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listTokenOrders(tenantId, month, orderReason, giftType, status, filter, withAggregations, deletedOnly, sortBy, page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary returns coupon orders for user
         * @param {string} userId 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listUserOrders(userId: string, page?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CouponsV2UserOrderListDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listUserOrders(userId, page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary returns tokens for user
         * @param {string} userId 
         * @param {boolean} [isRedeemed] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {Array<string>} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listUserTokens(userId: string, isRedeemed?: boolean, page?: number, pageSize?: number, sortBy?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CouponsV2UserTokenListDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listUserTokens(userId, isRedeemed, page, pageSize, sortBy, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary sets coupon code to seen
         * @param {string} userId 
         * @param {string} userOrderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setCouponCodeToHasBeenSeen(userId: string, userOrderId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setCouponCodeToHasBeenSeen(userId, userOrderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary updates a coupon gift for employee
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} couponsV2GiftId 
         * @param {UpdateCouponsV2GiftDTO} updateCouponsV2GiftDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCouponGift(tenantId: string, employeeId: string, couponsV2GiftId: string, updateCouponsV2GiftDTO: UpdateCouponsV2GiftDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCouponGift(tenantId, employeeId, couponsV2GiftId, updateCouponsV2GiftDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary updates a coupon order for user
         * @param {string} userId 
         * @param {string} userOrderId 
         * @param {UpdateCouponsV2UserOrderDTO} updateCouponsV2UserOrderDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserOrder(userId: string, userOrderId: string, updateCouponsV2UserOrderDTO: UpdateCouponsV2UserOrderDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserOrder(userId, userOrderId, updateCouponsV2UserOrderDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CouponsV2Api - factory interface
 * @export
 */
export const CouponsV2ApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CouponsV2ApiFp(configuration)
    return {
        /**
         * 
         * @summary cancels a pending token order
         * @param {string} tenantId 
         * @param {string} couponsV2TokenOrderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelTokenOrder(tenantId: string, couponsV2TokenOrderId: string, options?: any): AxiosPromise<void> {
            return localVarFp.cancelTokenOrder(tenantId, couponsV2TokenOrderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary cancels a pending coupon user order
         * @param {string} userId 
         * @param {string} userOrderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelUserOrder(userId: string, userOrderId: string, options?: any): AxiosPromise<void> {
            return localVarFp.cancelUserOrder(userId, userOrderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary claims the gift for an employee and assigns it to the user
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} couponsV2GiftId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        claimCouponGift(tenantId: string, employeeId: string, couponsV2GiftId: string, options?: any): AxiosPromise<void> {
            return localVarFp.claimCouponGift(tenantId, employeeId, couponsV2GiftId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary creates a coupon gift for employee
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {CreateCouponsV2GiftDTO} createCouponsV2GiftDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCouponGift(tenantId: string, employeeId: string, createCouponsV2GiftDTO: CreateCouponsV2GiftDTO, options?: any): AxiosPromise<IdentifierDTO> {
            return localVarFp.createCouponGift(tenantId, employeeId, createCouponsV2GiftDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary deletes a coupon gift for employee
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} couponsV2GiftId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCouponGift(tenantId: string, employeeId: string, couponsV2GiftId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteCouponGift(tenantId, employeeId, couponsV2GiftId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary returns the whole transaction history list in CSV format
         * @param {string} tenantId 
         * @param {string} [month] 
         * @param {'BENEFIT_ACTIVATION' | 'GIFT'} [orderReason] 
         * @param {'GIFT' | 'INCENTIVE'} [giftType] 
         * @param {Array<'INITIAL' | 'INVOICE_CREATED' | 'PAID' | 'AVAILABLE' | 'DELIVERED' | 'PAYMENT_FAILED' | 'PENDING_REFUND' | 'CANCELLED'>} [status] 
         * @param {string} [filter] 
         * @param {boolean} [withAggregations] 
         * @param {boolean} [deletedOnly] 
         * @param {Array<string>} [sortBy] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportTokenOrders(tenantId: string, month?: string, orderReason?: 'BENEFIT_ACTIVATION' | 'GIFT', giftType?: 'GIFT' | 'INCENTIVE', status?: Array<'INITIAL' | 'INVOICE_CREATED' | 'PAID' | 'AVAILABLE' | 'DELIVERED' | 'PAYMENT_FAILED' | 'PENDING_REFUND' | 'CANCELLED'>, filter?: string, withAggregations?: boolean, deletedOnly?: boolean, sortBy?: Array<string>, page?: number, pageSize?: number, options?: any): AxiosPromise<string> {
            return localVarFp.exportTokenOrders(tenantId, month, orderReason, giftType, status, filter, withAggregations, deletedOnly, sortBy, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary returns coupons available to user
         * @param {string} userId 
         * @param {boolean} [includeVariations] 
         * @param {number} [variation] 
         * @param {Array<number>} [variations] 
         * @param {string} [category] 
         * @param {boolean} [includeCombinations] 
         * @param {boolean} [isFallback] 
         * @param {boolean} [isFlexFallback] 
         * @param {Array<string>} [sortBy] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findUserCouponDefinitions(userId: string, includeVariations?: boolean, variation?: number, variations?: Array<number>, category?: string, includeCombinations?: boolean, isFallback?: boolean, isFlexFallback?: boolean, sortBy?: Array<string>, page?: number, pageSize?: number, options?: any): AxiosPromise<CouponDefinitionListDTO> {
            return localVarFp.findUserCouponDefinitions(userId, includeVariations, variation, variations, category, includeCombinations, isFallback, isFlexFallback, sortBy, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary returns a single coupon gift by id
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} couponsV2GiftId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCouponGift(tenantId: string, employeeId: string, couponsV2GiftId: string, options?: any): AxiosPromise<CouponsV2GiftDTO> {
            return localVarFp.getCouponGift(tenantId, employeeId, couponsV2GiftId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get a single token order by id
         * @param {string} tenantId 
         * @param {string} couponsV2TokenOrderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTokenOrder(tenantId: string, couponsV2TokenOrderId: string, options?: any): AxiosPromise<CouponsV2TokenOrderDTO> {
            return localVarFp.getTokenOrder(tenantId, couponsV2TokenOrderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary returns a single coupon definition for the user
         * @param {string} userId 
         * @param {string} couponDefinitionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserCouponDefinition(userId: string, couponDefinitionId: string, options?: any): AxiosPromise<CouponDefinitionDTO> {
            return localVarFp.getUserCouponDefinition(userId, couponDefinitionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary returns a single coupon user order by id
         * @param {string} userId 
         * @param {string} userOrderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserOrder(userId: string, userOrderId: string, options?: any): AxiosPromise<CouponsV2UserOrderDTO> {
            return localVarFp.getUserOrder(userId, userOrderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary returns a single user token by id
         * @param {string} userId 
         * @param {string} userTokenId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserToken(userId: string, userTokenId: string, options?: any): AxiosPromise<CouponsV2UserTokenDTO> {
            return localVarFp.getUserToken(userId, userTokenId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get all coupon orders for all users
         * @param {Array<'PENDING' | 'ORDERED' | 'COMPLETED' | 'ERROR'>} [status] 
         * @param {string} [filter] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {Array<string>} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAllOrders(status?: Array<'PENDING' | 'ORDERED' | 'COMPLETED' | 'ERROR'>, filter?: string, page?: number, pageSize?: number, sortBy?: Array<string>, options?: any): AxiosPromise<CouponsV2UserOrderWithUserListDTO> {
            return localVarFp.listAllOrders(status, filter, page, pageSize, sortBy, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get all budget orders for all tenants
         * @param {string} [month] 
         * @param {'BENEFIT_ACTIVATION' | 'GIFT'} [orderReason] 
         * @param {'GIFT' | 'INCENTIVE'} [giftType] 
         * @param {Array<'INITIAL' | 'INVOICE_CREATED' | 'PAID' | 'AVAILABLE' | 'DELIVERED' | 'PAYMENT_FAILED' | 'PENDING_REFUND' | 'CANCELLED'>} [status] 
         * @param {string} [filter] 
         * @param {boolean} [withAggregations] 
         * @param {boolean} [deletedOnly] 
         * @param {Array<string>} [sortBy] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAllTokenOrders(month?: string, orderReason?: 'BENEFIT_ACTIVATION' | 'GIFT', giftType?: 'GIFT' | 'INCENTIVE', status?: Array<'INITIAL' | 'INVOICE_CREATED' | 'PAID' | 'AVAILABLE' | 'DELIVERED' | 'PAYMENT_FAILED' | 'PENDING_REFUND' | 'CANCELLED'>, filter?: string, withAggregations?: boolean, deletedOnly?: boolean, sortBy?: Array<string>, page?: number, pageSize?: number, options?: any): AxiosPromise<CouponsV2TokenOrderListDTO> {
            return localVarFp.listAllTokenOrders(month, orderReason, giftType, status, filter, withAggregations, deletedOnly, sortBy, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary returns coupon gifts for employee
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCouponGifts(tenantId: string, employeeId: string, page?: number, pageSize?: number, options?: any): AxiosPromise<CouponsV2GiftListDTO> {
            return localVarFp.listCouponGifts(tenantId, employeeId, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary returns coupon gifts for tenant
         * @param {string} tenantId 
         * @param {string} [filter] 
         * @param {boolean} [isDelivered] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {Array<string>} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTenantCouponGifts(tenantId: string, filter?: string, isDelivered?: boolean, page?: number, pageSize?: number, sortBy?: Array<string>, options?: any): AxiosPromise<CouponsV2GiftWithEmployeeListDTO> {
            return localVarFp.listTenantCouponGifts(tenantId, filter, isDelivered, page, pageSize, sortBy, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary returns token orders for tenant
         * @param {string} tenantId 
         * @param {string} [month] 
         * @param {'BENEFIT_ACTIVATION' | 'GIFT'} [orderReason] 
         * @param {'GIFT' | 'INCENTIVE'} [giftType] 
         * @param {Array<'INITIAL' | 'INVOICE_CREATED' | 'PAID' | 'AVAILABLE' | 'DELIVERED' | 'PAYMENT_FAILED' | 'PENDING_REFUND' | 'CANCELLED'>} [status] 
         * @param {string} [filter] 
         * @param {boolean} [withAggregations] 
         * @param {boolean} [deletedOnly] 
         * @param {Array<string>} [sortBy] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTokenOrders(tenantId: string, month?: string, orderReason?: 'BENEFIT_ACTIVATION' | 'GIFT', giftType?: 'GIFT' | 'INCENTIVE', status?: Array<'INITIAL' | 'INVOICE_CREATED' | 'PAID' | 'AVAILABLE' | 'DELIVERED' | 'PAYMENT_FAILED' | 'PENDING_REFUND' | 'CANCELLED'>, filter?: string, withAggregations?: boolean, deletedOnly?: boolean, sortBy?: Array<string>, page?: number, pageSize?: number, options?: any): AxiosPromise<CouponsV2TokenOrderListDTO> {
            return localVarFp.listTokenOrders(tenantId, month, orderReason, giftType, status, filter, withAggregations, deletedOnly, sortBy, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary returns coupon orders for user
         * @param {string} userId 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUserOrders(userId: string, page?: number, pageSize?: number, options?: any): AxiosPromise<CouponsV2UserOrderListDTO> {
            return localVarFp.listUserOrders(userId, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary returns tokens for user
         * @param {string} userId 
         * @param {boolean} [isRedeemed] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {Array<string>} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUserTokens(userId: string, isRedeemed?: boolean, page?: number, pageSize?: number, sortBy?: Array<string>, options?: any): AxiosPromise<CouponsV2UserTokenListDTO> {
            return localVarFp.listUserTokens(userId, isRedeemed, page, pageSize, sortBy, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary sets coupon code to seen
         * @param {string} userId 
         * @param {string} userOrderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setCouponCodeToHasBeenSeen(userId: string, userOrderId: string, options?: any): AxiosPromise<void> {
            return localVarFp.setCouponCodeToHasBeenSeen(userId, userOrderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary updates a coupon gift for employee
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} couponsV2GiftId 
         * @param {UpdateCouponsV2GiftDTO} updateCouponsV2GiftDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCouponGift(tenantId: string, employeeId: string, couponsV2GiftId: string, updateCouponsV2GiftDTO: UpdateCouponsV2GiftDTO, options?: any): AxiosPromise<void> {
            return localVarFp.updateCouponGift(tenantId, employeeId, couponsV2GiftId, updateCouponsV2GiftDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary updates a coupon order for user
         * @param {string} userId 
         * @param {string} userOrderId 
         * @param {UpdateCouponsV2UserOrderDTO} updateCouponsV2UserOrderDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserOrder(userId: string, userOrderId: string, updateCouponsV2UserOrderDTO: UpdateCouponsV2UserOrderDTO, options?: any): AxiosPromise<void> {
            return localVarFp.updateUserOrder(userId, userOrderId, updateCouponsV2UserOrderDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for cancelTokenOrder operation in CouponsV2Api.
 * @export
 * @interface CouponsV2ApiCancelTokenOrderRequest
 */
export interface CouponsV2ApiCancelTokenOrderRequest {
    /**
     * 
     * @type {string}
     * @memberof CouponsV2ApiCancelTokenOrder
     */
    readonly tenantId: string

    /**
     * 
     * @type {string}
     * @memberof CouponsV2ApiCancelTokenOrder
     */
    readonly couponsV2TokenOrderId: string
}

/**
 * Request parameters for cancelUserOrder operation in CouponsV2Api.
 * @export
 * @interface CouponsV2ApiCancelUserOrderRequest
 */
export interface CouponsV2ApiCancelUserOrderRequest {
    /**
     * 
     * @type {string}
     * @memberof CouponsV2ApiCancelUserOrder
     */
    readonly userId: string

    /**
     * 
     * @type {string}
     * @memberof CouponsV2ApiCancelUserOrder
     */
    readonly userOrderId: string
}

/**
 * Request parameters for claimCouponGift operation in CouponsV2Api.
 * @export
 * @interface CouponsV2ApiClaimCouponGiftRequest
 */
export interface CouponsV2ApiClaimCouponGiftRequest {
    /**
     * 
     * @type {string}
     * @memberof CouponsV2ApiClaimCouponGift
     */
    readonly tenantId: string

    /**
     * 
     * @type {string}
     * @memberof CouponsV2ApiClaimCouponGift
     */
    readonly employeeId: string

    /**
     * 
     * @type {string}
     * @memberof CouponsV2ApiClaimCouponGift
     */
    readonly couponsV2GiftId: string
}

/**
 * Request parameters for createCouponGift operation in CouponsV2Api.
 * @export
 * @interface CouponsV2ApiCreateCouponGiftRequest
 */
export interface CouponsV2ApiCreateCouponGiftRequest {
    /**
     * 
     * @type {string}
     * @memberof CouponsV2ApiCreateCouponGift
     */
    readonly tenantId: string

    /**
     * 
     * @type {string}
     * @memberof CouponsV2ApiCreateCouponGift
     */
    readonly employeeId: string

    /**
     * 
     * @type {CreateCouponsV2GiftDTO}
     * @memberof CouponsV2ApiCreateCouponGift
     */
    readonly createCouponsV2GiftDTO: CreateCouponsV2GiftDTO
}

/**
 * Request parameters for deleteCouponGift operation in CouponsV2Api.
 * @export
 * @interface CouponsV2ApiDeleteCouponGiftRequest
 */
export interface CouponsV2ApiDeleteCouponGiftRequest {
    /**
     * 
     * @type {string}
     * @memberof CouponsV2ApiDeleteCouponGift
     */
    readonly tenantId: string

    /**
     * 
     * @type {string}
     * @memberof CouponsV2ApiDeleteCouponGift
     */
    readonly employeeId: string

    /**
     * 
     * @type {string}
     * @memberof CouponsV2ApiDeleteCouponGift
     */
    readonly couponsV2GiftId: string
}

/**
 * Request parameters for exportTokenOrders operation in CouponsV2Api.
 * @export
 * @interface CouponsV2ApiExportTokenOrdersRequest
 */
export interface CouponsV2ApiExportTokenOrdersRequest {
    /**
     * 
     * @type {string}
     * @memberof CouponsV2ApiExportTokenOrders
     */
    readonly tenantId: string

    /**
     * 
     * @type {string}
     * @memberof CouponsV2ApiExportTokenOrders
     */
    readonly month?: string

    /**
     * 
     * @type {'BENEFIT_ACTIVATION' | 'GIFT'}
     * @memberof CouponsV2ApiExportTokenOrders
     */
    readonly orderReason?: 'BENEFIT_ACTIVATION' | 'GIFT'

    /**
     * 
     * @type {'GIFT' | 'INCENTIVE'}
     * @memberof CouponsV2ApiExportTokenOrders
     */
    readonly giftType?: 'GIFT' | 'INCENTIVE'

    /**
     * 
     * @type {Array<'INITIAL' | 'INVOICE_CREATED' | 'PAID' | 'AVAILABLE' | 'DELIVERED' | 'PAYMENT_FAILED' | 'PENDING_REFUND' | 'CANCELLED'>}
     * @memberof CouponsV2ApiExportTokenOrders
     */
    readonly status?: Array<'INITIAL' | 'INVOICE_CREATED' | 'PAID' | 'AVAILABLE' | 'DELIVERED' | 'PAYMENT_FAILED' | 'PENDING_REFUND' | 'CANCELLED'>

    /**
     * 
     * @type {string}
     * @memberof CouponsV2ApiExportTokenOrders
     */
    readonly filter?: string

    /**
     * 
     * @type {boolean}
     * @memberof CouponsV2ApiExportTokenOrders
     */
    readonly withAggregations?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof CouponsV2ApiExportTokenOrders
     */
    readonly deletedOnly?: boolean

    /**
     * 
     * @type {Array<string>}
     * @memberof CouponsV2ApiExportTokenOrders
     */
    readonly sortBy?: Array<string>

    /**
     * 
     * @type {number}
     * @memberof CouponsV2ApiExportTokenOrders
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof CouponsV2ApiExportTokenOrders
     */
    readonly pageSize?: number
}

/**
 * Request parameters for findUserCouponDefinitions operation in CouponsV2Api.
 * @export
 * @interface CouponsV2ApiFindUserCouponDefinitionsRequest
 */
export interface CouponsV2ApiFindUserCouponDefinitionsRequest {
    /**
     * 
     * @type {string}
     * @memberof CouponsV2ApiFindUserCouponDefinitions
     */
    readonly userId: string

    /**
     * 
     * @type {boolean}
     * @memberof CouponsV2ApiFindUserCouponDefinitions
     */
    readonly includeVariations?: boolean

    /**
     * 
     * @type {number}
     * @memberof CouponsV2ApiFindUserCouponDefinitions
     */
    readonly variation?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof CouponsV2ApiFindUserCouponDefinitions
     */
    readonly variations?: Array<number>

    /**
     * 
     * @type {string}
     * @memberof CouponsV2ApiFindUserCouponDefinitions
     */
    readonly category?: string

    /**
     * 
     * @type {boolean}
     * @memberof CouponsV2ApiFindUserCouponDefinitions
     */
    readonly includeCombinations?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof CouponsV2ApiFindUserCouponDefinitions
     */
    readonly isFallback?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof CouponsV2ApiFindUserCouponDefinitions
     */
    readonly isFlexFallback?: boolean

    /**
     * 
     * @type {Array<string>}
     * @memberof CouponsV2ApiFindUserCouponDefinitions
     */
    readonly sortBy?: Array<string>

    /**
     * 
     * @type {number}
     * @memberof CouponsV2ApiFindUserCouponDefinitions
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof CouponsV2ApiFindUserCouponDefinitions
     */
    readonly pageSize?: number
}

/**
 * Request parameters for getCouponGift operation in CouponsV2Api.
 * @export
 * @interface CouponsV2ApiGetCouponGiftRequest
 */
export interface CouponsV2ApiGetCouponGiftRequest {
    /**
     * 
     * @type {string}
     * @memberof CouponsV2ApiGetCouponGift
     */
    readonly tenantId: string

    /**
     * 
     * @type {string}
     * @memberof CouponsV2ApiGetCouponGift
     */
    readonly employeeId: string

    /**
     * 
     * @type {string}
     * @memberof CouponsV2ApiGetCouponGift
     */
    readonly couponsV2GiftId: string
}

/**
 * Request parameters for getTokenOrder operation in CouponsV2Api.
 * @export
 * @interface CouponsV2ApiGetTokenOrderRequest
 */
export interface CouponsV2ApiGetTokenOrderRequest {
    /**
     * 
     * @type {string}
     * @memberof CouponsV2ApiGetTokenOrder
     */
    readonly tenantId: string

    /**
     * 
     * @type {string}
     * @memberof CouponsV2ApiGetTokenOrder
     */
    readonly couponsV2TokenOrderId: string
}

/**
 * Request parameters for getUserCouponDefinition operation in CouponsV2Api.
 * @export
 * @interface CouponsV2ApiGetUserCouponDefinitionRequest
 */
export interface CouponsV2ApiGetUserCouponDefinitionRequest {
    /**
     * 
     * @type {string}
     * @memberof CouponsV2ApiGetUserCouponDefinition
     */
    readonly userId: string

    /**
     * 
     * @type {string}
     * @memberof CouponsV2ApiGetUserCouponDefinition
     */
    readonly couponDefinitionId: string
}

/**
 * Request parameters for getUserOrder operation in CouponsV2Api.
 * @export
 * @interface CouponsV2ApiGetUserOrderRequest
 */
export interface CouponsV2ApiGetUserOrderRequest {
    /**
     * 
     * @type {string}
     * @memberof CouponsV2ApiGetUserOrder
     */
    readonly userId: string

    /**
     * 
     * @type {string}
     * @memberof CouponsV2ApiGetUserOrder
     */
    readonly userOrderId: string
}

/**
 * Request parameters for getUserToken operation in CouponsV2Api.
 * @export
 * @interface CouponsV2ApiGetUserTokenRequest
 */
export interface CouponsV2ApiGetUserTokenRequest {
    /**
     * 
     * @type {string}
     * @memberof CouponsV2ApiGetUserToken
     */
    readonly userId: string

    /**
     * 
     * @type {string}
     * @memberof CouponsV2ApiGetUserToken
     */
    readonly userTokenId: string
}

/**
 * Request parameters for listAllOrders operation in CouponsV2Api.
 * @export
 * @interface CouponsV2ApiListAllOrdersRequest
 */
export interface CouponsV2ApiListAllOrdersRequest {
    /**
     * 
     * @type {Array<'PENDING' | 'ORDERED' | 'COMPLETED' | 'ERROR'>}
     * @memberof CouponsV2ApiListAllOrders
     */
    readonly status?: Array<'PENDING' | 'ORDERED' | 'COMPLETED' | 'ERROR'>

    /**
     * 
     * @type {string}
     * @memberof CouponsV2ApiListAllOrders
     */
    readonly filter?: string

    /**
     * 
     * @type {number}
     * @memberof CouponsV2ApiListAllOrders
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof CouponsV2ApiListAllOrders
     */
    readonly pageSize?: number

    /**
     * 
     * @type {Array<string>}
     * @memberof CouponsV2ApiListAllOrders
     */
    readonly sortBy?: Array<string>
}

/**
 * Request parameters for listAllTokenOrders operation in CouponsV2Api.
 * @export
 * @interface CouponsV2ApiListAllTokenOrdersRequest
 */
export interface CouponsV2ApiListAllTokenOrdersRequest {
    /**
     * 
     * @type {string}
     * @memberof CouponsV2ApiListAllTokenOrders
     */
    readonly month?: string

    /**
     * 
     * @type {'BENEFIT_ACTIVATION' | 'GIFT'}
     * @memberof CouponsV2ApiListAllTokenOrders
     */
    readonly orderReason?: 'BENEFIT_ACTIVATION' | 'GIFT'

    /**
     * 
     * @type {'GIFT' | 'INCENTIVE'}
     * @memberof CouponsV2ApiListAllTokenOrders
     */
    readonly giftType?: 'GIFT' | 'INCENTIVE'

    /**
     * 
     * @type {Array<'INITIAL' | 'INVOICE_CREATED' | 'PAID' | 'AVAILABLE' | 'DELIVERED' | 'PAYMENT_FAILED' | 'PENDING_REFUND' | 'CANCELLED'>}
     * @memberof CouponsV2ApiListAllTokenOrders
     */
    readonly status?: Array<'INITIAL' | 'INVOICE_CREATED' | 'PAID' | 'AVAILABLE' | 'DELIVERED' | 'PAYMENT_FAILED' | 'PENDING_REFUND' | 'CANCELLED'>

    /**
     * 
     * @type {string}
     * @memberof CouponsV2ApiListAllTokenOrders
     */
    readonly filter?: string

    /**
     * 
     * @type {boolean}
     * @memberof CouponsV2ApiListAllTokenOrders
     */
    readonly withAggregations?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof CouponsV2ApiListAllTokenOrders
     */
    readonly deletedOnly?: boolean

    /**
     * 
     * @type {Array<string>}
     * @memberof CouponsV2ApiListAllTokenOrders
     */
    readonly sortBy?: Array<string>

    /**
     * 
     * @type {number}
     * @memberof CouponsV2ApiListAllTokenOrders
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof CouponsV2ApiListAllTokenOrders
     */
    readonly pageSize?: number
}

/**
 * Request parameters for listCouponGifts operation in CouponsV2Api.
 * @export
 * @interface CouponsV2ApiListCouponGiftsRequest
 */
export interface CouponsV2ApiListCouponGiftsRequest {
    /**
     * 
     * @type {string}
     * @memberof CouponsV2ApiListCouponGifts
     */
    readonly tenantId: string

    /**
     * 
     * @type {string}
     * @memberof CouponsV2ApiListCouponGifts
     */
    readonly employeeId: string

    /**
     * 
     * @type {number}
     * @memberof CouponsV2ApiListCouponGifts
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof CouponsV2ApiListCouponGifts
     */
    readonly pageSize?: number
}

/**
 * Request parameters for listTenantCouponGifts operation in CouponsV2Api.
 * @export
 * @interface CouponsV2ApiListTenantCouponGiftsRequest
 */
export interface CouponsV2ApiListTenantCouponGiftsRequest {
    /**
     * 
     * @type {string}
     * @memberof CouponsV2ApiListTenantCouponGifts
     */
    readonly tenantId: string

    /**
     * 
     * @type {string}
     * @memberof CouponsV2ApiListTenantCouponGifts
     */
    readonly filter?: string

    /**
     * 
     * @type {boolean}
     * @memberof CouponsV2ApiListTenantCouponGifts
     */
    readonly isDelivered?: boolean

    /**
     * 
     * @type {number}
     * @memberof CouponsV2ApiListTenantCouponGifts
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof CouponsV2ApiListTenantCouponGifts
     */
    readonly pageSize?: number

    /**
     * 
     * @type {Array<string>}
     * @memberof CouponsV2ApiListTenantCouponGifts
     */
    readonly sortBy?: Array<string>
}

/**
 * Request parameters for listTokenOrders operation in CouponsV2Api.
 * @export
 * @interface CouponsV2ApiListTokenOrdersRequest
 */
export interface CouponsV2ApiListTokenOrdersRequest {
    /**
     * 
     * @type {string}
     * @memberof CouponsV2ApiListTokenOrders
     */
    readonly tenantId: string

    /**
     * 
     * @type {string}
     * @memberof CouponsV2ApiListTokenOrders
     */
    readonly month?: string

    /**
     * 
     * @type {'BENEFIT_ACTIVATION' | 'GIFT'}
     * @memberof CouponsV2ApiListTokenOrders
     */
    readonly orderReason?: 'BENEFIT_ACTIVATION' | 'GIFT'

    /**
     * 
     * @type {'GIFT' | 'INCENTIVE'}
     * @memberof CouponsV2ApiListTokenOrders
     */
    readonly giftType?: 'GIFT' | 'INCENTIVE'

    /**
     * 
     * @type {Array<'INITIAL' | 'INVOICE_CREATED' | 'PAID' | 'AVAILABLE' | 'DELIVERED' | 'PAYMENT_FAILED' | 'PENDING_REFUND' | 'CANCELLED'>}
     * @memberof CouponsV2ApiListTokenOrders
     */
    readonly status?: Array<'INITIAL' | 'INVOICE_CREATED' | 'PAID' | 'AVAILABLE' | 'DELIVERED' | 'PAYMENT_FAILED' | 'PENDING_REFUND' | 'CANCELLED'>

    /**
     * 
     * @type {string}
     * @memberof CouponsV2ApiListTokenOrders
     */
    readonly filter?: string

    /**
     * 
     * @type {boolean}
     * @memberof CouponsV2ApiListTokenOrders
     */
    readonly withAggregations?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof CouponsV2ApiListTokenOrders
     */
    readonly deletedOnly?: boolean

    /**
     * 
     * @type {Array<string>}
     * @memberof CouponsV2ApiListTokenOrders
     */
    readonly sortBy?: Array<string>

    /**
     * 
     * @type {number}
     * @memberof CouponsV2ApiListTokenOrders
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof CouponsV2ApiListTokenOrders
     */
    readonly pageSize?: number
}

/**
 * Request parameters for listUserOrders operation in CouponsV2Api.
 * @export
 * @interface CouponsV2ApiListUserOrdersRequest
 */
export interface CouponsV2ApiListUserOrdersRequest {
    /**
     * 
     * @type {string}
     * @memberof CouponsV2ApiListUserOrders
     */
    readonly userId: string

    /**
     * 
     * @type {number}
     * @memberof CouponsV2ApiListUserOrders
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof CouponsV2ApiListUserOrders
     */
    readonly pageSize?: number
}

/**
 * Request parameters for listUserTokens operation in CouponsV2Api.
 * @export
 * @interface CouponsV2ApiListUserTokensRequest
 */
export interface CouponsV2ApiListUserTokensRequest {
    /**
     * 
     * @type {string}
     * @memberof CouponsV2ApiListUserTokens
     */
    readonly userId: string

    /**
     * 
     * @type {boolean}
     * @memberof CouponsV2ApiListUserTokens
     */
    readonly isRedeemed?: boolean

    /**
     * 
     * @type {number}
     * @memberof CouponsV2ApiListUserTokens
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof CouponsV2ApiListUserTokens
     */
    readonly pageSize?: number

    /**
     * 
     * @type {Array<string>}
     * @memberof CouponsV2ApiListUserTokens
     */
    readonly sortBy?: Array<string>
}

/**
 * Request parameters for setCouponCodeToHasBeenSeen operation in CouponsV2Api.
 * @export
 * @interface CouponsV2ApiSetCouponCodeToHasBeenSeenRequest
 */
export interface CouponsV2ApiSetCouponCodeToHasBeenSeenRequest {
    /**
     * 
     * @type {string}
     * @memberof CouponsV2ApiSetCouponCodeToHasBeenSeen
     */
    readonly userId: string

    /**
     * 
     * @type {string}
     * @memberof CouponsV2ApiSetCouponCodeToHasBeenSeen
     */
    readonly userOrderId: string
}

/**
 * Request parameters for updateCouponGift operation in CouponsV2Api.
 * @export
 * @interface CouponsV2ApiUpdateCouponGiftRequest
 */
export interface CouponsV2ApiUpdateCouponGiftRequest {
    /**
     * 
     * @type {string}
     * @memberof CouponsV2ApiUpdateCouponGift
     */
    readonly tenantId: string

    /**
     * 
     * @type {string}
     * @memberof CouponsV2ApiUpdateCouponGift
     */
    readonly employeeId: string

    /**
     * 
     * @type {string}
     * @memberof CouponsV2ApiUpdateCouponGift
     */
    readonly couponsV2GiftId: string

    /**
     * 
     * @type {UpdateCouponsV2GiftDTO}
     * @memberof CouponsV2ApiUpdateCouponGift
     */
    readonly updateCouponsV2GiftDTO: UpdateCouponsV2GiftDTO
}

/**
 * Request parameters for updateUserOrder operation in CouponsV2Api.
 * @export
 * @interface CouponsV2ApiUpdateUserOrderRequest
 */
export interface CouponsV2ApiUpdateUserOrderRequest {
    /**
     * 
     * @type {string}
     * @memberof CouponsV2ApiUpdateUserOrder
     */
    readonly userId: string

    /**
     * 
     * @type {string}
     * @memberof CouponsV2ApiUpdateUserOrder
     */
    readonly userOrderId: string

    /**
     * 
     * @type {UpdateCouponsV2UserOrderDTO}
     * @memberof CouponsV2ApiUpdateUserOrder
     */
    readonly updateCouponsV2UserOrderDTO: UpdateCouponsV2UserOrderDTO
}

/**
 * CouponsV2Api - object-oriented interface
 * @export
 * @class CouponsV2Api
 * @extends {BaseAPI}
 */
export class CouponsV2Api extends BaseAPI {
    /**
     * 
     * @summary cancels a pending token order
     * @param {CouponsV2ApiCancelTokenOrderRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CouponsV2Api
     */
    public cancelTokenOrder(requestParameters: CouponsV2ApiCancelTokenOrderRequest, options?: AxiosRequestConfig) {
        return CouponsV2ApiFp(this.configuration).cancelTokenOrder(requestParameters.tenantId, requestParameters.couponsV2TokenOrderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary cancels a pending coupon user order
     * @param {CouponsV2ApiCancelUserOrderRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CouponsV2Api
     */
    public cancelUserOrder(requestParameters: CouponsV2ApiCancelUserOrderRequest, options?: AxiosRequestConfig) {
        return CouponsV2ApiFp(this.configuration).cancelUserOrder(requestParameters.userId, requestParameters.userOrderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary claims the gift for an employee and assigns it to the user
     * @param {CouponsV2ApiClaimCouponGiftRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CouponsV2Api
     */
    public claimCouponGift(requestParameters: CouponsV2ApiClaimCouponGiftRequest, options?: AxiosRequestConfig) {
        return CouponsV2ApiFp(this.configuration).claimCouponGift(requestParameters.tenantId, requestParameters.employeeId, requestParameters.couponsV2GiftId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary creates a coupon gift for employee
     * @param {CouponsV2ApiCreateCouponGiftRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CouponsV2Api
     */
    public createCouponGift(requestParameters: CouponsV2ApiCreateCouponGiftRequest, options?: AxiosRequestConfig) {
        return CouponsV2ApiFp(this.configuration).createCouponGift(requestParameters.tenantId, requestParameters.employeeId, requestParameters.createCouponsV2GiftDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary deletes a coupon gift for employee
     * @param {CouponsV2ApiDeleteCouponGiftRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CouponsV2Api
     */
    public deleteCouponGift(requestParameters: CouponsV2ApiDeleteCouponGiftRequest, options?: AxiosRequestConfig) {
        return CouponsV2ApiFp(this.configuration).deleteCouponGift(requestParameters.tenantId, requestParameters.employeeId, requestParameters.couponsV2GiftId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary returns the whole transaction history list in CSV format
     * @param {CouponsV2ApiExportTokenOrdersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CouponsV2Api
     */
    public exportTokenOrders(requestParameters: CouponsV2ApiExportTokenOrdersRequest, options?: AxiosRequestConfig) {
        return CouponsV2ApiFp(this.configuration).exportTokenOrders(requestParameters.tenantId, requestParameters.month, requestParameters.orderReason, requestParameters.giftType, requestParameters.status, requestParameters.filter, requestParameters.withAggregations, requestParameters.deletedOnly, requestParameters.sortBy, requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary returns coupons available to user
     * @param {CouponsV2ApiFindUserCouponDefinitionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CouponsV2Api
     */
    public findUserCouponDefinitions(requestParameters: CouponsV2ApiFindUserCouponDefinitionsRequest, options?: AxiosRequestConfig) {
        return CouponsV2ApiFp(this.configuration).findUserCouponDefinitions(requestParameters.userId, requestParameters.includeVariations, requestParameters.variation, requestParameters.variations, requestParameters.category, requestParameters.includeCombinations, requestParameters.isFallback, requestParameters.isFlexFallback, requestParameters.sortBy, requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary returns a single coupon gift by id
     * @param {CouponsV2ApiGetCouponGiftRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CouponsV2Api
     */
    public getCouponGift(requestParameters: CouponsV2ApiGetCouponGiftRequest, options?: AxiosRequestConfig) {
        return CouponsV2ApiFp(this.configuration).getCouponGift(requestParameters.tenantId, requestParameters.employeeId, requestParameters.couponsV2GiftId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get a single token order by id
     * @param {CouponsV2ApiGetTokenOrderRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CouponsV2Api
     */
    public getTokenOrder(requestParameters: CouponsV2ApiGetTokenOrderRequest, options?: AxiosRequestConfig) {
        return CouponsV2ApiFp(this.configuration).getTokenOrder(requestParameters.tenantId, requestParameters.couponsV2TokenOrderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary returns a single coupon definition for the user
     * @param {CouponsV2ApiGetUserCouponDefinitionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CouponsV2Api
     */
    public getUserCouponDefinition(requestParameters: CouponsV2ApiGetUserCouponDefinitionRequest, options?: AxiosRequestConfig) {
        return CouponsV2ApiFp(this.configuration).getUserCouponDefinition(requestParameters.userId, requestParameters.couponDefinitionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary returns a single coupon user order by id
     * @param {CouponsV2ApiGetUserOrderRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CouponsV2Api
     */
    public getUserOrder(requestParameters: CouponsV2ApiGetUserOrderRequest, options?: AxiosRequestConfig) {
        return CouponsV2ApiFp(this.configuration).getUserOrder(requestParameters.userId, requestParameters.userOrderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary returns a single user token by id
     * @param {CouponsV2ApiGetUserTokenRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CouponsV2Api
     */
    public getUserToken(requestParameters: CouponsV2ApiGetUserTokenRequest, options?: AxiosRequestConfig) {
        return CouponsV2ApiFp(this.configuration).getUserToken(requestParameters.userId, requestParameters.userTokenId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get all coupon orders for all users
     * @param {CouponsV2ApiListAllOrdersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CouponsV2Api
     */
    public listAllOrders(requestParameters: CouponsV2ApiListAllOrdersRequest = {}, options?: AxiosRequestConfig) {
        return CouponsV2ApiFp(this.configuration).listAllOrders(requestParameters.status, requestParameters.filter, requestParameters.page, requestParameters.pageSize, requestParameters.sortBy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get all budget orders for all tenants
     * @param {CouponsV2ApiListAllTokenOrdersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CouponsV2Api
     */
    public listAllTokenOrders(requestParameters: CouponsV2ApiListAllTokenOrdersRequest = {}, options?: AxiosRequestConfig) {
        return CouponsV2ApiFp(this.configuration).listAllTokenOrders(requestParameters.month, requestParameters.orderReason, requestParameters.giftType, requestParameters.status, requestParameters.filter, requestParameters.withAggregations, requestParameters.deletedOnly, requestParameters.sortBy, requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary returns coupon gifts for employee
     * @param {CouponsV2ApiListCouponGiftsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CouponsV2Api
     */
    public listCouponGifts(requestParameters: CouponsV2ApiListCouponGiftsRequest, options?: AxiosRequestConfig) {
        return CouponsV2ApiFp(this.configuration).listCouponGifts(requestParameters.tenantId, requestParameters.employeeId, requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary returns coupon gifts for tenant
     * @param {CouponsV2ApiListTenantCouponGiftsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CouponsV2Api
     */
    public listTenantCouponGifts(requestParameters: CouponsV2ApiListTenantCouponGiftsRequest, options?: AxiosRequestConfig) {
        return CouponsV2ApiFp(this.configuration).listTenantCouponGifts(requestParameters.tenantId, requestParameters.filter, requestParameters.isDelivered, requestParameters.page, requestParameters.pageSize, requestParameters.sortBy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary returns token orders for tenant
     * @param {CouponsV2ApiListTokenOrdersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CouponsV2Api
     */
    public listTokenOrders(requestParameters: CouponsV2ApiListTokenOrdersRequest, options?: AxiosRequestConfig) {
        return CouponsV2ApiFp(this.configuration).listTokenOrders(requestParameters.tenantId, requestParameters.month, requestParameters.orderReason, requestParameters.giftType, requestParameters.status, requestParameters.filter, requestParameters.withAggregations, requestParameters.deletedOnly, requestParameters.sortBy, requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary returns coupon orders for user
     * @param {CouponsV2ApiListUserOrdersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CouponsV2Api
     */
    public listUserOrders(requestParameters: CouponsV2ApiListUserOrdersRequest, options?: AxiosRequestConfig) {
        return CouponsV2ApiFp(this.configuration).listUserOrders(requestParameters.userId, requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary returns tokens for user
     * @param {CouponsV2ApiListUserTokensRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CouponsV2Api
     */
    public listUserTokens(requestParameters: CouponsV2ApiListUserTokensRequest, options?: AxiosRequestConfig) {
        return CouponsV2ApiFp(this.configuration).listUserTokens(requestParameters.userId, requestParameters.isRedeemed, requestParameters.page, requestParameters.pageSize, requestParameters.sortBy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary sets coupon code to seen
     * @param {CouponsV2ApiSetCouponCodeToHasBeenSeenRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CouponsV2Api
     */
    public setCouponCodeToHasBeenSeen(requestParameters: CouponsV2ApiSetCouponCodeToHasBeenSeenRequest, options?: AxiosRequestConfig) {
        return CouponsV2ApiFp(this.configuration).setCouponCodeToHasBeenSeen(requestParameters.userId, requestParameters.userOrderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary updates a coupon gift for employee
     * @param {CouponsV2ApiUpdateCouponGiftRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CouponsV2Api
     */
    public updateCouponGift(requestParameters: CouponsV2ApiUpdateCouponGiftRequest, options?: AxiosRequestConfig) {
        return CouponsV2ApiFp(this.configuration).updateCouponGift(requestParameters.tenantId, requestParameters.employeeId, requestParameters.couponsV2GiftId, requestParameters.updateCouponsV2GiftDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary updates a coupon order for user
     * @param {CouponsV2ApiUpdateUserOrderRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CouponsV2Api
     */
    public updateUserOrder(requestParameters: CouponsV2ApiUpdateUserOrderRequest, options?: AxiosRequestConfig) {
        return CouponsV2ApiFp(this.configuration).updateUserOrder(requestParameters.userId, requestParameters.userOrderId, requestParameters.updateCouponsV2UserOrderDTO, options).then((request) => request(this.axios, this.basePath));
    }
}
