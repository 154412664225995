/* tslint:disable */
/* eslint-disable */
/**
 * Probonio full API Docs
 * If you stumbled upon this page and want to integrate Probonio into your business software [click here!](/api/external)    If you\'re looking for more information about Probonio [head over here](https://probonio.de)    **This documentation describes all Endpoints of the Probonio API. It\'s created for experienced Probonio Developers only!**  
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface CouponsV2GiftDTO
 */
export interface CouponsV2GiftDTO {
    /**
     * 
     * @type {string}
     * @memberof CouponsV2GiftDTO
     */
    'occasion': CouponsV2GiftDTOOccasionEnum;
    /**
     * 
     * @type {string}
     * @memberof CouponsV2GiftDTO
     */
    'giftType': CouponsV2GiftDTOGiftTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CouponsV2GiftDTO
     */
    'status'?: CouponsV2GiftDTOStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof CouponsV2GiftDTO
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CouponsV2GiftDTO
     */
    'description'?: string;
    /**
     * 
     * @type {number}
     * @memberof CouponsV2GiftDTO
     */
    'amount': number;
    /**
     * 
     * @type {string}
     * @memberof CouponsV2GiftDTO
     */
    'payoutDate': string;
    /**
     * 
     * @type {boolean}
     * @memberof CouponsV2GiftDTO
     */
    'recurring'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CouponsV2GiftDTO
     */
    'inviteWhenActive'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CouponsV2GiftDTO
     */
    'sendMailWhenActive'?: boolean;
}

export const CouponsV2GiftDTOOccasionEnum = {
    Birthday: 'BIRTHDAY',
    SaintsDay: 'SAINTS_DAY',
    ChildBirth: 'CHILD_BIRTH',
    ChildBaptism: 'CHILD_BAPTISM',
    ChildCommunion: 'CHILD_COMMUNION',
    ChildConfirmationCath: 'CHILD_CONFIRMATION_CATH',
    ChildConfirmationProt: 'CHILD_CONFIRMATION_PROT',
    Wedding: 'WEDDING',
    WeddingSilver: 'WEDDING_SILVER',
    WeddingGold: 'WEDDING_GOLD',
    PassedExam: 'PASSED_EXAM',
    CompanyAnniversary: 'COMPANY_ANNIVERSARY',
    Achievement: 'ACHIEVEMENT',
    WorkPerformance: 'WORK_PERFORMANCE',
    Commitment: 'COMMITMENT',
    Proposal: 'PROPOSAL',
    Recruitment: 'RECRUITMENT',
    Attendance: 'ATTENDANCE',
    SigningBonus: 'SIGNING_BONUS',
    ProfitSharing: 'PROFIT_SHARING',
    Other: 'OTHER'
} as const;

export type CouponsV2GiftDTOOccasionEnum = typeof CouponsV2GiftDTOOccasionEnum[keyof typeof CouponsV2GiftDTOOccasionEnum];
export const CouponsV2GiftDTOGiftTypeEnum = {
    Gift: 'GIFT',
    Incentive: 'INCENTIVE'
} as const;

export type CouponsV2GiftDTOGiftTypeEnum = typeof CouponsV2GiftDTOGiftTypeEnum[keyof typeof CouponsV2GiftDTOGiftTypeEnum];
export const CouponsV2GiftDTOStatusEnum = {
    Pending: 'PENDING',
    InvoiceCreated: 'INVOICE_CREATED',
    Available: 'AVAILABLE',
    Delivered: 'DELIVERED'
} as const;

export type CouponsV2GiftDTOStatusEnum = typeof CouponsV2GiftDTOStatusEnum[keyof typeof CouponsV2GiftDTOStatusEnum];


