import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import CheckIcon from '@mui/icons-material/Check';
import { Box, Divider, Paper, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { BackIconButton } from '../../component/button/BackIconButton';
import { MobileLayout } from '../../component/layout';
import { AppToolbar } from '../../component/layout/AppToolbar';
import { TenantLogo } from '../../component/layout/TenantLogo';
import { useCouponsV2BenefitDetails } from '../../module/couponsV2/couponsV2Queries';
import { useBackNavigator } from '../../module/navigation/BackNavigator';

interface TextProps {
  children: React.ReactNode;
}

const CouponConditionText: React.FC<TextProps> = ({ children }) => {
  return (
    <Box marginLeft={0.3} marginRight={0.5} marginBottom={0.3} display="flex" alignItems={'center'} gap={0.5}>
      <CardGiftcardIcon color="success" />
      <Typography variant="body2">{children}</Typography>
    </Box>
  );
};

const CouponConditions: React.FC = () => {
  const { t } = useTranslation('benefitModule');

  return (
    <Paper elevation={0} sx={{ paddingTop: 0.3 }}>
      <Box sx={{ ml: 0.8 }}>
        <Typography variant="descriptionBold">{t('benefit.COUPONS.infoPage.requiredHints.title')}</Typography>
        <Divider variant={'fullWidth'} sx={{ marginRight: 0.3, marginBottom: 0.3, marginTop: 0.1 }} />
        <CouponConditionText>{t('benefit.COUPONS.infoPage.requiredHints.attention')}</CouponConditionText>
        <CouponConditionText>{t('benefit.COUPONS.infoPage.requiredHints.return')}</CouponConditionText>
        <CouponConditionText>{t('benefit.COUPONS.infoPage.requiredHints.collectionPossible')}</CouponConditionText>
        <CouponConditionText>{t('benefit.COUPONS.infoPage.requiredHints.checkAmount')}</CouponConditionText>
        <CouponConditionText>{t('benefit.COUPONS.infoPage.requiredHints.validity')}</CouponConditionText>
      </Box>
    </Paper>
  );
};

const CouponBenefitsText: React.FC<TextProps> = ({ children }) => {
  return (
    <Box marginLeft={0.3} marginRight={0.5} marginBottom={0.3} display="flex" alignItems={'center'} gap={0.5}>
      <CheckIcon color="success" />
      <Typography variant="body2">{children}</Typography>
    </Box>
  );
};

const CouponStrongText: React.FC<TextProps> = ({ children }) => {
  return (
    <Box marginLeft={0.3} marginRight={0.5} marginBottom={0.3} display="flex" alignItems={'center'} gap={0.5}>
      <CheckIcon color="success" />
      <Typography variant="body2">
        <Trans>{children}</Trans>
      </Typography>
    </Box>
  );
};

const CouponBenefits: React.FC = () => {
  const { t } = useTranslation('benefitModule');
  const benefitDetails = useCouponsV2BenefitDetails();

  return (
    <Paper elevation={0} sx={{ paddingTop: 0.3 }}>
      <Box sx={{ ml: 0.8 }}>
        <Typography variant="descriptionBold">{t('benefit.COUPONS_V2.infoPage.howItWorks.title')}</Typography>
        <Divider variant={'fullWidth'} sx={{ marginRight: 0.3, marginBottom: 0.3, marginTop: 0.1 }} />
        {benefitDetails?.couponMonthlyBudget && (
          <CouponBenefitsText>
            {t('benefit.COUPONS_V2.infoPage.howItWorks.amount', {
              amountPerVoucher: benefitDetails?.couponMonthlyBudget,
            })}
          </CouponBenefitsText>
        )}
        <CouponBenefitsText>{t('benefit.COUPONS_V2.infoPage.howItWorks.redemption')}</CouponBenefitsText>
        <CouponStrongText>{t('benefit.COUPONS_V2.infoPage.howItWorks.oneToOne')}</CouponStrongText>
        <CouponBenefitsText>{t('benefit.COUPONS_V2.infoPage.howItWorks.disclaimer')}</CouponBenefitsText>
      </Box>
    </Paper>
  );
};

const CouponsV2InfoPage: React.FC = () => {
  const backNavigator = useBackNavigator();
  const { t } = useTranslation('benefitModule');

  return (
    <MobileLayout
      header={<AppToolbar title={t('benefit.COUPONS.infoPage.title')} backButton={<BackIconButton onClick={backNavigator.back} />} />}
    >
      <Box
        display="flex"
        flexDirection="column"
        height={'auto'}
        justifyContent="space-between"
        alignItems="center"
        marginX={1}
        gap={0.8}
        marginBottom={0.7}
      >
        <Box
          borderRadius={'8px'}
          component="img"
          width={'100%'}
          sx={{
            maxWidth: 410,
          }}
          src="/image/coupon-info-picture-scaled.jpg"
        />
        <CouponConditions />
        <CouponBenefits />
        <TenantLogo sx={{ width: '80%', textAlign: 'center' }} />
      </Box>
    </MobileLayout>
  );
};
export default CouponsV2InfoPage;
