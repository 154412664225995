import { UseQueryResult } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { BAVTermsDTO } from 'probonio-shared-ui/api';
import { apis, useTenantQuery } from 'probonio-shared-ui/module/api';
import { useWithMessage } from 'probonio-shared-ui/module/error';
import { useTenant } from 'probonio-shared-ui/module/me';

export function useBavTermsQuery(options?: { enabled?: boolean }): UseQueryResult<null | BAVTermsDTO, unknown> {
  const withMessage = useWithMessage();
  const { tenant } = useTenant();

  const queryResult = useTenantQuery(
    ['benefits', 'bav', 'terms', 'latest', tenant?.id, apis.bav.getLatestTerms.name],
    tenantId =>
      apis.bav
        .getLatestTerms({ tenantId })
        .then(res => res.data)
        .catch(error => {
          if (!(error instanceof AxiosError && error.response?.status === 404)) {
            withMessage(error as Error);
          }
          return null;
        }),
    {
      ...options,
    },
  );

  return queryResult;
}
