import { captureException, withScope } from '@sentry/browser';
import { isError } from '@sentry/utils';
import React, { Component, createContext, ReactNode } from 'react';
import { MessageError } from '.';
import { withLogoutHOC } from './withLoginContextHOC';

function isAtLeastReact17(version: string): boolean {
  const major = version.match(/^([^.]+)/);
  return major !== null && parseInt(major[0]) >= 17;
}

/**
 * @returns true if the error has been handled, false otherwise
 */
type ErrorHandler = (error: unknown) => boolean | undefined;

const defaultErrorHandler: ErrorHandler = err => {
  // eslint-disable-next-line no-console
  console.error(err);
  return true;
};

export const ErrorHandlerContext = createContext<ErrorHandler>(defaultErrorHandler);
interface Props {
  children: ReactNode;
  errorHandler?: ErrorHandler;
  logout?: () => {};
  ErrorFallback: React.ComponentType<Pick<Props, 'logout'> & { eventId?: string }>;
}

interface State {
  hasError: boolean;
  eventId?: string;
}

// https://eddiewould.com/2021/28/28/handling-rejected-promises-error-boundary-react/
// This is more easy with a class based component.
export class GlobalErrorCatcher extends Component<Props, State> {
  public state: State = { hasError: false };

  public constructor(props: Props) {
    super(props);
    this.handleError = this.handleError.bind(this);
  }

  public componentDidMount(): void {
    // Add an event listener to the window to catch unhandled promise rejections & stash the error in the state
    window.addEventListener('unhandledrejection', this.promiseRejectionHandler);
    window.addEventListener('error', this.windowErrorHandler);
  }

  private handleError(error: unknown, componentStack?: string | null): boolean {
    if (this.props.errorHandler?.(error)) {
      return true;
    }
    withScope(() => {
      if (isAtLeastReact17(React.version) && isError(error)) {
        const errorBoundaryError = new Error(error.message);
        errorBoundaryError.name = `React ErrorBoundary ${errorBoundaryError.name}`;
        errorBoundaryError.stack = componentStack || undefined;

        // Using the `LinkedErrors` integration to link the errors together.
        error.cause = errorBoundaryError;
      }

      const eventId = captureException(error, { contexts: { react: { componentStack } } });

      this.setState({ hasError: true, eventId });
    });
    return true;
  }

  public componentDidCatch(error: unknown, { componentStack }: React.ErrorInfo): void {
    this.handleError(error, componentStack);
  }

  public componentWillUnmount(): void {
    window.removeEventListener('unhandledrejection', this.promiseRejectionHandler);
    window.removeEventListener('error', this.windowErrorHandler);
  }

  private promiseRejectionHandler = (event: PromiseRejectionEvent): void => {
    let err: Error;
    if (event.reason instanceof MessageError) {
      err = event.reason;
    } else {
      err = new MessageError(event.reason);
    }

    this.handleError(err);
    event.preventDefault();
  };

  private windowErrorHandler = (event: ErrorEvent): void => {
    // window.onerror is called twice, see https://github.com/facebook/react/issues/11499
    if (event.error?.isHandled) {
      event.preventDefault();
      return;
    }

    let err: Error;
    // https://blog.sentry.io/client-javascript-reporting-window-onerror/ depending on browser version no error obj might be assigned
    if (!event.error) {
      err = new MessageError(new Error('Original ErrorEvent contains no error object'));
      this.handleError(err);
      event.preventDefault();
      return;
    }

    event.error.isHandled = true;

    if (event.error instanceof MessageError) {
      err = event.error;
    } else {
      err = new MessageError(event.error);
    }

    this.handleError(err);
    event.preventDefault();
  };

  public render(): ReactNode {
    const { children, ErrorFallback } = this.props;

    return (
      <ErrorHandlerContext.Provider value={this.handleError}>
        {this.state.hasError ? <ErrorFallback {...this.props} eventId={this.state.eventId} /> : children}
      </ErrorHandlerContext.Provider>
    );
  }
}

export const GlobalErrorCatcherWithLogout = withLogoutHOC(GlobalErrorCatcher);
