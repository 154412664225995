import { CouponDefinitionDTO } from 'probonio-shared-ui/api';

const COUPON_IMAGES = import.meta.glob('./coupons-img/*.png', { query: '?url', import: 'default', eager: true }) as Record<string, string>;

export function getCouponImageUrl(couponData: Pick<CouponDefinitionDTO, 'productNumber' | 'imageUrl'> | undefined): {
  url: string;
  isSuccess: boolean;
} {
  if (!couponData) {
    return {
      url: '',
      isSuccess: false,
    };
  }

  const imagePath = `./coupons-img/${couponData.productNumber}.png`;
  if (couponData.productNumber && COUPON_IMAGES[imagePath]) {
    return { url: COUPON_IMAGES[imagePath], isSuccess: true };
  }
  return { url: couponData.imageUrl, isSuccess: false };
}

export function getCouponIconUrl({
  productNumber,
  iconUrl,
  imageUrl,
}: Pick<CouponDefinitionDTO, 'productNumber' | 'iconUrl' | 'imageUrl'>): {
  url: string;
  isSuccess: boolean;
} {
  const imagePath = `./coupons-img/${productNumber}.png`;
  if (productNumber && COUPON_IMAGES[imagePath]) {
    return { url: COUPON_IMAGES[imagePath], isSuccess: true };
  }
  return { url: iconUrl || imageUrl, isSuccess: false };
}
