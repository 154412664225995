import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { useTheme } from '@mui/material';
import { CouponOrderDTO, CouponOrderDTOStatusEnum, CouponsV2UserOrderDTO } from 'probonio-shared-ui/api';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getCouponIconUrl } from '../checkCouponImageUrl';
import { CONTENT_SIZE } from '../components/CouponItemBackground';
import { CouponRow } from '../components/CouponRow';

interface Props {
  couponOrder: CouponOrderDTO | CouponsV2UserOrderDTO;
  urlPrefix: string;
}

const CouponItem: React.FC<Props> = ({ couponOrder, urlPrefix }) => {
  const theme = useTheme();
  const { t } = useTranslation('couponsModule');

  const { url: couponIconUrl } = getCouponIconUrl(couponOrder.couponDefinition);

  const isGift = (couponOrder: CouponOrderDTO | CouponsV2UserOrderDTO): boolean => {
    return !!couponOrder.giftOccasion;
  };

  let currentVariant: Variant;
  enum Variant {
    pending,
    completed,
    redeemed,
    error,
  }

  switch (couponOrder.status) {
    // Order is pending -> Hide Information
    case CouponOrderDTOStatusEnum.Ordered:
    case CouponOrderDTOStatusEnum.PaymentDue:
    case CouponOrderDTOStatusEnum.Pending:
      currentVariant = Variant.pending;
      break;

    // Order is completed -> Show information
    case CouponOrderDTOStatusEnum.Completed:
      // If voucher has a redeem date -> hide
      currentVariant = couponOrder.redeemDate !== undefined ? Variant.redeemed : Variant.completed;
      break;

    // Voucher was redeemed or order had an error -> Hide Card
    default:
      currentVariant = Variant.error;
  }

  const getSubTitles = (couponOrder: CouponOrderDTO | CouponsV2UserOrderDTO): string[] => {
    const subTitles = [];
    if (couponOrder.redeemDate) {
      subTitles.push(t('couponItemStatus.REDEEMED', { redeemDate: new Date(couponOrder.redeemDate) }));
      return subTitles;
    }
    if (isGift(couponOrder) && couponOrder.status === CouponOrderDTOStatusEnum.Completed) {
      subTitles.push(t('benefitModule:benefit.GIFTS.name'));
    } else {
      subTitles.push(t(`couponItemStatus.${couponOrder.status}`, { orderdate: new Date(couponOrder.orderDate) }));
    }
    if (couponOrder.couponCount > 1) {
      subTitles.push(`${couponOrder.orderIndex + 1}/${couponOrder.couponCount}`);
    }
    return subTitles;
  };

  return (
    <CouponRow
      statusColor={couponOrder.status}
      title={couponOrder.couponDefinition.name}
      subTitles={getSubTitles(couponOrder)}
      leftIcon={<img className="icon" src={couponIconUrl} />}
      rightIcon={
        currentVariant === Variant.pending ? (
          <AccessTimeIcon sx={{ color: theme.palette.primary.main, height: CONTENT_SIZE, width: CONTENT_SIZE }} />
        ) : undefined
      }
      amount={currentVariant === Variant.completed ? couponOrder.amount : undefined}
      remainingAmount={currentVariant === Variant.completed ? couponOrder.remainingAmount : undefined}
      fade={currentVariant === Variant.redeemed || currentVariant === Variant.error}
      linkTo={currentVariant !== Variant.error ? `${urlPrefix}/${couponOrder.id}` : undefined}
      dataTestId="coupon-order-item"
    />
  );
};

export default CouponItem;
