export async function calculateBlobSHA256(blob: Blob): Promise<string> {
  const data = await new Promise<ArrayBuffer>((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      const buffer = reader.result as ArrayBuffer;
      resolve(new Uint8Array(buffer));
    };
    reader.onerror = () => {
      reject(reader.error);
    };
    reader.readAsArrayBuffer(blob);
  });
  const hashBuffer = await crypto.subtle.digest('SHA-256', data);
  return [...new Uint8Array(hashBuffer)].map(byte => byte.toString(16).padStart(2, '0')).join('');
}

export function storeHash(key: string, hash: string): void {
  localStorage.setItem(`fileHash_${key}`, hash);
}

export function hasStoredHash(keys: string[], hash: string): boolean {
  return keys.some(key => localStorage.getItem(`fileHash_${key}`) === hash);
}

export function removeStoredHash(key: string): void {
  localStorage.removeItem(`fileHash_${key}`);
}
