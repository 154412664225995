import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { BackIconButton } from '../component/button/BackIconButton';
import { MobileLayout } from '../component/layout';
import { AppToolbar } from '../component/layout/AppToolbar';
import { APP_VERSION } from '../constants';
import { FeedbackForm } from '../module/feedback';

import { useBackNavigator } from '../module/navigation/BackNavigator';

const Feedback: React.FC = () => {
  const { t } = useTranslation('feedbackModule');
  const backNavigator = useBackNavigator();

  return (
    <MobileLayout paperBackground header={<AppToolbar title={t('title')} backButton={<BackIconButton onClick={backNavigator.back} />} />}>
      <Box
        padding={1}
        sx={{
          height: '100%',
        }}
      >
        <Typography variant="body1">{t('introText')}</Typography>
        <Box paddingTop={1}>
          <FeedbackForm />
        </Box>
        <Box paddingTop={1}>{t('common:appVersion', { version: APP_VERSION })}</Box>
      </Box>
    </MobileLayout>
  );
};

export default Feedback;
