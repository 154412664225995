import { SentryRoutes } from 'probonio-shared-ui/module/error/sentry';
import { Route } from 'react-router-dom';
import { NewsPage } from './NewsPage';
import { ArticlePage } from './ArticlePage';

const NewsRoutes: React.FC = () => {
  return (
    <SentryRoutes>
      <Route path="/*" element={<NewsPage />} />
      <Route path="/article/:articleId" element={<ArticlePage />} />
    </SentryRoutes>
  );
};

export default NewsRoutes;
