import { DependencyList } from 'react';
import { useMemo } from 'react';

export function useLoadingCallback<T extends (...args: never[]) => unknown>(
  fkt: T,
  deps: DependencyList,
  isLoading: boolean,
): LoadingFunction<T> {
  return useMemo(() => {
    const fktWithLoading = (...args: Parameters<T>): ReturnType<T> => fkt(...args) as ReturnType<T>;
    fktWithLoading.isLoading = isLoading;
    return fktWithLoading as LoadingFunction<T>;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...deps, isLoading]);
}

export type LoadingFunction<T extends (...args: never[]) => unknown> = T & { isLoading?: boolean };
