const AVERAGE_TAX_RATE = 0.457;
const BIKEFACTOR = 1.04464;
const MONTHS = 36;

export function calculateBikeCosts(bikePrice: number, employerGrant: number): { monthlyCosts: number } {
  const monthlyRate = Math.max(0, (bikePrice * BIKEFACTOR) / MONTHS - employerGrant);
  const monthlyCosts = monthlyRate * (1 - AVERAGE_TAX_RATE);

  return {
    monthlyCosts,
  };
}
