import type { TextFieldProps } from '@mui/material';
import { TextField } from '@mui/material';
import React, { useCallback, useMemo } from 'react';

interface HTMLNumericElement extends Omit<HTMLInputElement, 'value' | 'name'> {
  // eslint-disable-next-line @rushstack/no-new-null
  value: number | null | '';
  name?: string;
}

export type NumericInputProps = Omit<TextFieldProps, 'onChange'> & {
  value?: number | string;
  onChange?(e: React.ChangeEvent<HTMLNumericElement>): void;

  precision: number;
  locale?: string;
};

export const NumericInput: React.FC<NumericInputProps> = ({ ...props }) => {
  const { value, precision, locale, ...inputProps } = props;
  const defaultValue = value === null ? NaN : Number(value);
  const verifyNumber = useCallback((string: string) => {
    const numericRepresentation = string;

    return {
      isNumber: !isNaN(Number(numericRepresentation)),
      numberFomart: !isNaN(Number(numericRepresentation)) ? Number(numericRepresentation) : null,
    };
  }, []);
  const formatter = useMemo(
    () =>
      new Intl.NumberFormat(locale, {
        minimumFractionDigits: precision,
        maximumFractionDigits: precision,
      }),

    [precision, locale],
  );

  const format = useCallback(
    (number: number) => {
      const result = formatter.format(number);

      return result;
    },
    [formatter],
  );

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>): void => {
      if (e.key === ' ') e.preventDefault();

      if (
        e.ctrlKey ||
        e.shiftKey ||
        e.key === 'Backspace' ||
        e.key === 'Enter' ||
        e.key === 'Tab' ||
        e.key === 'ArrowRight' ||
        e.key === 'ArrowLeft'
      )
        return;
      if (!verifyNumber(e.key).isNumber) e.preventDefault();
    },
    [verifyNumber],
  );
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>): void => {
      const newEvent: React.ChangeEvent<HTMLNumericElement> = {
        ...e,
        currentTarget: {
          ...e.currentTarget,
          name: props.name,
          value: 0,
        },
        target: {
          ...e.target,
          name: props.name,
          value: 0,
        },
      };
      let numericRepresentation = e.target.value;

      numericRepresentation = numericRepresentation.replace(/,/g, '');
      numericRepresentation = numericRepresentation.replace(/\./g, '');

      if (numericRepresentation === '') {
        e.target.value = '';
        newEvent.target.value = null;
        newEvent.currentTarget.value = null;
        return props.onChange && props.onChange(newEvent);
      }

      const { isNumber, numberFomart } = verifyNumber(numericRepresentation);
      if (isNumber && numberFomart !== null) {
        const withPrecision = numberFomart / 10 ** precision;

        const formattedNumber = format(withPrecision);

        newEvent.target.value = withPrecision;
        newEvent.currentTarget.value = withPrecision;

        e.target.value = formattedNumber;

        return props.onChange && props.onChange(newEvent);
      }
    },
    [format, precision, props, verifyNumber],
  );
  const hasValue = value !== undefined;
  let inputDefaultValue;
  let inputValue;

  if (hasValue) {
    if (isNaN(defaultValue) || value === '') {
      inputValue = null;
    } else {
      inputValue = format(defaultValue);
    }
  }

  if (!hasValue && !isNaN(defaultValue)) {
    inputDefaultValue = format(defaultValue);
  }

  return (
    <TextField defaultValue={inputDefaultValue} {...inputProps} onKeyDown={handleKeyDown} onChange={handleChange} value={inputValue} />
  );
};
