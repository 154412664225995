import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import { UserDTOLegalTermsActionEnum } from 'probonio-shared-ui/api';
import { useTenant } from 'probonio-shared-ui/module/me/hooks';
import { Trans } from 'react-i18next';
import { BrandLogo } from '../../component/brand/logo';
import { MobileLayout } from '../../component/layout';
import { useAppSelector } from '../../redux/hooks';
import { sessionStorageSkippedKey } from './legalTerms.const';
import { LegalTermsInfo } from './LegalTermsInfo';
interface Props {
  children: React.ReactNode;
}

export const LegalTermsGuard: React.FC<Props> = ({ children }) => {
  const user = useAppSelector(state => state.me.user);

  const { tenant } = useTenant();

  const isEmployeeSkipping = sessionStorage.getItem(sessionStorageSkippedKey) === user?.id;
  const showLegalTerms =
    user?.legalTermsAction === UserDTOLegalTermsActionEnum.Consent ||
    (user?.legalTermsAction === UserDTOLegalTermsActionEnum.Skip && !isEmployeeSkipping);

  if (!user || !tenant) {
    return <CircularProgress />;
  }

  if (showLegalTerms) {
    return (
      <MobileLayout>
        <Box display="flex" flexGrow={1}>
          <Stack alignItems="center" padding={2} spacing={2} justifyContent="center">
            <BrandLogo />
            <Typography variant="h2" data-test-id="legal-terms-guard-title">
              <Trans i18nKey="legalTerms:guardTitle" />
            </Typography>
            <Typography component="span" variant="body2" textAlign="center" data-test-id="legal-terms-consent-done">
              <Trans i18nKey="legalTerms:guardSubtitle" />
            </Typography>
            <LegalTermsInfo user={user} tenantId={tenant.id} action={user.legalTermsAction!} align="center" />
          </Stack>
        </Box>
      </MobileLayout>
    );
  }

  return children;
};
