import { Box, Button, Fab, FabProps, useTheme } from '@mui/material';
import React from 'react';
import { CircularHeader } from './CircularHeader';

interface Props {
  children: React.ReactNode;
  tabs?: React.ReactNode;
  buttonProps: FabProps;
  borderColor?: string;
}
/**
 * Elevation is disabled in the theme by default. So this button enables it to be used in the FAB
 */
const ElevatedButton = React.forwardRef<HTMLButtonElement, React.ComponentProps<typeof Button>>((props, ref) => (
  <Button {...props} ref={ref} disableElevation={false} />
));

// size of the background div (i.e. maximum height the content above the button may occupy)
export const BG_HEIGHT = 35;

/**
 * A button floating in the center below the header, with a circular background image
 */
export const CircularFloatingButton: React.FC<Props> = ({ children, tabs, buttonProps, borderColor }): JSX.Element => {
  const theme = useTheme();

  return (
    <CircularHeader
      tabs={tabs}
      borderColor={borderColor}
      actionButton={
        <Box
          sx={{
            borderRadius: '100% 100% 100% 100%',
            backgroundColor: theme.palette.background.default,
            zIndex: 2,
            padding: '7px',
          }}
        >
          <Fab {...buttonProps} size="large" color="primary" component={ElevatedButton}>
            {children}
          </Fab>
        </Box>
      }
    />
  );
};
