import { Checkbox, FormControlLabel, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { BenefitDTOBenefitEnum } from 'probonio-shared-ui/api';
import { useCallback } from 'react';

interface Props {
  value?: boolean;
  onChange: (checked: boolean) => void;
  benefit: BenefitDTOBenefitEnum;
}

const ReceiptConditions: React.FC<Props> = ({ value, onChange, benefit }) => {
  const { t } = useTranslation('benefitModule');

  const REQUIRED_BENEFITS: BenefitDTOBenefitEnum[] = ['LUNCH', 'MOBILITY', 'RECREATION'];

  const isRequired = REQUIRED_BENEFITS.includes(benefit);

  const handleChange = useCallback(
    (event: unknown, checked: boolean) => {
      onChange(checked);
    },
    [onChange],
  );

  return isRequired ? (
    <FormControlLabel
      control={<Checkbox required checked={value} onChange={handleChange} />}
      label={
        <Typography component="span" variant="body2" color="text.secondary">
          {t(`benefit.${benefit}.addReceiptFlow.receiptConditions`)}
        </Typography>
      }
    />
  ) : null;
};

export default ReceiptConditions;
