import { Capacitor } from '@capacitor/core';
import { AuthorizationError } from '@openid/appauth';
import { AuthenticateOptions, AuthProvider, ErrorAction } from '@tiffinger-thiel/appauth-react';
import { FC, ReactNode, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { createPlatformAuthHandlers } from './platformAuthHandlers';

function getEnv(name: string): string {
  return (import.meta.env[name] || '').toString();
}

function buildURL(scheme: string, host: string, path: string): string {
  return `${scheme}://${host}${path && '/' + encodeURIComponent(path)}`;
}

// Checks for the Azure AD B2C specific error codes indicating an invalid registration token
// See https://docs.microsoft.com/en-us/azure/active-directory-b2c/error-codes for list of error codes
// This will have to be adapted for different IdPs
function isRegistrationError(err: unknown): boolean | undefined {
  return (
    err instanceof AuthorizationError &&
    err.error === 'invalid_request' &&
    (err.errorDescription?.includes('AADB2C90208') ||
      err.errorDescription?.includes('AADB2C90209') ||
      err.errorDescription?.includes('AADB2C90210'))
  );
}
function isUserCancelledError(err: unknown): boolean | undefined {
  return err instanceof AuthorizationError && err.error === 'access_denied' && err.errorDescription?.includes('AADB2C90091');
}
function isSocialUserNotFoundError(err: unknown): boolean | undefined {
  return err instanceof AuthorizationError && err.error === 'server_error' && err.errorDescription?.includes('AADB2C99002');
}

interface Props {
  children: ReactNode;
}

const platform = Capacitor.getPlatform();

export const authConfig: AuthenticateOptions = {
  openIdConnectUrl: getEnv('VITE_AUTH_OPEN_ID_CONNECT'),
  scope: getEnv('VITE_AUTH_SCOPE'),
  clientId: getEnv('VITE_AUTH_CLIENT_ID'),
  redirectUrl:
    platform === 'web'
      ? `${location.origin}/oauth`
      : buildURL(
          getEnv(`VITE_AUTH_${platform.toUpperCase()}_REDIRECT_SCHEME`),
          getEnv(`VITE_AUTH_${platform.toUpperCase()}_REDIRECT_HOST`),
          getEnv(`VITE_AUTH_${platform.toUpperCase()}_REDIRECT_PATH`),
        ),
};

export const MobileAuthProvider: FC<Props> = ({ children }) => {
  const navigate = useNavigate();
  const handleError = useCallback(
    (err: unknown, type: ErrorAction) => {
      if (isRegistrationError(err)) {
        // onError callback and isReady state change happen at the same time, causing navigation to
        // the error page to fail. setTimeout is a workaround to enforce an order to the navigate commands.
        // See OAuthPage and RegisterPage for conflicting navigate commands.
        setTimeout(() => {
          navigate('/register/error', { replace: true });
        }, 100);
      } else if (isSocialUserNotFoundError(err)) {
        setTimeout(() => {
          navigate('/socialLoginError', { replace: true });
        }, 100);
      } else if (isUserCancelledError(err)) {
        navigate('/', { replace: true });
      } else if (type === ErrorAction.REFRESH_TOKEN_REQUEST) {
        // token refresh failed, ignore
      } else {
        navigate('/login/error', { replace: true });
      }
    },
    // the updated navigate function (depending on the current location) is only needed for relative urls
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const { authHandler, endSessionHandler } = useMemo(() => createPlatformAuthHandlers(authConfig, handleError), [handleError]);

  return (
    <AuthProvider options={authConfig} authHandler={authHandler} endSessionHandler={endSessionHandler} onError={handleError}>
      {children}
    </AuthProvider>
  );
};
