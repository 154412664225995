import { Box, Button, Typography } from '@mui/material';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  amount: number;
  count: number;
  maxCount: number;
  onChangeCount: (amount: number, count: number) => void;
}

export const ExploreCouponsV2ChangeAmount: React.FC<Props> = ({ amount, count, maxCount, onChangeCount }) => {
  const { t } = useTranslation('couponsV2Module');

  const handleSubtractAmount = useCallback(() => {
    onChangeCount(amount, count - 1);
  }, [amount, count, onChangeCount]);

  const handleAddAmount = useCallback(() => {
    onChangeCount(amount, count + 1);
  }, [amount, count, onChangeCount]);

  return (
    <>
      <Box display={'flex'} sx={{ paddingBottom: 0.5, minWidth: '270px', justifyContent: 'space-between' }}>
        <Button variant="outlined" disabled={count === 0} sx={{ height: 30, minWidth: 10 }} onClick={handleSubtractAmount}>
          -
        </Button>
        <Typography component="h2" variant="h1" textAlign="center" sx={{ paddingLeft: 1 }} data-test-id="single-coupon-count">
          {t('couponOrderDetails.tokenAmount', { token: count })}
        </Typography>
        <Typography
          component="h2"
          variant="h1"
          textAlign="center"
          sx={{ paddingLeft: 1, paddingRight: 1 }}
          data-test-id="single-coupon-amount"
        >
          {t('couponOrderDetails.couponAmount', { amount: amount })}
        </Typography>
        <Button variant="contained" sx={{ height: 30, minWidth: 10 }} onClick={handleAddAmount} disabled={count >= maxCount}>
          +
        </Button>
      </Box>
    </>
  );
};
