import { AuthGuard, useLoginContext } from '@tiffinger-thiel/appauth-react';
import { useCallback, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { LoadingLayout } from '../../component/layout/LoadingLayout';
import InactivePage from '../../page/InactivePage';
import { useAppSelector } from '../../redux/hooks';
import { ErrorFallback } from '../error';
import { LegalTermsGuard } from '../legalTerms/LegalTermsGuard';

interface Props {
  page: React.ComponentType;
}

export const OAUTH_RETURN_PATH = 'oauthReturnPath';

/**
 * Wraps a page component with a guard that automatically redirects to the login page
 */
const PrivatePage: React.FC<Props> = ({ page }) => {
  const { isReady, isLoggedIn, login } = useLoginContext();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (isReady && !isLoggedIn) {
      localStorage.setItem(OAUTH_RETURN_PATH, `${location.pathname}${location.search}`);
      void login();
    }
  }, [isReady, isLoggedIn, login, location]);

  const handleLogout = useCallback(() => {
    navigate('/logout', { replace: true });
  }, [navigate]);

  const PageComponent = page;

  const { forbidden, registrationPending, initializationError } = useAppSelector(state => state.me);

  if (forbidden === undefined) {
    return <LoadingLayout />;
  } else if (forbidden) {
    return <InactivePage registrationPending={registrationPending} />;
  } else if (initializationError) {
    return <ErrorFallback logout={handleLogout} />;
  } else {
    return (
      <AuthGuard>
        <LegalTermsGuard>
          <PageComponent />
        </LegalTermsGuard>
      </AuthGuard>
    );
  }
};

export default PrivatePage;
