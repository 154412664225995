import { SentryRoutes } from 'probonio-shared-ui/module/error/sentry';
import { Route } from 'react-router-dom';
import RecreationPage from './RecreationPage';
import RecreationInfoPage from './RecreationInfoPage';
import { useImageSelection } from '../../module/shareTarget/useImageSelection';
import { LUNCH_MAX_IMAGE_SIZE } from '../../module/lunch/lunchConstants';
import { RecreationPeriodsProvider } from '../../module/recreation/RecreationPeriodsContext';
import { AddRecreationReceiptPage } from './AddRecreationReceiptPage';
import { RecreationReceiptDetailPage } from './RecreationReceiptDetailPage';
import EditReceiptPage from './EditReceiptPage';

const RecreationRoutes: React.FC = () => {
  const imageSelection = useImageSelection(true, LUNCH_MAX_IMAGE_SIZE);

  return (
    <RecreationPeriodsProvider>
      <SentryRoutes>
        <Route path="/*" element={<RecreationPage onSelectImage={imageSelection.handleChangeImage} />} />
        {imageSelection.selectedImage && (
          <Route path="/receipt/add" element={<AddRecreationReceiptPage imageSelection={imageSelection} />} />
        )}
        <Route path="/info" element={<RecreationInfoPage />} />
        <Route path="receipt/view/:recreationPeriod/:receiptId" element={<RecreationReceiptDetailPage />} />
        <Route path="receipt/edit/:recreationPeriod/:receiptId" element={<EditReceiptPage />} />
      </SentryRoutes>
    </RecreationPeriodsProvider>
  );
};

export default RecreationRoutes;
