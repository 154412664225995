import { SentryRoutes } from 'probonio-shared-ui/module/error/sentry';
import { Route } from 'react-router-dom';
import { CustomBenefitInfoPage } from './CustomBenefitInfoPage';

export const BenefitCustomRoutes: React.FC = () => {
  return (
    <SentryRoutes>
      <Route path="/:customBenefitId" element={<CustomBenefitInfoPage />} />
    </SentryRoutes>
  );
};
