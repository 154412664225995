import { Box, Typography, useTheme } from '@mui/material';

export const DateBox: React.FC<{ date: Date; active?: boolean; mode?: 'days' | 'months' }> = ({ date, active, mode }) => {
  const theme = useTheme();

  return (
    <Box
      justifyContent={'space-between'}
      display={'flex'}
      flexDirection={'column'}
      sx={{
        boxSizing: 'border-box',
        borderWidth: 2,
        borderColor: active ? theme.palette.primary.main : theme.palette.datePicker.border,
        color: active ? theme.palette.primary.main : 'inherit',
        borderStyle: 'solid',
        borderRadius: 1,
        height: theme.spacing(4.2),
        width: theme.spacing(3.3),
        display: 'inline-block',
        flexShrink: 0,
      }}
    >
      <Box marginTop={0.4}>
        <Typography variant="caption">
          {date.toLocaleDateString(undefined, mode === 'months' ? { month: 'short' } : { weekday: 'short' })}.
        </Typography>
      </Box>
      <Box>
        <Typography variant="subtitle1" fontWeight={'500'}>
          {date.toLocaleDateString(undefined, mode === 'months' ? { year: '2-digit' } : { day: 'numeric' })}
        </Typography>
      </Box>
    </Box>
  );
};
