import { useTheme } from '@mui/material';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import logo from './logo.svg';
import logoDark from './logoDark.svg';

export const BrandLogo: React.FC = () => {
  const { t } = useTranslation('brand');
  const theme = useTheme();

  return (
    <>
      <img src={theme.palette.mode === 'dark' ? logoDark : logo} alt={t('probonio')} style={{ verticalAlign: 'middle', height: '66px' }} />
    </>
  );
};
