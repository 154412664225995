import PhotoCameraOutlinedIcon from '@mui/icons-material/PhotoCameraOutlined';
import PhotoLibraryOutlinedIcon from '@mui/icons-material/PhotoLibraryOutlined';
import { Box, Button, Divider, Drawer } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DialogState } from '../dialog/BasicDialog';

interface Props {
  dialogState: DialogState;
  onTakePhoto: () => void;
  onSelectImage: () => void;
}

export const CameraDrawer: React.FC<Props> = ({ dialogState, onTakePhoto, onSelectImage }) => {
  const { t } = useTranslation('cameraButton');

  return (
    <Drawer open={dialogState.isOpen} onClose={dialogState.handleClose} anchor="bottom" sx={{ zIndex: 1999 }}>
      <Box display="flex" flexDirection="column">
        <Button
          size="large"
          startIcon={<PhotoCameraOutlinedIcon />}
          onClick={onTakePhoto}
          style={{ justifyContent: 'flex-start' }}
          sx={{ py: 1 }}
        >
          {t('takePhoto')}
        </Button>
        <Divider sx={{ mx: 1 }} />
        <Button
          size="large"
          startIcon={<PhotoLibraryOutlinedIcon />}
          onClick={onSelectImage}
          style={{ justifyContent: 'flex-start' }}
          sx={{ py: 1 }}
        >
          {t('selectPhoto')}
        </Button>
      </Box>
    </Drawer>
  );
};
