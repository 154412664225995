import { Box, Card, List, ListItemButton, ListItemText, Typography, useTheme } from '@mui/material';
import { BikeleasingContractDTO } from 'probonio-shared-ui/api';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useBikeleasingDetails } from './useBikeleasingDetails';

interface BikeTextProps {
  contract: BikeleasingContractDTO;
}

const ContractListEntry: React.FC<BikeTextProps> = ({ contract }) => {
  const { t } = useTranslation('benefitModule');
  const theme = useTheme();
  const navigate = useNavigate();

  const handleClick = useCallback(() => {
    navigate(`/bikeleasing/contracts/${contract.id}`);
  }, [contract.id, navigate]);

  return (
    <Card variant="outlined" sx={{ mb: 0.5 }} data-test-id="bikeleasing-contract">
      <ListItemButton onClick={handleClick}>
        <ListItemText
          primary={
            <Box>
              {contract.status === 'Active' ? (
                <Typography variant="body1" color={theme.palette.success.main}>
                  {t('common:money', { money: contract.purchasePrice ?? 0 })}
                </Typography>
              ) : (
                <Typography variant="body1" color={theme.palette.info.main}>
                  {t([`benefit.BIKELEASING.contract.status.${contract.status}`, 'benefit.BIKELEASING.contract.status.fallback'])}
                </Typography>
              )}
            </Box>
          }
          secondary={
            <Box display="flex" flexDirection="column">
              <Typography variant="small" textOverflow="ellipsis" whiteSpace="nowrap" overflow="hidden">
                {contract.bike.brand} {contract.bike.model} {contract.bike.frameSize}
              </Typography>
              {contract.startDate && contract.endDate && (
                <Typography variant="small">
                  {t('benefit.BIKELEASING.intervall', { start: new Date(contract.startDate), end: new Date(contract.endDate) })}
                </Typography>
              )}
            </Box>
          }
        />
      </ListItemButton>
    </Card>
  );
};

export const BikeleasingContracts: React.FC = () => {
  const details = useBikeleasingDetails();
  const { t } = useTranslation('benefitModule');

  return details?.bikeleasingContract.length ? (
    <>
      <Typography variant="h1" mb={1}>
        {t('benefit.BIKELEASING.contract.title')}
      </Typography>

      <List disablePadding>
        {details?.bikeleasingContract.map(contract => <ContractListEntry key={contract.id} contract={contract} />)}
      </List>
    </>
  ) : null;
};
