/* tslint:disable */
/* eslint-disable */
/**
 * Probonio full API Docs
 * If you stumbled upon this page and want to integrate Probonio into your business software [click here!](/api/external)    If you\'re looking for more information about Probonio [head over here](https://probonio.de)    **This documentation describes all Endpoints of the Probonio API. It\'s created for experienced Probonio Developers only!**  
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface CreateBAVEstimateRequestDTO
 */
export interface CreateBAVEstimateRequestDTO {
    /**
     * 
     * @type {string}
     * @memberof CreateBAVEstimateRequestDTO
     */
    'dateOfBirth': string;
    /**
     * 
     * @type {number}
     * @memberof CreateBAVEstimateRequestDTO
     */
    'taxClass': CreateBAVEstimateRequestDTOTaxClassEnum;
    /**
     * 
     * @type {number}
     * @memberof CreateBAVEstimateRequestDTO
     */
    'numberOfKids': CreateBAVEstimateRequestDTONumberOfKidsEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateBAVEstimateRequestDTO
     */
    'typeOfHealthInsurance': CreateBAVEstimateRequestDTOTypeOfHealthInsuranceEnum;
    /**
     * 
     * @type {number}
     * @memberof CreateBAVEstimateRequestDTO
     */
    'salary': number;
    /**
     * 
     * @type {number}
     * @memberof CreateBAVEstimateRequestDTO
     */
    'savingsRate'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CreateBAVEstimateRequestDTO
     */
    'isPayingChurchTax': boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateBAVEstimateRequestDTO
     */
    'nameOfHealthInsurance'?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateBAVEstimateRequestDTO
     */
    'privateCareInsuranceRate'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateBAVEstimateRequestDTO
     */
    'privateHealthInsuranceRate'?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateBAVEstimateRequestDTO
     */
    'phoneNumber'?: string;
}

export const CreateBAVEstimateRequestDTOTaxClassEnum = {
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5
} as const;

export type CreateBAVEstimateRequestDTOTaxClassEnum = typeof CreateBAVEstimateRequestDTOTaxClassEnum[keyof typeof CreateBAVEstimateRequestDTOTaxClassEnum];
export const CreateBAVEstimateRequestDTONumberOfKidsEnum = {
    NUMBER_0: 0,
    NUMBER_0_DOT_5: 0.5,
    NUMBER_1: 1,
    NUMBER_1_DOT_5: 1.5,
    NUMBER_2: 2,
    NUMBER_2_DOT_5: 2.5,
    NUMBER_3: 3,
    NUMBER_3_DOT_5: 3.5,
    NUMBER_4: 4,
    NUMBER_4_DOT_5: 4.5,
    NUMBER_5: 5
} as const;

export type CreateBAVEstimateRequestDTONumberOfKidsEnum = typeof CreateBAVEstimateRequestDTONumberOfKidsEnum[keyof typeof CreateBAVEstimateRequestDTONumberOfKidsEnum];
export const CreateBAVEstimateRequestDTOTypeOfHealthInsuranceEnum = {
    Public: 'PUBLIC',
    Private: 'PRIVATE'
} as const;

export type CreateBAVEstimateRequestDTOTypeOfHealthInsuranceEnum = typeof CreateBAVEstimateRequestDTOTypeOfHealthInsuranceEnum[keyof typeof CreateBAVEstimateRequestDTOTypeOfHealthInsuranceEnum];


