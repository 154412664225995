import { createSvgIcon } from '@mui/material';

export const ThreeWayArrowIcon = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M 10.857182,4.571192 H 7.428788 L 11.99998,0 16.571172,4.571192 H 13.142778 V 9.6337871 C 12.28568,10.159474 11.520004,10.810869 10.857182,11.565116 Z M 23.42796,11.42798 18.856768,6.856788 v 3.428394 c -4.125501,-0.171419 -7.645318,2.936991 -7.999586,7.051063 -1.782765,0.628539 -2.719859,2.594152 -2.09132,4.376917 0.628539,1.782765 2.594151,2.719859 4.376916,2.09132 1.782765,-0.639967 2.71986,-2.594151 2.09132,-4.376916 -0.342839,-0.982806 -1.119942,-1.748481 -2.09132,-2.091321 0.537115,-4.571191 5.108307,-4.799751 5.65685,-4.799751 v 3.428394 z M 10.434347,12.102231 C 8.914425,10.936577 7.051665,10.285182 5.143192,10.285182 V 6.856788 L 0.572,11.42798 5.143192,15.999172 v -3.428394 c 1.531349,0.03428 3.005559,0.571399 4.159785,1.599918 0.285699,-0.731392 0.662823,-1.428498 1.13137,-2.068465 z"
      fill="currentColor"
    />
  </svg>,
  'ThreeWayArrow',
);
