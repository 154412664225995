import { Box, LinearProgress, Typography } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { ReceiptAccordion } from '../benefit/receiptAccordion/ReceiptAccordion';
import { useMobilityPeriods } from './MobilityPeriodsContext';
import { MobilityReceiptList } from './MobilityReceiptList';

export const MobilityPeriods: React.FC = () => {
  const { t, i18n } = useTranslation('benefitModule');
  const {
    currentPeriod,
    mobilityPeriodsQuery: { data: mobilityPeriods, isLoading, isSuccess, isError },
    selectPeriod,
    selectedPeriodMonth: selectedPeriod,
  } = useMobilityPeriods();

  const { search } = useLocation();
  const initialOpenPeriod = useMemo(() => new URLSearchParams(search).get('initialOpenPeriod'), [search]);
  const [isInitialLoaded, setIsInitialLoaded] = useState(false);

  useEffect(() => {
    if (!isInitialLoaded && isSuccess && (selectedPeriod || currentPeriod?.month)) {
      setIsInitialLoaded(true);
      selectPeriod(selectedPeriod || initialOpenPeriod || currentPeriod?.month);
    }
  }, [selectedPeriod, initialOpenPeriod, isSuccess, selectPeriod, currentPeriod?.month, isInitialLoaded]);

  const handleTogglePeriod = useCallback(
    (period: string) => () => {
      if (selectedPeriod === period) {
        selectPeriod(undefined);
        return;
      }

      selectPeriod(period);
    },
    [selectedPeriod, selectPeriod],
  );

  return (
    <Box>
      <Box sx={{ marginBottom: 1, marginTop: 0.5 }}>
        <Typography variant="h5">{t('benefit.LUNCH.receiptsTitle')}</Typography>
      </Box>
      <Box>
        {isLoading && <LinearProgress />}
        {isError && <Typography>{t('benefit.LUNCH.noReceipt')}</Typography>}
        {!mobilityPeriods && <Typography>{t('benefit.LUNCH.noPeriod')}</Typography>}
        {isSuccess &&
          mobilityPeriods &&
          mobilityPeriods.mobilityPeriods.map(period => (
            <ReceiptAccordion
              onChange={handleTogglePeriod(period.month)}
              monthName={i18n.format(new Date(period.month), 'monthAndYear')}
              totalLimit={period.refund}
              isLocked={period.isLocked}
              key={period.month}
              expanded={selectedPeriod === period.month}
            >
              <MobilityReceiptList mobilityPeriod={period.month} />
            </ReceiptAccordion>
          ))}
      </Box>
    </Box>
  );
};
