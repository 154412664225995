import { Box, Button } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ImageScroller } from '../../../component/imageScroller/ImageScroller';
import MessageLayout from '../../../component/layout/MessageLayout';

const loadingPictureImports = import.meta.glob('./loadingImg/*.webp', { query: '?url', import: 'default', eager: true }) as Record<
  string,
  string
>;

interface Props {
  onSkip: () => void;
}

const SKIP_WAIT_MS = 3000;

export const OCRLoadingLayout: React.FC<Props> = ({ onSkip }) => {
  const { t } = useTranslation('benefitModule');
  const [skipEnabled, setSkipEnabled] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setSkipEnabled(true);
    }, SKIP_WAIT_MS);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <MessageLayout
      actions={<ImageScroller imageUrls={Object.values(loadingPictureImports)} height={8} imagePadding={4} scrollDuration="10s" />}
    >
      <Box paddingBottom={1}>
        <Button variant="text" disabled={!skipEnabled} onClick={onSkip}>
          {t('benefit.LUNCH.lineItems.skipAnalysis')}
        </Button>
      </Box>
    </MessageLayout>
  );
};
