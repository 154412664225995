import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Card, IconButton, List, SxProps } from '@mui/material';
import {
  BenefitDTOBenefitEnum,
  LunchReceiptRefundDTO,
  LunchRefundMetadataDTOPartialRefundTypeEnum,
  MobilityReceiptRefundDTO,
  MobilityRefundMetadataDTOPartialRefundTypeEnum,
  RecreationReceiptRefundDTO,
} from 'probonio-shared-ui/api';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfirmationModal } from '../../../component/confirmationModal';
import { ValueListItem } from '../../../component/list';
import { getValidityMonths } from './editReceiptMapper';

export interface ReceiptValues {
  comment?: string;
  date: string | Date;
  total: number;
  refund?: number;
  refundMetadata?:
    | LunchReceiptRefundDTO['refundMetadata']
    | MobilityReceiptRefundDTO['refundMetadata']
    | RecreationReceiptRefundDTO['refundMetadata'];
  lunchReceiptType?: LunchReceiptRefundDTO['receiptType'];
  mobilityType?: MobilityReceiptRefundDTO['mobilityType'];
  mobilityTravelPurpose?: MobilityReceiptRefundDTO['travelPurpose'];
  mobilityValidityStartDate?: MobilityReceiptRefundDTO['validityStartDate'];
  mobilityValidityEndDate?: MobilityReceiptRefundDTO['validityEndDate'];
}
interface Props {
  receipt: ReceiptValues;
  includeComment?: boolean;
  includeType?: boolean;
  sx?: SxProps;
  benefit: BenefitDTOBenefitEnum;
}

export const ReceiptInfoCard: React.FC<Props> = ({ receipt, includeComment = false, includeType = false, sx, benefit }) => {
  const { t, i18n } = useTranslation('benefitModule');
  const [isInfoDialogOpen, setIsInfoDialogOpen] = useState(false);

  const date = useMemo(() => {
    if (typeof receipt.date === 'string') {
      return new Date(receipt.date);
    }
    return receipt.date;
  }, [receipt.date]);

  const handleOpenInfoDialog = useCallback(() => {
    setIsInfoDialogOpen(true);
  }, []);

  const handleCloseInfoDialog = useCallback(() => {
    setIsInfoDialogOpen(false);
  }, []);

  let message = '';
  if (benefit === BenefitDTOBenefitEnum.Lunch) {
    const lunchRefundMetadata = receipt.refundMetadata as LunchReceiptRefundDTO['refundMetadata'];

    switch (receipt.refundMetadata?.partialRefundType) {
      case LunchRefundMetadataDTOPartialRefundTypeEnum.ReachedMaxPerMonth:
        message = t(`benefit.${benefit}.partialRefund.reachedMaxPerMonth`, {
          maxNumberOfReceipts: lunchRefundMetadata.maxNumberOfReceipts,
        });
        break;
      case LunchRefundMetadataDTOPartialRefundTypeEnum.PreventFlatRateTaxation:
        message = t(`benefit.${benefit}.partialRefund.preventFlatRateTaxation`, {
          nonCashValue: lunchRefundMetadata.nonCashValue,
          maxRefund: lunchRefundMetadata.dailyLimit,
          bestTotal: lunchRefundMetadata.dailyLimit + lunchRefundMetadata.nonCashValue,
        });
        break;
      case LunchRefundMetadataDTOPartialRefundTypeEnum.ReachedMaxPerReceipt:
        message = t(`benefit.${benefit}.partialRefund.reachedMaxPerReceipt`, {
          maxRefund: lunchRefundMetadata.dailyLimit,
        });
        break;
      case LunchRefundMetadataDTOPartialRefundTypeEnum.ReachedMaxTotalAmount:
        message = t(`benefit.${benefit}.partialRefund.reachedMaxTotalAmount`, {
          maxTotalAmount: lunchRefundMetadata.maxTotalAmount,
        });
        break;
    }
  }

  const validityMonths = getValidityMonths(receipt.mobilityValidityStartDate, receipt.mobilityValidityEndDate);

  if (benefit === BenefitDTOBenefitEnum.Recreation) {
    if (receipt.refund && receipt.refund < receipt.total) {
      message = t(`benefit.${benefit}.partialRefund.reachedMaxTotalAmount`);
    }
  }

  if (benefit === BenefitDTOBenefitEnum.Mobility) {
    const mobilityRefundMetadata = receipt.refundMetadata as MobilityReceiptRefundDTO['refundMetadata'];

    switch (mobilityRefundMetadata?.partialRefundType) {
      case MobilityRefundMetadataDTOPartialRefundTypeEnum.ReachedMaxPerMonth:
        message = t(`benefit.${benefit}.partialRefund.reachedMaxPerMonth`, {
          monthlyLimit: mobilityRefundMetadata.monthlyLimit,
        });
        break;
      case MobilityRefundMetadataDTOPartialRefundTypeEnum.TimeCardSplit:
        if (validityMonths) {
          message = t(`benefit.${benefit}.partialRefund.timeCardSplit`, { validityMonths });
        }
    }
  }

  return (
    <>
      <Card elevation={0} sx={{ marginBottom: 1, ...sx }}>
        <List>
          <ValueListItem label={`${t(`benefit.${benefit}.field.total`)} (€)`} value={t('common:money', { money: receipt.total })} />
          {receipt.refund !== undefined && (
            <ValueListItem
              label={`${t(`benefit.${benefit}.field.refund`)} (€)`}
              value={
                <>
                  {message && (
                    <IconButton sx={{ paddingY: 0 }} onClick={handleOpenInfoDialog}>
                      <HelpOutlineIcon />
                    </IconButton>
                  )}
                  {t('common:money', { money: receipt.refund })}
                </>
              }
            />
          )}
          {includeComment && benefit === BenefitDTOBenefitEnum.Lunch && (
            <ValueListItem
              label={t(`benefit.${benefit}.field.receiptType`)}
              value={t(`benefit.${benefit}.receiptType.${receipt.lunchReceiptType}.title`)}
            />
          )}

          {includeComment && benefit === BenefitDTOBenefitEnum.Mobility && (
            <>
              <ValueListItem
                label={t(`benefit.${benefit}.field.mobilityType`)}
                value={t(`benefit.${benefit}.mobilityType.${receipt.mobilityType}.title`)}
              />
              {validityMonths && (
                <>
                  <ValueListItem
                    label={t(`benefit.${benefit}.field.validityStartDate`)}
                    value={i18n.format(new Date(receipt.mobilityValidityStartDate!), 'monthAndYear')}
                  />
                  <ValueListItem label={t(`benefit.${benefit}.field.validityMonths`)} value={validityMonths} />
                </>
              )}
              <ValueListItem
                label={t(`benefit.${benefit}.field.travelPurpose`)}
                value={t(`benefit.${benefit}.travelPurpose.${receipt.mobilityTravelPurpose}.title`)}
              />
            </>
          )}

          {!validityMonths && <ValueListItem label={t(`benefit.${benefit}.field.date`)} value={i18n.format(date, 'date')} />}
          {receipt.comment && includeComment && (
            <ValueListItem label={t(`benefit.${benefit}.field.comment`)} value={receipt.comment || ''} multiLine />
          )}
        </List>
      </Card>
      <ConfirmationModal
        onSubmit={handleCloseInfoDialog}
        isOpen={isInfoDialogOpen}
        message={message}
        title={t(`benefit.${benefit}.partialRefund.title`)}
      />
    </>
  );
};
