/* tslint:disable */
/* eslint-disable */
/**
 * Probonio full API Docs
 * If you stumbled upon this page and want to integrate Probonio into your business software [click here!](/api/external)    If you\'re looking for more information about Probonio [head over here](https://probonio.de)    **This documentation describes all Endpoints of the Probonio API. It\'s created for experienced Probonio Developers only!**  
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CreateMobilityReceiptDTO } from '../dto';
// @ts-ignore
import { EmployeeMobilityReportsDTO } from '../dto';
// @ts-ignore
import { IdentifierDTO } from '../dto';
// @ts-ignore
import { MobilityPeriodsDTO } from '../dto';
// @ts-ignore
import { MobilityReceiptDTO } from '../dto';
// @ts-ignore
import { MobilityReceiptTenantListDTO } from '../dto';
// @ts-ignore
import { MobilityReceiptsDTO } from '../dto';
// @ts-ignore
import { MobilityReportDTO } from '../dto';
// @ts-ignore
import { UpdateMobilityReceiptDTO } from '../dto';
// @ts-ignore
import { UploadLinkDTO } from '../dto';
/**
 * MobilityApi - axios parameter creator
 * @export
 */
export const MobilityApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Add a receipt.
         * @summary add a receipt
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {CreateMobilityReceiptDTO} createMobilityReceiptDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createReceipt: async (tenantId: string, employeeId: string, createMobilityReceiptDTO: CreateMobilityReceiptDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('createReceipt', 'tenantId', tenantId)
            // verify required parameter 'employeeId' is not null or undefined
            assertParamExists('createReceipt', 'employeeId', employeeId)
            // verify required parameter 'createMobilityReceiptDTO' is not null or undefined
            assertParamExists('createReceipt', 'createMobilityReceiptDTO', createMobilityReceiptDTO)
            const localVarPath = `/tenants/{tenantId}/employees/{employeeId}/benefits/mobility/receipts`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"employeeId"}}`, encodeURIComponent(String(employeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createMobilityReceiptDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a form POST upload link for uploading the receipt image. Refer to https://docs.aws.amazon.com/AmazonS3/latest/API/sigv4-post-example.html on how to implement the form POST.
         * @summary create a presigned upload link
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} [contentType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUploadLink: async (tenantId: string, employeeId: string, contentType?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('createUploadLink', 'tenantId', tenantId)
            // verify required parameter 'employeeId' is not null or undefined
            assertParamExists('createUploadLink', 'employeeId', employeeId)
            const localVarPath = `/tenants/{tenantId}/employees/{employeeId}/benefits/mobility/receipts/createUploadLink`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"employeeId"}}`, encodeURIComponent(String(employeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (contentType !== undefined) {
                localVarQueryParameter['contentType'] = contentType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete the receipt with the given ID. Approved receipts cannot be deleted.
         * @summary delete a single receipt
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} receiptId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteReceipt: async (tenantId: string, employeeId: string, receiptId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('deleteReceipt', 'tenantId', tenantId)
            // verify required parameter 'employeeId' is not null or undefined
            assertParamExists('deleteReceipt', 'employeeId', employeeId)
            // verify required parameter 'receiptId' is not null or undefined
            assertParamExists('deleteReceipt', 'receiptId', receiptId)
            const localVarPath = `/tenants/{tenantId}/employees/{employeeId}/benefits/mobility/receipts/{receiptId}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"employeeId"}}`, encodeURIComponent(String(employeeId)))
                .replace(`{${"receiptId"}}`, encodeURIComponent(String(receiptId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary list of employees with the mobility benefit report values
         * @param {string} month 
         * @param {string} tenantId 
         * @param {string} [department] 
         * @param {string} [filter] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmployeeReports: async (month: string, tenantId: string, department?: string, filter?: string, page?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'month' is not null or undefined
            assertParamExists('getEmployeeReports', 'month', month)
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('getEmployeeReports', 'tenantId', tenantId)
            const localVarPath = `/tenants/{tenantId}/benefits/mobility/reports/employees`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (month !== undefined) {
                localVarQueryParameter['month'] = month;
            }

            if (department !== undefined) {
                localVarQueryParameter['department'] = department;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get all mobility receipts for a tenant
         * @param {string} month 
         * @param {string} tenantId 
         * @param {string} [department] 
         * @param {string} [filter] 
         * @param {Array<string>} [sortBy] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMobilityReceipts: async (month: string, tenantId: string, department?: string, filter?: string, sortBy?: Array<string>, page?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'month' is not null or undefined
            assertParamExists('getMobilityReceipts', 'month', month)
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('getMobilityReceipts', 'tenantId', tenantId)
            const localVarPath = `/tenants/{tenantId}/benefits/mobility/receipts`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (month !== undefined) {
                localVarQueryParameter['month'] = month;
            }

            if (department !== undefined) {
                localVarQueryParameter['department'] = department;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (sortBy) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Read the receipts of the requested period. The Mobility benefit must be active for the employee at the given month.
         * @summary read the receipts of the requested period
         * @param {string} month 
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPeriodReceipts: async (month: string, tenantId: string, employeeId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'month' is not null or undefined
            assertParamExists('getPeriodReceipts', 'month', month)
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('getPeriodReceipts', 'tenantId', tenantId)
            // verify required parameter 'employeeId' is not null or undefined
            assertParamExists('getPeriodReceipts', 'employeeId', employeeId)
            const localVarPath = `/tenants/{tenantId}/employees/{employeeId}/benefits/mobility/receipts`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"employeeId"}}`, encodeURIComponent(String(employeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (month !== undefined) {
                localVarQueryParameter['month'] = month;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the periods in a range.
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} startMonth 
         * @param {string} endMonth 
         * @param {number} [maxCount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPeriods: async (tenantId: string, employeeId: string, startMonth: string, endMonth: string, maxCount?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('getPeriods', 'tenantId', tenantId)
            // verify required parameter 'employeeId' is not null or undefined
            assertParamExists('getPeriods', 'employeeId', employeeId)
            // verify required parameter 'startMonth' is not null or undefined
            assertParamExists('getPeriods', 'startMonth', startMonth)
            // verify required parameter 'endMonth' is not null or undefined
            assertParamExists('getPeriods', 'endMonth', endMonth)
            const localVarPath = `/tenants/{tenantId}/employees/{employeeId}/benefits/mobility/periods`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"employeeId"}}`, encodeURIComponent(String(employeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (startMonth !== undefined) {
                localVarQueryParameter['startMonth'] = startMonth;
            }

            if (endMonth !== undefined) {
                localVarQueryParameter['endMonth'] = endMonth;
            }

            if (maxCount !== undefined) {
                localVarQueryParameter['maxCount'] = maxCount;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Read a receipt.
         * @summary read a single receipt
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} receiptId 
         * @param {boolean} [withImage] if true, imageURL will be populated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReceipt: async (tenantId: string, employeeId: string, receiptId: string, withImage?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('getReceipt', 'tenantId', tenantId)
            // verify required parameter 'employeeId' is not null or undefined
            assertParamExists('getReceipt', 'employeeId', employeeId)
            // verify required parameter 'receiptId' is not null or undefined
            assertParamExists('getReceipt', 'receiptId', receiptId)
            const localVarPath = `/tenants/{tenantId}/employees/{employeeId}/benefits/mobility/receipts/{receiptId}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"employeeId"}}`, encodeURIComponent(String(employeeId)))
                .replace(`{${"receiptId"}}`, encodeURIComponent(String(receiptId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (withImage !== undefined) {
                localVarQueryParameter['withImage'] = withImage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get csv with receipt details
         * @param {string} month 
         * @param {string} tenantId 
         * @param {string} [department] 
         * @param {string} [filter] 
         * @param {Array<string>} [sortBy] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReceiptDetailsExport: async (month: string, tenantId: string, department?: string, filter?: string, sortBy?: Array<string>, page?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'month' is not null or undefined
            assertParamExists('getReceiptDetailsExport', 'month', month)
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('getReceiptDetailsExport', 'tenantId', tenantId)
            const localVarPath = `/tenants/{tenantId}/benefits/mobility/reports/receiptDetails`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (month !== undefined) {
                localVarQueryParameter['month'] = month;
            }

            if (department !== undefined) {
                localVarQueryParameter['department'] = department;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (sortBy) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary report values of the mobility benefit over all employees
         * @param {string} month 
         * @param {string} tenantId 
         * @param {string} [department] 
         * @param {string} [filter] 
         * @param {Array<string>} [sortBy] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReport: async (month: string, tenantId: string, department?: string, filter?: string, sortBy?: Array<string>, page?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'month' is not null or undefined
            assertParamExists('getReport', 'month', month)
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('getReport', 'tenantId', tenantId)
            const localVarPath = `/tenants/{tenantId}/benefits/mobility/reports`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (month !== undefined) {
                localVarQueryParameter['month'] = month;
            }

            if (department !== undefined) {
                localVarQueryParameter['department'] = department;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (sortBy) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a receipt.
         * @summary update a single receipt
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} receiptId 
         * @param {UpdateMobilityReceiptDTO} updateMobilityReceiptDTO 
         * @param {boolean} [isAudit] 
         * @param {boolean} [isTenantAudit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateReceipt: async (tenantId: string, employeeId: string, receiptId: string, updateMobilityReceiptDTO: UpdateMobilityReceiptDTO, isAudit?: boolean, isTenantAudit?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('updateReceipt', 'tenantId', tenantId)
            // verify required parameter 'employeeId' is not null or undefined
            assertParamExists('updateReceipt', 'employeeId', employeeId)
            // verify required parameter 'receiptId' is not null or undefined
            assertParamExists('updateReceipt', 'receiptId', receiptId)
            // verify required parameter 'updateMobilityReceiptDTO' is not null or undefined
            assertParamExists('updateReceipt', 'updateMobilityReceiptDTO', updateMobilityReceiptDTO)
            const localVarPath = `/tenants/{tenantId}/employees/{employeeId}/benefits/mobility/receipts/{receiptId}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"employeeId"}}`, encodeURIComponent(String(employeeId)))
                .replace(`{${"receiptId"}}`, encodeURIComponent(String(receiptId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (isAudit !== undefined) {
                localVarQueryParameter['isAudit'] = isAudit;
            }

            if (isTenantAudit !== undefined) {
                localVarQueryParameter['isTenantAudit'] = isTenantAudit;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateMobilityReceiptDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MobilityApi - functional programming interface
 * @export
 */
export const MobilityApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MobilityApiAxiosParamCreator(configuration)
    return {
        /**
         * Add a receipt.
         * @summary add a receipt
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {CreateMobilityReceiptDTO} createMobilityReceiptDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createReceipt(tenantId: string, employeeId: string, createMobilityReceiptDTO: CreateMobilityReceiptDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdentifierDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createReceipt(tenantId, employeeId, createMobilityReceiptDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create a form POST upload link for uploading the receipt image. Refer to https://docs.aws.amazon.com/AmazonS3/latest/API/sigv4-post-example.html on how to implement the form POST.
         * @summary create a presigned upload link
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} [contentType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUploadLink(tenantId: string, employeeId: string, contentType?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadLinkDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUploadLink(tenantId, employeeId, contentType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete the receipt with the given ID. Approved receipts cannot be deleted.
         * @summary delete a single receipt
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} receiptId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteReceipt(tenantId: string, employeeId: string, receiptId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteReceipt(tenantId, employeeId, receiptId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary list of employees with the mobility benefit report values
         * @param {string} month 
         * @param {string} tenantId 
         * @param {string} [department] 
         * @param {string} [filter] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEmployeeReports(month: string, tenantId: string, department?: string, filter?: string, page?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeMobilityReportsDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEmployeeReports(month, tenantId, department, filter, page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get all mobility receipts for a tenant
         * @param {string} month 
         * @param {string} tenantId 
         * @param {string} [department] 
         * @param {string} [filter] 
         * @param {Array<string>} [sortBy] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMobilityReceipts(month: string, tenantId: string, department?: string, filter?: string, sortBy?: Array<string>, page?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MobilityReceiptTenantListDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMobilityReceipts(month, tenantId, department, filter, sortBy, page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Read the receipts of the requested period. The Mobility benefit must be active for the employee at the given month.
         * @summary read the receipts of the requested period
         * @param {string} month 
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPeriodReceipts(month: string, tenantId: string, employeeId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MobilityReceiptsDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPeriodReceipts(month, tenantId, employeeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the periods in a range.
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} startMonth 
         * @param {string} endMonth 
         * @param {number} [maxCount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPeriods(tenantId: string, employeeId: string, startMonth: string, endMonth: string, maxCount?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MobilityPeriodsDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPeriods(tenantId, employeeId, startMonth, endMonth, maxCount, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Read a receipt.
         * @summary read a single receipt
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} receiptId 
         * @param {boolean} [withImage] if true, imageURL will be populated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReceipt(tenantId: string, employeeId: string, receiptId: string, withImage?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MobilityReceiptDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReceipt(tenantId, employeeId, receiptId, withImage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get csv with receipt details
         * @param {string} month 
         * @param {string} tenantId 
         * @param {string} [department] 
         * @param {string} [filter] 
         * @param {Array<string>} [sortBy] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReceiptDetailsExport(month: string, tenantId: string, department?: string, filter?: string, sortBy?: Array<string>, page?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReceiptDetailsExport(month, tenantId, department, filter, sortBy, page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary report values of the mobility benefit over all employees
         * @param {string} month 
         * @param {string} tenantId 
         * @param {string} [department] 
         * @param {string} [filter] 
         * @param {Array<string>} [sortBy] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReport(month: string, tenantId: string, department?: string, filter?: string, sortBy?: Array<string>, page?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MobilityReportDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReport(month, tenantId, department, filter, sortBy, page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update a receipt.
         * @summary update a single receipt
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} receiptId 
         * @param {UpdateMobilityReceiptDTO} updateMobilityReceiptDTO 
         * @param {boolean} [isAudit] 
         * @param {boolean} [isTenantAudit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateReceipt(tenantId: string, employeeId: string, receiptId: string, updateMobilityReceiptDTO: UpdateMobilityReceiptDTO, isAudit?: boolean, isTenantAudit?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateReceipt(tenantId, employeeId, receiptId, updateMobilityReceiptDTO, isAudit, isTenantAudit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MobilityApi - factory interface
 * @export
 */
export const MobilityApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MobilityApiFp(configuration)
    return {
        /**
         * Add a receipt.
         * @summary add a receipt
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {CreateMobilityReceiptDTO} createMobilityReceiptDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createReceipt(tenantId: string, employeeId: string, createMobilityReceiptDTO: CreateMobilityReceiptDTO, options?: any): AxiosPromise<IdentifierDTO> {
            return localVarFp.createReceipt(tenantId, employeeId, createMobilityReceiptDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a form POST upload link for uploading the receipt image. Refer to https://docs.aws.amazon.com/AmazonS3/latest/API/sigv4-post-example.html on how to implement the form POST.
         * @summary create a presigned upload link
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} [contentType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUploadLink(tenantId: string, employeeId: string, contentType?: string, options?: any): AxiosPromise<UploadLinkDTO> {
            return localVarFp.createUploadLink(tenantId, employeeId, contentType, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete the receipt with the given ID. Approved receipts cannot be deleted.
         * @summary delete a single receipt
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} receiptId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteReceipt(tenantId: string, employeeId: string, receiptId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteReceipt(tenantId, employeeId, receiptId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary list of employees with the mobility benefit report values
         * @param {string} month 
         * @param {string} tenantId 
         * @param {string} [department] 
         * @param {string} [filter] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmployeeReports(month: string, tenantId: string, department?: string, filter?: string, page?: number, pageSize?: number, options?: any): AxiosPromise<EmployeeMobilityReportsDTO> {
            return localVarFp.getEmployeeReports(month, tenantId, department, filter, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get all mobility receipts for a tenant
         * @param {string} month 
         * @param {string} tenantId 
         * @param {string} [department] 
         * @param {string} [filter] 
         * @param {Array<string>} [sortBy] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMobilityReceipts(month: string, tenantId: string, department?: string, filter?: string, sortBy?: Array<string>, page?: number, pageSize?: number, options?: any): AxiosPromise<MobilityReceiptTenantListDTO> {
            return localVarFp.getMobilityReceipts(month, tenantId, department, filter, sortBy, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * Read the receipts of the requested period. The Mobility benefit must be active for the employee at the given month.
         * @summary read the receipts of the requested period
         * @param {string} month 
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPeriodReceipts(month: string, tenantId: string, employeeId: string, options?: any): AxiosPromise<MobilityReceiptsDTO> {
            return localVarFp.getPeriodReceipts(month, tenantId, employeeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the periods in a range.
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} startMonth 
         * @param {string} endMonth 
         * @param {number} [maxCount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPeriods(tenantId: string, employeeId: string, startMonth: string, endMonth: string, maxCount?: number, options?: any): AxiosPromise<MobilityPeriodsDTO> {
            return localVarFp.getPeriods(tenantId, employeeId, startMonth, endMonth, maxCount, options).then((request) => request(axios, basePath));
        },
        /**
         * Read a receipt.
         * @summary read a single receipt
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} receiptId 
         * @param {boolean} [withImage] if true, imageURL will be populated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReceipt(tenantId: string, employeeId: string, receiptId: string, withImage?: boolean, options?: any): AxiosPromise<MobilityReceiptDTO> {
            return localVarFp.getReceipt(tenantId, employeeId, receiptId, withImage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get csv with receipt details
         * @param {string} month 
         * @param {string} tenantId 
         * @param {string} [department] 
         * @param {string} [filter] 
         * @param {Array<string>} [sortBy] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReceiptDetailsExport(month: string, tenantId: string, department?: string, filter?: string, sortBy?: Array<string>, page?: number, pageSize?: number, options?: any): AxiosPromise<string> {
            return localVarFp.getReceiptDetailsExport(month, tenantId, department, filter, sortBy, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary report values of the mobility benefit over all employees
         * @param {string} month 
         * @param {string} tenantId 
         * @param {string} [department] 
         * @param {string} [filter] 
         * @param {Array<string>} [sortBy] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReport(month: string, tenantId: string, department?: string, filter?: string, sortBy?: Array<string>, page?: number, pageSize?: number, options?: any): AxiosPromise<MobilityReportDTO> {
            return localVarFp.getReport(month, tenantId, department, filter, sortBy, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a receipt.
         * @summary update a single receipt
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} receiptId 
         * @param {UpdateMobilityReceiptDTO} updateMobilityReceiptDTO 
         * @param {boolean} [isAudit] 
         * @param {boolean} [isTenantAudit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateReceipt(tenantId: string, employeeId: string, receiptId: string, updateMobilityReceiptDTO: UpdateMobilityReceiptDTO, isAudit?: boolean, isTenantAudit?: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.updateReceipt(tenantId, employeeId, receiptId, updateMobilityReceiptDTO, isAudit, isTenantAudit, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createReceipt operation in MobilityApi.
 * @export
 * @interface MobilityApiCreateReceiptRequest
 */
export interface MobilityApiCreateReceiptRequest {
    /**
     * 
     * @type {string}
     * @memberof MobilityApiCreateReceipt
     */
    readonly tenantId: string

    /**
     * 
     * @type {string}
     * @memberof MobilityApiCreateReceipt
     */
    readonly employeeId: string

    /**
     * 
     * @type {CreateMobilityReceiptDTO}
     * @memberof MobilityApiCreateReceipt
     */
    readonly createMobilityReceiptDTO: CreateMobilityReceiptDTO
}

/**
 * Request parameters for createUploadLink operation in MobilityApi.
 * @export
 * @interface MobilityApiCreateUploadLinkRequest
 */
export interface MobilityApiCreateUploadLinkRequest {
    /**
     * 
     * @type {string}
     * @memberof MobilityApiCreateUploadLink
     */
    readonly tenantId: string

    /**
     * 
     * @type {string}
     * @memberof MobilityApiCreateUploadLink
     */
    readonly employeeId: string

    /**
     * 
     * @type {string}
     * @memberof MobilityApiCreateUploadLink
     */
    readonly contentType?: string
}

/**
 * Request parameters for deleteReceipt operation in MobilityApi.
 * @export
 * @interface MobilityApiDeleteReceiptRequest
 */
export interface MobilityApiDeleteReceiptRequest {
    /**
     * 
     * @type {string}
     * @memberof MobilityApiDeleteReceipt
     */
    readonly tenantId: string

    /**
     * 
     * @type {string}
     * @memberof MobilityApiDeleteReceipt
     */
    readonly employeeId: string

    /**
     * 
     * @type {string}
     * @memberof MobilityApiDeleteReceipt
     */
    readonly receiptId: string
}

/**
 * Request parameters for getEmployeeReports operation in MobilityApi.
 * @export
 * @interface MobilityApiGetEmployeeReportsRequest
 */
export interface MobilityApiGetEmployeeReportsRequest {
    /**
     * 
     * @type {string}
     * @memberof MobilityApiGetEmployeeReports
     */
    readonly month: string

    /**
     * 
     * @type {string}
     * @memberof MobilityApiGetEmployeeReports
     */
    readonly tenantId: string

    /**
     * 
     * @type {string}
     * @memberof MobilityApiGetEmployeeReports
     */
    readonly department?: string

    /**
     * 
     * @type {string}
     * @memberof MobilityApiGetEmployeeReports
     */
    readonly filter?: string

    /**
     * 
     * @type {number}
     * @memberof MobilityApiGetEmployeeReports
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof MobilityApiGetEmployeeReports
     */
    readonly pageSize?: number
}

/**
 * Request parameters for getMobilityReceipts operation in MobilityApi.
 * @export
 * @interface MobilityApiGetMobilityReceiptsRequest
 */
export interface MobilityApiGetMobilityReceiptsRequest {
    /**
     * 
     * @type {string}
     * @memberof MobilityApiGetMobilityReceipts
     */
    readonly month: string

    /**
     * 
     * @type {string}
     * @memberof MobilityApiGetMobilityReceipts
     */
    readonly tenantId: string

    /**
     * 
     * @type {string}
     * @memberof MobilityApiGetMobilityReceipts
     */
    readonly department?: string

    /**
     * 
     * @type {string}
     * @memberof MobilityApiGetMobilityReceipts
     */
    readonly filter?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof MobilityApiGetMobilityReceipts
     */
    readonly sortBy?: Array<string>

    /**
     * 
     * @type {number}
     * @memberof MobilityApiGetMobilityReceipts
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof MobilityApiGetMobilityReceipts
     */
    readonly pageSize?: number
}

/**
 * Request parameters for getPeriodReceipts operation in MobilityApi.
 * @export
 * @interface MobilityApiGetPeriodReceiptsRequest
 */
export interface MobilityApiGetPeriodReceiptsRequest {
    /**
     * 
     * @type {string}
     * @memberof MobilityApiGetPeriodReceipts
     */
    readonly month: string

    /**
     * 
     * @type {string}
     * @memberof MobilityApiGetPeriodReceipts
     */
    readonly tenantId: string

    /**
     * 
     * @type {string}
     * @memberof MobilityApiGetPeriodReceipts
     */
    readonly employeeId: string
}

/**
 * Request parameters for getPeriods operation in MobilityApi.
 * @export
 * @interface MobilityApiGetPeriodsRequest
 */
export interface MobilityApiGetPeriodsRequest {
    /**
     * 
     * @type {string}
     * @memberof MobilityApiGetPeriods
     */
    readonly tenantId: string

    /**
     * 
     * @type {string}
     * @memberof MobilityApiGetPeriods
     */
    readonly employeeId: string

    /**
     * 
     * @type {string}
     * @memberof MobilityApiGetPeriods
     */
    readonly startMonth: string

    /**
     * 
     * @type {string}
     * @memberof MobilityApiGetPeriods
     */
    readonly endMonth: string

    /**
     * 
     * @type {number}
     * @memberof MobilityApiGetPeriods
     */
    readonly maxCount?: number
}

/**
 * Request parameters for getReceipt operation in MobilityApi.
 * @export
 * @interface MobilityApiGetReceiptRequest
 */
export interface MobilityApiGetReceiptRequest {
    /**
     * 
     * @type {string}
     * @memberof MobilityApiGetReceipt
     */
    readonly tenantId: string

    /**
     * 
     * @type {string}
     * @memberof MobilityApiGetReceipt
     */
    readonly employeeId: string

    /**
     * 
     * @type {string}
     * @memberof MobilityApiGetReceipt
     */
    readonly receiptId: string

    /**
     * if true, imageURL will be populated
     * @type {boolean}
     * @memberof MobilityApiGetReceipt
     */
    readonly withImage?: boolean
}

/**
 * Request parameters for getReceiptDetailsExport operation in MobilityApi.
 * @export
 * @interface MobilityApiGetReceiptDetailsExportRequest
 */
export interface MobilityApiGetReceiptDetailsExportRequest {
    /**
     * 
     * @type {string}
     * @memberof MobilityApiGetReceiptDetailsExport
     */
    readonly month: string

    /**
     * 
     * @type {string}
     * @memberof MobilityApiGetReceiptDetailsExport
     */
    readonly tenantId: string

    /**
     * 
     * @type {string}
     * @memberof MobilityApiGetReceiptDetailsExport
     */
    readonly department?: string

    /**
     * 
     * @type {string}
     * @memberof MobilityApiGetReceiptDetailsExport
     */
    readonly filter?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof MobilityApiGetReceiptDetailsExport
     */
    readonly sortBy?: Array<string>

    /**
     * 
     * @type {number}
     * @memberof MobilityApiGetReceiptDetailsExport
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof MobilityApiGetReceiptDetailsExport
     */
    readonly pageSize?: number
}

/**
 * Request parameters for getReport operation in MobilityApi.
 * @export
 * @interface MobilityApiGetReportRequest
 */
export interface MobilityApiGetReportRequest {
    /**
     * 
     * @type {string}
     * @memberof MobilityApiGetReport
     */
    readonly month: string

    /**
     * 
     * @type {string}
     * @memberof MobilityApiGetReport
     */
    readonly tenantId: string

    /**
     * 
     * @type {string}
     * @memberof MobilityApiGetReport
     */
    readonly department?: string

    /**
     * 
     * @type {string}
     * @memberof MobilityApiGetReport
     */
    readonly filter?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof MobilityApiGetReport
     */
    readonly sortBy?: Array<string>

    /**
     * 
     * @type {number}
     * @memberof MobilityApiGetReport
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof MobilityApiGetReport
     */
    readonly pageSize?: number
}

/**
 * Request parameters for updateReceipt operation in MobilityApi.
 * @export
 * @interface MobilityApiUpdateReceiptRequest
 */
export interface MobilityApiUpdateReceiptRequest {
    /**
     * 
     * @type {string}
     * @memberof MobilityApiUpdateReceipt
     */
    readonly tenantId: string

    /**
     * 
     * @type {string}
     * @memberof MobilityApiUpdateReceipt
     */
    readonly employeeId: string

    /**
     * 
     * @type {string}
     * @memberof MobilityApiUpdateReceipt
     */
    readonly receiptId: string

    /**
     * 
     * @type {UpdateMobilityReceiptDTO}
     * @memberof MobilityApiUpdateReceipt
     */
    readonly updateMobilityReceiptDTO: UpdateMobilityReceiptDTO

    /**
     * 
     * @type {boolean}
     * @memberof MobilityApiUpdateReceipt
     */
    readonly isAudit?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof MobilityApiUpdateReceipt
     */
    readonly isTenantAudit?: boolean
}

/**
 * MobilityApi - object-oriented interface
 * @export
 * @class MobilityApi
 * @extends {BaseAPI}
 */
export class MobilityApi extends BaseAPI {
    /**
     * Add a receipt.
     * @summary add a receipt
     * @param {MobilityApiCreateReceiptRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobilityApi
     */
    public createReceipt(requestParameters: MobilityApiCreateReceiptRequest, options?: AxiosRequestConfig) {
        return MobilityApiFp(this.configuration).createReceipt(requestParameters.tenantId, requestParameters.employeeId, requestParameters.createMobilityReceiptDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a form POST upload link for uploading the receipt image. Refer to https://docs.aws.amazon.com/AmazonS3/latest/API/sigv4-post-example.html on how to implement the form POST.
     * @summary create a presigned upload link
     * @param {MobilityApiCreateUploadLinkRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobilityApi
     */
    public createUploadLink(requestParameters: MobilityApiCreateUploadLinkRequest, options?: AxiosRequestConfig) {
        return MobilityApiFp(this.configuration).createUploadLink(requestParameters.tenantId, requestParameters.employeeId, requestParameters.contentType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete the receipt with the given ID. Approved receipts cannot be deleted.
     * @summary delete a single receipt
     * @param {MobilityApiDeleteReceiptRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobilityApi
     */
    public deleteReceipt(requestParameters: MobilityApiDeleteReceiptRequest, options?: AxiosRequestConfig) {
        return MobilityApiFp(this.configuration).deleteReceipt(requestParameters.tenantId, requestParameters.employeeId, requestParameters.receiptId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary list of employees with the mobility benefit report values
     * @param {MobilityApiGetEmployeeReportsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobilityApi
     */
    public getEmployeeReports(requestParameters: MobilityApiGetEmployeeReportsRequest, options?: AxiosRequestConfig) {
        return MobilityApiFp(this.configuration).getEmployeeReports(requestParameters.month, requestParameters.tenantId, requestParameters.department, requestParameters.filter, requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get all mobility receipts for a tenant
     * @param {MobilityApiGetMobilityReceiptsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobilityApi
     */
    public getMobilityReceipts(requestParameters: MobilityApiGetMobilityReceiptsRequest, options?: AxiosRequestConfig) {
        return MobilityApiFp(this.configuration).getMobilityReceipts(requestParameters.month, requestParameters.tenantId, requestParameters.department, requestParameters.filter, requestParameters.sortBy, requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Read the receipts of the requested period. The Mobility benefit must be active for the employee at the given month.
     * @summary read the receipts of the requested period
     * @param {MobilityApiGetPeriodReceiptsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobilityApi
     */
    public getPeriodReceipts(requestParameters: MobilityApiGetPeriodReceiptsRequest, options?: AxiosRequestConfig) {
        return MobilityApiFp(this.configuration).getPeriodReceipts(requestParameters.month, requestParameters.tenantId, requestParameters.employeeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the periods in a range.
     * @param {MobilityApiGetPeriodsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobilityApi
     */
    public getPeriods(requestParameters: MobilityApiGetPeriodsRequest, options?: AxiosRequestConfig) {
        return MobilityApiFp(this.configuration).getPeriods(requestParameters.tenantId, requestParameters.employeeId, requestParameters.startMonth, requestParameters.endMonth, requestParameters.maxCount, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Read a receipt.
     * @summary read a single receipt
     * @param {MobilityApiGetReceiptRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobilityApi
     */
    public getReceipt(requestParameters: MobilityApiGetReceiptRequest, options?: AxiosRequestConfig) {
        return MobilityApiFp(this.configuration).getReceipt(requestParameters.tenantId, requestParameters.employeeId, requestParameters.receiptId, requestParameters.withImage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get csv with receipt details
     * @param {MobilityApiGetReceiptDetailsExportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobilityApi
     */
    public getReceiptDetailsExport(requestParameters: MobilityApiGetReceiptDetailsExportRequest, options?: AxiosRequestConfig) {
        return MobilityApiFp(this.configuration).getReceiptDetailsExport(requestParameters.month, requestParameters.tenantId, requestParameters.department, requestParameters.filter, requestParameters.sortBy, requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary report values of the mobility benefit over all employees
     * @param {MobilityApiGetReportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobilityApi
     */
    public getReport(requestParameters: MobilityApiGetReportRequest, options?: AxiosRequestConfig) {
        return MobilityApiFp(this.configuration).getReport(requestParameters.month, requestParameters.tenantId, requestParameters.department, requestParameters.filter, requestParameters.sortBy, requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a receipt.
     * @summary update a single receipt
     * @param {MobilityApiUpdateReceiptRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobilityApi
     */
    public updateReceipt(requestParameters: MobilityApiUpdateReceiptRequest, options?: AxiosRequestConfig) {
        return MobilityApiFp(this.configuration).updateReceipt(requestParameters.tenantId, requestParameters.employeeId, requestParameters.receiptId, requestParameters.updateMobilityReceiptDTO, requestParameters.isAudit, requestParameters.isTenantAudit, options).then((request) => request(this.axios, this.basePath));
    }
}
