import { Box, CircularProgress } from '@mui/material';
import { Suspense, lazy, useMemo } from 'react';
import { BikeleasingCalculator } from './BikeleasingCalculator';
import { BikeleasingContracts } from './BikeleasingContracts';
import { BikeleasingOverview } from './BikeleasingOverview';
import { BikeleasingRegister } from './BikeleasingRegister';
import { FullWidthCenterBox } from '../../../component/layout/BoxLayout';

const BikeleasingVendorMapWrapper = lazy(() => import('./map/BikeleasingVendorMapWrapper'));

export enum BikeleasingTabs {
  overview = 'OVERVIEW',
  calculator = 'CALCULATOR',
  vendorMap = 'VENDOR_MAP',
}
interface Props {
  tab?: BikeleasingTabs;
}

export const BikeleasingContent: React.FC<Props> = ({ tab }) => {
  const component = useMemo(() => {
    switch (tab) {
      case BikeleasingTabs.overview:
        return (
          <Box marginX={1}>
            <BikeleasingRegister />
            <BikeleasingOverview />
            <BikeleasingContracts />
          </Box>
        );
      case BikeleasingTabs.calculator:
        return <BikeleasingCalculator />;

      case BikeleasingTabs.vendorMap:
        return (
          <Suspense
            fallback={
              <FullWidthCenterBox mt={10}>
                <CircularProgress />
              </FullWidthCenterBox>
            }
          >
            <BikeleasingVendorMapWrapper />
          </Suspense>
        );
      default:
        break;
    }
  }, [tab]);

  return <>{component}</>;
};
