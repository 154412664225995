import React from 'react';
import { useTranslation } from 'react-i18next';
import { ConfirmationModal } from '../../../component/confirmationModal';
import { DialogState } from '../../../component/dialog/BasicDialog';

interface Props {
  dialogState: DialogState;
  onSubmit: () => void;
}

export const DiscardFormConfirmation: React.FC<Props> = ({ dialogState, onSubmit }) => {
  const { t } = useTranslation('benefitModule');

  return (
    <ConfirmationModal
      onCancel={dialogState.handleClose}
      onSubmit={onSubmit}
      cancelButtonTitle={t(`benefit.LUNCH.addReceiptFlow.confirmClose.cancelButtonTitle`)}
      okButtonTitle={t(`benefit.LUNCH.addReceiptFlow.confirmClose.okButtonTitle`)}
      title={t(`benefit.LUNCH.addReceiptFlow.confirmClose.title`)}
      message={t(`benefit.LUNCH.addReceiptFlow.confirmClose.message`)}
      isOpen={dialogState.isOpen}
    />
  );
};
