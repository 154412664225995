/* tslint:disable */
/* eslint-disable */
/**
 * Probonio full API Docs
 * If you stumbled upon this page and want to integrate Probonio into your business software [click here!](/api/external)    If you\'re looking for more information about Probonio [head over here](https://probonio.de)    **This documentation describes all Endpoints of the Probonio API. It\'s created for experienced Probonio Developers only!**  
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { IdentifierDTO } from '../dto';
// @ts-ignore
import { NotificationSettingsDTO } from '../dto';
// @ts-ignore
import { RegisterDeviceDTO } from '../dto';
// @ts-ignore
import { UpdateNotificationSettingsDTO } from '../dto';
// @ts-ignore
import { UpdateUserDTO } from '../dto';
// @ts-ignore
import { UserDTO } from '../dto';
// @ts-ignore
import { UserIdpDetailsDTO } from '../dto';
// @ts-ignore
import { UserReferralCodeDTO } from '../dto';
// @ts-ignore
import { UsersDTO } from '../dto';
/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary generate referral code
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createReferralCode: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('createReferralCode', 'userId', userId)
            const localVarPath = `/users/{userId}/generateReferralCode`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary delete the user (also from the idp)
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('deleteUser', 'userId', userId)
            const localVarPath = `/users/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get notification settings for user
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotificationSettings: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getNotificationSettings', 'userId', userId)
            const localVarPath = `/users/{userId}/notificationSettings`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get a user
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getUser', 'userId', userId)
            const localVarPath = `/users/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get a user\'s IDP details
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserIdpDetails: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getUserIdpDetails', 'userId', userId)
            const localVarPath = `/users/{userId}/idpDetails`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary list users
         * @param {Array<'ADMIN' | 'BAV_MANAGER' | 'AUDIT' | 'SUPPORT_VIEWER' | 'SALES_MANAGER' | 'CUSTOMER_SUCCESS' | 'COUPON_BUYER'>} [roles] find users that have any of the given roles
         * @param {string} [tenantId] 
         * @param {string} [filter] 
         * @param {string} [referralCode] 
         * @param {boolean} [hasGlobalOrTenantRole] 
         * @param {Array<string>} [sortBy] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUsers: async (roles?: Array<'ADMIN' | 'BAV_MANAGER' | 'AUDIT' | 'SUPPORT_VIEWER' | 'SALES_MANAGER' | 'CUSTOMER_SUCCESS' | 'COUPON_BUYER'>, tenantId?: string, filter?: string, referralCode?: string, hasGlobalOrTenantRole?: boolean, sortBy?: Array<string>, page?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (roles) {
                localVarQueryParameter['roles'] = roles;
            }

            if (tenantId !== undefined) {
                localVarQueryParameter['tenantId'] = tenantId;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (referralCode !== undefined) {
                localVarQueryParameter['referralCode'] = referralCode;
            }

            if (hasGlobalOrTenantRole !== undefined) {
                localVarQueryParameter['hasGlobalOrTenantRole'] = hasGlobalOrTenantRole;
            }

            if (sortBy) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary register a push device token for the user
         * @param {string} userId 
         * @param {RegisterDeviceDTO} registerDeviceDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerDevice: async (userId: string, registerDeviceDTO: RegisterDeviceDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('registerDevice', 'userId', userId)
            // verify required parameter 'registerDeviceDTO' is not null or undefined
            assertParamExists('registerDevice', 'registerDeviceDTO', registerDeviceDTO)
            const localVarPath = `/users/{userId}/devices`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(registerDeviceDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary send request for account deletion (to Probonio Support)
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendDeleteUserRequest: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('sendDeleteUserRequest', 'userId', userId)
            const localVarPath = `/users/{userId}/sendDeletionRequest`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary remove a push device token for the user
         * @param {string} userId 
         * @param {string} deviceToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unregisterDevice: async (userId: string, deviceToken: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('unregisterDevice', 'userId', userId)
            // verify required parameter 'deviceToken' is not null or undefined
            assertParamExists('unregisterDevice', 'deviceToken', deviceToken)
            const localVarPath = `/users/{userId}/devices/{deviceToken}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"deviceToken"}}`, encodeURIComponent(String(deviceToken)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update notification settings for user
         * @param {string} userId 
         * @param {UpdateNotificationSettingsDTO} updateNotificationSettingsDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNotificationSettings: async (userId: string, updateNotificationSettingsDTO: UpdateNotificationSettingsDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updateNotificationSettings', 'userId', userId)
            // verify required parameter 'updateNotificationSettingsDTO' is not null or undefined
            assertParamExists('updateNotificationSettings', 'updateNotificationSettingsDTO', updateNotificationSettingsDTO)
            const localVarPath = `/users/{userId}/notificationSettings`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateNotificationSettingsDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update the user
         * @param {string} userId 
         * @param {UpdateUserDTO} updateUserDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser: async (userId: string, updateUserDTO: UpdateUserDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updateUser', 'userId', userId)
            // verify required parameter 'updateUserDTO' is not null or undefined
            assertParamExists('updateUser', 'updateUserDTO', updateUserDTO)
            const localVarPath = `/users/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateUserDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary generate referral code
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createReferralCode(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserReferralCodeDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createReferralCode(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary delete the user (also from the idp)
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUser(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUser(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get notification settings for user
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNotificationSettings(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotificationSettingsDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNotificationSettings(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get a user
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUser(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUser(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get a user\'s IDP details
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserIdpDetails(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserIdpDetailsDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserIdpDetails(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary list users
         * @param {Array<'ADMIN' | 'BAV_MANAGER' | 'AUDIT' | 'SUPPORT_VIEWER' | 'SALES_MANAGER' | 'CUSTOMER_SUCCESS' | 'COUPON_BUYER'>} [roles] find users that have any of the given roles
         * @param {string} [tenantId] 
         * @param {string} [filter] 
         * @param {string} [referralCode] 
         * @param {boolean} [hasGlobalOrTenantRole] 
         * @param {Array<string>} [sortBy] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listUsers(roles?: Array<'ADMIN' | 'BAV_MANAGER' | 'AUDIT' | 'SUPPORT_VIEWER' | 'SALES_MANAGER' | 'CUSTOMER_SUCCESS' | 'COUPON_BUYER'>, tenantId?: string, filter?: string, referralCode?: string, hasGlobalOrTenantRole?: boolean, sortBy?: Array<string>, page?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UsersDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listUsers(roles, tenantId, filter, referralCode, hasGlobalOrTenantRole, sortBy, page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary register a push device token for the user
         * @param {string} userId 
         * @param {RegisterDeviceDTO} registerDeviceDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async registerDevice(userId: string, registerDeviceDTO: RegisterDeviceDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.registerDevice(userId, registerDeviceDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary send request for account deletion (to Probonio Support)
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendDeleteUserRequest(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdentifierDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendDeleteUserRequest(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary remove a push device token for the user
         * @param {string} userId 
         * @param {string} deviceToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unregisterDevice(userId: string, deviceToken: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unregisterDevice(userId, deviceToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary update notification settings for user
         * @param {string} userId 
         * @param {UpdateNotificationSettingsDTO} updateNotificationSettingsDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateNotificationSettings(userId: string, updateNotificationSettingsDTO: UpdateNotificationSettingsDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateNotificationSettings(userId, updateNotificationSettingsDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary update the user
         * @param {string} userId 
         * @param {UpdateUserDTO} updateUserDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUser(userId: string, updateUserDTO: UpdateUserDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUser(userId, updateUserDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * 
         * @summary generate referral code
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createReferralCode(userId: string, options?: any): AxiosPromise<UserReferralCodeDTO> {
            return localVarFp.createReferralCode(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary delete the user (also from the idp)
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser(userId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteUser(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get notification settings for user
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotificationSettings(userId: string, options?: any): AxiosPromise<NotificationSettingsDTO> {
            return localVarFp.getNotificationSettings(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get a user
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser(userId: string, options?: any): AxiosPromise<UserDTO> {
            return localVarFp.getUser(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get a user\'s IDP details
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserIdpDetails(userId: string, options?: any): AxiosPromise<UserIdpDetailsDTO> {
            return localVarFp.getUserIdpDetails(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary list users
         * @param {Array<'ADMIN' | 'BAV_MANAGER' | 'AUDIT' | 'SUPPORT_VIEWER' | 'SALES_MANAGER' | 'CUSTOMER_SUCCESS' | 'COUPON_BUYER'>} [roles] find users that have any of the given roles
         * @param {string} [tenantId] 
         * @param {string} [filter] 
         * @param {string} [referralCode] 
         * @param {boolean} [hasGlobalOrTenantRole] 
         * @param {Array<string>} [sortBy] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUsers(roles?: Array<'ADMIN' | 'BAV_MANAGER' | 'AUDIT' | 'SUPPORT_VIEWER' | 'SALES_MANAGER' | 'CUSTOMER_SUCCESS' | 'COUPON_BUYER'>, tenantId?: string, filter?: string, referralCode?: string, hasGlobalOrTenantRole?: boolean, sortBy?: Array<string>, page?: number, pageSize?: number, options?: any): AxiosPromise<UsersDTO> {
            return localVarFp.listUsers(roles, tenantId, filter, referralCode, hasGlobalOrTenantRole, sortBy, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary register a push device token for the user
         * @param {string} userId 
         * @param {RegisterDeviceDTO} registerDeviceDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerDevice(userId: string, registerDeviceDTO: RegisterDeviceDTO, options?: any): AxiosPromise<void> {
            return localVarFp.registerDevice(userId, registerDeviceDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary send request for account deletion (to Probonio Support)
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendDeleteUserRequest(userId: string, options?: any): AxiosPromise<IdentifierDTO> {
            return localVarFp.sendDeleteUserRequest(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary remove a push device token for the user
         * @param {string} userId 
         * @param {string} deviceToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unregisterDevice(userId: string, deviceToken: string, options?: any): AxiosPromise<void> {
            return localVarFp.unregisterDevice(userId, deviceToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update notification settings for user
         * @param {string} userId 
         * @param {UpdateNotificationSettingsDTO} updateNotificationSettingsDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNotificationSettings(userId: string, updateNotificationSettingsDTO: UpdateNotificationSettingsDTO, options?: any): AxiosPromise<void> {
            return localVarFp.updateNotificationSettings(userId, updateNotificationSettingsDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update the user
         * @param {string} userId 
         * @param {UpdateUserDTO} updateUserDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser(userId: string, updateUserDTO: UpdateUserDTO, options?: any): AxiosPromise<void> {
            return localVarFp.updateUser(userId, updateUserDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createReferralCode operation in UserApi.
 * @export
 * @interface UserApiCreateReferralCodeRequest
 */
export interface UserApiCreateReferralCodeRequest {
    /**
     * 
     * @type {string}
     * @memberof UserApiCreateReferralCode
     */
    readonly userId: string
}

/**
 * Request parameters for deleteUser operation in UserApi.
 * @export
 * @interface UserApiDeleteUserRequest
 */
export interface UserApiDeleteUserRequest {
    /**
     * 
     * @type {string}
     * @memberof UserApiDeleteUser
     */
    readonly userId: string
}

/**
 * Request parameters for getNotificationSettings operation in UserApi.
 * @export
 * @interface UserApiGetNotificationSettingsRequest
 */
export interface UserApiGetNotificationSettingsRequest {
    /**
     * 
     * @type {string}
     * @memberof UserApiGetNotificationSettings
     */
    readonly userId: string
}

/**
 * Request parameters for getUser operation in UserApi.
 * @export
 * @interface UserApiGetUserRequest
 */
export interface UserApiGetUserRequest {
    /**
     * 
     * @type {string}
     * @memberof UserApiGetUser
     */
    readonly userId: string
}

/**
 * Request parameters for getUserIdpDetails operation in UserApi.
 * @export
 * @interface UserApiGetUserIdpDetailsRequest
 */
export interface UserApiGetUserIdpDetailsRequest {
    /**
     * 
     * @type {string}
     * @memberof UserApiGetUserIdpDetails
     */
    readonly userId: string
}

/**
 * Request parameters for listUsers operation in UserApi.
 * @export
 * @interface UserApiListUsersRequest
 */
export interface UserApiListUsersRequest {
    /**
     * find users that have any of the given roles
     * @type {Array<'ADMIN' | 'BAV_MANAGER' | 'AUDIT' | 'SUPPORT_VIEWER' | 'SALES_MANAGER' | 'CUSTOMER_SUCCESS' | 'COUPON_BUYER'>}
     * @memberof UserApiListUsers
     */
    readonly roles?: Array<'ADMIN' | 'BAV_MANAGER' | 'AUDIT' | 'SUPPORT_VIEWER' | 'SALES_MANAGER' | 'CUSTOMER_SUCCESS' | 'COUPON_BUYER'>

    /**
     * 
     * @type {string}
     * @memberof UserApiListUsers
     */
    readonly tenantId?: string

    /**
     * 
     * @type {string}
     * @memberof UserApiListUsers
     */
    readonly filter?: string

    /**
     * 
     * @type {string}
     * @memberof UserApiListUsers
     */
    readonly referralCode?: string

    /**
     * 
     * @type {boolean}
     * @memberof UserApiListUsers
     */
    readonly hasGlobalOrTenantRole?: boolean

    /**
     * 
     * @type {Array<string>}
     * @memberof UserApiListUsers
     */
    readonly sortBy?: Array<string>

    /**
     * 
     * @type {number}
     * @memberof UserApiListUsers
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof UserApiListUsers
     */
    readonly pageSize?: number
}

/**
 * Request parameters for registerDevice operation in UserApi.
 * @export
 * @interface UserApiRegisterDeviceRequest
 */
export interface UserApiRegisterDeviceRequest {
    /**
     * 
     * @type {string}
     * @memberof UserApiRegisterDevice
     */
    readonly userId: string

    /**
     * 
     * @type {RegisterDeviceDTO}
     * @memberof UserApiRegisterDevice
     */
    readonly registerDeviceDTO: RegisterDeviceDTO
}

/**
 * Request parameters for sendDeleteUserRequest operation in UserApi.
 * @export
 * @interface UserApiSendDeleteUserRequestRequest
 */
export interface UserApiSendDeleteUserRequestRequest {
    /**
     * 
     * @type {string}
     * @memberof UserApiSendDeleteUserRequest
     */
    readonly userId: string
}

/**
 * Request parameters for unregisterDevice operation in UserApi.
 * @export
 * @interface UserApiUnregisterDeviceRequest
 */
export interface UserApiUnregisterDeviceRequest {
    /**
     * 
     * @type {string}
     * @memberof UserApiUnregisterDevice
     */
    readonly userId: string

    /**
     * 
     * @type {string}
     * @memberof UserApiUnregisterDevice
     */
    readonly deviceToken: string
}

/**
 * Request parameters for updateNotificationSettings operation in UserApi.
 * @export
 * @interface UserApiUpdateNotificationSettingsRequest
 */
export interface UserApiUpdateNotificationSettingsRequest {
    /**
     * 
     * @type {string}
     * @memberof UserApiUpdateNotificationSettings
     */
    readonly userId: string

    /**
     * 
     * @type {UpdateNotificationSettingsDTO}
     * @memberof UserApiUpdateNotificationSettings
     */
    readonly updateNotificationSettingsDTO: UpdateNotificationSettingsDTO
}

/**
 * Request parameters for updateUser operation in UserApi.
 * @export
 * @interface UserApiUpdateUserRequest
 */
export interface UserApiUpdateUserRequest {
    /**
     * 
     * @type {string}
     * @memberof UserApiUpdateUser
     */
    readonly userId: string

    /**
     * 
     * @type {UpdateUserDTO}
     * @memberof UserApiUpdateUser
     */
    readonly updateUserDTO: UpdateUserDTO
}

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * 
     * @summary generate referral code
     * @param {UserApiCreateReferralCodeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public createReferralCode(requestParameters: UserApiCreateReferralCodeRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).createReferralCode(requestParameters.userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary delete the user (also from the idp)
     * @param {UserApiDeleteUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public deleteUser(requestParameters: UserApiDeleteUserRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).deleteUser(requestParameters.userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get notification settings for user
     * @param {UserApiGetNotificationSettingsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getNotificationSettings(requestParameters: UserApiGetNotificationSettingsRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).getNotificationSettings(requestParameters.userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get a user
     * @param {UserApiGetUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUser(requestParameters: UserApiGetUserRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).getUser(requestParameters.userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get a user\'s IDP details
     * @param {UserApiGetUserIdpDetailsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUserIdpDetails(requestParameters: UserApiGetUserIdpDetailsRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).getUserIdpDetails(requestParameters.userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary list users
     * @param {UserApiListUsersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public listUsers(requestParameters: UserApiListUsersRequest = {}, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).listUsers(requestParameters.roles, requestParameters.tenantId, requestParameters.filter, requestParameters.referralCode, requestParameters.hasGlobalOrTenantRole, requestParameters.sortBy, requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary register a push device token for the user
     * @param {UserApiRegisterDeviceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public registerDevice(requestParameters: UserApiRegisterDeviceRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).registerDevice(requestParameters.userId, requestParameters.registerDeviceDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary send request for account deletion (to Probonio Support)
     * @param {UserApiSendDeleteUserRequestRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public sendDeleteUserRequest(requestParameters: UserApiSendDeleteUserRequestRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).sendDeleteUserRequest(requestParameters.userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary remove a push device token for the user
     * @param {UserApiUnregisterDeviceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public unregisterDevice(requestParameters: UserApiUnregisterDeviceRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).unregisterDevice(requestParameters.userId, requestParameters.deviceToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update notification settings for user
     * @param {UserApiUpdateNotificationSettingsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public updateNotificationSettings(requestParameters: UserApiUpdateNotificationSettingsRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).updateNotificationSettings(requestParameters.userId, requestParameters.updateNotificationSettingsDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update the user
     * @param {UserApiUpdateUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public updateUser(requestParameters: UserApiUpdateUserRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).updateUser(requestParameters.userId, requestParameters.updateUserDTO, options).then((request) => request(this.axios, this.basePath));
    }
}
