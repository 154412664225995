import { App } from '@capacitor/app';
import { Browser } from '@capacitor/browser';
import { PluginListenerHandle } from '@capacitor/core';
import { LocationLike } from '@openid/appauth';

export class CapacitorBrowserLocationLike implements LocationLike {
  public hash: string = '';
  public host: string = '';
  public origin: string = '';
  public hostname: string = '';
  public pathname: string = '';
  public port: string = '';
  public protocol: string = '';
  public search: string = '';

  private appUrlListenerHandle?: PluginListenerHandle;
  private closeBrowserListenerHandle?: PluginListenerHandle;

  public constructor(
    private readonly redirectUrlBase: string,
    private readonly onCallback: () => void,
  ) {}

  public assign(url: string): void {
    void App.addListener('appUrlOpen', event => {
      if (this.handleRedirect(event.url)) {
        void this.removeHandles().then(() => Browser.close().catch(() => void 0));
      }
    }).then(appUrlListenerHandle => (this.appUrlListenerHandle = appUrlListenerHandle));

    void Browser.addListener('browserFinished', () => {
      void this.removeHandles();
    }).then(closeBrowserListenerHandle => (this.closeBrowserListenerHandle = closeBrowserListenerHandle));
    void Browser.open({ url });
  }

  private async removeHandles(): Promise<void> {
    await this.closeBrowserListenerHandle?.remove();
    this.closeBrowserListenerHandle = undefined;
    await this.appUrlListenerHandle?.remove();
    this.appUrlListenerHandle = undefined;
  }

  private handleRedirect(url: string): boolean | undefined {
    if (this.redirectUrlBase && url.startsWith(this.redirectUrlBase)) {
      // split url into origin, pathname, search and hash
      const parsedUrl = /([a-z.]+:\/\/[^/?#]+)(\/[^?#]*)?(\?[^#]+)?(#.*)?/.exec(url);
      if (parsedUrl) {
        this.origin = parsedUrl[1];
        this.pathname = parsedUrl[2] || '';
        this.search = parsedUrl[3] || '';
        this.hash = parsedUrl[4] || '';
        this.onCallback();
        return true;
      }
    }
  }
}
