import CheckIcon from '@mui/icons-material/Check';
import { Box } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { UpdateUserDTO } from 'probonio-shared-ui/api';
import { apis } from 'probonio-shared-ui/module/api';
import { loadMe } from 'probonio-shared-ui/module/me';
import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CloseIconButton } from '../component/button/CloseIconButton';
import { LoadingLinkIconButton } from '../component/button/LoadingLinkIconButton';
import { MobileLayout } from '../component/layout';
import { AppToolbar } from '../component/layout/AppToolbar';
import EditMeForm from '../module/me/EditMeForm';
import { UserDeleteRequestButton } from '../module/me/UserDeleteRequestButton';
import { useBackNavigator } from '../module/navigation/BackNavigator';
import { useAppDispatch, useAppSelector } from '../redux/hooks';

interface MeFields {
  firstName: string;
  lastName: string;
  title: string;
  salutation: string;
}

const EditProfilePage: React.FC = () => {
  const { t } = useTranslation('profilePage');
  const backNavigator = useBackNavigator();
  const { enqueueSnackbar } = useSnackbar();

  const [userId, firstName, lastName, title, salutation] = useAppSelector(state => [
    state.me.user?.id || '',
    state.me?.user?.firstName || '',
    state.me?.user?.lastName || '',
    state.me?.user?.title || '',
    state.me?.user?.salutation || '',
  ]);

  const dispatch = useAppDispatch();

  const { handleSubmit, control } = useForm<MeFields>({
    defaultValues: {
      firstName,
      lastName,
      title,
      salutation,
    },
  });

  const mutation = useMutation({
    mutationFn: (userDTO: UpdateUserDTO) => apis.user.updateUser({ userId, updateUserDTO: userDTO }),
    onSuccess: async () => {
      await dispatch(loadMe(true));
      enqueueSnackbar(t('success'), { variant: 'success' });
      backNavigator.back();
    },
  });

  const handleSubmitMe = useCallback(
    (data: MeFields) => {
      const updateMe: UpdateUserDTO = {
        firstName: data.firstName.trim(),
        lastName: data.lastName.trim(),
        title: data.title.trim(),
        salutation: data.salutation.trim(),
      };
      mutation.mutate(updateMe);
    },
    [mutation],
  );

  return (
    <MobileLayout
      paperBackground
      header={
        <AppToolbar
          title={t('modifyProfile')}
          backButton={<CloseIconButton onClick={backNavigator.back} />}
          actionButton={
            <LoadingLinkIconButton loading={mutation.isPending} onClick={handleSubmit(handleSubmitMe)}>
              <CheckIcon />
            </LoadingLinkIconButton>
          }
        />
      }
    >
      <Box sx={{ padding: 1 }}>
        <EditMeForm onSubmit={handleSubmit(handleSubmitMe)} control={control} />
        <UserDeleteRequestButton userId={userId} />
      </Box>
    </MobileLayout>
  );
};

export default EditProfilePage;
