/* tslint:disable */
/* eslint-disable */
/**
 * Probonio full API Docs
 * If you stumbled upon this page and want to integrate Probonio into your business software [click here!](/api/external)    If you\'re looking for more information about Probonio [head over here](https://probonio.de)    **This documentation describes all Endpoints of the Probonio API. It\'s created for experienced Probonio Developers only!**  
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface MobilityReceiptDTO
 */
export interface MobilityReceiptDTO {
    /**
     * 
     * @type {string}
     * @memberof MobilityReceiptDTO
     */
    'status': MobilityReceiptDTOStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof MobilityReceiptDTO
     * @deprecated
     */
    'receiptType': MobilityReceiptDTOReceiptTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof MobilityReceiptDTO
     */
    'mobilityType': MobilityReceiptDTOMobilityTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof MobilityReceiptDTO
     */
    'travelPurpose': MobilityReceiptDTOTravelPurposeEnum;
    /**
     * first month of time card validity
     * @type {string}
     * @memberof MobilityReceiptDTO
     */
    'validityStartDate'?: string;
    /**
     * last month of time card validity
     * @type {string}
     * @memberof MobilityReceiptDTO
     */
    'validityEndDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof MobilityReceiptDTO
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof MobilityReceiptDTO
     */
    'total': number;
    /**
     * 
     * @type {string}
     * @memberof MobilityReceiptDTO
     */
    'date': string;
    /**
     * 
     * @type {string}
     * @memberof MobilityReceiptDTO
     */
    'comment'?: string;
    /**
     * 
     * @type {string}
     * @memberof MobilityReceiptDTO
     */
    'rejectionReason'?: string;
    /**
     * 
     * @type {string}
     * @memberof MobilityReceiptDTO
     */
    'imageURL'?: string;
    /**
     * 
     * @type {string}
     * @memberof MobilityReceiptDTO
     */
    'thumbnailURL'?: string;
    /**
     * 
     * @type {string}
     * @memberof MobilityReceiptDTO
     */
    'pdfURL'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MobilityReceiptDTO
     */
    'isAuditedByTenant'?: boolean;
}

export const MobilityReceiptDTOStatusEnum = {
    Approved: 'APPROVED',
    Rejected: 'REJECTED',
    Pending: 'PENDING'
} as const;

export type MobilityReceiptDTOStatusEnum = typeof MobilityReceiptDTOStatusEnum[keyof typeof MobilityReceiptDTOStatusEnum];
export const MobilityReceiptDTOReceiptTypeEnum = {
    Local: 'LOCAL',
    LongDistance: 'LONG_DISTANCE'
} as const;

export type MobilityReceiptDTOReceiptTypeEnum = typeof MobilityReceiptDTOReceiptTypeEnum[keyof typeof MobilityReceiptDTOReceiptTypeEnum];
export const MobilityReceiptDTOMobilityTypeEnum = {
    LocalTrain: 'LOCAL_TRAIN',
    LongDistanceTrain: 'LONG_DISTANCE_TRAIN',
    LocalBus: 'LOCAL_BUS',
    LongDistanceBus: 'LONG_DISTANCE_BUS',
    Car: 'CAR',
    SharingOffers: 'SHARING_OFFERS',
    Taxi: 'TAXI',
    Bike: 'BIKE',
    TimeCard: 'TIME_CARD',
    DeutschlandTicket: 'DEUTSCHLAND_TICKET',
    Others: 'OTHERS'
} as const;

export type MobilityReceiptDTOMobilityTypeEnum = typeof MobilityReceiptDTOMobilityTypeEnum[keyof typeof MobilityReceiptDTOMobilityTypeEnum];
export const MobilityReceiptDTOTravelPurposeEnum = {
    Work: 'WORK',
    Private: 'PRIVATE'
} as const;

export type MobilityReceiptDTOTravelPurposeEnum = typeof MobilityReceiptDTOTravelPurposeEnum[keyof typeof MobilityReceiptDTOTravelPurposeEnum];


