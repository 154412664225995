import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Box, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { switchTenant, useActiveEmployment, useAvailableTenants, useTenant } from 'probonio-shared-ui/module/me';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { BasicDialog, DialogState } from '../../component/dialog/BasicDialog';
import { useAppDispatch } from '../../redux/hooks';

interface Props {
  dialogState: DialogState;
}

export const SwitchTenantDialog: React.FC<Props> = ({ dialogState }) => {
  const { t } = useTranslation('profilePage');
  const { tenant, isLoading } = useTenant();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const getActiveEmployment = useActiveEmployment();
  const [selectedTenantId, setSelectedTenantId] = useState<string>();

  const availableTenants = useAvailableTenants();

  const handleSelect = useCallback(
    (ev: React.MouseEvent<HTMLElement>, value: string | null) => {
      const newTenantId = value || getActiveEmployment().tenantId;
      setSelectedTenantId(newTenantId);
      dispatch(switchTenant({ tenantId: newTenantId }));
    },
    [dispatch, getActiveEmployment],
  );

  useEffect(() => {
    if (dialogState.isOpen) {
      setSelectedTenantId(undefined);
    }
  }, [dialogState.isOpen]);

  useEffect(() => {
    const closeDialog = dialogState.handleClose;
    if (selectedTenantId && selectedTenantId === tenant?.id) {
      closeDialog();
      navigate('/home');
    }
  }, [dialogState.handleClose, navigate, selectedTenantId, tenant]);

  return (
    <BasicDialog
      dialogState={dialogState}
      maxWidth="xs"
      title={t('switchTenant')}
      content={
        <>
          <Typography color="text.secondary" mb={1}>
            {t('switchTenantInfo')}
          </Typography>
          <ToggleButtonGroup
            color="primary"
            orientation="vertical"
            fullWidth
            size="large"
            exclusive
            disabled={isLoading}
            value={getActiveEmployment().tenantId}
            onChange={handleSelect}
          >
            {availableTenants?.map(tenant => (
              <ToggleButton key={tenant.id} value={tenant.id} sx={{ justifyContent: 'space-between' }}>
                <Box display="flex" flexGrow={1} alignItems="center" justifyContent="space-between" textAlign="left" color="primary.main">
                  <div>
                    {tenant.name}{' '}
                    {tenant.isInactive && (
                      <Typography component="span" variant="inherit" color="text.secondary">
                        ({t('switchTenantInactive')})
                      </Typography>
                    )}
                  </div>
                  <ChevronRightIcon />
                </Box>
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </>
      }
      actions={<></>}
    />
  );
};
