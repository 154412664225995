import { useMutation, UseMutationResult, useQueryClient, UseQueryResult } from '@tanstack/react-query';
import { DateTime } from 'luxon';
import { InternetReceiptRefundDTO } from 'probonio-shared-ui/api';
import { apis, useEmployeeQuery } from 'probonio-shared-ui/module/api';
import { useActiveEmployment } from 'probonio-shared-ui/module/me';
import { calculateBlobSHA256, storeHash } from 'probonio-shared-ui/utils/hashStorage';
import { useCallback } from 'react';
import { ImageFile } from '../../component/camera/ImageFile';
import { downloadResponseNative } from '../../utils/fileConversion/nativeFileDownload';
import { uploadInternetImageFile } from './internetFileUpload';

export function useInternetReceiptInProgress(): UseQueryResult<InternetReceiptRefundDTO, Error> {
  return useEmployeeQuery(['benefits', 'internet', 'receipts'], params =>
    apis.internet.getReceiptByMonth({ ...params, inProgress: true }).then(res => res.data),
  );
}

export function useInvalidateInternetBenefit(): () => void {
  const queryClient = useQueryClient();
  const getActiveEmployment = useActiveEmployment();

  return useCallback(() => {
    void queryClient.invalidateQueries({
      queryKey: ['tenants', getActiveEmployment().tenantId, 'employees', getActiveEmployment().id, 'benefits'],
    });
  }, [getActiveEmployment, queryClient]);
}

export function useCreateInternetReceiptMutation(): UseMutationResult<string, Error, number> {
  const getActiveEmployment = useActiveEmployment();
  const invalidateInternetBenefit = useInvalidateInternetBenefit();

  return useMutation({
    mutationFn: async (total: number) => {
      const { id } = await apis.internet
        .createReceipt({
          tenantId: getActiveEmployment().tenantId,
          employeeId: getActiveEmployment().id,
          createInternetReceiptDTO: { total },
        })
        .then(res => res.data);
      await apis.internet.sendStatementByMail({
        tenantId: getActiveEmployment().tenantId,
        employeeId: getActiveEmployment().id,
        receiptId: id,
      });
      return id;
    },

    onSuccess: () => {
      invalidateInternetBenefit();
    },
  });
}

export function useDownloadInternetStatementMutation(): UseMutationResult<void, Error, string> {
  const getActiveEmployment = useActiveEmployment();

  return useMutation({
    mutationFn: async (receiptId: string) => {
      const response = await apis.internet.downloadStatement(
        {
          tenantId: getActiveEmployment().tenantId,
          employeeId: getActiveEmployment().id,
          receiptId,
        },
        { responseType: 'blob' },
      );
      const hash = await calculateBlobSHA256(response.data);
      storeHash('internetConfirmation', hash);
      await downloadResponseNative(response, `Bestätigung Internetpauschale ${DateTime.utc().toISODate()}.pdf`);
    },
  });
}

export function useUploadReceiptStatementMutation(): UseMutationResult<
  void,
  Error,
  { receiptId: string; imageFile: ImageFile; pdf?: Blob }
> {
  const getActiveEmployment = useActiveEmployment();
  const invalidateInternetBenefit = useInvalidateInternetBenefit();

  return useMutation({
    mutationFn: async ({ receiptId, imageFile, pdf }) => {
      const imageBlob = await imageFile.getBlob();

      const imageKeys = await uploadInternetImageFile(getActiveEmployment().tenantId, getActiveEmployment().id, imageBlob, pdf);

      await apis.internet.updateExpirationDate({
        tenantId: getActiveEmployment().tenantId,
        employeeId: getActiveEmployment().id,
        receiptId,
        updateInternetReceiptDTO: { imageKey: imageKeys.imageKey, pdfKey: imageKeys.pdfKey },
      });
      invalidateInternetBenefit();
    },
  });
}

export function useDeleteReceiptMutation(): UseMutationResult<void, Error, string> {
  const getActiveEmployment = useActiveEmployment();
  const invalidateInternetBenefit = useInvalidateInternetBenefit();

  return useMutation({
    mutationFn: async receiptId => {
      await apis.internet.deleteReceipt({
        tenantId: getActiveEmployment().tenantId,
        employeeId: getActiveEmployment().id,
        receiptId,
      });
      invalidateInternetBenefit();
    },
  });
}

export function useConfirmInternetReceiptMutation(): UseMutationResult<void, Error, string> {
  const getActiveEmployment = useActiveEmployment();
  const invalidateInternetBenefit = useInvalidateInternetBenefit();

  return useMutation({
    mutationFn: async receiptId => {
      await apis.internet.updateExpirationDate({
        tenantId: getActiveEmployment().tenantId,
        employeeId: getActiveEmployment().id,
        receiptId,
        updateInternetReceiptDTO: {
          expirationDate: DateTime.now().plus({ year: 1 }).toISODate()!,
        },
      });
      invalidateInternetBenefit();
    },
  });
}
