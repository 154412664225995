import { Box, CircularProgress, useTheme } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { AvailableGiftDTO, CouponDefinitionDTO } from 'probonio-shared-ui/api';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { apis } from '../../../../probonio-shared-ui/module/api';
import { useActiveEmployment } from '../../../../probonio-shared-ui/module/me';
import { BackIconButton } from '../../component/button/BackIconButton';
import { MobileLayout } from '../../component/layout';
import { AppToolbar } from '../../component/layout/AppToolbar';
import { SwipeableList } from '../../component/swipeableList/SwipeableList';
import { ChangeDefaultCouponButton, useAvailableCoupons } from '../../module/coupons';
import { ChangeDefaultCouponCheckbox, useDefaultCouponState } from '../../module/coupons/ChangeDefaultCouponCheckbox';
import { useCouponDefinitionListQuery, useCouponDefinitionQuery } from '../../module/coupons/couponQueries';
import { getFirstCouponCombination } from '../../module/couponsShared/couponCombinations';
import { ExploreCouponsDetail } from '../../module/couponsShared/explore/ExploreCouponsDetail';
import { ALL_CATEGORY } from '../../module/couponsShared/redux/createCouponsSlice';
import { useBackNavigator } from '../../module/navigation/BackNavigator';
import { useAppSelector } from '../../redux/hooks';
import { useLoadingCallback } from '../../utils/loadingFunction';

const ExploreCouponsDetailPage: React.FC = () => {
  const { t } = useTranslation('couponsModule');
  const { couponId } = useParams();
  const { availableCoupons, refetchAvailableCoupons } = useAvailableCoupons();
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const gift = location.state as AvailableGiftDTO;
  const backNavigator = useBackNavigator();
  const selectedCategory = useAppSelector(state => state.coupons.selectedCategory);
  const getActiveEmployment = useActiveEmployment();

  const variation = gift ? gift.amount : availableCoupons?.couponMonthlyBudget;
  const availableAmounts = useMemo(() => {
    const availableAmount = gift ? gift.amount : availableCoupons?.coupons[0]?.amount;
    return availableAmount ? [availableAmount] : undefined;
  }, [availableCoupons?.coupons, gift]);

  const { data: coupons, isLoading: couponsIsLoading } = useCouponDefinitionListQuery({
    variation,
    includeCombinations: true,
    category: selectedCategory !== ALL_CATEGORY && !!selectedCategory ? selectedCategory : undefined,
  });
  const { data: selectedCoupon, isLoading: selectedCouponIsLoading } = useCouponDefinitionQuery(couponId);

  const defaultCouponState = useDefaultCouponState(selectedCoupon, gift);

  const orderMutation = useMutation({
    mutationFn: (amount: number) => {
      const combination = getFirstCouponCombination(selectedCoupon!.variations, amount);
      return apis.coupons.createCouponOrder({
        tenantId: getActiveEmployment().tenantId,
        employeeId: getActiveEmployment().id,
        createCouponOrderDTO: {
          couponDefinitionId: selectedCoupon!.id,
          combination,
          couponGiftId: gift?.couponGiftId,
        },
      });
    },

    onSuccess: () => {
      refetchAvailableCoupons();
      navigate(-1);
      setTimeout(() => {
        navigate(-1);
      }, 100);
    },
  });

  const handleOrder = useLoadingCallback(
    (amount: number) => {
      orderMutation.mutate(amount);
      defaultCouponState.handleConfirm();
    },
    [orderMutation],
    orderMutation.isPending,
  );

  const handleNavigate = useCallback(
    (item: CouponDefinitionDTO) => {
      navigate(`/coupons/explore-coupons/${item.id}`, { replace: true });
    },
    [navigate],
  );

  return (
    <MobileLayout
      header={<AppToolbar backButton={<BackIconButton onClick={backNavigator.back} />} />}
      backgroundColor={theme.palette.background.default}
    >
      <Box sx={{ paddingX: 1, paddingBottom: 1 }}>
        <SwipeableList items={coupons?.coupons} currentItemId={couponId!} onNavigate={handleNavigate}>
          {selectedCoupon && !selectedCouponIsLoading && !couponsIsLoading ? (
            <>
              <ExploreCouponsDetail
                coupon={selectedCoupon}
                availableAmounts={availableAmounts}
                onOrder={handleOrder}
                dialogExtension={<ChangeDefaultCouponCheckbox state={defaultCouponState} />}
                tenantBudgetAvailable={gift ? true : availableCoupons?.tenantBudgetAvailable}
                noneAvailableMessage={availableCoupons?.usedMonthlyBudget ? undefined : t('exploreCoupons.details.noSelectionPossible')}
              />
              {defaultCouponState.allowChange && <ChangeDefaultCouponButton couponDefinition={selectedCoupon} />}
            </>
          ) : (
            <Box textAlign="center">
              <CircularProgress />
            </Box>
          )}
        </SwipeableList>
      </Box>
    </MobileLayout>
  );
};

export default ExploreCouponsDetailPage;
