import { Box, styled } from '@mui/material';

export const FullWidthCenterBox = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  textAlign: 'center',
}));

export const GradientBox = styled(Box)(({ theme }) => ({
  background: `linear-gradient(180deg, ${theme.palette.background.default} 40%, #ffffff 100%)`,
}));

export const HorizontalBox = styled(Box)(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'row',
}));

export const VerticalBox = styled(Box)(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'column',
}));
