/* tslint:disable */
/* eslint-disable */
/**
 * Probonio full API Docs
 * If you stumbled upon this page and want to integrate Probonio into your business software [click here!](/api/external)    If you\'re looking for more information about Probonio [head over here](https://probonio.de)    **This documentation describes all Endpoints of the Probonio API. It\'s created for experienced Probonio Developers only!**  
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CreateDocumentDTO } from '../dto';
// @ts-ignore
import { CreateDocumentUploadLinkDTO } from '../dto';
// @ts-ignore
import { DocumentDTO } from '../dto';
// @ts-ignore
import { DocumentsDTO } from '../dto';
// @ts-ignore
import { IdentifierDTO } from '../dto';
// @ts-ignore
import { UploadLinkDTO } from '../dto';
/**
 * DocumentsApi - axios parameter creator
 * @export
 */
export const DocumentsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary upload a new document
         * @param {CreateDocumentDTO} createDocumentDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDocument: async (createDocumentDTO: CreateDocumentDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createDocumentDTO' is not null or undefined
            assertParamExists('createDocument', 'createDocumentDTO', createDocumentDTO)
            const localVarPath = `/documents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createDocumentDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a form POST upload link for uploading the tenant image. Refer to https://docs.aws.amazon.com/AmazonS3/latest/API/sigv4-post-example.html on how to implement the form POST.
         * @summary create a presigned upload link
         * @param {CreateDocumentUploadLinkDTO} createDocumentUploadLinkDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUploadLink: async (createDocumentUploadLinkDTO: CreateDocumentUploadLinkDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createDocumentUploadLinkDTO' is not null or undefined
            assertParamExists('createUploadLink', 'createDocumentUploadLinkDTO', createDocumentUploadLinkDTO)
            const localVarPath = `/documents/createUploadLink`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createDocumentUploadLinkDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary delete document
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDocument: async (documentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('deleteDocument', 'documentId', documentId)
            const localVarPath = `/documents/{documentId}`
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get a document by id
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocument: async (documentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('getDocument', 'documentId', documentId)
            const localVarPath = `/documents/{documentId}`
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary list documents
         * @param {'BAV' | 'LUNCH' | 'COUPONS' | 'COUPONS_V2' | 'MOBILITY' | 'GIFTS' | 'INTERNET' | 'BIKE' | 'NEWS' | 'FLEX' | 'RECREATION' | 'BIKELEASING' | 'CUSTOM'} [benefit] 
         * @param {string} [customBenefitTemplateKey] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDocuments: async (benefit?: 'BAV' | 'LUNCH' | 'COUPONS' | 'COUPONS_V2' | 'MOBILITY' | 'GIFTS' | 'INTERNET' | 'BIKE' | 'NEWS' | 'FLEX' | 'RECREATION' | 'BIKELEASING' | 'CUSTOM', customBenefitTemplateKey?: string, page?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/documents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (benefit !== undefined) {
                localVarQueryParameter['benefit'] = benefit;
            }

            if (customBenefitTemplateKey !== undefined) {
                localVarQueryParameter['customBenefitTemplateKey'] = customBenefitTemplateKey;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DocumentsApi - functional programming interface
 * @export
 */
export const DocumentsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DocumentsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary upload a new document
         * @param {CreateDocumentDTO} createDocumentDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDocument(createDocumentDTO: CreateDocumentDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdentifierDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDocument(createDocumentDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create a form POST upload link for uploading the tenant image. Refer to https://docs.aws.amazon.com/AmazonS3/latest/API/sigv4-post-example.html on how to implement the form POST.
         * @summary create a presigned upload link
         * @param {CreateDocumentUploadLinkDTO} createDocumentUploadLinkDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUploadLink(createDocumentUploadLinkDTO: CreateDocumentUploadLinkDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadLinkDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUploadLink(createDocumentUploadLinkDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary delete document
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDocument(documentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDocument(documentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get a document by id
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDocument(documentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDocument(documentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary list documents
         * @param {'BAV' | 'LUNCH' | 'COUPONS' | 'COUPONS_V2' | 'MOBILITY' | 'GIFTS' | 'INTERNET' | 'BIKE' | 'NEWS' | 'FLEX' | 'RECREATION' | 'BIKELEASING' | 'CUSTOM'} [benefit] 
         * @param {string} [customBenefitTemplateKey] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listDocuments(benefit?: 'BAV' | 'LUNCH' | 'COUPONS' | 'COUPONS_V2' | 'MOBILITY' | 'GIFTS' | 'INTERNET' | 'BIKE' | 'NEWS' | 'FLEX' | 'RECREATION' | 'BIKELEASING' | 'CUSTOM', customBenefitTemplateKey?: string, page?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentsDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listDocuments(benefit, customBenefitTemplateKey, page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DocumentsApi - factory interface
 * @export
 */
export const DocumentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DocumentsApiFp(configuration)
    return {
        /**
         * 
         * @summary upload a new document
         * @param {CreateDocumentDTO} createDocumentDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDocument(createDocumentDTO: CreateDocumentDTO, options?: any): AxiosPromise<IdentifierDTO> {
            return localVarFp.createDocument(createDocumentDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a form POST upload link for uploading the tenant image. Refer to https://docs.aws.amazon.com/AmazonS3/latest/API/sigv4-post-example.html on how to implement the form POST.
         * @summary create a presigned upload link
         * @param {CreateDocumentUploadLinkDTO} createDocumentUploadLinkDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUploadLink(createDocumentUploadLinkDTO: CreateDocumentUploadLinkDTO, options?: any): AxiosPromise<UploadLinkDTO> {
            return localVarFp.createUploadLink(createDocumentUploadLinkDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary delete document
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDocument(documentId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteDocument(documentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get a document by id
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocument(documentId: string, options?: any): AxiosPromise<DocumentDTO> {
            return localVarFp.getDocument(documentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary list documents
         * @param {'BAV' | 'LUNCH' | 'COUPONS' | 'COUPONS_V2' | 'MOBILITY' | 'GIFTS' | 'INTERNET' | 'BIKE' | 'NEWS' | 'FLEX' | 'RECREATION' | 'BIKELEASING' | 'CUSTOM'} [benefit] 
         * @param {string} [customBenefitTemplateKey] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDocuments(benefit?: 'BAV' | 'LUNCH' | 'COUPONS' | 'COUPONS_V2' | 'MOBILITY' | 'GIFTS' | 'INTERNET' | 'BIKE' | 'NEWS' | 'FLEX' | 'RECREATION' | 'BIKELEASING' | 'CUSTOM', customBenefitTemplateKey?: string, page?: number, pageSize?: number, options?: any): AxiosPromise<DocumentsDTO> {
            return localVarFp.listDocuments(benefit, customBenefitTemplateKey, page, pageSize, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createDocument operation in DocumentsApi.
 * @export
 * @interface DocumentsApiCreateDocumentRequest
 */
export interface DocumentsApiCreateDocumentRequest {
    /**
     * 
     * @type {CreateDocumentDTO}
     * @memberof DocumentsApiCreateDocument
     */
    readonly createDocumentDTO: CreateDocumentDTO
}

/**
 * Request parameters for createUploadLink operation in DocumentsApi.
 * @export
 * @interface DocumentsApiCreateUploadLinkRequest
 */
export interface DocumentsApiCreateUploadLinkRequest {
    /**
     * 
     * @type {CreateDocumentUploadLinkDTO}
     * @memberof DocumentsApiCreateUploadLink
     */
    readonly createDocumentUploadLinkDTO: CreateDocumentUploadLinkDTO
}

/**
 * Request parameters for deleteDocument operation in DocumentsApi.
 * @export
 * @interface DocumentsApiDeleteDocumentRequest
 */
export interface DocumentsApiDeleteDocumentRequest {
    /**
     * 
     * @type {string}
     * @memberof DocumentsApiDeleteDocument
     */
    readonly documentId: string
}

/**
 * Request parameters for getDocument operation in DocumentsApi.
 * @export
 * @interface DocumentsApiGetDocumentRequest
 */
export interface DocumentsApiGetDocumentRequest {
    /**
     * 
     * @type {string}
     * @memberof DocumentsApiGetDocument
     */
    readonly documentId: string
}

/**
 * Request parameters for listDocuments operation in DocumentsApi.
 * @export
 * @interface DocumentsApiListDocumentsRequest
 */
export interface DocumentsApiListDocumentsRequest {
    /**
     * 
     * @type {'BAV' | 'LUNCH' | 'COUPONS' | 'COUPONS_V2' | 'MOBILITY' | 'GIFTS' | 'INTERNET' | 'BIKE' | 'NEWS' | 'FLEX' | 'RECREATION' | 'BIKELEASING' | 'CUSTOM'}
     * @memberof DocumentsApiListDocuments
     */
    readonly benefit?: 'BAV' | 'LUNCH' | 'COUPONS' | 'COUPONS_V2' | 'MOBILITY' | 'GIFTS' | 'INTERNET' | 'BIKE' | 'NEWS' | 'FLEX' | 'RECREATION' | 'BIKELEASING' | 'CUSTOM'

    /**
     * 
     * @type {string}
     * @memberof DocumentsApiListDocuments
     */
    readonly customBenefitTemplateKey?: string

    /**
     * 
     * @type {number}
     * @memberof DocumentsApiListDocuments
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof DocumentsApiListDocuments
     */
    readonly pageSize?: number
}

/**
 * DocumentsApi - object-oriented interface
 * @export
 * @class DocumentsApi
 * @extends {BaseAPI}
 */
export class DocumentsApi extends BaseAPI {
    /**
     * 
     * @summary upload a new document
     * @param {DocumentsApiCreateDocumentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentsApi
     */
    public createDocument(requestParameters: DocumentsApiCreateDocumentRequest, options?: AxiosRequestConfig) {
        return DocumentsApiFp(this.configuration).createDocument(requestParameters.createDocumentDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a form POST upload link for uploading the tenant image. Refer to https://docs.aws.amazon.com/AmazonS3/latest/API/sigv4-post-example.html on how to implement the form POST.
     * @summary create a presigned upload link
     * @param {DocumentsApiCreateUploadLinkRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentsApi
     */
    public createUploadLink(requestParameters: DocumentsApiCreateUploadLinkRequest, options?: AxiosRequestConfig) {
        return DocumentsApiFp(this.configuration).createUploadLink(requestParameters.createDocumentUploadLinkDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary delete document
     * @param {DocumentsApiDeleteDocumentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentsApi
     */
    public deleteDocument(requestParameters: DocumentsApiDeleteDocumentRequest, options?: AxiosRequestConfig) {
        return DocumentsApiFp(this.configuration).deleteDocument(requestParameters.documentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get a document by id
     * @param {DocumentsApiGetDocumentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentsApi
     */
    public getDocument(requestParameters: DocumentsApiGetDocumentRequest, options?: AxiosRequestConfig) {
        return DocumentsApiFp(this.configuration).getDocument(requestParameters.documentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary list documents
     * @param {DocumentsApiListDocumentsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentsApi
     */
    public listDocuments(requestParameters: DocumentsApiListDocumentsRequest = {}, options?: AxiosRequestConfig) {
        return DocumentsApiFp(this.configuration).listDocuments(requestParameters.benefit, requestParameters.customBenefitTemplateKey, requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }
}
