import React from 'react';
import { AppToolbar } from '../layout/AppToolbar';
import { ImageZoomer } from '../imageZoomer/ImageZoomer';
import { Dialog, useTheme } from '@mui/material';
import { CloseIconButton } from '../button/CloseIconButton';
import { MobileLayout } from '../layout';

interface Props {
  imageURL: string;
  isOpen: boolean;
  onClose: () => void;
}

const FullScreenPicture: React.FC<Props> = ({ isOpen, imageURL, onClose }) => {
  const theme = useTheme();

  return (
    <Dialog fullScreen open={isOpen}>
      <MobileLayout
        header={<AppToolbar invert absolute color="inherit" actionButton={<CloseIconButton color="inherit" onClick={onClose} />} />}
      >
        <ImageZoomer centered wrapperStyle={{ backgroundColor: theme.palette.background.dark }} imageUrl={imageURL} />
      </MobileLayout>
    </Dialog>
  );
};

export default FullScreenPicture;
