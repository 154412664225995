import { useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { BackIconButton } from '../../component/button/BackIconButton';
import { MobileLayout } from '../../component/layout';
import { AppToolbar } from '../../component/layout/AppToolbar';
import { useBackNavigator } from '../../module/navigation/BackNavigator';

const BikeleasingLearnMorePage: React.FC = () => {
  const { t } = useTranslation('benefitModule');
  const theme = useTheme();
  const backNavigator = useBackNavigator();

  return (
    <>
      <MobileLayout
        iosOverloadScrollBackground={theme.palette.background.paper}
        header={
          <AppToolbar
            title={t('benefit.BIKELEASING.name')}
            color={theme.palette.background.default}
            backButton={<BackIconButton onClick={backNavigator.back} />}
          />
        }
      >
        {/* <BikeStepper /> */}
      </MobileLayout>
    </>
  );
};

export default BikeleasingLearnMorePage;
