import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { ConfirmResult, Dialog as CDialog } from '@capacitor/dialog';
import { useCallback, useEffect, useState } from 'react';
import { Capacitor } from '@capacitor/core';
import { useTranslation } from 'react-i18next';

interface Props {
  isOpen: boolean;
  title?: string;
  message: string;
  okButtonTitle?: string;
  cancelButtonTitle?: string;
  onSubmit: () => void;

  /**
   * If undefined, Close button will not be displayed.
   */
  onCancel?: () => void;
}

export const ConfirmationModal: React.FC<Props> = ({ title, message, okButtonTitle, cancelButtonTitle, onSubmit, onCancel, isOpen }) => {
  const [isNativeOpen, setIsNativeOpen] = useState(isOpen && Capacitor.isNativePlatform());
  const isWebOpen = isOpen && !Capacitor.isNativePlatform();
  const { t } = useTranslation();

  let actualOkButtonTitle = okButtonTitle;
  if (!actualOkButtonTitle) {
    actualOkButtonTitle = onCancel ? t('common:button.confirm') : t('common:button.ok');
  }

  useEffect(() => {
    setIsNativeOpen(isOpen && Capacitor.isNativePlatform());
  }, [isOpen]);

  useEffect(() => {
    if (!isNativeOpen) {
      return;
    }

    // open in mobile
    async function openNativeDialog(): Promise<void> {
      let res: ConfirmResult | undefined;

      if (onCancel) {
        res = await CDialog.confirm({
          title,
          message,
          okButtonTitle: actualOkButtonTitle,
          cancelButtonTitle: cancelButtonTitle || t('common:button.abort'),
        });
      } else {
        await CDialog.alert({
          title,
          message,
          buttonTitle: actualOkButtonTitle,
        });
      }
      setIsNativeOpen(false);
      if ((res && res.value === true) || onCancel === undefined) {
        return onSubmit();
      }
      return onCancel();
    }

    void openNativeDialog();
  }, [cancelButtonTitle, onCancel, onSubmit, isNativeOpen, message, actualOkButtonTitle, title, t]);

  const handleSubmit = useCallback(() => {
    onSubmit();
  }, [onSubmit]);

  const handleCancel = useCallback(() => {
    if (onCancel) {
      onCancel();
    }
  }, [onCancel]);

  return (
    <Dialog
      open={isWebOpen}
      onClose={handleCancel}
      data-test-id="confirmation-modal"
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
    >
      {!!title && <DialogTitle id="dialog-title">{title}</DialogTitle>}
      <DialogContent id="dialog-description">{message}</DialogContent>
      <DialogActions>
        {onCancel ? (
          <Button data-test-id="confirmation-modal-cancel" onClick={handleCancel}>
            {cancelButtonTitle || t('common:button.abort')}
          </Button>
        ) : (
          <></>
        )}
        <Button data-test-id="confirm-action-modal" onClick={handleSubmit}>
          {actualOkButtonTitle}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
