import { useLoginContext } from '@tiffinger-thiel/appauth-react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import MessageLayout from '../component/layout/MessageLayout';

const RegisterPage: React.FC = () => {
  const { t } = useTranslation('registerPage');
  const { login, isReady } = useLoginContext();
  const { hash } = useLocation();
  const navigate = useNavigate();
  const idToken = new URLSearchParams(hash.substring(1)).get('token');

  useEffect(() => {
    if (!isReady) {
      return;
    }
    if (idToken) {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      void login({ extras: { id_token_hint: idToken } });
    } else {
      navigate('/', { replace: true });
    }
  }, [login, navigate, isReady, idToken]);

  return <MessageLayout title={t('redirecting')} text={t('pleaseWait')} />;
};

export default RegisterPage;
