import { ThemeProvider, createTheme, useMediaQuery } from '@mui/material';
import { createContext, FC, useCallback, useContext, useMemo, useState } from 'react';
import { MATERIAL_THEME_DARK, MATERIAL_THEME_DARK_CUSTOM, MATERIAL_THEME_LIGHT, MATERIAL_THEME_LIGHT_CUSTOM } from '../../theme';
import { useTenant } from 'probonio-shared-ui/module/me';
import { useSearchParams } from 'react-router-dom';
import { deepmerge } from '@mui/utils';

export type ThemeVariant = 'light' | 'dark' | 'system';

interface ThemeSwitcherState {
  switchTheme: (newTheme?: ThemeVariant) => void;
  selectedVariant: ThemeVariant;
}

const ThemeSwitcherContext = createContext<ThemeSwitcherState>({
  switchTheme: () => undefined,
  selectedVariant: 'light',
});

const SELECTED_THEME_KEY = 'themeSelectedByUser';

export const useThemeSwitcher = (): ThemeSwitcherState => useContext<ThemeSwitcherState>(ThemeSwitcherContext);

export interface Props {
  children: React.ReactNode;
}

interface CorporateTheme {
  backgroundColor: string;
  primaryColor: string;
  secondaryColor: string;
  backgroundColorDark: string;
  primaryColorDark: string;
  secondaryColorDark: string;
}

export const ThemeSwitcherProvider: FC<Props> = ({ children }) => {
  const prefersLightMode = useMediaQuery('(prefers-color-scheme: light)');
  const { tenant } = useTenant();

  const [searchParams] = useSearchParams();

  const [selectedVariant, setSelectedVariant] = useState<ThemeVariant>(
    (localStorage.getItem(SELECTED_THEME_KEY) as ThemeVariant) || 'system',
  );

  const [themeFromParam] = useState<{
    primary: string | null;
    secondary: string | null;
    background: string | null;
    primaryDark: string | null;
    secondaryDark: string | null;
    backgroundDark: string | null;
  }>({
    primary: searchParams.get('primary'),
    secondary: searchParams.get('secondary'),
    background: searchParams.get('background'),
    primaryDark: searchParams.get('primaryDark'),
    secondaryDark: searchParams.get('secondaryDark'),
    backgroundDark: searchParams.get('backgroundDark'),
  });

  const { lightTheme, darkTheme } = useMemo(() => {
    const localCorporateThemeJson = localStorage.getItem('tenantCustomColors');
    let corporateTheme = localCorporateThemeJson ? (JSON.parse(localCorporateThemeJson) as CorporateTheme) : undefined;
    const hasCorporateColors = tenant?.additionalFeatures?.includes('CORPORATE_COLORS');

    if (
      hasCorporateColors &&
      tenant?.backgroundColor &&
      tenant?.primaryColor &&
      tenant?.secondaryColor &&
      tenant?.backgroundColorDark &&
      tenant?.primaryColorDark &&
      tenant?.secondaryColorDark
    ) {
      corporateTheme = {
        backgroundColor: tenant.backgroundColor,
        primaryColor: tenant.primaryColor,
        secondaryColor: tenant.secondaryColor,
        backgroundColorDark: tenant.backgroundColorDark,
        primaryColorDark: tenant.primaryColorDark,
        secondaryColorDark: tenant.secondaryColorDark,
      };
      localStorage.setItem('tenantCustomColors', JSON.stringify(corporateTheme));
    } else if (
      tenant &&
      (!hasCorporateColors ||
        !tenant.backgroundColor ||
        !tenant.primaryColor ||
        !tenant.secondaryColor ||
        !tenant.backgroundColorDark ||
        !tenant.primaryColorDark ||
        !tenant.secondaryColorDark)
    ) {
      localStorage.removeItem('tenantCustomColors');
      corporateTheme = undefined;
    }

    let isThemeCustomized = false;
    let lightTheme = MATERIAL_THEME_LIGHT_CUSTOM;
    let darkTheme = MATERIAL_THEME_DARK_CUSTOM;
    if (corporateTheme) {
      lightTheme = deepmerge(lightTheme, {
        palette: {
          background: { default: corporateTheme.backgroundColor },
          primary: { light: corporateTheme.primaryColor, main: corporateTheme.secondaryColor },
          secondary: { main: corporateTheme.primaryColor },
        },
      });
      darkTheme = deepmerge(darkTheme, {
        palette: {
          background: { default: corporateTheme.backgroundColorDark },
          primary: { light: corporateTheme.primaryColorDark, main: corporateTheme.secondaryColorDark },
        },
      });
      isThemeCustomized = true;
    }

    if (themeFromParam.primary && themeFromParam.secondary && themeFromParam.background) {
      lightTheme = deepmerge(lightTheme, {
        palette: {
          background: { default: `#${themeFromParam.background}` },
          primary: { light: `#${themeFromParam.primary}`, main: `#${themeFromParam.secondary}` },
          secondary: { main: `#${themeFromParam.primary}` },
        },
      });
      isThemeCustomized = true;
    }

    if (themeFromParam.primaryDark && themeFromParam.secondaryDark && themeFromParam.backgroundDark) {
      darkTheme = deepmerge(darkTheme, {
        palette: {
          background: { default: `#${themeFromParam.backgroundDark}` },
          primary: { light: `#${themeFromParam.primaryDark}`, main: `#${themeFromParam.secondaryDark}` },
        },
      });
      isThemeCustomized = true;
    }

    if (isThemeCustomized) {
      return { lightTheme: createTheme(lightTheme), darkTheme: createTheme(darkTheme) };
    } else {
      return { lightTheme: MATERIAL_THEME_LIGHT, darkTheme: MATERIAL_THEME_DARK };
    }
  }, [
    tenant,
    themeFromParam.primary,
    themeFromParam.primaryDark,
    themeFromParam.secondary,
    themeFromParam.secondaryDark,
    themeFromParam.background,
    themeFromParam.backgroundDark,
  ]);

  const handleThemeSwitch = useCallback((newTheme?: ThemeVariant) => {
    if (newTheme) {
      setSelectedVariant(newTheme);
      localStorage.setItem(SELECTED_THEME_KEY, newTheme);
      return;
    }
    setSelectedVariant(current => {
      return current === 'light' ? 'dark' : 'light';
    });
  }, []);

  const [colorModeFromParam] = useState<string | null>(searchParams.get('colorMode'));
  const actualTheme = useMemo(() => {
    let variant = colorModeFromParam;
    if (!variant) {
      if (selectedVariant === 'system') {
        variant = prefersLightMode ? 'light' : 'dark';
      } else {
        variant = selectedVariant;
      }
    }
    return variant === 'light' ? lightTheme : darkTheme;
  }, [colorModeFromParam, darkTheme, lightTheme, prefersLightMode, selectedVariant]);

  return (
    <ThemeSwitcherContext.Provider
      value={useMemo(
        () => ({
          switchTheme: handleThemeSwitch,
          selectedVariant,
        }),
        [handleThemeSwitch, selectedVariant],
      )}
    >
      <ThemeProvider theme={actualTheme}>{children}</ThemeProvider>
    </ThemeSwitcherContext.Provider>
  );
};
