import { LinkIconButton } from './LinkIconButton';
import EditIcon from '@mui/icons-material/Edit';

export const EditIconButton: React.FC<React.ComponentProps<typeof LinkIconButton>> = props => {
  return (
    <LinkIconButton {...props}>
      <EditIcon />
    </LinkIconButton>
  );
};
