import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, useTheme } from '@mui/material';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import i18n, { LANGUAGE_NAMES, LocaleEnum } from '../../lang';

interface SelectLocaleProps {
  variant: 'standard' | 'outlined' | 'filled' | undefined;
  size: 'small' | 'medium' | undefined;
  onLanguageChange: (event: SelectChangeEvent) => void;
}

export const SelectLocale: React.FC<SelectLocaleProps> = ({ variant, size, onLanguageChange }) => {
  const { t } = useTranslation('profilePage');
  const theme = useTheme();

  const changeLanguage = useCallback(
    (event: SelectChangeEvent) => {
      onLanguageChange(event);
    },
    [onLanguageChange],
  );

  return (
    <FormControl fullWidth variant={variant} size={size}>
      <InputLabel id="select-locale-label" sx={{ color: 'text.primary' }}>
        {t('locale')}
      </InputLabel>
      <Select
        labelId="select-locale-label"
        id="select-locale"
        disableUnderline
        value={i18n.resolvedLanguage}
        label={t('locale')}
        onChange={changeLanguage}
        sx={{ backgroundColor: theme.palette.primary.contrastText, borderRadius: 1 }}
      >
        {(Object.values(LocaleEnum) as Array<LocaleEnum>).map(value => (
          <MenuItem key={value} value={value}>
            <img height={15} src={`../image/locale_${value}.svg`} alt={`Locale_${value}`} style={{ paddingRight: '0.5em' }} />
            {LANGUAGE_NAMES[value]}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
