import { Capacitor } from '@capacitor/core';
import { Box, Typography, useTheme } from '@mui/material';
import { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { BasicAlert } from '../../../component/alert';
import { downloadFileNative } from '../../../utils/fileConversion/nativeFileDownload';
import { useBikeleasingDetails } from './useBikeleasingDetails';

interface MessageData {
  blob: Blob;
  filename: string;
}

export const BikeleasingCalculator: React.FC = () => {
  const theme = useTheme();
  const { t } = useTranslation('benefitModule');
  const backgroundColor = theme.palette.mode === 'dark' ? theme.palette.bikeleasing.secondary : theme.palette.background.paper;

  const bikeleasingBenefitDetails = useBikeleasingDetails();

  const isAndroid = Capacitor.getPlatform() === 'android';

  useEffect(() => {
    if (Capacitor.isNativePlatform()) {
      const handleMessage = (e: MessageEvent) => {
        const messageData: MessageData = e.data;
        void downloadFileNative(messageData.filename, messageData.blob, 'application/pdf');
      };
      window.addEventListener('message', handleMessage, false);

      return () => {
        window.removeEventListener('message', handleMessage);
      };
    }
  }, [bikeleasingBenefitDetails]);

  return (
    <Box sx={{ backgroundColor }} height="100%" display="flex" flexDirection="column">
      {isAndroid && (
        <BasicAlert sx={{ margin: 0.5, marginTop: 1 }} icon={false} severity="info">
          <Typography variant="body2" paddingX={1}>
            {t('benefit.BIKELEASING.calculatorAndroidInfo')}
          </Typography>
          <Typography variant="body2" paddingX={1}>
            <Trans i18nKey={'benefitModule:benefit.BIKELEASING.calculatorAndroidInfoLink'}>
              <a
                target="_blank"
                href={`${import.meta.env.VITE_BIKELEASING_CALCULATOR_URL}&code=${bikeleasingBenefitDetails?.bikeleasingCondition.companyCode}`}
                rel="noreferrer"
              ></a>
            </Trans>
          </Typography>
        </BasicAlert>
      )}
      <iframe
        src={`${import.meta.env.VITE_BIKELEASING_CALCULATOR_URL}&code=${bikeleasingBenefitDetails?.bikeleasingCondition.companyCode}`}
        seamless
        style={{
          textAlign: 'center',
          outline: 'none',
          border: 'none',
          width: '100%',
          height: '100%',
        }}
      />
    </Box>
  );
};
