import { Stack, Typography } from '@mui/material';
import React from 'react';

interface Props {
  label: React.ReactNode;
  value: React.ReactNode;
}

export const InfoItem: React.FC<Props> = ({ label, value }) => {
  return (
    <Stack direction="row" spacing={2} marginTop={1}>
      <Typography sx={{ flexGrow: 1 }} variant="caption" color="text.secondary">
        {label}
      </Typography>
      <Typography noWrap variant="caption" className="selectable" overflow="unset">
        {value}
      </Typography>
    </Stack>
  );
};
