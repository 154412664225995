import { Box, Typography, useTheme } from '@mui/material';
import { BikeContractDTO } from 'probonio-shared-ui/api';
import React from 'react';

interface Props {
  contract: BikeContractDTO;
}

export const BikeContractHeader: React.FC<Props> = ({ contract }) => {
  const theme = useTheme();

  const headerText = [contract.bikeProduct, contract.bikeCategory].filter(text => text).join('-');

  return (
    <Box sx={{ textAlign: 'center', paddingBottom: 0.5, paddingTop: 0.2, backgroundColor: theme.palette.primary.contrastText }}>
      <Typography variant="h2">{headerText}</Typography>
    </Box>
  );
};
