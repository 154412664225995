import {
  InfiniteData,
  UseInfiniteQueryResult,
  UseQueryResult,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { useCallback, useMemo } from 'react';
import {
  BenefitDTOBenefitEnum,
  CouponDefinitionDTO,
  CouponDefinitionListDTO,
  CouponsV2ApiFindUserCouponDefinitionsRequest,
  CouponsV2BenefitDetailsDTO,
  CouponsV2UserOrderDTO,
  CouponsV2UserOrderListDTO,
  CouponsV2UserTokenDTO,
  CouponsV2UserTokenListDTO,
} from '../../../../probonio-shared-ui/api';
import { apis, useEmployeeQuery } from '../../../../probonio-shared-ui/module/api';
import { useActiveEmployment } from '../../../../probonio-shared-ui/module/me';
import { useAppSelector } from '../../redux/hooks';
import { LoadingFunction, useLoadingCallback } from '../../utils/loadingFunction';
import { UpdateCouponOrderRedeem } from '../couponsShared/redeem/CouponRedeemButton';

export function useCouponsV2BenefitDetails(): CouponsV2BenefitDetailsDTO | undefined {
  const { data: benefitDetails } = useEmployeeQuery(['benefits', 'coupons_v2', apis.benefits.getBenefit.name], params =>
    apis.benefits.getBenefit({ ...params, benefit: BenefitDTOBenefitEnum.CouponsV2 }).then(res => res.data),
  );

  return benefitDetails?.details as CouponsV2BenefitDetailsDTO | undefined;
}

export function useInvalidateCouponsV2BenefitDetails(): () => void {
  const queryClient = useQueryClient();
  const getActiveEmployment = useActiveEmployment();

  return useCallback(() => {
    void queryClient.invalidateQueries({
      queryKey: ['tenants', getActiveEmployment().tenantId, 'employees', getActiveEmployment().id, 'benefits', 'coupons_v2'],
    });
  }, [getActiveEmployment, queryClient]);
}

export function useCouponsV2UserOrderQuery(userOrderId: string): UseQueryResult<CouponsV2UserOrderDTO, Error> {
  const userId = useAppSelector(state => state.me.user?.id);

  return useQuery({
    queryKey: ['users', userId, 'benefits', 'coupons_v2', 'userOrders', userOrderId, apis.couponsV2.getUserOrder.name],
    queryFn: () => apis.couponsV2.getUserOrder({ userId: userId!, userOrderId: userOrderId! }).then(res => res.data),
    enabled: !!userId,
  });
}

export function useCouponsV2UserOrderListQuery(): UseQueryResult<CouponsV2UserOrderListDTO, Error> {
  const userId = useAppSelector(state => state.me.user?.id);

  return useQuery({
    queryKey: ['users', userId, 'benefits', 'coupons_v2', 'userOrders', apis.couponsV2.listUserOrders.name],
    queryFn: () => apis.couponsV2.listUserOrders({ userId: userId! }).then(res => res.data),
    enabled: !!userId,
    staleTime: 60000,
  });
}

export function useInvalidateCouponsV2UserOrders(): () => Promise<void> {
  const queryClient = useQueryClient();
  const userId = useAppSelector(state => state.me.user?.id);

  return useCallback(() => {
    return queryClient.invalidateQueries({ queryKey: ['users', userId, 'benefits', 'coupons_v2', 'userOrders'] });
  }, [userId, queryClient]);
}

export function useRedeemCouponV2Mutation(
  couponOrder: CouponsV2UserOrderDTO,
): LoadingFunction<(redeemConditions: UpdateCouponOrderRedeem) => void> {
  const userId = useAppSelector(state => state.me.user?.id);
  const invalidateUserOrders = useInvalidateCouponsV2UserOrders();

  const mutation = useMutation({
    mutationFn: (redeemConditions: UpdateCouponOrderRedeem) =>
      apis.couponsV2.updateUserOrder({
        userId: userId!,
        userOrderId: couponOrder.id,
        updateCouponsV2UserOrderDTO: {
          redeemed: redeemConditions.shouldBeRedeemed && !couponOrder.redeemDate,
          //set remainingAmount to orderAmount if the coupon would be redeemed
          remainingAmount:
            redeemConditions.shouldBeRedeemed && !couponOrder.redeemDate ? couponOrder.amount : redeemConditions.newRemainingAmount,
        },
      }),

    onSuccess: async () => {
      await invalidateUserOrders();
    },
  });

  return useLoadingCallback(
    (redeemConditions: UpdateCouponOrderRedeem) => {
      mutation.mutate(redeemConditions);
    },
    [mutation],
    mutation.isPending,
  );
}

export const USER_TOKENS_PAGE_SIZE = 10;

export function useCouponsV2UserTokenListQuery(): UseInfiniteQueryResult<InfiniteData<CouponsV2UserTokenListDTO, Error>> {
  const userId = useAppSelector(state => state.me.user?.id);

  return useInfiniteQuery({
    queryKey: [
      'users',
      userId,
      'benefits',
      'coupons_v2',
      'userTokens',
      { pageSize: USER_TOKENS_PAGE_SIZE },
      apis.couponsV2.listUserTokens.name,
    ],
    queryFn: ({ pageParam = 0 }) =>
      apis.couponsV2.listUserTokens({ userId: userId!, page: pageParam, pageSize: USER_TOKENS_PAGE_SIZE }).then(res => res.data),

    enabled: !!userId,
    initialPageParam: 0,
    getNextPageParam: (lastPage, pages) => (pages.length * USER_TOKENS_PAGE_SIZE < lastPage.totalCount ? pages.length : undefined),
  });
}

export function useCouponsV2AvailableUserTokenListQuery(options?: { enabled?: boolean }): UseQueryResult<CouponsV2UserTokenListDTO, Error> {
  const userId = useAppSelector(state => state.me.user?.id);

  return useQuery({
    queryKey: ['users', userId, 'benefits', 'coupons_v2', 'userTokens', { isRedeemed: false }, apis.couponsV2.listUserTokens.name],
    queryFn: () => apis.couponsV2.listUserTokens({ userId: userId!, isRedeemed: false }).then(res => res.data),

    enabled: !!userId && options?.enabled !== false,
  });
}

export function useCouponsV2UserTokenCountQuery(): UseQueryResult<number, Error> {
  const userId = useAppSelector(state => state.me.user?.id);

  return useQuery({
    queryKey: ['users', userId, 'benefits', 'coupons_v2', 'userTokens', { pageSize: 0 }, apis.couponsV2.listUserTokens.name],
    queryFn: () => apis.couponsV2.listUserTokens({ userId: userId!, page: 0, pageSize: 0 }).then(res => res.data.totalCount),

    enabled: !!userId,
  });
}

export function useCouponsV2UserTokenQuery(userTokenId: string): UseQueryResult<CouponsV2UserTokenDTO, Error> {
  const userId = useAppSelector(state => state.me.user?.id);

  return useQuery({
    queryKey: ['users', userId, 'benefits', 'coupons_v2', 'userTokens', userTokenId, apis.couponsV2.getUserToken.name],
    queryFn: () => apis.couponsV2.getUserToken({ userId: userId!, userTokenId }).then(res => res.data),
    enabled: !!userId,
  });
}

export function useAvailableVariations(selectedAmount?: number): number[] {
  const couponsV2Details = useCouponsV2BenefitDetails();

  return useMemo(() => {
    const availableTokenAmounts = [...new Set(couponsV2Details?.availableTokens.map(token => token.amount))];
    if (selectedAmount && availableTokenAmounts.includes(selectedAmount)) {
      return [selectedAmount];
    }
    return availableTokenAmounts;
  }, [couponsV2Details?.availableTokens, selectedAmount]);
}

export function useCouponsV2DefinitionQuery(couponDefinitionId?: string): UseQueryResult<CouponDefinitionDTO, Error> {
  const userId = useAppSelector(state => state.me.user?.id);

  return useQuery({
    queryKey: ['users', userId, 'benefits', 'coupons_v2', 'definitions', couponDefinitionId, apis.couponsV2.getUserCouponDefinition.name],
    queryFn: () => {
      if (!couponDefinitionId) {
        throw new Error('No coupon id');
      }
      return apis.couponsV2.getUserCouponDefinition({ userId: userId!, couponDefinitionId }).then(res => res.data);
    },
    enabled: !!userId,
  });
}

export function useCouponsV2DefinitionListQuery(
  params: Omit<CouponsV2ApiFindUserCouponDefinitionsRequest, 'userId'>,
): UseQueryResult<CouponDefinitionListDTO, Error> {
  const userId = useAppSelector(state => state.me.user?.id);

  return useQuery({
    queryKey: ['users', userId, 'benefits', 'coupons_v2', 'definitions', params, apis.couponsV2.findUserCouponDefinitions.name],
    queryFn: () =>
      apis.couponsV2
        .findUserCouponDefinitions({
          userId: userId!,
          ...params,
        })
        .then(res => res.data),
    enabled: !!userId,
  });
}
