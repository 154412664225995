import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { LinkIconButton } from './LinkIconButton';
import React from 'react';

export const BackIconButton: React.FC<React.ComponentProps<typeof LinkIconButton>> = props => {
  return (
    <LinkIconButton {...props}>
      <ArrowBackIcon />
    </LinkIconButton>
  );
};
