import { Typography } from '@mui/material';
import React from 'react';
import { useNewDialogState } from '../../component/dialog/BasicDialog';
import { ReferralModal } from '../../component/referralModal/ReferralModal';
import HandshakeIcon from '@mui/icons-material/Handshake';
import NavigationButton from '../../component/button/NavigationButton';
import { useTranslation } from 'react-i18next';

export const ReferralDialogButton: React.FC = () => {
  const referralCodeDialog = useNewDialogState();
  const { t } = useTranslation('referralCodeModal');

  return (
    <>
      <NavigationButton iconLeft={<HandshakeIcon />} onClick={referralCodeDialog.handleOpen}>
        <Typography variant="caption" className="selectable" color="text.secondary">
          {t('buttonTitle')}
        </Typography>
      </NavigationButton>
      <ReferralModal dialogState={referralCodeDialog.dialogState} />
    </>
  );
};
