import DownloadIcon from '@mui/icons-material/Download';
import { Box, IconButton } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { BAVContractDTO, BAVDocumentDTO } from 'probonio-shared-ui/api';
import { apis, downloadUrl, useTenantQuery } from 'probonio-shared-ui/module/api';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { InfoAccordion } from '../../component/infoAccordion/InfoAccordion';
import { InfoRow } from '../../component/infoAccordion/InfoRow';

interface BAVContractDocumentDownloadProps {
  contract: BAVContractDTO;
  bavDocument: BAVDocumentDTO;
}

const BAVContractDocumentDownload: React.FC<BAVContractDocumentDownloadProps> = ({ contract, bavDocument }) => {
  const { t } = useTranslation('benefitModule');

  const mutation = useMutation({
    mutationFn: async () => {
      const { downloadURL, fileName } = await apis.bav
        .getContractDocument({
          tenantId: contract.tenantId,
          employeeId: contract.employeeId,
          contractId: contract.id,
          documentId: bavDocument.id,
        })
        .then(res => res.data);
      if (downloadURL) {
        downloadUrl(downloadURL, fileName, true);
      }
    },
  });

  const handleDownload = useCallback(() => {
    mutation.mutate();
  }, [mutation]);

  return (
    <>
      <InfoRow
        label={t(`benefit.BAV.documentFields.documentType.${bavDocument.documentType}`)}
        actions={
          <IconButton onClick={handleDownload} size="small" sx={{ padding: 0 }}>
            <DownloadIcon />
          </IconButton>
        }
      />
    </>
  );
};

interface Props {
  contract: BAVContractDTO;
}

export const BAVContractDocuments: React.FC<Props> = ({ contract }) => {
  const { t } = useTranslation('benefitModule');

  const { data: contractDocuments } = useTenantQuery(
    ['employees', contract.employeeId, 'benefits', 'bav', 'contracts', contract.id, 'documents', apis.bav.listContractDocuments.name],
    tenantId =>
      apis.bav.listContractDocuments({ tenantId, employeeId: contract.employeeId, contractId: contract.id }).then(res => res.data),
  );

  return (
    <>
      {contractDocuments && contractDocuments.totalCount !== 0 && (
        <Box pt={1}>
          <InfoAccordion defaultExpanded title={t(`benefit.BAV.documentFields.title`)}>
            {contractDocuments.results.map(doc => (
              <BAVContractDocumentDownload key={doc.id} contract={contract} bavDocument={doc} />
            ))}
          </InfoAccordion>
        </Box>
      )}
    </>
  );
};
