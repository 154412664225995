/* tslint:disable */
/* eslint-disable */
/**
 * Probonio full API Docs
 * If you stumbled upon this page and want to integrate Probonio into your business software [click here!](/api/external)    If you\'re looking for more information about Probonio [head over here](https://probonio.de)    **This documentation describes all Endpoints of the Probonio API. It\'s created for experienced Probonio Developers only!**  
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { BAVConsultantListDTO } from '../dto';
// @ts-ignore
import { BAVContractDTO } from '../dto';
// @ts-ignore
import { BAVContractListDTO } from '../dto';
// @ts-ignore
import { BAVContractWithTenantListDTO } from '../dto';
// @ts-ignore
import { BAVDocumentDTO } from '../dto';
// @ts-ignore
import { BAVDocumentListDTO } from '../dto';
// @ts-ignore
import { BAVTermsDTO } from '../dto';
// @ts-ignore
import { BAVTermsListDTO } from '../dto';
// @ts-ignore
import { CreateBAVChangeRequestDTO } from '../dto';
// @ts-ignore
import { CreateBAVConsultantDTO } from '../dto';
// @ts-ignore
import { CreateBAVContractDTO } from '../dto';
// @ts-ignore
import { CreateBAVDocumentDTO } from '../dto';
// @ts-ignore
import { CreateBAVEstimateRequestDTO } from '../dto';
// @ts-ignore
import { CreateBAVTermsDTO } from '../dto';
// @ts-ignore
import { IdentifierDTO } from '../dto';
// @ts-ignore
import { UpdateBAVConsultantDTO } from '../dto';
// @ts-ignore
import { UpdateBAVContractDTO } from '../dto';
// @ts-ignore
import { UploadLinkDTO } from '../dto';
/**
 * BAVApi - axios parameter creator
 * @export
 */
export const BAVApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary creates new external consultant for tenant
         * @param {string} tenantId 
         * @param {CreateBAVConsultantDTO} createBAVConsultantDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBAVConsultant: async (tenantId: string, createBAVConsultantDTO: CreateBAVConsultantDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('createBAVConsultant', 'tenantId', tenantId)
            // verify required parameter 'createBAVConsultantDTO' is not null or undefined
            assertParamExists('createBAVConsultant', 'createBAVConsultantDTO', createBAVConsultantDTO)
            const localVarPath = `/tenants/{tenantId}/benefits/bav/consultants`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createBAVConsultantDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary request a change to an existing BAV contract
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} contractId 
         * @param {CreateBAVChangeRequestDTO} createBAVChangeRequestDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createChangeRequest: async (tenantId: string, employeeId: string, contractId: string, createBAVChangeRequestDTO: CreateBAVChangeRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('createChangeRequest', 'tenantId', tenantId)
            // verify required parameter 'employeeId' is not null or undefined
            assertParamExists('createChangeRequest', 'employeeId', employeeId)
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('createChangeRequest', 'contractId', contractId)
            // verify required parameter 'createBAVChangeRequestDTO' is not null or undefined
            assertParamExists('createChangeRequest', 'createBAVChangeRequestDTO', createBAVChangeRequestDTO)
            const localVarPath = `/tenants/{tenantId}/employees/{employeeId}/benefits/bav/contracts/{contractId}/changeRequests`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"employeeId"}}`, encodeURIComponent(String(employeeId)))
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createBAVChangeRequestDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary create a new BAV contract
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {CreateBAVContractDTO} createBAVContractDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createContract: async (tenantId: string, employeeId: string, createBAVContractDTO: CreateBAVContractDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('createContract', 'tenantId', tenantId)
            // verify required parameter 'employeeId' is not null or undefined
            assertParamExists('createContract', 'employeeId', employeeId)
            // verify required parameter 'createBAVContractDTO' is not null or undefined
            assertParamExists('createContract', 'createBAVContractDTO', createBAVContractDTO)
            const localVarPath = `/tenants/{tenantId}/employees/{employeeId}/benefits/bav/contracts`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"employeeId"}}`, encodeURIComponent(String(employeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createBAVContractDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary upload a new document
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} contractId 
         * @param {CreateBAVDocumentDTO} createBAVDocumentDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createContractDocument: async (tenantId: string, employeeId: string, contractId: string, createBAVDocumentDTO: CreateBAVDocumentDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('createContractDocument', 'tenantId', tenantId)
            // verify required parameter 'employeeId' is not null or undefined
            assertParamExists('createContractDocument', 'employeeId', employeeId)
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('createContractDocument', 'contractId', contractId)
            // verify required parameter 'createBAVDocumentDTO' is not null or undefined
            assertParamExists('createContractDocument', 'createBAVDocumentDTO', createBAVDocumentDTO)
            const localVarPath = `/tenants/{tenantId}/employees/{employeeId}/benefits/bav/contracts/{contractId}/documents`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"employeeId"}}`, encodeURIComponent(String(employeeId)))
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createBAVDocumentDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary submit a new request for BAV estimate
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {CreateBAVEstimateRequestDTO} createBAVEstimateRequestDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEstimateRequest: async (tenantId: string, employeeId: string, createBAVEstimateRequestDTO: CreateBAVEstimateRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('createEstimateRequest', 'tenantId', tenantId)
            // verify required parameter 'employeeId' is not null or undefined
            assertParamExists('createEstimateRequest', 'employeeId', employeeId)
            // verify required parameter 'createBAVEstimateRequestDTO' is not null or undefined
            assertParamExists('createEstimateRequest', 'createBAVEstimateRequestDTO', createBAVEstimateRequestDTO)
            const localVarPath = `/tenants/{tenantId}/employees/{employeeId}/benefits/bav/estimateRequests`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"employeeId"}}`, encodeURIComponent(String(employeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createBAVEstimateRequestDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary create new BAV terms for a tenant
         * @param {string} tenantId 
         * @param {CreateBAVTermsDTO} createBAVTermsDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTerms: async (tenantId: string, createBAVTermsDTO: CreateBAVTermsDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('createTerms', 'tenantId', tenantId)
            // verify required parameter 'createBAVTermsDTO' is not null or undefined
            assertParamExists('createTerms', 'createBAVTermsDTO', createBAVTermsDTO)
            const localVarPath = `/tenants/{tenantId}/benefits/bav/terms`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createBAVTermsDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a form POST upload link for uploading a document. Refer to https://docs.aws.amazon.com/AmazonS3/latest/API/sigv4-post-example.html on how to implement the form POST.
         * @summary create a presigned upload link
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} contractId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUploadLink: async (tenantId: string, employeeId: string, contractId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('createUploadLink', 'tenantId', tenantId)
            // verify required parameter 'employeeId' is not null or undefined
            assertParamExists('createUploadLink', 'employeeId', employeeId)
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('createUploadLink', 'contractId', contractId)
            const localVarPath = `/tenants/{tenantId}/employees/{employeeId}/benefits/bav/contracts/{contractId}/documents/createUploadLink`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"employeeId"}}`, encodeURIComponent(String(employeeId)))
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary this will delete the consultant contact
         * @param {string} tenantId 
         * @param {string} bavConsultantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteConsultant: async (tenantId: string, bavConsultantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('deleteConsultant', 'tenantId', tenantId)
            // verify required parameter 'bavConsultantId' is not null or undefined
            assertParamExists('deleteConsultant', 'bavConsultantId', bavConsultantId)
            const localVarPath = `/tenants/{tenantId}/benefits/bav/consultants/{bavConsultantId}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"bavConsultantId"}}`, encodeURIComponent(String(bavConsultantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary delete BAV contract
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} contractId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteContract: async (tenantId: string, employeeId: string, contractId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('deleteContract', 'tenantId', tenantId)
            // verify required parameter 'employeeId' is not null or undefined
            assertParamExists('deleteContract', 'employeeId', employeeId)
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('deleteContract', 'contractId', contractId)
            const localVarPath = `/tenants/{tenantId}/employees/{employeeId}/benefits/bav/contracts/{contractId}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"employeeId"}}`, encodeURIComponent(String(employeeId)))
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary delete document
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} contractId 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteContractDocument: async (tenantId: string, employeeId: string, contractId: string, documentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('deleteContractDocument', 'tenantId', tenantId)
            // verify required parameter 'employeeId' is not null or undefined
            assertParamExists('deleteContractDocument', 'employeeId', employeeId)
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('deleteContractDocument', 'contractId', contractId)
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('deleteContractDocument', 'documentId', documentId)
            const localVarPath = `/tenants/{tenantId}/employees/{employeeId}/benefits/bav/contracts/{contractId}/documents/{documentId}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"employeeId"}}`, encodeURIComponent(String(employeeId)))
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)))
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get a BAV contract by id
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} contractId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContract: async (tenantId: string, employeeId: string, contractId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('getContract', 'tenantId', tenantId)
            // verify required parameter 'employeeId' is not null or undefined
            assertParamExists('getContract', 'employeeId', employeeId)
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('getContract', 'contractId', contractId)
            const localVarPath = `/tenants/{tenantId}/employees/{employeeId}/benefits/bav/contracts/{contractId}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"employeeId"}}`, encodeURIComponent(String(employeeId)))
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get a document by id
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} contractId 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContractDocument: async (tenantId: string, employeeId: string, contractId: string, documentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('getContractDocument', 'tenantId', tenantId)
            // verify required parameter 'employeeId' is not null or undefined
            assertParamExists('getContractDocument', 'employeeId', employeeId)
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('getContractDocument', 'contractId', contractId)
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('getContractDocument', 'documentId', documentId)
            const localVarPath = `/tenants/{tenantId}/employees/{employeeId}/benefits/bav/contracts/{contractId}/documents/{documentId}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"employeeId"}}`, encodeURIComponent(String(employeeId)))
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)))
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get latest published BAV terms for a tenant
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLatestTerms: async (tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('getLatestTerms', 'tenantId', tenantId)
            const localVarPath = `/tenants/{tenantId}/benefits/bav/terms/latest`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get list of external consultant for tenant
         * @param {string} tenantId 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListBAVConsultant: async (tenantId: string, page?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('getListBAVConsultant', 'tenantId', tenantId)
            const localVarPath = `/tenants/{tenantId}/benefits/bav/consultants`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary list BAV contracts for all tenants
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAllContracts: async (page?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/benefits/bav/contracts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary list documents
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} contractId 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listContractDocuments: async (tenantId: string, employeeId: string, contractId: string, page?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('listContractDocuments', 'tenantId', tenantId)
            // verify required parameter 'employeeId' is not null or undefined
            assertParamExists('listContractDocuments', 'employeeId', employeeId)
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('listContractDocuments', 'contractId', contractId)
            const localVarPath = `/tenants/{tenantId}/employees/{employeeId}/benefits/bav/contracts/{contractId}/documents`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"employeeId"}}`, encodeURIComponent(String(employeeId)))
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary list BAV contracts for employee
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listContracts: async (tenantId: string, employeeId: string, page?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('listContracts', 'tenantId', tenantId)
            // verify required parameter 'employeeId' is not null or undefined
            assertParamExists('listContracts', 'employeeId', employeeId)
            const localVarPath = `/tenants/{tenantId}/employees/{employeeId}/benefits/bav/contracts`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"employeeId"}}`, encodeURIComponent(String(employeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary list BAV contracts for tenant
         * @param {string} tenantId 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTenantContracts: async (tenantId: string, page?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('listTenantContracts', 'tenantId', tenantId)
            const localVarPath = `/tenants/{tenantId}/benefits/bav/contracts`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary list BAV terms for a tenant
         * @param {string} tenantId 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTerms: async (tenantId: string, page?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('listTerms', 'tenantId', tenantId)
            const localVarPath = `/tenants/{tenantId}/benefits/bav/terms`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update bav consultant
         * @param {string} tenantId 
         * @param {string} bavConsultantId 
         * @param {UpdateBAVConsultantDTO} updateBAVConsultantDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConsultant: async (tenantId: string, bavConsultantId: string, updateBAVConsultantDTO: UpdateBAVConsultantDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('updateConsultant', 'tenantId', tenantId)
            // verify required parameter 'bavConsultantId' is not null or undefined
            assertParamExists('updateConsultant', 'bavConsultantId', bavConsultantId)
            // verify required parameter 'updateBAVConsultantDTO' is not null or undefined
            assertParamExists('updateConsultant', 'updateBAVConsultantDTO', updateBAVConsultantDTO)
            const localVarPath = `/tenants/{tenantId}/benefits/bav/consultants/{bavConsultantId}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"bavConsultantId"}}`, encodeURIComponent(String(bavConsultantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateBAVConsultantDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update a BAV contract by id
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} contractId 
         * @param {UpdateBAVContractDTO} updateBAVContractDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateContract: async (tenantId: string, employeeId: string, contractId: string, updateBAVContractDTO: UpdateBAVContractDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('updateContract', 'tenantId', tenantId)
            // verify required parameter 'employeeId' is not null or undefined
            assertParamExists('updateContract', 'employeeId', employeeId)
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('updateContract', 'contractId', contractId)
            // verify required parameter 'updateBAVContractDTO' is not null or undefined
            assertParamExists('updateContract', 'updateBAVContractDTO', updateBAVContractDTO)
            const localVarPath = `/tenants/{tenantId}/employees/{employeeId}/benefits/bav/contracts/{contractId}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"employeeId"}}`, encodeURIComponent(String(employeeId)))
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateBAVContractDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BAVApi - functional programming interface
 * @export
 */
export const BAVApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BAVApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary creates new external consultant for tenant
         * @param {string} tenantId 
         * @param {CreateBAVConsultantDTO} createBAVConsultantDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createBAVConsultant(tenantId: string, createBAVConsultantDTO: CreateBAVConsultantDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createBAVConsultant(tenantId, createBAVConsultantDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary request a change to an existing BAV contract
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} contractId 
         * @param {CreateBAVChangeRequestDTO} createBAVChangeRequestDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createChangeRequest(tenantId: string, employeeId: string, contractId: string, createBAVChangeRequestDTO: CreateBAVChangeRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createChangeRequest(tenantId, employeeId, contractId, createBAVChangeRequestDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary create a new BAV contract
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {CreateBAVContractDTO} createBAVContractDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createContract(tenantId: string, employeeId: string, createBAVContractDTO: CreateBAVContractDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdentifierDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createContract(tenantId, employeeId, createBAVContractDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary upload a new document
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} contractId 
         * @param {CreateBAVDocumentDTO} createBAVDocumentDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createContractDocument(tenantId: string, employeeId: string, contractId: string, createBAVDocumentDTO: CreateBAVDocumentDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdentifierDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createContractDocument(tenantId, employeeId, contractId, createBAVDocumentDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary submit a new request for BAV estimate
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {CreateBAVEstimateRequestDTO} createBAVEstimateRequestDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createEstimateRequest(tenantId: string, employeeId: string, createBAVEstimateRequestDTO: CreateBAVEstimateRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createEstimateRequest(tenantId, employeeId, createBAVEstimateRequestDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary create new BAV terms for a tenant
         * @param {string} tenantId 
         * @param {CreateBAVTermsDTO} createBAVTermsDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTerms(tenantId: string, createBAVTermsDTO: CreateBAVTermsDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTerms(tenantId, createBAVTermsDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create a form POST upload link for uploading a document. Refer to https://docs.aws.amazon.com/AmazonS3/latest/API/sigv4-post-example.html on how to implement the form POST.
         * @summary create a presigned upload link
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} contractId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUploadLink(tenantId: string, employeeId: string, contractId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadLinkDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUploadLink(tenantId, employeeId, contractId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary this will delete the consultant contact
         * @param {string} tenantId 
         * @param {string} bavConsultantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteConsultant(tenantId: string, bavConsultantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteConsultant(tenantId, bavConsultantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary delete BAV contract
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} contractId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteContract(tenantId: string, employeeId: string, contractId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteContract(tenantId, employeeId, contractId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary delete document
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} contractId 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteContractDocument(tenantId: string, employeeId: string, contractId: string, documentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteContractDocument(tenantId, employeeId, contractId, documentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get a BAV contract by id
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} contractId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getContract(tenantId: string, employeeId: string, contractId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BAVContractDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getContract(tenantId, employeeId, contractId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get a document by id
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} contractId 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getContractDocument(tenantId: string, employeeId: string, contractId: string, documentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BAVDocumentDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getContractDocument(tenantId, employeeId, contractId, documentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get latest published BAV terms for a tenant
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLatestTerms(tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BAVTermsDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLatestTerms(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get list of external consultant for tenant
         * @param {string} tenantId 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getListBAVConsultant(tenantId: string, page?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BAVConsultantListDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getListBAVConsultant(tenantId, page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary list BAV contracts for all tenants
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAllContracts(page?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BAVContractWithTenantListDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAllContracts(page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary list documents
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} contractId 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listContractDocuments(tenantId: string, employeeId: string, contractId: string, page?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BAVDocumentListDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listContractDocuments(tenantId, employeeId, contractId, page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary list BAV contracts for employee
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listContracts(tenantId: string, employeeId: string, page?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BAVContractListDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listContracts(tenantId, employeeId, page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary list BAV contracts for tenant
         * @param {string} tenantId 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listTenantContracts(tenantId: string, page?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BAVContractListDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listTenantContracts(tenantId, page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary list BAV terms for a tenant
         * @param {string} tenantId 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listTerms(tenantId: string, page?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BAVTermsListDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listTerms(tenantId, page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary update bav consultant
         * @param {string} tenantId 
         * @param {string} bavConsultantId 
         * @param {UpdateBAVConsultantDTO} updateBAVConsultantDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateConsultant(tenantId: string, bavConsultantId: string, updateBAVConsultantDTO: UpdateBAVConsultantDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateConsultant(tenantId, bavConsultantId, updateBAVConsultantDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary update a BAV contract by id
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} contractId 
         * @param {UpdateBAVContractDTO} updateBAVContractDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateContract(tenantId: string, employeeId: string, contractId: string, updateBAVContractDTO: UpdateBAVContractDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateContract(tenantId, employeeId, contractId, updateBAVContractDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BAVApi - factory interface
 * @export
 */
export const BAVApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BAVApiFp(configuration)
    return {
        /**
         * 
         * @summary creates new external consultant for tenant
         * @param {string} tenantId 
         * @param {CreateBAVConsultantDTO} createBAVConsultantDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBAVConsultant(tenantId: string, createBAVConsultantDTO: CreateBAVConsultantDTO, options?: any): AxiosPromise<void> {
            return localVarFp.createBAVConsultant(tenantId, createBAVConsultantDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary request a change to an existing BAV contract
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} contractId 
         * @param {CreateBAVChangeRequestDTO} createBAVChangeRequestDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createChangeRequest(tenantId: string, employeeId: string, contractId: string, createBAVChangeRequestDTO: CreateBAVChangeRequestDTO, options?: any): AxiosPromise<void> {
            return localVarFp.createChangeRequest(tenantId, employeeId, contractId, createBAVChangeRequestDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary create a new BAV contract
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {CreateBAVContractDTO} createBAVContractDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createContract(tenantId: string, employeeId: string, createBAVContractDTO: CreateBAVContractDTO, options?: any): AxiosPromise<IdentifierDTO> {
            return localVarFp.createContract(tenantId, employeeId, createBAVContractDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary upload a new document
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} contractId 
         * @param {CreateBAVDocumentDTO} createBAVDocumentDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createContractDocument(tenantId: string, employeeId: string, contractId: string, createBAVDocumentDTO: CreateBAVDocumentDTO, options?: any): AxiosPromise<IdentifierDTO> {
            return localVarFp.createContractDocument(tenantId, employeeId, contractId, createBAVDocumentDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary submit a new request for BAV estimate
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {CreateBAVEstimateRequestDTO} createBAVEstimateRequestDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEstimateRequest(tenantId: string, employeeId: string, createBAVEstimateRequestDTO: CreateBAVEstimateRequestDTO, options?: any): AxiosPromise<void> {
            return localVarFp.createEstimateRequest(tenantId, employeeId, createBAVEstimateRequestDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary create new BAV terms for a tenant
         * @param {string} tenantId 
         * @param {CreateBAVTermsDTO} createBAVTermsDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTerms(tenantId: string, createBAVTermsDTO: CreateBAVTermsDTO, options?: any): AxiosPromise<string> {
            return localVarFp.createTerms(tenantId, createBAVTermsDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a form POST upload link for uploading a document. Refer to https://docs.aws.amazon.com/AmazonS3/latest/API/sigv4-post-example.html on how to implement the form POST.
         * @summary create a presigned upload link
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} contractId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUploadLink(tenantId: string, employeeId: string, contractId: string, options?: any): AxiosPromise<UploadLinkDTO> {
            return localVarFp.createUploadLink(tenantId, employeeId, contractId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary this will delete the consultant contact
         * @param {string} tenantId 
         * @param {string} bavConsultantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteConsultant(tenantId: string, bavConsultantId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteConsultant(tenantId, bavConsultantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary delete BAV contract
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} contractId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteContract(tenantId: string, employeeId: string, contractId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteContract(tenantId, employeeId, contractId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary delete document
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} contractId 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteContractDocument(tenantId: string, employeeId: string, contractId: string, documentId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteContractDocument(tenantId, employeeId, contractId, documentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get a BAV contract by id
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} contractId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContract(tenantId: string, employeeId: string, contractId: string, options?: any): AxiosPromise<BAVContractDTO> {
            return localVarFp.getContract(tenantId, employeeId, contractId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get a document by id
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} contractId 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContractDocument(tenantId: string, employeeId: string, contractId: string, documentId: string, options?: any): AxiosPromise<BAVDocumentDTO> {
            return localVarFp.getContractDocument(tenantId, employeeId, contractId, documentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get latest published BAV terms for a tenant
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLatestTerms(tenantId: string, options?: any): AxiosPromise<BAVTermsDTO> {
            return localVarFp.getLatestTerms(tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get list of external consultant for tenant
         * @param {string} tenantId 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListBAVConsultant(tenantId: string, page?: number, pageSize?: number, options?: any): AxiosPromise<BAVConsultantListDTO> {
            return localVarFp.getListBAVConsultant(tenantId, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary list BAV contracts for all tenants
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAllContracts(page?: number, pageSize?: number, options?: any): AxiosPromise<BAVContractWithTenantListDTO> {
            return localVarFp.listAllContracts(page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary list documents
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} contractId 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listContractDocuments(tenantId: string, employeeId: string, contractId: string, page?: number, pageSize?: number, options?: any): AxiosPromise<BAVDocumentListDTO> {
            return localVarFp.listContractDocuments(tenantId, employeeId, contractId, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary list BAV contracts for employee
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listContracts(tenantId: string, employeeId: string, page?: number, pageSize?: number, options?: any): AxiosPromise<BAVContractListDTO> {
            return localVarFp.listContracts(tenantId, employeeId, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary list BAV contracts for tenant
         * @param {string} tenantId 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTenantContracts(tenantId: string, page?: number, pageSize?: number, options?: any): AxiosPromise<BAVContractListDTO> {
            return localVarFp.listTenantContracts(tenantId, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary list BAV terms for a tenant
         * @param {string} tenantId 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTerms(tenantId: string, page?: number, pageSize?: number, options?: any): AxiosPromise<BAVTermsListDTO> {
            return localVarFp.listTerms(tenantId, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update bav consultant
         * @param {string} tenantId 
         * @param {string} bavConsultantId 
         * @param {UpdateBAVConsultantDTO} updateBAVConsultantDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConsultant(tenantId: string, bavConsultantId: string, updateBAVConsultantDTO: UpdateBAVConsultantDTO, options?: any): AxiosPromise<void> {
            return localVarFp.updateConsultant(tenantId, bavConsultantId, updateBAVConsultantDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update a BAV contract by id
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} contractId 
         * @param {UpdateBAVContractDTO} updateBAVContractDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateContract(tenantId: string, employeeId: string, contractId: string, updateBAVContractDTO: UpdateBAVContractDTO, options?: any): AxiosPromise<void> {
            return localVarFp.updateContract(tenantId, employeeId, contractId, updateBAVContractDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createBAVConsultant operation in BAVApi.
 * @export
 * @interface BAVApiCreateBAVConsultantRequest
 */
export interface BAVApiCreateBAVConsultantRequest {
    /**
     * 
     * @type {string}
     * @memberof BAVApiCreateBAVConsultant
     */
    readonly tenantId: string

    /**
     * 
     * @type {CreateBAVConsultantDTO}
     * @memberof BAVApiCreateBAVConsultant
     */
    readonly createBAVConsultantDTO: CreateBAVConsultantDTO
}

/**
 * Request parameters for createChangeRequest operation in BAVApi.
 * @export
 * @interface BAVApiCreateChangeRequestRequest
 */
export interface BAVApiCreateChangeRequestRequest {
    /**
     * 
     * @type {string}
     * @memberof BAVApiCreateChangeRequest
     */
    readonly tenantId: string

    /**
     * 
     * @type {string}
     * @memberof BAVApiCreateChangeRequest
     */
    readonly employeeId: string

    /**
     * 
     * @type {string}
     * @memberof BAVApiCreateChangeRequest
     */
    readonly contractId: string

    /**
     * 
     * @type {CreateBAVChangeRequestDTO}
     * @memberof BAVApiCreateChangeRequest
     */
    readonly createBAVChangeRequestDTO: CreateBAVChangeRequestDTO
}

/**
 * Request parameters for createContract operation in BAVApi.
 * @export
 * @interface BAVApiCreateContractRequest
 */
export interface BAVApiCreateContractRequest {
    /**
     * 
     * @type {string}
     * @memberof BAVApiCreateContract
     */
    readonly tenantId: string

    /**
     * 
     * @type {string}
     * @memberof BAVApiCreateContract
     */
    readonly employeeId: string

    /**
     * 
     * @type {CreateBAVContractDTO}
     * @memberof BAVApiCreateContract
     */
    readonly createBAVContractDTO: CreateBAVContractDTO
}

/**
 * Request parameters for createContractDocument operation in BAVApi.
 * @export
 * @interface BAVApiCreateContractDocumentRequest
 */
export interface BAVApiCreateContractDocumentRequest {
    /**
     * 
     * @type {string}
     * @memberof BAVApiCreateContractDocument
     */
    readonly tenantId: string

    /**
     * 
     * @type {string}
     * @memberof BAVApiCreateContractDocument
     */
    readonly employeeId: string

    /**
     * 
     * @type {string}
     * @memberof BAVApiCreateContractDocument
     */
    readonly contractId: string

    /**
     * 
     * @type {CreateBAVDocumentDTO}
     * @memberof BAVApiCreateContractDocument
     */
    readonly createBAVDocumentDTO: CreateBAVDocumentDTO
}

/**
 * Request parameters for createEstimateRequest operation in BAVApi.
 * @export
 * @interface BAVApiCreateEstimateRequestRequest
 */
export interface BAVApiCreateEstimateRequestRequest {
    /**
     * 
     * @type {string}
     * @memberof BAVApiCreateEstimateRequest
     */
    readonly tenantId: string

    /**
     * 
     * @type {string}
     * @memberof BAVApiCreateEstimateRequest
     */
    readonly employeeId: string

    /**
     * 
     * @type {CreateBAVEstimateRequestDTO}
     * @memberof BAVApiCreateEstimateRequest
     */
    readonly createBAVEstimateRequestDTO: CreateBAVEstimateRequestDTO
}

/**
 * Request parameters for createTerms operation in BAVApi.
 * @export
 * @interface BAVApiCreateTermsRequest
 */
export interface BAVApiCreateTermsRequest {
    /**
     * 
     * @type {string}
     * @memberof BAVApiCreateTerms
     */
    readonly tenantId: string

    /**
     * 
     * @type {CreateBAVTermsDTO}
     * @memberof BAVApiCreateTerms
     */
    readonly createBAVTermsDTO: CreateBAVTermsDTO
}

/**
 * Request parameters for createUploadLink operation in BAVApi.
 * @export
 * @interface BAVApiCreateUploadLinkRequest
 */
export interface BAVApiCreateUploadLinkRequest {
    /**
     * 
     * @type {string}
     * @memberof BAVApiCreateUploadLink
     */
    readonly tenantId: string

    /**
     * 
     * @type {string}
     * @memberof BAVApiCreateUploadLink
     */
    readonly employeeId: string

    /**
     * 
     * @type {string}
     * @memberof BAVApiCreateUploadLink
     */
    readonly contractId: string
}

/**
 * Request parameters for deleteConsultant operation in BAVApi.
 * @export
 * @interface BAVApiDeleteConsultantRequest
 */
export interface BAVApiDeleteConsultantRequest {
    /**
     * 
     * @type {string}
     * @memberof BAVApiDeleteConsultant
     */
    readonly tenantId: string

    /**
     * 
     * @type {string}
     * @memberof BAVApiDeleteConsultant
     */
    readonly bavConsultantId: string
}

/**
 * Request parameters for deleteContract operation in BAVApi.
 * @export
 * @interface BAVApiDeleteContractRequest
 */
export interface BAVApiDeleteContractRequest {
    /**
     * 
     * @type {string}
     * @memberof BAVApiDeleteContract
     */
    readonly tenantId: string

    /**
     * 
     * @type {string}
     * @memberof BAVApiDeleteContract
     */
    readonly employeeId: string

    /**
     * 
     * @type {string}
     * @memberof BAVApiDeleteContract
     */
    readonly contractId: string
}

/**
 * Request parameters for deleteContractDocument operation in BAVApi.
 * @export
 * @interface BAVApiDeleteContractDocumentRequest
 */
export interface BAVApiDeleteContractDocumentRequest {
    /**
     * 
     * @type {string}
     * @memberof BAVApiDeleteContractDocument
     */
    readonly tenantId: string

    /**
     * 
     * @type {string}
     * @memberof BAVApiDeleteContractDocument
     */
    readonly employeeId: string

    /**
     * 
     * @type {string}
     * @memberof BAVApiDeleteContractDocument
     */
    readonly contractId: string

    /**
     * 
     * @type {string}
     * @memberof BAVApiDeleteContractDocument
     */
    readonly documentId: string
}

/**
 * Request parameters for getContract operation in BAVApi.
 * @export
 * @interface BAVApiGetContractRequest
 */
export interface BAVApiGetContractRequest {
    /**
     * 
     * @type {string}
     * @memberof BAVApiGetContract
     */
    readonly tenantId: string

    /**
     * 
     * @type {string}
     * @memberof BAVApiGetContract
     */
    readonly employeeId: string

    /**
     * 
     * @type {string}
     * @memberof BAVApiGetContract
     */
    readonly contractId: string
}

/**
 * Request parameters for getContractDocument operation in BAVApi.
 * @export
 * @interface BAVApiGetContractDocumentRequest
 */
export interface BAVApiGetContractDocumentRequest {
    /**
     * 
     * @type {string}
     * @memberof BAVApiGetContractDocument
     */
    readonly tenantId: string

    /**
     * 
     * @type {string}
     * @memberof BAVApiGetContractDocument
     */
    readonly employeeId: string

    /**
     * 
     * @type {string}
     * @memberof BAVApiGetContractDocument
     */
    readonly contractId: string

    /**
     * 
     * @type {string}
     * @memberof BAVApiGetContractDocument
     */
    readonly documentId: string
}

/**
 * Request parameters for getLatestTerms operation in BAVApi.
 * @export
 * @interface BAVApiGetLatestTermsRequest
 */
export interface BAVApiGetLatestTermsRequest {
    /**
     * 
     * @type {string}
     * @memberof BAVApiGetLatestTerms
     */
    readonly tenantId: string
}

/**
 * Request parameters for getListBAVConsultant operation in BAVApi.
 * @export
 * @interface BAVApiGetListBAVConsultantRequest
 */
export interface BAVApiGetListBAVConsultantRequest {
    /**
     * 
     * @type {string}
     * @memberof BAVApiGetListBAVConsultant
     */
    readonly tenantId: string

    /**
     * 
     * @type {number}
     * @memberof BAVApiGetListBAVConsultant
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof BAVApiGetListBAVConsultant
     */
    readonly pageSize?: number
}

/**
 * Request parameters for listAllContracts operation in BAVApi.
 * @export
 * @interface BAVApiListAllContractsRequest
 */
export interface BAVApiListAllContractsRequest {
    /**
     * 
     * @type {number}
     * @memberof BAVApiListAllContracts
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof BAVApiListAllContracts
     */
    readonly pageSize?: number
}

/**
 * Request parameters for listContractDocuments operation in BAVApi.
 * @export
 * @interface BAVApiListContractDocumentsRequest
 */
export interface BAVApiListContractDocumentsRequest {
    /**
     * 
     * @type {string}
     * @memberof BAVApiListContractDocuments
     */
    readonly tenantId: string

    /**
     * 
     * @type {string}
     * @memberof BAVApiListContractDocuments
     */
    readonly employeeId: string

    /**
     * 
     * @type {string}
     * @memberof BAVApiListContractDocuments
     */
    readonly contractId: string

    /**
     * 
     * @type {number}
     * @memberof BAVApiListContractDocuments
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof BAVApiListContractDocuments
     */
    readonly pageSize?: number
}

/**
 * Request parameters for listContracts operation in BAVApi.
 * @export
 * @interface BAVApiListContractsRequest
 */
export interface BAVApiListContractsRequest {
    /**
     * 
     * @type {string}
     * @memberof BAVApiListContracts
     */
    readonly tenantId: string

    /**
     * 
     * @type {string}
     * @memberof BAVApiListContracts
     */
    readonly employeeId: string

    /**
     * 
     * @type {number}
     * @memberof BAVApiListContracts
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof BAVApiListContracts
     */
    readonly pageSize?: number
}

/**
 * Request parameters for listTenantContracts operation in BAVApi.
 * @export
 * @interface BAVApiListTenantContractsRequest
 */
export interface BAVApiListTenantContractsRequest {
    /**
     * 
     * @type {string}
     * @memberof BAVApiListTenantContracts
     */
    readonly tenantId: string

    /**
     * 
     * @type {number}
     * @memberof BAVApiListTenantContracts
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof BAVApiListTenantContracts
     */
    readonly pageSize?: number
}

/**
 * Request parameters for listTerms operation in BAVApi.
 * @export
 * @interface BAVApiListTermsRequest
 */
export interface BAVApiListTermsRequest {
    /**
     * 
     * @type {string}
     * @memberof BAVApiListTerms
     */
    readonly tenantId: string

    /**
     * 
     * @type {number}
     * @memberof BAVApiListTerms
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof BAVApiListTerms
     */
    readonly pageSize?: number
}

/**
 * Request parameters for updateConsultant operation in BAVApi.
 * @export
 * @interface BAVApiUpdateConsultantRequest
 */
export interface BAVApiUpdateConsultantRequest {
    /**
     * 
     * @type {string}
     * @memberof BAVApiUpdateConsultant
     */
    readonly tenantId: string

    /**
     * 
     * @type {string}
     * @memberof BAVApiUpdateConsultant
     */
    readonly bavConsultantId: string

    /**
     * 
     * @type {UpdateBAVConsultantDTO}
     * @memberof BAVApiUpdateConsultant
     */
    readonly updateBAVConsultantDTO: UpdateBAVConsultantDTO
}

/**
 * Request parameters for updateContract operation in BAVApi.
 * @export
 * @interface BAVApiUpdateContractRequest
 */
export interface BAVApiUpdateContractRequest {
    /**
     * 
     * @type {string}
     * @memberof BAVApiUpdateContract
     */
    readonly tenantId: string

    /**
     * 
     * @type {string}
     * @memberof BAVApiUpdateContract
     */
    readonly employeeId: string

    /**
     * 
     * @type {string}
     * @memberof BAVApiUpdateContract
     */
    readonly contractId: string

    /**
     * 
     * @type {UpdateBAVContractDTO}
     * @memberof BAVApiUpdateContract
     */
    readonly updateBAVContractDTO: UpdateBAVContractDTO
}

/**
 * BAVApi - object-oriented interface
 * @export
 * @class BAVApi
 * @extends {BaseAPI}
 */
export class BAVApi extends BaseAPI {
    /**
     * 
     * @summary creates new external consultant for tenant
     * @param {BAVApiCreateBAVConsultantRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BAVApi
     */
    public createBAVConsultant(requestParameters: BAVApiCreateBAVConsultantRequest, options?: AxiosRequestConfig) {
        return BAVApiFp(this.configuration).createBAVConsultant(requestParameters.tenantId, requestParameters.createBAVConsultantDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary request a change to an existing BAV contract
     * @param {BAVApiCreateChangeRequestRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BAVApi
     */
    public createChangeRequest(requestParameters: BAVApiCreateChangeRequestRequest, options?: AxiosRequestConfig) {
        return BAVApiFp(this.configuration).createChangeRequest(requestParameters.tenantId, requestParameters.employeeId, requestParameters.contractId, requestParameters.createBAVChangeRequestDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary create a new BAV contract
     * @param {BAVApiCreateContractRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BAVApi
     */
    public createContract(requestParameters: BAVApiCreateContractRequest, options?: AxiosRequestConfig) {
        return BAVApiFp(this.configuration).createContract(requestParameters.tenantId, requestParameters.employeeId, requestParameters.createBAVContractDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary upload a new document
     * @param {BAVApiCreateContractDocumentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BAVApi
     */
    public createContractDocument(requestParameters: BAVApiCreateContractDocumentRequest, options?: AxiosRequestConfig) {
        return BAVApiFp(this.configuration).createContractDocument(requestParameters.tenantId, requestParameters.employeeId, requestParameters.contractId, requestParameters.createBAVDocumentDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary submit a new request for BAV estimate
     * @param {BAVApiCreateEstimateRequestRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BAVApi
     */
    public createEstimateRequest(requestParameters: BAVApiCreateEstimateRequestRequest, options?: AxiosRequestConfig) {
        return BAVApiFp(this.configuration).createEstimateRequest(requestParameters.tenantId, requestParameters.employeeId, requestParameters.createBAVEstimateRequestDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary create new BAV terms for a tenant
     * @param {BAVApiCreateTermsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BAVApi
     */
    public createTerms(requestParameters: BAVApiCreateTermsRequest, options?: AxiosRequestConfig) {
        return BAVApiFp(this.configuration).createTerms(requestParameters.tenantId, requestParameters.createBAVTermsDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a form POST upload link for uploading a document. Refer to https://docs.aws.amazon.com/AmazonS3/latest/API/sigv4-post-example.html on how to implement the form POST.
     * @summary create a presigned upload link
     * @param {BAVApiCreateUploadLinkRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BAVApi
     */
    public createUploadLink(requestParameters: BAVApiCreateUploadLinkRequest, options?: AxiosRequestConfig) {
        return BAVApiFp(this.configuration).createUploadLink(requestParameters.tenantId, requestParameters.employeeId, requestParameters.contractId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary this will delete the consultant contact
     * @param {BAVApiDeleteConsultantRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BAVApi
     */
    public deleteConsultant(requestParameters: BAVApiDeleteConsultantRequest, options?: AxiosRequestConfig) {
        return BAVApiFp(this.configuration).deleteConsultant(requestParameters.tenantId, requestParameters.bavConsultantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary delete BAV contract
     * @param {BAVApiDeleteContractRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BAVApi
     */
    public deleteContract(requestParameters: BAVApiDeleteContractRequest, options?: AxiosRequestConfig) {
        return BAVApiFp(this.configuration).deleteContract(requestParameters.tenantId, requestParameters.employeeId, requestParameters.contractId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary delete document
     * @param {BAVApiDeleteContractDocumentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BAVApi
     */
    public deleteContractDocument(requestParameters: BAVApiDeleteContractDocumentRequest, options?: AxiosRequestConfig) {
        return BAVApiFp(this.configuration).deleteContractDocument(requestParameters.tenantId, requestParameters.employeeId, requestParameters.contractId, requestParameters.documentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get a BAV contract by id
     * @param {BAVApiGetContractRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BAVApi
     */
    public getContract(requestParameters: BAVApiGetContractRequest, options?: AxiosRequestConfig) {
        return BAVApiFp(this.configuration).getContract(requestParameters.tenantId, requestParameters.employeeId, requestParameters.contractId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get a document by id
     * @param {BAVApiGetContractDocumentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BAVApi
     */
    public getContractDocument(requestParameters: BAVApiGetContractDocumentRequest, options?: AxiosRequestConfig) {
        return BAVApiFp(this.configuration).getContractDocument(requestParameters.tenantId, requestParameters.employeeId, requestParameters.contractId, requestParameters.documentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get latest published BAV terms for a tenant
     * @param {BAVApiGetLatestTermsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BAVApi
     */
    public getLatestTerms(requestParameters: BAVApiGetLatestTermsRequest, options?: AxiosRequestConfig) {
        return BAVApiFp(this.configuration).getLatestTerms(requestParameters.tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get list of external consultant for tenant
     * @param {BAVApiGetListBAVConsultantRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BAVApi
     */
    public getListBAVConsultant(requestParameters: BAVApiGetListBAVConsultantRequest, options?: AxiosRequestConfig) {
        return BAVApiFp(this.configuration).getListBAVConsultant(requestParameters.tenantId, requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary list BAV contracts for all tenants
     * @param {BAVApiListAllContractsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BAVApi
     */
    public listAllContracts(requestParameters: BAVApiListAllContractsRequest = {}, options?: AxiosRequestConfig) {
        return BAVApiFp(this.configuration).listAllContracts(requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary list documents
     * @param {BAVApiListContractDocumentsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BAVApi
     */
    public listContractDocuments(requestParameters: BAVApiListContractDocumentsRequest, options?: AxiosRequestConfig) {
        return BAVApiFp(this.configuration).listContractDocuments(requestParameters.tenantId, requestParameters.employeeId, requestParameters.contractId, requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary list BAV contracts for employee
     * @param {BAVApiListContractsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BAVApi
     */
    public listContracts(requestParameters: BAVApiListContractsRequest, options?: AxiosRequestConfig) {
        return BAVApiFp(this.configuration).listContracts(requestParameters.tenantId, requestParameters.employeeId, requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary list BAV contracts for tenant
     * @param {BAVApiListTenantContractsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BAVApi
     */
    public listTenantContracts(requestParameters: BAVApiListTenantContractsRequest, options?: AxiosRequestConfig) {
        return BAVApiFp(this.configuration).listTenantContracts(requestParameters.tenantId, requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary list BAV terms for a tenant
     * @param {BAVApiListTermsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BAVApi
     */
    public listTerms(requestParameters: BAVApiListTermsRequest, options?: AxiosRequestConfig) {
        return BAVApiFp(this.configuration).listTerms(requestParameters.tenantId, requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update bav consultant
     * @param {BAVApiUpdateConsultantRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BAVApi
     */
    public updateConsultant(requestParameters: BAVApiUpdateConsultantRequest, options?: AxiosRequestConfig) {
        return BAVApiFp(this.configuration).updateConsultant(requestParameters.tenantId, requestParameters.bavConsultantId, requestParameters.updateBAVConsultantDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update a BAV contract by id
     * @param {BAVApiUpdateContractRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BAVApi
     */
    public updateContract(requestParameters: BAVApiUpdateContractRequest, options?: AxiosRequestConfig) {
        return BAVApiFp(this.configuration).updateContract(requestParameters.tenantId, requestParameters.employeeId, requestParameters.contractId, requestParameters.updateBAVContractDTO, options).then((request) => request(this.axios, this.basePath));
    }
}
