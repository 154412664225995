/* tslint:disable */
/* eslint-disable */
/**
 * Probonio full API Docs
 * If you stumbled upon this page and want to integrate Probonio into your business software [click here!](/api/external)    If you\'re looking for more information about Probonio [head over here](https://probonio.de)    **This documentation describes all Endpoints of the Probonio API. It\'s created for experienced Probonio Developers only!**  
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CalculatorNetResultDTO } from '../dto';
// @ts-ignore
import { CalculatorResultDTO } from '../dto';
/**
 * SalaryCalculatorApi - axios parameter creator
 * @export
 */
export const SalaryCalculatorApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary gets from external tax calculator
         * @param {string} tenantId 
         * @param {number} netIncome 
         * @param {boolean} isChurch 
         * @param {number} childAmount 
         * @param {number} taxClass 
         * @param {boolean} isPrivatelyInsured 
         * @param {number} privateInsuranceAmount 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculateGrossValue: async (tenantId: string, netIncome: number, isChurch: boolean, childAmount: number, taxClass: number, isPrivatelyInsured: boolean, privateInsuranceAmount: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('calculateGrossValue', 'tenantId', tenantId)
            // verify required parameter 'netIncome' is not null or undefined
            assertParamExists('calculateGrossValue', 'netIncome', netIncome)
            // verify required parameter 'isChurch' is not null or undefined
            assertParamExists('calculateGrossValue', 'isChurch', isChurch)
            // verify required parameter 'childAmount' is not null or undefined
            assertParamExists('calculateGrossValue', 'childAmount', childAmount)
            // verify required parameter 'taxClass' is not null or undefined
            assertParamExists('calculateGrossValue', 'taxClass', taxClass)
            // verify required parameter 'isPrivatelyInsured' is not null or undefined
            assertParamExists('calculateGrossValue', 'isPrivatelyInsured', isPrivatelyInsured)
            // verify required parameter 'privateInsuranceAmount' is not null or undefined
            assertParamExists('calculateGrossValue', 'privateInsuranceAmount', privateInsuranceAmount)
            const localVarPath = `/tenants/{tenantId}/grossNetCalculator/calculateGrossValue`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (netIncome !== undefined) {
                localVarQueryParameter['netIncome'] = netIncome;
            }

            if (isChurch !== undefined) {
                localVarQueryParameter['isChurch'] = isChurch;
            }

            if (childAmount !== undefined) {
                localVarQueryParameter['childAmount'] = childAmount;
            }

            if (taxClass !== undefined) {
                localVarQueryParameter['taxClass'] = taxClass;
            }

            if (isPrivatelyInsured !== undefined) {
                localVarQueryParameter['isPrivatelyInsured'] = isPrivatelyInsured;
            }

            if (privateInsuranceAmount !== undefined) {
                localVarQueryParameter['privateInsuranceAmount'] = privateInsuranceAmount;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary gets from external tax calculator
         * @param {string} tenantId 
         * @param {number} grossIncome 
         * @param {boolean} isChurch 
         * @param {number} childAmount 
         * @param {number} taxClass 
         * @param {boolean} isPrivatelyInsured 
         * @param {number} privateInsuranceAmount 
         * @param {number} lunchBenefitAmount 
         * @param {number} voucherBenefitAmount 
         * @param {number} giftBenefitAmount 
         * @param {number} mobilityBenefitAmount 
         * @param {number} internetBenefitAmount 
         * @param {number} recreationBenefitAmount 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFromCalculator: async (tenantId: string, grossIncome: number, isChurch: boolean, childAmount: number, taxClass: number, isPrivatelyInsured: boolean, privateInsuranceAmount: number, lunchBenefitAmount: number, voucherBenefitAmount: number, giftBenefitAmount: number, mobilityBenefitAmount: number, internetBenefitAmount: number, recreationBenefitAmount: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('getFromCalculator', 'tenantId', tenantId)
            // verify required parameter 'grossIncome' is not null or undefined
            assertParamExists('getFromCalculator', 'grossIncome', grossIncome)
            // verify required parameter 'isChurch' is not null or undefined
            assertParamExists('getFromCalculator', 'isChurch', isChurch)
            // verify required parameter 'childAmount' is not null or undefined
            assertParamExists('getFromCalculator', 'childAmount', childAmount)
            // verify required parameter 'taxClass' is not null or undefined
            assertParamExists('getFromCalculator', 'taxClass', taxClass)
            // verify required parameter 'isPrivatelyInsured' is not null or undefined
            assertParamExists('getFromCalculator', 'isPrivatelyInsured', isPrivatelyInsured)
            // verify required parameter 'privateInsuranceAmount' is not null or undefined
            assertParamExists('getFromCalculator', 'privateInsuranceAmount', privateInsuranceAmount)
            // verify required parameter 'lunchBenefitAmount' is not null or undefined
            assertParamExists('getFromCalculator', 'lunchBenefitAmount', lunchBenefitAmount)
            // verify required parameter 'voucherBenefitAmount' is not null or undefined
            assertParamExists('getFromCalculator', 'voucherBenefitAmount', voucherBenefitAmount)
            // verify required parameter 'giftBenefitAmount' is not null or undefined
            assertParamExists('getFromCalculator', 'giftBenefitAmount', giftBenefitAmount)
            // verify required parameter 'mobilityBenefitAmount' is not null or undefined
            assertParamExists('getFromCalculator', 'mobilityBenefitAmount', mobilityBenefitAmount)
            // verify required parameter 'internetBenefitAmount' is not null or undefined
            assertParamExists('getFromCalculator', 'internetBenefitAmount', internetBenefitAmount)
            // verify required parameter 'recreationBenefitAmount' is not null or undefined
            assertParamExists('getFromCalculator', 'recreationBenefitAmount', recreationBenefitAmount)
            const localVarPath = `/tenants/{tenantId}/grossNetCalculator/calculateNetValue`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (grossIncome !== undefined) {
                localVarQueryParameter['grossIncome'] = grossIncome;
            }

            if (isChurch !== undefined) {
                localVarQueryParameter['isChurch'] = isChurch;
            }

            if (childAmount !== undefined) {
                localVarQueryParameter['childAmount'] = childAmount;
            }

            if (taxClass !== undefined) {
                localVarQueryParameter['taxClass'] = taxClass;
            }

            if (isPrivatelyInsured !== undefined) {
                localVarQueryParameter['isPrivatelyInsured'] = isPrivatelyInsured;
            }

            if (privateInsuranceAmount !== undefined) {
                localVarQueryParameter['privateInsuranceAmount'] = privateInsuranceAmount;
            }

            if (lunchBenefitAmount !== undefined) {
                localVarQueryParameter['lunchBenefitAmount'] = lunchBenefitAmount;
            }

            if (voucherBenefitAmount !== undefined) {
                localVarQueryParameter['voucherBenefitAmount'] = voucherBenefitAmount;
            }

            if (giftBenefitAmount !== undefined) {
                localVarQueryParameter['giftBenefitAmount'] = giftBenefitAmount;
            }

            if (mobilityBenefitAmount !== undefined) {
                localVarQueryParameter['mobilityBenefitAmount'] = mobilityBenefitAmount;
            }

            if (internetBenefitAmount !== undefined) {
                localVarQueryParameter['internetBenefitAmount'] = internetBenefitAmount;
            }

            if (recreationBenefitAmount !== undefined) {
                localVarQueryParameter['recreationBenefitAmount'] = recreationBenefitAmount;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SalaryCalculatorApi - functional programming interface
 * @export
 */
export const SalaryCalculatorApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SalaryCalculatorApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary gets from external tax calculator
         * @param {string} tenantId 
         * @param {number} netIncome 
         * @param {boolean} isChurch 
         * @param {number} childAmount 
         * @param {number} taxClass 
         * @param {boolean} isPrivatelyInsured 
         * @param {number} privateInsuranceAmount 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async calculateGrossValue(tenantId: string, netIncome: number, isChurch: boolean, childAmount: number, taxClass: number, isPrivatelyInsured: boolean, privateInsuranceAmount: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalculatorNetResultDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.calculateGrossValue(tenantId, netIncome, isChurch, childAmount, taxClass, isPrivatelyInsured, privateInsuranceAmount, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary gets from external tax calculator
         * @param {string} tenantId 
         * @param {number} grossIncome 
         * @param {boolean} isChurch 
         * @param {number} childAmount 
         * @param {number} taxClass 
         * @param {boolean} isPrivatelyInsured 
         * @param {number} privateInsuranceAmount 
         * @param {number} lunchBenefitAmount 
         * @param {number} voucherBenefitAmount 
         * @param {number} giftBenefitAmount 
         * @param {number} mobilityBenefitAmount 
         * @param {number} internetBenefitAmount 
         * @param {number} recreationBenefitAmount 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFromCalculator(tenantId: string, grossIncome: number, isChurch: boolean, childAmount: number, taxClass: number, isPrivatelyInsured: boolean, privateInsuranceAmount: number, lunchBenefitAmount: number, voucherBenefitAmount: number, giftBenefitAmount: number, mobilityBenefitAmount: number, internetBenefitAmount: number, recreationBenefitAmount: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalculatorResultDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFromCalculator(tenantId, grossIncome, isChurch, childAmount, taxClass, isPrivatelyInsured, privateInsuranceAmount, lunchBenefitAmount, voucherBenefitAmount, giftBenefitAmount, mobilityBenefitAmount, internetBenefitAmount, recreationBenefitAmount, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SalaryCalculatorApi - factory interface
 * @export
 */
export const SalaryCalculatorApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SalaryCalculatorApiFp(configuration)
    return {
        /**
         * 
         * @summary gets from external tax calculator
         * @param {string} tenantId 
         * @param {number} netIncome 
         * @param {boolean} isChurch 
         * @param {number} childAmount 
         * @param {number} taxClass 
         * @param {boolean} isPrivatelyInsured 
         * @param {number} privateInsuranceAmount 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculateGrossValue(tenantId: string, netIncome: number, isChurch: boolean, childAmount: number, taxClass: number, isPrivatelyInsured: boolean, privateInsuranceAmount: number, options?: any): AxiosPromise<CalculatorNetResultDTO> {
            return localVarFp.calculateGrossValue(tenantId, netIncome, isChurch, childAmount, taxClass, isPrivatelyInsured, privateInsuranceAmount, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary gets from external tax calculator
         * @param {string} tenantId 
         * @param {number} grossIncome 
         * @param {boolean} isChurch 
         * @param {number} childAmount 
         * @param {number} taxClass 
         * @param {boolean} isPrivatelyInsured 
         * @param {number} privateInsuranceAmount 
         * @param {number} lunchBenefitAmount 
         * @param {number} voucherBenefitAmount 
         * @param {number} giftBenefitAmount 
         * @param {number} mobilityBenefitAmount 
         * @param {number} internetBenefitAmount 
         * @param {number} recreationBenefitAmount 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFromCalculator(tenantId: string, grossIncome: number, isChurch: boolean, childAmount: number, taxClass: number, isPrivatelyInsured: boolean, privateInsuranceAmount: number, lunchBenefitAmount: number, voucherBenefitAmount: number, giftBenefitAmount: number, mobilityBenefitAmount: number, internetBenefitAmount: number, recreationBenefitAmount: number, options?: any): AxiosPromise<CalculatorResultDTO> {
            return localVarFp.getFromCalculator(tenantId, grossIncome, isChurch, childAmount, taxClass, isPrivatelyInsured, privateInsuranceAmount, lunchBenefitAmount, voucherBenefitAmount, giftBenefitAmount, mobilityBenefitAmount, internetBenefitAmount, recreationBenefitAmount, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for calculateGrossValue operation in SalaryCalculatorApi.
 * @export
 * @interface SalaryCalculatorApiCalculateGrossValueRequest
 */
export interface SalaryCalculatorApiCalculateGrossValueRequest {
    /**
     * 
     * @type {string}
     * @memberof SalaryCalculatorApiCalculateGrossValue
     */
    readonly tenantId: string

    /**
     * 
     * @type {number}
     * @memberof SalaryCalculatorApiCalculateGrossValue
     */
    readonly netIncome: number

    /**
     * 
     * @type {boolean}
     * @memberof SalaryCalculatorApiCalculateGrossValue
     */
    readonly isChurch: boolean

    /**
     * 
     * @type {number}
     * @memberof SalaryCalculatorApiCalculateGrossValue
     */
    readonly childAmount: number

    /**
     * 
     * @type {number}
     * @memberof SalaryCalculatorApiCalculateGrossValue
     */
    readonly taxClass: number

    /**
     * 
     * @type {boolean}
     * @memberof SalaryCalculatorApiCalculateGrossValue
     */
    readonly isPrivatelyInsured: boolean

    /**
     * 
     * @type {number}
     * @memberof SalaryCalculatorApiCalculateGrossValue
     */
    readonly privateInsuranceAmount: number
}

/**
 * Request parameters for getFromCalculator operation in SalaryCalculatorApi.
 * @export
 * @interface SalaryCalculatorApiGetFromCalculatorRequest
 */
export interface SalaryCalculatorApiGetFromCalculatorRequest {
    /**
     * 
     * @type {string}
     * @memberof SalaryCalculatorApiGetFromCalculator
     */
    readonly tenantId: string

    /**
     * 
     * @type {number}
     * @memberof SalaryCalculatorApiGetFromCalculator
     */
    readonly grossIncome: number

    /**
     * 
     * @type {boolean}
     * @memberof SalaryCalculatorApiGetFromCalculator
     */
    readonly isChurch: boolean

    /**
     * 
     * @type {number}
     * @memberof SalaryCalculatorApiGetFromCalculator
     */
    readonly childAmount: number

    /**
     * 
     * @type {number}
     * @memberof SalaryCalculatorApiGetFromCalculator
     */
    readonly taxClass: number

    /**
     * 
     * @type {boolean}
     * @memberof SalaryCalculatorApiGetFromCalculator
     */
    readonly isPrivatelyInsured: boolean

    /**
     * 
     * @type {number}
     * @memberof SalaryCalculatorApiGetFromCalculator
     */
    readonly privateInsuranceAmount: number

    /**
     * 
     * @type {number}
     * @memberof SalaryCalculatorApiGetFromCalculator
     */
    readonly lunchBenefitAmount: number

    /**
     * 
     * @type {number}
     * @memberof SalaryCalculatorApiGetFromCalculator
     */
    readonly voucherBenefitAmount: number

    /**
     * 
     * @type {number}
     * @memberof SalaryCalculatorApiGetFromCalculator
     */
    readonly giftBenefitAmount: number

    /**
     * 
     * @type {number}
     * @memberof SalaryCalculatorApiGetFromCalculator
     */
    readonly mobilityBenefitAmount: number

    /**
     * 
     * @type {number}
     * @memberof SalaryCalculatorApiGetFromCalculator
     */
    readonly internetBenefitAmount: number

    /**
     * 
     * @type {number}
     * @memberof SalaryCalculatorApiGetFromCalculator
     */
    readonly recreationBenefitAmount: number
}

/**
 * SalaryCalculatorApi - object-oriented interface
 * @export
 * @class SalaryCalculatorApi
 * @extends {BaseAPI}
 */
export class SalaryCalculatorApi extends BaseAPI {
    /**
     * 
     * @summary gets from external tax calculator
     * @param {SalaryCalculatorApiCalculateGrossValueRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalaryCalculatorApi
     */
    public calculateGrossValue(requestParameters: SalaryCalculatorApiCalculateGrossValueRequest, options?: AxiosRequestConfig) {
        return SalaryCalculatorApiFp(this.configuration).calculateGrossValue(requestParameters.tenantId, requestParameters.netIncome, requestParameters.isChurch, requestParameters.childAmount, requestParameters.taxClass, requestParameters.isPrivatelyInsured, requestParameters.privateInsuranceAmount, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary gets from external tax calculator
     * @param {SalaryCalculatorApiGetFromCalculatorRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalaryCalculatorApi
     */
    public getFromCalculator(requestParameters: SalaryCalculatorApiGetFromCalculatorRequest, options?: AxiosRequestConfig) {
        return SalaryCalculatorApiFp(this.configuration).getFromCalculator(requestParameters.tenantId, requestParameters.grossIncome, requestParameters.isChurch, requestParameters.childAmount, requestParameters.taxClass, requestParameters.isPrivatelyInsured, requestParameters.privateInsuranceAmount, requestParameters.lunchBenefitAmount, requestParameters.voucherBenefitAmount, requestParameters.giftBenefitAmount, requestParameters.mobilityBenefitAmount, requestParameters.internetBenefitAmount, requestParameters.recreationBenefitAmount, options).then((request) => request(this.axios, this.basePath));
    }
}
