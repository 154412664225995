import { Box, styled, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { useCallback } from 'react';
import { BG_HEIGHT } from './CircularFloatingButton';

export enum ButtonTab {
  left = 'left',
  right = 'right',
}

interface SwitchButtonProps {
  isRight?: boolean;
}

const SwitchButton = styled(ToggleButton, {
  shouldForwardProp: prop => prop !== 'isRight',
})<SwitchButtonProps>(({ isRight = false, theme }) => ({
  width: `calc(50% + ${BG_HEIGHT / 2}px)`,
  position: 'absolute',
  top: 0,
  height: BG_HEIGHT + 8,
  color: theme.palette.greyBackground,
  backgroundColor: theme.palette.background.paper,
  border: 0,
  boxShadow: `0px 4px 0px 0px ${theme.palette.greyBackground}`,
  borderRadius: isRight ? '0% 100% 100% 0% / 100% 0% 100% 0%' : '100% 0% 0% 100%/0% 100% 0% 100%',
  right: isRight ? -BG_HEIGHT / 2 : undefined,
  left: isRight ? undefined : -BG_HEIGHT / 2,
  '&.Mui-disabled': {
    border: 0,
  },
  '&.Mui-selected': {
    backgroundColor: theme.palette.background.paper,
    boxShadow: `0px 4px 0px 0px ${theme.palette.primary.main}`,
  },
  '&:hover': {
    backgroundColor: theme.palette.background.paper,
  },
  '&.Mui-selected:hover': {
    backgroundColor: theme.palette.background.paper,
  },
}));

interface Props {
  defaultValue?: ButtonTab;
  handleTabChange?: (newSelectedTab: ButtonTab) => void;
  leftIcon: React.ReactNode;
  rightIcon: React.ReactNode;
  disabled?: boolean;
}

export const CircularFloatingButtonTabs: React.FC<Props> = ({
  defaultValue = ButtonTab.left,
  handleTabChange,
  leftIcon,
  rightIcon,
  disabled = false,
}) => {
  const handleChange = useCallback(
    (event: React.MouseEvent<HTMLElement>, newSelectedTab: ButtonTab) => {
      if (handleTabChange) {
        handleTabChange(newSelectedTab);
      }
    },
    [handleTabChange],
  );
  return (
    <ToggleButtonGroup
      sx={{
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        width: '100%',
        height: BG_HEIGHT + 35,
        overflow: 'hidden',
        borderRadius: 0,
      }}
      color="primary"
      value={defaultValue}
    >
      <SwitchButton value={ButtonTab.left} onClick={handleChange}>
        <Box sx={{ transform: 'rotate(7deg)' }}>{leftIcon}</Box>
      </SwitchButton>
      <SwitchButton disabled={disabled} value={ButtonTab.right} onClick={handleChange} isRight>
        <Box sx={{ transform: 'rotate(-7deg)' }}>{rightIcon}</Box>
      </SwitchButton>
    </ToggleButtonGroup>
  );
};
