import CheckIcon from '@mui/icons-material/Check';
import { Box, Divider, Paper, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { BackIconButton } from '../../component/button/BackIconButton';
import { MobileLayout } from '../../component/layout';
import { AppToolbar } from '../../component/layout/AppToolbar';
import { TenantLogo } from '../../component/layout/TenantLogo';
import { useBackNavigator } from '../../module/navigation/BackNavigator';
import { BENEFIT_PICTURES } from 'probonio-shared-ui/component/button';
interface TextProps {
  children: React.ReactNode;
}

const InfoText: React.FC = () => {
  const { t } = useTranslation('benefitModule');
  return (
    <Paper data-test-id="bike-info-intro" elevation={0} sx={{ paddingY: 0.3, width: '100%', paddingRight: 0.3 }}>
      <Box sx={{ ml: 0.8 }}>
        <Typography variant="description">{t('benefit.BIKE.infoPage.infoText')}</Typography>
      </Box>
    </Paper>
  );
};

const DesiredBike: React.FC = () => {
  const { t } = useTranslation('benefitModule');
  return (
    <Paper data-test-id="bike-info-desired-bike" elevation={0} sx={{ paddingY: 0.3, width: '100%', paddingRight: 0.3 }}>
      <Box sx={{ ml: 0.8 }}>
        <Typography variant="description">{t('benefit.BIKE.infoPage.desiredBike')}</Typography>
      </Box>
    </Paper>
  );
};

export const ReceiptAdvantagesText: React.FC<TextProps> = ({ children }) => {
  return (
    <Box marginLeft={0.3} marginRight={0.5} marginBottom={0.7} display="flex" alignItems={'center'} gap={0.5}>
      <CheckIcon color="success" />
      <Typography variant="body2">{children}</Typography>
    </Box>
  );
};

const ReceiptBenefits: React.FC = () => {
  const { t } = useTranslation('benefitModule');

  return (
    <Paper data-test-id="bike-receipt-advantages" elevation={0} sx={{ paddingY: 0.3, width: '100%' }}>
      <Box sx={{ ml: 0.8 }}>
        <Typography variant="descriptionBold">{t('benefit.BIKE.infoPage.howItWorks.name')}</Typography>
        <Divider variant={'fullWidth'} sx={{ marginRight: 0.3, marginBottom: 0.3, marginTop: 0.1 }} />
        <Typography variant={'description'} component="p" marginBottom={0.7}>
          {t('benefit.BIKE.infoPage.howItWorks.info')}
        </Typography>
        <ReceiptAdvantagesText>{t('benefit.BIKE.infoPage.howItWorks.calculateCosts')}</ReceiptAdvantagesText>
        <ReceiptAdvantagesText>{t('benefit.BIKE.infoPage.howItWorks.bikeLeasing')}</ReceiptAdvantagesText>
        <ReceiptAdvantagesText>{t('benefit.BIKE.infoPage.howItWorks.retailPartners')}</ReceiptAdvantagesText>
        <ReceiptAdvantagesText>{t('benefit.BIKE.infoPage.howItWorks.bikePickUp')}</ReceiptAdvantagesText>
        <ReceiptAdvantagesText>{t('benefit.BIKE.infoPage.howItWorks.leasingContract')}</ReceiptAdvantagesText>
      </Box>
    </Paper>
  );
};

const BikeInfoPage: React.FC = () => {
  const backNavigator = useBackNavigator();
  const { t } = useTranslation('benefitModule');

  return (
    <MobileLayout
      header={<AppToolbar title={t('benefit.BIKE.infoPage.header')} backButton={<BackIconButton onClick={backNavigator.back} />} />}
    >
      <Box
        display="flex"
        flexDirection="column"
        height={'auto'}
        justifyContent="space-between"
        alignItems="center"
        marginX={1}
        gap={0.8}
        marginBottom={0.7}
      >
        <Box
          data-test-id="bike-info-image"
          borderRadius={'8px'}
          component="img"
          width={'100%'}
          sx={{
            maxWidth: 410,
          }}
          src={BENEFIT_PICTURES.BIKE}
        />
        <InfoText />
        <DesiredBike />
        <ReceiptBenefits />
        <TenantLogo sx={{ width: '80%', textAlign: 'center' }} />
      </Box>
    </MobileLayout>
  );
};
export default BikeInfoPage;
