import 'probonio-shared-ui/module/error/sentry';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { createRoot } from 'react-dom/client';
import App from './App';
import { SnackbarProvider } from './component/snackbar';
import './index.css';
import './lang';
import '@tiffinger-thiel/share-target';

const root = createRoot(document.getElementById('root')!);
root.render(
  <SnackbarProvider>
    <App />
  </SnackbarProvider>,
);
