import { BikeleasingBenefitDetailsDTO, BenefitDTOBenefitEnum } from 'probonio-shared-ui/api';
import { useMemo } from 'react';
import { useActiveBenefitsQuery } from '../useActiveBenefitsQuery';

export function useBikeleasingDetails(): BikeleasingBenefitDetailsDTO | undefined {
  const { data } = useActiveBenefitsQuery();
  // const translateBoolean = useCallback((bool?: boolean) => t(`common:${bool}`), [t]);
  return useMemo(() => {
    return data?.benefits.find(benefit => benefit.benefit === BenefitDTOBenefitEnum.Bikeleasing)?.details as BikeleasingBenefitDetailsDTO;
  }, [data]);
}
