import { UseQueryResult, useMutation, useQueryClient } from '@tanstack/react-query';
import {
  CouponDefinitionDTO,
  CouponDefinitionListDTO,
  CouponOrderDTO,
  CouponOrderListDTO,
  CouponsApiFindCouponDefinitionsRequest,
} from 'probonio-shared-ui/api';
import { apis, useEmployeeQuery, useTenantQuery } from 'probonio-shared-ui/module/api';
import { useCallback } from 'react';
import { useActiveEmployment } from '../../../../probonio-shared-ui/module/me';
import { LoadingFunction, useLoadingCallback } from '../../utils/loadingFunction';
import { UpdateCouponOrderRedeem } from '../couponsShared/redeem/CouponRedeemButton';

export function useCouponOrderQuery(couponOrderId: string): UseQueryResult<CouponOrderDTO, Error> {
  return useEmployeeQuery(['benefits', 'coupons', 'orders', couponOrderId, apis.coupons.getCouponOrder.name], params =>
    apis.coupons.getCouponOrder({ ...params, couponOrderId: couponOrderId! }).then(res => res.data),
  );
}

export function useCouponOrderListQuery(): UseQueryResult<CouponOrderListDTO, Error> {
  return useEmployeeQuery(
    ['benefits', 'coupons', 'orders', apis.coupons.getCouponOrders.name],
    params => apis.coupons.getCouponOrders({ ...params }).then(res => res.data),
    { staleTime: 60000 },
  );
}

export function useInvalidateCouponOrders(): () => Promise<void> {
  const queryClient = useQueryClient();
  const getActiveEmployment = useActiveEmployment();

  return useCallback(() => {
    return queryClient.invalidateQueries({
      queryKey: ['tenants', getActiveEmployment().tenantId, 'employees', getActiveEmployment().id, 'benefits', 'coupons', 'orders'],
    });
  }, [getActiveEmployment, queryClient]);
}

export function useRedeemCouponMutation(couponOrder: CouponOrderDTO): LoadingFunction<(redeemConditions: UpdateCouponOrderRedeem) => void> {
  const getActiveEmployment = useActiveEmployment();
  const invalidateCouponOrders = useInvalidateCouponOrders();

  const mutation = useMutation({
    mutationFn: (redeemConditions: UpdateCouponOrderRedeem) =>
      apis.coupons.updateCouponOrder({
        tenantId: getActiveEmployment().tenantId,
        employeeId: getActiveEmployment().id,
        couponOrderId: couponOrder.id,
        updateCouponOrderDTO: {
          redeemed: redeemConditions.shouldBeRedeemed && !couponOrder.redeemDate,
          //set remainingAmount to orderAmount if the coupon would be redeemed
          remainingAmount:
            redeemConditions.shouldBeRedeemed && !couponOrder.redeemDate ? couponOrder.amount : redeemConditions.newRemainingAmount,
        },
      }),

    onSuccess: async () => {
      await invalidateCouponOrders();
    },
  });

  return useLoadingCallback(
    (redeemConditions: UpdateCouponOrderRedeem) => {
      mutation.mutate(redeemConditions);
    },
    [mutation],
    mutation.isPending,
  );
}

export function useCouponDefinitionQuery(couponDefinitionId?: string): UseQueryResult<CouponDefinitionDTO, Error> {
  return useTenantQuery(['benefits', 'coupons', 'definition', couponDefinitionId, apis.coupons.getCouponDefinition.name], tenantId => {
    if (!couponDefinitionId) {
      throw new Error('No coupon id');
    }
    return apis.coupons.getCouponDefinition({ tenantId, couponId: couponDefinitionId }).then(res => res.data);
  });
}

export function useCouponDefinitionListQuery(
  params: Omit<CouponsApiFindCouponDefinitionsRequest, 'tenantId'>,
): UseQueryResult<CouponDefinitionListDTO, Error> {
  return useTenantQuery(['benefits', 'coupons', 'definition', params, apis.coupons.findCouponDefinitions.name], tenantId =>
    apis.coupons
      .findCouponDefinitions({
        tenantId,
        ...params,
      })
      .then(res => res.data),
  );
}
