import { Box, Button, Typography } from '@mui/material';
import { CouponDefinitionDTO, CouponVariationAvailabilityDTOAvailabilityEnum } from 'probonio-shared-ui/api';
import { LoadingButton } from 'probonio-shared-ui/component/button/LoadingButton';
import { Trans, useTranslation } from 'react-i18next';
import { BasicDialog, DialogState } from '../../../component/dialog/BasicDialog';
import { LoadingFunction } from '../../../utils/loadingFunction';
import { useCallback } from 'react';
import { BasicAlert } from '../../../component/alert';

interface Props {
  dialogState: DialogState;
  coupon: CouponDefinitionDTO;
  count: number;
  cartState: Record<number, number>;
  availability?: CouponVariationAvailabilityDTOAvailabilityEnum;
  message?: React.ReactNode;
  onConfirm: LoadingFunction<(cartState: Record<number, number>) => void>;
}

export const ExploreCouponsV2DetailModal: React.FC<React.PropsWithChildren<Props>> = ({
  dialogState,
  coupon,
  count,
  cartState,
  availability,
  message,
  onConfirm,
  children,
}) => {
  const { t } = useTranslation('couponsV2Module');

  const handleConfirm = useCallback(() => {
    onConfirm(cartState);
  }, [cartState, onConfirm]);

  return (
    <BasicDialog
      title={t('exploreCoupons.details.confirmModal.title')}
      dialogState={dialogState}
      content={
        <Box>
          <Typography marginBottom={1}>
            {message || t('exploreCoupons.details.confirmModal.message')}{' '}
            {availability === CouponVariationAvailabilityDTOAvailabilityEnum.OnDemand &&
              t('exploreCoupons.details.confirmModal.onDemandWarning')}
            <Trans i18nKey="couponsV2Module:exploreCoupons.details.confirmModal.combinationsWarning" />
          </Typography>
          {coupon.redemptionAmountMaximal && count > coupon.redemptionAmountMaximal ? (
            <BasicAlert
              severity="info"
              sx={{
                mb: 1,
                // allow newlines in the translation
                whiteSpace: 'pre-wrap',
              }}
            >
              {t('exploreCoupons.details.confirmModal.redemptionAmountWarning', {
                name: coupon.name,
                redemptionAmountMaximal: coupon.redemptionAmountMaximal,
                count: count,
              })}
            </BasicAlert>
          ) : null}
          {children}
        </Box>
      }
      actions={
        <Box display="flex" justifyContent="space-between" width={'100%'}>
          <Button onClick={dialogState.handleClose} disabled={onConfirm.isLoading}>
            {t('exploreCoupons.details.confirmModal.cancel')}
          </Button>
          <LoadingButton onClick={handleConfirm} loading={onConfirm.isLoading} data-test-id="confirm-order">
            {t('exploreCoupons.details.confirmModal.confirm')}
          </LoadingButton>
        </Box>
      }
    />
  );
};
