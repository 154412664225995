/* tslint:disable */
/* eslint-disable */
/**
 * Probonio full API Docs
 * If you stumbled upon this page and want to integrate Probonio into your business software [click here!](/api/external)    If you\'re looking for more information about Probonio [head over here](https://probonio.de)    **This documentation describes all Endpoints of the Probonio API. It\'s created for experienced Probonio Developers only!**  
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { CreateLunchReceiptLineItemDTO } from './create-lunch-receipt-line-item-dto';

/**
 * 
 * @export
 * @interface CreateLunchReceiptDTO
 */
export interface CreateLunchReceiptDTO {
    /**
     * 
     * @type {number}
     * @memberof CreateLunchReceiptDTO
     */
    'total': number;
    /**
     * date of the receipt
     * @type {string}
     * @memberof CreateLunchReceiptDTO
     */
    'date': string;
    /**
     * 
     * @type {string}
     * @memberof CreateLunchReceiptDTO
     */
    'receiptType': CreateLunchReceiptDTOReceiptTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateLunchReceiptDTO
     */
    'comment'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateLunchReceiptDTO
     */
    'imageKey': string;
    /**
     * 
     * @type {string}
     * @memberof CreateLunchReceiptDTO
     */
    'pdfKey'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateLunchReceiptDTO
     */
    'thumbnailKey'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateLunchReceiptDTO
     */
    'ocrResultId'?: string;
    /**
     * 
     * @type {Array<CreateLunchReceiptLineItemDTO>}
     * @memberof CreateLunchReceiptDTO
     */
    'lineItems'?: Array<CreateLunchReceiptLineItemDTO>;
}

export const CreateLunchReceiptDTOReceiptTypeEnum = {
    Single: 'SINGLE',
    Group: 'GROUP',
    Mixed: 'MIXED'
} as const;

export type CreateLunchReceiptDTOReceiptTypeEnum = typeof CreateLunchReceiptDTOReceiptTypeEnum[keyof typeof CreateLunchReceiptDTOReceiptTypeEnum];


