import { useQueryClient } from '@tanstack/react-query';
import { useTenantID } from 'probonio-shared-ui/module/me';
import { useCallback } from 'react';

export const useInvalidateArticle = (employeeId?: string, articleId?: string): (() => Promise<void>) => {
  const queryClient = useQueryClient();
  const getTenantId = useTenantID();

  return useCallback(async () => {
    if (!articleId || !employeeId) {
      return;
    }
    await queryClient.invalidateQueries({
      queryKey: ['tenants', getTenantId(), 'employees', employeeId, 'benefits', 'news', 'articles', articleId],
    });
  }, [queryClient, getTenantId, employeeId, articleId]);
};
