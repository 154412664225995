import { createContext, useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

interface BackNavigatorState {
  back: () => void;
}

const BackNavigatorContext = createContext<BackNavigatorState | undefined>(undefined);

interface Props {
  children: React.ReactNode;
}

export const useBackNavigator = (): BackNavigatorState => {
  const backNavigatorState = useContext(BackNavigatorContext);

  if (backNavigatorState === undefined) {
    throw new Error('BackNavigatorContext is not injected');
  }

  return backNavigatorState;
};

export const BackNavigatorProvider: React.FC<Props> = ({ children }) => {
  const navigate = useNavigate();

  return (
    <BackNavigatorContext.Provider
      value={useMemo(
        () => ({
          back: () => navigate(-1),
        }),
        [navigate],
      )}
    >
      {children}
    </BackNavigatorContext.Provider>
  );
};
