import { Clipboard } from '@capacitor/clipboard';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Box, Button, Divider, IconButton, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { VoucherDetailsDTO } from 'probonio-shared-ui/api';
import { useWithMessage } from 'probonio-shared-ui/module/error';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

export const CouponDetailsLine: React.FC<{
  title: string;
  value: string;
  copy?: boolean;
  link?: string;
  voucherDetails?: VoucherDetailsDTO;
}> = ({ title, value, copy, link }) => {
  const { t } = useTranslation('couponsModule');
  const { enqueueSnackbar } = useSnackbar();
  const withMessage = useWithMessage();
  const handleCopy = useCallback(() => {
    Clipboard.write({ label: title, string: value })
      .then(() => enqueueSnackbar(t('couponOrderDetails.copiedToast'), { variant: 'success' }))
      .catch(withMessage);
  }, [title, value, withMessage, enqueueSnackbar, t]);

  return (
    <>
      <Divider sx={{ marginY: 0.5, display: 'none', '.coupon-details-line + &': { display: 'block' } }} />
      <Box sx={{ display: 'flex' }} className="coupon-details-line">
        <Box sx={{ flexGrow: 1, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} data-test-id="coupon-details">
          {!link && (
            <>
              <Typography variant="body2" color="text.secondary">
                {title}
              </Typography>
              <Typography variant="caption" className="selectable">
                {value}
              </Typography>
            </>
          )}
        </Box>
        {copy && (
          <Box>
            <IconButton title={t('couponOrderDetails.copyToClipboard')} onClick={handleCopy}>
              <ContentCopyIcon />
            </IconButton>
          </Box>
        )}
        {link && (
          <Button endIcon={<OpenInNewIcon />} fullWidth href={link} target="_blank" rel="noreferrer" color="secondary" variant="contained">
            {value}
          </Button>
        )}
      </Box>
    </>
  );
};
