import { Box, Button, Card, InputAdornment, Stack, Typography } from '@mui/material';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TextFieldControl } from '../../component/form/TextFieldControl';
import PhoneIcon from '@mui/icons-material/Phone';

interface Props {
  onSubmit: (values: PhoneFormValues) => void;
}

export interface PhoneFormValues {
  phoneNumber: string;
}

export const BAVPhoneContactDialog: React.FC<Props> = ({ onSubmit }) => {
  const { t } = useTranslation('bavModule', { keyPrefix: 'requestPhone' });
  const { control, handleSubmit } = useForm<PhoneFormValues>({});

  return (
    <Box component="form" flexDirection="column" display="flex" flexGrow={1} justifyContent={'center'} onSubmit={handleSubmit(onSubmit)}>
      <Card sx={{ padding: 1, marginBottom: 2 }}>
        <Stack spacing={1}>
          <Typography align="center">{t('text')}</Typography>
          <TextFieldControl
            control={control}
            name="phoneNumber"
            type="tel"
            label={t('phoneLabel')}
            size="small"
            fullWidth
            rules={{ required: true }}
            slotProps={{
              input: {
                startAdornment: (
                  <InputAdornment position="start">
                    <PhoneIcon color="primary" />
                  </InputAdornment>
                ),
              },
            }}
          />
          <Button type="submit" variant="contained" color="primary" fullWidth>
            {t('submitButton')}
          </Button>
        </Stack>
      </Card>
    </Box>
  );
};
