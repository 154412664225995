/* tslint:disable */
/* eslint-disable */
/**
 * Probonio full API Docs
 * If you stumbled upon this page and want to integrate Probonio into your business software [click here!](/api/external)    If you\'re looking for more information about Probonio [head over here](https://probonio.de)    **This documentation describes all Endpoints of the Probonio API. It\'s created for experienced Probonio Developers only!**  
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface UpdateLunchReceiptDTO
 */
export interface UpdateLunchReceiptDTO {
    /**
     * 
     * @type {number}
     * @memberof UpdateLunchReceiptDTO
     */
    'total'?: number;
    /**
     * date of the receipt in
     * @type {string}
     * @memberof UpdateLunchReceiptDTO
     */
    'date'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateLunchReceiptDTO
     */
    'receiptType'?: UpdateLunchReceiptDTOReceiptTypeEnum;
    /**
     * Only for Superadmins
     * @type {boolean}
     * @memberof UpdateLunchReceiptDTO
     */
    'skipped'?: boolean;
    /**
     * Only for Superadmins
     * @type {string}
     * @memberof UpdateLunchReceiptDTO
     */
    'status'?: UpdateLunchReceiptDTOStatusEnum;
    /**
     * Only for Superadmins
     * @type {string}
     * @memberof UpdateLunchReceiptDTO
     */
    'rejectionReason'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateLunchReceiptDTO
     */
    'comment'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateLunchReceiptDTO
     */
    'imageKey'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateLunchReceiptDTO
     */
    'pdfKey'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateLunchReceiptDTO
     */
    'thumbnailKey'?: string;
}

export const UpdateLunchReceiptDTOReceiptTypeEnum = {
    Single: 'SINGLE',
    Group: 'GROUP',
    Mixed: 'MIXED'
} as const;

export type UpdateLunchReceiptDTOReceiptTypeEnum = typeof UpdateLunchReceiptDTOReceiptTypeEnum[keyof typeof UpdateLunchReceiptDTOReceiptTypeEnum];
export const UpdateLunchReceiptDTOStatusEnum = {
    Approved: 'APPROVED',
    Rejected: 'REJECTED',
    Pending: 'PENDING',
    WaitingForAudit: 'WAITING_FOR_AUDIT'
} as const;

export type UpdateLunchReceiptDTOStatusEnum = typeof UpdateLunchReceiptDTOStatusEnum[keyof typeof UpdateLunchReceiptDTOStatusEnum];


