import { Capacitor } from '@capacitor/core';
import { Box, useTheme } from '@mui/material';
import { Ref } from 'react';

interface Props {
  header?: React.ReactNode;
  paperBackground?: boolean;
  backgroundColor?: string;

  /**
   * Changes the top color on the ios overload scroll.
   */
  iosOverloadScrollBackground?: string;
  padded?: boolean;
  paddedHeader?: boolean;
  paddedBody?: boolean;
  scrollRef?: Ref<HTMLDivElement>;
}

export const MobileLayout: React.FC<React.PropsWithChildren<Props>> = ({
  header,
  paperBackground: paper = false,
  backgroundColor,
  padded,
  paddedHeader,
  paddedBody,
  iosOverloadScrollBackground,
  scrollRef,
  children,
}) => {
  const extraPaddingTop = Capacitor.getPlatform() === 'ios' ? 2 : 0;
  const theme = useTheme();
  const defaultBackground = paper ? theme.palette.background.paper : theme.palette.background.default;
  const background = backgroundColor || defaultBackground;

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
        padding: padded ? 1 : 0,
        paddingTop: padded ? 1 + extraPaddingTop : 0,
        background,
        [theme.breakpoints.up('sm')]: {
          width: '450px',
          marginX: 'auto',
        },
        position: 'relative',
      }}
    >
      {header && (
        <Box
          sx={{
            paddingX: paddedHeader ? 1 : 0,
            paddingTop: paddedHeader ? 1 + extraPaddingTop : 0,
            display: 'flex',
            flexDirection: 'column',
            flexShrink: 0,
          }}
        >
          {header}
        </Box>
      )}
      <Box
        ref={scrollRef}
        sx={{
          padding: paddedBody ? '0px 20px 20px 20px' : 0,
          flexGrow: 1,
          overflow: 'auto',
          ...(iosOverloadScrollBackground
            ? {
                background: `linear-gradient(0deg, ${background} 49%, ${iosOverloadScrollBackground} 51%)`,
              }
            : {
                background,
              }),
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {iosOverloadScrollBackground && <Box sx={{ flexGrow: 1, background }}>{children}</Box>}
        {!iosOverloadScrollBackground && children}
      </Box>
    </Box>
  );
};
