import { Box, Card } from '@mui/material';
import { useCallback } from 'react';
import { getCouponImageUrl } from '../checkCouponImageUrl';

interface Props {
  id: string;
  name: string;
  imageUrl: string;
  productNumber?: string;
  onCardClick: (id: string) => void;
}

const ExploreCouponsItem: React.FC<Props> = ({ id, imageUrl, productNumber, onCardClick }) => {
  const handleCardClick = useCallback(() => {
    onCardClick(id);
  }, [onCardClick, id]);

  const { url, isSuccess } = getCouponImageUrl({ imageUrl, productNumber });

  return (
    <Card onClick={handleCardClick} sx={{ cursor: 'pointer' }} data-test-id={`explore-coupons-item-${productNumber}`}>
      <Box
        width={'100%'}
        sx={{
          backgroundImage: `url(${url})`,
          backgroundSize: isSuccess ? 'cover' : 'contain',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          paddingTop: '61.11%',
        }}
      />
    </Card>
  );
};

export default ExploreCouponsItem;
