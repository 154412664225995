import { StorageBackend } from '@openid/appauth';

const APPAUTH_KEY_MARKER = '_appauth_';
const AUTH_REFRESH_TOKEN_KEY = 'AUTH_REFRESH_TOKEN';

/**
 * Modified LocalStorageBackend that only clears the keys relevant to AppAuth
 */
export class AuthLocalStorageBackend extends StorageBackend {
  private storage: Storage;

  public constructor() {
    super();
    this.storage = window.localStorage;
  }

  // eslint-disable-next-line @rushstack/no-new-null
  public getItem(name: string): Promise<string | null> {
    return new Promise<string | null>((resolve, reject) => {
      const value = this.storage.getItem(name);
      if (value) {
        resolve(value);
      } else {
        resolve(null);
      }
    });
  }

  public removeItem(name: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.storage.removeItem(name);
      resolve();
    });
  }

  public clear(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.storage.removeItem(AUTH_REFRESH_TOKEN_KEY);
      for (let index = this.storage.length - 1; index >= 0; index--) {
        const key = this.storage.key(index);
        if (key?.includes(APPAUTH_KEY_MARKER)) {
          this.storage.removeItem(key);
        }
      }
      resolve();
    });
  }

  public setItem(name: string, value: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.storage.setItem(name, value);
      resolve();
    });
  }
}
