import { Capacitor } from '@capacitor/core';
import { LocationLike } from '@openid/appauth';
import { authConfig } from '.';
import { CapacitorBrowserLocationLike } from './CapacitorBrowserLocationLike';

function getEnv(name: string): string {
  return (import.meta.env[name] || '').toString();
}

const locationLike: LocationLike = Capacitor.isNativePlatform()
  ? new CapacitorBrowserLocationLike(authConfig.redirectUrl, () => void 0)
  : window.location;

export function redirectToPasswordReset(): void {
  const urlTemplate = getEnv('VITE_AUTH_PW_CHANGE_URL');
  const finalUrl = urlTemplate
    .replace('%CLIENT_ID%', encodeURIComponent(authConfig.clientId))
    .replace('%REDIRECT_URL%', encodeURIComponent(authConfig.redirectUrl))
    .replace('%SCOPE%', encodeURIComponent(authConfig.scope));

  locationLike.assign(finalUrl);
}
