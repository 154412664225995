import { Box, FormControl, FormControlLabel, FormHelperText, FormLabel, Radio, RadioGroup, RadioGroupProps, Tooltip } from '@mui/material';
import React, { useMemo } from 'react';
import { FieldPath, FieldValues, useController, UseControllerProps } from 'react-hook-form';

export interface RadioControlOption {
  label: React.ReactNode;
  hint?: string;
  value: string | number;
  disabled?: boolean;
  tooltip?: string;
}

type Props<T extends FieldValues, TName extends FieldPath<T>> = UseControllerProps<T, TName> &
  Omit<RadioGroupProps, 'defaultValue'> & {
    label?: React.ReactNode;
    options: RadioControlOption[];
    helperText?: React.ReactNode;
    children?: React.ReactNode;
  };

export const RadioControl = <T extends FieldValues, TName extends FieldPath<T>>({
  name,
  label,
  rules,
  control,
  defaultValue,
  shouldUnregister,
  options,
  helperText,
  ...props
}: Props<T, TName>): JSX.Element => {
  const { field, fieldState } = useController({ name, rules, control, defaultValue, shouldUnregister });
  const id = useMemo(() => (Math.random() + 1).toString(36).substring(7), []);

  return (
    <FormControl required error={fieldState.invalid}>
      {label && <FormLabel id={`${id}-label`}>{label}</FormLabel>}
      <RadioGroup {...field} {...props} aria-labelledby={label ? `${id}-label` : undefined} id={id}>
        {options?.map(option => (
          <Box key={option.value}>
            <Tooltip title={option.tooltip}>
              <span>
                {/** Span is needed for the tooltip to work on disabled buttons */}
                <FormControlLabel value={option.value} control={<Radio disabled={option.disabled} />} label={option.label} />
              </span>
            </Tooltip>
            {option.hint && <FormHelperText>{option.hint}</FormHelperText>}
          </Box>
        ))}
      </RadioGroup>
      {helperText && !fieldState.error && <FormHelperText>{helperText}</FormHelperText>}
      {fieldState.error && <FormHelperText>{fieldState.error.message}</FormHelperText>}
    </FormControl>
  );
};
