import { CircularProgress } from '@mui/material';
import React from 'react';
import MessageLayout from './MessageLayout';

interface Props {
  backdrop?: boolean;
}

export const LoadingLayout: React.FC<Props> = () => {
  return <MessageLayout actions={<CircularProgress />} />;
};
