import { useMemo } from 'react';
import { useCouponOrderListQuery } from '../coupons/couponQueries';
import { useCouponsV2UserOrderListQuery } from '../couponsV2/couponsV2Queries';
import { CouponItemModel } from './orderList/couponItemModel';
import { DateTime } from 'luxon';

function mapStatusSort(couponItem: CouponItemModel): number {
  if (couponItem.status === 'PENDING' || couponItem.status === 'ORDERED') {
    return 0;
  } else if (couponItem.status === 'COMPLETED' && !couponItem.redeemDate) {
    return 1;
  }
  return 2;
}

export function useCombinedCouponOrderListQuery(): { data: CouponItemModel[]; isLoading: boolean } {
  const { data: couponV1OrdersList, isLoading: isV1Loading } = useCouponOrderListQuery();
  const { data: couponV2OrdersList, isLoading: isV2Loading } = useCouponsV2UserOrderListQuery();

  const toMinutes = (date: string): number => DateTime.fromISO(date).startOf('minute').toMillis();

  const couponOrderList = useMemo(
    () =>
      [
        ...(couponV1OrdersList?.couponOrders || []).map<CouponItemModel>(res => ({ ...res, version: 'V1' })),
        ...(couponV2OrdersList?.results || []).map<CouponItemModel>(res => ({ ...res, version: 'V2' })),
      ].sort(
        (a, b) => mapStatusSort(a) - mapStatusSort(b) || toMinutes(b.orderDate) - toMinutes(a.orderDate) || a.orderIndex - b.orderIndex,
      ),
    [couponV1OrdersList?.couponOrders, couponV2OrdersList?.results],
  );

  return useMemo(
    () => ({
      data: couponOrderList,
      isLoading: isV1Loading || isV2Loading,
    }),
    [couponOrderList, isV1Loading, isV2Loading],
  );
}
