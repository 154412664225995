/* tslint:disable */
/* eslint-disable */
/**
 * Probonio full API Docs
 * If you stumbled upon this page and want to integrate Probonio into your business software [click here!](/api/external)    If you\'re looking for more information about Probonio [head over here](https://probonio.de)    **This documentation describes all Endpoints of the Probonio API. It\'s created for experienced Probonio Developers only!**  
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CreateLegalTermsConsentLogDTO } from '../dto';
// @ts-ignore
import { CreateLegalTermsDTO } from '../dto';
// @ts-ignore
import { IdentifierDTO } from '../dto';
// @ts-ignore
import { LegalTermsDTO } from '../dto';
// @ts-ignore
import { ListLegalTermsDTO } from '../dto';
// @ts-ignore
import { UploadLinkDTO } from '../dto';
/**
 * LegalTermsApi - axios parameter creator
 * @export
 */
export const LegalTermsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Creates new legal terms
         * @param {CreateLegalTermsDTO} createLegalTermsDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLegalTerms: async (createLegalTermsDTO: CreateLegalTermsDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createLegalTermsDTO' is not null or undefined
            assertParamExists('createLegalTerms', 'createLegalTermsDTO', createLegalTermsDTO)
            const localVarPath = `/legalTerms`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createLegalTermsDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create new legal terms consent log for a tenant
         * @param {string} tenantId 
         * @param {CreateLegalTermsConsentLogDTO} createLegalTermsConsentLogDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLegalTermsConsentLog: async (tenantId: string, createLegalTermsConsentLogDTO: CreateLegalTermsConsentLogDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('createLegalTermsConsentLog', 'tenantId', tenantId)
            // verify required parameter 'createLegalTermsConsentLogDTO' is not null or undefined
            assertParamExists('createLegalTermsConsentLog', 'createLegalTermsConsentLogDTO', createLegalTermsConsentLogDTO)
            const localVarPath = `/tenants/{tenantId}/legalTermsConsentLogs`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createLegalTermsConsentLogDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates an upload link for legal terms
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLegalTermsUploadLink: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/legalTerms/createUploadLink`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets latest legal terms
         * @param {'TENANT_TERMS' | 'USER_TERMS'} legalType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLatestLegalTerms: async (legalType: 'TENANT_TERMS' | 'USER_TERMS', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'legalType' is not null or undefined
            assertParamExists('getLatestLegalTerms', 'legalType', legalType)
            const localVarPath = `/legalTerms/latest`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (legalType !== undefined) {
                localVarQueryParameter['legalType'] = legalType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Lists all legal terms
         * @param {'TENANT_TERMS' | 'USER_TERMS'} [legalType] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLegalTerms: async (legalType?: 'TENANT_TERMS' | 'USER_TERMS', page?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/legalTerms`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (legalType !== undefined) {
                localVarQueryParameter['legalType'] = legalType;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LegalTermsApi - functional programming interface
 * @export
 */
export const LegalTermsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LegalTermsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Creates new legal terms
         * @param {CreateLegalTermsDTO} createLegalTermsDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createLegalTerms(createLegalTermsDTO: CreateLegalTermsDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdentifierDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createLegalTerms(createLegalTermsDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create new legal terms consent log for a tenant
         * @param {string} tenantId 
         * @param {CreateLegalTermsConsentLogDTO} createLegalTermsConsentLogDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createLegalTermsConsentLog(tenantId: string, createLegalTermsConsentLogDTO: CreateLegalTermsConsentLogDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createLegalTermsConsentLog(tenantId, createLegalTermsConsentLogDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates an upload link for legal terms
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createLegalTermsUploadLink(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadLinkDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createLegalTermsUploadLink(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets latest legal terms
         * @param {'TENANT_TERMS' | 'USER_TERMS'} legalType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLatestLegalTerms(legalType: 'TENANT_TERMS' | 'USER_TERMS', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LegalTermsDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLatestLegalTerms(legalType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Lists all legal terms
         * @param {'TENANT_TERMS' | 'USER_TERMS'} [legalType] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLegalTerms(legalType?: 'TENANT_TERMS' | 'USER_TERMS', page?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListLegalTermsDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLegalTerms(legalType, page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LegalTermsApi - factory interface
 * @export
 */
export const LegalTermsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LegalTermsApiFp(configuration)
    return {
        /**
         * 
         * @summary Creates new legal terms
         * @param {CreateLegalTermsDTO} createLegalTermsDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLegalTerms(createLegalTermsDTO: CreateLegalTermsDTO, options?: any): AxiosPromise<IdentifierDTO> {
            return localVarFp.createLegalTerms(createLegalTermsDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create new legal terms consent log for a tenant
         * @param {string} tenantId 
         * @param {CreateLegalTermsConsentLogDTO} createLegalTermsConsentLogDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLegalTermsConsentLog(tenantId: string, createLegalTermsConsentLogDTO: CreateLegalTermsConsentLogDTO, options?: any): AxiosPromise<object> {
            return localVarFp.createLegalTermsConsentLog(tenantId, createLegalTermsConsentLogDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates an upload link for legal terms
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLegalTermsUploadLink(options?: any): AxiosPromise<UploadLinkDTO> {
            return localVarFp.createLegalTermsUploadLink(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets latest legal terms
         * @param {'TENANT_TERMS' | 'USER_TERMS'} legalType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLatestLegalTerms(legalType: 'TENANT_TERMS' | 'USER_TERMS', options?: any): AxiosPromise<LegalTermsDTO> {
            return localVarFp.getLatestLegalTerms(legalType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Lists all legal terms
         * @param {'TENANT_TERMS' | 'USER_TERMS'} [legalType] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLegalTerms(legalType?: 'TENANT_TERMS' | 'USER_TERMS', page?: number, pageSize?: number, options?: any): AxiosPromise<ListLegalTermsDTO> {
            return localVarFp.getLegalTerms(legalType, page, pageSize, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createLegalTerms operation in LegalTermsApi.
 * @export
 * @interface LegalTermsApiCreateLegalTermsRequest
 */
export interface LegalTermsApiCreateLegalTermsRequest {
    /**
     * 
     * @type {CreateLegalTermsDTO}
     * @memberof LegalTermsApiCreateLegalTerms
     */
    readonly createLegalTermsDTO: CreateLegalTermsDTO
}

/**
 * Request parameters for createLegalTermsConsentLog operation in LegalTermsApi.
 * @export
 * @interface LegalTermsApiCreateLegalTermsConsentLogRequest
 */
export interface LegalTermsApiCreateLegalTermsConsentLogRequest {
    /**
     * 
     * @type {string}
     * @memberof LegalTermsApiCreateLegalTermsConsentLog
     */
    readonly tenantId: string

    /**
     * 
     * @type {CreateLegalTermsConsentLogDTO}
     * @memberof LegalTermsApiCreateLegalTermsConsentLog
     */
    readonly createLegalTermsConsentLogDTO: CreateLegalTermsConsentLogDTO
}

/**
 * Request parameters for getLatestLegalTerms operation in LegalTermsApi.
 * @export
 * @interface LegalTermsApiGetLatestLegalTermsRequest
 */
export interface LegalTermsApiGetLatestLegalTermsRequest {
    /**
     * 
     * @type {'TENANT_TERMS' | 'USER_TERMS'}
     * @memberof LegalTermsApiGetLatestLegalTerms
     */
    readonly legalType: 'TENANT_TERMS' | 'USER_TERMS'
}

/**
 * Request parameters for getLegalTerms operation in LegalTermsApi.
 * @export
 * @interface LegalTermsApiGetLegalTermsRequest
 */
export interface LegalTermsApiGetLegalTermsRequest {
    /**
     * 
     * @type {'TENANT_TERMS' | 'USER_TERMS'}
     * @memberof LegalTermsApiGetLegalTerms
     */
    readonly legalType?: 'TENANT_TERMS' | 'USER_TERMS'

    /**
     * 
     * @type {number}
     * @memberof LegalTermsApiGetLegalTerms
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof LegalTermsApiGetLegalTerms
     */
    readonly pageSize?: number
}

/**
 * LegalTermsApi - object-oriented interface
 * @export
 * @class LegalTermsApi
 * @extends {BaseAPI}
 */
export class LegalTermsApi extends BaseAPI {
    /**
     * 
     * @summary Creates new legal terms
     * @param {LegalTermsApiCreateLegalTermsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LegalTermsApi
     */
    public createLegalTerms(requestParameters: LegalTermsApiCreateLegalTermsRequest, options?: AxiosRequestConfig) {
        return LegalTermsApiFp(this.configuration).createLegalTerms(requestParameters.createLegalTermsDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create new legal terms consent log for a tenant
     * @param {LegalTermsApiCreateLegalTermsConsentLogRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LegalTermsApi
     */
    public createLegalTermsConsentLog(requestParameters: LegalTermsApiCreateLegalTermsConsentLogRequest, options?: AxiosRequestConfig) {
        return LegalTermsApiFp(this.configuration).createLegalTermsConsentLog(requestParameters.tenantId, requestParameters.createLegalTermsConsentLogDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates an upload link for legal terms
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LegalTermsApi
     */
    public createLegalTermsUploadLink(options?: AxiosRequestConfig) {
        return LegalTermsApiFp(this.configuration).createLegalTermsUploadLink(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets latest legal terms
     * @param {LegalTermsApiGetLatestLegalTermsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LegalTermsApi
     */
    public getLatestLegalTerms(requestParameters: LegalTermsApiGetLatestLegalTermsRequest, options?: AxiosRequestConfig) {
        return LegalTermsApiFp(this.configuration).getLatestLegalTerms(requestParameters.legalType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Lists all legal terms
     * @param {LegalTermsApiGetLegalTermsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LegalTermsApi
     */
    public getLegalTerms(requestParameters: LegalTermsApiGetLegalTermsRequest = {}, options?: AxiosRequestConfig) {
        return LegalTermsApiFp(this.configuration).getLegalTerms(requestParameters.legalType, requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }
}
