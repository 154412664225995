import { Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useCouponsV2BenefitDetails } from '../couponsV2Queries';

export const CouponsV2TokenStatus: React.FC = () => {
  const { t } = useTranslation('couponsV2Module');
  const benefitDetails = useCouponsV2BenefitDetails();

  return (
    <Typography variant="body1" color="text.secondary" mx={2}>
      {benefitDetails?.activatedNextMonth
        ? t('userTokens.infoAvailable', { amount: benefitDetails!.couponMonthlyBudget })
        : t('userTokens.infoNotAvailable')}
    </Typography>
  );
};
