import { Box, CircularProgress, Divider, List, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { LunchReceiptRefundDTO } from 'probonio-shared-ui/api';
import { Receipt } from '../benefit/receipt/Receipt';
import { useLunchPeriods } from './LunchPeriodsContext';

interface Props {
  lunchPeriod: string;
}

export const LunchReceiptList: React.FC<Props> = ({ lunchPeriod }) => {
  const { t, i18n } = useTranslation('benefitModule');

  const {
    receiptsQuery: { isSuccess, data },
    selectedPeriodMonth: selectedPeriod,
  } = useLunchPeriods();

  const open = selectedPeriod === lunchPeriod;

  // Cache the receipts for the selected period, so that if another period
  // gets selected, it won't reset the list.
  const [receipts, setReceipts] = React.useState<LunchReceiptRefundDTO[]>();
  useEffect(() => {
    if (isSuccess && data && open) {
      setReceipts(data.receipts);
    }
  }, [data, isSuccess, open]);

  return (
    <List disablePadding>
      {!receipts && (
        <Box display="flex" justifyContent="center" marginBottom={0.5} px={1}>
          <CircularProgress />
        </Box>
      )}
      {receipts &&
        receipts.map(receipt => (
          <Box key={receipt.id}>
            <Divider sx={{ mx: 1 }} />
            <Receipt
              linkTo={`/lunch/receipt/view/${lunchPeriod}/${receipt.id}`}
              amountPaid={t('common:money', { money: receipt.refund })}
              receiptStatus={receipt.status}
              date={i18n.format(new Date(receipt.date), 'date')}
            />
          </Box>
        ))}
      {isSuccess && open && (!receipts || receipts.length === 0) && (
        <>
          <Divider sx={{ mb: 1, mx: 1 }} />
          <Typography sx={{ mb: 1, mx: 1 }}>{t('benefit.LUNCH.noReceipt')}</Typography>
        </>
      )}
    </List>
  );
};
