import { apis, useEmployeeQuery } from 'probonio-shared-ui/module/api';
import { SentryRoutes } from 'probonio-shared-ui/module/error/sentry';
import { useTenant } from 'probonio-shared-ui/module/me';
import { Route } from 'react-router-dom';
import { BAVCalculator } from './BAVCalculator';
import { BAVProvider } from './BAVContext';
import { BAVContractPage } from './BAVContractPage';
import BAVInfoPage from './BAVInfoPage';
import BAVLandingPage from './BAVLandingPage';
import BAVMeetingPage from './BAVMeetingPage';
import BAVNotInterestedPage from './BAVNotInterestedPage';
import BAVTermsPage from './BAVTermsPage';

export const BAVRoutes: React.FC = () => {
  const { data: contractData, isLoading } = useEmployeeQuery(['bav', apis.bav.listContracts], params =>
    apis.bav.listContracts({ ...params }).then(res => res.data.results),
  );

  const { tenant } = useTenant();

  return (
    <BAVProvider>
      <SentryRoutes>
        {!isLoading && contractData && contractData.length !== 0 ? (
          <Route path="/" element={<BAVContractPage contract={contractData[contractData.length - 1]} />} />
        ) : (
          <Route path="/" element={<BAVLandingPage />} />
        )}
        <Route path="/info" element={<BAVInfoPage />} />
        <Route path="/calculator" element={<BAVCalculator />} />
        {tenant?.bavShowTerms && <Route path="/terms" element={<BAVTermsPage />} />}
        <Route path="/meeting" element={<BAVMeetingPage />} />
        <Route path="/notinterested" element={<BAVNotInterestedPage />} />
      </SentryRoutes>
    </BAVProvider>
  );
};
