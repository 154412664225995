/* tslint:disable */
/* eslint-disable */
/**
 * Probonio full API Docs
 * If you stumbled upon this page and want to integrate Probonio into your business software [click here!](/api/external)    If you\'re looking for more information about Probonio [head over here](https://probonio.de)    **This documentation describes all Endpoints of the Probonio API. It\'s created for experienced Probonio Developers only!**  
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { BenefitDTODetails } from './benefit-dtodetails';
import { BenefitStatisticsDTO } from './benefit-statistics-dto';

/**
 * 
 * @export
 * @interface BenefitDTO
 */
export interface BenefitDTO {
    /**
     * 
     * @type {string}
     * @memberof BenefitDTO
     */
    'benefit': BenefitDTOBenefitEnum;
    /**
     * 
     * @type {BenefitDTODetails}
     * @memberof BenefitDTO
     */
    'details': BenefitDTODetails;
    /**
     * 
     * @type {BenefitStatisticsDTO}
     * @memberof BenefitDTO
     */
    'statistics'?: BenefitStatisticsDTO;
}

export const BenefitDTOBenefitEnum = {
    Bav: 'BAV',
    Lunch: 'LUNCH',
    Coupons: 'COUPONS',
    CouponsV2: 'COUPONS_V2',
    Mobility: 'MOBILITY',
    Gifts: 'GIFTS',
    Internet: 'INTERNET',
    Bike: 'BIKE',
    News: 'NEWS',
    Flex: 'FLEX',
    Recreation: 'RECREATION',
    Bikeleasing: 'BIKELEASING',
    Custom: 'CUSTOM'
} as const;

export type BenefitDTOBenefitEnum = typeof BenefitDTOBenefitEnum[keyof typeof BenefitDTOBenefitEnum];


