import { Capacitor } from '@capacitor/core';
import { AuthorizationRequestHandler } from '@openid/appauth';
import { AuthenticateOptions, EndSessionRequestHandler, ErrorHandler } from '@tiffinger-thiel/appauth-react';
import { createDesktopAuthHandlers } from 'probonio-shared-ui/module/auth';
import { createMobileAuthHandlers } from './mobileAuthHandlers';

export function createPlatformAuthHandlers(
  authConfig: AuthenticateOptions,
  onError: ErrorHandler,
): {
  authHandler: AuthorizationRequestHandler;
  endSessionHandler: EndSessionRequestHandler;
} {
  const platform = Capacitor.getPlatform();

  switch (platform) {
    case 'android':
    case 'ios':
      return createMobileAuthHandlers(authConfig, onError);
    default:
      return createDesktopAuthHandlers();
  }
}
