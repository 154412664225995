import { Capacitor } from '@capacitor/core';
import { TextZoom } from '@capacitor/text-zoom';
import { useSnackbar } from 'notistack';
import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Provider } from 'react-redux';
import { BrowserRouter, HashRouter } from 'react-router-dom';
import { BackButtonListener } from './component/mobile/BackButtonListener';
import { APILoader } from 'probonio-shared-ui/module/api';
import { captureMessageError } from 'probonio-shared-ui/module/error/sentry';
import { MobileAuthProvider } from './module/auth';
import { MessageError } from 'probonio-shared-ui/module/error';
import { GlobalErrorCatcher, GlobalErrorCatcherWithLogout } from 'probonio-shared-ui/module/error';
import { BackNavigatorProvider } from './module/navigation/BackNavigator';
import { ShareTargetProvider } from './module/shareTarget/ShareTargetContext';
import AppRoutes from './page/AppRoutes';
import { store } from './redux/store';
import { ErrorFallback } from './module/error';
import { loadMe } from 'probonio-shared-ui/module/me';
import { VersionCheckListener } from './module/versionCheck/VersionCheckListener';
import UpdatePage from './page/UpdatePage';
import i18n from './lang';
import { PushNotificationRegistration } from './module/pushNotifications';
import { ThemeSwitcherProvider } from './module/theme/ThemeSwitcherProvider';
import { CssBaseline } from '@mui/material';

if (Capacitor.isNativePlatform()) {
  void TextZoom.set({ value: 1 }).catch(() => {});
}

const Router = Capacitor.getPlatform() === 'web' ? BrowserRouter : HashRouter;

const App: FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation('errorModule');

  /**
   * Shows the snackbar on MessageError. All other errors are ignored and handled by the
   * error boundary in GlobalErrorCatcher instead.
   * @param error
   * @returns {void}
   */
  const handleError = useCallback(
    (error: unknown): boolean | undefined => {
      if (error instanceof MessageError) {
        // eslint-disable-next-line no-console
        console.error(error.originalError);
        enqueueSnackbar(error.displayMessage || t('defaultMessage'), { variant: 'error' });
        if (!error.displayMessage) {
          // if no custom message is set, the error is unexpected and should be reported to Sentry
          captureMessageError(error.originalError);
        }
        return true;
      }
    },
    [enqueueSnackbar, t],
  );

  const handleLoadMe = useCallback(async (hasToken: boolean) => {
    await store.dispatch(loadMe(hasToken));
    const { me } = store.getState();
    if (me && me.user && me.user.locale) {
      void i18n.changeLanguage(me.user.locale);
    }
  }, []);

  return (
    <GlobalErrorCatcher errorHandler={handleError} ErrorFallback={ErrorFallback}>
      <Router>
        <BackNavigatorProvider>
          <Provider store={store}>
            <BackButtonListener />
            <MobileAuthProvider>
              <GlobalErrorCatcherWithLogout errorHandler={handleError} ErrorFallback={ErrorFallback}>
                <APILoader onLoginStateChange={handleLoadMe}>
                  <ThemeSwitcherProvider>
                    <CssBaseline />
                    <VersionCheckListener updatePage={<UpdatePage />}>
                      <PushNotificationRegistration>
                        <ShareTargetProvider>
                          <AppRoutes />
                        </ShareTargetProvider>
                      </PushNotificationRegistration>
                    </VersionCheckListener>
                  </ThemeSwitcherProvider>
                </APILoader>
              </GlobalErrorCatcherWithLogout>
            </MobileAuthProvider>
          </Provider>
        </BackNavigatorProvider>
      </Router>
    </GlobalErrorCatcher>
  );
};

export default App;
