import { BAVBenefitDetailsDTO, BenefitDTOBenefitEnum } from 'probonio-shared-ui/api';
import { apis, useEmployeeQuery } from 'probonio-shared-ui/module/api';
import { createContext, useContext, useMemo } from 'react';

export interface BAVState {
  bavDetails?: BAVBenefitDetailsDTO;
}

const BAVContext = createContext<BAVState | undefined>(undefined);

export const BAVProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { data: bavBenefit } = useEmployeeQuery(['benefits', 'bav', apis.benefits.getBenefit.name], params =>
    apis.benefits.getBenefit({ ...params, benefit: BenefitDTOBenefitEnum.Bav }).then(res => res.data),
  );

  const context = useMemo<BAVState>(
    () => ({
      bavDetails: bavBenefit?.details as BAVBenefitDetailsDTO,
    }),
    [bavBenefit?.details],
  );

  return <BAVContext.Provider value={context}>{children}</BAVContext.Provider>;
};

export function useBAV(): BAVState {
  const ctx = useContext(BAVContext);
  // Throw error that the Context is not injected, if it is undefined.
  if (ctx === undefined) {
    throw new Error('useBAV must be used within a BAVProvider');
  }
  return ctx;
}
