import { Box, Button, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { FeedbackApiSendFeedbackRequest } from 'probonio-shared-ui/api';
import { LoadingButton } from 'probonio-shared-ui/component/button/LoadingButton';
import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ButtonRendererProps, CameraButton } from '../../component/camera';
import { ImageFile } from '../../component/camera/ImageFile';
import { TextFieldControl } from '../../component/form/TextFieldControl';
import { VerticalBox } from '../../component/layout/BoxLayout';
import { useBackNavigator } from '../navigation/BackNavigator';
import { useFeedbackMutation } from './useFeedbackMutation';
import { FEEDBACK_MAX_IMAGE_SIZE } from '../lunch/lunchConstants';

const UploadImageButton: React.FC<ButtonRendererProps> = props => {
  const { t } = useTranslation('feedbackModule');
  return (
    <Button variant="outlined" fullWidth {...props}>
      {t('upload')}
    </Button>
  );
};

const UploadImageButtonAfterUpload: React.FC<ButtonRendererProps> = props => {
  const { t } = useTranslation('feedbackModule');
  return (
    <Button variant="outlined" fullWidth {...props}>
      {t('uploadSelected')}
    </Button>
  );
};

type FeedbackFields = Pick<FeedbackApiSendFeedbackRequest, 'subject' | 'message' | 'file'>;

export const FeedbackForm: React.FC = () => {
  const { t } = useTranslation('feedbackModule');
  const backNavigator = useBackNavigator();
  const { enqueueSnackbar } = useSnackbar();
  const [imageFile, setImageFile] = useState<ImageFile>();
  const [cameraButtonRenderer, setCameraButtonRenderer] = useState(false);
  const handleClose = useCallback(() => {
    backNavigator.back();
  }, [backNavigator]);

  const mutation = useFeedbackMutation(handleClose);

  const { control, getValues, handleSubmit } = useForm<FeedbackFields>({
    defaultValues: {
      subject: '',
      message: '',
    },
  });

  const handleImageChange = useCallback(
    async (image: ImageFile) => {
      const getImage = await image.getBlob();
      const imageSizeInKB = getImage.size / 1024;
      const roundedImageSizeInKB = Math.round(imageSizeInKB);

      if (roundedImageSizeInKB >= FEEDBACK_MAX_IMAGE_SIZE) {
        enqueueSnackbar(t('uploadSelectedError'), { variant: 'error' });
        return;
      }

      setImageFile(image);
      setCameraButtonRenderer(true);
      enqueueSnackbar(t('uploadSelectedInfo'), { variant: 'info' });
    },
    [enqueueSnackbar, setImageFile, t],
  );

  const handleSubmitFeedback = useCallback(() => {
    mutation.mutate({ subject: getValues('subject'), message: getValues('message'), imageFile: imageFile });
  }, [getValues, imageFile, mutation]);

  return (
    <form onSubmit={handleSubmit(handleSubmitFeedback)}>
      <TextFieldControl
        control={control}
        name="subject"
        fullWidth
        variant="outlined"
        margin="dense"
        label={t('subject')}
        rules={{ required: true }}
      />
      <TextFieldControl
        control={control}
        name="message"
        fullWidth
        variant="outlined"
        margin="dense"
        label={t('message')}
        multiline
        rows={4}
        rules={{ required: true }}
      />
      <Box marginTop={1}>
        {imageFile && <Typography>{imageFile.filename}</Typography>}
        <CameraButton
          uploadOnly
          buttonRenderer={cameraButtonRenderer ? UploadImageButtonAfterUpload : UploadImageButton}
          onSelect={handleImageChange}
        />
      </Box>

      <VerticalBox paddingTop={1}>
        <LoadingButton variant="contained" type="submit" loading={mutation.isPending}>
          {t('submitFeedback')}
        </LoadingButton>
      </VerticalBox>
    </form>
  );
};
