import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { apis } from 'probonio-shared-ui/module/api';
import { APP_VERSION } from '../../constants';

export interface VersionCheckState {
  updateRequired?: boolean;
  isLoading?: boolean;
}

const initialState: VersionCheckState = {
  isLoading: true,
};

export const loadVersionCheck = createAsyncThunk('versionCheck/loadVersionCheck', async () => {
  return await apis.public.versionCheck({ version: APP_VERSION }).then(res => res.data);
});

export const versionCheckSlice = createSlice({
  name: 'versionCheck',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(loadVersionCheck.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(loadVersionCheck.fulfilled, (state, action) => {
      state.isLoading = false;
      state.updateRequired = action.payload.updateRequired;
    });
    builder.addCase(loadVersionCheck.rejected, state => {
      state.isLoading = false;
      state.updateRequired = false;
    });
  },
});

export const versionCheckReducer = versionCheckSlice.reducer;
