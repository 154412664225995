import { UploadLinkDTO } from 'probonio-shared-ui/api';
import { apis } from 'probonio-shared-ui/module/api';
import { uploadFileToS3 } from 'probonio-shared-ui/module/api';

export interface UploadLinks {
  imageKey: string;
  pdfKey?: string;
}

export async function uploadInternetImageFile(tenantId: string, employeeId: string, imageFile: Blob, pdfFile?: Blob): Promise<UploadLinks> {
  const uploadLink = await apis.internet.createUploadLink({ tenantId, employeeId }).then(res => res.data);
  await uploadFileToS3(uploadLink, imageFile, imageFile.type);
  if (!pdfFile) {
    return { imageKey: uploadLink.key };
  }
  const uploadLinkPdf: UploadLinkDTO = await apis.internet.createUploadLink({ tenantId, employeeId }).then(res => res.data);
  await uploadFileToS3(uploadLinkPdf, pdfFile, pdfFile.type);

  return { imageKey: uploadLink.key, pdfKey: uploadLinkPdf.key };
}
