import { Divider, Paper, Stack, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { InternetReceiptRefundDTO } from 'probonio-shared-ui/api';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ConfirmationInternetButton } from './ConfirmationInternetButton';
import { DisableInternetButton } from './DisableInternetButton';

interface Props {
  currentReceipt: InternetReceiptRefundDTO;
}

export const CurrentReceiptPanel: React.FC<Props> = ({ currentReceipt }) => {
  const { t } = useTranslation('benefitModule');

  const nextConfirmationMonths = currentReceipt.expirationDate
    ? Math.max(0, Math.round(DateTime.fromISO(currentReceipt.expirationDate).diff(DateTime.now(), 'months').as('months')))
    : 0;

  return (
    <Stack spacing={1} mt={1} data-test-id="internet-flat-rate-info-box">
      <Paper sx={{ p: 1, textAlign: 'center' }}>
        <Typography variant="h3" textAlign="center" mb={1}>
          {t('benefitModule:benefit.INTERNET.activeTitle')}
        </Typography>
        <Typography color="text.secondary" mb={1}>
          <Trans i18nKey="benefitModule:benefit.INTERNET.routineInfo" values={{ monthlyAmount: currentReceipt.total }} />
        </Typography>
        {nextConfirmationMonths <= 4 && <ConfirmationInternetButton currentReceipt={currentReceipt} />}
        <Divider sx={{ my: 1 }} />
        <Typography variant="h3" data-test-id="expiration-date-text-primary">
          {t('benefit.INTERNET.confirmationDate', { count: nextConfirmationMonths })}
        </Typography>
      </Paper>
      <DisableInternetButton currentReceipt={currentReceipt} />
    </Stack>
  );
};
