import { Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { CouponDefinitionDTO } from 'probonio-shared-ui/api';
import { LoadingButton } from 'probonio-shared-ui/component/button/LoadingButton';
import { apis } from 'probonio-shared-ui/module/api';
import { useActiveEmployment } from 'probonio-shared-ui/module/me';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useAvailableCoupons } from './AvailableCouponsContext';

interface Props {
  couponDefinition: CouponDefinitionDTO;
}

export const ChangeDefaultCouponButton: React.FC<Props> = ({ couponDefinition }) => {
  const getActiveEmployment = useActiveEmployment();
  const { availableCoupons, couponsQuery, refetchAvailableCoupons } = useAvailableCoupons();
  const { t } = useTranslation('couponsModule');

  const mutationEmployee = useMutation({
    mutationFn: async () => {
      await apis.coupons.updateCouponEmployeeDefault({
        tenantId: getActiveEmployment().tenantId,
        employeeId: getActiveEmployment().id,
        updateEmployeeCouponDefaultDTO: {
          defaultCouponId: couponDefinition.id,
        },
      });
    },

    onSuccess: () => {
      refetchAvailableCoupons();
    },
  });
  const handleDefaultCouponChange = useCallback(() => {
    mutationEmployee.mutate();
  }, [mutationEmployee]);

  if (!availableCoupons?.currentlyActivated || !couponsQuery.data?.details.couponDefaultAllowed) {
    return null;
  }

  return (
    <>
      {couponDefinition.id === couponsQuery.data?.details.defaultCouponId ? (
        <Typography paddingTop={0.5} textAlign={'center'}>
          {t('exploreCoupons.details.defaultCouponSelectedMessage')}
        </Typography>
      ) : (
        <LoadingButton
          fullWidth
          variant="contained"
          color="secondary"
          loading={mutationEmployee.isPending}
          onClick={handleDefaultCouponChange}
        >
          {t('exploreCoupons.details.defaultCouponMessage')}
        </LoadingButton>
      )}
    </>
  );
};
