import DateRangeIcon from '@mui/icons-material/DateRange';
import { Box, Drawer, InputAdornment, InputLabel, TextField, Typography, useTheme } from '@mui/material';
import { DateTime } from 'luxon';
import {
  BenefitDTOBenefitEnum,
  LunchPeriodDTO,
  MobilityPeriodDTO,
  MobilityReceiptRefundDTOMobilityTypeEnum,
  UpdateLunchReceiptDTOReceiptTypeEnum,
  UpdateMobilityReceiptDTOMobilityTypeEnum,
  UpdateMobilityReceiptDTOTravelPurposeEnum,
} from 'probonio-shared-ui/api';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollingNumberRangePicker } from '../../../component/ScrollPicker/ScrollingNumberRangePicker';
import { ImageFile } from '../../../component/camera/ImageFile';
import { CalculatorFormField } from '../../../component/currencyInput/CalculatorFormField';
import { useCalculatorState } from '../../../component/currencyInput/useCalculatorState';
import { SelectImageField } from '../../../component/selectImageField/SelectImageField';
import { LunchReceiptTypes } from '../../lunch/addReceipt/LunchReceiptTypes';
import { MobilityTravelPurposes } from '../../mobility/addReceipt/MobilityTravelPurposes';
import { VALIDITY_MONTHS_MAX } from '../../mobility/addReceipt/MobilityTypeForm';
import { MobilityTypes } from '../../mobility/addReceipt/MobilityTypes';
import { useAvailableMobilityTypes } from '../../mobility/addReceipt/availableMobilityTypes';
import { PeriodDatePicker } from '../datePicker/PeriodDatePicker';
import { useLunchReceiptIsUsed } from '../datePicker/ReceiptExistsAlert';

interface Props {
  benefit: BenefitDTOBenefitEnum;
  previousPeriod?: MobilityPeriodDTO | LunchPeriodDTO;
  benefitPeriod: string;
  benefitPeriodDTO: MobilityPeriodDTO | LunchPeriodDTO;
  disabled?: boolean;
  values: EditReceiptFormValues;
  onChange: (values: EditReceiptFormValues) => void;
}

export interface EditReceiptFormValues {
  lunchReceiptType?: UpdateLunchReceiptDTOReceiptTypeEnum;
  mobilityType?: UpdateMobilityReceiptDTOMobilityTypeEnum;
  mobilityTravelPurpose?: UpdateMobilityReceiptDTOTravelPurposeEnum;
  mobilityValidityStartDate?: Date;
  mobilityValidityMonths?: number;
  total: number;
  date: Date;
  comment?: string;
  image?: string | ImageFile;
  pdf?: Blob;
}

export const EditReceiptForm: React.FC<Props> = ({
  benefit,
  previousPeriod,
  benefitPeriod: lunchPeriod,
  disabled,
  values,
  onChange,
  benefitPeriodDTO,
}) => {
  const { t, i18n } = useTranslation('benefitModule');
  const [dateOpen, setDateOpen] = useState(false);
  const [currencyOpen, setCurrencyOpen] = useState(false);
  const calculatorState = useCalculatorState();
  const lunchDateIsUsed = useLunchReceiptIsUsed(
    benefitPeriodDTO.month,
    previousPeriod && !previousPeriod.isLocked ? previousPeriod.month : undefined,
    { exclude: values.date },
  );
  const dateIsUsed = benefit === BenefitDTOBenefitEnum.Lunch ? lunchDateIsUsed : undefined;
  const theme = useTheme();

  const isTimeCard =
    benefit === BenefitDTOBenefitEnum.Mobility &&
    values.mobilityType === MobilityReceiptRefundDTOMobilityTypeEnum.TimeCard &&
    values.mobilityValidityMonths &&
    values.mobilityValidityMonths !== 1;

  const availableMobilityTypes = useAvailableMobilityTypes(
    benefitPeriodDTO as MobilityPeriodDTO,
    values.mobilityTravelPurpose,
    values.mobilityValidityMonths,
  );

  const handleChangeDate = useCallback(
    (date: Date) => {
      if (isTimeCard) {
        onChange({ ...values, mobilityValidityStartDate: date });
      } else {
        onChange({ ...values, date });
      }
      setDateOpen(false);
    },
    [isTimeCard, onChange, values],
  );
  const handleOpenDate = useCallback(() => setDateOpen(true), []);
  const handleCloseDate = useCallback(() => setDateOpen(false), []);
  const handleChangeLunchReceiptType = useCallback(
    (value: UpdateLunchReceiptDTOReceiptTypeEnum) => {
      if (value === null) {
        return;
      }
      onChange({ ...values, lunchReceiptType: value as UpdateLunchReceiptDTOReceiptTypeEnum });
    },
    [values, onChange],
  );

  const handleChangeMobilityType = useCallback(
    (value: string | null) => {
      if (value === null) {
        return;
      }
      onChange({ ...values, mobilityType: value as UpdateMobilityReceiptDTOMobilityTypeEnum });
    },
    [values, onChange],
  );

  const handleChangeMobilityTravelPurpose = useCallback(
    (value: string | null) => {
      if (value === null) {
        return;
      }
      onChange({ ...values, mobilityTravelPurpose: value as UpdateMobilityReceiptDTOTravelPurposeEnum });
    },
    [values, onChange],
  );
  const handleChangeMobilityValidityMonths = useCallback(
    (value: number) => {
      const minStartDate = DateTime.utc()
        .startOf('month')
        .minus({ months: value - 1 });
      if (value === 1) {
        onChange({ ...values, mobilityValidityMonths: value, mobilityValidityStartDate: undefined });
      } else if (!values.mobilityValidityStartDate || DateTime.fromJSDate(values.mobilityValidityStartDate) < minStartDate) {
        onChange({ ...values, mobilityValidityMonths: value, mobilityValidityStartDate: minStartDate.toJSDate() });
      } else {
        onChange({ ...values, mobilityValidityMonths: value });
      }
    },
    [onChange, values],
  );

  const handleChangeTotal = useCallback(() => {
    if (calculatorState.calculatorResult === 0) {
      return;
    }
    onChange({ ...values, total: calculatorState.calculatorResult });
    setCurrencyOpen(false);
  }, [calculatorState.calculatorResult, onChange, values]);

  const handleChangeComment = useCallback<React.ChangeEventHandler<HTMLInputElement>>(
    ev => onChange({ ...values, comment: ev.target.value }),
    [values, onChange],
  );

  const handleCloseCurrency = useCallback(() => {
    setCurrencyOpen(false);
  }, []);

  const handleOpenCurrency = useCallback(() => {
    setCurrencyOpen(true);
    calculatorState.reset(values.total);
  }, [calculatorState, values.total]);

  const handleChangeImage = useCallback(
    (image: ImageFile, pdf?: Blob) => {
      onChange({ ...values, image, pdf });
    },
    [values, onChange],
  );

  return (
    <>
      {benefit === BenefitDTOBenefitEnum.Lunch && (
        <LunchReceiptTypes lunchReceiptType={values.lunchReceiptType} setLunchReceiptType={handleChangeLunchReceiptType} />
      )}
      <Box sx={{ marginBottom: 1 }}>
        <InputLabel variant="standard" htmlFor="input-total">
          {t('benefit.LUNCH.receiptPicture')}
        </InputLabel>
        <SelectImageField
          value={values.image}
          onChange={handleChangeImage}
          sx={{ height: theme.spacing(9.25), width: '100%' }}
          benefit={benefit}
        />
      </Box>
      <Box sx={{ marginBottom: 1 }}>
        <InputLabel variant="standard" htmlFor="input-total">
          {t(`benefit.${benefit}.field.total`)}
        </InputLabel>
        <TextField
          id="input-total"
          margin="dense"
          placeholder={t(`benefit.${benefit}.field.total`)}
          variant="outlined"
          fullWidth
          value={t('common:money', { money: values.total })}
          required
          onClick={handleOpenCurrency}
          disabled={disabled}
          slotProps={{
            input: {
              readOnly: true,
              endAdornment: <InputAdornment position="end">€</InputAdornment>,
            },
          }}
        />
      </Box>
      <Box sx={{ marginBottom: 1 }}>
        <InputLabel variant="standard" htmlFor="input-date">
          {t(`benefit.${benefit}.field.${isTimeCard ? 'validityStartDate' : 'date'}`)}
        </InputLabel>
        <TextField
          id="input-date"
          margin="dense"
          variant="outlined"
          fullWidth
          value={i18n.format(isTimeCard ? values.mobilityValidityStartDate : values.date, 'date')}
          required
          disabled={disabled}
          onClick={handleOpenDate}
          slotProps={{
            input: {
              readOnly: true,
              endAdornment: (
                <InputAdornment position="end">
                  <DateRangeIcon />
                </InputAdornment>
              ),
            },
          }}
        />
      </Box>
      {benefit === BenefitDTOBenefitEnum.Mobility && benefitPeriodDTO && (
        <>
          {values.mobilityType === MobilityReceiptRefundDTOMobilityTypeEnum.TimeCard && (
            <Box sx={{ marginBottom: 1 }}>
              <InputLabel variant="standard">{t(`benefit.MOBILITY.field.validityMonths`)}</InputLabel>
              <ScrollingNumberRangePicker
                min={1}
                max={VALIDITY_MONTHS_MAX}
                value={
                  values.mobilityType === MobilityReceiptRefundDTOMobilityTypeEnum.TimeCard ? values.mobilityValidityMonths || 1 : undefined
                }
                onChange={handleChangeMobilityValidityMonths}
              />
            </Box>
          )}
          <MobilityTravelPurposes
            mobilityTravelPurpose={values.mobilityTravelPurpose}
            setMobilityTravelPurpose={handleChangeMobilityTravelPurpose}
          />
          <MobilityTypes
            availableMobilityTypes={availableMobilityTypes}
            mobilityType={values.mobilityType}
            setMobilityType={handleChangeMobilityType}
          />
        </>
      )}
      {benefit === BenefitDTOBenefitEnum.Lunch && (
        <Box sx={{ marginBottom: 1 }}>
          <Typography variant="h6" color="text.secondary">
            {t(`benefit.LUNCH.field.comment`)}
            {values.lunchReceiptType === UpdateLunchReceiptDTOReceiptTypeEnum.Mixed ? ' *' : ''}
          </Typography>
          <TextField
            id="input-comment"
            margin="dense"
            slotProps={{
              htmlInput: {
                maxLength: 1000,
              },
            }}
            multiline
            rows={3}
            fullWidth
            required={values.lunchReceiptType === UpdateLunchReceiptDTOReceiptTypeEnum.Mixed}
            variant="outlined"
            placeholder={t('benefit.LUNCH.placeholder.comment')}
            disabled={disabled}
            value={values.comment}
            onChange={handleChangeComment}
          />
        </Box>
      )}
      <Box justifyContent="center" display="flex">
        <Drawer
          anchor="bottom"
          open={dateOpen}
          onClose={handleCloseDate}
          PaperProps={{ sx: { textAlign: 'center', paddingY: 1, maxWidth: 455, margin: 'auto' } }}
        >
          <PeriodDatePicker
            numberOfMonths={isTimeCard ? values.mobilityValidityMonths! - 1 : undefined}
            showLastMonth={previousPeriod && !previousPeriod.isLocked}
            month={lunchPeriod}
            value={isTimeCard ? values.mobilityValidityStartDate : values.date}
            onConfirm={handleChangeDate}
            onCancel={handleCloseDate}
            dayIsUsed={dateIsUsed}
          />
        </Drawer>
        <Drawer
          anchor="bottom"
          open={currencyOpen}
          onClose={handleCloseCurrency}
          PaperProps={{ sx: { textAlign: 'center', paddingY: 1, maxWidth: 455, margin: 'auto' } }}
        >
          <CalculatorFormField
            state={calculatorState}
            hideCalculator={benefit === BenefitDTOBenefitEnum.Mobility}
            onSubmit={handleChangeTotal}
          />
        </Drawer>
      </Box>
    </>
  );
};
