import CalculateIcon from '@mui/icons-material/Calculate';
import CallIcon from '@mui/icons-material/Call';
import GavelIcon from '@mui/icons-material/Gavel';
import { Box, Button, Stack, Typography, useTheme } from '@mui/material';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { BackIconButton } from '../../component/button/BackIconButton';
import { CircularHeader } from '../../component/button/CircularFloatingButton/CircularHeader';
import NavigationButton from '../../component/button/NavigationButton';
import { MobileLayout } from '../../component/layout';
import { AppToolbar } from '../../component/layout/AppToolbar';
import { HighlightGuideButton } from '../../module/guiding';
import { useBackNavigator } from '../../module/navigation/BackNavigator';
import { useTenant } from 'probonio-shared-ui/module/me';

enum BavSubRoute {
  terms = 'terms',
  calculator = 'calculator',
  meeting = 'meeting',
  notinterested = 'notinterested',
  info = 'info',
}

interface Props {
  page: BavSubRoute;
  handleClick: () => void;
  icon: React.ReactNode;
}

const BAVNavigationButton: React.FC<Props> = ({ page, handleClick, icon }) => {
  const { t } = useTranslation('bavModule');

  return (
    <NavigationButton onClick={handleClick} iconLeft={icon}>
      <Typography variant="h3" gutterBottom color="text.primary">
        {t(`landingPage.${page}.title`)}
      </Typography>
      <Typography variant="body2" color="text.secondary">
        {t(`landingPage.${page}.subTitle`)}
      </Typography>
    </NavigationButton>
  );
};

const BAVLandingPage: React.FC = () => {
  const backNavigator = useBackNavigator();

  const { t } = useTranslation('bavModule');
  const { tenant } = useTenant();

  const theme = useTheme();

  const navigate = useNavigate();

  const handleNavigate = useCallback(
    (subRoute: BavSubRoute) => {
      navigate(`/bav/${subRoute}`);
    },
    [navigate],
  );

  const handleTermsNavigate = useCallback(() => handleNavigate(BavSubRoute.terms), [handleNavigate]);
  const handleCalculatorNavigate = useCallback(() => handleNavigate(BavSubRoute.calculator), [handleNavigate]);
  const handleMeetingNavigate = useCallback(() => handleNavigate(BavSubRoute.meeting), [handleNavigate]);
  const handleNotInterestedNavigate = useCallback(() => handleNavigate(BavSubRoute.notinterested), [handleNavigate]);
  const handlInfoNavigate = useCallback(() => handleNavigate(BavSubRoute.info), [handleNavigate]);

  return (
    <MobileLayout
      header={
        <AppToolbar
          title={t('benefitModule:benefit.BAV.nameNoContract')}
          actionButton={
            <HighlightGuideButton
              name={t('benefitModule:benefit.BAV.name')}
              header={t('benefitModule:benefit.BAV.firstHelpGuide.header')}
              text={t('benefitModule:benefit.BAV.firstHelpGuide.text')}
              onClick={handlInfoNavigate}
            />
          }
          color={theme.palette.primary.contrastText}
          backButton={<BackIconButton onClick={backNavigator.back} />}
        />
      }
    >
      <Box p={0.5} sx={{ backgroundColor: theme.palette.primary.contrastText }}>
        <Typography color="text.secondary" textAlign="center">
          {t('landingPage.lead')}
        </Typography>
      </Box>
      <CircularHeader borderColor={theme.palette.primary.main} />

      <Box
        display="flex"
        flexDirection="column"
        height={'100%'}
        justifyContent="space-between"
        alignItems="center"
        marginX={1}
        gap={0.8}
        marginBottom={1}
      >
        <Stack direction="column" justifyContent={'space-between'} spacing={1} marginTop={1}>
          <BAVNavigationButton page={BavSubRoute.calculator} handleClick={handleCalculatorNavigate} icon={<CalculateIcon />} />
          <BAVNavigationButton page={BavSubRoute.meeting} handleClick={handleMeetingNavigate} icon={<CallIcon />} />
          {tenant?.bavShowTerms && (
            <BAVNavigationButton
              page={BavSubRoute.terms}
              handleClick={handleTermsNavigate}
              icon={<GavelIcon data-test-id="ui-bav-terms-nav-button" />}
            />
          )}
        </Stack>
        <Button variant="outlined" color="error" fullWidth onClick={handleNotInterestedNavigate}>
          {t('notinterested.title')}
        </Button>
      </Box>
    </MobileLayout>
  );
};
export default BAVLandingPage;
