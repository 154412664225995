import CloseIcon from '@mui/icons-material/Close';
import { Box, Breakpoint, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, SxProps } from '@mui/material';
import React, { memo, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

export interface DialogState {
  isOpen: boolean;
  handleClose: () => void;
}

export const useNewDialogState = (): { dialogState: DialogState; handleOpen: () => void } => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  const handleOpen = useCallback(() => {
    setIsOpen(true);
  }, []);

  return useMemo(
    () => ({
      dialogState: {
        isOpen,
        handleClose,
      },
      handleOpen,
    }),
    [handleClose, handleOpen, isOpen],
  );
};

interface Props {
  dialogState: DialogState;
  fullWidth?: boolean;
  maxWidth?: Breakpoint;
  title: React.ReactNode;
  content: React.ReactNode;
  actions?: React.ReactNode;
  hideCloseIcon?: boolean;
  dataTestId?: string;
  sx?: SxProps;
}

export const BasicDialog: React.FC<Props> = memo(
  ({ dialogState: state, title, content, actions, fullWidth, maxWidth, hideCloseIcon, dataTestId, sx }) => {
    const { handleClose, isOpen } = state;
    const { t } = useTranslation('common');

    return (
      <Dialog
        open={isOpen}
        onClose={handleClose}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          'data-test-id': dataTestId,
        }}
      >
        <Box sx={sx}>
          <Box sx={{ display: 'flex', flexWrap: 'nowrap' }}>
            <DialogTitle id="alert-dialog-title" sx={{ flexGrow: 1, paddingX: 1, display: 'flex', alignItems: 'center' }}>
              {title}
            </DialogTitle>
            {!hideCloseIcon && (
              <IconButton
                name={'close'}
                sx={{ width: '48px', height: '48px' }}
                color="inherit"
                onClick={handleClose}
                title={t('button.close')}
                aria-label={t('button.close')}
              >
                <CloseIcon data-test-id="close-dialog" />
              </IconButton>
            )}
          </Box>

          <DialogContent sx={{ paddingX: 1, paddingY: 0 }}>{content}</DialogContent>
          <DialogActions sx={{ paddingX: 1 }}>{actions}</DialogActions>
        </Box>
      </Dialog>
    );
  },
);
