import { Box, CircularProgress, useTheme } from '@mui/material';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { BackIconButton } from '../../component/button/BackIconButton';
import { MobileLayout } from '../../component/layout';
import { AppToolbar } from '../../component/layout/AppToolbar';
import { CircularHeader } from '../../component/button/CircularFloatingButton/CircularHeader';
import { BikeCalculatorForm } from '../../module/bike/BikeCalculatorForm';
import { BikeContractHeader } from '../../module/bike/BikeContractHeader';
import { BikeContractInfo } from '../../module/bike/BikeContractInfo';
import { useBikeDetailsQuery } from '../../module/bike/useBikeDetailsQuery';
import { useCurrentBikeContract } from '../../module/bike/useCurrentBikeContract';
import { HighlightGuideButton } from '../../module/guiding';
import { useBackNavigator } from '../../module/navigation/BackNavigator';

const BikePage: React.FC = () => {
  const theme = useTheme();
  const backNavigator = useBackNavigator();
  const navigate = useNavigate();
  const { t } = useTranslation('benefitModule');

  const { contract: activeContract, isLoading } = useCurrentBikeContract();
  const { data: bikeBenefitDetails } = useBikeDetailsQuery();

  const navigateInfo = useCallback(() => {
    navigate('info');
  }, [navigate]);

  return (
    <>
      <MobileLayout
        iosOverloadScrollBackground={theme.palette.background.paper}
        header={
          <AppToolbar
            title={t('benefit.BIKE.name')}
            color={activeContract ? theme.palette.primary.contrastText : theme.palette.background.default}
            backButton={<BackIconButton onClick={backNavigator.back} />}
            actionButton={
              <HighlightGuideButton
                name={t('benefit.BIKE.name')}
                header={t('benefit.BIKE.firstHelpGuide.header')}
                text={t('benefit.BIKE.firstHelpGuide.text')}
                onClick={navigateInfo}
              />
            }
          />
        }
      >
        {isLoading || !bikeBenefitDetails ? (
          <Box textAlign="center" padding={5}>
            <CircularProgress />
          </Box>
        ) : activeContract ? (
          <>
            <BikeContractHeader contract={activeContract} />
            <CircularHeader borderColor={theme.palette.primary.main} />
            <BikeContractInfo contract={activeContract} bikeBenefitDetails={bikeBenefitDetails} />
          </>
        ) : (
          <BikeCalculatorForm />
        )}
      </MobileLayout>
    </>
  );
};

export default BikePage;
