import { Route, Routes } from 'react-router-dom';
import BikePage from './BikePage';
import BikeInfoPage from './BikeInfoPage';
import BikeLearnMorePage from './BikeLearnMorePage';

const BikeRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<BikePage />} />
      <Route path="/info" element={<BikeInfoPage />} />
      <Route path="/learnmore" element={<BikeLearnMorePage />} />
    </Routes>
  );
};

export default BikeRoutes;
