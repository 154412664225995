import { Box, Typography } from '@mui/material';
import { MobileErrorLayout } from '../../component/layout/MobileErrorLayout';

interface Props {
  headlineLarge: React.ReactNode;
  headlineSmall: React.ReactNode;
  message: React.ReactNode;
  eventId?: string;
}

export const ErrorView: React.FC<React.PropsWithChildren<Props>> = ({ headlineLarge, headlineSmall, message, eventId, children }) => {
  return (
    <MobileErrorLayout>
      <Box sx={{ textAlign: 'center' }}>
        <Typography variant="h2" sx={{ paddingBottom: 1, fontWeight: 400 }}>
          {headlineLarge}
        </Typography>
        <Typography variant="h5" gutterBottom>
          {headlineSmall}
        </Typography>
        <Box sx={{ marginBottom: 1.5 }}>
          <Typography color="inherit">{message}</Typography>
          {eventId && (
            <Typography variant="body2" sx={{ marginTop: 0.5 }}>
              ID: {eventId}
            </Typography>
          )}
        </Box>
        {children}
      </Box>
    </MobileErrorLayout>
  );
};
