import { Box, useTheme } from '@mui/material';

interface Props {
  broken?: boolean;
}

export const CouponItemDivider: React.FC<Props> = ({ broken }) => {
  const theme = useTheme();

  return broken ? (
    <Box>
      <svg width="24" height="65" viewBox="0 0 30 81" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M 18 1 L22.5 11L9 17L21 30L9 31.5L22.5 42.5L10.5 54.5L21 61.5L9 72.5L22.5 82"
          stroke={theme.palette.primary.contrastText}
          strokeWidth="5"
          strokeLinecap="square"
        />
      </svg>
    </Box>
  ) : (
    <Box className="hLine-dashed" />
  );
};
