import { CouponsV2UserOrderDTOStatusEnum } from 'probonio-shared-ui/api';
import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { LoadingLayout } from '../../component/layout/LoadingLayout';
import { CouponsShowVoucherModal } from '../couponsShared/components/CouponsShowVoucherModal';
import { useCouponsV2UserOrderQuery, useRedeemCouponV2Mutation } from './couponsV2Queries';

export const CouponsV2ShowVoucherPage: React.FC = () => {
  const { couponOrderId } = useParams();
  const { data: couponOrder } = useCouponsV2UserOrderQuery(couponOrderId!);
  const redeemCallback = useRedeemCouponV2Mutation(couponOrder!);
  const isCouponPending =
    couponOrder?.status === CouponsV2UserOrderDTOStatusEnum.Pending || couponOrder?.status === CouponsV2UserOrderDTOStatusEnum.Ordered;

  const handleConfirm = useCallback(() => {
    if (couponOrder) {
      redeemCallback({ shouldBeRedeemed: !couponOrder.redeemDate });
    }
  }, [couponOrder, redeemCallback]);

  if (!couponOrder?.voucher) {
    return <LoadingLayout />;
  }

  return (
    <CouponsShowVoucherModal
      amount={couponOrder.amount}
      isCouponPending={isCouponPending}
      couponDefinition={couponOrder.couponDefinition}
      voucherDetails={couponOrder.voucher}
      handleConfirmRedeemed={handleConfirm}
      onUpdate={redeemCallback}
      redeemDate={couponOrder.redeemDate}
    />
  );
};
