import { Avatar, SxProps, Theme } from '@mui/material';
import gravatar from 'gravatar';
import * as React from 'react';

/**
 * Generates a hash out of the string and creates a color value as HEX (#00rrggbb) out of it.
 * This ensures the same color for the same input string.
 */
function stringToColor(string: string): string {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    const valueString = `00${value.toString(16)}`;
    color += valueString.substring(valueString.length - 2, valueString.length);
  }
  /* eslint-enable no-bitwise */

  return color;
}

/**
 * @returns either white or black (contrast) depending on the brightness of the input color
 */
function getContrastYIQ(hexcolor: string): string {
  hexcolor = hexcolor.replace('#', '');
  const r = parseInt(hexcolor.substring(0, 2), 16);
  const g = parseInt(hexcolor.substring(2, 4), 16);
  const b = parseInt(hexcolor.substring(4, 6), 16);
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 128 ? '000' : 'fff';
}

interface Props {
  onClick?: React.MouseEventHandler<HTMLDivElement>;

  firstName?: string;
  lastName?: string;
  mail?: string;
  size?: number;
  sx?: SxProps<Theme>;
}

export const LetterAvatar: React.FC<Props> = ({ onClick, firstName, lastName, mail, size = 50, sx }) => {
  const initials = (firstName && lastName ? `${firstName?.[0]}${lastName?.[0]}` : mail?.[0])?.toUpperCase();
  const bgColorHex = stringToColor(`${firstName} ${lastName} ${mail}`).substring(1);
  const fontColor = getContrastYIQ(bgColorHex);
  return (
    <Avatar
      onClick={onClick}
      src={
        mail &&
        gravatar.url(mail, { default: `https://ui-avatars.com/api/${initials}/${size * 4}/${bgColorHex}/${fontColor}`, protocol: 'https' })
      }
      sx={{
        width: size,
        height: size,
        ...sx,
      }}
    />
  );
};
