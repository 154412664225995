import { Box, Button, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CouponsV2UserTokenDTO } from '../../../../../probonio-shared-ui/api';
import { CouponCard } from '../../couponsShared/components/CouponCard';
import { CouponUsageText } from '../../couponsShared/components/CouponUsageText';
import { CouponDetailsLine } from '../../couponsShared/orderDetails/CouponDetailsLine';
import { Link } from 'react-router-dom';

interface Props {
  userToken: CouponsV2UserTokenDTO;
}

export const CouponsV2TokenDetail: React.FC<Props> = ({ userToken }) => {
  const { t, i18n } = useTranslation('couponsV2Module');
  const theme = useTheme();

  return (
    <>
      <CouponCard
        bottomPanel={
          userToken.redeemDate ? (
            <Box sx={{ textAlign: 'center' }}>
              <Typography variant="caption">{t('tokenDetails.redeemDate')}</Typography>
              <Typography variant="body1" color="text.secondary">
                {i18n.format(new Date(userToken.redeemDate), 'date')}
              </Typography>
            </Box>
          ) : (
            <Button
              component={Link}
              variant="contained"
              color="primary"
              fullWidth
              to={`/couponsV2/explore-coupons?amount=${userToken.amount}`}
            >
              {t('tokenDetails.goToShop')}
            </Button>
          )
        }
      >
        <Box display="flex" sx={{ marginBottom: 0.75, alignItems: 'center', justifyContent: 'center' }} height={120}>
          <img src="/loginscreen/logo.svg" style={{ maxHeight: 100, maxWidth: 100 }} alt={t('tokenDetails.logoAlt')} />
        </Box>
        <Typography variant="h1" textAlign="center" sx={{ marginBottom: 1 }}>
          {t('tokenDetails.name')}
        </Typography>
        <Typography component="h2" variant="extraBig" textAlign="center" sx={{ marginBottom: 1.25 }}>
          {t('common:moneyFloat', { money: userToken.amount })}
        </Typography>
        <CouponUsageText usageText={t('tokenDetails.usageText')} theme={theme} />
        {userToken.code && <CouponDetailsLine title={t('tokenDetails.code')} value={userToken.code.replace(/(\w{4})(?!$)/g, '$1-')} copy />}
      </CouponCard>
    </>
  );
};
