import CloseIcon from '@mui/icons-material/Close';
import { Box, Card, IconButton, Typography, useTheme } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { Trans } from 'react-i18next';

interface Props {
  title: string;
  onClose: () => void;
  description: string;
  children: React.ReactNode;
}

export const FeedbackBox: React.FC<Props> = ({ title, onClose, description, children }) => {
  const theme = useTheme();

  return (
    <Box marginBottom={1}>
      <Card>
        <Grid container display={'flex'} flexDirection={'column'} padding={0.5} width="100%">
          <Grid display={'flex'} justifyContent="space-between">
            <Typography variant="h2" color="text.primary" gutterBottom>
              <Trans>{title}</Trans>
            </Typography>
            <Box mt={-0.25}>
              <IconButton size="small" onClick={onClose} data-test-id="close-feedback-visualization">
                <CloseIcon fontSize="medium" sx={{ color: theme.palette.closeIcon.main }} />
              </IconButton>
            </Box>
          </Grid>
          <Typography variant="description" color="text.secondary">
            <Trans>{description}</Trans>
          </Typography>
        </Grid>
        <Grid display="flex" gap={1} width="100%" paddingBottom={0.5} paddingX={0.5} justifyContent={'center'}>
          {children}
        </Grid>
      </Card>
    </Box>
  );
};
