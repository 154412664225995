import { SentryRoutes } from 'probonio-shared-ui/module/error/sentry';
import { Route } from 'react-router-dom';
import { LunchPeriodsProvider } from '../../module/lunch/LunchPeriodsContext';
import { useImageSelection } from '../../module/shareTarget/useImageSelection';
import AddReceiptPage from './AddReceiptPage';
import EditReceiptPage from './EditReceiptPage';
import LunchInfoPage from './LunchInfoPage';
import LunchPage from './LunchPage';
import { LunchReceiptDetailPage } from './LunchReceiptDetailPage';
import { LUNCH_MAX_IMAGE_SIZE } from '../../module/lunch/lunchConstants';

const LunchRoutes: React.FC = () => {
  const imageSelection = useImageSelection(true, LUNCH_MAX_IMAGE_SIZE);

  return (
    <LunchPeriodsProvider>
      <SentryRoutes>
        <Route path="/info" element={<LunchInfoPage />} />
        <Route path="/*" element={<LunchPage onSelectImage={imageSelection.handleChangeImage} />} />
        {imageSelection.selectedImage && <Route path="receipt/add" element={<AddReceiptPage imageSelection={imageSelection} />} />}
        <Route path="receipt/view/:lunchPeriod/:receiptId" element={<LunchReceiptDetailPage />} />
        <Route path="receipt/edit/:lunchPeriod/:receiptId" element={<EditReceiptPage />} />
      </SentryRoutes>
    </LunchPeriodsProvider>
  );
};

export default LunchRoutes;
