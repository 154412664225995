import CircleIcon from '@mui/icons-material/Circle';
import { Box, Button, Typography, useTheme } from '@mui/material';
import { CouponDefinitionDTO, CouponVariationAvailabilityDTOAvailabilityEnum } from 'probonio-shared-ui/api';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNewDialogState } from '../../../component/dialog/BasicDialog';
import { LoadingFunction } from '../../../utils/loadingFunction';
import { getCouponImageUrl } from '../../couponsShared/checkCouponImageUrl';
import { CouponCard } from '../../couponsShared/components/CouponCard';
import { CouponUsageText } from '../../couponsShared/components/CouponUsageText';
import { getAvailabilityByAmount, getWorstAvailability } from '../../couponsShared/couponCombinations';
import { ExploreCouponsDetailModal } from '../../couponsShared/explore/ExploreCouponsDetailModal';
import { ExploreCouponsV2ChangeAmount } from './ExploreCouponsV2ChangeAmount';
import { ExploreCouponsV2DetailModal } from './ExploreCouponsV2DetailModal';
import { useCouponsV2BenefitDetails } from '../couponsV2Queries';

interface Props {
  coupon: CouponDefinitionDTO;
  availableAmounts: number[];
  dialogExtension?: React.ReactNode;
  noneAvailableMessage?: React.ReactNode;
  disableMultipleSelection?: boolean;
  onOrderSingle: LoadingFunction<(amount: number) => void>;
  onOrderMultiple: LoadingFunction<(cartState: Record<number, number>) => void>;
}

const AVAILABILITY_COLORS: Record<CouponVariationAvailabilityDTOAvailabilityEnum, 'success' | 'info' | 'error'> = {
  IN_STOCK: 'success',
  IN_STOCK_UNLIMITED: 'success',
  ON_DEMAND: 'info',
  OUT_OF_STOCK: 'error',
};

type CartState = Record<number, number>;

export const ExploreCouponsV2Detail: React.FC<Props> = ({
  coupon,
  availableAmounts,
  dialogExtension,
  noneAvailableMessage,
  disableMultipleSelection,
  onOrderSingle,
  onOrderMultiple,
}) => {
  const { dialogState, handleOpen: handleOpenDialog } = useNewDialogState();
  const { t } = useTranslation('couponsModule');
  const theme = useTheme();
  const [cartState, setCartState] = useState<CartState>({});
  const benefitDetails = useCouponsV2BenefitDetails();

  const selectedAmountCount = Object.values(cartState).reduce((a, b) => a + b, 0);

  const { url: couponImageUrl } = getCouponImageUrl(coupon);

  const selectableAmounts = useMemo(
    () =>
      availableAmounts
        ?.filter(amount => coupon.variations?.includes(amount) || coupon.combinations?.includes(amount))
        .sort((a, b) => b - a),
    [availableAmounts, coupon.combinations, coupon.variations],
  );

  const availabilityByAmount = useMemo(() => getAvailabilityByAmount(coupon, selectableAmounts), [coupon, selectableAmounts]);

  useEffect(() => {
    const hightestAmount = Object.entries(availabilityByAmount).reduce((prev, [key, stockStatus]): number => {
      if (stockStatus !== 'OUT_OF_STOCK' && prev < Number.parseInt(key)) {
        prev = Number.parseInt(key);
      }
      return prev;
    }, 0);
    if (hightestAmount) {
      setCartState({ [hightestAmount]: 1 });
    }
  }, [availabilityByAmount, selectableAmounts]);

  const singleCouponAmount = selectableAmounts[0] as number | undefined;
  const cartValues =
    selectedAmountCount === 0 ? selectableAmounts : (Object.keys(cartState) as unknown as number[]).filter(amount => cartState[amount]);
  const cartAvailability = cartValues.map(amount => availabilityByAmount[amount]);
  const worstAvailability =
    disableMultipleSelection && singleCouponAmount ? availabilityByAmount[singleCouponAmount] : getWorstAvailability(cartAvailability);

  const tokenCount = useMemo(() => {
    const tokenCount: Record<number, number> = {};
    benefitDetails?.availableTokens?.forEach(token => {
      const { amount } = token;
      if (tokenCount[amount]) {
        tokenCount[amount]++;
      } else {
        tokenCount[amount] = 1;
      }
    });
    return tokenCount;
  }, [benefitDetails?.availableTokens]);

  const total = Object.entries(cartState).reduce((sum, [amount, count]) => sum + Number(amount) * count, 0);

  const handleChange = useCallback(
    (amount: number, count: number): void => {
      const newState = { ...cartState, [amount]: count };
      setCartState(newState);
    },
    [cartState],
  );

  return (
    <>
      <CouponCard
        bottomPanel={
          <>
            <Button variant="outlined" fullWidth href={coupon.redeemUrl || '#'} target="_blank">
              {t('exploreCoupons.details.visitShop')}
            </Button>
          </>
        }
      >
        <Box display={'flex'} sx={{ marginBottom: 0.75, alignItems: 'center', justifyContent: 'center' }} height={120}>
          <img src={couponImageUrl} style={{ borderRadius: 8, maxHeight: 120, maxWidth: 200 }} />
        </Box>
        <Typography variant="h1" textAlign="center" sx={{ marginBottom: 1 }}>
          {coupon.name}
        </Typography>
        {selectableAmounts?.[0] && worstAvailability ? (
          <>
            <Box display="flex" justifyContent="center" sx={{ marginBottom: 1.25 }}>
              {disableMultipleSelection ? (
                <Typography component="h2" variant="extraBig" textAlign="center" data-test-id="single-coupon-amount">
                  {t('couponOrderDetails.couponAmount', { amount: singleCouponAmount })}
                </Typography>
              ) : (
                <Box>
                  <Typography component="h2" variant="extraBig" textAlign="center" sx={{ paddingBottom: 1.25 }}>
                    Gesamt: {t('couponsV2Module:couponOrderDetails.couponTotal', { total: total })}
                  </Typography>
                  {selectableAmounts.map(amount => (
                    <ExploreCouponsV2ChangeAmount
                      key={amount}
                      amount={amount}
                      count={cartState[amount] || 0}
                      maxCount={availabilityByAmount[amount] === 'OUT_OF_STOCK' ? 0 : tokenCount[amount]}
                      onChangeCount={handleChange}
                    />
                  ))}
                </Box>
              )}
            </Box>

            <CouponUsageText usageText={coupon.redemptionDescription} theme={theme} />

            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              marginBottom={0.5}
              fontSize={theme.typography.description.fontSize}
            >
              <CircleIcon
                color={AVAILABILITY_COLORS[worstAvailability]}
                fontSize="inherit"
                sx={{ verticalAlign: 'middle', marginRight: 0.4 }}
              />
              <Typography variant="description">{t(`couponOrderDetails.couponAvailability.${worstAvailability}`)}</Typography>
            </Box>
          </>
        ) : (
          <Box paddingBottom={0.25}>
            <Typography variant="body2" fontStyle="italic" textAlign="center" color={theme.palette.text.secondary}>
              {noneAvailableMessage || t('exploreCoupons.details.couponAlreadyUsed')}
            </Typography>
          </Box>
        )}

        <Button
          variant="contained"
          fullWidth
          disabled={
            (!disableMultipleSelection && selectedAmountCount === 0) ||
            !singleCouponAmount ||
            worstAvailability === CouponVariationAvailabilityDTOAvailabilityEnum.OutOfStock
          }
          sx={{
            '&:disabled': { backgroundColor: theme.palette.primary.main, color: theme.palette.primary.contrastText, opacity: 0.5 },
          }}
          onClick={handleOpenDialog}
          data-test-id="order-coupon-button"
        >
          {selectedAmountCount > 1 ? t('exploreCoupons.details.orderCoupons') : t('exploreCoupons.details.orderCoupon')}
        </Button>
      </CouponCard>
      {disableMultipleSelection && singleCouponAmount ? (
        <ExploreCouponsDetailModal coupon={coupon} dialogState={dialogState} amount={singleCouponAmount} onConfirm={onOrderSingle}>
          {dialogExtension}
        </ExploreCouponsDetailModal>
      ) : (
        <ExploreCouponsV2DetailModal
          dialogState={dialogState}
          coupon={coupon}
          count={selectedAmountCount}
          cartState={cartState}
          availability={worstAvailability}
          onConfirm={onOrderMultiple}
        />
      )}
    </>
  );
};
