import topImage from './img/errorTop.svg';
import bottomImage from './img/errorBottom.svg';
import centerImage from './img/errorCenter.svg';
import { Box, useTheme } from '@mui/material';

export const MobileErrorLayout: React.FC<React.PropsWithChildren> = ({ children }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.default : theme.palette.primary.main,
        height: '100%',
        backgroundImage: `url("${topImage}")`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'top left',
        backgroundSize: '400px',
      }}
    >
      <Box
        sx={{
          height: '100%',
          backgroundImage: `url("${bottomImage}")`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'bottom right',
          backgroundSize: '400px',
          padding: 2,
          color: theme.palette.mode === 'dark' ? theme.palette.text.primary : theme.palette.primary.contrastText,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box component="img" alt="logo" src={centerImage} sx={{ width: '50vw', maxWidth: '250px', marginBottom: 2 }} />
        {children}
      </Box>
    </Box>
  );
};
