/* tslint:disable */
/* eslint-disable */
/**
 * Probonio full API Docs
 * If you stumbled upon this page and want to integrate Probonio into your business software [click here!](/api/external)    If you\'re looking for more information about Probonio [head over here](https://probonio.de)    **This documentation describes all Endpoints of the Probonio API. It\'s created for experienced Probonio Developers only!**  
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { IBANResolutionDTO } from '../dto';
// @ts-ignore
import { TenantSepaConfigDTO } from '../dto';
// @ts-ignore
import { UpdateTenantSepaConfigDTO } from '../dto';
// @ts-ignore
import { UploadLinkDTO } from '../dto';
/**
 * SepaApi - axios parameter creator
 * @export
 */
export const SepaApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a form POST upload link for uploading the tenant SEPA mandate. Refer to https://docs.aws.amazon.com/AmazonS3/latest/API/sigv4-post-example.html on how to implement the form POST.
         * @summary create a presigned upload link
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSepaUploadLink: async (tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('createSepaUploadLink', 'tenantId', tenantId)
            const localVarPath = `/tenants/{tenantId}/sepa/createUploadLink`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary generates the SEPA mandate PDF template
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateSepaMandateTemplate: async (tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('generateSepaMandateTemplate', 'tenantId', tenantId)
            const localVarPath = `/tenants/{tenantId}/sepa/mandate`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get a tenant sepa fields
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTenantSepaConfig: async (tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('getTenantSepaConfig', 'tenantId', tenantId)
            const localVarPath = `/tenants/{tenantId}/sepa`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary validate IBAN and return bank details
         * @param {string} iban 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resolveIBAN: async (iban: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'iban' is not null or undefined
            assertParamExists('resolveIBAN', 'iban', iban)
            const localVarPath = `/iban/{iban}`
                .replace(`{${"iban"}}`, encodeURIComponent(String(iban)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update the sepa mandate of a tenant
         * @param {string} tenantId 
         * @param {UpdateTenantSepaConfigDTO} updateTenantSepaConfigDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTenantSepaMandate: async (tenantId: string, updateTenantSepaConfigDTO: UpdateTenantSepaConfigDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('updateTenantSepaMandate', 'tenantId', tenantId)
            // verify required parameter 'updateTenantSepaConfigDTO' is not null or undefined
            assertParamExists('updateTenantSepaMandate', 'updateTenantSepaConfigDTO', updateTenantSepaConfigDTO)
            const localVarPath = `/tenants/{tenantId}/sepa`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateTenantSepaConfigDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SepaApi - functional programming interface
 * @export
 */
export const SepaApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SepaApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a form POST upload link for uploading the tenant SEPA mandate. Refer to https://docs.aws.amazon.com/AmazonS3/latest/API/sigv4-post-example.html on how to implement the form POST.
         * @summary create a presigned upload link
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSepaUploadLink(tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadLinkDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSepaUploadLink(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary generates the SEPA mandate PDF template
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateSepaMandateTemplate(tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateSepaMandateTemplate(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get a tenant sepa fields
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTenantSepaConfig(tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenantSepaConfigDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTenantSepaConfig(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary validate IBAN and return bank details
         * @param {string} iban 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resolveIBAN(iban: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IBANResolutionDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resolveIBAN(iban, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary update the sepa mandate of a tenant
         * @param {string} tenantId 
         * @param {UpdateTenantSepaConfigDTO} updateTenantSepaConfigDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTenantSepaMandate(tenantId: string, updateTenantSepaConfigDTO: UpdateTenantSepaConfigDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTenantSepaMandate(tenantId, updateTenantSepaConfigDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SepaApi - factory interface
 * @export
 */
export const SepaApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SepaApiFp(configuration)
    return {
        /**
         * Create a form POST upload link for uploading the tenant SEPA mandate. Refer to https://docs.aws.amazon.com/AmazonS3/latest/API/sigv4-post-example.html on how to implement the form POST.
         * @summary create a presigned upload link
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSepaUploadLink(tenantId: string, options?: any): AxiosPromise<UploadLinkDTO> {
            return localVarFp.createSepaUploadLink(tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary generates the SEPA mandate PDF template
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateSepaMandateTemplate(tenantId: string, options?: any): AxiosPromise<any> {
            return localVarFp.generateSepaMandateTemplate(tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get a tenant sepa fields
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTenantSepaConfig(tenantId: string, options?: any): AxiosPromise<TenantSepaConfigDTO> {
            return localVarFp.getTenantSepaConfig(tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary validate IBAN and return bank details
         * @param {string} iban 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resolveIBAN(iban: string, options?: any): AxiosPromise<IBANResolutionDTO> {
            return localVarFp.resolveIBAN(iban, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update the sepa mandate of a tenant
         * @param {string} tenantId 
         * @param {UpdateTenantSepaConfigDTO} updateTenantSepaConfigDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTenantSepaMandate(tenantId: string, updateTenantSepaConfigDTO: UpdateTenantSepaConfigDTO, options?: any): AxiosPromise<void> {
            return localVarFp.updateTenantSepaMandate(tenantId, updateTenantSepaConfigDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createSepaUploadLink operation in SepaApi.
 * @export
 * @interface SepaApiCreateSepaUploadLinkRequest
 */
export interface SepaApiCreateSepaUploadLinkRequest {
    /**
     * 
     * @type {string}
     * @memberof SepaApiCreateSepaUploadLink
     */
    readonly tenantId: string
}

/**
 * Request parameters for generateSepaMandateTemplate operation in SepaApi.
 * @export
 * @interface SepaApiGenerateSepaMandateTemplateRequest
 */
export interface SepaApiGenerateSepaMandateTemplateRequest {
    /**
     * 
     * @type {string}
     * @memberof SepaApiGenerateSepaMandateTemplate
     */
    readonly tenantId: string
}

/**
 * Request parameters for getTenantSepaConfig operation in SepaApi.
 * @export
 * @interface SepaApiGetTenantSepaConfigRequest
 */
export interface SepaApiGetTenantSepaConfigRequest {
    /**
     * 
     * @type {string}
     * @memberof SepaApiGetTenantSepaConfig
     */
    readonly tenantId: string
}

/**
 * Request parameters for resolveIBAN operation in SepaApi.
 * @export
 * @interface SepaApiResolveIBANRequest
 */
export interface SepaApiResolveIBANRequest {
    /**
     * 
     * @type {string}
     * @memberof SepaApiResolveIBAN
     */
    readonly iban: string
}

/**
 * Request parameters for updateTenantSepaMandate operation in SepaApi.
 * @export
 * @interface SepaApiUpdateTenantSepaMandateRequest
 */
export interface SepaApiUpdateTenantSepaMandateRequest {
    /**
     * 
     * @type {string}
     * @memberof SepaApiUpdateTenantSepaMandate
     */
    readonly tenantId: string

    /**
     * 
     * @type {UpdateTenantSepaConfigDTO}
     * @memberof SepaApiUpdateTenantSepaMandate
     */
    readonly updateTenantSepaConfigDTO: UpdateTenantSepaConfigDTO
}

/**
 * SepaApi - object-oriented interface
 * @export
 * @class SepaApi
 * @extends {BaseAPI}
 */
export class SepaApi extends BaseAPI {
    /**
     * Create a form POST upload link for uploading the tenant SEPA mandate. Refer to https://docs.aws.amazon.com/AmazonS3/latest/API/sigv4-post-example.html on how to implement the form POST.
     * @summary create a presigned upload link
     * @param {SepaApiCreateSepaUploadLinkRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SepaApi
     */
    public createSepaUploadLink(requestParameters: SepaApiCreateSepaUploadLinkRequest, options?: AxiosRequestConfig) {
        return SepaApiFp(this.configuration).createSepaUploadLink(requestParameters.tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary generates the SEPA mandate PDF template
     * @param {SepaApiGenerateSepaMandateTemplateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SepaApi
     */
    public generateSepaMandateTemplate(requestParameters: SepaApiGenerateSepaMandateTemplateRequest, options?: AxiosRequestConfig) {
        return SepaApiFp(this.configuration).generateSepaMandateTemplate(requestParameters.tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get a tenant sepa fields
     * @param {SepaApiGetTenantSepaConfigRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SepaApi
     */
    public getTenantSepaConfig(requestParameters: SepaApiGetTenantSepaConfigRequest, options?: AxiosRequestConfig) {
        return SepaApiFp(this.configuration).getTenantSepaConfig(requestParameters.tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary validate IBAN and return bank details
     * @param {SepaApiResolveIBANRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SepaApi
     */
    public resolveIBAN(requestParameters: SepaApiResolveIBANRequest, options?: AxiosRequestConfig) {
        return SepaApiFp(this.configuration).resolveIBAN(requestParameters.iban, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update the sepa mandate of a tenant
     * @param {SepaApiUpdateTenantSepaMandateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SepaApi
     */
    public updateTenantSepaMandate(requestParameters: SepaApiUpdateTenantSepaMandateRequest, options?: AxiosRequestConfig) {
        return SepaApiFp(this.configuration).updateTenantSepaMandate(requestParameters.tenantId, requestParameters.updateTenantSepaConfigDTO, options).then((request) => request(this.axios, this.basePath));
    }
}
