/* tslint:disable */
/* eslint-disable */
/**
 * Probonio full API Docs
 * If you stumbled upon this page and want to integrate Probonio into your business software [click here!](/api/external)    If you\'re looking for more information about Probonio [head over here](https://probonio.de)    **This documentation describes all Endpoints of the Probonio API. It\'s created for experienced Probonio Developers only!**  
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface MobilityPeriodDTO
 */
export interface MobilityPeriodDTO {
    /**
     * 
     * @type {string}
     * @memberof MobilityPeriodDTO
     */
    'month': string;
    /**
     * 
     * @type {string}
     * @memberof MobilityPeriodDTO
     */
    'mobilityTaxationType': MobilityPeriodDTOMobilityTaxationTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof MobilityPeriodDTO
     */
    'refundTaxable': number;
    /**
     * 
     * @type {number}
     * @memberof MobilityPeriodDTO
     */
    'refundTaxFree': number;
    /**
     * 
     * @type {number}
     * @memberof MobilityPeriodDTO
     */
    'refund': number;
    /**
     * 
     * @type {number}
     * @memberof MobilityPeriodDTO
     */
    'maxRefund': number;
    /**
     * 
     * @type {boolean}
     * @memberof MobilityPeriodDTO
     */
    'isLocked'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MobilityPeriodDTO
     */
    'hasUploadedDeutschlandTicket'?: boolean;
}

export const MobilityPeriodDTOMobilityTaxationTypeEnum = {
    TaxFree: 'TAX_FREE',
    Taxable: 'TAXABLE'
} as const;

export type MobilityPeriodDTOMobilityTaxationTypeEnum = typeof MobilityPeriodDTOMobilityTaxationTypeEnum[keyof typeof MobilityPeriodDTOMobilityTaxationTypeEnum];


