/* tslint:disable */
/* eslint-disable */
/**
 * Probonio full API Docs
 * If you stumbled upon this page and want to integrate Probonio into your business software [click here!](/api/external)    If you\'re looking for more information about Probonio [head over here](https://probonio.de)    **This documentation describes all Endpoints of the Probonio API. It\'s created for experienced Probonio Developers only!**  
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { UpdateTenantHRSystemDTO } from '../dto';
/**
 * HRSystemApi - axios parameter creator
 * @export
 */
export const HRSystemApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary update employees from the configured HR system
         * @param {string} tenantId 
         * @param {boolean} [fullSync] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncTenantHRSystem: async (tenantId: string, fullSync?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('syncTenantHRSystem', 'tenantId', tenantId)
            const localVarPath = `/tenants/{tenantId}/hrSystem/sync`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (fullSync !== undefined) {
                localVarQueryParameter['fullSync'] = fullSync;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update the hr system config of a tenant
         * @param {string} tenantId 
         * @param {UpdateTenantHRSystemDTO} updateTenantHRSystemDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTenantHRSystemConfig: async (tenantId: string, updateTenantHRSystemDTO: UpdateTenantHRSystemDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('updateTenantHRSystemConfig', 'tenantId', tenantId)
            // verify required parameter 'updateTenantHRSystemDTO' is not null or undefined
            assertParamExists('updateTenantHRSystemConfig', 'updateTenantHRSystemDTO', updateTenantHRSystemDTO)
            const localVarPath = `/tenants/{tenantId}/hrSystem`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateTenantHRSystemDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HRSystemApi - functional programming interface
 * @export
 */
export const HRSystemApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HRSystemApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary update employees from the configured HR system
         * @param {string} tenantId 
         * @param {boolean} [fullSync] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncTenantHRSystem(tenantId: string, fullSync?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncTenantHRSystem(tenantId, fullSync, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary update the hr system config of a tenant
         * @param {string} tenantId 
         * @param {UpdateTenantHRSystemDTO} updateTenantHRSystemDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTenantHRSystemConfig(tenantId: string, updateTenantHRSystemDTO: UpdateTenantHRSystemDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTenantHRSystemConfig(tenantId, updateTenantHRSystemDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * HRSystemApi - factory interface
 * @export
 */
export const HRSystemApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HRSystemApiFp(configuration)
    return {
        /**
         * 
         * @summary update employees from the configured HR system
         * @param {string} tenantId 
         * @param {boolean} [fullSync] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncTenantHRSystem(tenantId: string, fullSync?: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.syncTenantHRSystem(tenantId, fullSync, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update the hr system config of a tenant
         * @param {string} tenantId 
         * @param {UpdateTenantHRSystemDTO} updateTenantHRSystemDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTenantHRSystemConfig(tenantId: string, updateTenantHRSystemDTO: UpdateTenantHRSystemDTO, options?: any): AxiosPromise<void> {
            return localVarFp.updateTenantHRSystemConfig(tenantId, updateTenantHRSystemDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for syncTenantHRSystem operation in HRSystemApi.
 * @export
 * @interface HRSystemApiSyncTenantHRSystemRequest
 */
export interface HRSystemApiSyncTenantHRSystemRequest {
    /**
     * 
     * @type {string}
     * @memberof HRSystemApiSyncTenantHRSystem
     */
    readonly tenantId: string

    /**
     * 
     * @type {boolean}
     * @memberof HRSystemApiSyncTenantHRSystem
     */
    readonly fullSync?: boolean
}

/**
 * Request parameters for updateTenantHRSystemConfig operation in HRSystemApi.
 * @export
 * @interface HRSystemApiUpdateTenantHRSystemConfigRequest
 */
export interface HRSystemApiUpdateTenantHRSystemConfigRequest {
    /**
     * 
     * @type {string}
     * @memberof HRSystemApiUpdateTenantHRSystemConfig
     */
    readonly tenantId: string

    /**
     * 
     * @type {UpdateTenantHRSystemDTO}
     * @memberof HRSystemApiUpdateTenantHRSystemConfig
     */
    readonly updateTenantHRSystemDTO: UpdateTenantHRSystemDTO
}

/**
 * HRSystemApi - object-oriented interface
 * @export
 * @class HRSystemApi
 * @extends {BaseAPI}
 */
export class HRSystemApi extends BaseAPI {
    /**
     * 
     * @summary update employees from the configured HR system
     * @param {HRSystemApiSyncTenantHRSystemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HRSystemApi
     */
    public syncTenantHRSystem(requestParameters: HRSystemApiSyncTenantHRSystemRequest, options?: AxiosRequestConfig) {
        return HRSystemApiFp(this.configuration).syncTenantHRSystem(requestParameters.tenantId, requestParameters.fullSync, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update the hr system config of a tenant
     * @param {HRSystemApiUpdateTenantHRSystemConfigRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HRSystemApi
     */
    public updateTenantHRSystemConfig(requestParameters: HRSystemApiUpdateTenantHRSystemConfigRequest, options?: AxiosRequestConfig) {
        return HRSystemApiFp(this.configuration).updateTenantHRSystemConfig(requestParameters.tenantId, requestParameters.updateTenantHRSystemDTO, options).then((request) => request(this.axios, this.basePath));
    }
}
