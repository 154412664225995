import { UseQueryResult } from '@tanstack/react-query';
import { BenefitDTOBenefitEnum, BikeBenefitDetailsDTO } from 'probonio-shared-ui/api';
import { apis, useEmployeeQuery } from 'probonio-shared-ui/module/api';

export function useBikeDetailsQuery(): UseQueryResult<BikeBenefitDetailsDTO, Error> {
  return useEmployeeQuery(['benefits', BenefitDTOBenefitEnum.Bike, apis.benefits.getBenefit.name], params =>
    apis.benefits
      .getBenefit({ tenantId: params.tenantId, employeeId: params.employeeId, benefit: BenefitDTOBenefitEnum.Bike })
      .then(res => {
        return res.data.details as BikeBenefitDetailsDTO;
      }),
  );
}
