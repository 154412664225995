import { Box, Theme, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface Props {
  usageText?: string;
  theme: Theme;
}
export const CouponUsageText: React.FC<Props> = ({ usageText, theme }) => {
  const { t } = useTranslation('couponsModule');

  return (
    <>
      {usageText && (
        <Box sx={{ marginBottom: 1 }}>
          <Typography display={'block'} textAlign={'center'} variant={'description'} color="text.secondary">
            <b>{t('usage')}</b>
            <br />
            {usageText}
          </Typography>
        </Box>
      )}
    </>
  );
};
