import HandshakeIcon from '@mui/icons-material/Handshake';
import { Box, Typography } from '@mui/material';
import { useActiveEmployment } from 'probonio-shared-ui/module/me';
import NavigationButton from '../button/NavigationButton';
import { useNewDialogState } from '../dialog/BasicDialog';
import { ReferralModal } from './ReferralModal';
import { useTranslation } from 'react-i18next';

export const ReferralVisualization: React.FC = () => {
  const getActiveEmployment = useActiveEmployment();
  const { status } = getActiveEmployment();
  const referralCodeDialog = useNewDialogState();
  const { t } = useTranslation('referralCodeModal');

  if (status === 'INACTIVE') {
    return (
      <Box sx={{ marginY: 1 }} color="primary">
        <NavigationButton
          onClick={referralCodeDialog.handleOpen}
          iconRight={null}
          data-test-id="referral-button"
          iconLeft={<HandshakeIcon fontSize="large" />}
        >
          <Typography variant="h3" color="text.primary">
            {t('buttonTitle')}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {t('buttonSubTitle')}
          </Typography>
        </NavigationButton>
        <ReferralModal dialogState={referralCodeDialog.dialogState} dialogDescription={t('dialogDescriptionTerminated')} />
      </Box>
    );
  } else {
    return <></>;
  }
};

export default ReferralVisualization;
