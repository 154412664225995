import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { LunchPeriodDTO } from 'probonio-shared-ui/api';
import React, { useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Drawable } from '../../../component/drawable/Drawable';
import { PeriodDatePicker } from '../../benefit/datePicker/PeriodDatePicker';
import { useLunchReceiptIsUsed } from '../../benefit/datePicker/ReceiptExistsAlert';
import { FloatingInfoButton } from '../components/FloatingInfoButton';
import { ChecklistItem } from '../components/ChecklistItem';

export interface ReceiptStep2Value {
  date: Date;
}

interface Props {
  previousPeriod?: LunchPeriodDTO;
  currentPeriod?: LunchPeriodDTO;
  value?: ReceiptStep2Value;
  onChange: (value: ReceiptStep2Value) => void;
  onBack: () => void;
}

export const ReceiptFormStep2: React.FC<Props> = ({ previousPeriod, currentPeriod, value, onChange, onBack }) => {
  const { t } = useTranslation('benefitModule');
  const receiptIsUsed = useLunchReceiptIsUsed(
    currentPeriod?.month,
    previousPeriod && !previousPeriod.isLocked ? previousPeriod.month : undefined,
  );

  const handleSubmit = useCallback(
    (value: Date) => {
      onChange({ date: value });
    },
    [onChange],
  );

  return (
    <>
      <FloatingInfoButton stateKey="lunch-step-2">
        <Trans i18nKey="benefitModule:benefit.LUNCH.receiptStep2Checklist">
          <ChecklistItem />
        </Trans>
      </FloatingInfoButton>
      <Drawable icon={<CalendarMonthIcon />}>
        <PeriodDatePicker
          showLastMonth={currentPeriod && previousPeriod && !previousPeriod.isLocked}
          month={currentPeriod ? currentPeriod.month : previousPeriod!.month}
          dayIsUsed={receiptIsUsed}
          label={t(`benefit.LUNCH.dateHint`)}
          value={value?.date}
          onConfirm={handleSubmit}
          onCancel={onBack}
        />
      </Drawable>
    </>
  );
};
