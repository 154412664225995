import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface CouponsState {
  selectedCategory: string;
  scrollPosition: number;
  categoryScrollPosition: number;
}

export const ALL_CATEGORY = '_ALL';

const initialCouponsState: CouponsState = {
  selectedCategory: ALL_CATEGORY,
  scrollPosition: 0,
  categoryScrollPosition: 0,
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const createCouponsSlice = <T extends string = string>(name: T) =>
  createSlice({
    name,
    initialState: initialCouponsState,
    reducers: {
      setCategory: (state, action: PayloadAction<string>) => {
        state.selectedCategory = action.payload;
      },
      setScrollPosition: (state, action: PayloadAction<number>) => {
        state.scrollPosition = action.payload;
      },
      setCategoryScrollPosition: (state, action: PayloadAction<number>) => {
        state.categoryScrollPosition = action.payload;
      },
      resetCouponsState: state => {
        state.selectedCategory = initialCouponsState.selectedCategory;
        state.scrollPosition = initialCouponsState.scrollPosition;
        state.categoryScrollPosition = initialCouponsState.categoryScrollPosition;
      },
    },
  });
