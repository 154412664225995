import { useQueryClient, UseQueryResult } from '@tanstack/react-query';
import { DateTime } from 'luxon';
import { LunchPeriodDTO, LunchPeriodsDTO, LunchReceiptsDTO } from 'probonio-shared-ui/api';
import { apis, useEmployeeQuery } from 'probonio-shared-ui/module/api';
import { useActiveEmployment } from 'probonio-shared-ui/module/me';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';

export interface LunchPeriodsState {
  lunchPeriodsQuery: UseQueryResult<LunchPeriodsDTO, Error>;
  previousPeriod?: LunchPeriodDTO;
  currentPeriod?: LunchPeriodDTO;
  receiptsQuery: UseQueryResult<LunchReceiptsDTO, Error>;
  refetchLunch: () => void;
  selectPeriod: (month?: string) => void;
  selectedPeriodMonth?: string;
  selectedPeriod?: LunchPeriodDTO;
}

const LunchPeriodsContext = createContext<LunchPeriodsState | undefined>(undefined);

export const LunchPeriodsProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const queryClient = useQueryClient();
  const getActiveEmployment = useActiveEmployment();
  const [selectedPeriodMonth, setSelectedPeriodMonth] = useState<string>();

  const startMonth = DateTime.utc().minus({ years: 1 }).startOf('month').toFormat('yyyy-MM');
  const endMonth = DateTime.utc().endOf('month').toFormat('yyyy-MM');

  const periodsQuery: UseQueryResult<LunchPeriodsDTO, Error> = useEmployeeQuery(
    [
      'benefits',
      'lunch',
      'periods',
      {
        startMonth,
        endMonth,
      },
      apis.lunch.getPeriods.name,
    ],
    params =>
      apis.lunch
        .getPeriods({
          ...params,
          startMonth,
          endMonth,
        })
        .then(res => res.data),
    { refetchOnMount: false },
  );

  const selectedPeriod = useMemo(() => {
    if (selectedPeriodMonth && periodsQuery.data) {
      return periodsQuery.data.lunchPeriods.find(period => period.month === selectedPeriodMonth);
    }
  }, [periodsQuery.data, selectedPeriodMonth]);

  const receiptsQuery: UseQueryResult<LunchReceiptsDTO, Error> = useEmployeeQuery(
    ['benefits', 'lunch', 'periods', selectedPeriod, 'receipts', apis.lunch.getPeriodReceipts.name],
    params => apis.lunch.getPeriodReceipts({ ...params, month: selectedPeriodMonth! }).then(res => res.data),
    {
      enabled: !!selectedPeriodMonth,
      // Refetch only after min 60 seconds to prevent api calls on every open / close of the accordion.
      staleTime: 60000,
    },
  );

  useEffect(() => {
    if (selectedPeriod === undefined) {
      queryClient.removeQueries({
        queryKey: ['benefits', 'lunch', 'periods', selectedPeriod, 'receipts', apis.lunch.getPeriodReceipts.name],
      });
    }
  }, [queryClient, receiptsQuery, selectedPeriod]);

  const context = useMemo<LunchPeriodsState>(
    () => ({
      lunchPeriodsQuery: periodsQuery,
      previousPeriod: periodsQuery.data?.lunchPeriods.find(
        period => period.month === DateTime.now().minus({ months: 1 }).toFormat('yyyy-MM'),
      ),
      currentPeriod: periodsQuery.data?.lunchPeriods.find(period => period.month === DateTime.now().toFormat('yyyy-MM')),
      receiptsQuery: receiptsQuery,

      refetchLunch: async () => {
        return queryClient.invalidateQueries({
          queryKey: ['tenants', getActiveEmployment().tenantId, 'employees', getActiveEmployment().id, 'benefits', 'lunch'],
        });
      },
      selectPeriod: setSelectedPeriodMonth,
      selectedPeriodMonth: selectedPeriodMonth,
      selectedPeriod: selectedPeriod,
    }),
    [getActiveEmployment, periodsQuery, queryClient, receiptsQuery, selectedPeriod, selectedPeriodMonth],
  );

  return <LunchPeriodsContext.Provider value={context}>{children}</LunchPeriodsContext.Provider>;
};

export function useLunchPeriods(): LunchPeriodsState {
  const ctx = useContext(LunchPeriodsContext);
  // Throw error that the Context is not injected, if it is undefined.
  if (ctx === undefined) {
    throw new Error('useLunchPeriods must be used within a LunchPeriodsProvider');
  }
  return ctx;
}
