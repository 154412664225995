import { Box, useTheme } from '@mui/material';
import React from 'react';

interface Props {
  tabs?: React.ReactNode;
  borderColor?: string;
  actionButton?: React.ReactNode;
}

export const BG_HEIGHT = 35;

export const CircularHeader: React.FC<Props> = ({ tabs, borderColor, actionButton }): JSX.Element => {
  const theme = useTheme();

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', position: 'relative' }}>
      {tabs ? (
        tabs
      ) : (
        <Box
          sx={{
            position: 'absolute',
            left: 0,
            right: 0,
            top: 0,
            width: '100%',
            height: BG_HEIGHT + 35,
            overflow: 'hidden',
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              left: -BG_HEIGHT / 2,
              top: -BG_HEIGHT,
              height: BG_HEIGHT + 35,
              right: -BG_HEIGHT / 2,
              backgroundColor: theme.palette.primary.contrastText,
              boxShadow: borderColor ? `0px 4px 0px 0px ${borderColor}` : 'none',
              borderRadius: '50%',
            }}
          />
        </Box>
      )}
      {actionButton ?? <Box sx={{ height: theme.spacing(3) }} />}
    </Box>
  );
};
