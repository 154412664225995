/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, FormControlLabel, FormGroup, Switch, Typography, useTheme } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';
import {
  NotificationSettingsDTO,
  NotificationSettingsDTONotificationChannelsEnum,
} from 'probonio-shared-ui/api/dto/notification-settings-dto';
import { apis } from 'probonio-shared-ui/module/api';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfirmationModal } from '../../component/confirmationModal';
import { useAppSelector } from '../../redux/hooks';
import { usePushNotifications } from '../pushNotifications/PushNotificationRegistration';
import { useNotificationSettingsQuery } from './NotificationSettingsQuery';

export const NotificationSwitch: React.FC = () => {
  const { t } = useTranslation('profilePage');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const theme = useTheme();
  const { isSupported, requestPermission } = usePushNotifications();
  const userID = useAppSelector(state => state.me.user?.id);
  const [switchVisible, setSwitchVisible] = useState(true);
  const [checked, setChecked] = useState(true);
  const notificationSettingsQuery = useNotificationSettingsQuery();

  useEffect(() => {
    void (async () => {
      const pushSupported = await isSupported();
      setSwitchVisible(pushSupported);
    })();
  }, [isSupported]);

  useEffect(() => {
    if (notificationSettingsQuery.data) {
      setChecked(notificationSettingsQuery.data.notificationChannels.includes(NotificationSettingsDTONotificationChannelsEnum.Push));
    }
  }, [notificationSettingsQuery.data]);

  const mutation = useMutation({
    mutationFn: (data: NotificationSettingsDTO) =>
      apis.user.updateNotificationSettings({ userId: userID!, updateNotificationSettingsDTO: data }).then(response => response.data),

    onSuccess: () => {
      enqueueSnackbar(t('onSucess'), { variant: 'success' });
    },
  });

  const handleNotificationSwitch = useCallback(() => {
    if (checked) {
      setIsModalOpen(true);
      setChecked(false);
    } else {
      void requestPermission();
      const updatedNotificationSettings: NotificationSettingsDTO = {
        notificationChannels: ['PUSH'],
      };
      mutation.mutate(updatedNotificationSettings);
      setChecked(true);
    }
  }, [checked, mutation, requestPermission]);

  const handleModalSubmit = useCallback(() => {
    setIsModalOpen(false);
    const updatedNotificationSettings: NotificationSettingsDTO = {
      notificationChannels: [],
    };
    mutation.mutate(updatedNotificationSettings);
    setChecked(false);
  }, [mutation]);

  const handleModalClose = useCallback(() => {
    setIsModalOpen(false);
    setChecked(true);
  }, []);

  if (!switchVisible) {
    return <></>;
  }

  return (
    <>
      <Box
        data-test-id="notification-switch-form"
        sx={{
          display: 'flex',
          alignItems: 'center',
          paddingX: 0.75,
          paddingY: 0.5,
          backgroundColor: theme.palette.background.paper,
          borderRadius: 1,
          justifyContent: 'center',
        }}
      >
        <Box sx={{ flexGrow: 1 }}>
          <Typography data-test-id="notification-switch-title" variant="caption" color="text.secondary">
            {t('enableDisableNotifications')}
          </Typography>
        </Box>
        <Box>
          <FormGroup>
            <FormControlLabel
              sx={{ width: '100%', marginRight: -0.5 }}
              control={<Switch checked={checked} onChange={handleNotificationSwitch} inputProps={{ 'aria-label': 'controlled' }} />}
              label={undefined}
            />
          </FormGroup>
        </Box>
      </Box>
      <ConfirmationModal
        title={t('notificationModal.title')}
        isOpen={isModalOpen}
        message={t('notificationModal.text')}
        cancelButtonTitle={t('notificationModal.cancelButton')}
        okButtonTitle={t('notificationModal.okButton')}
        onCancel={handleModalClose}
        onSubmit={handleModalSubmit}
      />
    </>
  );
};
