import { Box, Chip, Stack, useTheme } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { CouponDefinitionListDTO } from '../../../../../probonio-shared-ui/api';
import { ALL_CATEGORY } from '../redux/createCouponsSlice';
import ExploreCouponsItem from './ExploreCouponsItem';
import { ExploreCouponsState } from './exploreCouponsState';

export interface Props {
  state: ExploreCouponsState;
  couponDefinitions: CouponDefinitionListDTO;
  onOpenCouponDetails: (couponDefinitionId: string) => void;
}

export const ExploreCoupons: React.FC<Props> = ({ state, couponDefinitions, onOpenCouponDetails }) => {
  const { t } = useTranslation('couponsModule');
  const theme = useTheme();
  const categoryScrollRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (state.categoryScrollPosition && categoryScrollRef.current) {
      categoryScrollRef.current.scrollTo(state.categoryScrollPosition, 0);
    }
  }, [state.categoryScrollPosition]);

  const chipData = useMemo(() => {
    const categoriesOrder = [
      'Sonstiges',
      'Baby',
      'Wohnen',
      'Technik',
      'Unterhaltung',
      'Spielwaren',
      'Sport',
      'Reisen',
      'Mobilität',
      'Drogerie',
      'Mode',
      'Essen & Trinken',
    ];

    const sortedCategories = [...couponDefinitions.categories];
    sortedCategories.sort((currentCategory, nextCategory) => {
      return categoriesOrder.indexOf(nextCategory) - categoriesOrder.indexOf(currentCategory);
    });

    return [ALL_CATEGORY, ...sortedCategories];
  }, [couponDefinitions]);

  const handleCategorySelect = useCallback(
    (category: string) => () => {
      const setCategory = state.setCategory;
      setCategory(category);
    },
    [state.setCategory],
  );

  const handleOpenCouponDetails = useCallback(
    (id: string) => {
      if (categoryScrollRef.current) {
        const setCategoryScrollPosition = state.setCategoryScrollPosition;
        setCategoryScrollPosition(categoryScrollRef.current.scrollLeft);
      }
      onOpenCouponDetails(id);
    },
    [state.setCategoryScrollPosition, onOpenCouponDetails],
  );

  return (
    <Box marginX={1} marginBottom={3}>
      <Stack direction="row" spacing={0.2} overflow="auto" paddingBottom={0.5} ref={categoryScrollRef}>
        {chipData.map(category => {
          return (
            <Chip
              sx={{
                borderRadius: 1.5,
                backgroundColor: state.selectedCategory === category ? theme.palette.primary.main : theme.palette.datePicker.border,
                color: state.selectedCategory === category ? theme.palette.primary.contrastText : theme.palette.primary.main,
                border: 0,
              }}
              key={category}
              label={
                category === ALL_CATEGORY ? t(`exploreCoupons.defaultFilter`) : t(`categories.${category.replace(/\./g, '')}`, category)
              }
              clickable
              onClick={handleCategorySelect(category)}
              color={state.selectedCategory === category ? 'primary' : 'default'}
              variant={state.selectedCategory === category ? 'filled' : 'outlined'}
            />
          );
        })}
      </Stack>
      <Grid container spacing={1} paddingTop={1}>
        {couponDefinitions.coupons.map(coupon => {
          return (
            (coupon.categories?.includes(state.selectedCategory) || state.selectedCategory === ALL_CATEGORY) && (
              <Grid size={6} key={coupon.id}>
                <ExploreCouponsItem
                  id={coupon.id}
                  imageUrl={coupon.imageUrl}
                  name={coupon.name}
                  productNumber={coupon.productNumber}
                  onCardClick={handleOpenCouponDetails}
                />
              </Grid>
            )
          );
        })}
      </Grid>
    </Box>
  );
};
