import { CouponOrderDTO } from 'probonio-shared-ui/api';
import { useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { SwipeableList } from '../../component/swipeableList/SwipeableList';
import { CouponOrderReceiptDetail } from './CouponOrderReceiptDetail';
import { useCombinedCouponOrderListQuery } from '../couponsShared/couponsSharedQueries';
import { CouponItemModel } from '../couponsShared/orderList/couponItemModel';

interface Props {
  couponOrder: CouponOrderDTO;
}

export const CouponOrderDetails: React.FC<Props> = ({ couponOrder }) => {
  const navigate = useNavigate();
  const { couponOrderId } = useParams();

  const { data: orders } = useCombinedCouponOrderListQuery();
  const selectableOrders = useMemo(() => orders.filter(coupon => coupon.status !== 'ERROR'), [orders]);

  const handleNavigate = useCallback(
    (item: CouponItemModel) => {
      navigate(`/${item.version === 'V1' ? 'coupons/order' : 'couponsV2/orders'}/${item.id}`, { replace: true });
    },
    [navigate],
  );

  return (
    <SwipeableList items={selectableOrders} currentItemId={couponOrderId!} onNavigate={handleNavigate}>
      <CouponOrderReceiptDetail couponOrder={couponOrder} />
    </SwipeableList>
  );
};
