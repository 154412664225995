/* eslint-disable max-classes-per-file */
import { useCallback, useContext, useState } from 'react';
import { ErrorHandlerContext } from './GlobalErrorCatcher';

export type ErrorCatcher = (err: Error) => string | undefined;

/**
 * all can be used to set a custom error message for all error types.
 */
export const all: (message: string) => ErrorCatcher = message => err => message;

export class MessageError extends Error {
  public readonly displayMessage?: string;

  public readonly originalError: Error;

  public constructor(originalError: Error, displayMessage?: string) {
    super(originalError.message);
    this.displayMessage = displayMessage;
    this.originalError = originalError;
  }
}

export class UnhandledError extends Error {
  public readonly originalError: unknown;

  public constructor(originalError: unknown) {
    super('unhandled error');
    this.originalError = originalError;
  }
}

/**
 * can be used to catch errors and show a message-box.
 * You can pass catchers which are basically just callbacks, which can return a
 * string which then overrides the default error message.
 * If these catchers return undefined, the default error message is used.
 *
 * @example
 * // without catchers
 *
 * const api = useAPI();
 * const withMessage = useWithMessage();
 *
 * useEffect(() => {
 *   return api.defaultApi.getParameters({ path })
 *     .then((res) => {
 *       setParameterTableRows(res.data);
 *     })
 *     .catch(withMessage);
 * }, [api.defaultApi, path, withMessage]);
 *
 *
 * @example
 * // with catchers
 *
 * const api = useAPI();
 * const { t } = useTranslation("aTranslationKey");
 * const withMessage = useWithMessage([
 *   (err) => (err.message === "my error message" ? t("specialError") : null),
 * ]);
 *
 * useEffect(() => {
 *   return api.defaultApi.getParameters({ path: selected[0] })
 *     .then((res) => {
 *       setParameterTableRows(res.data);
 *     })
 *     .catch(withMessage);
 * }, [api.defaultApi, path, withMessage]);
 *
 * @returns the withMessage callback which can be passed to any catch of a promise.
 */
export const useWithMessage = (...catchers: ErrorCatcher[]): ((err: Error) => void) => {
  const [catcherList] = useState(catchers);
  const errorHandler = useContext(ErrorHandlerContext);

  return useCallback(
    (err: Error): void => {
      const found = catcherList.find(c => !!c(err));
      const message = (found && found(err)) || undefined;
      errorHandler(new MessageError(err, message));
    },
    [catcherList, errorHandler],
  );
};
