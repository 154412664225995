import { WarningAmber } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import NavigationButton from '../button/NavigationButton';
import { useNewDialogState } from '../dialog/BasicDialog';
import supportedBrowsers from './supportedBrowsers';
import { UnsupportedBrowserDialog } from './UnsupportedBrowserBadgeDialog';
const LOCALSTORAGE_KEY = 'unsupportedBrowserModalOpened';

export const UnsupportedBrowserBadge: React.FC = () => {
  const [showBadge, setShowBadge] = useState(false);
  const { t } = useTranslation('unsupportedBrowserModal');
  const modalState = localStorage.getItem(LOCALSTORAGE_KEY);

  useEffect(() => {
    if (modalState !== 'closed' && !supportedBrowsers.test(window.navigator.userAgent)) {
      setShowBadge(true);
    }
  }, [modalState]);

  const handleClose = useCallback(() => {
    localStorage.setItem(LOCALSTORAGE_KEY, 'closed');
    setShowBadge(false);
  }, []);

  const unsupportedBrowserDialog = useNewDialogState();
  if (showBadge) {
    return (
      <Box marginY={1}>
        <NavigationButton
          iconRight={<CloseIcon />}
          data-test-id="referral-button"
          iconLeft={<WarningAmber fontSize="large" />}
          onClick={unsupportedBrowserDialog.handleOpen}
        >
          <Typography variant="h3" color="text.primary">
            {t('buttonTitle')}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {t('buttonSubTitle')}
          </Typography>
        </NavigationButton>
        <UnsupportedBrowserDialog dialogState={unsupportedBrowserDialog.dialogState} onClose={handleClose} />
      </Box>
    );
  } else {
    return <></>;
  }
};

export default UnsupportedBrowserBadge;
