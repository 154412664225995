import { Box, Button, Stack, Typography } from '@mui/material';
import { MobilityPeriodDTO, MobilityReceiptRefundDTOMobilityTypeEnum } from 'probonio-shared-ui/api';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollingNumberRangePicker } from '../../../component/ScrollPicker/ScrollingNumberRangePicker';
import { MobilityTravelPurposes } from './MobilityTravelPurposes';
import { MobilityTypes } from './MobilityTypes';
import { MobilityTypeState } from './useMobilityTypeState';
import { useAvailableMobilityTypes } from './availableMobilityTypes';

interface Props {
  currentPeriod: MobilityPeriodDTO;
  state: MobilityTypeState;
  onPrevious: () => void;
  onSubmit: () => void;
}

export const VALIDITY_MONTHS_MAX = 24;

export const MobilityTypeForm: React.FC<Props> = ({ currentPeriod, state, onPrevious, onSubmit }) => {
  const { t } = useTranslation('benefitModule');

  const handleChangeValidityMonths = useCallback(
    (value: number) => {
      const changeValidity = state.setValidityMonths;
      changeValidity(value);
    },
    [state.setValidityMonths],
  );

  const handleSubmit = useCallback(
    (event: React.FormEvent) => {
      event.preventDefault();
      onSubmit();
    },
    [onSubmit],
  );

  const { mobilityType, travelPurpose, validityMonths } = state;
  const isValid = mobilityType && travelPurpose && (mobilityType !== MobilityReceiptRefundDTOMobilityTypeEnum.TimeCard || !!validityMonths);

  const availableMobilityTypes = useAvailableMobilityTypes(currentPeriod, travelPurpose, validityMonths);

  return (
    <Box component="form" sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }} onSubmit={handleSubmit}>
      <Box sx={{ flexGrow: 1 }}>
        <MobilityTravelPurposes mobilityTravelPurpose={travelPurpose} setMobilityTravelPurpose={state.setTravelPurpose} />
        <MobilityTypes
          availableMobilityTypes={availableMobilityTypes}
          mobilityType={mobilityType}
          setMobilityType={state.setMobilityType}
        />
        {mobilityType === MobilityReceiptRefundDTOMobilityTypeEnum.TimeCard && (
          <Box sx={{ marginTop: 0.5, marginBottom: 1 }}>
            <Typography textAlign="center" variant="body2">
              {t('benefit.MOBILITY.field.validityMonths')}
            </Typography>
            <ScrollingNumberRangePicker min={1} max={VALIDITY_MONTHS_MAX} value={validityMonths} onChange={handleChangeValidityMonths} />
          </Box>
        )}
      </Box>
      <Stack direction="row" justifyContent="space-between">
        <Button variant="contained" color="secondary" onClick={onPrevious} sx={{ width: '35%' }}>
          {t('common:button.back')}
        </Button>

        <Button
          data-test-id="mobility-type-continue"
          type="submit"
          disabled={!isValid}
          variant="contained"
          color="primary"
          sx={{ width: '35%' }}
        >
          {t('common:button.continue')}
        </Button>
      </Stack>
    </Box>
  );
};
