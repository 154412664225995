import supportedBrowsers from './supportedBrowsers.mjs';

if (!supportedBrowsers.test(window.navigator.userAgent)) {
  const newDiv = document.createElement('div');

  const warningMessage = document.createTextNode(
    'Leider ist deine Browserversion von uns nicht mehr unterstützt. Bitte aktualisieren Sie Ihre Browser-Version.',
  );

  newDiv.appendChild(warningMessage);
  document.body.appendChild(newDiv);
}
