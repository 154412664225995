/* tslint:disable */
/* eslint-disable */
/**
 * Probonio full API Docs
 * If you stumbled upon this page and want to integrate Probonio into your business software [click here!](/api/external)    If you\'re looking for more information about Probonio [head over here](https://probonio.de)    **This documentation describes all Endpoints of the Probonio API. It\'s created for experienced Probonio Developers only!**  
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { BenefitActivationDTO } from './benefit-activation-dto';
import { CouponGiftPayoutDateWithActiveInviteDTO } from './coupon-gift-payout-date-with-active-invite-dto';
import { UserDTO } from './user-dto';

/**
 * 
 * @export
 * @interface EmployeeDTO
 */
export interface EmployeeDTO {
    /**
     * 
     * @type {string}
     * @memberof EmployeeDTO
     */
    'status': EmployeeDTOStatusEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof EmployeeDTO
     */
    'roles': Array<EmployeeDTORolesEnum>;
    /**
     * 
     * @type {string}
     * @memberof EmployeeDTO
     */
    'employeeType'?: EmployeeDTOEmployeeTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof EmployeeDTO
     */
    'civilStatus'?: EmployeeDTOCivilStatusEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof EmployeeDTO
     */
    'activeBenefits'?: Array<EmployeeDTOActiveBenefitsEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof EmployeeDTO
     */
    'upcomingBenefits'?: Array<EmployeeDTOUpcomingBenefitsEnum>;
    /**
     * 
     * @type {UserDTO}
     * @memberof EmployeeDTO
     */
    'user'?: UserDTO;
    /**
     * 
     * @type {Array<string>}
     * @memberof EmployeeDTO
     */
    'activeCustomBenefits'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof EmployeeDTO
     */
    'upcomingCustomBenefits'?: Array<string>;
    /**
     * 
     * @type {BenefitActivationDTO}
     * @memberof EmployeeDTO
     */
    'benefitActivation'?: BenefitActivationDTO;
    /**
     * 
     * @type {CouponGiftPayoutDateWithActiveInviteDTO}
     * @memberof EmployeeDTO
     */
    'nextGift'?: CouponGiftPayoutDateWithActiveInviteDTO;
    /**
     * 
     * @type {object}
     * @memberof EmployeeDTO
     */
    'newsArticleStatus'?: object;
    /**
     * 
     * @type {string}
     * @memberof EmployeeDTO
     */
    'registrationCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeDTO
     */
    'registrationToken'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeDTO
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeDTO
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeDTO
     */
    'tenantId': string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeDTO
     */
    'tenantName'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeDTO
     */
    'employeeNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeDTO
     */
    'birthDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeDTO
     */
    'hireDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeDTO
     */
    'terminationDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeDTO
     */
    'department'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeDTO
     */
    'costCenter'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeDTO
     */
    'defaultCouponId'?: string;
    /**
     * 
     * @type {number}
     * @memberof EmployeeDTO
     */
    'receiptCred'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof EmployeeDTO
     */
    'bavNotInterested'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof EmployeeDTO
     */
    'numberOfKids'?: number;
}

export const EmployeeDTOStatusEnum = {
    Active: 'ACTIVE',
    Inactive: 'INACTIVE',
    Registered: 'REGISTERED',
    InvitationCode: 'INVITATION_CODE',
    Deleted: 'DELETED'
} as const;

export type EmployeeDTOStatusEnum = typeof EmployeeDTOStatusEnum[keyof typeof EmployeeDTOStatusEnum];
export const EmployeeDTORolesEnum = {
    TenantAdmin: 'TENANT_ADMIN',
    TenantAssistant: 'TENANT_ASSISTANT',
    User: 'USER',
    TenantSales: 'TENANT_SALES'
} as const;

export type EmployeeDTORolesEnum = typeof EmployeeDTORolesEnum[keyof typeof EmployeeDTORolesEnum];
export const EmployeeDTOEmployeeTypeEnum = {
    FullTime: 'FULL_TIME',
    PartTime: 'PART_TIME',
    Intern: 'INTERN',
    Student: 'STUDENT',
    MiniJob: 'MINI_JOB',
    Misc: 'MISC'
} as const;

export type EmployeeDTOEmployeeTypeEnum = typeof EmployeeDTOEmployeeTypeEnum[keyof typeof EmployeeDTOEmployeeTypeEnum];
export const EmployeeDTOCivilStatusEnum = {
    Single: 'SINGLE',
    Married: 'MARRIED',
    Divorced: 'DIVORCED',
    Widowed: 'WIDOWED',
    CivilUnion: 'CIVIL_UNION',
    CivilUnionDeceased: 'CIVIL_UNION_DECEASED',
    CivilUnionCancelled: 'CIVIL_UNION_CANCELLED'
} as const;

export type EmployeeDTOCivilStatusEnum = typeof EmployeeDTOCivilStatusEnum[keyof typeof EmployeeDTOCivilStatusEnum];
export const EmployeeDTOActiveBenefitsEnum = {
    Bav: 'BAV',
    Lunch: 'LUNCH',
    Coupons: 'COUPONS',
    CouponsV2: 'COUPONS_V2',
    Mobility: 'MOBILITY',
    Gifts: 'GIFTS',
    Internet: 'INTERNET',
    Bike: 'BIKE',
    News: 'NEWS',
    Flex: 'FLEX',
    Recreation: 'RECREATION',
    Bikeleasing: 'BIKELEASING',
    Custom: 'CUSTOM'
} as const;

export type EmployeeDTOActiveBenefitsEnum = typeof EmployeeDTOActiveBenefitsEnum[keyof typeof EmployeeDTOActiveBenefitsEnum];
export const EmployeeDTOUpcomingBenefitsEnum = {
    Bav: 'BAV',
    Lunch: 'LUNCH',
    Coupons: 'COUPONS',
    CouponsV2: 'COUPONS_V2',
    Mobility: 'MOBILITY',
    Gifts: 'GIFTS',
    Internet: 'INTERNET',
    Bike: 'BIKE',
    News: 'NEWS',
    Flex: 'FLEX',
    Recreation: 'RECREATION',
    Bikeleasing: 'BIKELEASING',
    Custom: 'CUSTOM'
} as const;

export type EmployeeDTOUpcomingBenefitsEnum = typeof EmployeeDTOUpcomingBenefitsEnum[keyof typeof EmployeeDTOUpcomingBenefitsEnum];


