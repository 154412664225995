import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import de from './de.json';
import en from './en.json';
import hu from './hu.json';
import hr from './hr.json';
import pl from './pl.json';
import ro from './ro.json';
import tr from './tr.json';

export enum LocaleEnum {
  DE = 'de',
  EN = 'en',
  HU = 'hu',
  HR = 'hr',
  PL = 'pl',
  RO = 'ro',
  TR = 'tr',
}

export const LANGUAGE_NAMES: Record<LocaleEnum, string> = {
  en: 'English',
  de: 'Deutsch',
  ro: 'Română (Beta)',
  hu: 'Magyar (Beta)',
  pl: 'Polski (Beta)',
  hr: 'Hrvatski (Beta)',
  tr: 'Türkçe (Beta)',
};

void i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      de,
      en,
      hr,
      hu,
      pl,
      ro,
      tr,
    },
    fallbackLng: 'de',
    interpolation: {
      escapeValue: false,
      format: (value, format, lng) => {
        const locale = lng || i18n.resolvedLanguage;
        if (value instanceof Date) {
          if (format === 'time') {
            return value.toLocaleDateString(locale, { hour: '2-digit', minute: '2-digit', second: '2-digit' });
          } else if (format === 'datetime') {
            return value.toLocaleDateString(locale, {
              hour: '2-digit',
              minute: '2-digit',
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
            });
          } else if (format === 'monthAndYear') {
            return value.toLocaleDateString(locale, { year: 'numeric', month: 'long' });
          } else {
            return value.toLocaleDateString(locale, { day: '2-digit', month: '2-digit', year: 'numeric' });
          }
        } else if (typeof value === 'number') {
          if (format === 'euro') {
            // own design euro formatter
            const centToEuro = (value / 100).toLocaleString(locale, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            });
            if (locale?.startsWith('en')) {
              return `€${centToEuro}`;
            }
            return `${centToEuro}€`;
          } else if (format === 'euro-float') {
            // show decimals only if necessary (i.e. not for whole numbers)
            const centToEuro = (value / 100).toLocaleString(locale, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            });
            if (locale?.startsWith('en')) {
              return `€${centToEuro.replace(/[.,]00$/, '')}`;
            }
            return `${centToEuro.replace(/[.,]00$/, '')}€`;
          }
        }
        return value;
      },
    },
    react: {
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'p', 'ul', 'ol', 'li'],
    },
    debug: true,
  });

i18n.on('languageChanged', lng => {
  document.documentElement.setAttribute('lang', lng);
});

function hotReload(lang: string): void {
  const file = `./${lang}.ts`;
  if (import.meta.hot) {
    import.meta.hot.accept(file, updatedModule => {
      if (!updatedModule) {
        return;
      }
      const resources = updatedModule.default;
      Object.keys(resources).forEach(namespace => {
        void i18n.addResourceBundle(lang, namespace, resources[namespace], true, true);
      });
    });
  }
}

Object.values(LocaleEnum).forEach(locale => {
  hotReload(locale);
});

export default i18n;
