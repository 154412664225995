import { CouponDefinitionDTO, CouponVariationAvailabilityDTOAvailabilityEnum } from 'probonio-shared-ui/api';

const MAX_COMBINATION_LENGTH = 3;

/**
 * Yields all possible combinations of the input numbers (including length 1) up to the given maxSum and
 * maxLength, ordered by length (shortest first).
 * @param isExact true to yield only combinations matching maxSum exactly
 */
export function* findSumCombinations(numbers: number[], maxSum: number, maxLength: number, isExact?: boolean): Generator<number[], void> {
  const sortedNumbers = [...numbers].sort();
  // Breadth-first tree traversal: push the starting nodes into the queue
  const queue = sortedNumbers.map(num => [num]);

  while (queue.length) {
    const currentCombination = queue.shift()!;
    const currentSum = currentCombination.reduce((sum, v) => sum + v, 0);

    // for each node, push the valid child nodes of the next level into the queue
    if (currentSum < maxSum && currentCombination.length < maxLength) {
      queue.push(
        ...numbers.filter(num => num >= currentCombination[currentCombination.length - 1]).map(num => [...currentCombination, num]),
      );
    }

    // yield nodes of current level if sum and length are correct
    if ((currentSum === maxSum || (!isExact && currentSum < maxSum)) && currentCombination.length <= maxLength) {
      yield currentCombination;
    }
  }
}

//use this on NOT available coupons
export const getFirstCouponCombination = (numbers: number[] | undefined, total: number): number[] | undefined => {
  if (!numbers || numbers.includes(total)) {
    return;
  }

  return findSumCombinations(numbers, total, MAX_COMBINATION_LENGTH, true).next().value || undefined;
};

const AVAILABILITY_ORDER = ['IN_STOCK_UNLIMITED', 'IN_STOCK', 'ON_DEMAND', 'OUT_OF_STOCK'] as const;

export function getWorstAvailability(
  availabilities: CouponVariationAvailabilityDTOAvailabilityEnum[],
): CouponVariationAvailabilityDTOAvailabilityEnum | undefined {
  const minAvailability = availabilities
    .filter(availability => availability !== 'OUT_OF_STOCK')
    .sort((a, b) => AVAILABILITY_ORDER.indexOf(b) - AVAILABILITY_ORDER.indexOf(a))[0];
  return minAvailability || 'OUT_OF_STOCK';
}

export function getCouponAvailability(definition: CouponDefinitionDTO, amount: number): CouponVariationAvailabilityDTOAvailabilityEnum {
  const combination = getFirstCouponCombination(definition.variations, amount) || [amount];

  const availabilities = combination.map(
    value =>
      definition.variationAvailability?.find(variation => variation.value === value)?.availability ||
      CouponVariationAvailabilityDTOAvailabilityEnum.OnDemand,
  );
  const minAvailability = availabilities.sort((a, b) => AVAILABILITY_ORDER.indexOf(b) - AVAILABILITY_ORDER.indexOf(a))[0];
  return minAvailability;
}

export function getAvailabilityByAmount(
  definition: CouponDefinitionDTO,
  amounts: number[],
): Record<number, CouponVariationAvailabilityDTOAvailabilityEnum> {
  return amounts.reduce(
    (obj, amount) => ({ ...obj, [amount]: getCouponAvailability(definition, amount) }),
    {} as Record<number, CouponVariationAvailabilityDTOAvailabilityEnum>,
  );
}

export function canBeDefaultCoupon(couponAvailability: CouponVariationAvailabilityDTOAvailabilityEnum): boolean {
  return (
    couponAvailability === CouponVariationAvailabilityDTOAvailabilityEnum.InStockUnlimited ||
    couponAvailability === CouponVariationAvailabilityDTOAvailabilityEnum.OnDemand
  );
}

export function hasRequiredFlexVariations(definition?: CouponDefinitionDTO): boolean {
  const allVariations = [...(definition?.variations || []), ...(definition?.combinations || [])];
  // needs to have all variations from 5€ to 50€
  return new Array(10)
    .fill(0)
    .map((v, index) => (index + 1) * 500)
    .every(amount => allVariations.includes(amount));
}
