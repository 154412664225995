import { Box, useTheme } from '@mui/material';
import { useCallback, useState } from 'react';

interface Props {
  imageUrls: string[];
  height: number;
  imagePadding?: number;
  scrollDuration?: string;
}

export const ImageScroller: React.FC<Props> = ({ imageUrls, height, imagePadding, scrollDuration = '10s' }) => {
  const theme = useTheme();
  const [loadedCount, setLoadedCount] = useState(0);

  const handleLoad = useCallback(() => {
    setLoadedCount(value => value + 1);
  }, []);

  return (
    <Box position="relative" overflow="hidden" height={theme.spacing(height)}>
      <Box
        display="flex"
        flexWrap="nowrap"
        position="absolute"
        left={0}
        top={0}
        height="100%"
        sx={{
          visibility: loadedCount === imageUrls.length * 2 ? 'visible' : 'hidden',
          animation: loadedCount === imageUrls.length * 2 ? `slideshow ${scrollDuration} linear infinite, fade 0.5s` : undefined,
          '@keyframes slideshow': {
            '0%': { transform: 'translateX(-10%)' },
            '100%': { transform: 'translateX(-60%)' },
          },
          '@keyframes fade': {
            '0%': { opacity: '0' },
            '100%': { opacity: '1' },
          },
        }}
      >
        {[...imageUrls, ...imageUrls].map((url, index) => (
          <img key={index} src={url} height="100%" style={{ paddingLeft: theme.spacing(imagePadding || 0) }} onLoad={handleLoad} />
        ))}
      </Box>
    </Box>
  );
};
