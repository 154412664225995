import { useTheme } from '@mui/material';
import React from 'react';
import { ImageFile } from '../camera/ImageFile';
import { useImageURI } from '../camera/useImageUri';
import { Drawable } from '../drawable/Drawable';
import { ImageZoomer } from '../imageZoomer/ImageZoomer';
import { FullscreenStep } from './FullscreenStep';

interface Props {
  image: ImageFile;
  icon: React.ReactNode;
  onPrevious: () => void;
  onCancel: () => void;
}

export const ImageZoomerStep: React.FC<React.PropsWithChildren<Props>> = ({ image, icon, onPrevious, onCancel, children }) => {
  const theme = useTheme();

  const imageURI = useImageURI(image);

  return (
    <FullscreenStep transparent onPrevious={onPrevious} onCancel={onCancel}>
      <ImageZoomer imageUrl={imageURI} imageName="receipt" wrapperStyle={{ backgroundColor: theme.palette.background.dark }} />
      <Drawable icon={icon}>{children}</Drawable>
    </FullscreenStep>
  );
};
