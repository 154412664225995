import { configureStore } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import { meReducer } from 'probonio-shared-ui/module/me';
import { couponsReducer } from '../module/coupons/redux';
import { pushNotificationReducer } from '../module/pushNotifications';
import { versionCheckReducer } from '../module/versionCheck/versionCheckSlice';
import { couponsV2Reducer } from '../module/couponsV2/redux';

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  // Optionally pass options listed below
});

export const store = configureStore({
  reducer: {
    me: meReducer,
    versionCheck: versionCheckReducer,
    pushNotification: pushNotificationReducer,
    coupons: couponsReducer,
    couponsV2: couponsV2Reducer,
  },
  enhancers: getDefaultEnhancers => getDefaultEnhancers().concat(sentryReduxEnhancer),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
