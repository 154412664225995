import { CouponsV2UserTokenDTO } from 'probonio-shared-ui/api';
import { CouponRow } from '../../couponsShared/components/CouponRow';
import { useTranslation } from 'react-i18next';

interface Props {
  userToken: CouponsV2UserTokenDTO;
}

export const CouponsV2UserTokenItem: React.FC<Props> = ({ userToken }) => {
  const { t } = useTranslation('couponsV2Module');

  return (
    <CouponRow
      title={t('userTokens.tokenName')}
      subTitles={
        userToken.redeemDate
          ? [t('userTokens.statusText.REDEEMED', { redeemDate: new Date(userToken.redeemDate) })]
          : [t('userTokens.statusText.AVAILABLE', { issueDate: new Date(userToken.issueDate) })]
      }
      leftIcon={<img src="/loginscreen/logo.svg" style={{ maxHeight: 40, maxWidth: 40 }} alt={t('tokenDetails.logoAlt')} />}
      amount={userToken.redeemDate ? undefined : userToken.amount}
      linkTo={`/couponsV2/tokens/${userToken.id}`}
      fade={!!userToken.redeemDate}
    />
  );
};
