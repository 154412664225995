import { createSvgIcon } from '@mui/material';

export const CloudEuroIcon = createSvgIcon(
  <svg width="6.3499999mm" height="4.2333336mm" viewBox="0 0 6.3499999 4.2333336" xmlns="http://www.w3.org/2000/svg">
    <g transform="translate(-101.86458,-146.31459)">
      <path
        strokeWidth="0.264583"
        fill="currentColor"
        d="m 105.03958,146.31458 c -0.76464,0 -1.42885,0.43367 -1.75958,1.06867 -0.79639,0.0847 -1.41542,0.7596 -1.41542,1.57717 0,0.87577 0.71173,1.5875 1.5875,1.5875 h 3.43959 c 0.73025,0 1.32291,-0.59267 1.32291,-1.32292 0,-0.6985 -0.54249,-1.26496 -1.23041,-1.31258 -0.17992,-0.91282 -0.9815,-1.59784 -1.94459,-1.59784 z m 0.0326,0.64648 c 0.40411,0 0.77483,0.15285 1.0542,0.40411 l -0.31109,0.31109 c -0.2003,-0.17218 -0.46023,-0.27595 -0.74311,-0.27595 -0.43925,0 -0.82258,0.2495 -1.01234,0.61494 h 1.18804 l -0.1757,0.3514 h -1.1281 c -0.009,0.058 -0.0139,0.116 -0.0139,0.1757 0,0.0597 0.005,0.1177 0.0139,0.1757 h 1.3038 l -0.1757,0.3514 h -1.01234 c 0.18976,0.36546 0.57133,0.61495 1.01234,0.61495 0.28463,0 0.5425,-0.10377 0.74104,-0.27595 l 0.31316,0.31109 c -0.27937,0.25125 -0.64834,0.40411 -1.0542,0.40411 -0.68875,0 -1.27249,-0.441 -1.49035,-1.0542 h -0.79375 l 0.1757,-0.3514 h 0.53744 c -0.008,-0.058 -0.0103,-0.1159 -0.0103,-0.1757 0,-0.0597 0.003,-0.1177 0.0103,-0.1757 h -0.71314 l 0.1757,-0.3514 h 0.61805 c 0.21786,-0.61318 0.8016,-1.05419 1.49035,-1.05419 z"
      />
    </g>
  </svg>,
  'CloudEuro',
);
