import { Capacitor } from '@capacitor/core';
import { Box, Typography, useTheme } from '@mui/material';

interface Props {
  title?: string | React.ReactNode;
  absolute?: boolean;
  invert?: boolean;
  color?: string;
  backButton?: React.ReactNode;
  actionButton?: React.ReactNode;
}

export const AppToolbar: React.FC<Props> = ({ title, absolute, invert, color, backButton, actionButton }) => {
  const paddingTop = Capacitor.getPlatform() === 'ios' ? 3 : 1;
  const theme = useTheme();

  return (
    <Box
      sx={{
        position: absolute ? 'absolute' : undefined,
        top: 0,
        left: 0,
        right: 0,
        display: 'flex',
        alignItems: 'center',
        zIndex: 1,
        padding: 1,
        paddingTop,
        color: invert ? 'white' : undefined,
        backgroundColor: color,
      }}
    >
      <Box sx={{ flexBasis: 0, flexGrow: 1 }}>
        <Box sx={{ display: 'inline-block', backgroundColor: invert ? theme.palette.alphaBackground : undefined, borderRadius: '50%' }}>
          {backButton}
        </Box>
      </Box>
      <Box data-test-id="mobile-custom-benefit-title" sx={{ textAlign: 'center' }}>
        {title && <Typography variant="h2">{title}</Typography>}
      </Box>
      <Box sx={{ flexBasis: 0, flexGrow: 1, textAlign: 'right' }}>
        <Box sx={{ display: 'inline-block', backgroundColor: invert ? theme.palette.alphaBackground : undefined, borderRadius: '50%' }}>
          {actionButton}
        </Box>
      </Box>
    </Box>
  );
};
