import { Button, Stack } from '@mui/material';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNewDialogState } from '../../../component/dialog/BasicDialog';
import { LoadingFunction } from '../../../utils/loadingFunction';
import { CouponRedeemDrawer } from './CouponRedeemDrawer';
import { CouponRedeemedModal } from './CouponRedeemedModal';

export interface UpdateCouponOrderRedeem {
  newRemainingAmount?: number;
  shouldBeRedeemed?: boolean;
}
export interface RedeemableCouponDetails {
  amount: number;
  remainingAmount?: number;
  redeemDate?: string;
}

interface Props {
  couponOrder: RedeemableCouponDetails;
  onUpdate: LoadingFunction<(value: UpdateCouponOrderRedeem) => void>;
}

export const CouponRedeemButton: React.FC<Props> = ({ couponOrder, onUpdate }) => {
  const { t } = useTranslation('couponsModule');
  const { dialogState, handleOpen: handleOpenDialog } = useNewDialogState();
  const remainingAmount = couponOrder.remainingAmount ? couponOrder.remainingAmount : couponOrder.amount;

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const handleOpenDrawer = useCallback(() => {
    setIsDrawerOpen(true);
  }, []);
  const handleCloseDrawer = useCallback(() => {
    setIsDrawerOpen(false);
  }, []);

  // close dialog when order is reloaded
  const wasLoading = useRef<boolean>();
  useEffect(() => {
    if (wasLoading.current && !onUpdate.isLoading) {
      dialogState.handleClose();
    }
    wasLoading.current = onUpdate.isLoading;
  }, [dialogState, onUpdate.isLoading]);

  const handleConfirm = useCallback(() => {
    onUpdate({ shouldBeRedeemed: !couponOrder.redeemDate });
  }, [onUpdate, couponOrder.redeemDate]);

  const handleResetRedeem = useCallback(() => {
    onUpdate({ newRemainingAmount: couponOrder.amount, shouldBeRedeemed: false });
    handleCloseDrawer();
  }, [couponOrder.amount, handleCloseDrawer, onUpdate]);

  return (
    <>
      <Stack spacing={0.5}>
        {!couponOrder.redeemDate ? (
          <Button fullWidth variant="contained" color="secondary" onClick={handleOpenDialog}>
            {t('couponOrderDetails.redeemedButton')}
          </Button>
        ) : (
          <Button fullWidth variant="contained" color="secondary" onClick={handleConfirm}>
            {t('couponOrderDetails.unRedeemButton')}
          </Button>
        )}
        {!couponOrder.redeemDate && (
          <Button fullWidth variant="contained" color="secondary" onClick={handleOpenDrawer}>
            {t('partRedeem.title')}
          </Button>
        )}
      </Stack>
      <CouponRedeemedModal handleConfirm={handleConfirm} dialogState={dialogState} isLoading={onUpdate.isLoading} />
      <CouponRedeemDrawer
        open={isDrawerOpen}
        orderAmount={couponOrder.amount}
        remainingAmount={remainingAmount}
        onMutate={onUpdate}
        onCloseDrawer={handleCloseDrawer}
        onResetRedeem={handleResetRedeem}
      />
    </>
  );
};
