import { Capacitor } from '@capacitor/core';
import { deepmerge } from '@mui/utils';
import { createTheme, ThemeOptions } from '@mui/material';
import React from 'react';

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {}
}

declare module '@mui/material/SvgIcon' {
  interface SvgIconPropsSizeOverrides {
    extraLarge: true;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    extraBig: true;
    extraSmall: true;
    small: true;
    paragraph: true;
    description: true;
    descriptionBold: true;
  }
}

declare module '@mui/material/styles' {
  interface TypographyVariants {
    extraBig: React.CSSProperties;
    extraSmall: React.CSSProperties;
    small: React.CSSProperties;
    paragraph: React.CSSProperties;
    description: React.CSSProperties;
    descriptionBold: React.CSSProperties;
  }

  interface AlertPalette {
    text: string;
    background: string;
    icon: string;
  }

  interface Palette {
    alert: {
      info: AlertPalette;
      error: AlertPalette;
      warning: AlertPalette;
      success: AlertPalette;
    };
    datePicker: {
      border: string;
      background: string;
    };
    alphaBackground: string;
    greyBackground: string;
    magicCounter: {
      player1: string;
      player2: string;
    };
    bikeleasing: {
      primary: string;
      secondary: string;
      text: string;
    };
    closeIcon: { main?: string };
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    extraBig?: React.CSSProperties;
    extraSmall?: React.CSSProperties;
    small?: React.CSSProperties;
    paragraph?: React.CSSProperties;
    description?: React.CSSProperties;
    descriptionBold?: React.CSSProperties;
  }

  interface AlertPaletteOptions {
    text?: string;
    background?: string;
    icon?: string;
  }

  interface PaletteOptions {
    alert?: {
      info?: AlertPaletteOptions;
      error?: AlertPaletteOptions;
      warning?: AlertPaletteOptions;
      success?: AlertPaletteOptions;
    };
    datePicker?: {
      border?: string;
      background?: string;
    };
    alphaBackground?: string;
    greyBackground: string;

    magicCounter: {
      player1?: string;
      player2?: string;
    };
    bikeleasing: {
      primary?: string;
      secondary?: string;
      text?: string;
    };
    closeIcon: { main?: string };
  }
  interface ThemeOptions {
    palette?: PaletteOptions;
  }
  interface TypeBackground {
    dark?: string;
  }
}

export const fontWeights = {
  bold: 600,
  regular: 400,
  semiBold: 500,
};

const SECONDARY_TEXT = '#5a677f';
const BORDER_COLOR = 'rgba(0, 0, 0, 0.12)';

const baseTheme: ThemeOptions = {
  components: {
    MuiCssBaseline: {
      styleOverrides: Capacitor.isNativePlatform()
        ? {
            body: {
              userSelect: 'none',
            },
            '.selectable': {
              userSelect: 'text',
            },
          }
        : undefined,
    },
    MuiSvgIcon: {
      variants: [
        {
          props: { fontSize: 'extraLarge' },
          style: {
            fontSize: '2.5rem',
          },
        },
      ],
    },
    MuiDialog: {
      styleOverrides: {
        paperFullScreen: {
          margin: '0px',
        },
        paper: {
          margin: '20px',
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },

      styleOverrides: {
        root: {
          fontWeight: 600,
          paddingLeft: 25,
          paddingRight: 25,
          textTransform: 'none',
        },
        outlined: {
          borderWidth: 2,
          '&:hover': {
            borderWidth: 2,
          },
        },
        outlinedSizeMedium: {
          paddingTop: 6.5,
          paddingBottom: 6.5,
        },
        sizeSmall: {
          fontSize: 16,
          paddingTop: 6,
          paddingBottom: 6,
        },
        sizeMedium: {
          fontSize: 16,
          paddingTop: 8.5,
          paddingBottom: 8.5,
        },
        sizeLarge: {
          fontSize: 18,
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontSize: 15,
          fontWeight: 500,
        },
        sizeMedium: {
          paddingTop: 6,
          paddingBottom: 6,
        },
        sizeLarge: {
          paddingTop: 12,
          paddingBottom: 12,
        },
        primary: {
          '&.Mui-selected': {
            color: '#f00',
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        standard: {
          fontWeight: 500,
          fontSize: '14px',
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        standardWarning: { paddingTop: '0px', paddingBottom: '0px' },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        rounded: {
          '&:first-of-type': {
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
          },
          '&:last-of-type': {
            borderBottomLeftRadius: 8,
            borderBottomRightRadius: 8,
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        rounded: {
          borderRadius: 8,
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paperAnchorBottom: {
          borderTopLeftRadius: 12,
          borderTopRightRadius: 12,
        },
      },
    },

    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: BORDER_COLOR,
          opacity: 0.5,
          borderWidth: 1,
        },
      },
    },
  },

  shape: {
    borderRadius: 7,
  },

  spacing: 20,

  typography: {
    caption: {
      fontSize: 16,
      fontWeight: 500,
    },
    description: {
      fontSize: 14,
      fontWeight: 400,
    },
    descriptionBold: {
      fontSize: 14,
      fontWeight: 600,
    },
    paragraph: {
      fontSize: 16,
      fontWeight: 400,
    },
    extraBig: {
      fontSize: 32,
      fontWeight: 700,
      lineHeight: 1.167,
    },
    extraSmall: {
      fontSize: 10,
    },
    small: {
      fontSize: 13,
    },
    h1: {
      fontSize: 26,
      fontWeight: 600,
    },
    h2: {
      fontSize: 22,
      fontWeight: 600,
    },
    h3: {
      fontSize: 16,
      fontWeight: 600,
    },
    h5: {
      fontWeight: 600,
    },
    h6: {
      fontSize: 16,
      fontWeight: 600,
    },
    body1: {
      //  color: SECONDARY_TEXT,
    },
    body2: {
      fontSize: 14,
    },
    subtitle1: {
      fontSize: 26,
    },
  },
};

export const MATERIAL_THEME_LIGHT = createTheme(
  deepmerge(baseTheme, {
    typography: {
      description: {
        color: '#000000',
      },
      descriptionBold: {
        color: '#000000',
      },
      paragraph: {
        color: '#5A677F',
      },
    },
    palette: {
      mode: 'light',
      datePicker: {
        border: BORDER_COLOR,
        background: '#f1f7fb',
      },
      text: {
        secondary: SECONDARY_TEXT,
      },
      primary: { main: '#004976', contrastText: '#fff', light: '#9BCBEB' },
      secondary: { main: '#E4EEF7', contrastText: '#5A677F', light: '#5A677F' },
      alert: {
        info: {
          text: '#003352',
          background: '#9BCBEB',
          icon: '#004976',
        },
        warning: {
          text: '#663c00',
          background: '#fff4e5',
          icon: '#ff9800',
        },
        success: {
          text: '#1d6d51',
          background: '#cdf1e4',
          icon: '#38c895',
        },
        error: {
          text: '#77062c',
          background: '#fadce6',
          icon: '#dd1155',
        },
      },
      info: { main: '#004976', light: '#9acbeb', dark: '#e4eef7' },
      warning: { main: '#ff9800' },
      success: { main: '#38c895', light: '#38c89538' },
      error: { main: '#dd1155' },
      background: {
        default: '#edf7ff',
        paper: '#ffffff',
        dark: '#060F27',
      },
      alphaBackground: '#444b',
      greyBackground: 'rgba(0, 0, 0, 0.12)',
      magicCounter: {
        player1: '#ff9800',
        player2: '#27bbd4',
      },
      bikeleasing: {
        primary: '#95C11E',
        secondary: '#ddf2c0',
        text: '#060606',
      },
      closeIcon: { main: '#bdbdbd' },
    },
  }),
);

export const MATERIAL_THEME_DARK = createTheme(
  deepmerge(baseTheme, {
    typography: {
      description: {
        color: '#afbedb',
      },
      descriptionBold: {
        color: '#afbedb',
      },
      paragraph: {
        color: '#8698ba',
      },
    },
    palette: {
      mode: 'dark',
      datePicker: {
        border: 'rgba(0, 0, 0, 0.12)',
        background: '#121212',
      },

      text: {
        secondary: '#8698ba',
        primary: '#afbedb',
      },
      primary: { main: '#a0ceff', contrastText: '#121212' },
      secondary: { main: '#004976', contrastText: '#a0ceff' },
      alert: {
        info: {
          text: '#003352',
          background: '#9BCBEB',
          icon: '#004976',
        },
        warning: {
          text: '#663c00',
          background: '#fff4e5',
          icon: '#ff9800',
        },
        success: {
          text: '#1d6d51',
          background: '#cdf1e4',
          icon: '#3ac795',
        },
        error: {
          text: '#77062c',
          background: '#fadce6',
          icon: '#dd1155',
        },
      },
      info: { main: '#004976', light: '#9acbeb', dark: '#121212' },
      warning: { main: '#ff9800' },
      success: { main: '#3ac795', light: 'rgba(0, 0, 0, 0.0)' },
      error: { main: '#f44336' },
      background: {
        default: '#1c2329',
        paper: '#121212',
        dark: '#121212',
      },
      alphaBackground: '#444b',
      greyBackground: '#273a48',
      magicCounter: {
        player1: '#7d001e',
        player2: '#254490',
      },
      bikeleasing: {
        primary: '#95C11E',
        secondary: '#ddf2c0',
        text: '#060606',
      },
      closeIcon: { main: '#bdbdbd' },
    },
  }),
);

export const MATERIAL_THEME_LIGHT_CUSTOM = deepmerge(baseTheme, {
  typography: {
    description: {
      color: '#000000',
    },
    descriptionBold: {
      color: '#000000',
    },
    paragraph: {
      color: '#5A677F',
    },
  },
  palette: {
    mode: 'light',
    datePicker: {
      border: BORDER_COLOR,
      background: '#f1f7fb',
    },
    text: {
      secondary: '#5f5f5f',
    },
    primary: { main: '#004976', contrastText: '#fff', light: '#9BCBEB' },
    secondary: { main: '#E4EEF7' },
    alert: {
      info: {
        background: '#9BCBEB',
        icon: '#004976',
      },
      warning: {
        text: '#663c00',
        background: '#fff4e5',
        icon: '#ff9800',
      },
      success: {
        text: '#1d6d51',
        background: '#cdf1e4',
        icon: '#38c895',
      },
      error: {
        text: '#77062c',
        background: '#fadce6',
        icon: '#dd1155',
      },
    },
    info: { main: '#004976', light: '#9acbeb', dark: '#e4eef7' },
    warning: { main: '#ff9800' },
    success: { main: '#38c895', light: '#38c89538' },
    error: { main: '#dd1155' },
    background: {
      default: '#edf7ff',
      paper: '#ffffff',
      dark: '#060F27',
    },
    alphaBackground: '#444b',
    greyBackground: 'rgba(0, 0, 0, 0.12)',
    magicCounter: {
      player1: '#ff9800',
      player2: '#27bbd4',
    },
    bikeleasing: {
      primary: '#95C11E',
      secondary: '#ddf2c0',
      text: '#060606',
    },
    closeIcon: { main: '#bdbdbd' },
  },
});

export const MATERIAL_THEME_DARK_CUSTOM = deepmerge(baseTheme, {
  typography: {
    paragraph: {
      color: '#ccc',
    },
  },
  palette: {
    mode: 'dark',
    datePicker: {
      border: 'rgba(0, 0, 0, 0.12)',
      background: '#121212',
    },

    text: {
      secondary: '#ccc',
    },
    primary: { main: '#a0ceff', contrastText: '#121212', light: '#9BCBEB' },
    secondary: { main: '#444', contrastText: '#ccc' },
    alert: {
      info: {
        text: '#003352',
        background: '#9BCBEB',
        icon: '#004976',
      },
      warning: {
        text: '#663c00',
        background: '#fff4e5',
        icon: '#ff9800',
      },
      success: {
        text: '#1d6d51',
        background: '#cdf1e4',
        icon: '#3ac795',
      },
      error: {
        text: '#77062c',
        background: '#fadce6',
        icon: '#dd1155',
      },
    },
    info: { main: '#004976', light: '#9acbeb', dark: '#121212' },
    warning: { main: '#ff9800' },
    success: { main: '#3ac795', light: 'rgba(0, 0, 0, 0.0)' },
    error: { main: '#f44336' },
    background: {
      default: '#1c2329',
      paper: '#121212',
      dark: '#121212',
    },
    alphaBackground: '#444b',
    greyBackground: '#3f3f3f',
    magicCounter: {
      player1: '#7d001e',
      player2: '#254490',
    },
    bikeleasing: {
      primary: '#95C11E',
      secondary: '#ddf2c0',
      text: '#060606',
    },
    closeIcon: { main: '#bdbdbd' },
  },
});
