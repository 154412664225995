import { useMutation } from '@tanstack/react-query';
import { DateTime } from 'luxon';
import { CreateLunchReceiptDTO } from 'probonio-shared-ui/api';
import { resizeImage } from 'probonio-shared-ui/component/imageResizer/ImageResizer';
import { apis } from 'probonio-shared-ui/module/api';
import { useActiveEmployment } from 'probonio-shared-ui/module/me';
import { ImageSelectionState } from '../../shareTarget/useImageSelection';
import { LUNCH_THUMBNAIL_SIZE, MIME_TYPE_JPEG } from '../lunchConstants';
import { uploadLunchImageFile } from '../lunchFileUpload';
import { ReceiptStep1Value } from './ReceiptFormStep1';
import { ReceiptStep2Value } from './ReceiptFormStep2';
import { ReceiptStep3Value } from './ReceiptFormStep3';

type CreateLunchReceiptDTOWithoutImage = Omit<CreateLunchReceiptDTO, 'imageKey'> & { imageKey?: string };

export function mapToCreateLunchReceiptRequest(
  step1: ReceiptStep1Value,
  step2: ReceiptStep2Value,
  step3: ReceiptStep3Value,
  ocrResultId?: string,
  imageKey?: string,
): CreateLunchReceiptDTOWithoutImage {
  if (!step3.lunchReceiptType) {
    throw new Error('lunchReceiptType is necessary');
  }
  return {
    total: step1.total,
    receiptType: step3.lunchReceiptType,
    date: DateTime.fromJSDate(step2.date).toFormat('yyyy-MM-dd'),
    comment: step3.comment || undefined,
    ocrResultId,
    lineItems: step1.lineItems,
    imageKey,
  };
}

export function useCreateLunchReceipt(imageSelection: ImageSelectionState, onSuccess: () => void) {
  const getActiveEmployment = useActiveEmployment();

  return useMutation({
    mutationFn: async (dto: CreateLunchReceiptDTOWithoutImage) => {
      if (!imageSelection.selectedImage) {
        return;
      }
      const imageBlob = await imageSelection.selectedImage.getBlob();
      const mimeType = imageSelection.selectedImage!.mimetype;

      let thumbnailBlob;
      if (!imageSelection.pdf) {
        const imageURI = await imageSelection.selectedImage.getURI();
        const thumbnail = await resizeImage(imageURI, LUNCH_THUMBNAIL_SIZE, MIME_TYPE_JPEG);
        thumbnailBlob = thumbnail.blob;
      }

      const imageKeys = await uploadLunchImageFile(
        getActiveEmployment().tenantId,
        getActiveEmployment().id,
        mimeType,
        dto.imageKey ? undefined : imageBlob,
        imageSelection.pdf,
        thumbnailBlob,
      );

      return apis.lunch.createReceipt({
        tenantId: getActiveEmployment().tenantId,
        employeeId: getActiveEmployment().id,
        createLunchReceiptDTO: {
          ...dto,
          imageKey: dto.imageKey || imageKeys.imageKey!,
          pdfKey: imageKeys.pdfKey,
          thumbnailKey: imageKeys.thumbnailKey,
        },
      });
    },

    onSuccess,
  });
}
