import React from 'react';
import { FullWidthCenterBox, GradientBox } from './BoxLayout';
import { BrandLogo } from '../brand/logo';
import { Box, Typography, useTheme } from '@mui/material';
import backgroundImage from './img/background.png';
import backgroundImageDark from './img/backgroundDark.png';

interface Props {
  title?: React.ReactNode;
  text?: React.ReactNode;
  actions?: React.ReactNode;
  children?: React.ReactNode;
}

const MessageLayout: React.FC<Props> = ({ children, text, title, actions }: Props) => {
  const theme = useTheme();

  return (
    <GradientBox
      sx={{
        display: 'flex',
        justifyContent: 'center',
        height: '100%',
        backgroundImage: `url("${theme.palette.mode === 'light' ? backgroundImage : backgroundImageDark}")`,
        backgroundSize: 'contain',
        backgroundPositionX: 0,
        backgroundPositionY: '50%',
        backgroundRepeat: 'no-repeat',
        [theme.breakpoints.down('sm')]: {
          backgroundSize: 'cover',
          backgroundPositionX: '50%',
        },
      }}
    >
      <Box
        sx={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          [theme.breakpoints.up('sm')]: {
            maxWidth: '450px',
            maxHeight: '700px',
          },
          // needed so text wont hide behind backgroundHider
          // used in Parent
          zIndex: 10,
        }}
      >
        <FullWidthCenterBox sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
          <Box sx={{ marginBottom: 3 }}>
            <BrandLogo />
          </Box>
          <Typography variant="h1" marginBottom={1}>
            {title}
          </Typography>
          <FullWidthCenterBox>
            <Typography color="text.secondary" sx={{ maxWidth: '300px', marginX: 1.5 }}>
              {text}
            </Typography>
          </FullWidthCenterBox>
          {actions && <Box sx={{ marginTop: 3, [theme.breakpoints.up('sm')]: { marginTop: 6 } }}>{actions}</Box>}
        </FullWidthCenterBox>
        {children}
      </Box>
    </GradientBox>
  );
};

export default MessageLayout;
