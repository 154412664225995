import { DateTime } from 'luxon';
import { BAVBenefitDetailsDTO } from 'probonio-shared-ui/api';

// this is an estimate of all tax and social deductions based on Steuerklasse 1, no kids, and the average salary below
const AVERAGE_TAX_RATE = 0.457;
// this value is actually not required for the calculation; it is just here for completeness
export const AVERAGE_SALARY = 38000;
const RATE_OF_INTEREST = 0.06; // p.a.
const PENSION_AGE = 67;
// based on BAV calculator from Allianz
const PENSION_RATE = 0.0025; // 10000€ = 1 RP = 25€

function getSalaryAfterTax(yearlySalary: number): number {
  // this estimate is only valid for the average salary
  return yearlySalary * (1 - AVERAGE_TAX_RATE);
}

export function calculateBAVEstimate(
  bavDetails: BAVBenefitDetailsDTO,
  yearOfBirth: number,
  savingsRate: number,
): { nettoMonthlyCost: number; employerPayment: number; rateOfInterest: number; finalAmount: number; pensionRate: number } {
  const employerPayment = ((bavDetails.additionalPaymentPercent / 100) * savingsRate) / (1 + bavDetails.additionalPaymentPercent / 100);

  const salaryWithoutBav = getSalaryAfterTax(AVERAGE_SALARY);
  const salaryWithBav = getSalaryAfterTax(
    AVERAGE_SALARY - Math.max(0, savingsRate - employerPayment - bavDetails.vwlConversion / 100) * 12,
  );
  const nettoMonthlyCost = (salaryWithoutBav - salaryWithBav) / 12;

  const yearsUntilPension = Math.max(0, PENSION_AGE - (DateTime.now().year - yearOfBirth));

  const q = 1 + RATE_OF_INTEREST / 12;
  const n = yearsUntilPension * 12;
  const r = savingsRate;
  const finalAmount = (r * q * (q ** n - 1)) / (q - 1);

  return {
    nettoMonthlyCost,
    employerPayment,
    rateOfInterest: RATE_OF_INTEREST,
    finalAmount,
    pensionRate: finalAmount * PENSION_RATE,
  };
}
