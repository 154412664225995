import { Capacitor } from '@capacitor/core';
import { Directory, Encoding, Filesystem } from '@capacitor/filesystem';
import { FileOpener } from '@capacitor-community/file-opener';
import { AxiosResponse } from 'axios';
import { downloadFile } from 'probonio-shared-ui/module/api';

function blobToBase64(blob: Blob): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      const dataURL = reader.result as string;
      return resolve(dataURL.substring(dataURL.indexOf(',') + 1));
    };
    reader.readAsDataURL(blob);
  });
}

export async function downloadFileNative(fileName: string, data: string | Blob, contentType?: string): Promise<void> {
  if (!Capacitor.isNativePlatform()) {
    throw new Error('must be on native');
  }
  const encodedData = typeof data === 'string' ? data : await blobToBase64(data);
  const writeResult = await Filesystem.writeFile({
    data: encodedData,
    encoding: typeof data === 'string' ? Encoding.UTF8 : undefined,
    path: fileName,
    directory: Directory.Cache,
  });
  await FileOpener.open({ filePath: writeResult.uri, openWithDefault: true });
}

export async function downloadResponseNative(
  response: AxiosResponse<string | Blob>,
  defaultFileName: string | ((contentType?: string) => string),
): Promise<void> {
  const contentType = response.headers['content-type'];
  const contentDisposition = response.headers['content-disposition'];
  const fileName = /attachment; filename="([^"]+)"/.exec(contentDisposition || '')?.[1];

  const resolvedFileName = typeof defaultFileName === 'string' ? defaultFileName : defaultFileName(contentType);
  if (Capacitor.isNativePlatform()) {
    await downloadFileNative(fileName || resolvedFileName, response.data, contentType);
  } else {
    downloadFile(fileName || resolvedFileName, response.data, contentType);
  }
}
